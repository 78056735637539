const QueenBelfastCourses = [
	{ label: "International Business (Major) with German - Faculty of Arts, Humanities and Social Sciences", value: "International Business (Major) with German - Faculty of Arts, Humanities and Social Sciences" },
	{ label: "Chemical Engineering MEng - Faculty of Engineering and Physical Sciences", value: "Chemical Engineering MEng - Faculty of Engineering and Physical Sciences" },
	{ label: "Medicinal Chemistry with a Year in Industry - Faculty of Engineering and Physical Sciences", value: "Medicinal Chemistry with a Year in Industry - Faculty of Engineering and Physical Sciences" },
	{ label: "Anthropology and Irish - Faculty of Arts, Humanities and Social Sciences", value: "Anthropology and Irish - Faculty of Arts, Humanities and Social Sciences" },
	{ label: "Chemical Engineering with a Year in Industry MEng - Faculty of Engineering and Physical Sciences", value: "Chemical Engineering with a Year in Industry MEng - Faculty of Engineering and Physical Sciences" },
	{ label: "Electrical and Electronic Engineering Sandwich - Faculty of Engineering and Physical Sciences", value: "Electrical and Electronic Engineering Sandwich - Faculty of Engineering and Physical Sciences" },
	{ label: "Archaeology-Palaeoecology and Geography - Faculty of Engineering and Physical Sciences", value: "Archaeology-Palaeoecology and Geography - Faculty of Engineering and Physical Sciences" },
	{ label: "Computer Engineering (with Year in Industry) - Faculty of Engineering and Physical Sciences", value: "Computer Engineering (with Year in Industry) - Faculty of Engineering and Physical Sciences" },
	{ label: "Physics with Spanish MPhys - Faculty of Engineering and Physical Sciences", value: "Physics with Spanish MPhys - Faculty of Engineering and Physical Sciences" },
	{ label: "Computer Science Professional Experience - Faculty of Engineering and Physical Sciences", value: "Computer Science Professional Experience - Faculty of Engineering and Physical Sciences" },
	{ label: "Professional Nursing (Mental Health) - Faculty of Medicine, Health and Life Sciences", value: "Professional Nursing (Mental Health) - Faculty of Medicine, Health and Life Sciences" },
	{ label: "Computer Science MEng - Faculty of Engineering and Physical Sciences", value: "Computer Science MEng - Faculty of Engineering and Physical Sciences" },
	{ label: "Applied Mathematics and Physics MSci - Faculty of Engineering and Physical Sciences", value: "Applied Mathematics and Physics MSci - Faculty of Engineering and Physical Sciences" },
	{ label: "Physics MPhys - Faculty of Engineering and Physical Sciences", value: "Physics MPhys - Faculty of Engineering and Physical Sciences" },
	{ label: "Mathematics and Statistics & Op Research MMath - Faculty of Engineering and Physical Sciences", value: "Mathematics and Statistics & Op Research MMath - Faculty of Engineering and Physical Sciences" },
	{ label: "Medicinal Chemistry - Faculty of Engineering and Physical Sciences", value: "Medicinal Chemistry - Faculty of Engineering and Physical Sciences" },
	{ label: "Geography BSc - Faculty of Engineering and Physical Sciences", value: "Geography BSc - Faculty of Engineering and Physical Sciences" },
	{ label: "Drama and English - Faculty of Arts, Humanities and Social Sciences", value: "Drama and English - Faculty of Arts, Humanities and Social Sciences" },
	{ label: "Environmental and Civil Engineering with a year in industry MEng - Faculty of Engineering and Physical Sciences", value: "Environmental and Civil Engineering with a year in industry MEng - Faculty of Engineering and Physical Sciences" },
	{ label: "Aerospace Engineering Sandwich MEng - Faculty of Engineering and Physical Sciences", value: "Aerospace Engineering Sandwich MEng - Faculty of Engineering and Physical Sciences" },
	{ label: "Physics with Astro-Physics - Faculty of Engineering and Physical Sciences", value: "Physics with Astro-Physics - Faculty of Engineering and Physical Sciences" },
	{ label: "Pharmaceutical Sciences - Faculty of Medicine, Health and Life Sciences", value: "Pharmaceutical Sciences - Faculty of Medicine, Health and Life Sciences" },
	{ label: "Software Engineering With Placement MEng - Faculty of Engineering and Physical Sciences", value: "Software Engineering With Placement MEng - Faculty of Engineering and Physical Sciences" },
	{ label: "Civil Engineering - Faculty of Engineering and Physical Sciences", value: "Civil Engineering - Faculty of Engineering and Physical Sciences" },
	{ label: "Anthropology and Politics - Faculty of Arts, Humanities and Social Sciences", value: "Anthropology and Politics - Faculty of Arts, Humanities and Social Sciences" },
	{ label: "Archaeology and History - Faculty of Engineering and Physical Sciences", value: "Archaeology and History - Faculty of Engineering and Physical Sciences" },
	{ label: "Finance with a Year in Industry - Faculty of Arts, Humanities and Social Sciences", value: "Finance with a Year in Industry - Faculty of Arts, Humanities and Social Sciences" },
	{ label: "Applied Mathematics and Physics - Faculty of Engineering and Physical Sciences", value: "Applied Mathematics and Physics - Faculty of Engineering and Physical Sciences" }
];

export default QueenBelfastCourses;