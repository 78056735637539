const AlphacrucisCourses = [
	{ value: 'Doctor of Philosophy', label: 'Doctor of Philosophy' },
	{ value: 'Master of Teaching (Primary)', label: 'Master of Teaching (Primary)' },
	{ value: 'Bachelor of Business', label: 'Bachelor of Business' },
	{ value: 'Diploma of Business', label: 'Diploma of Business' },
	{ value: 'Bachelor of Education (Early Childhood and Primary)', label: 'Bachelor of Education (Early Childhood and Primary)' },
	{ value: 'Bachelor of Education (Primary)', label: 'Bachelor of Education (Primary)' },
	{ value: 'Master of Teaching (Secondary)', label: 'Master of Teaching (Secondary)' },
	{ value: 'Master of Counselling', label: 'Master of Counselling' },
	{ value: 'Bachelor of Applied Social Science', label: 'Bachelor of Applied Social Science' },
	{ value: 'Diploma of Arts', label: 'Diploma of Arts' },
	{ value: 'Bachelor of Education (Secondary)', label: 'Bachelor of Education (Secondary)' },
	{ value: 'Master of Philosophy', label: 'Master of Philosophy' }
];

export default AlphacrucisCourses;