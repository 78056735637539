const WinnipegCourses = [
	{ value: 'BBA in Business and Administration - Accounting', label: 'BBA in Business and Administration - Accounting' },
	{ value: 'Bachelor of Science in Chemistry ( 4 yrs )', label: 'Bachelor of Science in Chemistry ( 4 yrs )' },
	{ value: 'Bachelor of Science (Honours) in Chemistry', label: 'Bachelor of Science (Honours) in Chemistry' },
	{ value: 'Bachelor of Science in Geography', label: 'Bachelor of Science in Geography' },
	{ value: 'Grade 10th', label: 'Grade 10th' },
	{ value: 'Bachelor of Arts in Classics', label: 'Bachelor of Arts in Classics' },
	{ value: 'Bachelor of Arts in Theatre & Film - Filmmaking', label: 'Bachelor of Arts in Theatre & Film - Filmmaking' },
	{ value: 'Bachelor of Science in Applied Computer Science - Health Informatics', label: 'Bachelor of Science in Applied Computer Science - Health Informatics' },
	{ value: 'Bachelor of Science in Physics', label: 'Bachelor of Science in Physics' },
	{ value: 'Bachelor of Arts in Environmental Studies - Issues in Sustainability ( 4 yrs )', label: 'Bachelor of Arts in Environmental Studies - Issues in Sustainability ( 4 yrs )' },
	{ value: 'Bachelor of Science in Geography ( 4 yrs )', label: 'Bachelor of Science in Geography ( 4 yrs )' },
	{ value: 'Bachelor of Arts in International Development Studies', label: 'Bachelor of Arts in International Development Studies' },
	{ value: 'Bachelor of Arts in Disability Studies ( 4 yrs )', label: 'Bachelor of Arts in Disability Studies ( 4 yrs )' },
	{ value: 'Bachelor of Arts in Political Science ( 4 yrs )', label: 'Bachelor of Arts in Political Science ( 4 yrs )' },
	{ value: 'Bachelor of Arts in Women\'s and Gender Studies ( 4 yrs )', label: 'Bachelor of Arts in Women\'s and Gender Studies ( 4 yrs )' },
	{ value: 'Bachelor of Science in Physics ( 4 yrs )', label: 'Bachelor of Science in Physics ( 4 yrs )' },
	{ value: 'Bachelor of Arts in Applied Computer Science - Health Informatics', label: 'Bachelor of Arts in Applied Computer Science - Health Informatics' },
	{ value: 'Bachelor of Arts in Criminal Justice', label: 'Bachelor of Arts in Criminal Justice' },
	{ value: 'Bachelor of Arts in Indigenous Studies', label: 'Bachelor of Arts in Indigenous Studies' },
	{ value: 'Bachelor of Arts in Modern Languages', label: 'Bachelor of Arts in Modern Languages' },
	{ value: 'Bachelor of Arts in Theatre & Film - Stage Management and Production', label: 'Bachelor of Arts in Theatre & Film - Stage Management and Production' },
	{ value: 'BBA in Business and Administration - Human Resource Management & Organisational Behaviour', label: 'BBA in Business and Administration - Human Resource Management & Organisational Behaviour' },
	{ value: 'Bachelor of Arts in Human Rights ( 4 yrs )', label: 'Bachelor of Arts in Human Rights ( 4 yrs )' },
	{ value: 'PG Diploma in Mobile Application Development', label: 'PG Diploma in Mobile Application Development' },
	{ value: 'Bachelor of Arts in Interdisciplinary Linguistics ( 4 yrs )', label: 'Bachelor of Arts in Interdisciplinary Linguistics ( 4 yrs )' },
	{ value: 'Bachelor of Arts in Religion & Culture ( 4 yrs )', label: 'Bachelor of Arts in Religion & Culture ( 4 yrs )' },
	{ value: 'Bachelor of Arts in Rhetoric, Writing, and Communications ( 4 yrs )', label: 'Bachelor of Arts in Rhetoric, Writing, and Communications ( 4 yrs )' },
	{ value: 'Bachelor of Arts in Mathematics', label: 'Bachelor of Arts in Mathematics' },
	{ value: 'BBA in Business and Administration - Management of Co-operative Enterprises', label: 'BBA in Business and Administration - Management of Co-operative Enterprises' },
	{ value: 'Bachelor of Science in Applied Computer Science ( 4 yrs )', label: 'Bachelor of Science in Applied Computer Science ( 4 yrs )' },
	{ value: 'BBA in Business and Administration ( 4 yrs )', label: 'BBA in Business and Administration ( 4 yrs )' },
	{ value: 'Bachelor of Arts in Applied Computer Science - Information Systems', label: 'Bachelor of Arts in Applied Computer Science - Information Systems' },
	{ value: 'Bachelor of Arts in Economics', label: 'Bachelor of Arts in Economics' },
	{ value: 'Bachelor of Science (Honours) in Biochemistry', label: 'Bachelor of Science (Honours) in Biochemistry' },
	{ value: 'Bachelor of Arts in Economics ( 4 yrs )', label: 'Bachelor of Arts in Economics ( 4 yrs )' },
	{ value: 'Bachelor of Arts in Philosophy ( 4 yrs )', label: 'Bachelor of Arts in Philosophy ( 4 yrs )' },
	{ value: 'Bachelor of Arts in Sociology', label: 'Bachelor of Arts in Sociology' },
	{ value: 'Bachelor of Science (Honours) in Mathematics', label: 'Bachelor of Science (Honours) in Mathematics' },
	{ value: 'Bachelor of Arts in Statistics ( 4 yrs )', label: 'Bachelor of Arts in Statistics ( 4 yrs )' },
	{ value: 'PG diploma in Marketing Management', label: 'PG diploma in Marketing Management' },
	{ value: 'Bachelor of Arts in Conflict Resolution Studies', label: 'Bachelor of Arts in Conflict Resolution Studies' },
	{ value: 'Bachelor of Business Administration (Combined Major)', label: 'Bachelor of Business Administration (Combined Major)' },
	{ value: 'Bachelor of Arts in Psychology ( 4 yrs )', label: 'Bachelor of Arts in Psychology ( 4 yrs )' },
	{ value: 'Bachelor of Arts in History', label: 'Bachelor of Arts in History' },
	{ value: 'Bachelor of Arts in Theatre & Film - Acting', label: 'Bachelor of Arts in Theatre & Film - Acting' },
	{ value: 'Bachelor of Science (Honours) in Physics', label: 'Bachelor of Science (Honours) in Physics' },
	{ value: 'BBA in Business and Administration - International Business', label: 'BBA in Business and Administration - International Business' },
	{ value: 'Bachelor of Arts in Geography ( 4 yrs )', label: 'Bachelor of Arts in Geography ( 4 yrs )' },
	{ value: 'PG diploma in Project Management', label: 'PG diploma in Project Management' },
	{ value: 'PG Diploma in Supply Chain Management', label: 'PG Diploma in Supply Chain Management' },
	{ value: 'Bachelor of Science (Honours) in Neuroscience', label: 'Bachelor of Science (Honours) in Neuroscience' },
	{ value: 'Bachelor of Arts in Statistics', label: 'Bachelor of Arts in Statistics' },
	{ value: 'Bachelor of Science in Mathematics', label: 'Bachelor of Science in Mathematics' },
	{ value: 'Bachelor of Arts in Mathematics ( 4 yrs )', label: 'Bachelor of Arts in Mathematics ( 4 yrs )' },
	{ value: 'Grade 12th', label: 'Grade 12th' },
	{ value: 'Bachelor of Arts in Economics and Finance', label: 'Bachelor of Arts in Economics and Finance' },
	{ value: 'Bachelor of Arts in English: Creative Writing', label: 'Bachelor of Arts in English: Creative Writing' },
	{ value: 'Bachelor of Arts in Geography', label: 'Bachelor of Arts in Geography' },
	{ value: 'Bachelor of Science in Chemistry', label: 'Bachelor of Science in Chemistry' },
	{ value: 'Bachelor of Science (Honours) in Computational Physics', label: 'Bachelor of Science (Honours) in Computational Physics' },
	{ value: 'Bachelor of Arts in Applied Computer Science', label: 'Bachelor of Arts in Applied Computer Science' },
	{ value: 'Bachelor of Kinesiology', label: 'Bachelor of Kinesiology' },
	{ value: 'Bachelor of Arts in Classics ( 4 yrs )', label: 'Bachelor of Arts in Classics ( 4 yrs )' },
	{ value: 'Bachelor of Science (Combined Major)', label: 'Bachelor of Science (Combined Major)' },
	{ value: 'Bachelor of Science in Biology', label: 'Bachelor of Science in Biology' },
	{ value: 'Bachelor of Science in Statistics', label: 'Bachelor of Science in Statistics' },
	{ value: 'Pre-Dentistry Studies', label: 'Pre-Dentistry Studies' },
	{ value: 'Bachelor of Science (Honours) in Geography', label: 'Bachelor of Science (Honours) in Geography' },
	{ value: 'Bachelor of Arts -  Undecided', label: 'Bachelor of Arts -  Undecided' },
	{ value: 'Bachelor of Science in Environment Science - Forest Policy & Management', label: 'Bachelor of Science in Environment Science - Forest Policy & Management' },
	{ value: 'Bachelor of Arts in Anthropology ( 4 yrs )', label: 'Bachelor of Arts in Anthropology ( 4 yrs )' },
	{ value: 'Bachelor of Arts in Environmental Studies - Urban Environments ( 4 yrs )', label: 'Bachelor of Arts in Environmental Studies - Urban Environments ( 4 yrs )' },
	{ value: 'Bachelor of Business Administration (Combined Major) ( 4 yrs )', label: 'Bachelor of Business Administration (Combined Major) ( 4 yrs )' },
	{ value: 'Bachelor of Arts in History ( 4 yrs )', label: 'Bachelor of Arts in History ( 4 yrs )' },
	{ value: 'Bachelor of Science (Honours) in Biology', label: 'Bachelor of Science (Honours) in Biology' },
	{ value: 'Bachelor of Science in Environment Science - Global Environmental Systems', label: 'Bachelor of Science in Environment Science - Global Environmental Systems' },
	{ value: 'BBA in Business and Administration - Marketing', label: 'BBA in Business and Administration - Marketing' },
	{ value: 'PG diploma in Public Relations & Strategic Communication', label: 'PG diploma in Public Relations & Strategic Communication' },
	{ value: 'Bachelor of Arts (Honours) in Economics', label: 'Bachelor of Arts (Honours) in Economics' },
	{ value: 'Bachelor of Arts in English: Young People\'s Texts and Cultures', label: 'Bachelor of Arts in English: Young People\'s Texts and Cultures' },
	{ value: 'Bachelor of Science (Honours) in Applied Computer Science', label: 'Bachelor of Science (Honours) in Applied Computer Science' },
	{ value: 'Bachelor of Science in Statistics - Data Science', label: 'Bachelor of Science in Statistics - Data Science' },
	{ value: 'Bachelor of Science in Biology ( 4 yrs )', label: 'Bachelor of Science in Biology ( 4 yrs )' },
	{ value: 'Grade 9th', label: 'Grade 9th' },
	{ value: 'Grade 11th', label: 'Grade 11th' },
	{ value: 'Bachelor of Arts in Sociology ( 4 yrs )', label: 'Bachelor of Arts in Sociology ( 4 yrs )' },
	{ value: 'Bachelor of Arts in English', label: 'Bachelor of Arts in English' },
	{ value: 'Bachelor of Arts in English: Screen and Cultural Studies', label: 'Bachelor of Arts in English: Screen and Cultural Studies' },
	{ value: 'Bachelor of Arts in History of Art', label: 'Bachelor of Arts in History of Art' },
	{ value: 'PG Certificate in Advanced Business Management', label: 'PG Certificate in Advanced Business Management' },
	{ value: 'Bachelor of Arts in Theatre & Film - Design', label: 'Bachelor of Arts in Theatre & Film - Design' },
	{ value: 'Bachelor of Science in Applied Computer Science', label: 'Bachelor of Science in Applied Computer Science' },
	{ value: 'Bachelor of Science in Environment Science - Chemistry', label: 'Bachelor of Science in Environment Science - Chemistry' },
	{ value: 'Bachelor of Arts in Indigenous Languages', label: 'Bachelor of Arts in Indigenous Languages' },
	{ value: 'Bachelor of Arts in Philosophy', label: 'Bachelor of Arts in Philosophy' },
	{ value: 'Bachelor of Arts in Applied Computer Science ( 4 yrs )', label: 'Bachelor of Arts in Applied Computer Science ( 4 yrs )' },
	{ value: 'Bachelor of Arts in Human Rights', label: 'Bachelor of Arts in Human Rights' },
	{ value: 'Bachelor of Arts in Religion & Culture', label: 'Bachelor of Arts in Religion & Culture' },
	{ value: 'Bachelor of Arts in Theatre & Film - Dance', label: 'Bachelor of Arts in Theatre & Film - Dance' },
	{ value: 'Bachelor of Arts in Urban and Inner-City Studies', label: 'Bachelor of Arts in Urban and Inner-City Studies' },
	{ value: 'Bachelor of Arts in Women\'s and Gender Studies', label: 'Bachelor of Arts in Women\'s and Gender Studies' },
	{ value: 'Bachelor of Science in Applied Physics', label: 'Bachelor of Science in Applied Physics' },
	{ value: 'Bachelor of Science in Environment Science - Forest Ecology', label: 'Bachelor of Science in Environment Science - Forest Ecology' },
	{ value: 'Bachelor of Science in Neuroscience', label: 'Bachelor of Science in Neuroscience' },
	{ value: 'Bachelor of Physical and Health Education', label: 'Bachelor of Physical and Health Education' },
	{ value: 'Bachelor of Arts in International Development Studies ( 4 yrs )', label: 'Bachelor of Arts in International Development Studies ( 4 yrs )' },
	{ value: 'Bachelor of Science (Combined Major) ( 4 yrs )', label: 'Bachelor of Science (Combined Major) ( 4 yrs )' },
	{ value: 'Bachelor of Arts in Disability Studies', label: 'Bachelor of Arts in Disability Studies' },
	{ value: 'Bachelor of Arts in Environmental Studies - Issues in Sustainability', label: 'Bachelor of Arts in Environmental Studies - Issues in Sustainability' },
	{ value: 'Bachelor of Science in Biochemistry', label: 'Bachelor of Science in Biochemistry' },
	{ value: 'Bachelor of Science (Honours) in Medical Physics', label: 'Bachelor of Science (Honours) in Medical Physics' },
	{ value: 'Bachelor of Arts in Rhetoric, Writing, and Communications', label: 'Bachelor of Arts in Rhetoric, Writing, and Communications' },
	{ value: 'Bachelor of Arts in English ( 4 yrs )', label: 'Bachelor of Arts in English ( 4 yrs )' },
	{ value: 'PG Diploma in Web Application Development', label: 'PG Diploma in Web Application Development' },
	{ value: 'Bachelor of Arts in Psychology', label: 'Bachelor of Arts in Psychology' },
	{ value: 'BBA in Business and Administration', label: 'BBA in Business and Administration' },
	{ value: 'Bachelor of Science in Neuroscience ( 4 yrs )', label: 'Bachelor of Science in Neuroscience ( 4 yrs )' },
	{ value: 'Bachelor of Science in Applied Computer Science - Information Systems', label: 'Bachelor of Science in Applied Computer Science - Information Systems' },
	{ value: 'Bachelor of Science (Honours) in Mathematical Physics', label: 'Bachelor of Science (Honours) in Mathematical Physics' },
	{ value: 'Bachelor of Arts in History of Art ( 4 yrs )', label: 'Bachelor of Arts in History of Art ( 4 yrs )' },
	{ value: 'Bachelor of Arts in Urban and Inner-City Studies ( 4 yrs )', label: 'Bachelor of Arts in Urban and Inner-City Studies ( 4 yrs )' },
	{ value: 'PG diploma in Human Resources Management', label: 'PG diploma in Human Resources Management' },
	{ value: 'Bachelor of Arts in Criminal Justice ( 4 Yrs )', label: 'Bachelor of Arts in Criminal Justice ( 4 Yrs )' },
	{ value: 'Bachelor of Science in Mathematics ( 4 yrs )', label: 'Bachelor of Science in Mathematics ( 4 yrs )' },
	{ value: 'Bachelor of Arts in Environmental Studies - Urban Environments', label: 'Bachelor of Arts in Environmental Studies - Urban Environments' },
	{ value: 'Bachelor of Arts in Interdisciplinary Linguistics', label: 'Bachelor of Arts in Interdisciplinary Linguistics' },
	{ value: 'Bachelor of Science in Biochemistry ( 4 yrs )', label: 'Bachelor of Science in Biochemistry ( 4 yrs )' },
	{ value: 'PG diploma in Network Security', label: 'PG diploma in Network Security' },
	{ value: 'Bachelor of Arts in Anthropology', label: 'Bachelor of Arts in Anthropology' },
	{ value: 'Bachelor of Arts in Developmental Studies', label: 'Bachelor of Arts in Developmental Studies' },
	{ value: 'Bachelor of Arts in Political Science', label: 'Bachelor of Arts in Political Science' },
	{ value: 'Bachelor of Science in Bioanthropology', label: 'Bachelor of Science in Bioanthropology' },
	{ value: 'Bachelor of Science (Honours) in Chemical Physics', label: 'Bachelor of Science (Honours) in Chemical Physics' },
	{ value: 'Bachelor of Arts in Indigenous Studies ( 4 yrs )', label: 'Bachelor of Arts in Indigenous Studies ( 4 yrs )' },
	{ value: 'Bachelor of Arts in Theatre & Film - Playwriting', label: 'Bachelor of Arts in Theatre & Film - Playwriting' },
	{ value: 'Bachelor of Science -  Undecided', label: 'Bachelor of Science -  Undecided' },
	{ value: 'Bachelor of Arts in Conflict Resolution Studies ( 4 yrs )', label: 'Bachelor of Arts in Conflict Resolution Studies ( 4 yrs )' },
	{ value: 'Bachelor of Science in Bioanthropology ( 4 yrs )', label: 'Bachelor of Science in Bioanthropology ( 4 yrs )' },
	{ value: 'Bachelor of Science in Statistics ( 4 yrs )', label: 'Bachelor of Science in Statistics ( 4 yrs )' },
	{ value: 'PG diploma in Predictive Analytics', label: 'PG diploma in Predictive Analytics' }
];

export default WinnipegCourses;