const DundalkCourses = [
	{ label: "BA (Hons) in Social Care", value: "BA (Hons) in Social Care" },
	{ label: "BA (Hons) in Arts", value: "BA (Hons) in Arts" },
	{ label: "BA in Community Youth Work", value: "BA in Community Youth Work" },
	{ label: "BA in Sport, Exercise and Enterprise", value: "BA in Sport, Exercise and Enterprise" },
	{ label: "BA (Hons) in Sport, Exercise with Enterprise (add-on)", value: "BA (Hons) in Sport, Exercise with Enterprise (add-on)" },
	{ label: "BA (Hons) in Youth Work (add-on) - NSETS Endorsed", value: "BA (Hons) in Youth Work (add-on) - NSETS Endorsed" },
	{ label: "BA in Hospitality Management", value: "BA in Hospitality Management" },
	{ label: "BA in Event Management", value: "BA in Event Management" },
	{ label: "BA in Culinary Arts", value: "BA in Culinary Arts" },
	{ label: "BA (Hons) in Hospitality Management (add-on)", value: "BA (Hons) in Hospitality Management (add-on)" },
	{ label: "BA (Hons) in Event Management (add-on)", value: "BA (Hons) in Event Management (add-on)" },
	{ label: "BA (Hons) in Culinary Enterprise (add-on)", value: "BA (Hons) in Culinary Enterprise (add-on)" },
	{ label: "BEng in Mechanical Engineering", value: "BEng in Mechanical Engineering" },
	{ label: "BEng in Electrical and Electronic Systems", value: "BEng in Electrical and Electronic Systems" },
	{ label: "BEng in Civil Engineering", value: "BEng in Civil Engineering" },
	{ label: "BSc (Hons) in Building Surveying", value: "BSc (Hons) in Building Surveying" },
	{ label: "BSc (Hons) in Architectural Technology", value: "BSc (Hons) in Architectural Technology" },
	{ label: "BSc (Hons) in Construction Management", value: "BSc (Hons) in Construction Management" },
	{ label: "BSc in Building Surveying", value: "BSc in Building Surveying" },
	{ label: "BSc in Architectural Technology", value: "BSc in Architectural Technology" },
	{ label: "BSc in Construction Management", value: "BSc in Construction Management" },
	{ label: "BSc (Hons) in Engineering Entrepreneurship (add-on)", value: "BSc (Hons) in Engineering Entrepreneurship (add-on)" },
	{ label: "BA (Hons) in Accounting & Finance", value: "BA (Hons) in Accounting & Finance" },
	{ label: "Bachelor of Business (BB) (Hons)", value: "Bachelor of Business (BB) (Hons)" },
	{ label: "BB in Business & Technology", value: "BB in Business & Technology" },
	{ label: "BB in Business & Management", value: "BB in Business & Management" },
	{ label: "Bachelor of Business (BB) (Hons) Add On", value: "Bachelor of Business (BB) (Hons) Add On" },
	{ label: "BBs (Hons) in Digital & International Business (add-on)", value: "BBs (Hons) in Digital & International Business (add-on)" },
	{ label: "BA (Hons) in Global Marketing and Strategic Communication (add-on)", value: "BA (Hons) in Global Marketing and Strategic Communication (add-on)" },
	{ label: "BA (Hons) in Early Childhood Studies", value: "BA (Hons) in Early Childhood Studies" },
	{ label: "BSc (Hons) in Science (Award Options: Environmental Bioscience OR Biopharmaceutical Science)", value: "BSc (Hons) in Science (Award Options: Environmental Bioscience OR Biopharmaceutical Science)" },
	{ label: "BSc (Hons) in Health & Physical Activity", value: "BSc (Hons) in Health & Physical Activity" },
	{ label: "BSc in Pharmaceutical Science", value: "BSc in Pharmaceutical Science" },
	{ label: "BSc in Bioscience", value: "BSc in Bioscience" },
	{ label: "BSc (Hons) in Computing in Software Development", value: "BSc (Hons) in Computing in Software Development" },
	{ label: "BSc in Computing", value: "BSc in Computing" },
	{ label: "BSc (Hons) in Computing in Games Development", value: "BSc (Hons) in Computing in Games Development" },
	{ label: "BSc (Hons) in Computing in Cloud and Data Centre Operations (add-on)", value: "BSc (Hons) in Computing in Cloud and Data Centre Operations (add-on)" },
	{ label: "BSc (Hons) in Computing in Cloud Computing (add-on)", value: "BSc (Hons) in Computing in Cloud Computing (add-on)" },
	{ label: "BA (Hons) in Film & Television Production", value: "BA (Hons) in Film & Television Production" },
	{ label: "BA in Theatre & Film Practice", value: "BA in Theatre & Film Practice" },
	{ label: "BA in Musical Theatre", value: "BA in Musical Theatre" },
	{ label: "BA (Hons) in Creative Media", value: "BA (Hons) in Creative Media" },
	{ label: "BA in Creative Media", value: "BA in Creative Media" },
	{ label: "BA (Hons) in Music", value: "BA (Hons) in Music" },
	{ label: "BA (Hons) in Audio & Music Production", value: "BA (Hons) in Audio & Music Production" },
	{ label: "BA in Audio & Music Production", value: "BA in Audio & Music Production" },
	{ label: "BSc (Hons) in Mathematics and Data Science", value: "BSc (Hons) in Mathematics and Data Science" },
	{ label: "BA (Hons) in International Tourism Management", value: "BA (Hons) in International Tourism Management" },
	{ label: "BEng (Hons) in Electrical and Electronic Engineering", value: "BEng (Hons) in Electrical and Electronic Engineering" },
	{ label: "BEng (Hons) in Mechanical Engineering", value: "BEng (Hons) in Mechanical Engineering" },
	{ label: "BEng (Hons) in Civil Engineering", value: "BEng (Hons) in Civil Engineering" },
	{ label: "BEng (Hons) in Engineering (Common Entry)", value: "BEng (Hons) in Engineering (Common Entry)" },
	{ label: "BSc (Hons) in Civil Engineering (Add-on)", value: "BSc (Hons) in Civil Engineering (Add-on)" },
	{ label: "BA in Digital Marketing & Public Relations", value: "BA in Digital Marketing & Public Relations" },
	{ label: "BSc (Hons) in Computing Systems and Operations", value: "BSc (Hons) in Computing Systems and Operations" },
	{ label: "BA (Hons) in Drama and Performance (add-on)", value: "BA (Hons) in Drama and Performance (add-on)" },
	{ label: "BSc (Hons) in Nutrition and Health", value: "BSc (Hons) in Nutrition and Health" },
	{ label: "BSc (Hons) in Food Production (add-on)", value: "BSc (Hons) in Food Production (add-on)" },
	{ label: "BA (Hons) in Digital Marketing and Strategic Communication", value: "BA (Hons) in Digital Marketing and Strategic Communication" },
	{ label: "BB (Hons) in International Business with Digitalisation", value: "BB (Hons) in International Business with Digitalisation" },
	{ label: "BB (Hons) in Business with Management", value: "BB (Hons) in Business with Management" },
	{ label: "BA (Hons) in Sport and Exercise with Business", value: "BA (Hons) in Sport and Exercise with Business" },
	{ label: "BA (Hons) in Applied Youth Work — NSETS Endorsed", value: "BA (Hons) in Applied Youth Work — NSETS Endorsed" },
	{ label: "BA (Hons) in Hospitality Management with Business", value: "BA (Hons) in Hospitality Management with Business" },
	{ label: "BA (Hons) in Marketing and International Tourism Management", value: "BA (Hons) in Marketing and International Tourism Management" },
	{ label: "MEng in Mechanical Engineering", value: "MEng in Mechanical Engineering" },
	{ label: "MA in Music for Screen", value: "MA in Music for Screen" },
	{ label: "MA/MSc in Music Technology", value: "MA/MSc in Music Technology" },
	{ label: "MSc Computing", value: "MSc Computing" },
	{ label: "MSc Data Analytics", value: "MSc Data Analytics" },
	{ label: "MSc in Agricultural Biotechnology", value: "MSc in Agricultural Biotechnology" },
	{ label: "MBS in Entrepreneurship & Marketing", value: "MBS in Entrepreneurship & Marketing" },
	{ label: "MSc in Renewable Energy Systems", value: "MSc in Renewable Energy Systems" },
	{ label: "International Foundation Programme", value: "International Foundation Programme" }
];

export default DundalkCourses;