const SwarogCourses = [
	{ value: '1-Year Slovene language elderly care nursing practice program', label: '1-Year Slovene language elderly care nursing practice program' },
	{ value: '1-Year-German Nursing Adaptation Certificate for working in German speaking countries', label: '1-Year-German Nursing Adaptation Certificate for working in German speaking countries' },
	{ value: 'BHS-Hospitality Training Program', label: 'BHS-Hospitality Training Program' },
	{ value: '1-year Certificate Program of Slovene Language', label: '1-year Certificate Program of Slovene Language' },
	{ value: '1-year Certificate Program of German Language', label: '1-year Certificate Program of German Language' },
	{ value: '1-Year EU BUS DRIVER’S PROGRAM', label: '1-Year EU BUS DRIVER’S PROGRAM' },
	{ value: '1 YEAR-EU GLOBAL DRIVER’S PROGRAM-SUV6', label: '1 YEAR-EU GLOBAL DRIVER’S PROGRAM-SUV6' },
	{ value: 'Welder program, for TIG Welding workers', label: 'Welder program, for TIG Welding workers' },
];

export default SwarogCourses;