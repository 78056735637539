const GriffithCollegeCourses = [
	{ value: 'Diploma of Science (Standard)', label: 'Diploma of Science (Standard)' },
	{ value: 'Diploma of Design (Accelerated)', label: 'Diploma of Design (Accelerated)' },
	{ value: 'Diploma of Health Care (Standard)', label: 'Diploma of Health Care (Standard)' },
	{ value: 'Diploma of Science (Accelerated)', label: 'Diploma of Science (Accelerated)' },
	{ value: 'Diploma of Criminology and Criminal Justice (Standard)', label: 'Diploma of Criminology and Criminal Justice (Standard)' },
	{ value: 'Diploma of Social and Psychological Science (Standard)', label: 'Diploma of Social and Psychological Science (Standard)' },
	{ value: 'Diploma of International Tourism and Hotel Management', label: 'Diploma of International Tourism and Hotel Management' },
	{ value: 'Diploma of Engineering (Accelerated)', label: 'Diploma of Engineering (Accelerated)' },
	{ value: 'Diploma of Hotel Management (Accelerated)', label: 'Diploma of Hotel Management (Accelerated)' },
	{ value: 'Diploma of Information Technology (Standard)', label: 'Diploma of Information Technology (Standard)' },
	{ value: 'Diploma of Commerce (Standard)', label: 'Diploma of Commerce (Standard)' },
	{ value: 'Diploma of Health Care (Accelerated)', label: 'Diploma of Health Care (Accelerated)' },
	{ value: 'Diploma of Health Sciences (Accelerated)', label: 'Diploma of Health Sciences (Accelerated)' },
	{ value: 'Diploma of Health Sciences (Standard)', label: 'Diploma of Health Sciences (Standard)' },
	{ value: 'Diploma of Criminology and Criminal Justice (Accelerated)', label: 'Diploma of Criminology and Criminal Justice (Accelerated)' },
	{ value: 'Diploma of Engineering (Standard)', label: 'Diploma of Engineering (Standard)' },
	{ value: 'Diploma of Design (Standard)', label: 'Diploma of Design (Standard)' },
	{ value: 'Diploma of Educational Studies', label: 'Diploma of Educational Studies' },
	{ value: 'Diploma of Information Technology (Accelerated)', label: 'Diploma of Information Technology (Accelerated)' },
	{ value: 'Diploma of Hotel Management (Standard)', label: 'Diploma of Hotel Management (Standard)' },
	{ value: 'Diploma of Social and Psychological Science (Accelerated)', label: 'Diploma of Social and Psychological Science (Accelerated)' }
];

export default GriffithCollegeCourses;