const CharlesDarwinCourses = [
	{ value: 'International Masters Qualifying Programme - Standard', label: 'International Masters Qualifying Programme - Standard' },
	{ value: 'Master of Information Technology (Cyber Security)', label: 'Master of Information Technology (Cyber Security)' },
	{ value: 'International Masters Qualifying Programme - Accelerated', label: 'International Masters Qualifying Programme - Accelerated' },
	{ value: 'Master of Data Science', label: 'Master of Data Science' },
	{ value: 'First Year Diploma in Business Studies', label: 'First Year Diploma in Business Studies' },
	{ value: 'First Year Diploma in Information Technology', label: 'First Year Diploma in Information Technology' },
	{ value: 'Masters Preparation Programme', label: 'Masters Preparation Programme' },
	{ value: 'International Masters Qualifying Programme - Accelerated', label: 'International Masters Qualifying Programme - Accelerated' },
	{ value: 'Masters Preparation Programme', label: 'Masters Preparation Programme' },
	{ value: 'International Masters Qualifying Programme - Standard', label: 'International Masters Qualifying Programme - Standard' },
	{ value: 'Foundation Studies Programme', label: 'Foundation Studies Programme' }
];

export default CharlesDarwinCourses;