// src/contexts/NavigationContext.js
import React, { createContext, useContext, useState } from 'react';

const NavigationContext = createContext();

export const useNavigation = () => useContext(NavigationContext);

export const NavigationProvider = ({ children }) => {
	const [fromPage, setFromPage] = useState('');

	return (
		<NavigationContext.Provider value={{ fromPage, setFromPage }}>
			{children}
		</NavigationContext.Provider>
	);
};
