import React, { useState } from 'react';
import axios from 'axios';
import {
	Box, Button, Input, Link, Text, useToast, AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	CircularProgress,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { getUserDetailsFromToken } from '../utils/getUserDetailsFromToken';

const DocumentUpload = ({
	label,
	selectedFile,
	onFileSelect,
	onRemove,
	isFileUploaded,
	uploadedFileName,
	studentID,
	attachmentId,
	setUploadedFileName,
	documentType,
	filePath
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [isLoadingUpload, setIsLoadingUpload] = useState(false);
	const [isLoadingFetch, setIsLoadingFetch] = useState(false);
	const toast = useToast();
	const userDetails = getUserDetailsFromToken();
	const isAdmin = userDetails?.privilege === 'Admin';

	const onClose = () => setIsOpen(false);
	const cancelRef = React.useRef();

	const handleRemove = async () => {
		setIsLoadingFetch(true);

		try {
			await onRemove();
			toast({
				title: 'Success',
				description: 'File deleted successfully',
				status: 'success',
				duration: 5000,
				isClosable: true,
			});
		} catch (error) {
			toast({
				title: 'Error',
				description: 'Error deleting file',
				status: 'error',
				duration: 5000,
				isClosable: true,
			});
		} finally {
			setIsLoadingFetch(false);
			onClose();
		}
	};

	const handleSubmit = async () => {
		setIsLoadingUpload(true);

		const formData = new FormData();
		formData.append('file', selectedFile);
		formData.append('studentID', studentID);
		formData.append('documentType', documentType);

		const config = {
			headers: { 'Content-Type': 'multipart/form-data' }
		};

		try {
			let response;
			if (isFileUploaded && attachmentId) {
				formData.append('attachmentId', attachmentId);
				response = await axios.post(`${process.env.REACT_APP_API_URL}/api/enquiry-attachments/replace`, formData, config);
			} else {
				response = await axios.post(`${process.env.REACT_APP_API_URL}/api/enquiry-attachments/upload`, formData, config);
			}

			toast({
				title: 'Success',
				description: 'File uploaded successfully',
				status: 'success',
				duration: 5000,
				isClosable: true,
			});

			if (response.data && response.data.fileName) {
				setUploadedFileName(prevState => ({ ...prevState, [documentType]: response.data.fileName }));
			}
		} catch (error) {
			toast({
				title: 'Error',
				description: 'Error uploading file',
				status: 'error',
				duration: 5000,
				isClosable: true,
			});
		} finally {
			setIsLoadingUpload(false);
		}
	};

	const bgColor = "aliceBlue";

	return (
		<>
			<Box bg={bgColor} p={4} m={4} boxShadow='md' borderRadius='lg'>
				<Text fontSize="lg" fontWeight="bold">{label}</Text>
				<Box d='flex' alignItems='center' mt={3}>
					<Input type="file" onChange={onFileSelect} />
					<Button ml={2} m={2} onClick={handleSubmit} disabled={!selectedFile || isLoadingUpload || isLoadingFetch} colorScheme="blue">
						{isLoadingUpload ? (
							<CircularProgress isIndeterminate size="24px" color="teal.300" />
						) : isLoadingFetch ? (
							<CircularProgress isIndeterminate size="24px" color="teal.300" />
						) : (
							isFileUploaded ? 'Replace' : 'Upload'
						)}
					</Button>
				</Box>
				{isFileUploaded && uploadedFileName && !isLoadingFetch && (
					<Box mt={4}>
						<Text>Uploaded File:</Text>
						<Link href={`${process.env.REACT_APP_API_URL}/${filePath}`} isExternal color='blue.500'>
							{uploadedFileName}
						</Link>
						{isAdmin &&
							<Button onClick={() => setIsOpen(true)} variant="unstyled" ml={2} disabled={isLoadingFetch}>
								<DeleteIcon />
							</Button>
						}
					</Box>
				)}
			</Box>
			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Delete File
						</AlertDialogHeader>
						<AlertDialogBody>
							Are you sure? You can't undo this action afterwards.
						</AlertDialogBody>
						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={onClose} disabled={isLoadingFetch}>
								Cancel
							</Button>
							<Button colorScheme="red" onClick={handleRemove} ml={3} isLoading={isLoadingFetch}>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
};

export default DocumentUpload;
