const BangorUniversityCourses = [
	{ value: "BA (Hons) English Literature and Creative Writing", label: "BA (Hons) English Literature and Creative Writing" },
	{ value: "BA (Joint Hons) History and English Literature", label: "BA (Joint Hons) History and English Literature" },
	{ value: "BSc (Hons) Creative Technologies", label: "BSc (Hons) Creative Technologies" },
	{ value: "BSc (Hons) Zoology with Animal Behaviour", label: "BSc (Hons) Zoology with Animal Behaviour" },
	{ value: "BSc (Hons) Zoology with Herpetology", label: "BSc (Hons) Zoology with Herpetology" },
	{ value: "MA Comparative Criminology and Criminal Justice", label: "MA Comparative Criminology and Criminal Justice" },
	{ value: "BSc (Hons) Environmental Conservation", label: "BSc (Hons) Environmental Conservation" },
	{ value: "MSc Marine Renewable Energy", label: "MSc Marine Renewable Energy" },
	{ value: "MA by Research Music", label: "MA by Research Music" },
	{ value: "MA History", label: "MA History" },
	{ value: "BA (Hons) Linguistics and the English Language", label: "BA (Hons) Linguistics and the English Language" },
	{ value: "BEng (Hons) Electronic Engineering", label: "BEng (Hons) Electronic Engineering" },
	{ value: "BSc (Hons) Accounting and Finance", label: "BSc (Hons) Accounting and Finance" },
	{ value: "BSc (Hons) Psychology with Neuropsychology", label: "BSc (Hons) Psychology with Neuropsychology" },
	{ value: "BSc Zoology with Ornithology", label: "BSc Zoology with Ornithology" },
	{ value: "MSc By Research Ocean Sciences", label: "MSc By Research Ocean Sciences" },
	{ value: "MSc Counselling", label: "MSc Counselling" },
	{ value: "MSc Language Acquisition and Development", label: "MSc Language Acquisition and Development" },
	{ value: "MSc Investment Management", label: "MSc Investment Management" },
	{ value: "MSc Physical Oceanography", label: "MSc Physical Oceanography" },
	{ value: "MBA Finance", label: "MBA Finance" },
	{ value: "MA Applied Linguistics for TEFL", label: "MA Applied Linguistics for TEFL" },
	{ value: "MA English Literature", label: "MA English Literature" },
	{ value: "MSc Language Technologies", label: "MSc Language Technologies" },
	{ value: "BA (Hons) English Language", label: "BA (Hons) English Language" },
	{ value: "BA (Hons) English Language with Creative Writing", label: "BA (Hons) English Language with Creative Writing" },
	{ value: "BA (Hons) Music and Creative Writing", label: "BA (Hons) Music and Creative Writing" },
	{ value: "BA (Joint Hons) Linguistics and Psychology", label: "BA (Joint Hons) Linguistics and Psychology" },
	{ value: "BSc (Hons) Environmental Science", label: "BSc (Hons) Environmental Science" },
	{ value: "BSc (Hons) Business Management", label: "BSc (Hons) Business Management" },
	{ value: "MScRes Biological Sciences", label: "MScRes Biological Sciences" },
	{ value: "MSci Marine Biology and Zoology", label: "MSci Marine Biology and Zoology" },
	{ value: "BA (Hons) Creative and Professional Writing", label: "BA (Hons) Creative and Professional Writing" },
	{ value: "BA (Joint Hons) History and Music", label: "BA (Joint Hons) History and Music" },
	{ value: "BSc (Hons) Sport and Exercise Psychology", label: "BSc (Hons) Sport and Exercise Psychology" },
	{ value: "Mres Psychology", label: "Mres Psychology" },
	{ value: "MSc Accounting and Banking", label: "MSc Accounting and Banking" },
	{ value: "Mphil Education", label: "Mphil Education" },
	{ value: "MSc Advanced Data Science", label: "MSc Advanced Data Science" },
	{ value: "MSc Accounting", label: "MSc Accounting" },
	{ value: "MSc Public Health and Health Promotion", label: "MSc Public Health and Health Promotion" },
	{ value: "Principles of Clinical Neuropsychology", label: "Principles of Clinical Neuropsychology" },
	{ value: "MA Education Studies", label: "MA Education Studies" },
	{ value: "BA (Hons) English Literature", label: "BA (Hons) English Literature" },
	{ value: "BSc (Hons) Computer Information Systems", label: "BSc (Hons) Computer Information Systems" },
	{ value: "BSc (Hons) Ocean Science", label: "BSc (Hons) Ocean Science" },
	{ value: "MSc Exercise Rehabilitation", label: "MSc Exercise Rehabilitation" },
	{ value: "BSc (Hons) Conservation with Forestry", label: "BSc (Hons) Conservation with Forestry" },
	{ value: "BSc (Hons) Environmental Science (with placement year)", label: "BSc (Hons) Environmental Science (with placement year)" },
	{ value: "MSc International Media Management", label: "MSc International Media Management" },
	{ value: "BSc (Hons) Adventure Sport Science", label: "BSc (Hons) Adventure Sport Science" },
	{ value: "BSc (Hons) Zoology", label: "BSc (Hons) Zoology" },
	{ value: "LLB (Hons) Law", label: "LLB (Hons) Law" },
	{ value: "MZool (Primatology) Zoology with Primatology", label: "MZool (Primatology) Zoology with Primatology" },
	{ value: "Mphil Biological Sciences", label: "Mphil Biological Sciences" },
	{ value: "Mres Professional Writing", label: "Mres Professional Writing" },
	{ value: "MSc Banking and Finance", label: "MSc Banking and Finance" },
	{ value: "MSc Marine Environmental Protection", label: "MSc Marine Environmental Protection" },
	{ value: "MSci Geological Oceanography", label: "MSci Geological Oceanography" },
	{ value: "BSc (Hons) Physical Geography and Oceanography", label: "BSc (Hons) Physical Geography and Oceanography" },
	{ value: "MZool (Animal Behaviour) Zoology with Animal Behaviour", label: "MZool (Animal Behaviour) Zoology with Animal Behaviour" },
	{ value: "MZool (Ornithology) Zoology with Ornithology", label: "MZool (Ornithology) Zoology with Ornithology" },
	{ value: "Mres Film Studies", label: "Mres Film Studies" },
	{ value: "BSc (Hons) Ocean and Geophysics", label: "BSc (Hons) Ocean and Geophysics" },
	{ value: "BSc (Hons) Psychology with Clinical and Health Psychology", label: "BSc (Hons) Psychology with Clinical and Health Psychology" },
	{ value: "Mbiol Biology", label: "Mbiol Biology" },
	{ value: "Mphil Agroforestry", label: "Mphil Agroforestry" },
	{ value: "MSc Banking (Chartered Banker)", label: "MSc Banking (Chartered Banker)" },
	{ value: "MSc Medical Molecular Biology with Genetics", label: "MSc Medical Molecular Biology with Genetics" },
	{ value: "MBA Environmental Management", label: "MBA Environmental Management" },
	{ value: "MA Business & Marketing", label: "MA Business & Marketing" },
	{ value: "MA Bilingualism", label: "MA Bilingualism" },
	{ value: "BSc (Hons) Marine Vertebrate Zoology", label: "BSc (Hons) Marine Vertebrate Zoology" },
	{ value: "Mbiol Biology with Biotechnology", label: "Mbiol Biology with Biotechnology" },
	{ value: "MEng Electronic Engineering", label: "MEng Electronic Engineering" },
	{ value: "MSc Advanced Computer Science", label: "MSc Advanced Computer Science" },
	{ value: "MSc Neuroimaging", label: "MSc Neuroimaging" },
	{ value: "BSc (Hons) Computer Science", label: "BSc (Hons) Computer Science" },
	{ value: "BA (Hons) Criminology and Criminal Justice", label: "BA (Hons) Criminology and Criminal Justice" },
	{ value: "BSc (Hons) Geological Oceanography", label: "BSc (Hons) Geological Oceanography" },
	{ value: "MFor Forestry", label: "MFor Forestry" },
	{ value: "MSc Environmental Forestry", label: "MSc Environmental Forestry" },
	{ value: "MSc Accounting & Finance", label: "MSc Accounting & Finance" },
	{ value: "BMus (Hons) Music", label: "BMus (Hons) Music" },
	{ value: "BSc (Hons) Biology", label: "BSc (Hons) Biology" },
	{ value: "BSc (Hons) Geography", label: "BSc (Hons) Geography" },
	{ value: "BSc (Hons) Zoology with Marine Zoology", label: "BSc (Hons) Zoology with Marine Zoology" },
	{ value: "BA (Hons) Geography", label: "BA (Hons) Geography" },
	{ value: "BSc (Hons) Electronic Engineering", label: "BSc (Hons) Electronic Engineering" },
	{ value: "BSc (Hons) Zoology with Conservation (with Placement Year)", label: "BSc (Hons) Zoology with Conservation (with Placement Year)" },
	{ value: "MEnvSci Environmental Science", label: "MEnvSci Environmental Science" },
	{ value: "MA Criminology and Law", label: "MA Criminology and Law" },
	{ value: "MA Medieval Studies", label: "MA Medieval Studies" },
	{ value: "MA Linguistics", label: "MA Linguistics" },
	{ value: "MA Criminology and Sociology", label: "MA Criminology and Sociology" },
	{ value: "MSci Marine Vertebrate Zoology", label: "MSci Marine Vertebrate Zoology" },
	{ value: "MSc Business Data Analytics", label: "MSc Business Data Analytics" },
	{ value: "BA (Hons) Music", label: "BA (Hons) Music" },
	{ value: "BSc (Hons) Biology with Biotechnology", label: "BSc (Hons) Biology with Biotechnology" },
	{ value: "BSc (Hons) Wildlife Conservation", label: "BSc (Hons) Wildlife Conservation" },
	{ value: "LLB (Hons) Law with Criminology", label: "LLB (Hons) Law with Criminology" },
	{ value: "Mres Media", label: "Mres Media" },
	{ value: "Mres Molecular Medicine", label: "Mres Molecular Medicine" },
	{ value: "MSc Dementia Studies", label: "MSc Dementia Studies" },
	{ value: "MSc Nanotechnology and Microfabrication", label: "MSc Nanotechnology and Microfabrication" },
	{ value: "MBA Informationa Management", label: "MBA Informationa Management" },
	{ value: "MA Music", label: "MA Music" },
	{ value: "MA Creative Writing", label: "MA Creative Writing" },
	{ value: "BA (Joint Hons) English Language and English Literature", label: "BA (Joint Hons) English Language and English Literature" },
	{ value: "BSc (Hons) Environmental Conservation (with placement year)", label: "BSc (Hons) Environmental Conservation (with placement year)" },
	{ value: "Mfor Forestry (with placement year)", label: "Mfor Forestry (with placement year)" },
	{ value: "MSc Finance", label: "MSc Finance" },
	{ value: "MSc Management and Finance", label: "MSc Management and Finance" },
	{ value: "MA Psychology", label: "MA Psychology" },
	{ value: "MBA Islamic Banking and Finance", label: "MBA Islamic Banking and Finance" },
	{ value: "BA (Joint Hons) English Literature and Music", label: "BA (Joint Hons) English Literature and Music" },
	{ value: "BSc (Hons) Zoology with Conservation", label: "BSc (Hons) Zoology with Conservation" },
	{ value: "Mphil Forestry", label: "Mphil Forestry" },
	{ value: "MBA Banking & Finance", label: "MBA Banking & Finance" },
	{ value: "MA Social Policy", label: "MA Social Policy" },
	{ value: "BSc (Hons) Marine Environmental Studies", label: "BSc (Hons) Marine Environmental Studies" },
	{ value: "BSc (Hons) Zoology with Primatology", label: "BSc (Hons) Zoology with Primatology" },
	{ value: "BSc Marketing", label: "BSc Marketing" },
	{ value: "MSc Biomedical Science", label: "MSc Biomedical Science" },
	{ value: "Mres Electronic Engineering", label: "Mres Electronic Engineering" },
	{ value: "MSc Electronic Engineering", label: "MSc Electronic Engineering" },
	{ value: "MSc Clinical and Health Psychology", label: "MSc Clinical and Health Psychology" },
	{ value: "BA (Joint Hons) English Language and English Literature", label: "BA (Joint Hons) English Language and English Literature" },
	{ value: "BSc (Hons) Forestry", label: "BSc (Hons) Forestry" },
	{ value: "MZool Zoology", label: "MZool Zoology" },
	{ value: "MSc By Research Environmental Sciences", label: "MSc By Research Environmental Sciences" },
	{ value: "MBA International Marketing", label: "MBA International Marketing" },
	{ value: "MSc Psychological Research", label: "MSc Psychological Research" },
	{ value: "MSci Marine Biology and Oceanography", label: "MSci Marine Biology and Oceanography" },
	{ value: "MSc Applied Sport and Exercise Physiology", label: "MSc Applied Sport and Exercise Physiology" },
	{ value: "MSc Islamic Banking and Finance", label: "MSc Islamic Banking and Finance" },
	{ value: "MSc Environmental and Business Management", label: "MSc Environmental and Business Management" },
	{ value: "MSc Applied Marine Geoscience", label: "MSc Applied Marine Geoscience" },
	{ value: "MBA International Business", label: "MBA International Business" },
	{ value: "LLM International Criminal Law and International Human Rights Law", label: "LLM International Criminal Law and International Human Rights Law" },
	{ value: "LLM Law and Criminology", label: "LLM Law and Criminology" },
	{ value: "MA Music with Education", label: "MA Music with Education" },
	{ value: "BEng (Hons) Computer Systems Engineering", label: "BEng (Hons) Computer Systems Engineering" },
	{ value: "MComp (Hons) Computer Science", label: "MComp (Hons) Computer Science" },
	{ value: "MSc Computing", label: "MSc Computing" },
	{ value: "MA Translation Studies", label: "MA Translation Studies" },
	{ value: "MSci Physical Oceanography", label: "MSci Physical Oceanography" },
	{ value: "BSc (Hons) Computer Information Systems for Business", label: "BSc (Hons) Computer Information Systems for Business" },
	{ value: "MBA Law & Management", label: "MBA Law & Management" },
	{ value: "MA Sociology", label: "MA Sociology" },
	{ value: "Master by Research Health Economics", label: "Master by Research Health Economics" },
	{ value: "BSc (Hons) Wildlife Conservation (with placement year)", label: "BSc (Hons) Wildlife Conservation (with placement year)" },
	{ value: "MZool (Herpetology) Zoology with Herpetology", label: "MZool (Herpetology) Zoology with Herpetology" },
	{ value: "MSc Agroforestry and Food Security", label: "MSc Agroforestry and Food Security" },
	{ value: "LLM Law", label: "LLM Law" },
	{ value: "MA Arthurian Literature", label: "MA Arthurian Literature" },
	{ value: "BA Journalism and Media Studies", label: "BA Journalism and Media Studies" },
	{ value: "BSc (Hons) Applied Terrestrial and Marine Ecology (with placement year)", label: "BSc (Hons) Applied Terrestrial and Marine Ecology (with placement year)" },
	{ value: "BSc (Hons) Marine Biology and Zoology", label: "BSc (Hons) Marine Biology and Zoology" },
	{ value: "LLB (Hons) Law with History", label: "LLB (Hons) Law with History" },
	{ value: "MSc Rise of the Machines", label: "MSc Rise of the Machines" },
	{ value: "MBA Management", label: "MBA Management" },
	{ value: "MSc Applied Behaviour Analysis", label: "MSc Applied Behaviour Analysis" },
	{ value: "MSci Marine Biology", label: "MSci Marine Biology" },
	{ value: "BA (Hons) History", label: "BA (Hons) History" },
	{ value: "BSc (Hons) Data Science and Visualisation", label: "BSc (Hons) Data Science and Visualisation" },
	{ value: "BSc (Hons) Marine Biology", label: "BSc (Hons) Marine Biology" },
	{ value: "MSc Psychology", label: "MSc Psychology" },
	{ value: "BMedSci (Hons) Medical Sciences", label: "BMedSci (Hons) Medical Sciences" },
	{ value: "BSc (Hons) Medical Biology", label: "BSc (Hons) Medical Biology" },
	{ value: "MEng Control and Instrumentation Engineering", label: "MEng Control and Instrumentation Engineering" },
	{ value: "MZool (Conservation) Zoology with Conservation", label: "MZool (Conservation) Zoology with Conservation" },
	{ value: "MA Celtic Archaeology", label: "MA Celtic Archaeology" },
	{ value: "MSc by Research Environmental Microbiology and Biotechnology", label: "MSc by Research Environmental Microbiology and Biotechnology" },
	{ value: "Mres Creative Practice", label: "Mres Creative Practice" },
	{ value: "BA (Hons) Linguistics", label: "BA (Hons) Linguistics" },
	{ value: "BSc (Hons) Biomedical Science", label: "BSc (Hons) Biomedical Science" },
	{ value: "MZool (Marine Zoology) Zoology with Marine Zoology", label: "MZool (Marine Zoology) Zoology with Marine Zoology" },
	{ value: "MSc Broadband & Optical Communications", label: "MSc Broadband & Optical Communications" },
	{ value: "MSc Conservation and Land Management", label: "MSc Conservation and Land Management" },
	{ value: "MSc Sport and Exercise Science", label: "MSc Sport and Exercise Science" },
	{ value: "BSc (Hons) Applied Terrestrial and Marine Ecology", label: "BSc (Hons) Applied Terrestrial and Marine Ecology" },
	{ value: "BSc (Hons) Banking and Finance", label: "BSc (Hons) Banking and Finance" },
	{ value: "BSc (Hons) Conservation with Forestry (with placement year)", label: "BSc (Hons) Conservation with Forestry (with placement year)" },
	{ value: "BSc Zoology with Animal Behaviour", label: "BSc Zoology with Animal Behaviour" },
	{ value: "MEng Computer Systems Engineering", label: "MEng Computer Systems Engineering" },
	{ value: "MSc Consumer and Digital Marketing Analytics", label: "MSc Consumer and Digital Marketing Analytics" },
	{ value: "MEnvSci Environmental Science", label: "MEnvSci Environmental Science" },
	{ value: "BSc (Hons) Marine Biology and Oceanography", label: "BSc (Hons) Marine Biology and Oceanography" },
	{ value: "MSc Computing for Data Science", label: "MSc Computing for Data Science" },
	{ value: "MSc Marine Biology", label: "MSc Marine Biology" },
	{ value: "BA (Hons) English Literature with Theatre and Performance", label: "BA (Hons) English Literature with Theatre and Performance" },
	{ value: "BSc (Hons) Forestry (with placement year)", label: "BSc (Hons) Forestry (with placement year)" },
	{ value: "BSc (Hons) Psychology", label: "BSc (Hons) Psychology" },
	{ value: "Mgeog Geography", label: "Mgeog Geography" },
	{ value: "BSc (Hons) Data Science and Artificial Intelligence", label: "BSc (Hons) Data Science and Artificial Intelligence" },
	{ value: "MSc Applied Innovation Design", label: "MSc Applied Innovation Design" }
];

export default BangorUniversityCourses;