const AlanaKayeCourses = [
	{ value: 'Diploma of Business', label: 'Diploma of Business' },
	{ value: 'Certificate III in Early Childhood Education and Care', label: 'Certificate III in Early Childhood Education and Care' },
	{ value: 'Advanced Diploma of Leadership and Management', label: 'Advanced Diploma of Leadership and Management' },
	{ value: 'Certificate IV in Building and Construction (Site Management Specialisation)', label: 'Certificate IV in Building and Construction (Site Management Specialisation)' },
	{ value: 'Advanced Diploma of Community Sector Management', label: 'Advanced Diploma of Community Sector Management' },
	{ value: 'Diploma of Leadership and Management', label: 'Diploma of Leadership and Management' },
	{ value: 'Diploma of Early Childhood Education and Care', label: 'Diploma of Early Childhood Education and Care' },
	{ value: 'Certificate IV in Project Management Practice', label: 'Certificate IV in Project Management Practice' },
	{ value: 'Diploma of Project Management', label: 'Diploma of Project Management' },
	{ value: 'Certificate IV in Ageing Support', label: 'Certificate IV in Ageing Support' },
	{ value: 'Diploma of Community Services', label: 'Diploma of Community Services' },
	{ value: 'Certificate IV in Allied Health Assistance', label: 'Certificate IV in Allied Health Assistance' },
	{ value: 'Certificate IV in Training and Assessment', label: 'Certificate IV in Training and Assessment' },
	{ value: 'Certificate II in Security Operations', label: 'Certificate II in Security Operations' },
	{ value: 'Graduate Diploma of Management', label: 'Graduate Diploma of Management' },
	{ value: 'Certificate III in Individual Support', label: 'Certificate III in Individual Support' }
];

export default AlanaKayeCourses;