import React, { useEffect, useState } from 'react';
import { Container, Box, Text, Stack, SimpleGrid, Flex, Spacer, useColorModeValue } from "@chakra-ui/react";
import { FaUser } from 'react-icons/fa';
import Main from "../global/wrapper/Main";
import { getUserDetailsFromToken } from "../utils/getUserDetailsFromToken";

function Dashboard() {
	const bgColor = useColorModeValue('aliceBlue', 'gray.700');
	const textColor = useColorModeValue('gray.500', 'gray.300');

	const [userCount, setUserCount] = useState(0);
	const [enquiryCount, setEnquiryCount] = useState(0);
	const [latestComments, setLatestComments] = useState([]);
	const [branchesCount, setBranchesCount] = useState(0);

	useEffect(() => {
		fetchUserCount();
		fetchEnquiryCount();
		fetchLatestComments();
		fetchBranchesCount();
	}, []);

	const fetchUserCount = async () => {
		try {
			const userDetails = getUserDetailsFromToken();
			const branchId = userDetails?.branchId; // Assuming branchId is included in the user details
			const privilege = userDetails?.privilege;

			// Append branchId as a query parameter if available
			const url = new URL(`${process.env.REACT_APP_API_URL}/api/users/count`);
			if (branchId) {
				url.searchParams.append('branchId', branchId);
			}

			if (privilege) {
				url.searchParams.append('privilege', privilege); // Include privilege in the request
			}

			const response = await fetch(url);
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const data = await response.json();
			setUserCount(data.count);
		} catch (error) {
			console.error('Error fetching user count:', error);
		}
	};

	const fetchEnquiryCount = async () => {
		try {
			const userDetails = getUserDetailsFromToken();
			const branchId = userDetails?.branchId; // Assuming branchId is the field in the user details
			const privilege = userDetails?.privilege;

			// Append branchId as a query parameter if available
			const url = new URL(`${process.env.REACT_APP_API_URL}/api/enquiries/count`);
			if (branchId) {
				url.searchParams.append('branchId', branchId);
			}

			if (privilege) {
				url.searchParams.append('privilege', privilege); // Include privilege in the request
			}

			const response = await fetch(url);
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const data = await response.json();
			setEnquiryCount(data.count);
		} catch (error) {
			console.error('Error fetching enquiry count:', error);
		}
	};

	const fetchBranchesCount = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/branches/count`);
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const data = await response.json();
			setBranchesCount(data.count);
		} catch (error) {
			console.error('Error fetching branches count:', error);
		}
	};

	const getRecentCommentsCount = () => {
		const currentDate = new Date();
		const twentyFourHoursAgo = new Date(currentDate);
		twentyFourHoursAgo.setHours(currentDate.getHours() - 24);

		const recentCommentsCount = latestComments.filter(comment => new Date(comment.datePosted) >= twentyFourHoursAgo).length;

		return recentCommentsCount;
	};


	const fetchLatestComments = async () => {
		try {
			const userDetails = getUserDetailsFromToken();
			const { branchId, privilege } = userDetails;

			// Append branchId as a query parameter if available
			const url = new URL(`${process.env.REACT_APP_API_URL}/api/comments/latest`);
			if (branchId) {
				url.searchParams.append('branchId', branchId);
			}

			if (privilege) {
				url.searchParams.append('privilege', privilege); // Include privilege in the request
			}

			const response = await fetch(url);
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const data = await response.json();
			setLatestComments(data);
		} catch (error) {
			console.error('Error fetching latest comments:', error);
		}
	};

	const formatCommentDate = (dateString) => {
		if (!dateString || new Date(dateString).toString() === 'Invalid Date') {
			// Log for debugging
			console.error("Invalid or undefined date string:", dateString);
			return "Date not available";
		}
		const options = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
		return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
	};

	const userDetails = getUserDetailsFromToken();
	const isAdmin = userDetails?.privilege === "Admin";

	return (
		<Main>
			<Container maxW={{ base: "100%", md: "80%" }}>
				<Stack
					direction={{ base: "column", md: "row" }}
					spacing={{ base: 4, md: 6 }}
				>
					{/* First Row */}
					<Box flex="1">
						{/* Card component for Recent Activities */}
						<Box
							borderWidth="1px"
							borderRadius="lg"
							overflow="hidden"
							boxShadow="md"
							width="100%"
							bg="purple.200" // Change to your desired background color
						>
							<Box p="4">
								<Text fontSize="xl" fontWeight="semibold">
									Recent Comments
								</Text>
								<Text mt="2">{getRecentCommentsCount()}</Text>
							</Box>
						</Box>
					</Box>

					<Box flex="1">
						{/* Card component for No. of Enquiries */}
						<Box
							borderWidth="1px"
							borderRadius="lg"
							overflow="hidden"
							boxShadow="md"
							width="100%"
							bg="green.200" // Change to your desired background color
						>
							<Box p="4">
								<Text fontSize="xl" fontWeight="semibold">
									No. of Enquiries
								</Text>
								<Text mt="2">{enquiryCount}</Text>
							</Box>
						</Box>
					</Box>

					<Box flex="1">
						{/* Card component for No. of Users */}
						<Box
							borderWidth="1px"
							borderRadius="lg"
							overflow="hidden"
							boxShadow="md"
							width="100%"
							bg="blue.200" // Change to your desired background color
						>
							<Box p="4">
								<Text fontSize="xl" fontWeight="semibold">
									No. of Users
								</Text>
								<Text mt="2">{userCount}</Text>
							</Box>
						</Box>
					</Box>

					{isAdmin && (
						<Box flex="1">
							{/* Add more card components for additional features */}
							<Box
								borderWidth="1px"
								borderRadius="lg"
								overflow="hidden"
								boxShadow="md"
								width="100%"
								bg="gray.300" // Change to your desired background color
							>
								<Box p="4">
									<Text fontSize="xl" fontWeight="semibold">
										No. of Branches
									</Text>
									<Text mt="2">{branchesCount}</Text>
								</Box>
							</Box>
						</Box>
					)}

				</Stack>
				<Stack
					direction={{ base: "column", md: "row" }}
					spacing={{ base: 4, md: 6 }}
					mt={10}
				>
					<Box
						borderWidth="1px"
						borderRadius="lg"
						overflow="hidden"
						boxShadow="md"
						width="100%"
						bg="blue.200"
					>
						<Box p="4">
							<Text fontSize="xl" fontWeight="semibold">
								Latest Comments
							</Text>
							<SimpleGrid columns={{ base: 1, md: 1 }} spacing={4} mt={4}>
								{latestComments.map((comment) => (
									<Box key={comment._id} borderWidth="1px" borderRadius="lg" p="4" bgColor={bgColor}>
										<Flex alignItems="center" mb={2}>
											<Box>
												<FaUser className='userIcon' />
											</Box>
											<Box ml={2}>
												<Flex alignItems="center">
													<Text fontSize="lg" m={0} fontWeight="bold">
														{/* {comment.user.userName} */}
														{comment.userDetails ? comment.userDetails.userName : 'Unknown User'}
														{
															comment.enquiryDetails
																? (
																	<>
																		<small> commented on</small> <a style={{ textDecoration: 'underline' }} href={`${process.env.PUBLIC_URL}/enquiry-details/${comment.studentID}`}>{comment.enquiryDetails.givenName}</a>
																	</>
																)
																: 'No enquiry details'
														}
													</Text>
													{/* <Link color="blue.500" ml={2} textDecoration="underline" fontSize="xs" href={`/student-details/${comment.studentID}/#${comment._id}`}>
															View Comment
														</Link> */}
												</Flex>
											</Box>
											<Spacer />
											<Box>
												<Text fontSize="sm" color={textColor} textAlign="right" display={{ base: 'none', md: 'block' }}>
													{formatCommentDate(comment.datePosted)}
												</Text>
											</Box>
										</Flex>
										<Text fontSize="sm" color={textColor} display={{ base: 'block', md: 'none' }}>
											{formatCommentDate(comment.datePosted)}
										</Text>

										<Text className='comment-text' mt={2}>
											{comment.text}
										</Text>
										{/* Replace 'YourRoute' with the actual route */}
									</Box>
								))}
							</SimpleGrid>
						</Box>

					</Box>

				</Stack>
			</Container>
		</Main >
	);
}

export default Dashboard;
