const CapilanoCourses = [
	{ value: 'Post - Baccalaureate Diploma in Global Hospitality & Tourism Management', label: 'Post - Baccalaureate Diploma in Global Hospitality & Tourism Management' },
	{ value: 'Associate of Arts Degree', label: 'Associate of Arts Degree' },
	{ value: 'Bachelor of Arts with a Major in Interdisciplinary Studies', label: 'Bachelor of Arts with a Major in Interdisciplinary Studies' },
	{ value: 'Bachelor of Legal Studies (Paralegal) Degree', label: 'Bachelor of Legal Studies (Paralegal) Degree' },
	{ value: 'Bachelor of Communication Studies Degree', label: 'Bachelor of Communication Studies Degree' },
	{ value: 'Business Administration Diploma', label: 'Business Administration Diploma' },
	{ value: 'Rehabilitation Assistant Diploma', label: 'Rehabilitation Assistant Diploma' },
	{ value: 'Early Childhood Care and Education Post Baccalaureate Diploma', label: 'Early Childhood Care and Education Post Baccalaureate Diploma' },
	{ value: 'North American Business Management Applied Post Baccalaureate Diploma', label: 'North American Business Management Applied Post Baccalaureate Diploma' },
	{ value: 'Diploma in Tourism Management International', label: 'Diploma in Tourism Management International' },
	{ value: 'Associate of Arts Degree - English', label: 'Associate of Arts Degree - English' },
	{ value: 'Associate of Arts Degree - Psychology', label: 'Associate of Arts Degree - Psychology' },
	{ value: 'Associate of Science Degree - Biology', label: 'Associate of Science Degree - Biology' },
	{ value: 'Legal Administrative Assistant Certificate', label: 'Legal Administrative Assistant Certificate' },
	{ value: 'Associate of Arts Degree - Creative Writing', label: 'Associate of Arts Degree - Creative Writing' },
	{ value: 'Bachelor of Tourism Management Degree', label: 'Bachelor of Tourism Management Degree' },
	{ value: 'Applied Behaviour Analysis (Autism) Post Baccalaureate Diploma', label: 'Applied Behaviour Analysis (Autism) Post Baccalaureate Diploma' },
	{ value: 'Bachelor of Business Administration Degree', label: 'Bachelor of Business Administration Degree' },
	{ value: 'North American Business Management Post Baccalaureate Diploma', label: 'North American Business Management Post Baccalaureate Diploma' },
	{ value: 'Tourism Management Co - operative Education Diploma', label: 'Tourism Management Co - operative Education Diploma' },
	{ value: 'Communication Studies Diploma', label: 'Communication Studies Diploma' },
	{ value: 'Bachelor Early Childhood Care and Education Degree', label: 'Bachelor Early Childhood Care and Education Degree' },
	{ value: 'EAP/Business Administration Diploma', label: 'EAP/Business Administration Diploma' },
	{ value: 'Bachelor of Arts with a Major in Psychology', label: 'Bachelor of Arts with a Major in Psychology' },
	{ value: '2D Animation and Visual Development Diploma', label: '2D Animation and Visual Development Diploma' },
	{ value: '3D Animation for Film and Games Diploma', label: '3D Animation for Film and Games Diploma' },
	{ value: 'Associate of Science Degree', label: 'Associate of Science Degree' },
	{ value: 'Visual Effects for Film, Streaming & Immersive Media Diploma', label: 'Visual Effects for Film, Streaming & Immersive Media Diploma' },
	{ value: 'Bachelor of Early Childhood Care and Education Degree -4 Yr', label: 'Bachelor of Early Childhood Care and Education Degree -4 Yr' },
	{ value: 'Early Childhood Care and Education Diploma', label: 'Early Childhood Care and Education Diploma' },
	{ value: 'Business Administration Certificate', label: 'Business Administration Certificate' },
	{ value: 'Kinesiology Bachelor Degree', label: 'Kinesiology Bachelor Degree' },
	{ value: 'Bachelor of Science - General', label: 'Bachelor of Science - General' }
];

export default CapilanoCourses;
