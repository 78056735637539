const AbertayUniversityCourses = [
	{ value: "Interactive Sound and Music - MPhil", label: "Interactive Sound and Music - MPhil" },
	{ value: "Structural Engineering - PhD", label: "Structural Engineering - PhD" },
	{ value: "Criminology and Sociology BA (Hons)", label: "Criminology and Sociology BA (Hons)" },
	{ value: "Psychology with Forensic and Investigative Psychology BSc (Hons)", label: "Psychology with Forensic and Investigative Psychology BSc (Hons)" },
	{ value: "Environment and Business - MSc", label: "Environment and Business - MSc" },
	{ value: "Law - PhD", label: "Law - PhD" },
	{ value: "Strength and Conditioning BSc (Hons)", label: "Strength and Conditioning BSc (Hons)" },
	{ value: "Masters in Ethical Hacking and Cyber Security", label: "Masters in Ethical Hacking and Cyber Security" },
	{ value: "Sport and development, exercise and physical activity - MPhil", label: "Sport and development, exercise and physical activity - MPhil" },
	{ value: "Sustainability assessment & decision making and environmental management - PhD", label: "Sustainability assessment & decision making and environmental management - PhD" },
	{ value: "Criminology BA (Hons)", label: "Criminology BA (Hons)" },
	{ value: "Civil and Environmental Engineering MEng (Hons)", label: "Civil and Environmental Engineering MEng (Hons)" },
	{ value: "Mental Health Nursing BSc (Hons)", label: "Mental Health Nursing BSc (Hons)" },
	{ value: "Sport and Management BA(Hons)", label: "Sport and Management BA(Hons)" },
	{ value: "Sustainable urban drainage systems (SUDS) - MSc by Research", label: "Sustainable urban drainage systems (SUDS) - MSc by Research" },
	{ value: "Impact of contaminants on metabolic health -PhD", label: "Impact of contaminants on metabolic health -PhD" },
	{ value: "Psychology and Counselling BSc (Hons)", label: "Psychology and Counselling BSc (Hons)" },
	{ value: "Impact of contaminants on metabolic health - MPhil", label: "Impact of contaminants on metabolic health - MPhil" },
	{ value: "Sustainable urban drainage systems (SUDS) - MPhil", label: "Sustainable urban drainage systems (SUDS) - MPhil" },
	{ value: "Human nutrition, dietary patterns and physical activity - PhD", label: "Human nutrition, dietary patterns and physical activity - PhD" },
	{ value: "Psychology with Forensic Scene Investigation BSc (Hons)", label: "Psychology with Forensic Scene Investigation BSc (Hons)" },
	{ value: "Sustainability assessment & decision making and environmental management - MSc by Research", label: "Sustainability assessment & decision making and environmental management - MSc by Research" },
	{ value: "Fitness, Nutrition and Health BSc (Hons)", label: "Fitness, Nutrition and Health BSc (Hons)" },
	{ value: "Computing BSc (Hons)", label: "Computing BSc (Hons)" },
	{ value: "Food Science, Nutrition and Wellbeing BSc (Hons)", label: "Food Science, Nutrition and Wellbeing BSc (Hons)" },
	{ value: "Accounting and Finance (CIMA Gateway) - MSc", label: "Accounting and Finance (CIMA Gateway) - MSc" },
	{ value: "Infertility and sperm function - MPhil", label: "Infertility and sperm function - MPhil" },
	{ value: "Sociology - PhD", label: "Sociology - PhD" },
	{ value: "Food and Consumer Science BSc (Hons)", label: "Food and Consumer Science BSc (Hons)" },
	{ value: "Food Quality and Safety with Industrial Practice - MSc", label: "Food Quality and Safety with Industrial Practice - MSc" },
	{ value: "Construction and Project Management - MSc", label: "Construction and Project Management - MSc" },
	{ value: "Sociology - MPhil", label: "Sociology - MPhil" },
	{ value: "Sports Development and Coaching BSc (Hons)", label: "Sports Development and Coaching BSc (Hons)" },
	{ value: "Structural Engineering - MPhil", label: "Structural Engineering - MPhil" },
	{ value: "Digital Marketing and Business Management BA (Hons)", label: "Digital Marketing and Business Management BA (Hons)" },
	{ value: "Psychology BSc (Hons)", label: "Psychology BSc (Hons)" },
	{ value: "Computer Game Applications Development BSc (Hons)", label: "Computer Game Applications Development BSc (Hons)" },
	{ value: "Infertility and sperm function - MSc by Research", label: "Infertility and sperm function - MSc by Research" },
	{ value: "Vulnerable groups in healthcare - MSc by Research", label: "Vulnerable groups in healthcare - MSc by Research" },
	{ value: "Sustainability assessment & decision making and environmental management - Mphil", label: "Sustainability assessment & decision making and environmental management - Mphil" },
	{ value: "Marketing - MPhil", label: "Marketing - MPhil" },
	{ value: "Marketing - PhD", label: "Marketing - PhD" },
	{ value: "Business Management with People Management BA (Hons)", label: "Business Management with People Management BA (Hons)" },
	{ value: "Accounting and Finance with Business Analytics BA (Hons)", label: "Accounting and Finance with Business Analytics BA (Hons)" },
	{ value: "Biomedical Science BSc (Hons)", label: "Biomedical Science BSc (Hons)" },
	{ value: "Water and Environmental Management - MSc", label: "Water and Environmental Management - MSc" },
	{ value: "Global MBA", label: "Global MBA" },
	{ value: "Structural Engineering & Experimental/Numerical modelling of complex systems - MSc by Research", label: "Structural Engineering & Experimental/Numerical modelling of complex systems - MSc by Research" },
	{ value: "Professional Masters in Games Development - MProf", label: "Professional Masters in Games Development - MProf" },
	{ value: "Business Management with Events Management BA (Hons)", label: "Business Management with Events Management BA (Hons)" },
	{ value: "Forensic Sciences BSc (Hons)", label: "Forensic Sciences BSc (Hons)" },
	{ value: "Human Resource Management - MSc", label: "Human Resource Management - MSc" },
	{ value: "Water-Energy-Food Nexus (Management) - PhD", label: "Water-Energy-Food Nexus (Management) - PhD" },
	{ value: "Sport and Exercise BSc (Hons)", label: "Sport and Exercise BSc (Hons)" },
	{ value: "Physical Activity and Health BSc (Hons)", label: "Physical Activity and Health BSc (Hons)" },
	{ value: "Marketing - MSc by Research", label: "Marketing - MSc by Research" },
	{ value: "Applied Artificial Intelligence & User Experience - MSc", label: "Applied Artificial Intelligence & User Experience - MSc" },
	{ value: "Structural Engineering & Experimental/Numerical modelling of complex systems - PhD", label: "Structural Engineering & Experimental/Numerical modelling of complex systems - PhD" },
	{ value: "Business Management with Law BA (Hons)", label: "Business Management with Law BA (Hons)" },
	{ value: "Marketing and Business Management BA (Hons)", label: "Marketing and Business Management BA (Hons)" },
	{ value: "Law - LLM by Research", label: "Law - LLM by Research" },
	{ value: "Structural Engineering & Experimental/Numerical modelling of complex systems - MPhil", label: "Structural Engineering & Experimental/Numerical modelling of complex systems - MPhil" },
	{ value: "Food Quality, Safety and Innovation - MSc", label: "Food Quality, Safety and Innovation - MSc" },
	{ value: "Immersive Experiences and Technologies - MSc by Research", label: "Immersive Experiences and Technologies - MSc by Research" },
	{ value: "Interactive Sound and Music - MSc by Research", label: "Interactive Sound and Music - MSc by Research" },
	{ value: "Law - MPhil", label: "Law - MPhil" },
	{ value: "Computer Games Technology BSc (Hons)", label: "Computer Games Technology BSc (Hons)" },
	{ value: "Performance and Play - PhD", label: "Performance and Play - PhD" },
	{ value: "Ethical Hacking BSc (Hons)", label: "Ethical Hacking BSc (Hons)" },
	{ value: "Environment and Energy - MSc", label: "Environment and Energy - MSc" },
	{ value: "Sport and development, exercise and physical activity - MSc by Research", label: "Sport and development, exercise and physical activity - MSc by Research" },
	{ value: "Mathematical approaches to evaluate health risks, behaviour, and interventions - PhD", label: "Mathematical approaches to evaluate health risks, behaviour, and interventions - PhD" },
	{ value: "Psychology with Development and Education BSc (Hons)", label: "Psychology with Development and Education BSc (Hons)" },
	{ value: "Water-Energy-Food Nexus (Management) - MSc by Research", label: "Water-Energy-Food Nexus (Management) - MSc by Research" },
	{ value: "Human nutrition, dietary patterns and physical activity - MPhil", label: "Human nutrition, dietary patterns and physical activity - MPhil" },
	{ value: "Cybersecurity BSc (Hons)", label: "Cybersecurity BSc (Hons)" },
	{ value: "Law LLB (Hons)", label: "Law LLB (Hons)" },
	{ value: "Civil and Environmental Engineering BEng (Hons)", label: "Civil and Environmental Engineering BEng (Hons)" },
	{ value: "Performance and Play - MSc by Research", label: "Performance and Play - MSc by Research" },
	{ value: "Immersive Experiences and Technologies - PhD", label: "Immersive Experiences and Technologies - PhD" },
	{ value: "Computer Arts BA (Hons)", label: "Computer Arts BA (Hons)" },
	{ value: "Impact of contaminants on metabolic health - MSc by Research", label: "Impact of contaminants on metabolic health - MSc by Research" },
	{ value: "Digital Marketing with Events Management BA (Hons)", label: "Digital Marketing with Events Management BA (Hons)" },
	{ value: "Technical Art and Visual Effects - MA", label: "Technical Art and Visual Effects - MA" },
	{ value: "Fintech - MSc", label: "Fintech - MSc" },
	{ value: "Water-Energy-Food Nexus (Management) - MPhil", label: "Water-Energy-Food Nexus (Management) - MPhil" },
	{ value: "Business Management with Analytics BA (Hons)", label: "Business Management with Analytics BA (Hons)" },
	{ value: "Game Design and Production BA (Hons)", label: "Game Design and Production BA (Hons)" },
	{ value: "Civil Engineering - MSc", label: "Civil Engineering - MSc" },
	{ value: "Mathematical approaches to evaluate health risks, behaviour, and interventions - MPhil", label: "Mathematical approaches to evaluate health risks, behaviour, and interventions - MPhil" },
	{ value: "Business Management BA (Hons)", label: "Business Management BA (Hons)" },
	{ value: "Accounting and Finance with People Management BA (Hons)", label: "Accounting and Finance with People Management BA (Hons)" },
	{ value: "Sustainable urban drainage systems (SUDS) - PhD", label: "Sustainable urban drainage systems (SUDS) - PhD" },
	{ value: "Digital Marketing and e-Business (MSc)", label: "Digital Marketing and e-Business (MSc)" },
	{ value: "Structural Engineering - MSc by Research", label: "Structural Engineering - MSc by Research" },
	{ value: "Interactive Sound and Music - PhD", label: "Interactive Sound and Music - PhD" },
	{ value: "Accounting and Finance with Law BA (Hons)", label: "Accounting and Finance with Law BA (Hons)" },
	{ value: "International Human Resource Management - MSc", label: "International Human Resource Management - MSc" },
	{ value: "Accounting and Finance BA (Hons)", label: "Accounting and Finance BA (Hons)" },
	{ value: "Immersive Experiences and Technologies - MPhil", label: "Immersive Experiences and Technologies - MPhil" },
	{ value: "Food, Nutrition and Health BSc (Hons)", label: "Food, Nutrition and Health BSc (Hons)" },
	{ value: "Sociology - MA by Research", label: "Sociology - MA by Research" },
	{ value: "Performance and Play - MPhil", label: "Performance and Play - MPhil" },
	{ value: "Infertility and sperm function - PhD", label: "Infertility and sperm function - PhD" },
	{ value: "Vulnerable groups in healthcare - PhD", label: "Vulnerable groups in healthcare - PhD" },
	{ value: "Criminology and Policing BA (Hons)", label: "Criminology and Policing BA (Hons)" },
	{ value: "Sport and Excercise Science BSc (Hons)", label: "Sport and Excercise Science BSc (Hons)" },
	{ value: "Human nutrition, dietary patterns and physical activity - MSc by Research", label: "Human nutrition, dietary patterns and physical activity - MSc by Research" },
	{ value: "Mathematical approaches to evaluate health risks, behaviour, and interventions - MSc by Research", label: "Mathematical approaches to evaluate health risks, behaviour, and interventions - MSc by Research" },
	{ value: "Computer Games Technology - MSc", label: "Computer Games Technology - MSc" },
	{ value: "Sport and development, exercise and physical activity - PhD", label: "Sport and development, exercise and physical activity - PhD" }
];

export default AbertayUniversityCourses;