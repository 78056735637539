// colors.js
const colors = {
	aliceBlue: '#f0f8ff',
	primary: '#072f86',
	secondary: '#6c757d',
	lightBg: '#F5F5F5',
	lightGray: '#f0f0f0',
	darkGray: '#939393',
	success: '#28a745',
	danger: '#dc3545',
	warning: '#ffc107',
	info: '#17a2b8',
	light: '#f8f9fa',
	dark: '#343a40',
};

export default colors;
