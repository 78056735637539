export const countryOptions = [
	{ value: 'Australia', label: 'Australia' },
	{ value: 'Canada', label: 'Canada' },
	{ value: 'France', label: 'France' },
	{ value: 'Germany', label: 'Germany' },
	{ value: 'Ireland', label: 'Ireland' },
	{ value: 'Latvia', label: 'Latvia' },
	{ value: 'New Zealand', label: 'New Zealand' },
	{ value: 'Slovenia', label: 'Slovenia' },
	{ value: 'UK', label: 'UK' },
];
