import AustraliaCourses from './courses/AustraliaCourses';
import CanadaCourses from './courses/CanadaCourses';
import NewzealandCourses from './courses/NewzealandCourses';
import UkCourses from './courses/UKcourses';
import USACourses from './courses/USACourses';

const coursesByCountry = {
	Australia: AustraliaCourses,
	Canada: CanadaCourses,
	Newzealand: NewzealandCourses,
	Uk: UkCourses,
	USA: USACourses,
};

export default coursesByCountry;
