import React from 'react';
import { Box } from '@chakra-ui/react';

export const CardFull = ({ children }) => {
	return (
		<Box
			p={5}
			mt={5}
			mb={5}
			boxShadow="md"
			borderWidth="1px"
			borderRadius="lg"
			bg="white"
			width="100%"
		>
			{children}
		</Box>
	);
};

export default CardFull;
