const SkemaBusinessCourses = [
	{ value: "BBA Global Management", label: "BBA Global Management" },
	{ value: "Master in Management", label: "Master in Management" },
	{ value: "MSc in Auditing, Management Accounting & Information Systems - 1 year", label: "MSc in Auditing, Management Accounting & Information Systems - 1 year" },
	{ value: "MSc in Auditing, Management Accounting & Information Systems - 2 years", label: "MSc in Auditing, Management Accounting & Information Systems - 2 years" },
	{ value: "MSc in International Marketing & Business Development - 1 Year", label: "MSc in International Marketing & Business Development - 1 Year" },
	{ value: "MSc in International Marketing & Business Development - 2 Years", label: "MSc in International Marketing & Business Development - 2 Years" },
	{ value: "MSc in International Human Resource & Performance Management - 1 year", label: "MSc in International Human Resource & Performance Management - 1 year" },
	{ value: "MSc in International Human Resource & Performance Management - 2 years", label: "MSc in International Human Resource & Performance Management - 2 years" },
	{ value: "MSc in Financial Market & Investments - 1 Year", label: "MSc in Financial Market & Investments - 1 Year" },
	{ value: "MSc in Financial Market & Investments - 2 Years", label: "MSc in Financial Market & Investments - 2 Years" },
	{ value: "MSc in Luxury Hospitality and Innovation - 1 Year", label: "MSc in Luxury Hospitality and Innovation - 1 Year" },
	{ value: "MSc in Luxury Hospitality and Innovation - 2 Years", label: "MSc in Luxury Hospitality and Innovation - 2 Years" },
	{ value: "MSc in Luxury & Fashion Management - 1 Year", label: "MSc in Luxury & Fashion Management - 1 Year" },
	{ value: "MSc in Luxury & Fashion Management - 2 Years", label: "MSc in Luxury & Fashion Management - 2 Years" },
	{ value: "MSc in International Business - 1 Years", label: "MSc in International Business - 1 Years" },
	{ value: "MSc in International Business - 2 Years", label: "MSc in International Business - 2 Years" },
	{ value: "MSc in Entrepreneurship & Innovation - 1 year", label: "MSc in Entrepreneurship & Innovation - 1 year" },
	{ value: "MSc in Entrepreneurship & Innovation - 2 Years", label: "MSc in Entrepreneurship & Innovation - 2 Years" },
	{ value: "MSc Business Consulting & Digital Transformation - 1 Year", label: "MSc Business Consulting & Digital Transformation - 1 Year" },
	{ value: "MSc Business Consulting & Digital Transformation - 2 Years", label: "MSc Business Consulting & Digital Transformation - 2 Years" },
	{ value: "MSc in Project and Programme Management & Business Development - 1 Year", label: "MSc in Project and Programme Management & Business Development - 1 Year" },
	{ value: "MSc in Project and Programme Management & Business Development - 2 Years", label: "MSc in Project and Programme Management & Business Development - 2 Years" },
	{ value: "MSc in Corporate Financial Management - 1 Year", label: "MSc in Corporate Financial Management - 1 Year" },
	{ value: "MSc in Corporate Financial Management - 2 years", label: "MSc in Corporate Financial Management - 2 years" },
	{ value: "MSc in Strategic Event Management & Tourism Management - 1 year", label: "MSc in Strategic Event Management & Tourism Management - 1 year" },
	{ value: "MSc in Strategic Event Management & Tourism Management - 2 years", label: "MSc in Strategic Event Management & Tourism Management - 2 years" },
	{ value: "MSc in Digital Marketing - 1 Year", label: "MSc in Digital Marketing - 1 Year" },
	{ value: "MSc in Digital Marketing - 2 Years", label: "MSc in Digital Marketing - 2 Years" },
	{ value: "Msc in Global Supply Chain Management & Procurement - 1 Year", label: "Msc in Global Supply Chain Management & Procurement - 1 Year" },
	{ value: "Msc in Global Supply Chain Management & Procurement - 2 Years", label: "Msc in Global Supply Chain Management & Procurement - 2 Years" },
	{ value: "MSc in Digital Business and Artificial Intelligence - 1 Year", label: "MSc in Digital Business and Artificial Intelligence - 1 Year" },
	{ value: "MSc in Digital Business and Artificial Intelligence - 2 years", label: "MSc in Digital Business and Artificial Intelligence - 2 years" },
	{ value: "MSc Artificial Intelligence for Business Transformation - 1 year", label: "MSc Artificial Intelligence for Business Transformation - 1 year" },
	{ value: "MSc Artificial Intelligence for Business Transformation - 2 Years", label: "MSc Artificial Intelligence for Business Transformation - 2 Years" },
	{ value: "MSc in Sustainable Finance & Fintech - 1 year", label: "MSc in Sustainable Finance & Fintech - 1 year" },
	{ value: "MSc in Sustainable Finance & Fintech - 2 years", label: "MSc in Sustainable Finance & Fintech - 2 years" }
];

export default SkemaBusinessCourses;