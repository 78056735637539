const UniEuropeAppliedSciencesCourses = [
	{ label: "Business & Management Studies BSc", value: "Business & Management Studies BSc" },
	{ label: "Digital Business & Data Science BSc", value: "Digital Business & Data Science BSc" },
	{ label: "Sport & Event Management BSc", value: "Sport & Event Management BSc" },
	{ label: "Corporate Management MSc -3 Yr", value: "Corporate Management MSc -3 Yr" },
	{ label: "Finance & Management MSc", value: "Finance & Management MSc" },
	{ label: "Communication Design Bachelor of Arts", value: "Communication Design Bachelor of Arts" },
	{ label: "Film + Motion Design Bachelor of Arts", value: "Film + Motion Design Bachelor of Arts" },
	{ label: "Photography Bachelor of Arts", value: "Photography Bachelor of Arts" },
	{ label: "Game Design Bachelor of Arts", value: "Game Design Bachelor of Arts" },
	{ label: "Illustration Bachelor of Arts", value: "Illustration Bachelor of Arts" },
	{ label: "Corporate Management MSc -4 Yr", value: "Corporate Management MSc -4 Yr" },
	{ label: "Digital Media & Marketing BSc", value: "Digital Media & Marketing BSc" },
	{ label: "Dual Study Program Sports &Event Management at ALBA", value: "Dual Study Program Sports &Event Management at ALBA" },
	{ label: "Football Management (Specialisation)", value: "Football Management (Specialisation)" },
	{ label: "ESports Studies (Specialisation)", value: "ESports Studies (Specialisation)" },
	{ label: "Basketball (Specialisation)", value: "Basketball (Specialisation)" },
	{ label: "Software Engineering - BSc", value: "Software Engineering - BSc" },
	{ label: "Data Science MSc", value: "Data Science MSc" },
	{ label: "Software Engineering MSc -1 yr", value: "Software Engineering MSc -1 yr" },
	{ label: "Software Engineering MSc", value: "Software Engineering MSc" },
	{ label: "Summer School - Certificate in Digital Marketing", value: "Summer School - Certificate in Digital Marketing" },
	{ label: "Summer School - Certificate in Sports Mangement", value: "Summer School - Certificate in Sports Mangement" },
	{ label: "Summer School - Certificatte in Portrait and Fashion Photography", value: "Summer School - Certificatte in Portrait and Fashion Photography" },
	{ label: "UX/UI Design Bachelor of Arts (BA)", value: "UX/UI Design Bachelor of Arts (BA)" },
	{ label: "Digital Product Management Bachelor of Arts", value: "Digital Product Management Bachelor of Arts" },
	{ label: "BA in Communication Design Top Up", value: "BA in Communication Design Top Up" },
	{ label: "Master of Arts (MA) in Photography", value: "Master of Arts (MA) in Photography" },
	{ label: "Media Spaces Master of Arts", value: "Media Spaces Master of Arts" },
	{ label: "Innovation Design Management Master of Arts", value: "Innovation Design Management Master of Arts" },
	{ label: "Visual & Experience Design MA", value: "Visual & Experience Design MA" },
	{ label: "Creative Computing Master of Arts", value: "Creative Computing Master of Arts" },
	{ label: "Marketinng Mangement and PR (MA)", value: "Marketinng Mangement and PR (MA)" },
	{ label: "Psychology, BSC", value: "Psychology, BSC" },
	{ label: "Dual Degree in Business Administration and Taxation", value: "Dual Degree in Business Administration and Taxation" },
	{ label: "Master of Business Administration", value: "Master of Business Administration" },
	{ label: "Smart City Management MA", value: "Smart City Management MA" },
	{ label: "International Sport & Event Management MA", value: "International Sport & Event Management MA" },
	{ label: "Business course (W - Course)", value: "Business course (W - Course)" },
	{ label: "Technical Course (T - course)", value: "Technical Course (T - course)" }
];

export default UniEuropeAppliedSciencesCourses;