import React from 'react';

function Footer() {
	return (
		<footer style={{ padding: '0.8em' }}>
			<p style={{ marginBottom: '0', fontSize: '12px' }}>© Copyright 2024 - GSC PORTAL. Powered by <a target="_blank" rel="noreferrer" href={process.env.REACT_APP_POWERED_URL}><u>Techbelief</u></a></p>
		</footer >
	);
}

export default Footer;
