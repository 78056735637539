const CutAboveAcademyCourses = [
	{ value: "Certificate in Salon Skills Level 2", label: "Certificate in Salon Skills Level 2" },
	{ value: "Certificate in Hairdressing (Salon Support) Level 3", label: "Certificate in Hairdressing (Salon Support) Level 3" },
	{ value: "Certificate in Commercial Barbering Level 4", label: "Certificate in Commercial Barbering Level 4" },
	{ value: "Certificate in Hairdressing (Emerging Stylist) - Level 4", label: "Certificate in Hairdressing (Emerging Stylist) - Level 4" },
	{ value: "Certificate in Hairdressing (Salon Support) Level 3 + Certificate in Hairdressing (Emerging Stylist) Level 4", label: "Certificate in Hairdressing (Salon Support) Level 3 + Certificate in Hairdressing (Emerging Stylist) Level 4" },
	{ value: "Certificate in Makeup Artistry Level 4", label: "Certificate in Makeup Artistry Level 4" },
	{ value: "Diploma in Special FX & Prosthetic Makeup Artistry Level 5", label: "Diploma in Special FX & Prosthetic Makeup Artistry Level 5" }
];

export default CutAboveAcademyCourses;