import React, { useEffect } from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import { useAuth } from '../../utils/AuthContext';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Box, Flex, Image, IconButton, Slide, VStack } from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { useDisclosure, useBreakpointValue } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
// import Logo from '../../../../public/logo.png';
import NotificationComponent from '../../notifications/NotificationComponent';
import Navigation from "./Navigation"; // Import Navigation

const logoUrl = `${process.env.PUBLIC_URL}/logo.png`;

function Breadcrumbs() {
	const matchResetPassword = useMatch('/reset-password/:token');
	const location = useLocation();
	const pathnames = location.pathname.split('/').filter(x => x);

	// Exclude breadcrumb for Dashboard page
	if (location.pathname === '/' || location.pathname === '/dashboard' || location.pathname === '/registration' || location.pathname === '/login' || matchResetPassword) return null;

	return (
		<Breadcrumb separator=">" color="black">
			<BreadcrumbItem>
				<BreadcrumbLink as={NavLink} to="/dashboard" color="black" _hover={{ textDecor: 'none', color: 'blue.500' }}>
					Home
				</BreadcrumbLink>
			</BreadcrumbItem>
			{pathnames.map((name, index) => {
				const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
				const isLast = index === pathnames.length - 1;
				return (
					<BreadcrumbItem key={index} isCurrentPage={isLast}>
						<BreadcrumbLink
							as={NavLink}
							to={routeTo}
							color={isLast ? 'blue.500' : "black"} // Active page is blue, others are black
							_hover={{ textDecor: 'none', color: 'blue.600' }} // Slightly darker blue on hover
						>
							{name.charAt(0).toUpperCase() + name.slice(1)}
						</BreadcrumbLink>
					</BreadcrumbItem>
				);
			})}
		</Breadcrumb>
	);
}

function Header() {
	const { isOpen, onToggle, onClose } = useDisclosure();

	const { isLoggedIn } = useAuth();
	const location = useLocation();

	useEffect(() => {
		// Close the menu when the location changes
		onClose();
	}, [location.pathname, onClose]);

	const justifyValue = useBreakpointValue({ base: 'flex-start', md: 'space-between' });
	const logoMargin = useBreakpointValue({ base: '2', md: '10' });

	return (
		<>
			<Box bg="#072f86" p={2}>
				<Flex justify={justifyValue} align="center">
					<NavLink to={isLoggedIn ? "/dashboard" : "/"}>
						<Image src={logoUrl} boxSize="50px" ml={logoMargin} alt="Logo" />
					</NavLink>

					<IconButton
						icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
						onClick={onToggle}
						display={{ md: 'none' }}
						aria-label="Open Menu"
						zIndex={20}
						position="relative"
						color="black"
						marginLeft="auto"
					/>


					{/* Slide animation for mobile menu */}
					<Slide direction="top" in={isOpen} style={{ zIndex: 10 }}>
						<Box
							p={4}
							color="white"
							bg="gray.200"
							shadow="md"
							display={{ base: 'block', md: 'none' }}
							style={{ marginTop: isOpen ? '65px' : '0' }}
						>
							<VStack align="stretch" spacing={4}>
								<Navigation isMobile={true} />
							</VStack>
						</Box>
					</Slide>

					{/* Static box for larger screens */}
					<Box
						display={{ base: 'none', md: 'block' }}
						flexBasis={{ base: '100%', md: 'auto' }}
					>
						<Navigation />
					</Box>
					<NotificationComponent />
				</Flex>
			</Box>
			<Box mt="4">
				<Breadcrumbs />
			</Box>
		</>
	);
}

export default Header;
