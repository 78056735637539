const UniversityVictoriaCourses = [
	{ value: 'Bachelor of Science in Biochemistry (Optional Co-op)', label: 'Bachelor of Science in Biochemistry (Optional Co-op)' },
	{ value: 'Bachelor of Science in Microbiology (Optional Co-op)', label: 'Bachelor of Science in Microbiology (Optional Co-op)' },
	{ value: 'BSc in Computer Science and Mathematics (Optional Co-op)', label: 'BSc in Computer Science and Mathematics (Optional Co-op)' },
	{ value: 'Bachelor of Arts in Psychology', label: 'Bachelor of Arts in Psychology' },
	{ value: 'Bachelor of Arts in Economic', label: 'Bachelor of Arts in Economic' },
	{ value: 'Bachelor of Science in Physics', label: 'Bachelor of Science in Physics' },
	{ value: 'BSc in Psychology and Computer Science', label: 'BSc in Psychology and Computer Science' },
	{ value: 'Bachelor of Science (BSc) in Psychology', label: 'Bachelor of Science (BSc) in Psychology' },
	{ value: 'Bachelor of Social Sciences ( Undeclared )', label: 'Bachelor of Social Sciences ( Undeclared )' },
	{ value: 'Bachelor of Science (BSc) in Chemistry', label: 'Bachelor of Science (BSc) in Chemistry' }
];

export default UniversityVictoriaCourses;
