const AbertayGermanyCourses = [
	{ value: "BA (Hons) Business Management- Human Resource Management", label: "BA (Hons) Business Management- Human Resource Management" },
	{ value: "BA (Hons) Business Management - Marketing", label: "BA (Hons) Business Management - Marketing" },
	{ value: "BA (Hons) Business Management - Tourism", label: "BA (Hons) Business Management - Tourism" },
	{ value: "BSc (Hons) Health and care Management", label: "BSc (Hons) Health and care Management" },
	{ value: "BSc (Hons) Accounting and Finance", label: "BSc (Hons) Accounting and Finance" },
	{ value: "BA (Hons) Business Management with Foundation Year", label: "BA (Hons) Business Management with Foundation Year" },
	{ value: "BA (Hons) Business Management- Marketing with Foundation Year", label: "BA (Hons) Business Management- Marketing with Foundation Year" },
	{ value: "BA (Hons) Business Management -Tourism with Foundation Year", label: "BA (Hons) Business Management -Tourism with Foundation Year" },
	{ value: "BSc (Hons) Accounting and Finance with Foundation Year", label: "BSc (Hons) Accounting and Finance with Foundation Year" },
	{ value: "BSc (Hons) Computing with Foundation Year", label: "BSc (Hons) Computing with Foundation Year" },
	{ value: "BA (Hons) Business Management", label: "BA (Hons) Business Management" },
	{ value: "BSc (Hons) Computing", label: "BSc (Hons) Computing" },
	{ value: "BA (Hons) Business Management -Human resourse management with Foundation Year", label: "BA (Hons) Business Management -Human resourse management with Foundation Year" },
	{ value: "BSc (Hons) Health and care Management with Foundation Year", label: "BSc (Hons) Health and care Management with Foundation Year" },
	{ value: "Executive MBA", label: "Executive MBA" },
	{ value: "Master of Business Administration", label: "Master of Business Administration" },
	{ value: "MSc in Supply Chain Management and Logistics", label: "MSc in Supply Chain Management and Logistics" },
	{ value: "MSc Strategic Digital Marketing", label: "MSc Strategic Digital Marketing" },
	{ value: "MSc Data Analytics and Information Systems Management", label: "MSc Data Analytics and Information Systems Management" },
	{ value: "MSc Engineering Management", label: "MSc Engineering Management" },
	{ value: "MSc IT Security Management", label: "MSc IT Security Management" },
	{ value: "MSc Project Management", label: "MSc Project Management" }
];

export default AbertayGermanyCourses;