const EUBusinessCourses = [
	{ value: "International Business BSc (Hons)", label: "International Business BSc (Hons)" },
	{ value: "International Business BSc (Hons) with Leisure & Tourism Management (Minor)", label: "International Business BSc (Hons) with Leisure & Tourism Management (Minor)" },
	{ value: "International Business BSc (Hons) with International Relations (Minor)", label: "International Business BSc (Hons) with International Relations (Minor)" },
	{ value: "International Business BSc (Hons) with Sports Management (Minor)", label: "International Business BSc (Hons) with Sports Management (Minor)" },
	{ value: "International Business BSc (Hons) with Digital Business, Design & Innovation (Minor)", label: "International Business BSc (Hons) with Digital Business, Design & Innovation (Minor)" },
	{ value: "BA (Hons) in Business Management", label: "BA (Hons) in Business Management" },
	{ value: "International Business BSc (Hons) with Business Finance (Minor)", label: "International Business BSc (Hons) with Business Finance (Minor)" },
	{ value: "BA (Hons) in Business Management - Marketing", label: "BA (Hons) in Business Management - Marketing" },
	{ value: "BA (Hons) in Business Management - Finance", label: "BA (Hons) in Business Management - Finance" },
	{ value: "BA (Hons) in Business Management - Enterprise", label: "BA (Hons) in Business Management - Enterprise" },
	{ value: "BA (Hons) in Business Management - Human Resources Management", label: "BA (Hons) in Business Management - Human Resources Management" },
	{ value: "MSc in International Management", label: "MSc in International Management" },
	{ value: "MSc in International Management - International Business (Majors)", label: "MSc in International Management - International Business (Majors)" },
	{ value: "MSc in International Management - Communication & Public Relations (Majors)", label: "MSc in International Management - Communication & Public Relations (Majors)" },
	{ value: "MSc in International Management - International Marketing (Majors)", label: "MSc in International Management - International Marketing (Majors)" },
	{ value: "MSc in International Management - Global Banking & Finance (Majors)", label: "MSc in International Management - Global Banking & Finance (Majors)" },
	{ value: "MSc in International Management - Entreprenuership (Majors)", label: "MSc in International Management - Entreprenuership (Majors)" },
	{ value: "MSc in International Management - Digital Business (Majors)", label: "MSc in International Management - Digital Business (Majors)" },
	{ value: "MSc in International Management - Sports Management (Majors)", label: "MSc in International Management - Sports Management (Majors)" },
	{ value: "MSc in International Management - Human Resources Management (Majors)", label: "MSc in International Management - Human Resources Management (Majors)" },
	{ value: "MSc in International Management - Design Management (Majors)", label: "MSc in International Management - Design Management (Majors)" },
	{ value: "MSc in International Management - Blockchain Management (Majors)", label: "MSc in International Management - Blockchain Management (Majors)" },
	{ value: "Master of Business Administration - Blockchain Management (MBA)", label: "Master of Business Administration - Blockchain Management (MBA)" },
	{ value: "Master of Business Administration - Design Management (MBA)", label: "Master of Business Administration - Design Management (MBA)" },
	{ value: "Master of Business Administration - International Business (MBA)", label: "Master of Business Administration - International Business (MBA)" },
	{ value: "Master of Business Administration - Communication & Public Relations (MBA)", label: "Master of Business Administration - Communication & Public Relations (MBA)" },
	{ value: "Master of Business Administration - International Marketing (MBA)", label: "Master of Business Administration - International Marketing (MBA)" },
	{ value: "Master of Business Administration - Global Banking & Fiinance (MBA)", label: "Master of Business Administration - Global Banking & Fiinance (MBA)" },
	{ value: "Master of Business Administration - Leisure & Tourism Management (MBA)", label: "Master of Business Administration - Leisure & Tourism Management (MBA)" },
	{ value: "Master of Business Administration - Enterprenuership (MBA)", label: "Master of Business Administration - Enterprenuership (MBA)" },
	{ value: "Master of Business Administration - Digital Business (MBA)", label: "Master of Business Administration - Digital Business (MBA)" },
	{ value: "Master of Business Administration - Sports Management (MBA)", label: "Master of Business Administration - Sports Management (MBA)" },
	{ value: "Master of Business Administration - Human Resources Management (MBA)", label: "Master of Business Administration - Human Resources Management (MBA)" },
	{ value: "International Business BSc (Hons) with Communication & Public Relations (Minor)", label: "International Business BSc (Hons) with Communication & Public Relations (Minor)" },
	{ value: "MSc in International Management - Leisure & Tourism Management (Majors)", label: "MSc in International Management - Leisure & Tourism Management (Majors)" }
];

export default EUBusinessCourses;