const LaTrobeCourses = [
	{ value: "Bachelor of Accounting", label: "Bachelor of Accounting" },
	{ value: "Bachelor of International Business", label: "Bachelor of International Business" },
	{ value: "Bachelor of Information Technology", label: "Bachelor of Information Technology" },
	{ value: "Global Master of Business Administration", label: "Global Master of Business Administration" },
	{ value: "Bachelor of Commerce/Bachelor of Health Sciences", label: "Bachelor of Commerce/Bachelor of Health Sciences" },
	{ value: "Master of Strength and Conditioning", label: "Master of Strength and Conditioning" },
	{ value: "Bachelor of Health Sciences (Medical Classification)/Bachelor of Health Information Management", label: "Bachelor of Health Sciences (Medical Classification)/Bachelor of Health Information Management" },
	{ value: "Master of Management", label: "Master of Management" },
	{ value: "Bachelor of Arts/Bachelor of Health Sciences", label: "Bachelor of Arts/Bachelor of Health Sciences" },
	{ value: "Bachelor of Arts/Bachelor of Science", label: "Bachelor of Arts/Bachelor of Science" },
	{ value: "Bachelor of Outdoor Education", label: "Bachelor of Outdoor Education" },
	{ value: "Master of Archaeology", label: "Master of Archaeology" },
	{ value: "Master of Financial Analysis and Master of Business Administration", label: "Master of Financial Analysis and Master of Business Administration" },
	{ value: "Master of Quantum Information Technology", label: "Master of Quantum Information Technology" },
	{ value: "Bachelor of Prosthetics and Orthotics (Honours)", label: "Bachelor of Prosthetics and Orthotics (Honours)" },
	{ value: "Bachelor of Media and Communication", label: "Bachelor of Media and Communication" },
	{ value: "Master of Engineering (Civil)", label: "Master of Engineering (Civil)" },
	{ value: "Master of Engineering (Electronics)", label: "Master of Engineering (Electronics)" },
	{ value: "Juris Doctor", label: "Juris Doctor" },
	{ value: "Bachelor of Human Nutrition", label: "Bachelor of Human Nutrition" },
	{ value: "Master of Education", label: "Master of Education" },
	{ value: "Bachelor of Laws/Bachelor of Politics, Philosophy and Economics", label: "Bachelor of Laws/Bachelor of Politics, Philosophy and Economics" },
	{ value: "Master of Telecommunication and Network Engineering", label: "Master of Telecommunication and Network Engineering" },
	{ value: "Bachelor of Electrical and Electronic Engineering (Honours)", label: "Bachelor of Electrical and Electronic Engineering (Honours)" },
	{ value: "Bachelor of Accounting (Management and Marketing)", label: "Bachelor of Accounting (Management and Marketing)" },
	{ value: "Bachelor of Accounting (International Business)", label: "Bachelor of Accounting (International Business)" },
	{ value: "Bachelor of Nursing/Bachelor of Psychological Science", label: "Bachelor of Nursing/Bachelor of Psychological Science" },
	{ value: "Master of Logistics and Supply chain Management", label: "Master of Logistics and Supply chain Management" },
	{ value: "Bachelor of Accounting (International Business)", label: "Bachelor of Accounting (International Business)" },
	{ value: "Bachelor of Nursing/Bachelor of Psychological Science", label: "Bachelor of Nursing/Bachelor of Psychological Science" },
	{ value: "Master of Logistics and Supply chain Management", label: "Master of Logistics and Supply chain Management" },
	{ value: "Master of Clinical Psychology", label: "Master of Clinical Psychology" },
	{ value: "Master of Professional Archaeology", label: "Master of Professional Archaeology" },
	{ value: "Master of Engineering Management", label: "Master of Engineering Management" },
	{ value: "Bachelor of Business (Marketing)", label: "Bachelor of Business (Marketing)" },
	{ value: "Master of Occupational Therapy Practice", label: "Master of Occupational Therapy Practice" },
	{ value: "Bachelor of Animal and Veterinary Biosciences", label: "Bachelor of Animal and Veterinary Biosciences" },
	{ value: "Bachelor of Biomedicine", label: "Bachelor of Biomedicine" },
	{ value: "Bachelor of Business (Human resources management)", label: "Bachelor of Business (Human resources management)" },
	{ value: "Bachelor of Accounting (Marketing)", label: "Bachelor of Accounting (Marketing)" },
	{ value: "Bachelor of Biomedical Science", label: "Bachelor of Biomedical Science" },
	{ value: "Master of International Business", label: "Master of International Business" },
	{ value: "Master of Extended Professional Accounting", label: "Master of Extended Professional Accounting" },
	{ value: "Master of Clinical Neuropsychology", label: "Master of Clinical Neuropsychology" },
	{ value: "Bachelor of Commerce/Bachelor of International Relations", label: "Bachelor of Commerce/Bachelor of International Relations" },
	{ value: "Bachelor of Finance", label: "Bachelor of Finance" },
	{ value: "Bachelor of Commerce/Bachelor of International Relations", label: "Bachelor of Commerce/Bachelor of International Relations" },
	{ value: "Bachelor of Humanities, Innovation and Technology", label: "Bachelor of Humanities, Innovation and Technology" },
	{ value: "Bachelor of Laws/Bachelor of Media and Communication", label: "Bachelor of Laws/Bachelor of Media and Communication" },
	{ value: "Bachelor of Commerce", label: "Bachelor of Commerce" },
	{ value: "Bachelor of Politics, Philosophy and Economics", label: "Bachelor of Politics, Philosophy and Economics" },
	{ value: "Bachelor of Laws/Bachelor of Arts", label: "Bachelor of Laws/Bachelor of Arts" },
	{ value: "Bachelor of Commerce/Bachelor of Computer Science", label: "Bachelor of Commerce/Bachelor of Computer Science" },
	{ value: "Master of Applied Linguistics", label: "Master of Applied Linguistics" },
	{ value: "Master of Financial Analysis and Master of International Business", label: "Master of Financial Analysis and Master of International Business" },
	{ value: "Master of Art Therapy", label: "Master of Art Therapy" },
	{ value: "Master of Community Planning and Development", label: "Master of Community Planning and Development" },
	{ value: "Master of Cybersecurity (Business Operations)", label: "Master of Cybersecurity (Business Operations)" },
	{ value: "Bachelor of Laws/Bachelor of Biomedicine", label: "Bachelor of Laws/Bachelor of Biomedicine" },
	{ value: "Master of Public Health and Master of Health Administration", label: "Master of Public Health and Master of Health Administration" },
	{ value: "Bachelor of Biological Sciences", label: "Bachelor of Biological Sciences" },
	{ value: "Bachelor of Business (Accounting)", label: "Bachelor of Business (Accounting)" },
	{ value: "Bachelor of Commerce/Bachelor of Laws", label: "Bachelor of Commerce/Bachelor of Laws" },
	{ value: "Bachelor of Commerce/Bachelor of Arts", label: "Bachelor of Commerce/Bachelor of Arts" },
	{ value: "Master of Professional Accounting", label: "Master of Professional Accounting" },
	{ value: "Master of Business Analytics", label: "Master of Business Analytics" },
	{ value: "Master of Professional Accounting", label: "Master of Professional Accounting" },
	{ value: "Bachelor of Information Technology", label: "Bachelor of Information Technology" },
	{ value: "Master of Health Information Management", label: "Master of Health Information Management" },
	{ value: "Bachelor of Accounting (Human Resource Management)", label: "Bachelor of Accounting (Human Resource Management)" },
	{ value: "Bachelor of Business (Accounting and Finance)", label: "Bachelor of Business (Accounting and Finance)" },
	{ value: "Bachelor of Exercise Science", label: "Bachelor of Exercise Science" },
	{ value: "Bachelor of Psychological Science", label: "Bachelor of Psychological Science" },
	{ value: "Bachelor of Business (Accounting)", label: "Bachelor of Business (Accounting)" },
	{ value: "Bachelor of Accounting (Economics)", label: "Bachelor of Accounting (Economics)" },
	{ value: "Bachelor of Biomedical Science", label: "Bachelor of Biomedical Science" },
	{ value: "Bachelor of Health Sciences", label: "Bachelor of Health Sciences" },
	{ value: "Bachelor of Oral Health Science", label: "Bachelor of Oral Health Science" },
	{ value: "Bachelor of Civil Engineering (Honours)", label: "Bachelor of Civil Engineering (Honours)" },
	{ value: "Bachelor of Commerce/Bachelor of Arts", label: "Bachelor of Commerce/Bachelor of Arts" },
	{ value: "Bachelor of Biomedicine", label: "Bachelor of Biomedicine" },
	{ value: "Bachelor of Food and Nutrition", label: "Bachelor of Food and Nutrition" },
	{ value: "Bachelor of Creative Arts", label: "Bachelor of Creative Arts" },
	{ value: "Bachelor of Criminology/Bachelor of Psychological Science", label: "Bachelor of Criminology/Bachelor of Psychological Science" },
	{ value: "Master of Financial Analysis", label: "Master of Financial Analysis" },
	{ value: "Master of Financial Analysis (Financial Risk Management)", label: "Master of Financial Analysis (Financial Risk Management)" },
	{ value: "Bachelor of Science", label: "Bachelor of Science" },
	{ value: "Bachelor of Cybersecurity/Bachelor of Commerce", label: "Bachelor of Cybersecurity/Bachelor of Commerce" },
	{ value: "Master of Business Information Management and Systems", label: "Master of Business Information Management and Systems" },
	{ value: "Bachelor of Laws (Graduate Entry)", label: "Bachelor of Laws (Graduate Entry)" },
	{ value: "Bachelor of Science (Wildlife and Conservation Biology)", label: "Bachelor of Science (Wildlife and Conservation Biology)" },
	{ value: "Bachelor of Arts", label: "Bachelor of Arts" },
	{ value: "Master of Physiotherapy Practice", label: "Master of Physiotherapy Practice" },
	{ value: "Master of Podiatric Practice", label: "Master of Podiatric Practice" },
	{ value: "Bachelor of Dietetics (Honours)", label: "Bachelor of Dietetics (Honours)" },
	{ value: "Bachelor of Accounting (Agribusiness)", label: "Bachelor of Accounting (Agribusiness)" },
	{ value: "Bachelor of Commerce/Bachelor of Health Sciences", label: "Bachelor of Commerce/Bachelor of Health Sciences" },
	{ value: "Master of Clinical Prosthetics and Orthotics", label: "Master of Clinical Prosthetics and Orthotics" },
	{ value: "Master of Health Administration", label: "Master of Health Administration" },
	{ value: "Master of Artificial Intelligence", label: "Master of Artificial Intelligence" },
	{ value: "Master of Business Analytics", label: "Master of Business Analytics" },
	{ value: "Master of Nursing", label: "Master of Nursing" },
	{ value: "Master of Cybersecurity (Computer Science)", label: "Master of Cybersecurity (Computer Science)" },
	{ value: "Master of Orthoptics", label: "Master of Orthoptics" },
	{ value: "Bachelor of Education (Secondary)", label: "Bachelor of Education (Secondary)" },
	{ value: "Bachelor of Business (Sport Development and Management)", label: "Bachelor of Business (Sport Development and Management)" },
	{ value: "Bachelor of Business Analytics", label: "Bachelor of Business Analytics" },
	{ value: "Bachelor of Veterinary Nursing", label: "Bachelor of Veterinary Nursing" },
	{ value: "Master of Information Technology (Computer Networks)", label: "Master of Information Technology (Computer Networks)" },
	{ value: "Bachelor of Accounting (Financial Management)", label: "Bachelor of Accounting (Financial Management)" },
	{ value: "Master of International Relations (Research Studies)", label: "Master of International Relations (Research Studies)" },
	{ value: "Master of Professional Accounting (Information Systems Management)", label: "Master of Professional Accounting (Information Systems Management)" },
	{ value: "Bachelor of Business (Management)", label: "Bachelor of Business (Management)" },
	{ value: "Postgraduate Enabling Program", label: "Postgraduate Enabling Program" },
	{ value: "Master of Strategic Communication", label: "Master of Strategic Communication" },
	{ value: "Bachelor of Business", label: "Bachelor of Business" },
	{ value: "Master of Teaching English to Speakers of Other Languages (TESOL)", label: "Master of Teaching English to Speakers of Other Languages (TESOL)" },
	{ value: "Master of Speech Pathology", label: "Master of Speech Pathology" },
	{ value: "Bachelor of Laws", label: "Bachelor of Laws" },
	{ value: "Bachelor of Business (Tourism and Hospitality)", label: "Bachelor of Business (Tourism and Hospitality)" },
	{ value: "Bachelor of Nursing (Pre-registration)", label: "Bachelor of Nursing (Pre-registration)" },
	{ value: "Master of Communication (Journalism Innovation)", label: "Master of Communication (Journalism Innovation)" },
	{ value: "Master of Health Sciences", label: "Master of Health Sciences" },
	{ value: "Master of Public Health", label: "Master of Public Health" },
	{ value: "Bachelor of Criminology", label: "Bachelor of Criminology" },
	{ value: "Bachelor of International Relations", label: "Bachelor of International Relations" },
	{ value: "Bachelor of Languages and Linguistics", label: "Bachelor of Languages and Linguistics" },
	{ value: "Master of Social Work", label: "Master of Social Work" },
	{ value: "Master of Dietetic Practice", label: "Master of Dietetic Practice" },
	{ value: "Bachelor of Biomedical Science", label: "Bachelor of Biomedical Science" },
	{ value: "Master of Data Science", label: "Master of Data Science" },
	{ value: "Master of Construction and Engineering Management", label: "Master of Construction and Engineering Management" },
	{ value: "Bachelor of Information Technology", label: "Bachelor of Information Technology" },
	{ value: "Master of Business Administration and Master of Health Administration", label: "Master of Business Administration and Master of Health Administration" },
	{ value: "Bachelor of Agricultural Sciences", label: "Bachelor of Agricultural Sciences" },
	{ value: "Master of Management (Project Management)", label: "Master of Management (Project Management)" },
	{ value: "Bachelor of Cybersecurity/Bachelor of Criminology", label: "Bachelor of Cybersecurity/Bachelor of Criminology" },
	{ value: "Bachelor of Cybersecurity/Bachelor of Psychological Science", label: "Bachelor of Cybersecurity/Bachelor of Psychological Science" },
	{ value: "Master of Sport Analytics", label: "Master of Sport Analytics" },
	{ value: "Bachelor of Politics, Philosophy and Economics", label: "Bachelor of Politics, Philosophy and Economics" },
	{ value: "Bachelor of Archaeology", label: "Bachelor of Archaeology" },
	{ value: "Bachelor of Urban, Rural and Environmental Planning", label: "Bachelor of Urban, Rural and Environmental Planning" },
	{ value: "Master of International Relations", label: "Master of International Relations" },
	{ value: "Master of Teaching (Primary)", label: "Master of Teaching (Primary)" },
	{ value: "Bachelor of Business (Sport Management)", label: "Bachelor of Business (Sport Management)" },
	{ value: "Master of Engineering (Manufacturing)", label: "Master of Engineering (Manufacturing)" },
	{ value: "Bachelor of Engineering Honours (Industrial)", label: "Bachelor of Engineering Honours (Industrial)" },
	{ value: "Bachelor of Exercise Science and Master of Exercise Physiology", label: "Bachelor of Exercise Science and Master of Exercise Physiology" },
	{ value: "Master of Digital Health", label: "Master of Digital Health" },
	{ value: "Bachelor of Cybersecurity", label: "Bachelor of Cybersecurity" },
	{ value: "Master of Information and Communication Technology", label: "Master of Information and Communication Technology" },
	{ value: "Master of Chemical Sciences", label: "Master of Chemical Sciences" },
	{ value: "Master of Artificial Intelligence", label: "Master of Artificial Intelligence" },
	{ value: "Master of Strategic Communication", label: "Master of Strategic Communication" },
	{ value: "Master of Biotechnology Management", label: "Master of Biotechnology Management" },
	{ value: "Master of Business Administration (Advanced)", label: "Master of Business Administration (Advanced)" },
	{ value: "Master of Educational Leadership and Management", label: "Master of Educational Leadership and Management" },
	{ value: "Bachelor of Commerce/Bachelor of Laws", label: "Bachelor of Commerce/Bachelor of Laws" },
	{ value: "Bachelor of Psychological Science", label: "Bachelor of Psychological Science" },
	{ value: "Bachelor of Arts/Bachelor of Health Sciences", label: "Bachelor of Arts/Bachelor of Health Sciences" },
	{ value: "Bachelor of Educational Studies", label: "Bachelor of Educational Studies" },
	{ value: "Bachelor of Business (Human Resource Management)", label: "Bachelor of Business (Human Resource Management)" },
	{ value: "Master of Rehabilitation Counselling and Mental Health", label: "Master of Rehabilitation Counselling and Mental Health" },
	{ value: "Master of Business Information Systems", label: "Master of Business Information Systems" },
	{ value: "Master of Accounting and Financial Management", label: "Master of Accounting and Financial Management" },
	{ value: "Master of Professional Accounting (Business Analytics)", label: "Master of Professional Accounting (Business Analytics)" },
	{ value: "Master of Teaching (Secondary)", label: "Master of Teaching (Secondary)" },
	{ value: "Master of Clinical Audiology", label: "Master of Clinical Audiology" },
	{ value: "Bachelor of Business (Marketing)", label: "Bachelor of Business (Marketing)" },
	{ value: "Bachelor of Accounting (Management)", label: "Bachelor of Accounting (Management)" },
	{ value: "Bachelor of Politics, Philosophy and Economics", label: "Bachelor of Politics, Philosophy and Economics" },
	{ value: "Bachelor of Accounting", label: "Bachelor of Accounting" },
	{ value: "Bachelor of Criminology/Bachelor of Laws", label: "Bachelor of Criminology/Bachelor of Laws" },
	{ value: "Bachelor of Business Information Systems", label: "Bachelor of Business Information Systems" },
	{ value: "Bachelor of Business/Bachelor of Arts", label: "Bachelor of Business/Bachelor of Arts" },
	{ value: "Bachelor of Commerce", label: "Bachelor of Commerce" },
	{ value: "Bachelor of Commerce/Bachelor of Biomedicine", label: "Bachelor of Commerce/Bachelor of Biomedicine" },
	{ value: "Bachelor of Commerce/Bachelor of Psychological Science", label: "Bachelor of Commerce/Bachelor of Psychological Science" },
	{ value: "Bachelor of International Business", label: "Bachelor of International Business" },
	{ value: "Bachelor of Laws/Bachelor of International Relations", label: "Bachelor of Laws/Bachelor of International Relations" },
	{ value: "Bachelor of Business (Financial management)", label: "Bachelor of Business (Financial management)" },
	{ value: "Bachelor of Podiatry (Honours)", label: "Bachelor of Podiatry (Honours)" },
	{ value: "Bachelor of Sport and Exercise Science", label: "Bachelor of Sport and Exercise Science" },
	{ value: "Bachelor of Laws/Bachelor of Science", label: "Bachelor of Laws/Bachelor of Science" },
	{ value: "Master of Social Work", label: "Master of Social Work" },
	{ value: "Master of Information Technology", label: "Master of Information Technology" },
	{ value: "Master of Biotechnology and Bioinformatics", label: "Master of Biotechnology and Bioinformatics" },
	{ value: "Bachelor of Commerce/Bachelor of Science", label: "Bachelor of Commerce/Bachelor of Science" },
	{ value: "Master of Data Science", label: "Master of Data Science" },
	{ value: "Bachelor of Early Childhood and Primary Education", label: "Bachelor of Early Childhood and Primary Education" },
	{ value: "Bachelor of Business (Event Management)", label: "Bachelor of Business (Event Management)" },
	{ value: "Bachelor of Commerce/Bachelor of Agricultural Sciences", label: "Bachelor of Commerce/Bachelor of Agricultural Sciences" },
	{ value: "Master of Marketing", label: "Master of Marketing" },
	{ value: "Bachelor of Business (Agribusiness)", label: "Bachelor of Business (Agribusiness)" },
	{ value: "Bachelor of Computer Science", label: "Bachelor of Computer Science" },
	{ value: "Bachelor of Dental Science (Honours)", label: "Bachelor of Dental Science (Honours)" },
	{ value: "Bachelor of Orthoptics (Honours)", label: "Bachelor of Orthoptics (Honours)" },
	{ value: "Bachelor of Business Information Systems", label: "Bachelor of Business Information Systems" },
	{ value: "Master of Financial Analysis (Investment)", label: "Master of Financial Analysis (Investment)" },
	{ value: "Bachelor of Digital Business", label: "Bachelor of Digital Business" },
	{ value: "Master of Business Administration", label: "Master of Business Administration" },
	{ value: "Master of Financial Analysis and Master of Professional Accounting", label: "Master of Financial Analysis and Master of Professional Accounting" },
	{ value: "Bachelor of Business (Event Management/Marketing)", label: "Bachelor of Business (Event Management/Marketing)" },
	{ value: "Master of Internet of Things", label: "Master of Internet of Things" },
	{ value: "Bachelor of Education (Primary)", label: "Bachelor of Education (Primary)" },
	{ value: "Bachelor of Physical, Health and Outdoor Education", label: "Bachelor of Physical, Health and Outdoor Education" },
	{ value: "Bachelor of Accounting (Sport Management)", label: "Bachelor of Accounting (Sport Management)" },
	{ value: "Master of Professional Accounting", label: "Master of Professional Accounting" },
	{ value: "Master of International Development", label: "Master of International Development" },
	{ value: "Master of International Business", label: "Master of International Business" }
];

export default LaTrobeCourses;