export const universityOptions = [
	// Australia
	{ value: 'Alana Kaye College Darwin', label: 'Alana Kaye College Darwin', country: 'Australia' },
	{ value: 'Alphacrucis University College Sydney', label: 'Alphacrucis University College Sydney', country: 'Australia' },
	{ value: 'Griffith University', label: 'Griffith University', country: 'Australia' },
	{ value: 'Griffith College', label: 'Griffith College', country: 'Australia' },
	{ value: 'Australian Ideal College', label: 'Australian Ideal College', country: 'Australia' },
	{ value: 'Charles Darwin International Pathway College', label: 'Charles Darwin International Pathway College', country: 'Australia' },
	{ value: 'Deakin University', label: 'Deakin University', country: 'Australia' },
	{ value: 'Flinders University', label: 'Flinders University', country: 'Australia' },
	{ value: 'James Cook University', label: 'James Cook University', country: 'Australia' },
	{ value: 'La Trobe University', label: 'La Trobe University', country: 'Australia' },
	{ value: 'Le Cordon Bleu Australia', label: 'Le Cordon Bleu Australia', country: 'Australia' },
	{ value: 'Lead College Pty Ltd', label: 'Lead College Pty Ltd', country: 'Australia' },
	{ value: 'Queensland University of Technology', label: 'Queensland University of Technology', country: 'Australia' },
	{ value: 'RMIT University, Melbourne', label: 'RMIT University, Melbourne', country: 'Australia' },

	// Canada
	{ value: 'University of Niagara Falls, Canada', label: 'University of Niagara Falls, Canada', country: 'Canada' },
	{ value: 'University Canada West', label: 'University Canada West', country: 'Canada' },
	{ value: 'Queens University, Canada', label: 'Queens University, Canada', country: 'Canada' },
	{ value: 'University of Victoria', label: 'University of Victoria', country: 'Canada' },
	{ value: 'University of Guelph', label: 'University of Guelph', country: 'Canada' },
	{ value: 'University of New Brunswick', label: 'University of New Brunswick', country: 'Canada' },
	{ value: 'University of Regina', label: 'University of Regina', country: 'Canada' },
	{ value: 'Lakehead University, Thunder Bay', label: 'Lakehead University, Thunder Bay', country: 'Canada' },
	{ value: 'Acadia University', label: 'Acadia University', country: 'Canada' },
	{ value: 'Bow Valley College, Calgary, Alberta', label: 'Bow Valley College, Calgary, Alberta', country: 'Canada' },
	{ value: 'Cambrian College', label: 'Cambrian College', country: 'Canada' },
	{ value: 'Cape Breton University', label: 'Cape Breton University', country: 'Canada' },
	{ value: 'Capilano University', label: 'Capilano University', country: 'Canada' },
	{ value: 'Conestoga College - Kitchener (Downtown) Campus', label: 'Conestoga College - Kitchener (Downtown) Campus', country: 'Canada' },
	{ value: 'Durham College', label: 'Durham College', country: 'Canada' },
	{ value: 'Fanshawe College', label: 'Fanshawe College', country: 'Canada' },
	{ value: 'George Brown College', label: 'George Brown College', country: 'Canada' },
	{ value: 'Lakeland College', label: 'Lakeland College', country: 'Canada' },
	{ value: 'University of Winnipeg', label: 'University of Winnipeg', country: 'Canada' },
	{ value: 'Sheridan College', label: 'Sheridan College', country: 'Canada' },

	// France
	{ value: 'Burgundy School of Business', label: 'Burgundy School of Business', country: 'France' },
	{ value: 'College de paris (Paris)', label: 'College de paris (Paris)', country: 'France' },
	{ value: 'College de Paris Ecole Conte (Paris)', label: 'College de Paris Ecole Conte (Paris)', country: 'France' },
	{ value: 'College De Paris ECEMA Business School (Lyon)', label: 'College De Paris ECEMA Business School (Lyon)', country: 'France' },
	{ value: 'CY Tech Grande Ecole of Sciences, Engineering, Economics and Management', label: 'CY Tech Grande Ecole of Sciences, Engineering, Economics and Management', country: 'France' },
	{ value: 'Ecole de Management Applique (EMA)', label: 'Ecole de Management Applique (EMA)', country: 'France' },
	{ value: 'EPITA- Graduate School of Computer Science', label: 'EPITA- Graduate School of Computer Science', country: 'France' },
	{ value: 'ESSCA School of Management', label: 'ESSCA School of Management', country: 'France' },
	{ value: 'IDRAC Business School', label: 'IDRAC Business School', country: 'France' },
	{ value: 'Le Cordon Bleu Paris', label: 'Le Cordon Bleu Paris', country: 'France' },
	{ value: 'Skema Business School', label: 'Skema Business School', country: 'France' },

	// Germany
	{ value: 'Abertay University', label: 'Abertay University', country: 'Germany' },
	{ value: 'EBS University', label: 'EBS University', country: 'Germany' },
	{ value: 'EU Business School Munich', label: 'EU Business School Munich', country: 'Germany' },
	{ value: 'GISMA Business School', label: 'GISMA Business School', country: 'Germany' },
	{ value: 'International School of Management (ISM)', label: 'International School of Management (ISM)', country: 'Germany' },
	{ value: 'IU International University of Applied Sciences', label: 'IU International University of Applied Sciences', country: 'Germany' },
	{ value: 'University of Europe for Applied Sciences', label: 'University of Europe for Applied Sciences', country: 'Germany' },
	{ value: 'SRH Berlin University of Applied Sciences', label: 'SRH Berlin University of Applied Sciences', country: 'Germany' },

	// Ireland
	{ value: 'American College Dublin, Dublin', label: 'American College Dublin, Dublin', country: 'Ireland' },
	{ value: 'Atlantic Technological University', label: 'Atlantic Technological University', country: 'Ireland' },
	{ value: 'Dublin Business School', label: 'Dublin Business School', country: 'Ireland' },
	{ value: 'Dundalk Institute of Technology, Dundalk', label: 'Dundalk Institute of Technology, Dundalk', country: 'Ireland' },
	{ value: 'Maynooth University', label: 'Maynooth University', country: 'Ireland' },
	{ value: 'Shannon College of Hotel Management', label: 'Shannon College of Hotel Management', country: 'Ireland' },
	{ value: 'Trinity College Dublin', label: 'Trinity College Dublin', country: 'Ireland' },
	{ value: 'University College Cork', label: 'University College Cork', country: 'Ireland' },

	// Latvia
	{ value: 'Transport and Telecommunication Institute', label: 'Transport and Telecommunication Institute', country: 'Latvia' },

	// Newzealand
	{ value: 'Crimson Global Academy', label: 'Crimson Global Academy', country: 'New Zealand' },
	{ value: 'AGI Education Auckland City', label: 'AGI Education Auckland City', country: 'New Zealand' },
	{ value: 'Whitecliffe Manukau', label: 'Whitecliffe Manukau', country: 'New Zealand' },
	{ value: 'Le Cordon Blue New Zealand', label: 'Le Cordon Blue New Zealand', country: 'New Zealand' },
	{ value: 'Elite School of Beauty and Spa', label: 'Elite School of Beauty and Spa', country: 'New Zealand' },
	{ value: 'Cut Above Academy', label: 'Cut Above Academy', country: 'New Zealand' },
	{ value: 'University of Canterbury', label: 'University of Canterbury', country: 'New Zealand' },
	{ value: 'Auckland University of Technology', label: 'Auckland University of Technology', country: 'New Zealand' },
	{ value: 'The University of Otago', label: 'The University of Otago', country: 'New Zealand' },
	{ value: 'The University of Auckland', label: 'The University of Auckland', country: 'New Zealand' },
	{ value: 'New Zealand Management Academies', label: 'New Zealand Management Academies', country: 'New Zealand' },

	// Slovania
	{ value: 'Svarog educational Institute', label: 'Svarog educational Institute', country: 'Slovenia' },
	{ value: 'IBS International Business School Ljubljana', label: 'IBS International Business School Ljubljana', country: 'Slovenia' },
	{ value: 'University of Maribor', label: 'University of Maribor', country: 'Slovenia' },

	// UK
	{ value: 'Abertay University UK', label: 'Abertay University UK', country: 'UK' },
	{ value: 'Anglia Ruskin University', label: 'Anglia Ruskin University', country: 'UK' },
	{ value: 'Bangor University', label: 'Bangor University', country: 'UK' },
	{ value: 'Brunel University London', label: 'Brunel University London', country: 'UK' },
	{ value: 'Coventry University', label: 'Coventry University', country: 'UK' },
	{ value: 'Queens University Belfast', label: 'Queens University Belfast', country: 'UK' },
	{ value: 'University of Bedfordshire', label: 'University of Bedfordshire', country: 'UK' },
	{ value: 'University of Bolton', label: 'University of Bolton', country: 'UK' },


];