const LeCordonBleuCourses = [
	{ value: "Bachelor of Business Administration (BBA)", label: "Bachelor of Business Administration (BBA)" },
	{ value: "SIT40516 Certificate IV in Commercial Cookery", label: "SIT40516 Certificate IV in Commercial Cookery" },
	{ value: "Bachelor of Business", label: "Bachelor of Business" },
	{ value: "SIT31016 Certificate III in Patisserie", label: "SIT31016 Certificate III in Patisserie" },
	{ value: "SIT30816 Certificate III in Commercial Cookery & SIT31016 Certificate III in Patisserie", label: "SIT30816 Certificate III in Commercial Cookery & SIT31016 Certificate III in Patisserie" },
	{ value: "Bachelor of Business (International Hotel Management)", label: "Bachelor of Business (International Hotel Management)" },
	{ value: "Bachelor of Business (International Restaurant Management)", label: "Bachelor of Business (International Restaurant Management)" },
	{ value: "Master of Business Administration (MBA)", label: "Master of Business Administration (MBA)" },
	{ value: "SIT40716 Certificate IV in Patisserie", label: "SIT40716 Certificate IV in Patisserie" },
	{ value: "SIT60316 Advanced Diploma of Hospitality Management", label: "SIT60316 Advanced Diploma of Hospitality Management" },
	{ value: "Master of Applied Hospitality Management", label: "Master of Applied Hospitality Management" }
];

export default LeCordonBleuCourses;
