const UniCollegeCorkCourses = [
	{ label: "BSc Hons Accounting", value: "BSc Hons Accounting" },
	{ label: "BA Hons Archaeology", value: "BA Hons Archaeology" },
	{ label: "BA Hons Arts International", value: "BA Hons Arts International" },
	{ label: "BA Hons Asian Studies", value: "BA Hons Asian Studies" },
	{ label: "BSc Hons Astrophysics", value: "BSc Hons Astrophysics" },
	{ label: "BSc Hons Applied Plant Biology", value: "BSc Hons Applied Plant Biology" },
	{ label: "BSc Hons Biochemistry", value: "BSc Hons Biochemistry" },
	{ label: "BSc Hons Biological and Chemical Sciences", value: "BSc Hons Biological and Chemical Sciences" },
	{ label: "BSc Hons Biological, Earth and Environmental Sciences", value: "BSc Hons Biological, Earth and Environmental Sciences" },
	{ label: "BSc Hons Business Information Systems", value: "BSc Hons Business Information Systems" },
	{ label: "BSc Hons Data Science and Analytics", value: "BSc Hons Data Science and Analytics" },
	{ label: "BDS Hons Dentistry", value: "BDS Hons Dentistry" },
	{ label: "BA Hons Digital Humanities and Information Technology", value: "BA Hons Digital Humanities and Information Technology" },
	{ label: "BA Hons Theatre and Performative Practices", value: "BA Hons Theatre and Performative Practices" },
	{ label: "BA Hons Celtic Civilisation", value: "BA Hons Celtic Civilisation" },
	{ label: "BSc Hons Chemical Physics", value: "BSc Hons Chemical Physics" },
	{ label: "BSc Hons Chemical Sciences", value: "BSc Hons Chemical Sciences" },
	{ label: "BSc Hons Chemistry", value: "BSc Hons Chemistry" },
	{ label: "BSc Hons Chemistry with Forensic Science", value: "BSc Hons Chemistry with Forensic Science" },
	{ label: "Bcom Commerce Hons", value: "Bcom Commerce Hons" },
	{ label: "BSc Hons Computer Science", value: "BSc Hons Computer Science" },
	{ label: "BA Hons Computer Science", value: "BA Hons Computer Science" },
	{ label: "BA Hons Criminology", value: "BA Hons Criminology" },
	{ label: "BA Hons Early Years and Childhood Studies", value: "BA Hons Early Years and Childhood Studies" },
	{ label: "BA Hons Economics (through Transformational Learning)", value: "BA Hons Economics (through Transformational Learning)" },
	{ label: "BA Hons Economics", value: "BA Hons Economics" },
	{ label: "BE Hons Electrical and Electronic Engineering", value: "BE Hons Electrical and Electronic Engineering" },
	{ label: "BE Hons Energy Engineering", value: "BE Hons Energy Engineering" },
	{ label: "BE Hons Engineering", value: "BE Hons Engineering" },
	{ label: "BA Hons English", value: "BA Hons English" },
	{ label: "BA Hons Arts English", value: "BA Hons Arts English" },
	{ label: "BSc Hons Environmental Science", value: "BSc Hons Environmental Science" },
	{ label: "BA Hons European Studies", value: "BA Hons European Studies" },
	{ label: "BA Hons Film and Screen Media", value: "BA Hons Film and Screen Media" },
	{ label: "BSc Hons Finance", value: "BSc Hons Finance" },
	{ label: "BSc Hons Financial Mathematics and Actuarial Science", value: "BSc Hons Financial Mathematics and Actuarial Science" },
	{ label: "BSc Hons Food Marketing and Entrepreneurship", value: "BSc Hons Food Marketing and Entrepreneurship" },
	{ label: "BSc Hons Food Science", value: "BSc Hons Food Science" },
	{ label: "BA Hons French", value: "BA Hons French" },
	{ label: "BSc Hons Genetics", value: "BSc Hons Genetics" },
	{ label: "BA Hons Geographical and Archaelogical Sciences", value: "BA Hons Geographical and Archaelogical Sciences" },
	{ label: "BA Hons Geography", value: "BA Hons Geography" },
	{ label: "BSc Hons Government and Political Science", value: "BSc Hons Government and Political Science" },
	{ label: "BA Hons Greek and Roman Civilisation", value: "BA Hons Greek and Roman Civilisation" },
	{ label: "BA Hons History", value: "BA Hons History" },
	{ label: "BA Hons History of Arts", value: "BA Hons History of Arts" },
	{ label: "BCL Hons Law Clinical", value: "BCL Hons Law Clinical" },
	{ label: "BA Hons World Languages", value: "BA Hons World Languages" },
	{ label: "BSc Hons Zoology", value: "BSc Hons Zoology" },
	{ label: "BA Hons Religions and Global Diversity Arts", value: "BA Hons Religions and Global Diversity Arts" },
	{ label: "BEd Science Education", value: "BEd Science Education" },
	{ label: "BSocSc Hons Social Science", value: "BSocSc Hons Social Science" },
	{ label: "BA Hons Sociology", value: "BA Hons Sociology" },
	{ label: "BA Applied Psychology", value: "BA Applied Psychology" },
	{ label: "Mpharm Pharmacy", value: "Mpharm Pharmacy" },
	{ label: "BA Hons Arts Philosophy", value: "BA Hons Arts Philosophy" },
	{ label: "BSc Hons Physics", value: "BSc Hons Physics" },
	{ label: "BSc Hons Physics and Astrophysics", value: "BSc Hons Physics and Astrophysics" },
	{ label: "BSc Hons Physiology", value: "BSc Hons Physiology" },
	{ label: "BA Hons Politics", value: "BA Hons Politics" },
	{ label: "BE Hons Process and Chemical Engineering", value: "BE Hons Process and Chemical Engineering" },
	{ label: "BA Hons Psychology and Computing", value: "BA Hons Psychology and Computing" },
	{ label: "BSc Hons Public Health Sciences", value: "BSc Hons Public Health Sciences" },
	{ label: "BSc Hons General Nursing", value: "BSc Hons General Nursing" },
	{ label: "BSc Hons Intellectual Disability Nursing", value: "BSc Hons Intellectual Disability Nursing" },
	{ label: "BSc Hons Mental Health Nursing", value: "BSc Hons Mental Health Nursing" },
	{ label: "BSc Hons Neuroscience", value: "BSc Hons Neuroscience" },
	{ label: "BSc Hons Nursing (Children's and General Integrated)", value: "BSc Hons Nursing (Children's and General Integrated)" },
	{ label: "BSc Hons Nutritional Sciences", value: "BSc Hons Nutritional Sciences" },
	{ label: "BA Hons Music", value: "BA Hons Music" },
	{ label: "BSc Hons Mathematical Sciences (Single Honours)", value: "BSc Hons Mathematical Sciences (Single Honours)" },
	{ label: "BA Hons Mathematical Studies", value: "BA Hons Mathematical Studies" },
	{ label: "BA Hons Mathematics (Arts)", value: "BA Hons Mathematics (Arts)" },
	{ label: "BSc Hons Mathematical Sciences and Physics", value: "BSc Hons Mathematical Sciences and Physics" },
	{ label: "BSc Hons Microbiology", value: "BSc Hons Microbiology" },
	{ label: "BA Hons Studies in Music (Arts)", value: "BA Hons Studies in Music (Arts)" },
	{ label: "BA (Hons) Arts", value: "BA (Hons) Arts" },
	{ label: "BSc(Hons) Biotechnology", value: "BSc(Hons) Biotechnology" },
	{ label: "BSc (Hons) Chemistry of Pharmaceutical Compounds", value: "BSc (Hons) Chemistry of Pharmaceutical Compounds" },
	{ label: "BE (Hons) Civil, Structural and Environmental Engineering", value: "BE (Hons) Civil, Structural and Environmental Engineering" },
	{ label: "BSc (Hons) Ecology and Environmental Biology", value: "BSc (Hons) Ecology and Environmental Biology" },
	{ label: "BSc (Hons ) Geosciences", value: "BSc (Hons ) Geosciences" },
	{ label: "BA (Hons) German", value: "BA (Hons) German" },
	{ label: "BA (Hons) Greek", value: "BA (Hons) Greek" },
	{ label: "BSc (Hons) International Business with Languages", value: "BSc (Hons) International Business with Languages" },
	{ label: "BA (Hons)Arts Italian", value: "BA (Hons)Arts Italian" },
	{ label: "BA (Hons) Arts Latin", value: "BA (Hons) Arts Latin" },
	{ label: "BCL(Hons) Law and Business", value: "BCL(Hons) Law and Business" },
	{ label: "BCL(Hons) International Law", value: "BCL(Hons) International Law" },
	{ label: "BCL(Hons) Law Pathways", value: "BCL(Hons) Law Pathways" },
	{ label: "BA (Hons) Applied Mathematics", value: "BA (Hons) Applied Mathematics" },
	{ label: "BSc Hons Mathematical Sciences", value: "BSc Hons Mathematical Sciences" },
	{ label: "BSc (Hons)Midwifery", value: "BSc (Hons)Midwifery" },
	{ label: "BSc(Hons) Occupational Therapy", value: "BSc(Hons) Occupational Therapy" },
	{ label: "BA (Hons)Portuguese", value: "BA (Hons)Portuguese" },
	{ label: "BA (Hons) Spanish", value: "BA (Hons) Spanish" },
	{ label: "BSC (HONS) Speech And Language Therapy", value: "BSC (HONS) Speech And Language Therapy" },
	{ label: "BSc (Hons) Medical and Health Sciences", value: "BSc (Hons) Medical and Health Sciences" },
	{ label: "BA Hons Anthroplogy", value: "BA Hons Anthroplogy" },
	{ label: "BED (Hons) Sports Studies and Physical Education", value: "BED (Hons) Sports Studies and Physical Education" },
	{ label: "BSc Hons International Development", value: "BSc Hons International Development" },
	{ label: "BCL Hons Law and French", value: "BCL Hons Law and French" },
	{ label: "BCL Hons Law and Irish", value: "BCL Hons Law and Irish" },
	{ label: "BAgrSc (Hons) Agricultural Science", value: "BAgrSc (Hons) Agricultural Science" },
	{ label: "BSc in Architecture (Joint Programme with MTU)", value: "BSc in Architecture (Joint Programme with MTU)" },
	{ label: "BSc in Biomedical Science (Hons) (Joint Programme with MTU)", value: "BSc in Biomedical Science (Hons) (Joint Programme with MTU)" },
	{ label: "MRes Biochemistry & Biosciences", value: "MRes Biochemistry & Biosciences" },
	{ label: "MA Archaeology - Human Osteoarchaeology", value: "MA Archaeology - Human Osteoarchaeology" },
	{ label: "MSc Human Nutrition & Dietetics", value: "MSc Human Nutrition & Dietetics" },
	{ label: "MEngSc in Engineering - Pharmaceutical & Biopharmaceutical Engineering", value: "MEngSc in Engineering - Pharmaceutical & Biopharmaceutical Engineering" },
	{ label: "Law LLB", value: "Law LLB" },
	{ label: "Law LLM (Business Law)", value: "Law LLM (Business Law)" },
	{ label: "MA Classical Studies", value: "MA Classical Studies" },
	{ label: "MSc in Industrial Pharmaceutical Sciences, Operations & Management", value: "MSc in Industrial Pharmaceutical Sciences, Operations & Management" },
	{ label: "MSc International Sustainable Business", value: "MSc International Sustainable Business" },
	{ label: "MMedSc Sports and Exercise Medicine", value: "MMedSc Sports and Exercise Medicine" },
	{ label: "MSc Physiotherapy", value: "MSc Physiotherapy" },
	{ label: "MSc Diagnostic Radiography", value: "MSc Diagnostic Radiography" },
	{ label: "MSc Radiation Therapy", value: "MSc Radiation Therapy" },
	{ label: "MA Teaching Chinese to Speakers of Other Languages", value: "MA Teaching Chinese to Speakers of Other Languages" },
	{ label: "MA Irish - Modern Irish (Nua-Ghaeilge)", value: "MA Irish - Modern Irish (Nua-Ghaeilge)" },
	{ label: "MA Philosophy", value: "MA Philosophy" },
	{ label: "MSc Biotechnology", value: "MSc Biotechnology" },
	{ label: "Msc in Bioinformatics and Computational Biology", value: "Msc in Bioinformatics and Computational Biology" },
	{ label: "Msc Marine Biology", value: "Msc Marine Biology" },
	{ label: "Msc in Business Economics", value: "Msc in Business Economics" },
	{ label: "Msc in  Chemistry- Analysis of Pharmaceutical Compounds", value: "Msc in  Chemistry- Analysis of Pharmaceutical Compounds" },
	{ label: "Msc in Chemistry - Analytical Chemistry", value: "Msc in Chemistry - Analytical Chemistry" },
	{ label: "Msc in Chemistry - Environmental Analytical Chemistry", value: "Msc in Chemistry - Environmental Analytical Chemistry" },
	{ label: "MA English (Modernities: Literature, Theory and Culture from the Romantics to the Present)", value: "MA English (Modernities: Literature, Theory and Culture from the Romantics to the Present)" },
	{ label: "MSc in Finance (Investment and Asset Management)", value: "MSc in Finance (Investment and Asset Management)" },
	{ label: "MSc Geology - Applied Environmental Geoscience", value: "MSc Geology - Applied Environmental Geoscience" },
	{ label: "MA in History", value: "MA in History" },
	{ label: "MA Global Cultures & Languages", value: "MA Global Cultures & Languages" },
	{ label: "MSc in Mathematical Modelling & Machine Learning", value: "MSc in Mathematical Modelling & Machine Learning" },
	{ label: "MA Medieval History: Culture, Text, Image", value: "MA Medieval History: Culture, Text, Image" },
	{ label: "MA Music - Ethnomusicology", value: "MA Music - Ethnomusicology" },
	{ label: "(Mplan) Planning and Sustainable Development", value: "(Mplan) Planning and Sustainable Development" },
	{ label: "MA in Work and Organisational Behaviour", value: "MA in Work and Organisational Behaviour" },
	{ label: "MA in Sociology", value: "MA in Sociology" },
	{ label: "Law LLM", value: "Law LLM" },
	{ label: "LLM Environmental and Natural Resources Law", value: "LLM Environmental and Natural Resources Law" },
	{ label: "LLM Marine and Maritime Law", value: "LLM Marine and Maritime Law" },
	{ label: "MA Applied Linguistics", value: "MA Applied Linguistics" },
	{ label: "MA Applied Psychology", value: "MA Applied Psychology" },
	{ label: "MA Asian Studies", value: "MA Asian Studies" },
	{ label: "MA Creative Writing", value: "MA Creative Writing" },
	{ label: "MA Criminology", value: "MA Criminology" },
	{ label: "MA Digital Arts and the Humanities", value: "MA Digital Arts and the Humanities" },
	{ label: "MA English- Texts and Contexts: Medieval to Renaissance", value: "MA English- Texts and Contexts: Medieval to Renaissance" },
	{ label: "MA Experimental Sound Practice", value: "MA Experimental Sound Practice" },
	{ label: "MA Health and Society", value: "MA Health and Society" },
	{ label: "MA International Relations", value: "MA International Relations" },
	{ label: "MA Museum Studies", value: "MA Museum Studies" },
	{ label: "MA Music and Cultural History", value: "MA Music and Cultural History" },
	{ label: "MA Theatre and Performative Practices", value: "MA Theatre and Performative Practices" },
	{ label: "MA Translation Studies", value: "MA Translation Studies" },
	{ label: "MA Women's Studies", value: "MA Women's Studies" },
	{ label: "MACC - Master in Accounting", value: "MACC - Master in Accounting" },
	{ label: "MSc - Food Science", value: "MSc - Food Science" },
	{ label: "Masters in Architecture (Joint Programme with MTU)", value: "Masters in Architecture (Joint Programme with MTU)" },
	{ label: "Law - LLM (Children's Rights and Family Law)", value: "Law - LLM (Children's Rights and Family Law)" },
	{ label: "Masters in Dental Public Health (MDPH)", value: "Masters in Dental Public Health (MDPH)" },
	{ label: "Masters in Film and Screen Media", value: "Masters in Film and Screen Media" },
	{ label: "Law - LLM (Intellectual Property and E-Law)", value: "Law - LLM (Intellectual Property and E-Law)" },
	{ label: "Law - LLM (International Human Rights Law and Public Policy)", value: "Law - LLM (International Human Rights Law and Public Policy)" },
	{ label: "Masters in Mechanical Engineering - Manufacturing, Process and Automation Systems", value: "Masters in Mechanical Engineering - Manufacturing, Process and Automation Systems" },
	{ label: "MEngSc Electrical and Electronic Engineering", value: "MEngSc Electrical and Electronic Engineering" },
	{ label: "MEngSc in Sustainable Energy", value: "MEngSc in Sustainable Energy" },
	{ label: "MSc Audiology", value: "MSc Audiology" },
	{ label: "MSc Business Analytics", value: "MSc Business Analytics" },
	{ label: "MSc Computer Science- Computing Science", value: "MSc Computer Science- Computing Science" },
	{ label: "MSc Interactive Media", value: "MSc Interactive Media" },
	{ label: "MSc Sustainable Development, Agri-Food & Co-operatives", value: "MSc Sustainable Development, Agri-Food & Co-operatives" },
	{ label: "MSc Data Science & Analytics", value: "MSc Data Science & Analytics" },
	{ label: "MSc Design and Development of Digital Business", value: "MSc Design and Development of Digital Business" },
	{ label: "MSc Finance (Banking and Risk Management)", value: "MSc Finance (Banking and Risk Management)" },
	{ label: "MSc Food Business and Innovation", value: "MSc Food Business and Innovation" },
	{ label: "MSc Food Microbiology", value: "MSc Food Microbiology" },
	{ label: "MSc Government and Politics", value: "MSc Government and Politics" },
	{ label: "MSc Human Anatomy", value: "MSc Human Anatomy" },
	{ label: "MSc in Finance (Corporate Finance)", value: "MSc in Finance (Corporate Finance)" },
	{ label: "MSc Information Systems for Business Performance", value: "MSc Information Systems for Business Performance" },
	{ label: "MSc International Public Policy and Diplomacy", value: "MSc International Public Policy and Diplomacy" },
	{ label: "MSc Management and Marketing", value: "MSc Management and Marketing" },
	{ label: "MSc Management Information and Managerial Accounting Systems", value: "MSc Management Information and Managerial Accounting Systems" },
	{ label: "MSc Molecular Cell Biology with Bioinnovation", value: "MSc Molecular Cell Biology with Bioinnovation" },
	{ label: "MSc Nursing (Non-EU Applicants)", value: "MSc Nursing (Non-EU Applicants)" },
	{ label: "MSc Older Person Rehabilitation", value: "MSc Older Person Rehabilitation" },
	{ label: "MSocSc Social Policy", value: "MSocSc Social Policy" },
	{ label: "MA Psychology - Applied Psychology (Mental Health)", value: "MA Psychology - Applied Psychology (Mental Health)" },
	{ label: "MSc Financial and Computational Mathematics", value: "MSc Financial and Computational Mathematics" },
	{ label: "MA Arts Management and Creative Producing", value: "MA Arts Management and Creative Producing" },
	{ label: "MA Applied Psychology (Positive and Coaching Psychology)", value: "MA Applied Psychology (Positive and Coaching Psychology)" },
	{ label: "MA Anthropology", value: "MA Anthropology" },
	{ label: "MA Celtic Civilisation", value: "MA Celtic Civilisation" },
	{ label: "MA Irish - Early & Medieval Irish", value: "MA Irish - Early & Medieval Irish" },
	{ label: "MSc Applied Coastal and Marine Management", value: "MSc Applied Coastal and Marine Management" },
	{ label: "MA Sociology of Sustainability & Global Challenges", value: "MA Sociology of Sustainability & Global Challenges" },
	{ label: "MSW Social Work", value: "MSW Social Work" },
	{ label: "MA Global Gallery Studies", value: "MA Global Gallery Studies" },
	{ label: "PME Education (Professional Master of Education)", value: "PME Education (Professional Master of Education)" },
	{ label: "MSc Marketing", value: "MSc Marketing" },
	{ label: "MPH Master of Public Health", value: "MPH Master of Public Health" },
	{ label: "MSc Dementia", value: "MSc Dementia" },
	{ label: "MSc in Human Resource Management", value: "MSc in Human Resource Management" },
	{ label: "PG Dip. in Engineering - Pharmaceutical and Biopharmaceutical Engineering", value: "PG Dip. in Engineering - Pharmaceutical and Biopharmaceutical Engineering" }
];

export default UniCollegeCorkCourses;