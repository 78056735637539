const LeadCollegeCourses = [
	{ value: "ICT60220 Advanced Diploma Of Information Technology", label: "ICT60220 Advanced Diploma Of Information Technology" },
	{ value: "CHC30121 Certificate III In Early Childhood Education And Care + CHC50121 Diploma Of Early Childhood Education And Care (Packaged Course)", label: "CHC30121 Certificate III In Early Childhood Education And Care + CHC50121 Diploma Of Early Childhood Education And Care (Packaged Course)" },
	{ value: "SIT50416 Diploma Of Hospitality Management", label: "SIT50416 Diploma Of Hospitality Management" },
	{ value: "BSB80120 Graduate Diploma Of Management (Learning)", label: "BSB80120 Graduate Diploma Of Management (Learning)" },
	{ value: "CHC30121 Certificate III In Early Childhood Education And Care", label: "CHC30121 Certificate III In Early Childhood Education And Care" },
	{ value: "Commercial Cookery & Hospitality Management - Packaged Course", label: "Commercial Cookery & Hospitality Management - Packaged Course" },
	{ value: "CHC43015 Certificate IV In Ageing Support + 104 weeks CHC52015 Diploma of Community Services (Packaged Courses)", label: "CHC43015 Certificate IV In Ageing Support + 104 weeks CHC52015 Diploma of Community Services (Packaged Courses)" },
	{ value: "ICT50220 Diploma of Information Technology", label: "ICT50220 Diploma of Information Technology" },
	{ value: "CPC50220 Diploma of Building and Construction (Building)", label: "CPC50220 Diploma of Building and Construction (Building)" },
	{ value: "HLT37215 Certificate III in Pathology Collection", label: "HLT37215 Certificate III in Pathology Collection" },
	{ value: "CPC - Packaged Course 4", label: "CPC - Packaged Course 4" },
	{ value: "CHC50121 Diploma Of Early Childhood Education And Care", label: "CHC50121 Diploma Of Early Childhood Education And Care" },
	{ value: "CHC52015 Diploma of Community Services", label: "CHC52015 Diploma of Community Services" },
	{ value: "Community Services - Packaged Course 5", label: "Community Services - Packaged Course 5" },
	{ value: "SIT60316 Advanced Diploma Of Hospitality Management", label: "SIT60316 Advanced Diploma Of Hospitality Management" },
	{ value: "CPC30220 Certificate III in Carpentry", label: "CPC30220 Certificate III in Carpentry" },
	{ value: "CHC43115 Certificate IV In Disability + HLT51020 Diploma of Emergency Health Care (Packaged Courses)", label: "CHC43115 Certificate IV In Disability + HLT51020 Diploma of Emergency Health Care (Packaged Courses)" },
	{ value: "HLT51020 Diploma of Emergency Health Care", label: "HLT51020 Diploma of Emergency Health Care" },
	{ value: "Community Services - Packaged Course 6", label: "Community Services - Packaged Course 6" },
	{ value: "BSB50420 Diploma Of Leadership And Management", label: "BSB50420 Diploma Of Leadership And Management" },
	{ value: "CHC43115 Certificate IV In Disability + 104 CHC52015 weeks Diploma of Community Services (Packaged Courses)", label: "CHC43115 Certificate IV In Disability + 104 CHC52015 weeks Diploma of Community Services (Packaged Courses)" },
	{ value: "CHC33015 Certificate III In Individual Support + CHC43115 Certificate IV In Disability + 104 weeks CHC52015 Diploma of Community Services (Packaged Courses)", label: "CHC33015 Certificate III In Individual Support + CHC43115 Certificate IV In Disability + 104 weeks CHC52015 Diploma of Community Services (Packaged Courses)" },
	{ value: "SIT50416 Diploma Of Hospitality Management", label: "SIT50416 Diploma Of Hospitality Management" },
	{ value: "CPC33020 Certificate III in Bricklaying and Blocklaying", label: "CPC33020 Certificate III in Bricklaying and Blocklaying" },
	{ value: "CPC33020 Certificate III in Bricklaying and Blocklaying", label: "CPC33020 Certificate III in Bricklaying and Blocklaying" },
	{ value: "ICT50220 Diploma of Information Technology +ICT60220 Advanced Diploma Of Information Technology (Packaged Course)", label: "ICT50220 Diploma of Information Technology +ICT60220 Advanced Diploma Of Information Technology (Packaged Course)" },
	{ value: "AUR40216 Certificate IV In Automotive Mechanical Diagnosis", label: "AUR40216 Certificate IV In Automotive Mechanical Diagnosis" },
	{ value: "CPC - Packaged Course 5", label: "CPC - Packaged Course 5" },
	{ value: "CPC40120 Certificate IV in Building and Construction", label: "CPC40120 Certificate IV in Building and Construction" },
	{ value: "SIT40516 Certificate IV In Commercial Cookery", label: "SIT40516 Certificate IV In Commercial Cookery" },
	{ value: "AUR50116 Diploma Of Automotive Management", label: "AUR50116 Diploma Of Automotive Management" },
	{ value: "CPC - Packaged Course 2", label: "CPC - Packaged Course 2" },
	{ value: "CHC43015 Certificate IV In Ageing Support", label: "CHC43015 Certificate IV In Ageing Support" },
	{ value: "CHC52015 Diploma of Community Services", label: "CHC52015 Diploma of Community Services" },
	{ value: "CHC33015 Certificate III In Individual Support", label: "CHC33015 Certificate III In Individual Support" },
	{ value: "Community Services - Packaged Course 1", label: "Community Services - Packaged Course 1" },
	{ value: "ICT60220 Advanced Diploma Of Information Technology", label: "ICT60220 Advanced Diploma Of Information Technology" },
	{ value: "Business & Leadership Management Packaged 1", label: "Business & Leadership Management Packaged 1" },
	{ value: "Information Technology - Packaged Courses", label: "Information Technology - Packaged Courses" },
	{ value: "Automotive - Packaged Course 1", label: "Automotive - Packaged Course 1" },
	{ value: "RII60520 Advanced Diploma of Civil Construction Design", label: "RII60520 Advanced Diploma of Civil Construction Design" },
	{ value: "BSB50420 Diploma Of Leadership And Management", label: "BSB50420 Diploma Of Leadership And Management" },
	{ value: "CPC30220 Certificate III in Carpentry", label: "CPC30220 Certificate III in Carpentry" },
	{ value: "SIT30816 Certificate III In Commercial Cookery", label: "SIT30816 Certificate III In Commercial Cookery" },
	{ value: "ICT50220 Diploma of Information Technology", label: "ICT50220 Diploma of Information Technology" },
	{ value: "CHC33015 Certificate III In Individual Support + 104 weeks CHC52015 Diploma of Community Services (Packaged Courses)", label: "CHC33015 Certificate III In Individual Support + 104 weeks CHC52015 Diploma of Community Services (Packaged Courses)" },
	{ value: "CPC - Packaged Course 3", label: "CPC - Packaged Course 3" },
	{ value: "Business & Leadership Management Packaged 1", label: "Business & Leadership Management Packaged 1" },
	{ value: "CHC52015 Diploma of Community Services", label: "CHC52015 Diploma of Community Services" },
	{ value: "CHC43015 Certificate IV In Ageing Support", label: "CHC43015 Certificate IV In Ageing Support" },
	{ value: "Information Technology - Packaged Courses", label: "Information Technology - Packaged Courses" },
	{ value: "SIT40516 Certificate IV In Commercial Cookery", label: "SIT40516 Certificate IV In Commercial Cookery" },
	{ value: "CPC - Packaged Course 3", label: "CPC - Packaged Course 3" },
	{ value: "Community Services - Packaged Course 3", label: "Community Services - Packaged Course 3" },
	{ value: "Community Services - Packaged Course 4", label: "Community Services - Packaged Course 4" },
	{ value: "CHC33015 Certificate III In Individual Support", label: "CHC33015 Certificate III In Individual Support" },
	{ value: "SIT30816 Certificate III in Commercial Cookery + SIT40516 Certificate IV in Commercial Cookery + SIT50416 Diploma of Hospitality Management (Packaged Course)", label: "SIT30816 Certificate III in Commercial Cookery + SIT40516 Certificate IV in Commercial Cookery + SIT50416 Diploma of Hospitality Management (Packaged Course)" },
	{ value: "CHC43115 Certificate IV In Disability", label: "CHC43115 Certificate IV In Disability" },
	{ value: "Community Services - Packaged Course 2", label: "Community Services - Packaged Course 2" },
	{ value: "CHC33015 Certificate III In Individual Support + CHC43015 Certificate IV In Ageing Support + 104 weeks CHC52015 Diploma of Community Services (Packaged Courses)", label: "CHC33015 Certificate III In Individual Support + CHC43015 Certificate IV In Ageing Support + 104 weeks CHC52015 Diploma of Community Services (Packaged Courses)" },
	{ value: "SIT40516 Certificate IV In Commercial Cookery", label: "SIT40516 Certificate IV In Commercial Cookery" },
	{ value: "CHC43015 Certificate IV In Ageing Support", label: "CHC43015 Certificate IV In Ageing Support" },
	{ value: "Community Services - Packaged Course 1", label: "Community Services - Packaged Course 1" },
	{ value: "CPC - Packaged Course 5", label: "CPC - Packaged Course 5" },
	{ value: "CPC - Packaged Course 1", label: "CPC - Packaged Course 1" },
	{ value: "BSB80120 Graduate Diploma Of Management (Learning)", label: "BSB80120 Graduate Diploma Of Management (Learning)" },
	{ value: "Business & Leadership Management Packaged 2", label: "Business & Leadership Management Packaged 2" },
	{ value: "HLT51020 Diploma of Emergency Health Care", label: "HLT51020 Diploma of Emergency Health Care" },
	{ value: "CHC33015 Certificate III In Individual Support", label: "CHC33015 Certificate III In Individual Support" },
	{ value: "HLT37215 Certificate III in Pathology Collection", label: "HLT37215 Certificate III in Pathology Collection" },
	{ value: "Community Services - Packaged Course 6", label: "Community Services - Packaged Course 6" },
	{ value: "Community Services - Packaged Course 7", label: "Community Services - Packaged Course 7" },
	{ value: "BSB60420 Advanced Diploma of Leadership And Management", label: "BSB60420 Advanced Diploma of Leadership And Management" },
	{ value: "CHC33015 Certificate III In Individual Support + HLT51020 Diploma of Emergency Health Care (Packaged Courses)", label: "CHC33015 Certificate III In Individual Support + HLT51020 Diploma of Emergency Health Care (Packaged Courses)" },
	{ value: "CPC - Packaged Course 1", label: "CPC - Packaged Course 1" },
	{ value: "CHC30121 Certificate III In Early Childhood Education And Care", label: "CHC30121 Certificate III In Early Childhood Education And Care" },
	{ value: "Automotive - Packaged Course 2", label: "Automotive - Packaged Course 2" },
	{ value: "CPC40120 Certificate IV in Building and Construction", label: "CPC40120 Certificate IV in Building and Construction" },
	{ value: "Children Service Packaged Course", label: "Children Service Packaged Course" },
	{ value: "Commercial Cookery & Hospitality Management - Packaged Course", label: "Commercial Cookery & Hospitality Management - Packaged Course" },
	{ value: "Community Services - Packaged Course 4", label: "Community Services - Packaged Course 4" },
	{ value: "Community Services - Packaged Course 7", label: "Community Services - Packaged Course 7" },
	{ value: "CPC - Packaged Course 2", label: "CPC - Packaged Course 2" },
	{ value: "ICT60220 Advanced Diploma Of Information Technology", label: "ICT60220 Advanced Diploma Of Information Technology" },
	{ value: "RII60520 Advanced Diploma of Civil Construction Design", label: "RII60520 Advanced Diploma of Civil Construction Design" },
	{ value: "Community Services - Packaged Course 5", label: "Community Services - Packaged Course 5" },
	{ value: "SIT30816 Certificate III In Commercial Cookery", label: "SIT30816 Certificate III In Commercial Cookery" },
	{ value: "BSB60420 Advanced Diploma of Leadership And Management", label: "BSB60420 Advanced Diploma of Leadership And Management" },
	{ value: "SIT60316 Advanced Diploma Of Hospitality Management", label: "SIT60316 Advanced Diploma Of Hospitality Management" },
	{ value: "SIT30816 Certificate III In Commercial Cookery", label: "SIT30816 Certificate III In Commercial Cookery" },
	{ value: "SIT60316 Advanced Diploma Of Hospitality Management", label: "SIT60316 Advanced Diploma Of Hospitality Management" },
	{ value: "CPC - Packaged Course 4", label: "CPC - Packaged Course 4" },
	{ value: "Business & Leadership Management Packaged 2", label: "Business & Leadership Management Packaged 2" },
	{ value: "CHC43115 Certificate IV In Disability", label: "CHC43115 Certificate IV In Disability" },
	{ value: "Community Services - Packaged Course 3", label: "Community Services - Packaged Course 3" },
	{ value: "HLT51020 Diploma of Emergency Health Care", label: "HLT51020 Diploma of Emergency Health Care" },
	{ value: "BSB50420 Diploma Of Leadership And Management+BSB60420 Advanced Diploma Of Leadership And Management (Packaged Course)", label: "BSB50420 Diploma Of Leadership And Management+BSB60420 Advanced Diploma Of Leadership And Management (Packaged Course)" },
	{ value: "BSB60420 Advanced Diploma of Leadership And Management", label: "BSB60420 Advanced Diploma of Leadership And Management" },
	{ value: "BSB50420 Diploma Of Leadership And Management+BSB60420 Advanced Diploma of Leadership And Management+104 weeks BSB80120 Graduate Diploma Of Management (Learning) (Packaged Course)", label: "BSB50420 Diploma Of Leadership And Management+BSB60420 Advanced Diploma of Leadership And Management+104 weeks BSB80120 Graduate Diploma Of Management (Learning) (Packaged Course)" },
	{ value: "BSB80120 Graduate Diploma Of Management (Learning)", label: "BSB80120 Graduate Diploma Of Management (Learning)" },
	{ value: "CHC50121 Diploma Of Early Childhood Education And Care", label: "CHC50121 Diploma Of Early Childhood Education And Care" },
	{ value: "BSB50420 Diploma Of Leadership And Management", label: "BSB50420 Diploma Of Leadership And Management" },
	{ value: "AUR30620 Certificate III in Light Vehicle Mechanical Technology", label: "AUR30620 Certificate III in Light Vehicle Mechanical Technology" },
	{ value: "CHC43115 Certificate IV In Disability", label: "CHC43115 Certificate IV In Disability" },
	{ value: "Community Services - Packaged Course 2", label: "Community Services - Packaged Course 2" },
	{ value: "SIT50416 Diploma Of Hospitality Management", label: "SIT50416 Diploma Of Hospitality Management" }
];

export default LeadCollegeCourses;
