const EBSGerCourses = [
	{ value: "Bachelor in Business Studies (BSc)", label: "Bachelor in Business Studies (BSc)" },
	{ value: "Bachelor in Law, Politics and Economics (BA)", label: "Bachelor in Law, Politics and Economics (BA)" },
	{ value: "Master in Business Analytics (MSc) (One Year)", label: "Master in Business Analytics (MSc) (One Year)" },
	{ value: "Master in Business Analytics (MSc) (Two Year)", label: "Master in Business Analytics (MSc) (Two Year)" },
	{ value: "Master in Marketing (MSc)", label: "Master in Marketing (MSc)" },
	{ value: "Master in Strategy and International Management (MSc)", label: "Master in Strategy and International Management (MSc)" },
	{ value: "Master in Management (MSc)", label: "Master in Management (MSc)" },
	{ value: "Master in Finance (MSc)", label: "Master in Finance (MSc)" },
	{ value: "Master in Real Estate (MSc)", label: "Master in Real Estate (MSc)" },
	{ value: "Master of Business Administration (MBA Plus )", label: "Master of Business Administration (MBA Plus )" },
	{ value: "Master of Business Administration (MBA )", label: "Master of Business Administration (MBA )" }
];

export default EBSGerCourses;