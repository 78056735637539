import React, { useState, useEffect } from 'react';
import Main from '../global/wrapper/Main';
import axios from 'axios';
import { Link, useSearchParams } from 'react-router-dom';
import {
	Table, Thead, Tbody, Tr, Th, Td, Box, Text, VStack, HStack, Wrap,
	WrapItem, useColorModeValue, IconButton, Tooltip
} from '@chakra-ui/react';
import { ViewIcon } from '@chakra-ui/icons';
import { getUserDetailsFromToken } from '../utils/getUserDetailsFromToken';
import { useNavigation } from '../utils/NavigationContext';

const Enquiries = () => {
	const [enquiries, setEnquiries] = useState([]);
	const [branchName, setBranchName] = useState('');
	const [recordsPerPage] = useState(10);
	const [searchParams, setSearchParams] = useSearchParams();

	const currentPageFromURL = parseInt(searchParams.get('page') || '1', 10);
	const [currentPage, setCurrentPage] = useState(currentPageFromURL);

	const { setFromPage } = useNavigation();
	const handleLinkClick = () => {
		setFromPage('enquiries');
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				// Extract userDetails from token
				const userDetails = getUserDetailsFromToken();
				setBranchName(userDetails?.branchName || null);
				console.log("Branch Name:", userDetails?.branchName);
				console.log(userDetails?.branchId);
				const apiUrl = userDetails?.privilege === "Admin"
					? `${process.env.REACT_APP_API_URL}/api/enquiries`
					: `${process.env.REACT_APP_API_URL}/api/enquiries?branchId=${userDetails?.branchId}`;

				console.log("API URL:", apiUrl);
				const response = await axios.get(apiUrl);
				setEnquiries(response.data);
			} catch (error) {
				console.error('Error fetching data: ', error);
				// Handle error appropriately in production code
			}
		};

		fetchData();
	}, [currentPage, searchParams]);

	useEffect(() => {
		setCurrentPage(currentPageFromURL);
	}, [currentPageFromURL]);

	const paginate = (pageNumber) => {
		setSearchParams({ page: pageNumber });
		setCurrentPage(pageNumber);
	};

	const totalPages = Math.ceil(enquiries.length / recordsPerPage);
	const indexOfLastRecord = currentPage * recordsPerPage;
	const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
	const currentRecords = enquiries.slice(indexOfFirstRecord, indexOfLastRecord);

	console.log("currentRecords" + currentRecords);

	const formatDate = (dateString) => {
		const date = new Date(dateString);
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedMonth = month < 10 ? `0${month}` : month;

		return `${formattedDay}/${formattedMonth}/${year}`;
	};

	const capitalizeFirstLetter = (string) => {
		if (typeof string !== 'string' || string.length === 0) return '';
		return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
	};

	// const isMobile = useBreakpointValue({ base: true, sm: false });

	const tableBg = useColorModeValue("white", "gray.700");
	// const hoverBg = useColorModeValue("gray.100", "gray.600");
	const bgColor = "blue.100";

	return (
		<Main>
			<VStack spacing={4} align="stretch">
				<Box p={4} bg={tableBg} borderRadius="lg" boxShadow="md">
					{branchName ?
						<Text mb="5" fontSize="lg">Total enquiries for <strong>{branchName}</strong> : "<strong>{enquiries.length}</strong>"</Text> :
						<Text mb="5" fontSize="lg">Total enquiries : "<strong>{enquiries.length}</strong>"</Text>
					}
					<Box overflowX="auto">
						<Table variant="simple" size="sm">
							<Thead>
								<Tr>
									<Th>Enquiry Date</Th>
									<Th>Student Name</Th>
									<Th>Student ID</Th>
									<Th>DoB</Th>
									<Th>Desired Courses</Th>
									<Th>Preferred Universities</Th>
									<Th>Country</Th>
									<Th>Mobile Number</Th>
									<Th>Enrollment Status</Th>
									<Th>Counselor Name</Th>
									<Th>View More</Th>
								</Tr>
							</Thead>
							<Tbody>
								{currentRecords.map((enquiry) => (
									<Tr key={enquiry.studentID}>
										<Td>{enquiry.enquiryDate ? formatDate(enquiry.enquiryDate) : 'N/A'}</Td>
										<Td>
											<Box color="black" bg="yellow.300" p={2} borderRadius="md">
												<Text mb="0">
													<Link to={`/enquiry-details/${enquiry.studentID}?detailPage=1&backPage=${currentPage}`} onClick={handleLinkClick}>{`${enquiry.givenName ? capitalizeFirstLetter(enquiry.givenName) : ''} ${enquiry.familyName ? capitalizeFirstLetter(enquiry.familyName) : ''}`}</Link>
												</Text>
											</Box>
										</Td>
										<Td>{enquiry.studentID}</Td>
										<Td>{enquiry.dob ? formatDate(enquiry.dob) : ''}</Td>
										<Td>
											<Wrap spacing={1}>
												{enquiry.desiredCourses.map((course, index) => (
													<WrapItem key={index}>
														<Box
															p={2} // Padding for spacing around text
															bg="green.200" // Background color for courses (you can choose a different color)
															borderRadius="md" // Rounded corners
														>
															<Text mb={0}>{course.label}</Text>
														</Box>
													</WrapItem>
												))}
											</Wrap>
										</Td>
										<Td>
											<Wrap spacing={1}>
												{enquiry.university.map((u, index) => (
													<WrapItem key={index}>
														<Box
															p={2} // Padding for spacing around text
															bg="blue.200" // Background color (you can change this to any Chakra UI color)
															borderRadius="md" // Rounded corners
														>
															<Text mb={0}>{u.label}</Text>
														</Box>
													</WrapItem>
												))}
											</Wrap>
										</Td>
										<Td>{enquiry.preferredCountry}</Td>
										<Td>{enquiry.mobileNumber}</Td>
										<Td style={{ backgroundColor: bgColor, color: "black" }}>{enquiry.enrollmentStatus ? capitalizeFirstLetter(enquiry.enrollmentStatus) : ''}</Td>
										<Td>{enquiry.counselorName}</Td>
										<Td>
											<Tooltip label="View Details" hasArrow>
												<IconButton
													icon={<ViewIcon />}
													as={Link}
													to={`/enquiry-details/${enquiry.studentID}?detailPage=1&backPage=${currentPage}`}
													aria-label="View Details"
													colorScheme="blue"
													onClick={handleLinkClick}
												/>
											</Tooltip>
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</Box>

					<HStack mt={10} spacing={5} justifyContent="center">
						{Array.from({ length: totalPages }, (_, i) => (
							<span
								key={i + 1}
								style={{
									cursor: 'pointer',
									textDecoration: i + 1 === currentPage ? 'underline' : 'none',
									fontWeight: i + 1 === currentPage ? 'bold' : 'normal',
								}}
								onClick={() => paginate(i + 1)}
							>
								{i + 1}
							</span>
						))}
					</HStack>

				</Box>
			</VStack>
		</Main>
	);
};

export default Enquiries;
