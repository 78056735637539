const UniverisityNiagaraFallsCourses = [
	{ value: 'Honours Bachelor of Business Administration', label: 'Honours Bachelor of Business Administration' },
	{ value: 'Honours Bachelor of Business Administration - Digital Economy', label: 'Honours Bachelor of Business Administration - Digital Economy' },
	{ value: 'Honours Bachelor of Business Administration - Digital Marketing', label: 'Honours Bachelor of Business Administration - Digital Marketing' },
	{ value: 'Honours Bachelor of Science – Biomedical Sciences', label: 'Honours Bachelor of Science – Biomedical Sciences' },
	{ value: 'Master of Management (MM)', label: 'Master of Management (MM)' },
	{ value: 'Master of Management (MM) - Specialization in Entrepreneurship', label: 'Master of Management (MM) - Specialization in Entrepreneurship' },
	{ value: 'Master of Management (MM) - Specialization in Emerging technology', label: 'Master of Management (MM) - Specialization in Emerging technology' },
	{ value: 'Master of Data Analytics', label: 'Master of Data Analytics' },
	{ value: 'Master of Arts in Digital Media and Global Communications', label: 'Master of Arts in Digital Media and Global Communications' },
];

export default UniverisityNiagaraFallsCourses;
