const CrimsonGlobalCourses = [
	{ value: "Pre-International GCSE (4 Subjects)", label: "Pre-International GCSE (4 Subjects)" },
	{ value: "Pre-International GCSE (5 Subjects)", label: "Pre-International GCSE (5 Subjects)" },
	{ value: "Pre-International GCSE (6 Subjects)", label: "Pre-International GCSE (6 Subjects)" },
	{ value: "Pre-International GCSE (7 Subjects)", label: "Pre-International GCSE (7 Subjects)" },
	{ value: "Pre-International GCSE (8 Subjects)", label: "Pre-International GCSE (8 Subjects)" },
	{ value: "International GCSE (4 Subjects)", label: "International GCSE (4 Subjects)" },
	{ value: "International GCSE (5 Subjects)", label: "International GCSE (5 Subjects)" },
	{ value: "International GCSE (6 Subjects)", label: "International GCSE (6 Subjects)" },
	{ value: "International GCSE (7 Subjects)", label: "International GCSE (7 Subjects)" },
	{ value: "International GCSE (8 Subjects)", label: "International GCSE (8 Subjects)" },
	{ value: "International AS/A Level (3 Subjects)", label: "International AS/A Level (3 Subjects)" },
	{ value: "International AS/A Level (4 Subjects)", label: "International AS/A Level (4 Subjects)" },
	{ value: "International AS/A Level (5 Subjects)", label: "International AS/A Level (5 Subjects)" },
	{ value: "International AS/A Level (6 Subjects)", label: "International AS/A Level (6 Subjects)" },
	{ value: "International AS/A Level (7 Subjects)", label: "International AS/A Level (7 Subjects)" }
];

export default CrimsonGlobalCourses;