import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import Card from '../global/holder/Card';
import AccountLayout from '../layout/AccountLayout';
import { useToast, useBreakpointValue } from '@chakra-ui/react';

const CreateUser = () => {
	const toast = useToast();
	const rowStyle = {
		marginBottom: '15px'
	};

	const [formData, setFormData] = useState({
		userName: '',
		email: '',
		password: '',
		confirmPassword: '',
		privilege: 'Editor', // Default privilege
		branchId: '',
	});

	const [branches, setBranches] = useState([]);
	const [passwordShown, setPasswordShown] = useState(false);
	const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
	const [passwordError, setPasswordError] = useState('');
	const [generatedPasswordUsed, setGeneratedPasswordUsed] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [passwordValidateError, setPasswordValidateError] = useState('');

	useEffect(() => {
		const fetchBranches = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}/api/branches`);
				const data = await response.json();
				setBranches(data); // Make sure this line correctly updates your state
			} catch (error) {
				console.error('Fetch branches error:', error);
				toast({
					title: 'Error fetching branches',
					description: 'Unable to load branch data.',
					status: 'error',
					duration: 9000,
					isClosable: true,
				});
			}
		};

		fetchBranches();
	}, [toast]);

	const togglePasswordVisiblity = () => {
		setPasswordShown(passwordShown => !passwordShown);
	};

	const toggleConfirmPasswordVisiblity = () => {
		setConfirmPasswordShown(confirmPasswordShown => !confirmPasswordShown);
	};

	const resetPasswordFields = () => {
		setFormData({ ...formData, password: '', confirmPassword: '' });
		setGeneratedPasswordUsed(false);
	};

	const submitHandler = async (e) => {
		e.preventDefault();

		if (formData.password !== formData.confirmPassword) {
			setPasswordError("Passwords do not match");
			return;
		}
		setPasswordError('');

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/register`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					userName: formData.userName,
					email: formData.email,
					password: formData.password,
					privilege: formData.privilege,
					branchId: formData.branchId
				}),
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const contentType = response.headers.get("content-type");
			if (contentType && contentType.includes("application/json")) {
				await response.json();
				setSuccessMessage('User registered successfully');
				// Process JSON data if needed
			} else {
				const text = await response.text();
				toast({
					title: 'Success',
					description: text,
					status: 'success',
					duration: 5000,
					isClosable: true,
				});
				// Process text response if needed
			}

			setFormData({
				userName: '',
				email: '',
				password: '',
				confirmPassword: '',
				privilege: 'Viewer'
			});
			setErrorMessage('');

		} catch (error) {
			console.error('Error:', error);
			setErrorMessage('Error registering user. Please try again.');
			setSuccessMessage('');
		}
	};

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
		if (generatedPasswordUsed) setGeneratedPasswordUsed(false);

		// Password validation
		if (e.target.name === "password") {
			const password = e.target.value;
			const isValidPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(password);
			if (!isValidPassword && password.length > 0) {
				setPasswordValidateError("Password must be at least 8 characters long and include a capital letter, number, and special character.");
			} else {
				setPasswordValidateError('');
			}
		}
	};

	const generateStrongPassword = () => {
		const length = 12;
		const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
		let password = "";
		for (var i = 0, n = charset.length; i < length; ++i) {
			password += charset.charAt(Math.floor(Math.random() * n));
		}
		setFormData({ ...formData, password, confirmPassword: password });
		setPasswordValidateError('');
		setPasswordError('');
		setGeneratedPasswordUsed(true);
	};

	const minWidth = useBreakpointValue({ base: '100%', md: '400px' });

	return (
		<AccountLayout>
			<Row>
				<Card>
					<div style={{ minWidth: minWidth }}>
						<h1 style={{ marginBottom: '20px' }} className='text-left'>Create a new user</h1>
						<Form onSubmit={submitHandler}>

							<Row form="true" style={rowStyle}>
								<Col md={12}>
									<FormGroup className="text-left">
										<Label for="privilege">User Role</Label>
										<Input
											type="select"
											name="privilege"
											value={formData.privilege}
											onChange={handleChange}
										>
											<option value="Admin">Admin</option>
											<option value="Editor">Editor</option>
											<option value="Viewer">Viewer</option>
										</Input>
									</FormGroup>
									<FormGroup className="text-left">
										<Label for="branchId">Branch</Label>
										<Input
											type="select"
											name="branchId"
											value={formData.branchId}
											onChange={handleChange}
											required
										>
											<option value="">Select a branch</option>
											{branches.map((branch) => (
												<option key={branch.value} value={branch.value}>{branch.label}</option>
											))}
										</Input>
									</FormGroup>
									<FormGroup className="text-left">
										<Label for="givenName">Username</Label>
										<Input
											type="text"
											className="form-control"
											name="userName"
											value={formData.userName}
											onChange={handleChange}
											required
										/>
									</FormGroup>
									<FormGroup className="text-left">
										<Label for="givenName">Email</Label>
										<Input
											type="email"
											className="form-control"
											name="email"
											value={formData.email}
											onChange={handleChange}
											required
										/>
									</FormGroup>
									<FormGroup className="text-left">
										<Label for="givenName">Password</Label>
										<div className="input-group">
											<Input
												type={passwordShown ? "text" : "password"}
												className="form-control"
												name="password"
												value={formData.password}
												onChange={handleChange}
												required
											/>
											<div className="input-group-append">
												<button className="btn btn-outline-secondary rounded-0" type="button" onClick={togglePasswordVisiblity}>
													{passwordShown ? 'Hide' : 'Show'}
												</button>
											</div>
										</div>
										{passwordValidateError && <div style={{ color: '#ff0000' }}>{passwordValidateError}</div>}
									</FormGroup>
									<FormGroup className="text-left">
										<Label for="givenName">Confirm Password</Label>
										<div className="input-group">
											<input
												type={confirmPasswordShown ? "text" : "password"}
												className="form-control"
												name="confirmPassword"
												value={formData.confirmPassword}
												onChange={handleChange}
												required
											/>
											<div className="input-group-append">
												<button className="btn btn-outline-secondary rounded-0" type="button" onClick={toggleConfirmPasswordVisiblity}>
													{confirmPasswordShown ? 'Hide' : 'Show'}
												</button>
											</div>
										</div>
										{passwordError && <div style={{ color: '#ff0000' }}>{passwordError}</div>}
									</FormGroup>

									<FormGroup style={{ display: 'flex', gap: '20px' }}>
										<button type="button" className="btn btn-secondary btn-block" onClick={generateStrongPassword}>
											Generate Strong Password
										</button>

										{generatedPasswordUsed && (
											<button type="button" className="btn btn-info btn-block" onClick={resetPasswordFields}>
												Choose Your Own Password
											</button>
										)}
									</FormGroup>
									<FormGroup>
										<button type="submit" className="btn btn-primary btn-block">Register</button>
									</FormGroup>

									{successMessage && (
										<div className="alert alert-success alert-dismissible fade show" role="alert">
											{successMessage}
											<button
												type="button"
												className="close"
												data-dismiss="alert"
												aria-label="Close"
												onClick={() => setSuccessMessage('')}
											>
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
									)}

									{errorMessage && (
										<div className="alert alert-danger alert-dismissible fade show" role="alert">
											{errorMessage}
											<button
												type="button"
												className="close"
												data-dismiss="alert"
												aria-label="Close"
												onClick={() => setErrorMessage('')}
											>
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
									)}
								</Col>
							</Row>

						</Form>
					</div>
				</Card>
			</Row>
		</AccountLayout>
	);
};

export default CreateUser;
