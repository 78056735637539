import React from 'react';
import Main from '../global/wrapper/Main';

function NotFound() {
	return (
		<Main>
			<h1>404 - Not Found</h1>
			<p>Sorry, the page you are looking for does not exist.</p>
		</Main>
	);
}

export default NotFound;
