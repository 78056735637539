const BrunswickCourses = [
	{ value: 'Bachelor of Arts in Anthropology', label: 'Bachelor of Arts in Anthropology' },
	{ value: 'Bachelor of Arts in Classical Studies', label: 'Bachelor of Arts in Classical Studies' },
	{ value: 'Bachelor of Arts in Gender and Women\'s Studies', label: 'Bachelor of Arts in Gender and Women\'s Studies' },
	{ value: 'Bachelor of Arts in Gender Studies', label: 'Bachelor of Arts in Gender Studies' },
	{ value: 'Bachelor of Arts in Media Arts and Cultures', label: 'Bachelor of Arts in Media Arts and Cultures' },
	{ value: 'Bachelor of Arts in Music', label: 'Bachelor of Arts in Music' },
	{ value: 'Bachelor of Arts in Philosophy', label: 'Bachelor of Arts in Philosophy' },
	{ value: 'Bachelor of Science in Engineering- Geodesy and Geomatics Engineering', label: 'Bachelor of Science in Engineering- Geodesy and Geomatics Engineering' },
	{ value: 'Bachelor of Science in Engineering- Electrical Engineering', label: 'Bachelor of Science in Engineering- Electrical Engineering' },
	{ value: 'Master of Business Administration - Project Management', label: 'Master of Business Administration - Project Management' },
	{ value: 'Bachelor of Applied Management in Electronic Commerce', label: 'Bachelor of Applied Management in Electronic Commerce' },
	{ value: 'Bachelor of Applied Management in General Business', label: 'Bachelor of Applied Management in General Business' },
	{ value: 'Bachelor of Arts in Biology', label: 'Bachelor of Arts in Biology' },
	{ value: 'Bachelor of Arts in Creative Writing', label: 'Bachelor of Arts in Creative Writing' },
	{ value: 'Bachelor of Arts in Criminal Justice Studies', label: 'Bachelor of Arts in Criminal Justice Studies' },
	{ value: 'Bachelor of Arts in Drama', label: 'Bachelor of Arts in Drama' },
	{ value: 'Bachelor of Arts in Economic Studies', label: 'Bachelor of Arts in Economic Studies' },
	{ value: 'Bachelor of Arts in Economics', label: 'Bachelor of Arts in Economics' },
	{ value: 'Bachelor of Arts in English', label: 'Bachelor of Arts in English' },
	{ value: 'Bachelor of Arts in History', label: 'Bachelor of Arts in History' }
];

export default BrunswickCourses;

