const DurhamCourses = [
	{ value: 'Certificate in Office Administration – General', label: 'Certificate in Office Administration – General' },
	{ value: 'Diploma in Supply Chain and Operations – Business – Transfer to UOIT Bachelor of Commerce (Hons)', label: 'Diploma in Supply Chain and Operations – Business – Transfer to UOIT Bachelor of Commerce (Hons)' },
	{ value: 'Diploma in Chemical Laboratory Technician', label: 'Diploma in Chemical Laboratory Technician' },
	{ value: 'Advanced Diploma in Marketing – Business Administration', label: 'Advanced Diploma in Marketing – Business Administration' },
	{ value: 'Advanced Diploma in Computer Systems Technology ( Co-Op option available )', label: 'Advanced Diploma in Computer Systems Technology ( Co-Op option available )' },
	{ value: 'Advanced Diploma in Finance – Business Administration', label: 'Advanced Diploma in Finance – Business Administration' },
	{ value: 'Certificate in Mechanical Techniques – Plumbing', label: 'Certificate in Mechanical Techniques – Plumbing' },
	{ value: 'Graduate Certificate in Addictions and Mental Health', label: 'Graduate Certificate in Addictions and Mental Health' },
	{ value: 'Graduate Certificate in Cybersecurity (aka Information Systems Security - Computer and Networking)', label: 'Graduate Certificate in Cybersecurity (aka Information Systems Security - Computer and Networking)' },
	{ value: 'Diploma in Electrical Engineering Technician', label: 'Diploma in Electrical Engineering Technician' },
	{ value: 'Graduate Certificate in Project Management', label: 'Graduate Certificate in Project Management' },
	{ value: 'Diploma in Supply Chain and Operations – Business', label: 'Diploma in Supply Chain and Operations – Business' },
	{ value: 'Diploma in Human Resources – Business', label: 'Diploma in Human Resources – Business' },
	{ value: 'Diploma in Office Administration – Health Services', label: 'Diploma in Office Administration – Health Services' },
	{ value: 'Diploma in Paralegal', label: 'Diploma in Paralegal' },
	{ value: 'Graduate Certificate in Cloud Computing', label: 'Graduate Certificate in Cloud Computing' },
	{ value: 'Certificate in Personal Support Worker', label: 'Certificate in Personal Support Worker' },
	{ value: 'Certificate in Hospitality Skills', label: 'Certificate in Hospitality Skills' },
	{ value: 'Graduate Certificate in Data Analytics for Business Decision Making', label: 'Graduate Certificate in Data Analytics for Business Decision Making' },
	{ value: 'Graduate Certificate in Autism and Behavioural Sciences', label: 'Graduate Certificate in Autism and Behavioural Sciences' },
	{ value: 'Graduate Certificate in Professional Selling and Business Development – Canadian Context', label: 'Graduate Certificate in Professional Selling and Business Development – Canadian Context' },
	{ value: 'Certificate in Gas Technician 2', label: 'Certificate in Gas Technician 2' },
	{ value: 'Diploma In Interactive Media Design – Web Development', label: 'Diploma In Interactive Media Design – Web Development' },
	{ value: 'Diploma In Interactive Media Design', label: 'Diploma In Interactive Media Design' },
	{ value: 'Certificate in Business Fundamentals', label: 'Certificate in Business Fundamentals' },
	{ value: 'Advanced Diploma in Supply Chain and Operations Management – Business Administration', label: 'Advanced Diploma in Supply Chain and Operations Management – Business Administration' },
	{ value: 'Certificate in Pre-Health Sciences Pathway to Advanced Diplomas and Degrees', label: 'Certificate in Pre-Health Sciences Pathway to Advanced Diplomas and Degrees' },
	{ value: 'Diploma in Computer Systems Technician', label: 'Diploma in Computer Systems Technician' },
	{ value: 'Diploma in Entrepreneurship and Small Business – Business', label: 'Diploma in Entrepreneurship and Small Business – Business' },
	{ value: 'Diploma in Marketing – Business', label: 'Diploma in Marketing – Business' },
	{ value: 'Diploma in Office Administration – Executive', label: 'Diploma in Office Administration – Executive' },
	{ value: 'Diploma in Finance - Business – Transfer to UOIT Bachelor of Commerce (Hons)', label: 'Diploma in Finance - Business – Transfer to UOIT Bachelor of Commerce (Hons)' },
	{ value: 'Diploma in Accounting – Business', label: 'Diploma in Accounting – Business' },
	{ value: 'Graduate Certificate in Artificial Intelligence Analysis, Design and Implementation (Optional Co-op)', label: 'Graduate Certificate in Artificial Intelligence Analysis, Design and Implementation (Optional Co-op)' },
	{ value: 'Diploma in Practical Nursing', label: 'Diploma in Practical Nursing' },
	{ value: 'Advanced Diploma in Biotechnology – Advanced (fast-track)', label: 'Advanced Diploma in Biotechnology – Advanced (fast-track)' },
	{ value: 'Diploma in Computer Systems Technician – Transfer to UOIT Bachelor of Information Technology (Hons)', label: 'Diploma in Computer Systems Technician – Transfer to UOIT Bachelor of Information Technology (Hons)' },
	{ value: 'Diploma in Human Resources – Business – Transfer to UOIT Bachelor of Commerce (Hons)', label: 'Diploma in Human Resources – Business – Transfer to UOIT Bachelor of Commerce (Hons)' },
	{ value: 'Advanced Diploma in Computer Programmer Analyst', label: 'Advanced Diploma in Computer Programmer Analyst' },
	{ value: 'Graduate Certificate in International Business Management', label: 'Graduate Certificate in International Business Management' },
	{ value: 'Diploma in Culinary Management', label: 'Diploma in Culinary Management' },
	{ value: 'Diploma in Hospitality – Hotel and Restaurant Operations Management', label: 'Diploma in Hospitality – Hotel and Restaurant Operations Management' },
	{ value: 'Diploma in Office Administration – Health Services (Four consecutive semesters)', label: 'Diploma in Office Administration – Health Services (Four consecutive semesters)' },
	{ value: 'Diploma in Finance – Business', label: 'Diploma in Finance – Business' },
	{ value: 'Certificate in Pre-Health Sciences Pathway to Certificates and Diplomas', label: 'Certificate in Pre-Health Sciences Pathway to Certificates and Diplomas' },
	{ value: 'Diploma in Social Service Worker', label: 'Diploma in Social Service Worker' },
	{ value: 'Advanced Diploma in Accounting – Business Administration', label: 'Advanced Diploma in Accounting – Business Administration' },
	{ value: 'Diploma in Fitness and Health Promotion', label: 'Diploma in Fitness and Health Promotion' },
	{ value: 'Graduate Certificate in Human Resources Management', label: 'Graduate Certificate in Human Resources Management' },
	{ value: 'Diploma in Computer Programming', label: 'Diploma in Computer Programming' },
	{ value: 'Graduate Certificate in Professional Selling and Business Development (24 months)', label: 'Graduate Certificate in Professional Selling and Business Development (24 months)' },
	{ value: 'Certificate in Dental Assisting (Levels I and II)', label: 'Certificate in Dental Assisting (Levels I and II)' },
	{ value: 'Certificate in Foundations in Art and Design', label: 'Certificate in Foundations in Art and Design' },
	{ value: 'Diploma in Police Foundations', label: 'Diploma in Police Foundations' },
	{ value: 'Diploma in Early Childhood Education', label: 'Diploma in Early Childhood Education' },
	{ value: 'Advanced Diploma in Human Resources – Business Administration', label: 'Advanced Diploma in Human Resources – Business Administration' },
	{ value: 'Diploma in Protection, Security and Investigation', label: 'Diploma in Protection, Security and Investigation' },
	{ value: 'Certificate in General Arts and Science', label: 'Certificate in General Arts and Science' },
	{ value: 'Advanced Diploma in Computer Programming and Analysis (Optional Co-op)', label: 'Advanced Diploma in Computer Programming and Analysis (Optional Co-op)' },
	{ value: 'Graduate Certificate in Supply Chain Management - Global', label: 'Graduate Certificate in Supply Chain Management - Global' }
];

export default DurhamCourses;
