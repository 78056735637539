const AmericanCollegeDublinCourses = [
	{ label: "Bachelor of Fine Arts in Musical Theatre", value: "Bachelor of Fine Arts in Musical Theatre" },
	{ label: "Bachelor of Fine Arts in Performing Arts", value: "Bachelor of Fine Arts in Performing Arts" },
	{ label: "Bachelor of Fine Arts in Creative Writing", value: "Bachelor of Fine Arts in Creative Writing" },
	{ label: "Bachelor of Arts (Hons) in Liberal Arts", value: "Bachelor of Arts (Hons) in Liberal Arts" },
	{ label: "BA (Hons) in International Business", value: "BA (Hons) in International Business" },
	{ label: "Bachelor of Arts in Event Management", value: "Bachelor of Arts in Event Management" },
	{ label: "Bachelor of Arts in Hospitality Management", value: "Bachelor of Arts in Hospitality Management" },
	{ label: "Master of Fine Arts in Fashion Design", value: "Master of Fine Arts in Fashion Design" },
	{ label: "Master of Fine Arts in Creative Writing Practice", value: "Master of Fine Arts in Creative Writing Practice" },
	{ label: "Master of Business Administration", value: "Master of Business Administration" },
	{ label: "Master of Business in International Business", value: "Master of Business in International Business" },
	{ label: "MBA in Oil and Gas Management", value: "MBA in Oil and Gas Management" }
];

export default AmericanCollegeDublinCourses;