import React, { useState } from 'react';
import { Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import Card from '../global/holder/Card';
import AccountLayout from '../layout/AccountLayout';
import { useToast, useBreakpointValue } from '@chakra-ui/react';

const CreateBranch = () => {
	const toast = useToast();
	const rowStyle = {
		marginBottom: '15px'
	};

	const [branchName, setBranchName] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const [errorMessage, setErrorMessage] = useState('');

	const submitHandler = async (e) => {
		e.preventDefault();

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/branches`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ branchName }),
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			toast({
				title: 'Success',
				description: 'Branch created successfully',
				status: 'success',
				duration: 5000,
				isClosable: true,
			});
			setBranchName('');
		} catch (error) {
			console.error('Error:', error);
			setErrorMessage('Error creating branch. Please try again.');
		}
	};

	const minWidth = useBreakpointValue({ base: '100%', md: '500px' });

	return (
		<AccountLayout>
			<Row>
				<Card>
					<div style={{ minWidth: minWidth }}>
						<h1 style={{ marginBottom: '20px' }} className='text-left'>Create a new branch</h1>
						<Form onSubmit={submitHandler}>
							<Row form="true" style={rowStyle}>
								<Col md={10}>
									<FormGroup className="text-left">
										<Label for="branchName">Branch Name</Label>
										<Input
											type="text"
											className="form-control"
											name="branchName"
											value={branchName}
											onChange={(e) => setBranchName(e.target.value)}
											required
										/>
									</FormGroup>
									<FormGroup>
										<button type="submit" className="btn btn-primary btn-block">Create Branch</button>
									</FormGroup>

									{successMessage && (
										<div className="alert alert-success alert-dismissible fade show" role="alert">
											{successMessage}
											<button
												type="button"
												className="close"
												data-dismiss="alert"
												aria-label="Close"
												onClick={() => setSuccessMessage('')}
											>
												<span aria-hidden="true">×</span>
											</button>
										</div>
									)}

									{errorMessage && (
										<div className="alert alert-danger alert-dismissible fade show" role="alert">
											{errorMessage}
											<button
												type="button"
												className="close"
												data-dismiss="alert"
												aria-label="Close"
												onClick={() => setErrorMessage('')}
											>
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
									)}
								</Col>
							</Row>
						</Form>
					</div>
				</Card>
			</Row>
		</AccountLayout>
	);
};


export default CreateBranch;