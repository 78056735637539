const EcoleConteCourses = [
	{ value: "Master of Science in Fashion and Luxury Goods Management", label: "Master of Science in Fashion and Luxury Goods Management" },
	{ value: "Ms Food & Beverage Management", label: "Ms Food & Beverage Management" },
	{ value: "Bachelor Design 360°", label: "Bachelor Design 360°" },
	{ value: "Bachelor of Arts Design 360° - Specialising in Object Design", label: "Bachelor of Arts Design 360° - Specialising in Object Design" },
	{ value: "Bachelor of Arts Design 360° - Specialising in Interior Design", label: "Bachelor of Arts Design 360° - Specialising in Interior Design" },
	{ value: "Bachelor of Arts Design 360° - Specialising in Textile Design", label: "Bachelor of Arts Design 360° - Specialising in Textile Design" },
	{ value: "Master of Business Administration in Fashion and Luxury Goods Management", label: "Master of Business Administration in Fashion and Luxury Goods Management" }
];

export default EcoleConteCourses;