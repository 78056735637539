import React, { useState } from 'react';
import { FormGroup, Label, Row, Col, Input } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../utils/AuthContext';
import { Card } from '../global/holder/Card';
import { VStack, Box, Center, Button, useToast, Link as ChakraLink } from '@chakra-ui/react';
import AccountLayout from '../layout/AccountLayout';

const PasswordResetRequest = () => {
	const toast = useToast();
	const navigate = useNavigate();
	const { isLoggedIn } = useAuth();
	const [email, setEmail] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	// const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/request-password-reset`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ email })
			});
			if (!response.ok) {
				throw new Error('Failed to send reset request');
			}
			const contentType = response.headers.get("content-type");
			if (contentType && contentType.includes("application/json")) {
				const data = await response.json();
				// setSuccessMessage(data.message);
				toast({
					title: 'Success',
					description: data.message,
					status: 'success',
					duration: 5000,
					isClosable: true,
				});
				setEmail('');
			}
			setErrorMessage('');
		} catch (error) {
			setSuccessMessage('');
			toast({
				title: 'Failed',
				description: 'Please provide a valid email',
				status: 'error',
				duration: 5000,
				isClosable: true,
			});
		}
	};

	return (
		<>
			{!isLoggedIn ?
				<Center h="100vh">
					<Card>
						<VStack spacing={4} align="stretch" minWidth={{ base: '100%', md: '400px' }}>
							<h1>Reset Password</h1>
							<Box as="form" onSubmit={handleSubmit}>
								<Row form="true">
									<Col md={12}>
										<FormGroup className="text-left">
											<Label for="usernameOrEmail">Provide a valid email</Label>
											<Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
										</FormGroup>
										<FormGroup className='text-center'>
											<Button mt={4} colorScheme="blue" type="submit">
												Send Reset Link
											</Button>
										</FormGroup>

										<FormGroup style={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
											<div><span>Changed your mind? </span><span>
												<ChakraLink
													color="teal.500"
													ml={2}
													onClick={() => navigate('/login')}
												>
													Login Now
												</ChakraLink>
											</span></div>
										</FormGroup>

										{successMessage && (
											<div className="alert alert-success alert-dismissible fade show" role="alert">
												{successMessage}
												<button
													type="button"
													className="close"
													data-dismiss="alert"
													aria-label="Close"
													onClick={() => setSuccessMessage('')}
												>
													<span aria-hidden="true">&times;</span>
												</button>
											</div>
										)}

										{errorMessage && (
											<div className="alert alert-danger alert-dismissible fade show" role="alert">
												{errorMessage}
												<button
													type="button"
													className="close"
													data-dismiss="alert"
													aria-label="Close"
													onClick={() => setErrorMessage('')}
												>
													<span aria-hidden="true">&times;</span>
												</button>
											</div>
										)}
									</Col>
								</Row>
							</Box>
						</VStack>
					</Card>
				</Center>
				:
				<AccountLayout>
					<Card>
						<VStack spacing={4} align="stretch" minWidth={{ base: '100%', md: '400px' }}>
							<h1>Reset Password</h1>
							<Box as="form" onSubmit={handleSubmit}>
								<Row form="true">
									<Col md={10}>
										<FormGroup className="text-left">
											<Label for="usernameOrEmail">Provide a valid email</Label>
											<Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
										</FormGroup>
										<FormGroup>
											<button type="submit" className="btn btn-primary btn-block">Send Reset Link</button>
										</FormGroup>

										{errorMessage && (
											<div className="alert alert-danger alert-dismissible fade show" role="alert">
												{errorMessage}
												<button
													type="button"
													className="close"
													data-dismiss="alert"
													aria-label="Close"
													onClick={() => setErrorMessage('')}
												>
													<span aria-hidden="true">&times;</span>
												</button>
											</div>
										)}
									</Col>
								</Row>
							</Box>
						</VStack>
					</Card>
				</AccountLayout>
			}
		</>
	);
};

export default PasswordResetRequest;
