const CoventryUniversityCourses = [
	{ value: "Early Childhood Development & Learning HND", label: "Early Childhood Development & Learning HND" },
	{ value: "Law & Practice Higher National Certificate (HNC)", label: "Law & Practice Higher National Certificate (HNC)" },
	{ value: "Global Business top up BA (Hons)", label: "Global Business top up BA (Hons)" },
	{ value: "Digital Media BA (Hons) - sandwich", label: "Digital Media BA (Hons) - sandwich" },
	{ value: "Photography BA (Hons) - with study abroad / professional placement", label: "Photography BA (Hons) - with study abroad / professional placement" },
	{ value: "English BA (Hons) - with study abroad / professional placement", label: "English BA (Hons) - with study abroad / professional placement" },
	{ value: "Biological and Forensic Science BSc (Hons) - sandwich", label: "Biological and Forensic Science BSc (Hons) - sandwich" },
	{ value: "Food Science BSc (Hons) - sandwich", label: "Food Science BSc (Hons) - sandwich" },
	{ value: "Geography BSc (Hons) - sandwich", label: "Geography BSc (Hons) - sandwich" },
	{ value: "Ethical Hacking and Cybersecurity MSci", label: "Ethical Hacking and Cybersecurity MSci" },
	{ value: "Dietetics BSc (Hons)", label: "Dietetics BSc (Hons)" },
	{ value: "Learning Disabilities Nursing BSc (Hons)", label: "Learning Disabilities Nursing BSc (Hons)" },
	{ value: "Product Design BA (Hons)", label: "Product Design BA (Hons)" },
	{ value: "Quantity Surveying and Commercial Management BSc (Hons)", label: "Quantity Surveying and Commercial Management BSc (Hons)" },
	{ value: "Accounting and Finance BSc (Hons)", label: "Accounting and Finance BSc (Hons)" },
	{ value: "Financial Economics and Banking BSc (Hons)", label: "Financial Economics and Banking BSc (Hons)" },
	{ value: "Languages for Global Communication BA (Hons)", label: "Languages for Global Communication BA (Hons)" },
	{ value: "Accounting and Finance for International Business BSc (Hons) top-up 1Yr", label: "Accounting and Finance for International Business BSc (Hons) top-up 1Yr" },
	{ value: "Applied Mechanical Engineering BSc (Hons) top-up", label: "Applied Mechanical Engineering BSc (Hons) top-up" },
	{ value: "Project Management MSc", label: "Project Management MSc" },
	{ value: "Digital Technology for Business MSc (Work placement option)", label: "Digital Technology for Business MSc (Work placement option)" },
	{ value: "Career Development and Management PGDip", label: "Career Development and Management PGDip" },
	{ value: "Manufacturing Engineering BEng (Hons) - sandwich", label: "Manufacturing Engineering BEng (Hons) - sandwich" },
	{ value: "Business Administration BBA (Hons) - sandwich", label: "Business Administration BBA (Hons) - sandwich" },
	{ value: "Business and Marketing BA (Hons) - sandwich", label: "Business and Marketing BA (Hons) - sandwich" },
	{ value: "Food Safety, Inspection and Control BSc (Hons) (study abroad or work placement)", label: "Food Safety, Inspection and Control BSc (Hons) (study abroad or work placement)" },
	{ value: "Geography and Natural Hazards BSc (Hons) - sandwich", label: "Geography and Natural Hazards BSc (Hons) - sandwich" },
	{ value: "Adult Nursing Blended Learning BSc (Hons)", label: "Adult Nursing Blended Learning BSc (Hons)" },
	{ value: "Construction Management BSc (Hons)", label: "Construction Management BSc (Hons)" },
	{ value: "Ethical Hacking and Cybersecurity BSc (Hons)", label: "Ethical Hacking and Cybersecurity BSc (Hons)" },
	{ value: "Games Technology BSc (Hons)", label: "Games Technology BSc (Hons)" },
	{ value: "Photography BA (Hons)", label: "Photography BA (Hons)" },
	{ value: "Design Management MA", label: "Design Management MA" },
	{ value: "Disaster Management and Resilience MSc", label: "Disaster Management and Resilience MSc" },
	{ value: "Photography MA", label: "Photography MA" },
	{ value: "Control, Automation and Artificial Intelligence MSc (With Work Placement)", label: "Control, Automation and Artificial Intelligence MSc (With Work Placement)" },
	{ value: "Financial Economics and Banking BSc (Hons) -With Sandwich Placement", label: "Financial Economics and Banking BSc (Hons) -With Sandwich Placement" },
	{ value: "Global Events Management BSc (Hons)-With Sandwich Placement", label: "Global Events Management BSc (Hons)-With Sandwich Placement" },
	{ value: "Master of Science (MSc) in Financial Technology (FinTech) -MSc", label: "Master of Science (MSc) in Financial Technology (FinTech) -MSc" },
	{ value: "Master of Science (MSc) in Professional Accounting MSc", label: "Master of Science (MSc) in Professional Accounting MSc" },
	{ value: "Master of Science in Professional Accounting MSc(with  Professional Practice)", label: "Master of Science in Professional Accounting MSc(with  Professional Practice)" },
	{ value: "Sport Performance & Coaching Foundation Year", label: "Sport Performance & Coaching Foundation Year" },
	{ value: "Architectural Technology BSc (Hons) - sandwich", label: "Architectural Technology BSc (Hons) - sandwich" },
	{ value: "Automotive Engineering BEng (Hons) - sandwich", label: "Automotive Engineering BEng (Hons) - sandwich" },
	{ value: "Civil Engineering BEng (Hons) - sandwich", label: "Civil Engineering BEng (Hons) - sandwich" },
	{ value: "International Fashion Business BA (Hons) - with study abroad / professional placement", label: "International Fashion Business BA (Hons) - with study abroad / professional placement" },
	{ value: "Marketing BA (Hons) - (study abroad or work placement)", label: "Marketing BA (Hons) - (study abroad or work placement)" },
	{ value: "Civil Engineering MEng", label: "Civil Engineering MEng" },
	{ value: "History BA (Hons)", label: "History BA (Hons)" },
	{ value: "Forensic Psychology BSc (Hons)", label: "Forensic Psychology BSc (Hons)" },
	{ value: "Finance MBus", label: "Finance MBus" },
	{ value: "MSc Global Logistics", label: "MSc Global Logistics" },
	{ value: "Brand Management MA", label: "Brand Management MA" },
	{ value: "PGCE (Postgraduate Certificate in Education) for International Schools", label: "PGCE (Postgraduate Certificate in Education) for International Schools" },
	{ value: "Protective Security and Resilience MSc", label: "Protective Security and Resilience MSc" },
	{ value: "Postgraduate Certificate in Entrepreneurship", label: "Postgraduate Certificate in Entrepreneurship" },
	{ value: "Master of Science (MSc) in Global Financial Trading - MSc", label: "Master of Science (MSc) in Global Financial Trading - MSc" },
	{ value: "Master of Business Administration in Global Business - MBA", label: "Master of Business Administration in Global Business - MBA" },
	{ value: "Engineering Foundation Year", label: "Engineering Foundation Year" },
	{ value: "Aerospace Systems Engineering BEng (Hons) -  sandwich", label: "Aerospace Systems Engineering BEng (Hons) -  sandwich" },
	{ value: "Accounting and Finance BSc (Hons) - With Placement", label: "Accounting and Finance BSc (Hons) - With Placement" },
	{ value: "Languages for Global Communication BA (Hons) - with study abroad / professional placement", label: "Languages for Global Communication BA (Hons) - with study abroad / professional placement" },
	{ value: "Mathematics and Physics BSc (Hons) - sandwich", label: "Mathematics and Physics BSc (Hons) - sandwich" },
	{ value: "Criminology and Psychology BA (Hons)", label: "Criminology and Psychology BA (Hons)" },
	{ value: "Mechanical Engineering BEng (Hons)", label: "Mechanical Engineering BEng (Hons)" },
	{ value: "Economics BSc (Hons)", label: "Economics BSc (Hons)" },
	{ value: "Construction Project and Cost Management MSc", label: "Construction Project and Cost Management MSc" },
	{ value: "English Literature MA", label: "English Literature MA" },
	{ value: "Automotive Journalism MA", label: "Automotive Journalism MA" },
	{ value: "Professional Accountancy MSc", label: "Professional Accountancy MSc" },
	{ value: "Policing Practice Higher National Certificate (HNC)", label: "Policing Practice Higher National Certificate (HNC)" },
	{ value: "Computing Science BSc (Hons)", label: "Computing Science BSc (Hons)" },
	{ value: "Animation and Illustration BA (Hons) - with study abroad / professional placement", label: "Animation and Illustration BA (Hons) - with study abroad / professional placement" },
	{ value: "Film Production BA (Hons) - (study abroad or work placement)", label: "Film Production BA (Hons) - (study abroad or work placement)" },
	{ value: "Interior Architecture and Design BA (Hons) -  study abroad / professional placement", label: "Interior Architecture and Design BA (Hons) -  study abroad / professional placement" },
	{ value: "Sport & Leisure Management BA (Hons)", label: "Sport & Leisure Management BA (Hons)" },
	{ value: "Games Art BA (Hons)  - with study abroad / professional placement", label: "Games Art BA (Hons)  - with study abroad / professional placement" },
	{ value: "History and Politics BA (Hons) - with study abroad / professional placement", label: "History and Politics BA (Hons) - with study abroad / professional placement" },
	{ value: "Psychology BSc (Hons) - (study abroad or work placement)", label: "Psychology BSc (Hons) - (study abroad or work placement)" },
	{ value: "Computer Science MSci", label: "Computer Science MSci" },
	{ value: "Motorsport Engineering MEng", label: "Motorsport Engineering MEng" },
	{ value: "Criminology and Law BA (Hons)", label: "Criminology and Law BA (Hons)" },
	{ value: "International Business Management BSc (Hons)", label: "International Business Management BSc (Hons)" },
	{ value: "Sport and Exercise Science BSc (Hons)", label: "Sport and Exercise Science BSc (Hons)" },
	{ value: "International Finance and Banking BSc (Hons) top-up -  (Second year entry)", label: "International Finance and Banking BSc (Hons) top-up -  (Second year entry)" },
	{ value: "Forensic Psychology and Mental Health MSc", label: "Forensic Psychology and Mental Health MSc" },
	{ value: "Global Health Care Management MSc", label: "Global Health Care Management MSc" },
	{ value: "Connected Autonomous Vehicles Engineering MSc", label: "Connected Autonomous Vehicles Engineering MSc" },
	{ value: "Master of Business Administration", label: "Master of Business Administration" },
	{ value: "Engineering Management MSc (Work placement option)", label: "Engineering Management MSc (Work placement option)" },
	{ value: "Air Transport Management MSc (With Work Placement)", label: "Air Transport Management MSc (With Work Placement)" },
	{ value: "Early Childhood Development & Learning BA (Hons)", label: "Early Childhood Development & Learning BA (Hons)" },
	{ value: "Business Foundation Year", label: "Business Foundation Year" },
	{ value: "Journalism and English BA (Hons) - with study abroad / professional placement", label: "Journalism and English BA (Hons) - with study abroad / professional placement" },
	{ value: "Mechanical Engineering MEng", label: "Mechanical Engineering MEng" },
	{ value: "Music Technology BSc (Hons) - with study abroad / professional placement", label: "Music Technology BSc (Hons) - with study abroad / professional placement" },
	{ value: "Physics MSci", label: "Physics MSci" },
	{ value: "Popular Music Performance and Songwriting BA (Hons) - with study abroad / professional placement", label: "Popular Music Performance and Songwriting BA (Hons) - with study abroad / professional placement" },
	{ value: "Criminology and Psychology BA (Hons)  - (study abroad or work placement)", label: "Criminology and Psychology BA (Hons)  - (study abroad or work placement)" },
	{ value: "English Literature BA (Hons) - with study abroad / professional placement", label: "English Literature BA (Hons) - with study abroad / professional placement" },
	{ value: "Sport and Exercise Science BSc (Hons) - (study abroad or work placement)", label: "Sport and Exercise Science BSc (Hons) - (study abroad or work placement)" },
	{ value: "Marketing BA (Hons)", label: "Marketing BA (Hons)" },
	{ value: "Diagnostic Radiography BSc", label: "Diagnostic Radiography BSc" },
	{ value: "Automotive and Transport Design MA", label: "Automotive and Transport Design MA" },
	{ value: "Management of Information Systems and Technology MSc", label: "Management of Information Systems and Technology MSc" },
	{ value: "Agroecology, Water and Food Sovereignty MSc", label: "Agroecology, Water and Food Sovereignty MSc" },
	{ value: "Children and Young People's Nursing (Pre-registration) MSc", label: "Children and Young People's Nursing (Pre-registration) MSc" },
	{ value: "Global Business Management BA (Hons) -With Sandwich Placement", label: "Global Business Management BA (Hons) -With Sandwich Placement" },
	{ value: "International Finance and Accounting BA (Hons)-With Sandwich Placement", label: "International Finance and Accounting BA (Hons)-With Sandwich Placement" },
	{ value: "Building Surveying BSc (Hons) - sandwich", label: "Building Surveying BSc (Hons) - sandwich" },
	{ value: "Journalism BA (Hons) - with study abroad / professional placement", label: "Journalism BA (Hons) - with study abroad / professional placement" },
	{ value: "Public Health BSc (Hons)", label: "Public Health BSc (Hons)" },
	{ value: "Financial Economics BSc (Hons)", label: "Financial Economics BSc (Hons)" },
	{ value: "Health & Social Care Foundation Year", label: "Health & Social Care Foundation Year" },
	{ value: "Geography BA (Hons) - sandwich", label: "Geography BA (Hons) - sandwich" },
	{ value: "Civil Engineering BSc (Hons)", label: "Civil Engineering BSc (Hons)" },
	{ value: "Criminology BA (Hons)", label: "Criminology BA (Hons)" },
	{ value: "Fashion BA (Hons)", label: "Fashion BA (Hons)" },
	{ value: "Occupational Therapy BSc (Hons)", label: "Occupational Therapy BSc (Hons)" },
	{ value: "Foundation Diploma in Art, Design and Media", label: "Foundation Diploma in Art, Design and Media" },
	{ value: "Data Science MSc", label: "Data Science MSc" },
	{ value: "Cyber Security MSc", label: "Cyber Security MSc" },
	{ value: "Diplomacy, Law and Global Change MA", label: "Diplomacy, Law and Global Change MA" },
	{ value: "History MA", label: "History MA" },
	{ value: "Sustainability and Environmental Management MSc", label: "Sustainability and Environmental Management MSc" },
	{ value: "Production Engineering and Operations Management MSc", label: "Production Engineering and Operations Management MSc" },
	{ value: "Emergency and Incident Management PG Cert", label: "Emergency and Incident Management PG Cert" },
	{ value: "Automotive Engineering MSc (With Work Placement Option)", label: "Automotive Engineering MSc (With Work Placement Option)" },
	{ value: "Renewable Energy Engineering MSc (Work placement option)", label: "Renewable Energy Engineering MSc (Work placement option)" },
	{ value: "Protective Security and Resilience PGCert", label: "Protective Security and Resilience PGCert" },
	{ value: "Pharmacology and Drug Discovery MSc (with Professional Experience)", label: "Pharmacology and Drug Discovery MSc (with Professional Experience)" },
	{ value: "Master of Science in Financial Technology (FinTech) -MSc  (with Professional Practice)", label: "Master of Science in Financial Technology (FinTech) -MSc  (with Professional Practice)" },
	{ value: "Master of Science (MSc) in Enterprise and Innovation MSc", label: "Master of Science (MSc) in Enterprise and Innovation MSc" },
	{ value: "Games Technology BSc (Hons) - sandwich", label: "Games Technology BSc (Hons) - sandwich" },
	{ value: "Finance BSc (Hons) - sandwich", label: "Finance BSc (Hons) - sandwich" },
	{ value: "Mathematics BSc (Hons) - sandwich", label: "Mathematics BSc (Hons) - sandwich" },
	{ value: "Data Science MSci", label: "Data Science MSci" },
	{ value: "Automotive and Transport Design BA (Hons)", label: "Automotive and Transport Design BA (Hons)" },
	{ value: "Computer Science BSc (Hons)", label: "Computer Science BSc (Hons)" },
	{ value: "Popular Music Performance and Songwriting BA (Hons)", label: "Popular Music Performance and Songwriting BA (Hons)" },
	{ value: "Business Management BA (Hons)", label: "Business Management BA (Hons)" },
	{ value: "Digital Marketing BA (Hons)", label: "Digital Marketing BA (Hons)" },
	{ value: "Politics BA (Hons)", label: "Politics BA (Hons)" },
	{ value: "Psychology BSc (Hons)", label: "Psychology BSc (Hons)" },
	{ value: "Computer Science MSci - with placement", label: "Computer Science MSci - with placement" },
	{ value: "Air Transport Management MSc", label: "Air Transport Management MSc" },
	{ value: "Computer Science MSc", label: "Computer Science MSc" },
	{ value: "Engineering Management MSc", label: "Engineering Management MSc" },
	{ value: "Sport Management MSc", label: "Sport Management MSc" },
	{ value: "Forensic Psychology and Offender Rehabilitation MSc", label: "Forensic Psychology and Offender Rehabilitation MSc" },
	{ value: "Molecular Biology MSc (with professional experience)", label: "Molecular Biology MSc (with professional experience)" },
	{ value: "Acting Higher National Certificate (HNC)", label: "Acting Higher National Certificate (HNC)" },
	{ value: "Paramedic Science BSc (Hons)", label: "Paramedic Science BSc (Hons)" },
	{ value: "Mathematics and Physics BSc (Hons)", label: "Mathematics and Physics BSc (Hons)" },
	{ value: "Mathematics BSc (Hons)", label: "Mathematics BSc (Hons)" },
	{ value: "International Finance and Banking BSc (Hons) top-up - (Final year entry)", label: "International Finance and Banking BSc (Hons) top-up - (Final year entry)" },
	{ value: "Emergency Management and Resilience MSc", label: "Emergency Management and Resilience MSc" },
	{ value: "Painting MA", label: "Painting MA" },
	{ value: "Advertising and Marketing MA", label: "Advertising and Marketing MA" },
	{ value: "Control, Automation and Artificial Intelligence MSc", label: "Control, Automation and Artificial Intelligence MSc" },
	{ value: "Electrical and Electronic Engineering MSc", label: "Electrical and Electronic Engineering MSc" },
	{ value: "Brand Management MA (with professional experience)", label: "Brand Management MA (with professional experience)" },
	{ value: "Biotechnology MSc (with professional experience)", label: "Biotechnology MSc (with professional experience)" },
	{ value: "Master of Science in Management MSc (with  Professional Practice)", label: "Master of Science in Management MSc (with  Professional Practice)" },
	{ value: "Product Design BA (Hons) - with study abroad / professional placement", label: "Product Design BA (Hons) - with study abroad / professional placement" },
	{ value: "Business Economics BSc (Hons) - sandwich", label: "Business Economics BSc (Hons) - sandwich" },
	{ value: "Media and Communication BA (Hons)", label: "Media and Communication BA (Hons)" },
	{ value: "Business and Human Resource Management BA (Hons)", label: "Business and Human Resource Management BA (Hons)" },
	{ value: "Business Economics BSc (Hons)", label: "Business Economics BSc (Hons)" },
	{ value: "English Language and Teaching English as a Foreign Language (TEFL) BA (Hons)", label: "English Language and Teaching English as a Foreign Language (TEFL) BA (Hons)" },
	{ value: "International Marketing BA (Hons)Top Up", label: "International Marketing BA (Hons)Top Up" },
	{ value: "International Business Management MSc", label: "International Business Management MSc" },
	{ value: "Aerospace Engineering MSc (With Placement)", label: "Aerospace Engineering MSc (With Placement)" },
	{ value: "Business Management & Leadership BA (Hons) Degree - Co-op", label: "Business Management & Leadership BA (Hons) Degree - Co-op" },
	{ value: "International Fashion Management and Marketing BA (Hons)", label: "International Fashion Management and Marketing BA (Hons)" },
	{ value: "International Finance and Accounting BA (Hons)", label: "International Finance and Accounting BA (Hons)" },
	{ value: "Computing BSc (Hons) - sandwich", label: "Computing BSc (Hons) - sandwich" },
	{ value: "Sociology and Criminology BA (Hons) - with study abroad / professional placement", label: "Sociology and Criminology BA (Hons) - with study abroad / professional placement" },
	{ value: "Sport and Exercise Psychology BSc (Hons) - (study abroad or work placement)", label: "Sport and Exercise Psychology BSc (Hons) - (study abroad or work placement)" },
	{ value: "Automotive Engineering MEng", label: "Automotive Engineering MEng" },
	{ value: "Information Technology for Business BSc (Hons)", label: "Information Technology for Business BSc (Hons)" },
	{ value: "Mathematics and Statistics BSc (Hons)", label: "Mathematics and Statistics BSc (Hons)" },
	{ value: "Sport Coaching BSc (Hons)", label: "Sport Coaching BSc (Hons)" },
	{ value: "English Language Teaching and Applied Linguistics MA", label: "English Language Teaching and Applied Linguistics MA" },
	{ value: "Post-Digital Humanities MA", label: "Post-Digital Humanities MA" },
	{ value: "Terrorism, International Crime and Global Security MA", label: "Terrorism, International Crime and Global Security MA" },
	{ value: "Data Science and Computational Intelligence MSc", label: "Data Science and Computational Intelligence MSc" },
	{ value: "FinTech MSc", label: "FinTech MSc" },
	{ value: "Master of Business Administration (Artificial Intelligence)", label: "Master of Business Administration (Artificial Intelligence)" },
	{ value: "LLM in Law  (with prof. experience) (per year)", label: "LLM in Law  (with prof. experience) (per year)" },
	{ value: "PGCE with QTS (Postgraduate Certificate in Education with Qualified Teacher Status)", label: "PGCE with QTS (Postgraduate Certificate in Education with Qualified Teacher Status)" },
	{ value: "Peace and Conflict Studies MA", label: "Peace and Conflict Studies MA" },
	{ value: "International Hospitality, Tourism and Wellness Management Top Up BA (Hons)", label: "International Hospitality, Tourism and Wellness Management Top Up BA (Hons)" },
	{ value: "Master of Science in international hospitality and tourism management (with  Professional Practice)", label: "Master of Science in international hospitality and tourism management (with  Professional Practice)" },
	{ value: "Acting (Stage and Screen) BA (Hons) - (study abroad or work placement)", label: "Acting (Stage and Screen) BA (Hons) - (study abroad or work placement)" },
	{ value: "Computer Science BSc (Hons) - with placement", label: "Computer Science BSc (Hons) - with placement" },
	{ value: "Electrical and Electronic Engineering MEng", label: "Electrical and Electronic Engineering MEng" },
	{ value: "Banking and Finance BSc (Hons) - sandwich", label: "Banking and Finance BSc (Hons) - sandwich" },
	{ value: "Diagnostic Radiography MSci", label: "Diagnostic Radiography MSci" },
	{ value: "Mathematics and Statistics BSc (Hons) - sandwich", label: "Mathematics and Statistics BSc (Hons) - sandwich" },
	{ value: "Games Art BA (Hons)", label: "Games Art BA (Hons)" },
	{ value: "Illustration BA (Hons)", label: "Illustration BA (Hons)" },
	{ value: "English BA (Hons)", label: "English BA (Hons)" },
	{ value: "Social Work BA (Hons)", label: "Social Work BA (Hons)" },
	{ value: "Electrical and Electronic Engineering MEng - sandwich", label: "Electrical and Electronic Engineering MEng - sandwich" },
	{ value: "Product Design Innovation MA", label: "Product Design Innovation MA" },
	{ value: "Renewable Energy Management MSc", label: "Renewable Energy Management MSc" },
	{ value: "Advanced Mechanical Engineering MSc", label: "Advanced Mechanical Engineering MSc" },
	{ value: "Advanced International Business MSc", label: "Advanced International Business MSc" },
	{ value: "Advanced Supply Chain Management MSc (With Work Placement Option)", label: "Advanced Supply Chain Management MSc (With Work Placement Option)" },
	{ value: "Cognitive Behavioural Therapy PGDip", label: "Cognitive Behavioural Therapy PGDip" },
	{ value: "Marketing Management top up BA", label: "Marketing Management top up BA" },
	{ value: "Master of Science in international project management (with  Professional Practice)", label: "Master of Science in international project management (with  Professional Practice)" },
	{ value: "Business and Human Resource Management BA (Hons) - sandwich", label: "Business and Human Resource Management BA (Hons) - sandwich" },
	{ value: "International Business Management BSc (Hons) - (study abroad or work placement)", label: "International Business Management BSc (Hons) - (study abroad or work placement)" },
	{ value: "Aerospace Systems Engineering MEng - sandwich", label: "Aerospace Systems Engineering MEng - sandwich" },
	{ value: "Aviation Management BSc (Hons)", label: "Aviation Management BSc (Hons)" },
	{ value: "Digital Media BA (Hons)", label: "Digital Media BA (Hons)" },
	{ value: "Human Biosciences BSc (Hons)", label: "Human Biosciences BSc (Hons)" },
	{ value: "Accounting and Finance for International Business BSc (Hons) top-up 2 Yrs", label: "Accounting and Finance for International Business BSc (Hons) top-up 2 Yrs" },
	{ value: "Forensic Psychology MSc", label: "Forensic Psychology MSc" },
	{ value: "MA Politics", label: "MA Politics" },
	{ value: "Oil and Gas Engineering MSc", label: "Oil and Gas Engineering MSc" },
	{ value: "Structural Engineering MSc (Work placement option additional fee)", label: "Structural Engineering MSc (Work placement option additional fee)" },
	{ value: "Maritime Security PgCert", label: "Maritime Security PgCert" },
	{ value: "Electro-Mechanical Engineering Higher National Certificate (HNC)", label: "Electro-Mechanical Engineering Higher National Certificate (HNC)" },
	{ value: "Global Events Management BSc (Hons)", label: "Global Events Management BSc (Hons)" },
	{ value: "Health & Social Care Higher National Certificate (HNC)", label: "Health & Social Care Higher National Certificate (HNC)" },
	{ value: "Sport Performance & Coaching BSc (Hons)", label: "Sport Performance & Coaching BSc (Hons)" },
	{ value: "Business Analytics MSc", label: "Business Analytics MSc" },
	{ value: "International Human Resource Management MSc", label: "International Human Resource Management MSc" },
	{ value: "Maritime Security MA", label: "Maritime Security MA" },
	{ value: "Sports and Exercise Therapy BSc (Hons)", label: "Sports and Exercise Therapy BSc (Hons)" },
	{ value: "Engineering Project Management MSc", label: "Engineering Project Management MSc" },
	{ value: "Crisis, Security and Resilience PG Cert", label: "Crisis, Security and Resilience PG Cert" },
	{ value: "Disaster Risk and Resilience PG Cert", label: "Disaster Risk and Resilience PG Cert" },
	{ value: "Master of Science (MSc) in international project management", label: "Master of Science (MSc) in international project management" },
	{ value: "Digital Media BA (Hons) -  with study abroad / professional placement", label: "Digital Media BA (Hons) -  with study abroad / professional placement" },
	{ value: "Physics BSc (Hons) - sandwich", label: "Physics BSc (Hons) - sandwich" },
	{ value: "Business and Finance BSc (Hons) - sandwich", label: "Business and Finance BSc (Hons) - sandwich" },
	{ value: "Law LLB (Hons) - (study abroad or work placement)", label: "Law LLB (Hons) - (study abroad or work placement)" },
	{ value: "Sports and Exercise Therapy BSc (Hons) - (study abroad or work placement)", label: "Sports and Exercise Therapy BSc (Hons) - (study abroad or work placement)" },
	{ value: "Business and Marketing BA (Hons)", label: "Business and Marketing BA (Hons)" },
	{ value: "International Business BA (Hons) top-up - (Second year entry)", label: "International Business BA (Hons) top-up - (Second year entry)" },
	{ value: "Civil Engineering/Civil Engineering Technical Route MSc (Work placement option additional fee)", label: "Civil Engineering/Civil Engineering Technical Route MSc (Work placement option additional fee)" },
	{ value: "Crowded Places and Public Safety Management MSc (Work placement option)", label: "Crowded Places and Public Safety Management MSc (Work placement option)" },
	{ value: "Emergency Preparedness and Management PG Cert", label: "Emergency Preparedness and Management PG Cert" },
	{ value: "PGCE (Postgraduate Certificate in Education) for Independent Schools", label: "PGCE (Postgraduate Certificate in Education) for Independent Schools" },
	{ value: "Architecture MArch", label: "Architecture MArch" },
	{ value: "Maritime Security PGDip", label: "Maritime Security PGDip" },
	{ value: "Occupational Therapy (pre-registration) MSc", label: "Occupational Therapy (pre-registration) MSc" },
	{ value: "Mental Health Nursing MSc (Pre-registration)", label: "Mental Health Nursing MSc (Pre-registration)" },
	{ value: "Musculoskeletal Physiotherapy MSc (clinical pathway)", label: "Musculoskeletal Physiotherapy MSc (clinical pathway)" },
	{ value: "Public Health & Community Studies Higher National Certificate (HNC)", label: "Public Health & Community Studies Higher National Certificate (HNC)" },
	{ value: "Fashion BA (Hons)   - with study abroad / professional placement", label: "Fashion BA (Hons)   - with study abroad / professional placement" },
	{ value: "Interactive Media and Web Technologies BSc (Hons) - sandwich", label: "Interactive Media and Web Technologies BSc (Hons) - sandwich" },
	{ value: "Advertising and Marketing BA (Hons) - (study abroad or work placement)", label: "Advertising and Marketing BA (Hons) - (study abroad or work placement)" },
	{ value: "Animation and Illustration BA (Hons)", label: "Animation and Illustration BA (Hons)" },
	{ value: "Manufacturing Engineering BEng (Hons)", label: "Manufacturing Engineering BEng (Hons)" },
	{ value: "Mental Health Nursing BSc (Hons)", label: "Mental Health Nursing BSc (Hons)" },
	{ value: "Motorsport EngineeringBEng (Hons)", label: "Motorsport EngineeringBEng (Hons)" },
	{ value: "Business Administration BBA (Hons)", label: "Business Administration BBA (Hons)" },
	{ value: "Law LLB (Hons)", label: "Law LLB (Hons)" },
	{ value: "Nutrition and Health BSc (Hons)", label: "Nutrition and Health BSc (Hons)" },
	{ value: "Manufacturing Engineering MEng - sandwich", label: "Manufacturing Engineering MEng - sandwich" },
	{ value: "Health & Social Care BA (Hons) - Co-op", label: "Health & Social Care BA (Hons) - Co-op" },
	{ value: "Primary Education & Teaching Studies BA (Hons) - Co-op", label: "Primary Education & Teaching Studies BA (Hons) - Co-op" },
	{ value: "Primary Education & Teaching Studies Higher National Certificate (HNC)", label: "Primary Education & Teaching Studies Higher National Certificate (HNC)" },
	{ value: "Master of Science (MSc) in Global Finance MSc", label: "Master of Science (MSc) in Global Finance MSc" },
	{ value: "Electrical and Electronic Engineering BEng (Hons) - sandwich", label: "Electrical and Electronic Engineering BEng (Hons) - sandwich" },
	{ value: "Mechanical Engineering BEng (Hons) - sandwich", label: "Mechanical Engineering BEng (Hons) - sandwich" },
	{ value: "Law LLB (Hons) - sandwich", label: "Law LLB (Hons) - sandwich" },
	{ value: "Politics BA (Hons) -  - with study abroad / professional placement", label: "Politics BA (Hons) -  - with study abroad / professional placement" },
	{ value: "Civil and Environmental Engineering MEng", label: "Civil and Environmental Engineering MEng" },
	{ value: "Architectural Technology BSc (Hons)", label: "Architectural Technology BSc (Hons)" },
	{ value: "Electrical and Electronic Engineering BEng (Hons)", label: "Electrical and Electronic Engineering BEng (Hons)" },
	{ value: "Music Technology BSc (Hons)", label: "Music Technology BSc (Hons)" },
	{ value: "Operating Department Practice DipHE and BSc (Hons)", label: "Operating Department Practice DipHE and BSc (Hons)" },
	{ value: "Food Science BSc (Hons)", label: "Food Science BSc (Hons)" },
	{ value: "Mechanical Engineering MEng - sandwich", label: "Mechanical Engineering MEng - sandwich" },
	{ value: "Ethical Hacking and Cybersecurity MSci - sandwich", label: "Ethical Hacking and Cybersecurity MSci - sandwich" },
	{ value: "Advanced Supply Chain Management MSc", label: "Advanced Supply Chain Management MSc" },
	{ value: "International Corporate Law LLM (with prof. experience)", label: "International Corporate Law LLM (with prof. experience)" },
	{ value: "Learning Disabilities Nursing (pre-registration) MSc", label: "Learning Disabilities Nursing (pre-registration) MSc" },
	{ value: "Cyber Security BSc (Hons) - Co-op", label: "Cyber Security BSc (Hons) - Co-op" },
	{ value: "Civil Engineering/Civil Engineering Technical Route MSc", label: "Civil Engineering/Civil Engineering Technical Route MSc" },
	{ value: "Crowded Places and Public Safety Management MSc", label: "Crowded Places and Public Safety Management MSc" },
	{ value: "LLM in Law", label: "LLM in Law" },
	{ value: "Psychology MSc", label: "Psychology MSc" },
	{ value: "Strength and Conditioning MSc", label: "Strength and Conditioning MSc" },
	{ value: "Connected Autonomous Vehicles Engineering MSc (with work placement)", label: "Connected Autonomous Vehicles Engineering MSc (with work placement)" },
	{ value: "International Business Economics MSc", label: "International Business Economics MSc" },
	{ value: "Renewable Energy Engineering MSc", label: "Renewable Energy Engineering MSc" },
	{ value: "Cyber Security MSc (Work placement option)", label: "Cyber Security MSc (Work placement option)" },
	{ value: "Accounting and Financial Management MSc", label: "Accounting and Financial Management MSc" },
	{ value: "Academic Writing Development PGCert", label: "Academic Writing Development PGCert" },
	{ value: "Law & Practice LLB (Hons)", label: "Law & Practice LLB (Hons)" },
	{ value: "Computer Science with Artificial Intelligence BSc (Hons) - sandwich", label: "Computer Science with Artificial Intelligence BSc (Hons) - sandwich" },
	{ value: "Global Journalism and Media BA (Hons) - with study abroad / professional placement", label: "Global Journalism and Media BA (Hons) - with study abroad / professional placement" },
	{ value: "English and Creative Writing BA (Hons) - with study abroad / professional placement", label: "English and Creative Writing BA (Hons) - with study abroad / professional placement" },
	{ value: "Civil Engineering BEng (Hons)", label: "Civil Engineering BEng (Hons)" },
	{ value: "Computer Hardware and Software Engineering BEng (Hons)", label: "Computer Hardware and Software Engineering BEng (Hons)" },
	{ value: "Journalism and English BA (Hons)", label: "Journalism and English BA (Hons)" },
	{ value: "Journalism BA (Hons)", label: "Journalism BA (Hons)" },
	{ value: "Geography and Natural Hazards BSc (Hons)", label: "Geography and Natural Hazards BSc (Hons)" },
	{ value: "International Business BA (Hons) top-up - (Final year entry)", label: "International Business BA (Hons) top-up - (Final year entry)" },
	{ value: "Immersive and Virtual Media MA", label: "Immersive and Virtual Media MA" },
	{ value: "Occupational Psychology MSc", label: "Occupational Psychology MSc" },
	{ value: "International Events Management MSc", label: "International Events Management MSc" },
	{ value: "MBA (Cyber Security Management)", label: "MBA (Cyber Security Management)" },
	{ value: "Global Health Care Management MSc (with professional experience)", label: "Global Health Care Management MSc (with professional experience)" },
	{ value: "Social Work MA", label: "Social Work MA" },
	{ value: "Computing Science Higher National Certificate (HNC)", label: "Computing Science Higher National Certificate (HNC)" },
	{ value: "International Hospitality and Tourism Management BA (Hons)", label: "International Hospitality and Tourism Management BA (Hons)" },
	{ value: "Master of Business Administration in International Human Resource Management MBA", label: "Master of Business Administration in International Human Resource Management MBA" },
	{ value: "Bachelor of Arts (Hons)in Health and Social Care", label: "Bachelor of Arts (Hons)in Health and Social Care" },
	{ value: "Information Technology for Business BSc (Hons) - sandwich", label: "Information Technology for Business BSc (Hons) - sandwich" },
	{ value: "Financial Economics BSc (Hons) -  (study abroad or work placement)", label: "Financial Economics BSc (Hons) -  (study abroad or work placement)" },
	{ value: "Civil and Environmental Engineering BEng (Hons)", label: "Civil and Environmental Engineering BEng (Hons)" },
	{ value: "Construction Management with BIM MSc", label: "Construction Management with BIM MSc" },
	{ value: "International Commercial Law LLM", label: "International Commercial Law LLM" },
	{ value: "Media Practice MA", label: "Media Practice MA" },
	{ value: "Human Resource Management MA", label: "Human Resource Management MA" },
	{ value: "Software Development MSc", label: "Software Development MSc" },
	{ value: "Master of Business Administration (Marketing)", label: "Master of Business Administration (Marketing)" },
	{ value: "Management of Information Systems and Technology MSc ((Work placement option)", label: "Management of Information Systems and Technology MSc ((Work placement option)" },
	{ value: "Peace and Conflict Studies PgCert", label: "Peace and Conflict Studies PgCert" },
	{ value: "Fraud Investigation Management MSc  (with Professional Experience)", label: "Fraud Investigation Management MSc  (with Professional Experience)" },
	{ value: "Biomedical Science MSc", label: "Biomedical Science MSc" },
	{ value: "Molecular Biology MSc", label: "Molecular Biology MSc" },
	{ value: "Electro-Mechanical Engineering BEng (Hons)", label: "Electro-Mechanical Engineering BEng (Hons)" },
	{ value: "Applied Psychology BSc (Hons) - Degree", label: "Applied Psychology BSc (Hons) - Degree" },
	{ value: "Electronic Engineering BEng (Hons) - sandwich", label: "Electronic Engineering BEng (Hons) - sandwich" },
	{ value: "Electronic Engineering MEng", label: "Electronic Engineering MEng" },
	{ value: "Manufacturing Engineering MEng", label: "Manufacturing Engineering MEng" },
	{ value: "Interior Architecture and Design BA (Hons)", label: "Interior Architecture and Design BA (Hons)" },
	{ value: "Media Production BA (Hons)", label: "Media Production BA (Hons)" },
	{ value: "Physiotherapy BSc (Hons)", label: "Physiotherapy BSc (Hons)" },
	{ value: "Childhood, Youth and Education Studies BA (Hons)", label: "Childhood, Youth and Education Studies BA (Hons)" },
	{ value: "International Relations BA (Hons)", label: "International Relations BA (Hons)" },
	{ value: "Operating Department Practice DipHE", label: "Operating Department Practice DipHE" },
	{ value: "English and Education Management MA", label: "English and Education Management MA" },
	{ value: "Nursing MSc", label: "Nursing MSc" },
	{ value: "Global Marketing BSc (Hons)-With Sandwich Placement", label: "Global Marketing BSc (Hons)-With Sandwich Placement" },
	{ value: "International Fashion Management and Marketing BA (Hons)-With Sandwich Placement", label: "International Fashion Management and Marketing BA (Hons)-With Sandwich Placement" },
	{ value: "Civil Engineering BSc (Hons) - sandwich", label: "Civil Engineering BSc (Hons) - sandwich" },
	{ value: "Construction Management BSc (Hons) - sandwich", label: "Construction Management BSc (Hons) - sandwich" },
	{ value: "Human Biosciences BSc (Hons)  - (study abroad or work placement)", label: "Human Biosciences BSc (Hons)  - (study abroad or work placement)" },
	{ value: "Psychology BSc (Hons) - sandwich", label: "Psychology BSc (Hons) - sandwich" },
	{ value: "Electronic Engineering BEng (Hons)", label: "Electronic Engineering BEng (Hons)" },
	{ value: "Fine Art BA (Hons)", label: "Fine Art BA (Hons)" },
	{ value: "Geography BSc (Hons)", label: "Geography BSc (Hons)" },
	{ value: "Media BA (Hons) top-up", label: "Media BA (Hons) top-up" },
	{ value: "International Digital Marketing (Hons)Top Up - (Second year entry)", label: "International Digital Marketing (Hons)Top Up - (Second year entry)" },
	{ value: "Master of Science in Enterprise and Innovation- MSc (with Professional Practice)", label: "Master of Science in Enterprise and Innovation- MSc (with Professional Practice)" },
	{ value: "Acting BA (Hons) - Degree", label: "Acting BA (Hons) - Degree" },
	{ value: "Health & Social Care BA (Hons)", label: "Health & Social Care BA (Hons)" },
	{ value: "Civil and Environmental Engineering BEng (Hons) - sandwich", label: "Civil and Environmental Engineering BEng (Hons) - sandwich" },
	{ value: "Computer Hardware and Software Engineering BEng (Hons) - sandwich", label: "Computer Hardware and Software Engineering BEng (Hons) - sandwich" },
	{ value: "Criminology and Law BA (Hons) - (study abroad or work placement)", label: "Criminology and Law BA (Hons) - (study abroad or work placement)" },
	{ value: "Ethical Hacking and Cybersecurity BSc (Hons) - sandwich", label: "Ethical Hacking and Cybersecurity BSc (Hons) - sandwich" },
	{ value: "Media Production BA (Hons) - with study abroad / professional placement", label: "Media Production BA (Hons) - with study abroad / professional placement" },
	{ value: "Digital Marketing BA (Hons) - (study abroad or work placement)", label: "Digital Marketing BA (Hons) - (study abroad or work placement)" },
	{ value: "Nutrition and Health BSc (Hons) - sandwich", label: "Nutrition and Health BSc (Hons) - sandwich" },
	{ value: "Pharmacology BSc (Hons) - (study abroad or work placement)", label: "Pharmacology BSc (Hons) - (study abroad or work placement)" },
	{ value: "Sport Coaching BSc (Hons) - (study abroad or work placement)", label: "Sport Coaching BSc (Hons) - (study abroad or work placement)" },
	{ value: "Forensic Investigations BSc (Hons) - (study abroad or work placement)", label: "Forensic Investigations BSc (Hons) - (study abroad or work placement)" },
	{ value: "Interactive Media and Web Technologies BSc (Hons)", label: "Interactive Media and Web Technologies BSc (Hons)" },
	{ value: "Physics BSc (Hons)", label: "Physics BSc (Hons)" },
	{ value: "Advertising and Marketing BA (Hons)", label: "Advertising and Marketing BA (Hons)" },
	{ value: "Banking and Finance BSc (Hons)", label: "Banking and Finance BSc (Hons)" },
	{ value: "English Literature BA (Hons)", label: "English Literature BA (Hons)" },
	{ value: "Geography BA (Hons)", label: "Geography BA (Hons)" },
	{ value: "International Digital Marketing (Hons)Top Up - (Final year entry)", label: "International Digital Marketing (Hons)Top Up - (Final year entry)" },
	{ value: "Physics MSci - sandwich", label: "Physics MSci - sandwich" },
	{ value: "Sports and Exercise Nutrition MSc", label: "Sports and Exercise Nutrition MSc" },
	{ value: "Supply Chain Management and Logistics MSc", label: "Supply Chain Management and Logistics MSc" },
	{ value: "Sport Management MSc (with prof experience)", label: "Sport Management MSc (with prof experience)" },
	{ value: "Musculoskeletal Physiotherapy MSc (Non clinical pathway)", label: "Musculoskeletal Physiotherapy MSc (Non clinical pathway)" },
	{ value: "Accounting and Finance for International Business top up BA (Hons)", label: "Accounting and Finance for International Business top up BA (Hons)" },
	{ value: "International Fashion PR and Communication top up BA (Hons)", label: "International Fashion PR and Communication top up BA (Hons)" },
	{ value: "Master of Business Administration in International Fashion Management MBA", label: "Master of Business Administration in International Fashion Management MBA" },
	{ value: "Professional Policing BA (Hons)", label: "Professional Policing BA (Hons)" },
	{ value: "Quantity Surveying and Commercial Management BSc (Hons) - sandwich", label: "Quantity Surveying and Commercial Management BSc (Hons) - sandwich" },
	{ value: "Finance and Investment BSc (Hons) - sandwich", label: "Finance and Investment BSc (Hons) - sandwich" },
	{ value: "Sport and Exercise Psychology BSc (Hons)", label: "Sport and Exercise Psychology BSc (Hons)" },
	{ value: "Health Psychology MSc", label: "Health Psychology MSc" },
	{ value: "Connected Autonomous Vehicle Systems MSc", label: "Connected Autonomous Vehicle Systems MSc" },
	{ value: "International Events Management MSc (with prof experience)", label: "International Events Management MSc (with prof experience)" },
	{ value: "Electrical Automotive Engineering MSc (Work placement option )", label: "Electrical Automotive Engineering MSc (Work placement option )" },
	{ value: "Postgraduate Certificate in Business Administration", label: "Postgraduate Certificate in Business Administration" },
	{ value: "FinTech MSc (with professional experience)", label: "FinTech MSc (with professional experience)" },
	{ value: "Finance MSc (with professional experience)", label: "Finance MSc (with professional experience)" },
	{ value: "Biomedical Science / Applied Biomedical Science BSc (Hons)", label: "Biomedical Science / Applied Biomedical Science BSc (Hons)" },
	{ value: "Business Management & Leadership Higher National Certificate (HNC)", label: "Business Management & Leadership Higher National Certificate (HNC)" },
	{ value: "Applied Psychology Higher National Certificate (HNC)", label: "Applied Psychology Higher National Certificate (HNC)" },
	{ value: "Master of Science (MSc) in Digital Marketing with Data Analytics -MSc", label: "Master of Science (MSc) in Digital Marketing with Data Analytics -MSc" },
	{ value: "Economics BSc (Hons) - sandwich", label: "Economics BSc (Hons) - sandwich" },
	{ value: "Enterprise and Entrepreneurship BA (Hons) - sandwich", label: "Enterprise and Entrepreneurship BA (Hons) - sandwich" },
	{ value: "Aerospace Systems Engineering BEng (Hons)", label: "Aerospace Systems Engineering BEng (Hons)" },
	{ value: "International Event and Hospitality Management BA (Hons) top-up - (Final year entry)", label: "International Event and Hospitality Management BA (Hons) top-up - (Final year entry)" },
	{ value: "International Event and Hospitality Management BA (Hons) top-up - (Second year entry)", label: "International Event and Hospitality Management BA (Hons) top-up - (Second year entry)" },
	{ value: "Applied Psychology MSc", label: "Applied Psychology MSc" },
	{ value: "Interior Design MA", label: "Interior Design MA" },
	{ value: "International Corporate Law LLM", label: "International Corporate Law LLM" },
	{ value: "Aerospace Engineering MSc", label: "Aerospace Engineering MSc" },
	{ value: "Digital Marketing Management MSc", label: "Digital Marketing Management MSc" },
	{ value: "Electro-Mechanical Engineering BEng (Hons) - Co-op", label: "Electro-Mechanical Engineering BEng (Hons) - Co-op" },
	{ value: "Biomedical Science MSc (with professional experience)", label: "Biomedical Science MSc (with professional experience)" },
	{ value: "Early Childhood Development & Learning HNC", label: "Early Childhood Development & Learning HNC" },
	{ value: "Professional Policing BA (Hons) - Co-op", label: "Professional Policing BA (Hons) - Co-op" },
	{ value: "Public Health & Community Studies BA (Hons) - Co-op", label: "Public Health & Community Studies BA (Hons) - Co-op" },
	{ value: "International Entrepreneurship MSc", label: "International Entrepreneurship MSc" },
	{ value: "International Marketing Management MSc", label: "International Marketing Management MSc" },
	{ value: "Postgraduate Certificate in Nursing", label: "Postgraduate Certificate in Nursing" },
	{ value: "Protective Security and Resilience PGDip", label: "Protective Security and Resilience PGDip" },
	{ value: "Biotechnology MSc", label: "Biotechnology MSc" },
	{ value: "Peace and Conflict Studies PGDip", label: "Peace and Conflict Studies PGDip" },
	{ value: "Aviation Management BSc (Hons) - sandwich", label: "Aviation Management BSc (Hons) - sandwich" },
	{ value: "Data Science BSc (Hons) - sanwich", label: "Data Science BSc (Hons) - sanwich" },
	{ value: "Accountancy BSc (Hons) - sandwich", label: "Accountancy BSc (Hons) - sandwich" },
	{ value: "Computer Science with Artificial Intelligence BSc (Hons)", label: "Computer Science with Artificial Intelligence BSc (Hons)" },
	{ value: "Data Science BSc (Hons)", label: "Data Science BSc (Hons)" },
	{ value: "Global Journalism and Media BA (Hons)", label: "Global Journalism and Media BA (Hons)" },
	{ value: "Forensic Investigations BSc (Hons)", label: "Forensic Investigations BSc (Hons)" },
	{ value: "Data Science MSci - sandwich", label: "Data Science MSci - sandwich" },
	{ value: "Digital Technology for Business MSc", label: "Digital Technology for Business MSc" },
	{ value: "Law & Practice LLB (Hons) - Co-op", label: "Law & Practice LLB (Hons) - Co-op" },
	{ value: "Sport & Leisure Management Higher National Certificate (HNC)", label: "Sport & Leisure Management Higher National Certificate (HNC)" },
	{ value: "Master of Science (MSc) in international hospitality and tourism management", label: "Master of Science (MSc) in international hospitality and tourism management" },
	{ value: "Master of Science (MSc) in Management MSc", label: "Master of Science (MSc) in Management MSc" },
	{ value: "Master of Science (MSc) in Global Financial Trading - MSc (with Professional Practice)", label: "Master of Science (MSc) in Global Financial Trading - MSc (with Professional Practice)" },
	{ value: "Early Childhood Development & Learning BA (Hons) - Co-op", label: "Early Childhood Development & Learning BA (Hons) - Co-op" },
	{ value: "Business Management BA (Hons) -  (study abroad or work placement)", label: "Business Management BA (Hons) -  (study abroad or work placement)" },
	{ value: "Disaster and Emergency Management BSc (Hons) - sandwich", label: "Disaster and Emergency Management BSc (Hons) - sandwich" },
	{ value: "Architecture BSc (Hons)", label: "Architecture BSc (Hons)" },
	{ value: "Automotive Engineering BEng (Hons)", label: "Automotive Engineering BEng (Hons)" },
	{ value: "Disaster and Emergency Management BSc (Hons)", label: "Disaster and Emergency Management BSc (Hons)" },
	{ value: "International Relations MA", label: "International Relations MA" },
	{ value: "International Human Rights Law LLM  (with prof. experience)", label: "International Human Rights Law LLM  (with prof. experience)" },
	{ value: "Applied Psychology MSc ( Extended Professional Practice)", label: "Applied Psychology MSc ( Extended Professional Practice)" },
	{ value: "International Hospitality and Tourism Management BA (Hons)-With Sandwich Placement", label: "International Hospitality and Tourism Management BA (Hons)-With Sandwich Placement" },
	{ value: "Public Health & Community Studies BA (Hons)", label: "Public Health & Community Studies BA (Hons)" },
	{ value: "Media and Communication BA (Hons) - sandwich", label: "Media and Communication BA (Hons) - sandwich" },
	{ value: "Children and Young People's Nursing BSc (Hons)", label: "Children and Young People's Nursing BSc (Hons)" },
	{ value: "Accountancy BSc (Hons)", label: "Accountancy BSc (Hons)" },
	{ value: "Enterprise and Entrepreneurship BA (Hons)", label: "Enterprise and Entrepreneurship BA (Hons)" },
	{ value: "Event Management BA (Hons)", label: "Event Management BA (Hons)" },
	{ value: "History and Politics BA (Hons)", label: "History and Politics BA (Hons)" },
	{ value: "Biological and Forensic Science BSc (Hons)", label: "Biological and Forensic Science BSc (Hons)" },
	{ value: "Electronic Engineering MEng - sandwich", label: "Electronic Engineering MEng - sandwich" },
	{ value: "Graphic Design BA (Hons) - with study abroad / professional placement", label: "Graphic Design BA (Hons) - with study abroad / professional placement" },
	{ value: "Event Management BA (Hons) - sandwich", label: "Event Management BA (Hons) - sandwich" },
	{ value: "Sport Management BA (Hons) -  (study abroad or work placement)", label: "Sport Management BA (Hons) -  (study abroad or work placement)" },
	{ value: "Biomedical Science / Applied Biomedical Science BSc (Hons) - (study abroad or work placement)", label: "Biomedical Science / Applied Biomedical Science BSc (Hons) - (study abroad or work placement)" },
	{ value: "Acting (Stage and Screen) BA (Hons)", label: "Acting (Stage and Screen) BA (Hons)" },
	{ value: "International Fashion Business BA (Hons)", label: "International Fashion Business BA (Hons)" },
	{ value: "Sociology and Criminology BA (Hons)", label: "Sociology and Criminology BA (Hons)" },
	{ value: "Sport Management BA (Hons)", label: "Sport Management BA (Hons)" },
	{ value: "Aerospace Systems Engineering MEng", label: "Aerospace Systems Engineering MEng" },
	{ value: "Advancing Physiotherapy Practice MSc", label: "Advancing Physiotherapy Practice MSc" },
	{ value: "Media Management MA", label: "Media Management MA" },
	{ value: "Finance MSc", label: "Finance MSc" },
	{ value: "Data Science and Computational Intelligence MSc  (Work placement option)", label: "Data Science and Computational Intelligence MSc  (Work placement option)" },
	{ value: "Automotive and Transport Design BA (Hons) - with study abroad / professional placement", label: "Automotive and Transport Design BA (Hons) - with study abroad / professional placement" },
	{ value: "Digital Media BA (Hons) - with study abroad / professional placement", label: "Digital Media BA (Hons) - with study abroad / professional placement" },
	{ value: "English Language and Teaching English as a Foreign Language (TEFL) BA (Hons) - with study abroad / professional placement", label: "English Language and Teaching English as a Foreign Language (TEFL) BA (Hons) - with study abroad / professional placement" },
	{ value: "International Relations BA (Hons) - with study abroad / professional placement", label: "International Relations BA (Hons) - with study abroad / professional placement" },
	{ value: "Computing BSc (Hons)", label: "Computing BSc (Hons)" },
	{ value: "Graphic Design BA (Hons)", label: "Graphic Design BA (Hons)" },
	{ value: "Business and Finance BSc (Hons)", label: "Business and Finance BSc (Hons)" },
	{ value: "Sociology BA (Hons)", label: "Sociology BA (Hons)" },
	{ value: "Food Safety, Inspection and Control BSc (Hons)", label: "Food Safety, Inspection and Control BSc (Hons)" },
	{ value: "Computer Science with Artificial Intelligence MSci - sandwich", label: "Computer Science with Artificial Intelligence MSci - sandwich" },
	{ value: "Business and Organisational Psychology MSc", label: "Business and Organisational Psychology MSc" },
	{ value: "Public Health Nutrition MSc", label: "Public Health Nutrition MSc" },
	{ value: "Embedded Systems Engineering MSc", label: "Embedded Systems Engineering MSc" },
	{ value: "Electrical Automotive Engineering MSc", label: "Electrical Automotive Engineering MSc" },
	{ value: "Academic Writing Development and Research MA", label: "Academic Writing Development and Research MA" },
	{ value: "Pharmacology and Drug Discovery MSc", label: "Pharmacology and Drug Discovery MSc" },
	{ value: "Physiotherapy and Leadership (pre-registration) MSc", label: "Physiotherapy and Leadership (pre-registration) MSc" },
	{ value: "Financial Economics and Banking BSc (Hons)", label: "Financial Economics and Banking BSc (Hons)" },
	{ value: "Global Marketing BSc (Hons)", label: "Global Marketing BSc (Hons)" },
	{ value: "Master of Business Administration in International Marketing MBA", label: "Master of Business Administration in International Marketing MBA" },
	{ value: "Primary Education & Teaching Studies BA (Hons)", label: "Primary Education & Teaching Studies BA (Hons)" },
	{ value: "Aerospace Technology BEng (Hons) - sandwich", label: "Aerospace Technology BEng (Hons) - sandwich" },
	{ value: "Illustration BA (Hons)  - with study abroad / professional placement", label: "Illustration BA (Hons)  - with study abroad / professional placement" },
	{ value: "International Fashion Business BA (Hons) - with study abroad / professional", label: "International Fashion Business BA (Hons) - with study abroad / professional" },
	{ value: "Music Technology BSc (Hons) - sandwich", label: "Music Technology BSc (Hons) - sandwich" },
	{ value: "Film Production BA (Hons)", label: "Film Production BA (Hons)" },
	{ value: "Pharmacology BSc (Hons)", label: "Pharmacology BSc (Hons)" },
	{ value: "Informatics BSc (Hons) top-up - direct entry", label: "Informatics BSc (Hons) top-up - direct entry" },
	{ value: "Career Development and Management MA", label: "Career Development and Management MA" },
	{ value: "International Human Rights Law LLM", label: "International Human Rights Law LLM" },
	{ value: "Structural Engineering MSc", label: "Structural Engineering MSc" },
	{ value: "Oil and Gas Management MSc", label: "Oil and Gas Management MSc" },
	{ value: "Master of Business Administration (Healthcare sector)", label: "Master of Business Administration (Healthcare sector)" },
	{ value: "International Commercial Law LLM  (with prof. experience)", label: "International Commercial Law LLM  (with prof. experience)" },
	{ value: "Fraud Investigation Management MSc", label: "Fraud Investigation Management MSc" },
	{ value: "Supply Chain Management and Logistics MSc (Work placement option )", label: "Supply Chain Management and Logistics MSc (Work placement option )" },
	{ value: "Academic Writing Development and Research PGDip", label: "Academic Writing Development and Research PGDip" },
	{ value: "Applied Psychology Higher National Diploma (HND)", label: "Applied Psychology Higher National Diploma (HND)" },
	{ value: "Sport Performance and Coaching National Certificate (HNC)", label: "Sport Performance and Coaching National Certificate (HNC)" },
	{ value: "Master of Business Administration in Global Financial Services MBA", label: "Master of Business Administration in Global Financial Services MBA" },
	{ value: "Master of Science in International Fashion Marketing - MSc(with  Professional Practice)", label: "Master of Science in International Fashion Marketing - MSc(with  Professional Practice)" },
	{ value: "Business and Human Resource Management BA (Hons) -  (study abroad or work placement)", label: "Business and Human Resource Management BA (Hons) -  (study abroad or work placement)" },
	{ value: "Financial Economics and Banking BSc (Hons) - sandwich", label: "Financial Economics and Banking BSc (Hons) - sandwich" },
	{ value: "Forensic Psychology BSc (Hons) - (study abroad or work placement)", label: "Forensic Psychology BSc (Hons) - (study abroad or work placement)" },
	{ value: "Finance and Investment BSc (Hons)", label: "Finance and Investment BSc (Hons)" },
	{ value: "Finance BSc (Hons)", label: "Finance BSc (Hons)" },
	{ value: "Engineering Business Management BEng (Hons) top-up", label: "Engineering Business Management BEng (Hons) top-up" },
	{ value: "Automotive Engineering MEng -  sandwich", label: "Automotive Engineering MEng -  sandwich" },
	{ value: "Communication, Culture and Media MA", label: "Communication, Culture and Media MA" },
	{ value: "Petroleum and Environmental Technology MSc", label: "Petroleum and Environmental Technology MSc" }
];

export default CoventryUniversityCourses;