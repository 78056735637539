const AtlanticTechCourses = [
	{ label: "Bachelor of Laws (Hons) LLB", value: "Bachelor of Laws (Hons) LLB" },
	{ label: "Bachelor of Arts (Hons) Design (Common Entry)", value: "Bachelor of Arts (Hons) Design (Common Entry)" },
	{ label: "Bachelor of Arts (Hons) Animation", value: "Bachelor of Arts (Hons) Animation" },
	{ label: "Bachelor of Arts (Hons) Film & Media Production", value: "Bachelor of Arts (Hons) Film & Media Production" },
	{ label: "Bachelor of Arts (Hons) Fashion with Promotion", value: "Bachelor of Arts (Hons) Fashion with Promotion" },
	{ label: "Bachelor of Arts (Hons) Graphic & UX Design", value: "Bachelor of Arts (Hons) Graphic & UX Design" },
	{ label: "BSc (Hons) in Athletic Therapy and Exercise Rehabilitation", value: "BSc (Hons) in Athletic Therapy and Exercise Rehabilitation" },
	{ label: "BSc (Hons) in Sports & Exercise (Common Entry)", value: "BSc (Hons) in Sports & Exercise (Common Entry)" },
	{ label: "BSc (Hons) in Sport & Exercise with Performance and Well-being", value: "BSc (Hons) in Sport & Exercise with Performance and Well-being" },
	{ label: "BSc (Hons) in Sport & Exercise with Physical Education", value: "BSc (Hons) in Sport & Exercise with Physical Education" },
	{ label: "Bachelor of Business (Hons) in Accounting", value: "Bachelor of Business (Hons) in Accounting" },
	{ label: "Bachelor of Business (Hons) Business", value: "Bachelor of Business (Hons) Business" },
	{ label: "Bachelor of Business (Hons) Marketing with Online Technologies", value: "Bachelor of Business (Hons) Marketing with Online Technologies" },
	{ label: "Bachelor of Science (Hons) in Architectural Technology", value: "Bachelor of Science (Hons) in Architectural Technology" },
	{ label: "BSc (Hons) in Construction Management", value: "BSc (Hons) in Construction Management" },
	{ label: "Bachelor of Engineering (Hons) in Fire Safety Engineering", value: "Bachelor of Engineering (Hons) in Fire Safety Engineering" },
	{ label: "Bachelor of Science (Hons) in Quantity Surveying", value: "Bachelor of Science (Hons) in Quantity Surveying" },
	{ label: "Bachelor of Engineering (Hons) in Biomedical Engineering", value: "Bachelor of Engineering (Hons) in Biomedical Engineering" },
	{ label: "Bachelor of Laws (Hons) LLB", value: "Bachelor of Laws (Hons) LLB" },
	{ label: "Bachelor of Arts (Hons) Design (Common Entry)", value: "Bachelor of Arts (Hons) Design (Common Entry)" },
	{ label: "Bachelor of Arts (Hons) Animation", value: "Bachelor of Arts (Hons) Animation" },
	{ label: "Bachelor of Arts (Hons) Film & Media Production", value: "Bachelor of Arts (Hons) Film & Media Production" },
	{ label: "Bachelor of Arts (Hons) Fashion with Promotion", value: "Bachelor of Arts (Hons) Fashion with Promotion" },
	{ label: "Bachelor of Arts (Hons) Graphic & UX Design", value: "Bachelor of Arts (Hons) Graphic & UX Design" },
	{ label: "BSc (Hons) in Athletic Therapy and Exercise Rehabilitation", value: "BSc (Hons) in Athletic Therapy and Exercise Rehabilitation" },
	{ label: "BSc (Hons) in Sports & Exercise (Common Entry)", value: "BSc (Hons) in Sports & Exercise (Common Entry)" },
	{ label: "BSc (Hons) in Sport & Exercise with Performance and Well-being", value: "BSc (Hons) in Sport & Exercise with Performance and Well-being" },
	{ label: "BSc (Hons) in Sport & Exercise with Physical Education", value: "BSc (Hons) in Sport & Exercise with Physical Education" },
	{ label: "Bachelor of Business (Hons) in Accounting", value: "Bachelor of Business (Hons) in Accounting" },
	{ label: "Bachelor of Business (Hons) Business", value: "Bachelor of Business (Hons) Business" },
	{ label: "Bachelor of Business (Hons) Marketing with Online Technologies", value: "Bachelor of Business (Hons) Marketing with Online Technologies" },
	{ label: "Bachelor of Science (Hons) in Architectural Technology", value: "Bachelor of Science (Hons) in Architectural Technology" },
	{ label: "BSc (Hons) in Construction Management", value: "BSc (Hons) in Construction Management" },
	{ label: "Bachelor of Engineering (Hons) in Fire Safety Engineering", value: "Bachelor of Engineering (Hons) in Fire Safety Engineering" },
	{ label: "Bachelor of Science (Hons) in Quantity Surveying", value: "Bachelor of Science (Hons) in Quantity Surveying" },
	{ label: "Bachelor of Engineering (Hons) in Biomedical Engineering", value: "Bachelor of Engineering (Hons) in Biomedical Engineering" },
	{ label: "Bachelor of Engineering (Hons) in Electronic Engineering", value: "Bachelor of Engineering (Hons) in Electronic Engineering" },
	{ label: "Bachelor of Engineering (Hons) in Mechanical Engineering", value: "Bachelor of Engineering (Hons) in Mechanical Engineering" },
	{ label: "Bachelor of Science (Hons) in Applied Computing", value: "Bachelor of Science (Hons) in Applied Computing" },
	{ label: "Bachelor of Science (Hons) in Cyberpsychology", value: "Bachelor of Science (Hons) in Cyberpsychology" },
	{ label: "Bachelor of Science (Hons) Computer Science", value: "Bachelor of Science (Hons) Computer Science" },
	{ label: "Bachelor of Science (Hons) in Agriculture (Common Entry)", value: "Bachelor of Science (Hons) in Agriculture (Common Entry)" },
	{ label: "Bachelor of Science (Hons) in Agriculture (Animal & Crop Science)", value: "Bachelor of Science (Hons) in Agriculture (Animal & Crop Science)" },
	{ label: "Bachelor of Science (Hons) in Agriculture (Environmental Management)", value: "Bachelor of Science (Hons) in Agriculture (Environmental Management)" },
	{ label: "BSc (Hons) in Bioanalytical Science", value: "BSc (Hons) in Bioanalytical Science" },
	{ label: "BSc (Hons) in Food Science and Nutrition", value: "BSc (Hons) in Food Science and Nutrition" },
	{ label: "BSc (Hons)in  Pharmaceutical & Medicinal Science", value: "BSc (Hons)in  Pharmaceutical & Medicinal Science" },
	{ label: "BSc (Hons) in Early Childhood Care, Health & Education", value: "BSc (Hons) in Early Childhood Care, Health & Education" },
	{ label: "Bachelor of Arts in Culinary Arts", value: "Bachelor of Arts in Culinary Arts" },
	{ label: "Bachelor of Business in Business (Common Entry)", value: "Bachelor of Business in Business (Common Entry)" },
	{ label: "Bachelor of Business in Management", value: "Bachelor of Business in Management" },
	{ label: "Bachelor of Business in Marketing with Online Technologies", value: "Bachelor of Business in Marketing with Online Technologies" },
	{ label: "B.Eng in Civil Engineering", value: "B.Eng in Civil Engineering" },
	{ label: "BSc in Construction (Architectural Tech or Construction Mgmt)", value: "BSc in Construction (Architectural Tech or Construction Mgmt)" },
	{ label: "Bachelor of Science in Quantity Surveying", value: "Bachelor of Science in Quantity Surveying" },
	{ label: "B.Eng in Electronic Engineering", value: "B.Eng in Electronic Engineering" },
	{ label: "Bachelor of Engineering in Mechanical Engineering", value: "Bachelor of Engineering in Mechanical Engineering" },
	{ label: "Bachelor of Science in Computer Games Development", value: "Bachelor of Science in Computer Games Development" },
	{ label: "BSc in Cybersecurity and Digital Forensics", value: "BSc in Cybersecurity and Digital Forensics" },
	{ label: "B.Sc in Computing", value: "B.Sc in Computing" },
	{ label: "Bachelor of Science in Agriculture", value: "Bachelor of Science in Agriculture" },
	{ label: "Bachelor of Science in Bioscience", value: "Bachelor of Science in Bioscience" },
	{ label: "BSc in Food Science and Nutrition", value: "BSc in Food Science and Nutrition" },
	{ label: "BSc in Pharmaceutical & Medicinal Science", value: "BSc in Pharmaceutical & Medicinal Science" },
	{ label: "Bachelor of Arts in Tourism & Hospitality Operations", value: "Bachelor of Arts in Tourism & Hospitality Operations" },
	{ label: "Bachelor of Science in Science (Common Entry)", value: "Bachelor of Science in Science (Common Entry)" },
	{ label: "Bachelor of Science (Hons) in Health & Social Care", value: "Bachelor of Science (Hons) in Health & Social Care" },
	{ label: "MSc in Business Management", value: "MSc in Business Management" },
	{ label: "MSc in Data Science & Preparatory Certificate in Computing", value: "MSc in Data Science & Preparatory Certificate in Computing" },
	{ label: "MSc in Healthcare Innovation and Technology", value: "MSc in Healthcare Innovation and Technology" },
	{ label: "MSc in Sport Performance Practice", value: "MSc in Sport Performance Practice" },
	{ label: "MSc in Artificial Intelligence Research", value: "MSc in Artificial Intelligence Research" },
	{ label: "MSc in Big Data Analytics", value: "MSc in Big Data Analytics" },
	{ label: "MSc in Big Data Analytics and Artificial Intelligence", value: "MSc in Big Data Analytics and Artificial Intelligence" },
	{ label: "MSc in Cloud Technologies", value: "MSc in Cloud Technologies" },
	{ label: "MSc in Cloud Technologies Research", value: "MSc in Cloud Technologies Research" },
	{ label: "MSc in Cybersecurity", value: "MSc in Cybersecurity" },
	{ label: "MSc in Computing in Cybersecurity Research", value: "MSc in Computing in Cybersecurity Research" },
	{ label: "MSc DevOps", value: "MSc DevOps" },
	{ label: "MSc DevOps Research", value: "MSc DevOps Research" },
	{ label: "MSc in Cyberpsychology", value: "MSc in Cyberpsychology" }
];

export default AtlanticTechCourses;