const AngliaRuskinCourses = [
	{ value: "Criminology - BA (Hons)", label: "Criminology - BA (Hons)" },
	{ value: "Zoology - BSc (Hons)", label: "Zoology - BSc (Hons)" },
	{ value: "BSc (Hons) Finance with Economics", label: "BSc (Hons) Finance with Economics" },
	{ value: "BSc (Hons) Business with Human Resource Management", label: "BSc (Hons) Business with Human Resource Management" },
	{ value: "Drama and English Literature - BA (Hons)", label: "Drama and English Literature - BA (Hons)" },
	{ value: "BSc (Hons) Business with Economics (4 years with placement)", label: "BSc (Hons) Business with Economics (4 years with placement)" },
	{ value: "BA (Hons) Film and Media (4 years with placement)", label: "BA (Hons) Film and Media (4 years with placement)" },
	{ value: "English Literature - BA (Hons)", label: "English Literature - BA (Hons)" },
	{ value: "BSc (Hons) Ecology and Conservation", label: "BSc (Hons) Ecology and Conservation" },
	{ value: "Optometry - BOptom (Hons)", label: "Optometry - BOptom (Hons)" },
	{ value: "BSc (Hons) Crime and Investigative Studies (4 years with placement)", label: "BSc (Hons) Crime and Investigative Studies (4 years with placement)" },
	{ value: "BA (Hons) Criminology (4 years with placement)", label: "BA (Hons) Criminology (4 years with placement)" },
	{ value: "Pre-sessional Course 10 weeks", label: "Pre-sessional Course 10 weeks" },
	{ value: "Pre-sessional Course 20 weeks", label: "Pre-sessional Course 20 weeks" },
	{ value: "Illustration - BA (Hons)", label: "Illustration - BA (Hons)" },
	{ value: "English Language and Linguistics - BA (Hons)", label: "English Language and Linguistics - BA (Hons)" },
	{ value: "BSc (Hons) Artificial Intelligence (4 years with placement)", label: "BSc (Hons) Artificial Intelligence (4 years with placement)" },
	{ value: "BSc (Hons) Audio & Music Technology", label: "BSc (Hons) Audio & Music Technology" },
	{ value: "BA (Hons) Philosophy (4 years with placement)", label: "BA (Hons) Philosophy (4 years with placement)" },
	{ value: "History - BA (Hons)", label: "History - BA (Hons)" },
	{ value: "BSc (Hons) Audio & Music Technology (4 years with placement)", label: "BSc (Hons) Audio & Music Technology (4 years with placement)" },
	{ value: "BA (Hons) Graphic Design (4 years with placement)", label: "BA (Hons) Graphic Design (4 years with placement)" },
	{ value: "BSc (Hons) Sport and Exercise Science (4 years with placement)", label: "BSc (Hons) Sport and Exercise Science (4 years with placement)" },
	{ value: "PhD Optometry and Vision Sciences", label: "PhD Optometry and Vision Sciences" },
	{ value: "BA (Hons) Photography (4 years with placement)", label: "BA (Hons) Photography (4 years with placement)" },
	{ value: "Illustration and Animation - BA (Hons)", label: "Illustration and Animation - BA (Hons)" },
	{ value: "BSc (Hons) Computer Science (4 years with placement)", label: "BSc (Hons) Computer Science (4 years with placement)" },
	{ value: "Cyber Security - BSc (Hons)", label: "Cyber Security - BSc (Hons)" },
	{ value: "Forensic Science - BSc (Hons)", label: "Forensic Science - BSc (Hons)" },
	{ value: "BSc (Hons) Ecology and Conservation (4 years with placement)", label: "BSc (Hons) Ecology and Conservation (4 years with placement)" },
	{ value: "Philosophy and English Literature - BA (Hons)", label: "Philosophy and English Literature - BA (Hons)" },
	{ value: "Animal Behaviour - BSc (Hons)", label: "Animal Behaviour - BSc (Hons)" },
	{ value: "BEng (Hons) Computer Networks", label: "BEng (Hons) Computer Networks" },
	{ value: "Crime and Investigative Studies - BSc (Hons)", label: "Crime and Investigative Studies - BSc (Hons)" },
	{ value: "Criminology and Sociology - BA (Hons)", label: "Criminology and Sociology - BA (Hons)" },
	{ value: "Sports Coaching and Physical Education - BSc (Hons)", label: "Sports Coaching and Physical Education - BSc (Hons)" },
	{ value: "Sport and Exercise Therapy BSc (Hons) (4 years with placement)", label: "Sport and Exercise Therapy BSc (Hons) (4 years with placement)" },
	{ value: "BA (Hons) Writing and English Literature (4 years with placement)", label: "BA (Hons) Writing and English Literature (4 years with placement)" },
	{ value: "BSc (Hons) Cyber Security (4 years with placement)", label: "BSc (Hons) Cyber Security (4 years with placement)" },
	{ value: "BA (Hons) Film and Media", label: "BA (Hons) Film and Media" },
	{ value: "Biomedical Science - BSc (Hons)", label: "Biomedical Science - BSc (Hons)" },
	{ value: "BSc (Hons) Computer Games Technology (4 years with placement)", label: "BSc (Hons) Computer Games Technology (4 years with placement)" },
	{ value: "BA (Hons) Drama (4 years with placement)", label: "BA (Hons) Drama (4 years with placement)" },
	{ value: "BSc (Hons) Sports Coaching and Physical Education (4 years with placement)", label: "BSc (Hons) Sports Coaching and Physical Education (4 years with placement)" },
	{ value: "Philosophy - BA (Hons)", label: "Philosophy - BA (Hons)" },
	{ value: "BSc (Hons) Business with Human Resource Management (4 years with placement)", label: "BSc (Hons) Business with Human Resource Management (4 years with placement)" },
	{ value: "Sociology - BA (Hons)", label: "Sociology - BA (Hons)" },
	{ value: "BSc (Hons) Business with Events Management", label: "BSc (Hons) Business with Events Management" },
	{ value: "BSc (Hons) Psychology (4 years with placement)", label: "BSc (Hons) Psychology (4 years with placement)" },
	{ value: "BSc (Hons) Psychology with Clinical Psychology (with placement year)", label: "BSc (Hons) Psychology with Clinical Psychology (with placement year)" },
	{ value: "BSc (Hons) Business with Marketing", label: "BSc (Hons) Business with Marketing" },
	{ value: "BA (Hons) English Literature (4 years with placement)", label: "BA (Hons) English Literature (4 years with placement)" },
	{ value: "BSc (Hons) Psychology with Criminology (4 years with placement)", label: "BSc (Hons) Psychology with Criminology (4 years with placement)" },
	{ value: "BA (Hons) Writing and Film (4 years with placement)", label: "BA (Hons) Writing and Film (4 years with placement)" },
	{ value: "BA (Hons) Music Performance", label: "BA (Hons) Music Performance" },
	{ value: "BSc (Hons) Psychology with Criminology", label: "BSc (Hons) Psychology with Criminology" },
	{ value: "Writing and English Literature - BA (Hons)", label: "Writing and English Literature - BA (Hons)" },
	{ value: "BA (Hons) Writing and Film", label: "BA (Hons) Writing and Film" },
	{ value: "BA (Hons) Film (4 years with placement)", label: "BA (Hons) Film (4 years with placement)" },
	{ value: "BSc (Hons) Zoology (4 years with placement)", label: "BSc (Hons) Zoology (4 years with placement)" },
	{ value: "Fine Art - BA (Hons)", label: "Fine Art - BA (Hons)" },
	{ value: "BSc (Hons) Business with Entrepreneurship", label: "BSc (Hons) Business with Entrepreneurship" },
	{ value: "Pre-sessional Course 6 weeks", label: "Pre-sessional Course 6 weeks" },
	{ value: "Pre-sessional Course 12 weeks", label: "Pre-sessional Course 12 weeks" },
	{ value: "Public Service (Top-Up) - BA (Hons)", label: "Public Service (Top-Up) - BA (Hons)" },
	{ value: "Sport and Exercise Science - BSc (Hons)", label: "Sport and Exercise Science - BSc (Hons)" },
	{ value: "BSc (Hons) Psychology with Clinical Psychology", label: "BSc (Hons) Psychology with Clinical Psychology" },
	{ value: "BSc (Hons) Animal Behaviour (4 years with placement)", label: "BSc (Hons) Animal Behaviour (4 years with placement)" },
	{ value: "Drama - BA (Hons)", label: "Drama - BA (Hons)" },
	{ value: "BA (Hons) Media and Communication", label: "BA (Hons) Media and Communication" },
	{ value: "Psychology - BSc (Hons)", label: "Psychology - BSc (Hons)" },
	{ value: "BSc (Hons) Computer Science", label: "BSc (Hons) Computer Science" },
	{ value: "BA (Hons) Interior Design (4 years with placement)", label: "BA (Hons) Interior Design (4 years with placement)" },
	{ value: "BSc (Hons) Computer Games Technology", label: "BSc (Hons) Computer Games Technology" },
	{ value: "Interior Design - BA (Hons)", label: "Interior Design - BA (Hons)" },
	{ value: "BA (Hons) Film", label: "BA (Hons) Film" },
	{ value: "BSc (Hons) Business with Tourism Management", label: "BSc (Hons) Business with Tourism Management" },
	{ value: "BA (Hons) English Language and Linguistics (4 years with placement)", label: "BA (Hons) English Language and Linguistics (4 years with placement)" },
	{ value: "BSc (Hons) Forensic Science (4 years with placement)", label: "BSc (Hons) Forensic Science (4 years with placement)" },
	{ value: "BSc (Hons) Software Development (4 years with placement)", label: "BSc (Hons) Software Development (4 years with placement)" },
	{ value: "PhD History", label: "PhD History" },
	{ value: "BA (Hons) Digital Media Production", label: "BA (Hons) Digital Media Production" },
	{ value: "BA (Hons) Film Production", label: "BA (Hons) Film Production" },
	{ value: "Photography - BA (Hons)", label: "Photography - BA (Hons)" },
	{ value: "BA (Hons) Computer Games Art (4 years with placement)", label: "BA (Hons) Computer Games Art (4 years with placement)" },
	{ value: "BA (Hons) Criminology and Sociology (4 years with placement)", label: "BA (Hons) Criminology and Sociology (4 years with placement)" },
	{ value: "BSc (Hons) International Business Management (4 years with placement)", label: "BSc (Hons) International Business Management (4 years with placement)" },
	{ value: "Computer Games Art - BA (Hons)", label: "Computer Games Art - BA (Hons)" },
	{ value: "Sport and Exercise Therapy BSc (Hons)", label: "Sport and Exercise Therapy BSc (Hons)" },
	{ value: "Graphic Design - BA (Hons)", label: "Graphic Design - BA (Hons)" },
	{ value: "Pre-sessional Course 15 weeks", label: "Pre-sessional Course 15 weeks" },
	{ value: "BA (Hons) Digital Media Production (4 years with placement)", label: "BA (Hons) Digital Media Production (4 years with placement)" },
	{ value: "BSc (Hons) Business with Entrepreneurship (4 years with placement)", label: "BSc (Hons) Business with Entrepreneurship (4 years with placement)" },
	{ value: "Computer Science - BEng (Hons)", label: "Computer Science - BEng (Hons)" },
	{ value: "BA (Hons) Drama and Film", label: "BA (Hons) Drama and Film" },
	{ value: "BSc (Hons) Business with Economics", label: "BSc (Hons) Business with Economics" },
	{ value: "BA (Hons) Philosophy and English Literature (4 years with placement)", label: "BA (Hons) Philosophy and English Literature (4 years with placement)" },
	{ value: "BA (Hons) Fashion Design (4 years with placement)", label: "BA (Hons) Fashion Design (4 years with placement)" },
	{ value: "BSc (Hons) Software Development", label: "BSc (Hons) Software Development" },
	{ value: "BA (Hons) Criminology and Policing", label: "BA (Hons) Criminology and Policing" },
	{ value: "BA (Hons) Drama and English Literature (4 years with placement)", label: "BA (Hons) Drama and English Literature (4 years with placement)" },
	{ value: "Fashion Design - BA (Hons)", label: "Fashion Design - BA (Hons)" },
	{ value: "Politics - BA (Hons)", label: "Politics - BA (Hons)" },
	{ value: "BA (Hons) Media and Communication (4 years with placement)", label: "BA (Hons) Media and Communication (4 years with placement)" },
	{ value: "BA (Hons) Film Production (4 years with placement)", label: "BA (Hons) Film Production (4 years with placement)" },
	{ value: "BSc (Hons) Business with Events Management (4 years with placement)", label: "BSc (Hons) Business with Events Management (4 years with placement)" },
	{ value: "BSc (Hons) Computer Networks (4 years with placement)", label: "BSc (Hons) Computer Networks (4 years with placement)" },
	{ value: "BA (Hons) Drama and Film (4 years with placement)", label: "BA (Hons) Drama and Film (4 years with placement)" },
	{ value: "BA (Hons) Fine Art (4 years with placement)", label: "BA (Hons) Fine Art (4 years with placement)" },
	{ value: "BEng (Hons) Computer Science (4 years with placement)", label: "BEng (Hons) Computer Science (4 years with placement)" },
	{ value: "BA (Hons) Politics (4 years with placement)", label: "BA (Hons) Politics (4 years with placement)" },
	{ value: "BA (Hons) Illustration (4 years with placement)", label: "BA (Hons) Illustration (4 years with placement)" },
	{ value: "International Business Management - BSc (Hons)", label: "International Business Management - BSc (Hons)" },
	{ value: "BSc (Hons) Artificial Intelligence", label: "BSc (Hons) Artificial Intelligence" }
];

export default AngliaRuskinCourses;