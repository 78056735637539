import React from 'react';
import { FormControl, FormLabel, Stack, Textarea, Input, HStack, Radio, Button } from '@chakra-ui/react';
import { getUserDetailsFromToken } from '../utils/getUserDetailsFromToken';

const CommentForm = ({ comment, setComment, setAttachments, handleSubmitComment, isSubmitEnabled, fileInputRef, visibility, setVisibility }) => {
	const userDetails = getUserDetailsFromToken();
	const isAdmin = userDetails?.privilege === 'Admin';
	return (
		<FormControl as="form" onSubmit={handleSubmitComment}>
			<Stack spacing={4}>
				<Textarea
					placeholder="Enter comment"
					value={comment}
					onChange={(e) => setComment(e.target.value)}
				/>
				<Input
					type="file"
					multiple
					onChange={(e) => setAttachments([...e.target.files])}
					ref={fileInputRef}
				/>
				{/* Horizontal Radio Buttons */}
				{isAdmin && (
					<HStack>
						<FormLabel htmlFor="visibilityOption">Visibility:</FormLabel>
						<Radio
							id="publicOption"
							value="Public"
							name="visibilityOption"
							onChange={(e) => setVisibility(e.target.value)}
							isChecked={visibility === 'Public' || visibility === undefined}
						>
							Public
						</Radio>
						<Radio
							id="privateOption"
							value="Private"
							name="visibilityOption"
							onChange={(e) => setVisibility(e.target.value)}
							isChecked={visibility === 'Private'}
						>
							Private
						</Radio>
					</HStack>
				)}
				<Button
					colorScheme="blue"
					onClick={handleSubmitComment}
					disabled={!isSubmitEnabled}
				>
					Send
				</Button>
			</Stack>
		</FormControl>
	);
};

export default CommentForm;
