import React, { useState, useEffect } from 'react';
import { Button, Table, Form, FormGroup, Label, Input } from 'reactstrap';
import AccountLayout from '../layout/AccountLayout';
import {
	useToast, useDisclosure, AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	useBreakpointValue,
	Box
} from '@chakra-ui/react';

const BranchesList = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const cancelRef = React.useRef();
	const [branches, setBranches] = useState([]);
	const [editingId, setEditingId] = useState('');
	const [newBranchName, setNewBranchName] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [recordsPerPage] = useState(20);
	const [deleteBranchId, setDeleteBranchId] = useState(null);
	const [searchTerm, setSearchTerm] = useState('');

	const toast = useToast();

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URL}/api/branches`)
			.then(res => res.json())
			.then(data => {
				console.log("Fetched branches data:", data);
				setBranches(data); // Assuming data is already in the desired format
			})
			.catch(error => console.error('Error fetching branches:', error));
	}, []);

	const deleteBranch = async (id) => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/branches/${id}`, {
				method: 'DELETE',
			});
			if (!response.ok) {
				throw new Error('Failed to delete the branch.');
			}
			// Remove the branch from the local state to update the UI accordingly
			setBranches(branches.filter(branch => branch.value !== id));
			toast({
				title: 'Branch deleted',
				description: 'The branch has been successfully deleted.',
				status: 'success',
				duration: 5000,
				isClosable: true,
			});
		} catch (error) {
			console.error('Error:', error);
			toast({
				title: 'Error',
				description: 'There was an error deleting the branch.',
				status: 'error',
				duration: 5000,
				isClosable: true,
			});
		}
	};

	const editBranch = async (id) => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/branches/${id}`, {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ branchName: newBranchName }),
			});
			if (!response.ok) {
				throw new Error('Failed to update the branch.');
			}

			setBranches(branches.map(branch =>
				branch.value === id ? { ...branch, label: newBranchName } : branch
			));
			setEditingId('');
			setNewBranchName('');
			toast({
				title: 'Branch updated',
				description: 'The branch has been successfully updated.',
				status: 'success',
				duration: 5000,
				isClosable: true,
			});
		} catch (error) {
			console.error('Error:', error);
			toast({
				title: 'Error',
				description: 'There was an error updating the branch.',
				status: 'error',
				duration: 5000,
				isClosable: true,
			});
		}
	};

	const handleEditChange = (e) => {
		setNewBranchName(e.target.value);
	};

	const handleEditSubmit = (e) => {
		e.preventDefault(); // Prevent default form submission
		editBranch(editingId);
	};

	// const handleSearchChange = (e) => {
	// 	setSearchTerm(e.target.value);
	// 	setCurrentPage(1); // Reset to the first page when search term changes
	// };

	// New addition: Filter branches based on search term
	const filteredBranches = searchTerm.length === 0 ? branches : branches.filter(branch =>
		branch.label.toLowerCase().includes(searchTerm.toLowerCase())
	);

	const currentBranches = filteredBranches.slice(
		(currentPage - 1) * recordsPerPage,
		currentPage * recordsPerPage
	);

	const paginate = pageNumber => setCurrentPage(pageNumber);
	const pageNumbers = [];
	for (let i = 1; i <= Math.ceil(filteredBranches.length / recordsPerPage); i++) {
		pageNumbers.push(i);
	}

	const minWidth = useBreakpointValue({ base: '100%', md: '500px' });

	if (branches.length === 0) {
		return <div>Loading branches...</div>;
	}

	return (
		<>
			<AccountLayout>
				<Box style={{ minWidth: minWidth }}>
					<Input
						placeholder="Search branches..."
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
						my={4}
					/>
				</Box>
				<Table className='table table-striped table-bordered' style={{ minWidth: minWidth }}>
					<thead>
						<tr>
							<th>Branch Name</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{currentBranches.map((branch) => (
							editingId === branch.value ? ( // Use branch.value to compare with editingId
								<tr key={branch.value}> {/* Use branch.value for the key */}
									<td>
										<Form inline onSubmit={handleEditSubmit}>
											<FormGroup>
												<Label hidden>Edit Branch Name</Label>
												<Input
													type="text"
													value={newBranchName}
													onChange={handleEditChange}
													required
												/>
											</FormGroup>
											<div style={{ display: 'flex', gap: '10px' }}>
												<Button type="submit" color="primary">Save</Button>
												<Button onClick={() => setEditingId('')} color="secondary">Cancel</Button>
											</div>
										</Form>
									</td>
									<td>
										{/* If you have other actions column, they would go here */}
									</td>
								</tr>
							) : (
								<tr key={branch.value}>
									<td>{branch.label}</td> {/* Use branch.label for displaying name */}
									<td style={{ display: 'flex', gap: '10px' }}>
										<Button onClick={() => { setEditingId(branch.value); setNewBranchName(branch.label); }} color="info">Edit</Button>
										<Button onClick={() => { setDeleteBranchId(branch.value); onOpen(); }} color="danger">Delete</Button>
									</td>
								</tr>
							)
						))}
					</tbody>
				</Table>
				{branches.length > recordsPerPage && (
					<nav>
						<ul className='pagination'>
							{pageNumbers.map(number => (
								<li key={number} className='page-item'>
									<button onClick={() => paginate(number)} className='page-link' style={{ border: 0, background: 'none' }}>
										{number}
									</button>
								</li>
							))}
						</ul>
					</nav>
				)}
			</AccountLayout>
			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Delete Branch
						</AlertDialogHeader>

						<AlertDialogBody>
							Are you sure? You can't undo this action afterwards.
						</AlertDialogBody>

						<AlertDialogFooter style={{ display: 'flex', gap: '10px' }}>
							<Button ref={cancelRef} onClick={onClose}>
								Cancel
							</Button>
							<Button color="danger" onClick={() => {
								deleteBranch(deleteBranchId);
								onClose();
							}} ml={3}>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
};

export default BranchesList;
