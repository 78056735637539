const CollegeDeParisCourses = [
	{ value: "Bachelor in Business Administration", label: "Bachelor in Business Administration" },
	{ value: "Ms Digital Management of Tourism and Hospitality", label: "Ms Digital Management of Tourism and Hospitality" },
	{ value: "Master of Science in Culinary Arts and Management", label: "Master of Science in Culinary Arts and Management" },
	{ value: "Master of Science in International Marketing", label: "Master of Science in International Marketing" },
	{ value: "Bachelors of Business Administration Business", label: "Bachelors of Business Administration Business" },
	{ value: "Bachelors of Business Administration IT Training", label: "Bachelors of Business Administration IT Training" },
	{ value: "Bachelors of Business Administration Tourism & Hospitality", label: "Bachelors of Business Administration Tourism & Hospitality" },
	{ value: "Bachelors of Business Administration Action Sports Marketing & Management", label: "Bachelors of Business Administration Action Sports Marketing & Management" },
	{ value: "Master of Business Administration Business & International Affairs", label: "Master of Business Administration Business & International Affairs" },
	{ value: "MBA IT Training", label: "MBA IT Training" },
	{ value: "Master of Business Administration Digital Management of Tourism & Hospitality", label: "Master of Business Administration Digital Management of Tourism & Hospitality" },
	{ value: "Master of Business Administration Action Sports Marketing & Governance", label: "Master of Business Administration Action Sports Marketing & Governance" },
	{ value: "Master of Business Administration in International Marketing", label: "Master of Business Administration in International Marketing" },
	{ value: "Master of Business Administration in Food & Beverage Management", label: "Master of Business Administration in Food & Beverage Management" },
	{ value: "Master of Business Administration in Hospitality & Tourism Management", label: "Master of Business Administration in Hospitality & Tourism Management" },
	{ value: "Master of Business Administration in Entrepreneurship", label: "Master of Business Administration in Entrepreneurship" },
	{ value: "Master of Business Administration in Artificial Intelligence & Data Science", label: "Master of Business Administration in Artificial Intelligence & Data Science" },
	{ value: "Master of Science in Food and Beverage Management", label: "Master of Science in Food and Beverage Management" },
	{ value: "Master of Science in Hospitality & Tourism Management", label: "Master of Science in Hospitality & Tourism Management" },
	{ value: "Master of Science in Business & International Affairs", label: "Master of Science in Business & International Affairs" },
	{ value: "Master of Science in Digital Management of Tourism & Hospitality", label: "Master of Science in Digital Management of Tourism & Hospitality" },
	{ value: "Master of Science in Action Sports Marketing & Governance", label: "Master of Science in Action Sports Marketing & Governance" },
	{ value: "Ms in Culinary Art Program & Management", label: "Ms in Culinary Art Program & Management" },
	{ value: "Ms Fashion and Luxury Goods Management", label: "Ms Fashion and Luxury Goods Management" },
	{ value: "Ms Food and Beverage Management", label: "Ms Food and Beverage Management" },
	{ value: "MS Hospitality & Tourism Management", label: "MS Hospitality & Tourism Management" },
	{ value: "Ms International Marketing", label: "Ms International Marketing" },
	{ value: "MBA Food & Beverage Management", label: "MBA Food & Beverage Management" },
	{ value: "Global MBA", label: "Global MBA" },
	{ value: "MBA Fashion and Luxury Goods Management", label: "MBA Fashion and Luxury Goods Management" },
	{ value: "MBA Culinary Art Program & Management", label: "MBA Culinary Art Program & Management" },
	{ value: "MBA in Entrepreneurship", label: "MBA in Entrepreneurship" },
	{ value: "MBA Hospitality & Tourism Management", label: "MBA Hospitality & Tourism Management" },
	{ value: "MBA in Artificial Intelligence & Data Science", label: "MBA in Artificial Intelligence & Data Science" },
	{ value: "MBA International Marketing", label: "MBA International Marketing" },
	{ value: "Bachelor in Business Administration", label: "Bachelor in Business Administration" },
	{ value: "Bachelor Design 360°", label: "Bachelor Design 360°" },
	{ value: "Bachelor in Textile Design", label: "Bachelor in Textile Design" },
	{ value: "Bachelor in Objects Design", label: "Bachelor in Objects Design" },
	{ value: "Bachelor in Interior Design", label: "Bachelor in Interior Design" },
	{ value: "Technical MBA in Cloudification and Data Security", label: "Technical MBA in Cloudification and Data Security" },
	{ value: "Technical MBA in Communication", label: "Technical MBA in Communication" },
	{ value: "Technical MBA in 5G/6G Smart Solutions", label: "Technical MBA in 5G/6G Smart Solutions" }
];

export default CollegeDeParisCourses;