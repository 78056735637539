import React, { useState, useRef, useEffect } from 'react';
// Import necessary components and icons
import { Box, Flex, IconButton, Image, Link, SimpleGrid, Text, Textarea, Badge, Button, Spacer, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay } from '@chakra-ui/react';
import { FaEdit, FaTimes, FaUser, FaTrash } from 'react-icons/fa'; // Added FaTrash for delete icon
import { getUserDetailsFromToken } from '../utils/getUserDetailsFromToken';
import { useLocation } from 'react-router-dom';
import { LockIcon } from '@chakra-ui/icons';

const CommentsList = ({ comments, editingComment, handleEditComment, cancelEdit, submitEditedComment, isImage, formatCommentDate, isAdmin, setEditingComment, deleteComment, commentsPerPage, renderPageNumbers, mode }) => {

	const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
	const [commentToDelete, setCommentToDelete] = useState(null);
	const cancelRef = useRef();

	const textareaRef = useRef(null);
	const location = useLocation();

	// Use useEffect to focus and set the cursor at the end of the textarea when editing starts
	useEffect(() => {
		if (editingComment.id && textareaRef.current) {
			textareaRef.current.focus();
			// Set the cursor at the end of the current text
			textareaRef.current.setSelectionRange(editingComment.text.length, editingComment.text.length);
		}
	}, [editingComment, textareaRef]);

	useEffect(() => {
		// Get the hash fragment from the location
		const hash = location.hash;

		// Check if the hash fragment is not empty and starts with a '#'
		if (hash && hash.startsWith('#')) {
			// Remove the '#' symbol to get the ID
			const id = hash.slice(1);

			// Check if there is an element with the matching id
			const targetElement = document.getElementById(id);
			if (targetElement) {
				// Scroll to the target element
				targetElement.scrollIntoView({ behavior: 'smooth' });
			}
		}
	}, [location]);

	const requestDeleteComment = (commentId) => {
		setCommentToDelete(commentId);
		setIsAlertDialogOpen(true);
	};

	const onClose = () => setIsAlertDialogOpen(false);

	// Function to call when confirmation to delete is given
	const confirmDeleteComment = async () => {
		if (commentToDelete) {
			await deleteComment(commentToDelete); // Assuming deleteComment is a prop function that handles deletion
			setIsAlertDialogOpen(false); // Close the AlertDialog
		}
	};


	const userDetails = getUserDetailsFromToken();

	const canEditOrDelete = (commentUserId) => {
		// console.log(commentUserId);
		return userDetails && (userDetails.userId === commentUserId);
	};

	return (
		<div className="comments-list" style={{ marginTop: '30px' }}>
			{comments && comments.length > 0 ? (
				<SimpleGrid columns={1} spacing={4}>
					{comments.map((comment) => (
						((comment.visibility === "Public") || (comment.visibility === "Private" && isAdmin)) && (
							<>
								<Box key={comment._id} id={comment._id} alignItems="center" justifyContent="space-between">
									<Box
										borderWidth="1px"
										borderRadius="lg"
										p={4}
										bgColor={mode('aliceBlue', 'gray.700')}
									>
										<Flex alignItems="center" mb={2}>
											<Box>
												<FaUser className='searchIcon' />
											</Box>
											<Box ml={2}>
												<Text fontSize="lg" m={0} fontWeight="bold">
													{comment?.user?.userName} {comment?.visibility === 'Private' && (
														<Badge colorScheme="blue" fontSize="sm" borderRadius={5} pl={2} pr={2}>
															<LockIcon boxSize={3} color="gray.500" /> Private to you
														</Badge>
													)}
												</Text>
											</Box>
											<Spacer />
											<Box>
												<Text fontSize="sm" color={mode('gray.500', 'gray.300')} textAlign="right" display={{ base: 'none', md: 'block' }}>
													{formatCommentDate(comment.datePosted)}
												</Text>
											</Box>
										</Flex>
										<Box>
											{
												canEditOrDelete(comment?.user?._id) && (
													<>
														<IconButton
															icon={<FaTrash />}
															size="sm"
															aria-label="Delete Comment"
															onClick={() => requestDeleteComment(comment?._id)} // Updated to use requestDeleteComment
														/>
														<IconButton
															icon={editingComment.id === comment?._id ? <FaTimes /> : <FaEdit />}
															size="sm"
															aria-label={editingComment.id === comment?._id ? "Close Edit" : "Edit Comment"}
															onClick={() => editingComment.id === comment?._id ? cancelEdit() : handleEditComment(comment)}
														/>
													</>
												)
											}
										</Box>
										{comment.text && (
											<>
												{editingComment.id === comment._id ? (
													<form onSubmit={(e) => {
														e.preventDefault();
														submitEditedComment();
													}}>
														<Textarea
															ref={textareaRef}
															value={editingComment.text}
															onChange={(e) => setEditingComment({ ...editingComment, text: e.target.value })}
															style={{
																backgroundColor: 'white',
																outline: 'none',
															}}
														/>
														<div style={{ marginTop: '10px', display: 'flex', gap: '10px' }}>
															<Button type="submit" colorScheme="blue" variant="solid">Save</Button>
															<Button onClick={cancelEdit} colorScheme="gray" variant="solid">Cancel</Button>
														</div>
													</form>
												) : (
													<>
														<Text fontSize="sm" color={mode('gray.500', 'gray.300')} display={{ base: 'block', md: 'none' }}>
															{formatCommentDate(comment.datePosted)}
														</Text>
														<Text className='comment-text' mt={2}>
															{comment.text}
														</Text>
													</>
												)}
											</>
										)}
										{comment.attachments && comment.attachments.length > 0 && (
											<Flex mt={2} wrap="wrap" justifyContent="flex-start" gap="10px">
												{comment.attachments.map((attachment, idx) => (
													<Box
														key={idx}
														className='comment-attachment'
														boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
														borderRadius="md"
														overflow="hidden"
														backgroundColor="white"
														display="flex"
														alignItems="center"
													>
														{isImage(attachment) ? (
															<Image
																src={`${process.env.REACT_APP_API_URL}/${attachment}`}
																alt="Attachment"
																maxW="100px"
																maxH="100px"
																cursor="pointer"
																onClick={() => window.open(`${process.env.REACT_APP_API_URL}/${attachment}`, '_blank')}
															/>
														) : (
															<Link href={`${process.env.REACT_APP_API_URL}/${attachment}`} target="_blank" rel="noopener noreferrer">
																{attachment.split('/').pop()}
															</Link>
														)}
													</Box>
												))}
											</Flex>
										)}
										{comment.edited && <Text mt={5} fontSize="xs" color="gray.500">Edited</Text>}
									</Box>
								</Box>
							</>
						)
					))}
				</SimpleGrid>
			) : (
				<Text fontSize="lg" fontWeight="bold">
					No comments available. Be the first one to write a comment.
				</Text>
			)}
			{comments.length > commentsPerPage && renderPageNumbers()}
			<AlertDialog
				isOpen={isAlertDialogOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Delete Comment
						</AlertDialogHeader>
						<AlertDialogBody>
							Are you sure? You can't undo this action afterwards.
						</AlertDialogBody>
						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={onClose}>
								Cancel
							</Button>
							<Button colorScheme="red" onClick={confirmDeleteComment} ml={3}>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</div>
	);
};

export default CommentsList;
