import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FormGroup, Label, Input } from 'reactstrap';
import { Card } from '../global/holder/Card';
import { VStack, Box, Center, Button, useToast } from '@chakra-ui/react';
import { useAuth } from '../utils/AuthContext';
import AccountLayout from '../layout/AccountLayout';

const PasswordReset = () => {
	const { isLoggedIn } = useAuth();
	const { token } = useParams();
	const navigate = useNavigate();
	const toast = useToast();
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [isTokenValid, setIsTokenValid] = useState(false);

	useEffect(() => {
		const verifyToken = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}/api/verify-reset-token/${token}`, {
					method: 'GET',
				});
				if (!response.ok) throw new Error('Token verification failed');
				setIsTokenValid(true);
			} catch (error) {
				toast({
					title: 'Error',
					description: error.message,
					status: 'error',
					duration: 5000,
					isClosable: true,
				});
			}
		};
		verifyToken();
	}, [token, toast]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (password !== confirmPassword) {
			toast({
				title: 'Error',
				description: "Passwords don't match.",
				status: 'error',
				duration: 5000,
				isClosable: true,
			});
			return;
		}

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/reset-password/${token}`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ password })
			});
			if (!response.ok) {
				throw new Error('Failed to reset password');
			}
			const data = await response.json();
			toast({
				title: 'Success',
				description: data.message,
				status: 'success',
				duration: 5000,
				isClosable: true,
			});
			setPassword('');
			setConfirmPassword('');
			navigate('/login'); // Assuming you want to redirect to login page
		} catch (error) {
			toast({
				title: 'Failed',
				description: error.message,
				status: 'error',
				duration: 5000,
				isClosable: true,
			});
		}
	};

	return (
		<>
			{!isLoggedIn ?
				<Center p="20">
					<Card>
						<VStack spacing={4} align="stretch" minWidth={{ base: '100%', md: '400px' }}>
							<h1>Set New Password</h1>
							{isTokenValid ? (
								<Box as="form" onSubmit={handleSubmit}>
									<FormGroup className="text-left">
										<Label for="password">New Password</Label>
										<Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
									</FormGroup>
									<FormGroup className="text-left">
										<Label for="confirmPassword">Confirm Password</Label>
										<Input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
									</FormGroup>

									<Button mt={4} colorScheme="blue" type="submit">
										Reset Password
									</Button>
								</Box>
							) : (
								<p>Verifying token...</p>
							)}
						</VStack>
					</Card>
				</Center> :
				<AccountLayout>
					<Center>
						<Card>
							<VStack spacing={4} align="stretch" minWidth={{ base: '100%', md: '400px' }}>
								<h1>Set New Password</h1>
								{isTokenValid ? (
									<Box as="form" onSubmit={handleSubmit}>
										<FormGroup className="text-left">
											<Label for="password">New Password</Label>
											<Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
										</FormGroup>
										<FormGroup className="text-left">
											<Label for="confirmPassword">Confirm Password</Label>
											<Input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
										</FormGroup>

										<Button mt={4} colorScheme="blue" type="submit">
											Reset Password
										</Button>
									</Box>
								) : (
									<p>Verifying token...</p>
								)}
							</VStack>
						</Card>
					</Center>
				</AccountLayout>
			}
		</>
	);
};

export default PasswordReset;
