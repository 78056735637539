const BowValleyCourses = [
	{ value: 'Diploma in Disability Studies', label: 'Diploma in Disability Studies' },
	{ value: 'Post-Diploma Certificate in Data Management and Analytics', label: 'Post-Diploma Certificate in Data Management and Analytics' },
	{ value: 'Diploma in Business Administration - Human Resources (Major)', label: 'Diploma in Business Administration - Human Resources (Major)' },
	{ value: 'Diploma in Software Development', label: 'Diploma in Software Development' },
	{ value: 'Post-Diploma Certificate in Kitchen and Bath Design', label: 'Post-Diploma Certificate in Kitchen and Bath Design' },
	{ value: 'Diploma in Business Administration - General Business (Major)', label: 'Diploma in Business Administration - General Business (Major)' },
	{ value: 'Diploma in Advanced Entertainment Arts Production Management', label: 'Diploma in Advanced Entertainment Arts Production Management' },
	{ value: 'Post-Diploma Certificate in Cybersecurity', label: 'Post-Diploma Certificate in Cybersecurity' },
	{ value: 'Diploma in Information Technology Systems', label: 'Diploma in Information Technology Systems' },
	{ value: 'Diploma in Business Administration - Accounting (Major)', label: 'Diploma in Business Administration - Accounting (Major)' },
	{ value: 'Certificate in Education Assistant', label: 'Certificate in Education Assistant' },
	{ value: 'Certificate in Disability Studies', label: 'Certificate in Disability Studies' },
	{ value: 'Diploma in Pharmacy Technician', label: 'Diploma in Pharmacy Technician' },
	{ value: 'Certificate in Foundation in Entertainment Arts', label: 'Certificate in Foundation in Entertainment Arts' },
	{ value: 'Certificate in Business Administration', label: 'Certificate in Business Administration' },
	{ value: 'Certificate in Human Resources', label: 'Certificate in Human Resources' },
	{ value: 'Diploma in Social Work', label: 'Diploma in Social Work' },
	{ value: 'Diploma in Early Childhood Education and Development', label: 'Diploma in Early Childhood Education and Development' },
	{ value: 'Diploma in Interior Decorating', label: 'Diploma in Interior Decorating' },
	{ value: 'Certificate in Veterinary Office Assistant', label: 'Certificate in Veterinary Office Assistant' },
	{ value: 'Diploma in Justice Studies - specialization in General Justice', label: 'Diploma in Justice Studies - specialization in General Justice' },
	{ value: 'Post-Diploma Certificate in Digital Marketing', label: 'Post-Diploma Certificate in Digital Marketing' },
	{ value: 'Certificate in Health care Aide', label: 'Certificate in Health care Aide' },
	{ value: 'Diploma in Business Administration - Insurance and Risk Management (Major)', label: 'Diploma in Business Administration - Insurance and Risk Management (Major)' },
	{ value: 'Diploma in Legal Assistant', label: 'Diploma in Legal Assistant' },
	{ value: 'Certificate in Medical Office Assistant', label: 'Certificate in Medical Office Assistant' },
	{ value: 'Diploma in Business Administration - Financial Services (Major)', label: 'Diploma in Business Administration - Financial Services (Major)' },
	{ value: 'Diploma in Advanced 3D Animation and 3D Modelling', label: 'Diploma in Advanced 3D Animation and 3D Modelling' },
	{ value: 'Diploma in Advanced Game Development', label: 'Diploma in Advanced Game Development' },
	{ value: 'Post-Diploma Certificate in Cloud Computing', label: 'Post-Diploma Certificate in Cloud Computing' },
	{ value: 'Diploma in Child and Youth care', label: 'Diploma in Child and Youth care' },
	{ value: 'Diploma in Advanced Film Production', label: 'Diploma in Advanced Film Production' },
	{ value: 'Post-Diploma Certificate in Health and Human Services Management', label: 'Post-Diploma Certificate in Health and Human Services Management' },
	{ value: 'Diploma in Advanced Visual FX (Virtual Production)', label: 'Diploma in Advanced Visual FX (Virtual Production)' },
	{ value: 'Diploma in Esports Business Management', label: 'Diploma in Esports Business Management' },
	{ value: 'Diploma in Business Administration - Digital Marketing (Major)', label: 'Diploma in Business Administration - Digital Marketing (Major)' },
	{ value: 'Diploma in Digital Design', label: 'Diploma in Digital Design' },
	{ value: 'Diploma in Business Administration - Supply Chain Management ( Major )', label: 'Diploma in Business Administration - Supply Chain Management ( Major )' },
	{ value: 'Certificate in Administrative Professional', label: 'Certificate in Administrative Professional' }
];

export default BowValleyCourses;