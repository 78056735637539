const NzManagementAcadCourses = [
	{ value: "Diploma in Hospitality Management(Level 6)", label: "Diploma in Hospitality Management(Level 6)" },
	{ value: "Diploma in Hospitality Management(Level 5)", label: "Diploma in Hospitality Management(Level 5)" },
	{ value: "Certificate in Hospitality (Restaurant Service) (Level 4)", label: "Certificate in Hospitality (Restaurant Service) (Level 4)" },
	{ value: "Certificate in Health and Wellbeing (Community and Mental Health)", label: "Certificate in Health and Wellbeing (Community and Mental Health)" },
	{ value: "Health and Wellbeing - Introduction(Level 3)", label: "Health and Wellbeing - Introduction(Level 3)" },
	{ value: "Certificate in Health and Wellbeing (Social & Community Services)", label: "Certificate in Health and Wellbeing (Social & Community Services)" },
	{ value: "Aged Care and Complex Needs (Level 4)", label: "Aged Care and Complex Needs (Level 4)" },
	{ value: "Social and Community Health (Level 4)", label: "Social and Community Health (Level 4)" },
	{ value: "Mental Health and Addiction (Level 4)", label: "Mental Health and Addiction (Level 4)" },
	{ value: "Introduction to Pharmacy Practice (Level 3)", label: "Introduction to Pharmacy Practice (Level 3)" },
	{ value: "Certificate in Pharmacy Technician (Level 5)", label: "Certificate in Pharmacy Technician (Level 5)" },
	{ value: "Certificate in Early Childhood and Care (Level 4)", label: "Certificate in Early Childhood and Care (Level 4)" },
	{ value: "Diploma in Early Childhood Education (Level 5)", label: "Diploma in Early Childhood Education (Level 5)" },
	{ value: "Certificate in Carpentry Trade Skills (Level 3)", label: "Certificate in Carpentry Trade Skills (Level 3)" },
	{ value: "Certificate in Electrical Engineering (Level 3)", label: "Certificate in Electrical Engineering (Level 3)" },
	{ value: "Certificate in Plumbing, Gasfitting and Drainlaying (Pre-Apprenticeship)(Level 3)", label: "Certificate in Plumbing, Gasfitting and Drainlaying (Pre-Apprenticeship)(Level 3)" },
	{ value: "Certificate in Trade Skills with strand in Paint and Plaster (Level 3)", label: "Certificate in Trade Skills with strand in Paint and Plaster (Level 3)" },
	{ value: "Certificate in Baking (Generalist)(Level 4)", label: "Certificate in Baking (Generalist)(Level 4)" },
	{ value: "Certificate in Cookery (Level 4)", label: "Certificate in Cookery (Level 4)" },
	{ value: "Diploma in Cookery (Advanced) - Patisserie Strand (Level 5)", label: "Diploma in Cookery (Advanced) - Patisserie Strand (Level 5)" },
	{ value: "Diploma in Cookery (Advanced) - Cookery Strand (Level 5)", label: "Diploma in Cookery (Advanced) - Cookery Strand (Level 5)" },
	{ value: "Diploma in Culinary Management (Level 6)", label: "Diploma in Culinary Management (Level 6)" },
	{ value: "Health & Fitness Personal Trainer Level 4", label: "Health & Fitness Personal Trainer Level 4" },
	{ value: "Certificate in Early Childhood and Care (Level 4)", label: "Certificate in Early Childhood and Care (Level 4)" },
	{ value: "Certificate in Horticulture (Level 3)", label: "Certificate in Horticulture (Level 3)" },
	{ value: "Certificate in Landscape Design & Build (Level 3)", label: "Certificate in Landscape Design & Build (Level 3)" },
	{ value: "Certificate in Animal Care (Level 3)", label: "Certificate in Animal Care (Level 3)" },
	{ value: "Aged Care and Complex Needs (Level 4)", label: "Aged Care and Complex Needs (Level 4)" },
	{ value: "Social and Community Health (Level 4)", label: "Social and Community Health (Level 4)" },
	{ value: "Mental Health and Addiction (Level 4)", label: "Mental Health and Addiction (Level 4)" },
	{ value: "Leading Workplace Teams (Level 4)", label: "Leading Workplace Teams (Level 4)" },
	{ value: "Small Business Essentials (Level 4)", label: "Small Business Essentials (Level 4)" },
	{ value: "Certificate in Supporting Diverse Learners (Level 4)", label: "Certificate in Supporting Diverse Learners (Level 4)" },
	{ value: "Residential Construction Supervisor (Level 5)", label: "Residential Construction Supervisor (Level 5)" }
];

export default NzManagementAcadCourses;