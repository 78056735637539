const DublinBusinessSchoolCourses = [
	{ label: "BA (Hons) Accounting & Finance", value: "BA (Hons) Accounting & Finance" },
	{ label: "Bachelor of Business", value: "Bachelor of Business" },
	{ label: "BA (Hons) Social Science", value: "BA (Hons) Social Science" },
	{ label: "BA (Hons) Financial Services", value: "BA (Hons) Financial Services" },
	{ label: "BA (Hons) Accounting and Finance and 1 Year Part III ACCA", value: "BA (Hons) Accounting and Finance and 1 Year Part III ACCA" },
	{ label: "BA (Hons) Business", value: "BA (Hons) Business" },
	{ label: "BA (Hons) Business (HRM)", value: "BA (Hons) Business (HRM)" },
	{ label: "BA (Hons) Business (Law)", value: "BA (Hons) Business (Law)" },
	{ label: "BA (Hons) Business (Management)", value: "BA (Hons) Business (Management)" },
	{ label: "BA (Hons) Business (Project Management)", value: "BA (Hons) Business (Project Management)" },
	{ label: "BA (Hons) Business (Psychology)", value: "BA (Hons) Business (Psychology)" },
	{ label: "BA (Hons) Business (Information Systems)", value: "BA (Hons) Business (Information Systems)" },
	{ label: "BA (Hons) Marketing", value: "BA (Hons) Marketing" },
	{ label: "BA (Hons) Marketing (Digital Media & Cloud Computing)", value: "BA (Hons) Marketing (Digital Media & Cloud Computing)" },
	{ label: "BA (Hons) Marketing (Digital Media)", value: "BA (Hons) Marketing (Digital Media)" },
	{ label: "BA (Hons) Marketing (Event Management)", value: "BA (Hons) Marketing (Event Management)" },
	{ label: "Bachelor of Business in Accounting", value: "Bachelor of Business in Accounting" },
	{ label: "BSc (Hons) Computing (Data Analytics)", value: "BSc (Hons) Computing (Data Analytics)" },
	{ label: "BSc (Hons) Computing (Software Development)", value: "BSc (Hons) Computing (Software Development)" },
	{ label: "BA (Hons) Film and Creative Media", value: "BA (Hons) Film and Creative Media" },
	{ label: "BA (Hons) Audio Production & Music Project Management", value: "BA (Hons) Audio Production & Music Project Management" },
	{ label: "BA (Hons) in Business (Cloud Computing)", value: "BA (Hons) in Business (Cloud Computing)" },
	{ label: "BA (Hons) in Applied Social Care", value: "BA (Hons) in Applied Social Care" },
	{ label: "Bachelor of Arts in Applied Social Care", value: "Bachelor of Arts in Applied Social Care" },
	{ label: "BA (Hons) Psychology", value: "BA (Hons) Psychology" },
	{ label: "Bachelor of Business in Information Technology", value: "Bachelor of Business in Information Technology" },
	{ label: "Bachelor of Business in Marketing", value: "Bachelor of Business in Marketing" },
	{ label: "Bachelor of Laws (Hons) LL. B", value: "Bachelor of Laws (Hons) LL. B" },
	{ label: "BSc (Hons) Computing", value: "BSc (Hons) Computing" },
	{ label: "MSc Information & Library Management", value: "MSc Information & Library Management" },
	{ label: "MSc Human Resource Management", value: "MSc Human Resource Management" },
	{ label: "MSc Digital Marketing and Analytics", value: "MSc Digital Marketing and Analytics" },
	{ label: "MSc in Supply Chain Management", value: "MSc in Supply Chain Management" },
	{ label: "Higher Diploma in Business", value: "Higher Diploma in Business" },
	{ label: "MSc in Health Psychology", value: "MSc in Health Psychology" },
	{ label: "MSc Business Analytics", value: "MSc Business Analytics" },
	{ label: "MSc in Artificial Intelligence", value: "MSc in Artificial Intelligence" },
	{ label: "MSc in Cybersecurity", value: "MSc in Cybersecurity" },
	{ label: "MSc in Financial Analytics", value: "MSc in Financial Analytics" },
	{ label: "MA Addiction Studies", value: "MA Addiction Studies" },
	{ label: "MSc in Data Analytics", value: "MSc in Data Analytics" },
	{ label: "MSc Financial Technology", value: "MSc Financial Technology" },
	{ label: "MBA (Cloud Computing)", value: "MBA (Cloud Computing)" },
	{ label: "MBA (Finance)", value: "MBA (Finance)" },
	{ label: "Master of Business Administration (MBA)", value: "Master of Business Administration (MBA)" },
	{ label: "MBA (HRM)", value: "MBA (HRM)" },
	{ label: "MBA (Marketing)", value: "MBA (Marketing)" },
	{ label: "MBA (Project Management)", value: "MBA (Project Management)" },
	{ label: "MBA (Information Systems)", value: "MBA (Information Systems)" },
	{ label: "MSc Applied Psychology", value: "MSc Applied Psychology" },
	{ label: "MSc Information Systems with Computing", value: "MSc Information Systems with Computing" },
	{ label: "MSc Management Practice", value: "MSc Management Practice" },
	{ label: "MSc International Accounting & Finance", value: "MSc International Accounting & Finance" },
	{ label: "MSc Marketing", value: "MSc Marketing" },
	{ label: "Higher Diploma in Science in Aviation Finance", value: "Higher Diploma in Science in Aviation Finance" },
	{ label: "Higher Diploma in Science in Digital Marketing", value: "Higher Diploma in Science in Digital Marketing" },
	{ label: "Higher Diploma in Science in Data Analytics", value: "Higher Diploma in Science in Data Analytics" }
];

export default DublinBusinessSchoolCourses;