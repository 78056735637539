const ISMGerCourses = [
	{ value: "BSc International Management", label: "BSc International Management" },
	{ value: "BSc International Management(Global Track)", label: "BSc International Management(Global Track)" },
	{ value: "BSc Information Systems", label: "BSc Information Systems" },
	{ value: "BSc Information Systems(Global Track)", label: "BSc Information Systems(Global Track)" },
	{ value: "M.Sc. International Management", label: "M.Sc. International Management" },
	{ value: "M.A. Strategic Marketing Management", label: "M.A. Strategic Marketing Management" },
	{ value: "M.Sc. Finance", label: "M.Sc. Finance" },
	{ value: "M.Sc. Business Intelligence & Data Science", label: "M.Sc. Business Intelligence & Data Science" },
	{ value: "M.A. Luxury, Fashion & Sales Management", label: "M.A. Luxury, Fashion & Sales Management" },
	{ value: "M.Sc. International Business", label: "M.Sc. International Business" },
	{ value: "MBA in General Management", label: "MBA in General Management" },
	{ value: "M.Sc. International Management (ISM) + Master of Business in International Business (Bond University)", label: "M.Sc. International Management (ISM) + Master of Business in International Business (Bond University)" },
	{ value: "M.Sc. International Management (ISM) + Master of Business in Marketing (Bond University)", label: "M.Sc. International Management (ISM) + Master of Business in Marketing (Bond University)" },
	{ value: "M.Sc. International Management (ISM) + Master of Business Non-Specialization (Bond University)", label: "M.Sc. International Management (ISM) + Master of Business Non-Specialization (Bond University)" },
	{ value: "M.Sc. International Management (ISM) + Master in International Marketing in a Digital Environment (Universitat Ramon Llull)", label: "M.Sc. International Management (ISM) + Master in International Marketing in a Digital Environment (Universitat Ramon Llull)" },
	{ value: "M.Sc. International Management (ISM) + Master of International Business Management (University of the Sunshine Coast)", label: "M.Sc. International Management (ISM) + Master of International Business Management (University of the Sunshine Coast)" },
	{ value: "M.Sc. International Management (ISM) + Master of Management (University of the Sunshine Coast)", label: "M.Sc. International Management (ISM) + Master of Management (University of the Sunshine Coast)" },
	{ value: "M.Sc. International Management (ISM) + MSc Financial Management (Boston University)", label: "M.Sc. International Management (ISM) + MSc Financial Management (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Global Marketing Management (Boston University)", label: "M.Sc. International Management (ISM) + M.Sc. Global Marketing Management (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Administrative Studies Concentration in Innovation & Technology (Boston University)", label: "M.Sc. International Management (ISM) + M.Sc. Administrative Studies Concentration in Innovation & Technology (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Project Management (Boston University)", label: "M.Sc. International Management (ISM) + M.Sc. Project Management (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Enterprise Risk Management (Boston University)", label: "M.Sc. International Management (ISM) + M.Sc. Enterprise Risk Management (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Supply Chain Management (Boston University)", label: "M.Sc. International Management (ISM) + M.Sc. Supply Chain Management (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Applied Business Analytics (Boston University)", label: "M.Sc. International Management (ISM) + M.Sc. Applied Business Analytics (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Business Administration (ECUST)", label: "M.Sc. International Management (ISM) + M.Sc. Business Administration (ECUST)" },
	{ value: "M.Sc. International Management (ISM) + International Master of Business Administration (ECUST)", label: "M.Sc. International Management (ISM) + International Master of Business Administration (ECUST)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Management (Lancaster University)", label: "M.Sc. International Management (ISM) + M.Sc. Management (Lancaster University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. International Business & Strategy (Lancaster University)", label: "M.Sc. International Management (ISM) + M.Sc. International Business & Strategy (Lancaster University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Human Resource Management (Lancaster University)", label: "M.Sc. International Management (ISM) + M.Sc. Human Resource Management (Lancaster University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Information Technology, Management and Organisational Change (Lancaster University)", label: "M.Sc. International Management (ISM) + M.Sc. Information Technology, Management and Organisational Change (Lancaster University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Project Management (Lancaster University)", label: "M.Sc. International Management (ISM) + M.Sc. Project Management (Lancaster University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Management (Edinburgh Napier University)", label: "M.Sc. International Management (ISM) + M.Sc. Management (Edinburgh Napier University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. of International Business (Griffith College)", label: "M.Sc. International Management (ISM) + M.Sc. of International Business (Griffith College)" },
	{ value: "M.Sc. International Management (ISM) + Diplôme INSEEC/Grade de Master (INSEEC)", label: "M.Sc. International Management (ISM) + Diplôme INSEEC/Grade de Master (INSEEC)" },
	{ value: "M.Sc. Business Intelligence & Data Science (ISM) + M.Sc. Global Marketing Management (Boston University)", label: "M.Sc. Business Intelligence & Data Science (ISM) + M.Sc. Global Marketing Management (Boston University)" },
	{ value: "M.Sc. Business Intelligence & Data Science (ISM) + M.Sc. Project Management (Boston University)", label: "M.Sc. Business Intelligence & Data Science (ISM) + M.Sc. Project Management (Boston University)" },
	{ value: "M.Sc. Business Intelligence & Data Science (ISM) + M.Sc. Administrative Studies Concentration in Innovation & Technology (Boston University)", label: "M.Sc. Business Intelligence & Data Science (ISM) + M.Sc. Administrative Studies Concentration in Innovation & Technology (Boston University)" },
	{ value: "M.Sc. Business Intelligence & Data Science (ISM) + M.Sc. Supply Chain Management (Boston University)", label: "M.Sc. Business Intelligence & Data Science (ISM) + M.Sc. Supply Chain Management (Boston University)" },
	{ value: "M.Sc. Business Intelligence & Data Science (ISM) + M.Sc. Applied Business Analytics (Boston University)", label: "M.Sc. Business Intelligence & Data Science (ISM) + M.Sc. Applied Business Analytics (Boston University)" },
	{ value: "M.A. Luxury, Fashion & Sales Management + M.Sc Management (Edinburgh Napier University)", label: "M.A. Luxury, Fashion & Sales Management + M.Sc Management (Edinburgh Napier University)" },
	{ value: "M.A. Strategic Marketing Management (ISM) + Master in International Marketing in a Digital Environment (Universitat Ramon Llull)", label: "M.A. Strategic Marketing Management (ISM) + Master in International Marketing in a Digital Environment (Universitat Ramon Llull)" },
	{ value: "M.A Strategic Marketing Management (ISM) + Master of Business in Marketing (Bond University)", label: "M.A Strategic Marketing Management (ISM) + Master of Business in Marketing (Bond University)" },
	{ value: "M.A. Strategic Marketing Management (ISM) + Master of Business Non-Specialization (Bond University)", label: "M.A. Strategic Marketing Management (ISM) + Master of Business Non-Specialization (Bond University)" },
	{ value: "M.A. Strategic Marketing Management (ISM) + Master of Management (University of the Sunshine Coast)", label: "M.A. Strategic Marketing Management (ISM) + Master of Management (University of the Sunshine Coast)" },
	{ value: "M.A. Strategic Marketing Management (ISM) + M.Sc. Financial Management (Boston University)", label: "M.A. Strategic Marketing Management (ISM) + M.Sc. Financial Management (Boston University)" },
	{ value: "M.A. Strategic Marketing Management (ISM) + M.Sc. Administrative Studies Concentration in Innovation & Technology (Boston University)", label: "M.A. Strategic Marketing Management (ISM) + M.Sc. Administrative Studies Concentration in Innovation & Technology (Boston University)" },
	{ value: "M.A. Strategic Marketing Management (ISM) + M.Sc. Global Marketing Management (Boston University)", label: "M.A. Strategic Marketing Management (ISM) + M.Sc. Global Marketing Management (Boston University)" },
	{ value: "M.A. Strategic Marketing Management (ISM) + M.Sc. Project Management (Boston University)", label: "M.A. Strategic Marketing Management (ISM) + M.Sc. Project Management (Boston University)" },
	{ value: "M.A. Strategic Marketing Management (ISM) + M.Sc. Human Resource Management (Lancaster University)", label: "M.A. Strategic Marketing Management (ISM) + M.Sc. Human Resource Management (Lancaster University)" },
	{ value: "M.A. Strategic Marketing Management (ISM) + M.Sc. Information Technology, Management and Organisational Change (Lancaster University)", label: "M.A. Strategic Marketing Management (ISM) + M.Sc. Information Technology, Management and Organisational Change (Lancaster University)" },
	{ value: "M.A. Strategic Marketing Management (ISM) + M.Sc. Management (Edinburgh Napier University)", label: "M.A. Strategic Marketing Management (ISM) + M.Sc. Management (Edinburgh Napier University)" },
	{ value: "M.A. Strategic Marketing Management (ISM) + Diplôme INSEEC/Grade de Master (INSEEC)", label: "M.A. Strategic Marketing Management (ISM) + Diplôme INSEEC/Grade de Master (INSEEC)" },
	{ value: "M.Sc. Finance (ISM) + Master of Business majoring in Finance (Bond University)", label: "M.Sc. Finance (ISM) + Master of Business majoring in Finance (Bond University)" },
	{ value: "M.Sc. Finance (ISM) + Master of Finance (Bond University)", label: "M.Sc. Finance (ISM) + Master of Finance (Bond University)" },
	{ value: "M.Sc. Finance (ISM) + M.Sc. Financial Management (Boston University)", label: "M.Sc. Finance (ISM) + M.Sc. Financial Management (Boston University)" },
	{ value: "M.Sc. Finance (ISM) + M.Sc. Global Marketing Management (Boston University)", label: "M.Sc. Finance (ISM) + M.Sc. Global Marketing Management (Boston University)" },
	{ value: "M.Sc. Finance (ISM) + M.Sc. Administrative Studies Concentration in Innovation & Technology (Boston University)", label: "M.Sc. Finance (ISM) + M.Sc. Administrative Studies Concentration in Innovation & Technology (Boston University)" },
	{ value: "M.Sc. Finance (ISM) + M.Sc. Project Management (Boston University)", label: "M.Sc. Finance (ISM) + M.Sc. Project Management (Boston University)" },
	{ value: "M.Sc. Finance (ISM) + M.Sc. Enterprise Risk Management (Boston University)", label: "M.Sc. Finance (ISM) + M.Sc. Enterprise Risk Management (Boston University)" },
	{ value: "M.Sc. Finance (ISM) + M.Sc. Supply Chain Management (Boston University)", label: "M.Sc. Finance (ISM) + M.Sc. Supply Chain Management (Boston University)" },
	{ value: "M.Sc. Finance (ISM) + M.Sc. Applied Business Analytics (Boston University)", label: "M.Sc. Finance (ISM) + M.Sc. Applied Business Analytics (Boston University)" },
	{ value: "M.Sc. Finance (ISM) + M.Sc. Business Administration (ECUST)", label: "M.Sc. Finance (ISM) + M.Sc. Business Administration (ECUST)" },
	{ value: "M.Sc. Finance (ISM) + International Master of Business Administration (ECUST)", label: "M.Sc. Finance (ISM) + International Master of Business Administration (ECUST)" },
	{ value: "M.Sc. Finance (ISM) + M.Sc. Finance (Lancaster University)", label: "M.Sc. Finance (ISM) + M.Sc. Finance (Lancaster University)" },
	{ value: "M.Sc. Finance (ISM) + M.Sc. Accounting and Financial Management (Lancaster University)", label: "M.Sc. Finance (ISM) + M.Sc. Accounting and Financial Management (Lancaster University)" },
	{ value: "M.Sc. Finance (ISM) + M.Sc. Money, Banking and Finance (Lancaster University)", label: "M.Sc. Finance (ISM) + M.Sc. Money, Banking and Finance (Lancaster University)" },
	{ value: "M.Sc. Finance (ISM) + M.Sc. Quantitative Finance (Lancaster University)", label: "M.Sc. Finance (ISM) + M.Sc. Quantitative Finance (Lancaster University)" },
	{ value: "M.Sc. Finance (ISM) + M.Sc. Advanced Financial Analysis (Lancaster University)", label: "M.Sc. Finance (ISM) + M.Sc. Advanced Financial Analysis (Lancaster University)" },
	{ value: "M.Sc. Finance (ISM) + MSc in Management (Edinburgh Napier University)", label: "M.Sc. Finance (ISM) + MSc in Management (Edinburgh Napier University)" },
	{ value: "M.Sc. Finance (ISM) + Diplôme INSEEC/Grade de Master (INSEEC)", label: "M.Sc. Finance (ISM) + Diplôme INSEEC/Grade de Master (INSEEC)" },
	{ value: "Pre Master in Master of Arts", label: "Pre Master in Master of Arts" },
	{ value: "Pre Master in Master of Science", label: "Pre Master in Master of Science" },
	{ value: "BSc International Management(Global Track)", label: "BSc International Management(Global Track)" },
	{ value: "BSc Information Systems", label: "BSc Information Systems" },
	{ value: "BSc Information Systems(Global Track)", label: "BSc Information Systems(Global Track)" },
	{ value: "M.Sc. International Management", label: "M.Sc. International Management" },
	{ value: "M.Sc. Business Intelligence & Data Science", label: "M.Sc. Business Intelligence & Data Science" },
	{ value: "M.Sc. International Logistics & Supply Chain Management", label: "M.Sc. International Logistics & Supply Chain Management" },
	{ value: "M.Sc. International Management (ISM) + Master of Business in International Business (Bond University)", label: "M.Sc. International Management (ISM) + Master of Business in International Business (Bond University)" },
	{ value: "M.Sc. International Management (ISM) + Master of Business in Marketing (Bond University)", label: "M.Sc. International Management (ISM) + Master of Business in Marketing (Bond University)" },
	{ value: "M.Sc. International Management (ISM) + Master of Business Non-Specialization (Bond University)", label: "M.Sc. International Management (ISM) + Master of Business Non-Specialization (Bond University)" },
	{ value: "M.Sc. International Management (ISM) + Master in International Marketing in a Digital Environment (Universitat Ramon Llull)", label: "M.Sc. International Management (ISM) + Master in International Marketing in a Digital Environment (Universitat Ramon Llull)" },
	{ value: "M.Sc. International Management (ISM) + Master of International Business Management (University of the Sunshine Coast)", label: "M.Sc. International Management (ISM) + Master of International Business Management (University of the Sunshine Coast)" },
	{ value: "M.Sc. International Management (ISM) + Master of Management (University of the Sunshine Coast)", label: "M.Sc. International Management (ISM) + Master of Management (University of the Sunshine Coast)" },
	{ value: "M.Sc. International Management (ISM) + MSc Financial Management (Boston University)", label: "M.Sc. International Management (ISM) + MSc Financial Management (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Global Marketing Management (Boston University)", label: "M.Sc. International Management (ISM) + M.Sc. Global Marketing Management (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Administrative Studies Concentration in Innovation & Technology (Boston University)", label: "M.Sc. International Management (ISM) + M.Sc. Administrative Studies Concentration in Innovation & Technology (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Project Management (Boston University)", label: "M.Sc. International Management (ISM) + M.Sc. Project Management (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Enterprise Risk Management (Boston University)", label: "M.Sc. International Management (ISM) + M.Sc. Enterprise Risk Management (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Supply Chain Management (Boston University)", label: "M.Sc. International Management (ISM) + M.Sc. Supply Chain Management (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Applied Business Analytics (Boston University)", label: "M.Sc. International Management (ISM) + M.Sc. Applied Business Analytics (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Business Administration (ECUST)", label: "M.Sc. International Management (ISM) + M.Sc. Business Administration (ECUST)" },
	{ value: "M.Sc. International Management (ISM) + International Master of Business Administration (ECUST)", label: "M.Sc. International Management (ISM) + International Master of Business Administration (ECUST)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Management (Lancaster University)", label: "M.Sc. International Management (ISM) + M.Sc. Management (Lancaster University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. International Business & Strategy (Lancaster University)", label: "M.Sc. International Management (ISM) + M.Sc. International Business & Strategy (Lancaster University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Human Resource Management (Lancaster University)", label: "M.Sc. International Management (ISM) + M.Sc. Human Resource Management (Lancaster University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Information Technology, Management and Organisational Change (Lancaster University)", label: "M.Sc. International Management (ISM) + M.Sc. Information Technology, Management and Organisational Change (Lancaster University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Project Management (Lancaster University)", label: "M.Sc. International Management (ISM) + M.Sc. Project Management (Lancaster University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Management (Edinburgh Napier University)", label: "M.Sc. International Management (ISM) + M.Sc. Management (Edinburgh Napier University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. of International Business (Griffith College)", label: "M.Sc. International Management (ISM) + M.Sc. of International Business (Griffith College)" },
	{ value: "M.Sc. International Management (ISM) + Diplôme INSEEC/Grade de Master (INSEEC)", label: "M.Sc. International Management (ISM) + Diplôme INSEEC/Grade de Master (INSEEC)" },
	{ value: "M.Sc. Business Intelligence & Data Science (ISM) + M.Sc. Global Marketing Management (Boston University)", label: "M.Sc. Business Intelligence & Data Science (ISM) + M.Sc. Global Marketing Management (Boston University)" },
	{ value: "M.Sc. Business Intelligence & Data Science (ISM) + M.Sc. Project Management (Boston University)", label: "M.Sc. Business Intelligence & Data Science (ISM) + M.Sc. Project Management (Boston University)" },
	{ value: "M.Sc. Business Intelligence & Data Science (ISM) + M.Sc. Administrative Studies Concentration in Innovation & Technology (Boston University)", label: "M.Sc. Business Intelligence & Data Science (ISM) + M.Sc. Administrative Studies Concentration in Innovation & Technology (Boston University)" },
	{ value: "M.Sc. Business Intelligence & Data Science (ISM) + M.Sc. Supply Chain Management (Boston University)", label: "M.Sc. Business Intelligence & Data Science (ISM) + M.Sc. Supply Chain Management (Boston University)" },
	{ value: "M.Sc. Business Intelligence & Data Science (ISM) + M.Sc. Applied Business Analytics (Boston University)", label: "M.Sc. Business Intelligence & Data Science (ISM) + M.Sc. Applied Business Analytics (Boston University)" },
	{ value: "M.Sc. International Logistics & Supply Chain Management (ISM) + Master of Management (University of the Sunshine Coast)", label: "M.Sc. International Logistics & Supply Chain Management (ISM) + Master of Management (University of the Sunshine Coast)" },
	{ value: "M.Sc. International Logistics & Supply Chain Management (ISM) + Master of International Business Management (University of the Sunshine Coast)", label: "M.Sc. International Logistics & Supply Chain Management (ISM) + Master of International Business Management (University of the Sunshine Coast)" },
	{ value: "M.Sc. International Logistics & Supply Chain Management (ISM) + Master in Management (M2) in Supply Chain Management (EM Normandie)", label: "M.Sc. International Logistics & Supply Chain Management (ISM) + Master in Management (M2) in Supply Chain Management (EM Normandie)" },
	{ value: "M.Sc. International Logistics & Supply Chain Management (ISM) + Master in Management (M2) in International Logistics & Crisis Management (EM Normandie)", label: "M.Sc. International Logistics & Supply Chain Management (ISM) + Master in Management (M2) in International Logistics & Crisis Management (EM Normandie)" },
	{ value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Financial Management (Boston University)", label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Financial Management (Boston University)" },
	{ value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Global Marketing Management (Boston University)", label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Global Marketing Management (Boston University)" },
	{ value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Administrative Studies Concentration in Innovation and Technology (Boston University)", label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Administrative Studies Concentration in Innovation and Technology (Boston University)" },
	{ value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Project Management (Boston University)", label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Project Management (Boston University)" },
	{ value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Enterprise Risk Management (Boston University)", label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Enterprise Risk Management (Boston University)" },
	{ value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Supply Chain Management (Boston University)", label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Supply Chain Management (Boston University)" },
	{ value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Applied Business Analytics (Boston University)", label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Applied Business Analytics (Boston University)" },
	{ value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Logistics & Supply Chain Management (Lancaster University)", label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Logistics & Supply Chain Management (Lancaster University)" },
	{ value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Project Management (Lancaster University)", label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Project Management (Lancaster University)" },
	{ value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Marketing Analytics (Lancaster University)", label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Marketing Analytics (Lancaster University)" },
	{ value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Management Science (Lancaster University)", label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Management Science (Lancaster University)" },
	{ value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Management (Edinburgh Napier University)", label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Management (Edinburgh Napier University)" },
	{ value: "BSc International Management", label: "BSc International Management" },
	{ value: "BSc International Management(Global Track)", label: "BSc International Management(Global Track)" },
	{ value: "M.Sc. International Management", label: "M.Sc. International Management" },
	{ value: "M.Sc. International Management (ISM) + Master of Business in International Business (Bond University)", label: "M.Sc. International Management (ISM) + Master of Business in International Business (Bond University)" },
	{ value: "M.Sc. International Management (ISM) + Master of Business in Marketing (Bond University)", label: "M.Sc. International Management (ISM) + Master of Business in Marketing (Bond University)" },
	{ value: "M.Sc. International Management (ISM) + Master of Business Non-Specialization (Bond University)", label: "M.Sc. International Management (ISM) + Master of Business Non-Specialization (Bond University)" },
	{ value: "M.Sc. International Management (ISM) + Master in International Marketing in a Digital Environment (Universitat Ramon Llull)", label: "M.Sc. International Management (ISM) + Master in International Marketing in a Digital Environment (Universitat Ramon Llull)" },
	{ value: "M.Sc. International Management (ISM) + Master of International Business Management (University of the Sunshine Coast)", label: "M.Sc. International Management (ISM) + Master of International Business Management (University of the Sunshine Coast)" },
	{ value: "M.Sc. International Management (ISM) + Master of Management (University of the Sunshine Coast)", label: "M.Sc. International Management (ISM) + Master of Management (University of the Sunshine Coast)" },
	{ value: "M.Sc. International Management (ISM) + MSc Financial Management (Boston University)", label: "M.Sc. International Management (ISM) + MSc Financial Management (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Global Marketing Management (Boston University)", label: "M.Sc. International Management (ISM) + M.Sc. Global Marketing Management (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Administrative Studies Concentration in Innovation & Technology (Boston University)", label: "M.Sc. International Management (ISM) + M.Sc. Administrative Studies Concentration in Innovation & Technology (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Project Management (Boston University)", label: "M.Sc. International Management (ISM) + M.Sc. Project Management (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Enterprise Risk Management (Boston University)", label: "M.Sc. International Management (ISM) + M.Sc. Enterprise Risk Management (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Supply Chain Management (Boston University)", label: "M.Sc. International Management (ISM) + M.Sc. Supply Chain Management (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Applied Business Analytics (Boston University)", label: "M.Sc. International Management (ISM) + M.Sc. Applied Business Analytics (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Business Administration (ECUST)", label: "M.Sc. International Management (ISM) + M.Sc. Business Administration (ECUST)" },
	{ value: "M.Sc. International Management (ISM) + International Master of Business Administration (ECUST)", label: "M.Sc. International Management (ISM) + International Master of Business Administration (ECUST)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Management (Lancaster University)", label: "M.Sc. International Management (ISM) + M.Sc. Management (Lancaster University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. International Business & Strategy (Lancaster University)", label: "M.Sc. International Management (ISM) + M.Sc. International Business & Strategy (Lancaster University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Human Resource Management (Lancaster University)", label: "M.Sc. International Management (ISM) + M.Sc. Human Resource Management (Lancaster University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Information Technology, Management and Organisational Change (Lancaster University)", label: "M.Sc. International Management (ISM) + M.Sc. Information Technology, Management and Organisational Change (Lancaster University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Project Management (Lancaster University)", label: "M.Sc. International Management (ISM) + M.Sc. Project Management (Lancaster University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Management (Edinburgh Napier University)", label: "M.Sc. International Management (ISM) + M.Sc. Management (Edinburgh Napier University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. of International Business (Griffith College)", label: "M.Sc. International Management (ISM) + M.Sc. of International Business (Griffith College)" },
	{ value: "M.Sc. International Management (ISM) + Diplôme INSEEC/Grade de Master (INSEEC)", label: "M.Sc. International Management (ISM) + Diplôme INSEEC/Grade de Master (INSEEC)" },
	{ value: "BSc International Management", label: "BSc International Management" },
	{ value: "BSc International Management(Global Track)", label: "BSc International Management(Global Track)" },
	{ value: "BSc Finance & Management", label: "BSc Finance & Management" },
	{ value: "BSc Finance & Management (Global Track)", label: "BSc Finance & Management (Global Track)" },
	{ value: "M.Sc. Finance", label: "M.Sc. Finance" },
	{ value: "M.A. Luxury, Fashion & Sales Management", label: "M.A. Luxury, Fashion & Sales Management" },
	{ value: "M.Sc. International Business", label: "M.Sc. International Business" },
	{ value: "M.A. Luxury, Fashion & Sales Management + M.Sc Management (Edinburgh Napier University)", label: "M.A. Luxury, Fashion & Sales Management + M.Sc Management (Edinburgh Napier University)" },
	{ value: "M.Sc. Finance (ISM) + Master of Business majoring in Finance (Bond University)", label: "M.Sc. Finance (ISM) + Master of Business majoring in Finance (Bond University)" },
	{ value: "M.Sc. Finance (ISM) + Master of Finance (Bond University)", label: "M.Sc. Finance (ISM) + Master of Finance (Bond University)" },
	{ value: "M.Sc. Finance (ISM) + M.Sc. Supply Chain Management (Boston University)", label: "M.Sc. Finance (ISM) + M.Sc. Supply Chain Management (Boston University)" },
	{ value: "M.Sc. Finance (ISM) + M.Sc. Applied Business Analytics (Boston University)", label: "M.Sc. Finance (ISM) + M.Sc. Applied Business Analytics (Boston University)" },
	{ value: "M.Sc. Finance (ISM) + M.Sc. Financial Management (Boston University)", label: "M.Sc. Finance (ISM) + M.Sc. Financial Management (Boston University)" },
	{ value: "M.Sc. Finance (ISM) + M.Sc. Global Marketing Management (Boston University)", label: "M.Sc. Finance (ISM) + M.Sc. Global Marketing Management (Boston University)" },
	{ value: "M.Sc. Finance (ISM) + M.Sc. Administrative Studies Concentration in Innovation & Technology (Boston University)", label: "M.Sc. Finance (ISM) + M.Sc. Administrative Studies Concentration in Innovation & Technology (Boston University)" },
	{ value: "M.Sc. Finance (ISM) + M.Sc. Project Management (Boston University)", label: "M.Sc. Finance (ISM) + M.Sc. Project Management (Boston University)" },
	{ value: "M.Sc. Finance (ISM) + M.Sc. Enterprise Risk Management (Boston University)", label: "M.Sc. Finance (ISM) + M.Sc. Enterprise Risk Management (Boston University)" },
	{ value: "M.Sc. Finance (ISM) + M.Sc. Business Administration (ECUST)", label: "M.Sc. Finance (ISM) + M.Sc. Business Administration (ECUST)" },
	{ value: "M.Sc. Finance (ISM) + International Master of Business Administration (ECUST)", label: "M.Sc. Finance (ISM) + International Master of Business Administration (ECUST)" },
	{ value: "M.Sc. Finance (ISM) + M.Sc. Finance (Lancaster University)", label: "M.Sc. Finance (ISM) + M.Sc. Finance (Lancaster University)" },
	{ value: "M.Sc. Finance (ISM) + M.Sc. Accounting and Financial Management (Lancaster University)", label: "M.Sc. Finance (ISM) + M.Sc. Accounting and Financial Management (Lancaster University)" },
	{ value: "M.Sc. Finance (ISM) + M.Sc. Money, Banking and Finance (Lancaster University)", label: "M.Sc. Finance (ISM) + M.Sc. Money, Banking and Finance (Lancaster University)" },
	{ value: "M.Sc. Finance (ISM) + M.Sc. Quantitative Finance (Lancaster University)", label: "M.Sc. Finance (ISM) + M.Sc. Quantitative Finance (Lancaster University)" },
	{ value: "M.Sc. Finance (ISM) + M.Sc. Advanced Financial Analysis (Lancaster University)", label: "M.Sc. Finance (ISM) + M.Sc. Advanced Financial Analysis (Lancaster University)" },
	{ value: "M.Sc. Finance (ISM) + MSc in Management (Edinburgh Napier University)", label: "M.Sc. Finance (ISM) + MSc in Management (Edinburgh Napier University)" },
	{ value: "M.Sc. Finance (ISM) + Diplôme INSEEC/Grade de Master (INSEEC)", label: "M.Sc. Finance (ISM) + Diplôme INSEEC/Grade de Master (INSEEC)" },
	{ value: "Pre Master in Master of Arts", label: "Pre Master in Master of Arts" },
	{ value: "Pre Master in Master of Science", label: "Pre Master in Master of Science" },
	{ value: "BSc International Management", label: "BSc International Management" },
	{ value: "BSc International Management(Global Track)", label: "BSc International Management(Global Track)" },
	{ value: "BSc Finance & Management", label: "BSc Finance & Management" },
	{ value: "BSc Finance & Management (Global Track)", label: "BSc Finance & Management (Global Track)" },
	{ value: "M.Sc. International Management", label: "M.Sc. International Management" },
	{ value: "M.Sc. International Management (ISM) + Master of Business in International Business (Bond University)", label: "M.Sc. International Management (ISM) + Master of Business in International Business (Bond University)" },
	{ value: "M.Sc. International Management (ISM) + Master of Business in Marketing (Bond University)", label: "M.Sc. International Management (ISM) + Master of Business in Marketing (Bond University)" },
	{ value: "M.Sc. International Management (ISM) + Master of Business Non-Specialization (Bond University)", label: "M.Sc. International Management (ISM) + Master of Business Non-Specialization (Bond University)" },
	{ value: "M.Sc. International Management (ISM) + Master in International Marketing in a Digital Environment (Universitat Ramon Llull)", label: "M.Sc. International Management (ISM) + Master in International Marketing in a Digital Environment (Universitat Ramon Llull)" },
	{ value: "M.Sc. International Management (ISM) + Master of International Business Management (University of the Sunshine Coast)", label: "M.Sc. International Management (ISM) + Master of International Business Management (University of the Sunshine Coast)" },
	{ value: "M.Sc. International Management (ISM) + Master of Management (University of the Sunshine Coast)", label: "M.Sc. International Management (ISM) + Master of Management (University of the Sunshine Coast)" },
	{ value: "M.Sc. International Management (ISM) + MSc Financial Management (Boston University)", label: "M.Sc. International Management (ISM) + MSc Financial Management (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Global Marketing Management (Boston University)", label: "M.Sc. International Management (ISM) + M.Sc. Global Marketing Management (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Administrative Studies Concentration in Innovation & Technology (Boston University)", label: "M.Sc. International Management (ISM) + M.Sc. Administrative Studies Concentration in Innovation & Technology (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Project Management (Boston University)", label: "M.Sc. International Management (ISM) + M.Sc. Project Management (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Enterprise Risk Management (Boston University)", label: "M.Sc. International Management (ISM) + M.Sc. Enterprise Risk Management (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Supply Chain Management (Boston University)", label: "M.Sc. International Management (ISM) + M.Sc. Supply Chain Management (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Applied Business Analytics (Boston University)", label: "M.Sc. International Management (ISM) + M.Sc. Applied Business Analytics (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Business Administration (ECUST)", label: "M.Sc. International Management (ISM) + M.Sc. Business Administration (ECUST)" },
	{ value: "M.Sc. International Management (ISM) + International Master of Business Administration (ECUST)", label: "M.Sc. International Management (ISM) + International Master of Business Administration (ECUST)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Management (Lancaster University)", label: "M.Sc. International Management (ISM) + M.Sc. Management (Lancaster University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. International Business & Strategy (Lancaster University)", label: "M.Sc. International Management (ISM) + M.Sc. International Business & Strategy (Lancaster University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Human Resource Management (Lancaster University)", label: "M.Sc. International Management (ISM) + M.Sc. Human Resource Management (Lancaster University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Information Technology, Management and Organisational Change (Lancaster University)", label: "M.Sc. International Management (ISM) + M.Sc. Information Technology, Management and Organisational Change (Lancaster University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Project Management (Lancaster University)", label: "M.Sc. International Management (ISM) + M.Sc. Project Management (Lancaster University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Management (Edinburgh Napier University)", label: "M.Sc. International Management (ISM) + M.Sc. Management (Edinburgh Napier University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. of International Business (Griffith College)", label: "M.Sc. International Management (ISM) + M.Sc. of International Business (Griffith College)" },
	{ value: "M.Sc. International Management (ISM) + Diplôme INSEEC/Grade de Master (INSEEC)", label: "M.Sc. International Management (ISM) + Diplôme INSEEC/Grade de Master (INSEEC)" },
	{ value: "BSc International Management", label: "BSc International Management" },
	{ value: "BSc International Management(Global Track)", label: "BSc International Management(Global Track)" },
	{ value: "Qualifier Program - Bachelor of International Management", label: "Qualifier Program - Bachelor of International Management" },
	{ value: "BSc Information Systems", label: "BSc Information Systems" },
	{ value: "BSc Information Systems(Global Track)", label: "BSc Information Systems(Global Track)" },
	{ value: "M.Sc. International Management", label: "M.Sc. International Management" },
	{ value: "M.Sc. Finance", label: "M.Sc. Finance" },
	{ value: "M.Sc. Business Intelligence & Data Science", label: "M.Sc. Business Intelligence & Data Science" },
	{ value: "M.Sc. International Business", label: "M.Sc. International Business" },
	{ value: "Pre Master in Master of Arts", label: "Pre Master in Master of Arts" },
	{ value: "Pre Master in Master of Science", label: "Pre Master in Master of Science" },
	{ value: "M.Sc. International Management (ISM) + Master of Business in International Business (Bond University)", label: "M.Sc. International Management (ISM) + Master of Business in International Business (Bond University)" },
	{ value: "M.Sc. International Management (ISM) + Master of Business in Marketing (Bond University)", label: "M.Sc. International Management (ISM) + Master of Business in Marketing (Bond University)" },
	{ value: "M.Sc. International Management (ISM) + Master of Business Non-Specialization (Bond University)", label: "M.Sc. International Management (ISM) + Master of Business Non-Specialization (Bond University)" },
	{ value: "M.Sc. International Management (ISM) + Master in International Marketing in a Digital Environment (Universitat Ramon Llull)", label: "M.Sc. International Management (ISM) + Master in International Marketing in a Digital Environment (Universitat Ramon Llull)" },
	{ value: "M.Sc. International Management (ISM) + Master of International Business Management (University of the Sunshine Coast)", label: "M.Sc. International Management (ISM) + Master of International Business Management (University of the Sunshine Coast)" },
	{ value: "M.Sc. International Management (ISM) + Master of Management (University of the Sunshine Coast)", label: "M.Sc. International Management (ISM) + Master of Management (University of the Sunshine Coast)" },
	{ value: "M.Sc. International Management (ISM) + MSc Financial Management (Boston University)", label: "M.Sc. International Management (ISM) + MSc Financial Management (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Global Marketing Management (Boston University)", label: "M.Sc. International Management (ISM) + M.Sc. Global Marketing Management (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Administrative Studies Concentration in Innovation & Technology (Boston University)", label: "M.Sc. International Management (ISM) + M.Sc. Administrative Studies Concentration in Innovation & Technology (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Project Management (Boston University)", label: "M.Sc. International Management (ISM) + M.Sc. Project Management (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Enterprise Risk Management (Boston University)", label: "M.Sc. International Management (ISM) + M.Sc. Enterprise Risk Management (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Supply Chain Management (Boston University)", label: "M.Sc. International Management (ISM) + M.Sc. Supply Chain Management (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Applied Business Analytics (Boston University)", label: "M.Sc. International Management (ISM) + M.Sc. Applied Business Analytics (Boston University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Business Administration (ECUST)", label: "M.Sc. International Management (ISM) + M.Sc. Business Administration (ECUST)" },
	{ value: "M.Sc. International Management (ISM) + International Master of Business Administration (ECUST)", label: "M.Sc. International Management (ISM) + International Master of Business Administration (ECUST)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Management (Lancaster University)", label: "M.Sc. International Management (ISM) + M.Sc. Management (Lancaster University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. International Business & Strategy (Lancaster University)", label: "M.Sc. International Management (ISM) + M.Sc. International Business & Strategy (Lancaster University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Human Resource Management (Lancaster University)", label: "M.Sc. International Management (ISM) + M.Sc. Human Resource Management (Lancaster University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Information Technology, Management and Organisational Change (Lancaster University)", label: "M.Sc. International Management (ISM) + M.Sc. Information Technology, Management and Organisational Change (Lancaster University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Project Management (Lancaster University)", label: "M.Sc. International Management (ISM) + M.Sc. Project Management (Lancaster University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. Management (Edinburgh Napier University)", label: "M.Sc. International Management (ISM) + M.Sc. Management (Edinburgh Napier University)" },
	{ value: "M.Sc. International Management (ISM) + M.Sc. of International Business (Griffith College)", label: "M.Sc. International Management (ISM) + M.Sc. of International Business (Griffith College)" },
	{ value: "M.Sc. International Management (ISM) + Diplôme INSEEC/Grade de Master (INSEEC)", label: "M.Sc. International Management (ISM) + Diplôme INSEEC/Grade de Master (INSEEC)" },
	{ value: "M.A. Strategic Marketing Management (ISM) + Master in International Marketing in a Digital Environment (Universitat Ramon Llull)", label: "M.A. Strategic Marketing Management (ISM) + Master in International Marketing in a Digital Environment (Universitat Ramon Llull)" },
	{ value: "M.A. Strategic Marketing Management (ISM) + Master of Business in Marketing (Bond University)", label: "M.A. Strategic Marketing Management (ISM) + Master of Business in Marketing (Bond University)" },
	{ value: "M.A. Strategic Marketing Management (ISM) + Master of Business Non-Specialization (Bond University)", label: "M.A. Strategic Marketing Management (ISM) + Master of Business Non-Specialization (Bond University)" },
	{ value: "M.A. Strategic Marketing Management (ISM) + Master of Management (University of the Sunshine Coast)", label: "M.A. Strategic Marketing Management (ISM) + Master of Management (University of the Sunshine Coast)" },
	{ value: "M.A. Strategic Marketing Management (ISM) + M.Sc. Financial Management (Boston University)", label: "M.A. Strategic Marketing Management (ISM) + M.Sc. Financial Management (Boston University)" },
	{ value: "M.A. Strategic Marketing Management (ISM) + M.Sc. Administrative Studies Concentration in Innovation & Technology (Boston University)", label: "M.A. Strategic Marketing Management (ISM) + M.Sc. Administrative Studies Concentration in Innovation & Technology (Boston University)" },
	{ value: "M.A. Strategic Marketing Management (ISM) + M.Sc. Global Marketing Management (Boston University)", label: "M.A. Strategic Marketing Management (ISM) + M.Sc. Global Marketing Management (Boston University)" },
	{ value: "M.A. Strategic Marketing Management (ISM) + M.Sc. Project Management (Boston University)", label: "M.A. Strategic Marketing Management (ISM) + M.Sc. Project Management (Boston University)" },
	{ value: "M.A. Strategic Marketing Management (ISM) + M.Sc. Human Resource Management (Lancaster University)", label: "M.A. Strategic Marketing Management (ISM) + M.Sc. Human Resource Management (Lancaster University)" },
	{ value: "M.A. Strategic Marketing Management (ISM) + M.Sc. Information Technology, Management and Organisational Change (Lancaster University)", label: "M.A. Strategic Marketing Management (ISM) + M.Sc. Information Technology, Management and Organisational Change (Lancaster University)" },
	{ value: "M.A. Strategic Marketing Management (ISM) + M.Sc. Management (Edinburgh Napier University)", label: "M.A. Strategic Marketing Management (ISM) + M.Sc. Management (Edinburgh Napier University)" },
	{ value: "M.A. Strategic Marketing Management (ISM) + Diplôme INSEEC/Grade de Master (INSEEC)", label: "M.A. Strategic Marketing Management (ISM) + Diplôme INSEEC/Grade de Master (INSEEC)" },
	{ value: "M.Sc. Business Intelligence & Data Science (ISM) + M.Sc. Global Marketing Management (Boston University)", label: "M.Sc. Business Intelligence & Data Science (ISM) + M.Sc. Global Marketing Management (Boston University)" },
	{ value: "M.Sc. Business Intelligence & Data Science (ISM) + M.Sc. Project Management (Boston University)", label: "M.Sc. Business Intelligence & Data Science (ISM) + M.Sc. Project Management (Boston University)" },
	{ value: "M.Sc. Business Intelligence & Data Science (ISM) + M.Sc. Administrative Studies Concentration in Innovation & Technology (Boston University)", label: "M.Sc. Business Intelligence & Data Science (ISM) + M.Sc. Administrative Studies Concentration in Innovation & Technology (Boston University)" },
	{ value: "M.Sc. Business Intelligence & Data Science (ISM) + M.Sc. Supply Chain Management (Boston University)", label: "M.Sc. Business Intelligence & Data Science (ISM) + M.Sc. Supply Chain Management (Boston University)" },
	{ value: "M.Sc. Business Intelligence & Data Science (ISM) + M.Sc. Applied Business Analytics (Boston University)", label: "M.Sc. Business Intelligence & Data Science (ISM) + M.Sc. Applied Business Analytics (Boston University)" },
	{ value: "M.Sc. International Logistics & Supply Chain Management (ISM) + Master of Management (University of the Sunshine Coast)", label: "M.Sc. International Logistics & Supply Chain Management (ISM) + Master of Management (University of the Sunshine Coast)" },
	{ value: "M.Sc. International Logistics & Supply Chain Management (ISM) + Master of International Business Management (University of the Sunshine Coast)", label: "M.Sc. International Logistics & Supply Chain Management (ISM) + Master of International Business Management (University of the Sunshine Coast)" },
	{ value: "M.Sc. International Logistics & Supply Chain Management (ISM) + Master in Management (M2) in Supply Chain Management (EM Normandie)", label: "M.Sc. International Logistics & Supply Chain Management (ISM) + Master in Management (M2) in Supply Chain Management (EM Normandie)" },
	{ value: "M.Sc. International Logistics & Supply Chain Management (ISM) + Master in Management (M2) in International Logistics & Crisis Management (EM Normandie)", label: "M.Sc. International Logistics & Supply Chain Management (ISM) + Master in Management (M2) in International Logistics & Crisis Management (EM Normandie)" },
	{ value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Financial Management (Boston University)", label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Financial Management (Boston University)" },
	{ value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Global Marketing Management (Boston University)", label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Global Marketing Management (Boston University)" },
	{ value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Administrative Studies Concentration in Innovation and Technology (Boston University)", label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Administrative Studies Concentration in Innovation and Technology (Boston University)" },
	{ value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Project Management (Boston University)", label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Project Management (Boston University)" },
	{ value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Enterprise Risk Management (Boston University)", label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Enterprise Risk Management (Boston University)" },
	{ value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Supply Chain Management (Boston University)", label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Supply Chain Management (Boston University)" },
	{ value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Applied Business Analytics (Boston University)", label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Applied Business Analytics (Boston University)" },
	{ value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Logistics & Supply Chain Management (Lancaster University)", label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Logistics & Supply Chain Management (Lancaster University)" },
	{ value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Project Management (Lancaster University)", label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Project Management (Lancaster University)" },
	{ value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Marketing Analytics (Lancaster University)", label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Marketing Analytics (Lancaster University)" },
	{ value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Management Science (Lancaster University)", label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Management Science (Lancaster University)" },
	{ value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Management (Edinburgh Napier University)", label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Management (Edinburgh Napier University)" },
	{ value: "M.Sc. Finance (ISM) + M.Sc. Financial Management (Boston University)", label: "M.Sc. Finance (ISM) + M.Sc. Financial Management (Boston University)" },
	{ value: "M.Sc. Finance (ISM) + M.Sc. Global Marketing Management (Boston University)", label: "M.Sc. Finance (ISM) + M.Sc. Global Marketing Management (Boston University)" },
	{ value: "M.Sc. Finance (ISM) + M.Sc. Administrative Studies Concentration in Innovation & Technology (Boston University)", label: "M.Sc. Finance (ISM) + M.Sc. Administrative Studies Concentration in Innovation & Technology (Boston University)" },
	{ label: "M.Sc. Finance", value: "M.Sc. Finance" },
	{ label: "M.Sc. International Logistics & Supply Chain Management", value: "M.Sc. International Logistics & Supply Chain Management" },
	{ label: "M.Sc. International Business", value: "M.Sc. International Business" },
	{ label: "Pre Master in Master of Arts", value: "Pre Master in Master of Arts" },
	{ label: "Pre Master in Master of Science", value: "Pre Master in Master of Science" },
	{ label: "M.Sc. International Management (ISM) + Master of Business in International Business (Bond University)", value: "M.Sc. International Management (ISM) + Master of Business in International Business (Bond University)" },
	{ label: "M.Sc. International Management (ISM) + Master of Business in Marketing (Bond University)", value: "M.Sc. International Management (ISM) + Master of Business in Marketing (Bond University)" },
	{ label: "M.Sc. International Management (ISM) + Master of Business Non-Specialization (Bond University)", value: "M.Sc. International Management (ISM) + Master of Business Non-Specialization (Bond University)" },
	{ label: "M.Sc. International Management (ISM) + Master in International Marketing in a Digital Environment (Universitat Ramon Llull)", value: "M.Sc. International Management (ISM) + Master in International Marketing in a Digital Environment (Universitat Ramon Llull)" },
	{ label: "M.Sc. International Management (ISM) + Master of International Business Management (University of the Sunshine Coast)", value: "M.Sc. International Management (ISM) + Master of International Business Management (University of the Sunshine Coast)" },
	{ label: "M.Sc. International Management (ISM) + Master of Management (University of the Sunshine Coast)", value: "M.Sc. International Management (ISM) + Master of Management (University of the Sunshine Coast)" },
	{ label: "M.Sc. International Management (ISM) + MSc Financial Management (Boston University)", value: "M.Sc. International Management (ISM) + MSc Financial Management (Boston University)" },
	{ label: "M.Sc. International Management (ISM) + M.Sc. Global Marketing Management (Boston University)", value: "M.Sc. International Management (ISM) + M.Sc. Global Marketing Management (Boston University)" },
	{ label: "M.Sc. International Management (ISM) + M.Sc. Administrative Studies Concentration in Innovation & Technology (Boston University)", value: "M.Sc. International Management (ISM) + M.Sc. Administrative Studies Concentration in Innovation & Technology (Boston University)" },
	{ label: "M.Sc. International Management (ISM) + M.Sc. Project Management (Boston University)", value: "M.Sc. International Management (ISM) + M.Sc. Project Management (Boston University)" },
	{ label: "M.Sc. International Management (ISM) + M.Sc. Enterprise Risk Management (Boston University)", value: "M.Sc. International Management (ISM) + M.Sc. Enterprise Risk Management (Boston University)" },
	{ label: "M.Sc. International Management (ISM) + M.Sc. Supply Chain Management (Boston University)", value: "M.Sc. International Management (ISM) + M.Sc. Supply Chain Management (Boston University)" },
	{ label: "M.Sc. International Management (ISM) + M.Sc. Applied Business Analytics (Boston University)", value: "M.Sc. International Management (ISM) + M.Sc. Applied Business Analytics (Boston University)" },
	{ label: "M.Sc. International Management (ISM) + M.Sc. Business Administration (ECUST)", value: "M.Sc. International Management (ISM) + M.Sc. Business Administration (ECUST)" },
	{ label: "M.Sc. International Management (ISM) + International Master of Business Administration (ECUST)", value: "M.Sc. International Management (ISM) + International Master of Business Administration (ECUST)" },
	{ label: "M.Sc. International Management (ISM) + M.Sc. Management (Lancaster University)", value: "M.Sc. International Management (ISM) + M.Sc. Management (Lancaster University)" },
	{ label: "M.Sc. International Management (ISM) + M.Sc. International Business & Strategy (Lancaster University)", value: "M.Sc. International Management (ISM) + M.Sc. International Business & Strategy (Lancaster University)" },
	{ label: "M.Sc. International Management (ISM) + M.Sc. Human Resource Management (Lancaster University)", value: "M.Sc. International Management (ISM) + M.Sc. Human Resource Management (Lancaster University)" },
	{ label: "M.Sc. International Management (ISM) + M.Sc. Information Technology, Management and Organisational Change (Lancaster University)", value: "M.Sc. International Management (ISM) + M.Sc. Information Technology, Management and Organisational Change (Lancaster University)" },
	{ label: "M.Sc. International Management (ISM) + M.Sc. Project Management (Lancaster University)", value: "M.Sc. International Management (ISM) + M.Sc. Project Management (Lancaster University)" },
	{ label: "M.Sc. International Management (ISM) + M.Sc. Management (Edinburgh Napier University)", value: "M.Sc. International Management (ISM) + M.Sc. Management (Edinburgh Napier University)" },
	{ label: "M.Sc. International Management (ISM) + M.Sc. of International Business (Griffith College)", value: "M.Sc. International Management (ISM) + M.Sc. of International Business (Griffith College)" },
	{ label: "M.Sc. International Management (ISM) + Diplôme INSEEC/Grade de Master (INSEEC)", value: "M.Sc. International Management (ISM) + Diplôme INSEEC/Grade de Master (INSEEC)" },
	{ label: "M.Sc. Finance (ISM) + M.Sc. Financial Management (Boston University)", value: "M.Sc. Finance (ISM) + M.Sc. Financial Management (Boston University)" },
	{ label: "M.Sc. Finance (ISM) + M.Sc. Global Marketing Management (Boston University)", value: "M.Sc. Finance (ISM) + M.Sc. Global Marketing Management (Boston University)" },
	{ label: "M.Sc. Finance (ISM) + M.Sc. Administrative Studies Concentration in Innovation & Technology (Boston University)", value: "M.Sc. Finance (ISM) + M.Sc. Administrative Studies Concentration in Innovation & Technology (Boston University)" },
	{ label: "M.Sc. Finance (ISM) + M.Sc. Project Management (Boston University)", value: "M.Sc. Finance (ISM) + M.Sc. Project Management (Boston University)" },
	{ label: "M.Sc. Finance (ISM) + M.Sc. Enterprise Risk Management (Boston University)", value: "M.Sc. Finance (ISM) + M.Sc. Enterprise Risk Management (Boston University)" },
	{ label: "M.Sc. Finance (ISM) + M.Sc. Supply Chain Management (Boston University)", value: "M.Sc. Finance (ISM) + M.Sc. Supply Chain Management (Boston University)" },
	{ label: "M.Sc. Finance (ISM) + M.Sc. Applied Business Analytics (Boston University)", value: "M.Sc. Finance (ISM) + M.Sc. Applied Business Analytics (Boston University)" },
	{ label: "M.Sc. Finance (ISM) + M.Sc. Business Administration (ECUST)", value: "M.Sc. Finance (ISM) + M.Sc. Business Administration (ECUST)" },
	{ label: "M.Sc. Finance (ISM) + International Master of Business Administration (ECUST)", value: "M.Sc. Finance (ISM) + International Master of Business Administration (ECUST)" },
	{ label: "M.Sc. Finance (ISM) + M.Sc. Finance (Lancaster University)", value: "M.Sc. Finance (ISM) + M.Sc. Finance (Lancaster University)" },
	{ label: "M.Sc. International Logistics & Supply Chain Management (ISM) + Master of Management (University of the Sunshine Coast)", value: "M.Sc. International Logistics & Supply Chain Management (ISM) + Master of Management (University of the Sunshine Coast)" },
	{ label: "M.Sc. International Logistics & Supply Chain Management (ISM) + Master of International Business Management (University of the Sunshine Coast)", value: "M.Sc. International Logistics & Supply Chain Management (ISM) + Master of International Business Management (University of the Sunshine Coast)" },
	{ label: "M.Sc. International Logistics & Supply Chain Management (ISM) + Master in Management (M2) in Supply Chain Management (EM Normandie)", value: "M.Sc. International Logistics & Supply Chain Management (ISM) + Master in Management (M2) in Supply Chain Management (EM Normandie)" },
	{ label: "M.Sc. International Logistics & Supply Chain Management (ISM) + Master in Management (M2) in International Logistics & Crisis Management (EM Normandie)", value: "M.Sc. International Logistics & Supply Chain Management (ISM) + Master in Management (M2) in International Logistics & Crisis Management (EM Normandie)" },
	{ label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Financial Management (Boston University)", value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Financial Management (Boston University)" },
	{ label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Global Marketing Management (Boston University)", value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Global Marketing Management (Boston University)" },
	{ label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Administrative Studies Concentration in Innovation and Technology (Boston University)", value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Administrative Studies Concentration in Innovation and Technology (Boston University)" },
	{ label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Project Management (Boston University)", value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Project Management (Boston University)" },
	{ label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Enterprise Risk Management (Boston University)", value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Enterprise Risk Management (Boston University)" },
	{ label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Supply Chain Management (Boston University)", value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Supply Chain Management (Boston University)" },
	{ label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Applied Business Analytics (Boston University)", value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Applied Business Analytics (Boston University)" },
	{ label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Logistics & Supply Chain Management (Lancaster University)", value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Logistics & Supply Chain Management (Lancaster University)" },
	{ label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Project Management (Lancaster University)", value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Project Management (Lancaster University)" },
	{ label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Marketing Analytics (Lancaster University)", value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Marketing Analytics (Lancaster University)" },
	{ label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Management (Edinburgh Napier University)", value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Management (Edinburgh Napier University)" },
	{ label: "M.Sc. Finance (ISM) + Master of Business majoring in Finance (Bond University)", value: "M.Sc. Finance (ISM) + Master of Business majoring in Finance (Bond University)" },
	{ label: "M.Sc. Finance (ISM) + Master of Finance (Bond University)", value: "M.Sc. Finance (ISM) + Master of Finance (Bond University)" },
	{ label: "M.Sc. Finance (ISM) + MSc in Management (Edinburgh Napier University)", value: "M.Sc. Finance (ISM) + MSc in Management (Edinburgh Napier University)" },
	{ label: "M.Sc. Finance (ISM) + Diplôme INSEEC/Grade de Master (INSEEC)", value: "M.Sc. Finance (ISM) + Diplôme INSEEC/Grade de Master (INSEEC)" },
	{ label: "M.Sc. Finance (ISM) + M.Sc. Accounting and Financial Management (Lancaster University)", value: "M.Sc. Finance (ISM) + M.Sc. Accounting and Financial Management (Lancaster University)" },
	{ label: "M.Sc. Finance (ISM) + M.Sc. Money, Banking and Finance (Lancaster University)", value: "M.Sc. Finance (ISM) + M.Sc. Money, Banking and Finance (Lancaster University)" },
	{ label: "M.Sc. Finance (ISM) + M.Sc. Quantitative Finance (Lancaster University)", value: "M.Sc. Finance (ISM) + M.Sc. Quantitative Finance (Lancaster University)" },
	{ label: "M.Sc. Finance (ISM) + M.Sc. Advanced Financial Analysis (Lancaster University)", value: "M.Sc. Finance (ISM) + M.Sc. Advanced Financial Analysis (Lancaster University)" },
	{ label: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Management Science (Lancaster University)", value: "M.Sc. International Logistics & Supply Chain Management (ISM) + M.Sc. Management Science (Lancaster University)" }
];

export default ISMGerCourses;