const BurgundySchoolBusinessCourses = [
	{ value: "Bachelor in Management", label: "Bachelor in Management" },
	{ value: "Bachelor in Management ( 3rd year Entrance )", label: "Bachelor in Management ( 3rd year Entrance )" },
	{ value: "MSc Luxury Management & Innovation", label: "MSc Luxury Management & Innovation" },
	{ value: "MSc in Climate Change & Corporate Finance", label: "MSc in Climate Change & Corporate Finance" },
	{ value: "MSc in Artificial Intelligence & Digital Technology Management", label: "MSc in Artificial Intelligence & Digital Technology Management" },
	{ value: "MSc in Green Tech & Sustainable Societies", label: "MSc in Green Tech & Sustainable Societies" },
	{ value: "MSc in Sustainable Wine Tourism & Gastronomy", label: "MSc in Sustainable Wine Tourism & Gastronomy" },
	{ value: "MSc in Corporate Finance and Investment Banking", label: "MSc in Corporate Finance and Investment Banking" },
	{ value: "MSc in Wine Management", label: "MSc in Wine Management" },
	{ value: "MSc in Arts and Cultural Management", label: "MSc in Arts and Cultural Management" },
	{ value: "Master in Management", label: "Master in Management" },
	{ value: "MSc in Data Science & Organisational Behaviour", label: "MSc in Data Science & Organisational Behaviour" },
	{ value: "MBA in Wine & Spirits Business", label: "MBA in Wine & Spirits Business" }
];

export default BurgundySchoolBusinessCourses;
