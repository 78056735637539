const CanadaCourses = [
	{ value: 'University of Waterloo', label: 'University of Waterloo' },
	{ value: 'University of Calgary4', label: 'University of Calgary4' },
	{ value: 'Simon Fraser University through Fraser International College', label: 'Simon Fraser University through Fraser International College' },
	{ value: 'Queens University, Canada', label: 'Queens University, Canada' },
	{ value: 'York University', label: 'York University' },
	{ value: 'Dalhousie University', label: 'Dalhousie University' },
	{ value: 'University of Victoria', label: 'University of Victoria' },
	{ value: 'University of Manitoba', label: 'University of Manitoba' },
	{ value: 'University of Manitoba through International College of Manitoba', label: 'University of Manitoba through International College of Manitoba' },
	{ value: 'University of Guelph', label: 'University of Guelph' },
	{ value: 'Memorial University of Newfoundland, Grenfell Campus', label: 'Memorial University of Newfoundland, Grenfell Campus' },
	{ value: 'Memorial University of Newfoundland', label: 'Memorial University of Newfoundland' },
	{ value: 'University of New Brunswick', label: 'University of New Brunswick' },
	{ value: 'University of New Brunswick, Saint John', label: 'University of New Brunswick, Saint John' },
	{ value: 'University of Windsor', label: 'University of Windsor' },
	{ value: 'Toronto Metropolitan University', label: 'Toronto Metropolitan University' },
	{ value: 'Toronto Metropolitan University through Toronto Metropolitan International College', label: 'Toronto Metropolitan University through Toronto Metropolitan International College' },
	{ value: 'University of Regina', label: 'University of Regina' },
	{ value: 'Brock University', label: 'Brock University' },
	{ value: 'Wilfrid Laurier International College', label: 'Wilfrid Laurier International College' },
	{ value: 'Wilfrid Laurier University', label: 'Wilfrid Laurier University' },
	{ value: 'Lakehead University, Orillia', label: 'Lakehead University, Orillia' },
	{ value: 'Lakehead University, Thunder Bay', label: 'Lakehead University, Thunder Bay' },
	{ value: 'Lakehead University through Georgian College, Barrie, Ontario', label: 'Lakehead University through Georgian College, Barrie, Ontario' },
	{ value: 'Trent University', label: 'Trent University' },
	{ value: 'University of Winnipeg', label: 'University of Winnipeg' },
	{ value: 'Ontario Tech University', label: 'Ontario Tech University' },
	{ value: 'University of Northern British Columbia', label: 'University of Northern British Columbia' },
	{ value: 'Laurentian University', label: 'Laurentian University' },
	{ value: 'St. Francis Xavier University', label: 'St. Francis Xavier University' },
	{ value: 'University of Prince Edward Island', label: 'University of Prince Edward Island' },
	{ value: 'Acadia University', label: 'Acadia University' },
	{ value: 'Thompson Rivers University', label: 'Thompson Rivers University' },
	{ value: 'Fairleigh Dickinson University, Vancouver, Canada', label: 'Fairleigh Dickinson University, Vancouver, Canada' },
	{ value: 'Mount Allison University', label: 'Mount Allison University' },
	{ value: 'MacEwan University', label: 'MacEwan University' },
	{ value: 'Nipissing University', label: 'Nipissing University' },
	{ value: 'University of Lethbridge through International College Calgary', label: 'University of Lethbridge through International College Calgary' },
	{ value: 'University of Lethbridge', label: 'University of Lethbridge' },
	{ value: 'Royal Roads University', label: 'Royal Roads University' },
	{ value: 'Mount Saint Vincent University', label: 'Mount Saint Vincent University' },
	{ value: 'Cape Breton University', label: 'Cape Breton University' },
	{ value: 'Trinity Western University', label: 'Trinity Western University' },
	{ value: 'British Columbia Institute of Technology', label: 'British Columbia Institute of Technology' },
	{ value: 'New York Institute of Technology, Canada', label: 'New York Institute of Technology, Canada' },
	{ value: 'Seneca Polytechnic', label: 'Seneca Polytechnic' },
	{ value: 'Seneca International Academy', label: 'Seneca International Academy' },
	{ value: 'Vancouver Island University', label: 'Vancouver Island University' },
	{ value: 'University of the Fraser Valley', label: 'University of the Fraser Valley' },
	{ value: 'Kwantlen Polytechnic University', label: 'Kwantlen Polytechnic University' },
	{ value: 'Sheridan College - Davis Campus (Brampton)', label: 'Sheridan College - Davis Campus (Brampton)' },
	{ value: 'Sheridan College - Hazel McCallion Campus (Mississauga)', label: 'Sheridan College - Hazel McCallion Campus (Mississauga)' },
	{ value: 'Sheridan College - Trafalgar Campus (Oakville)', label: 'Sheridan College - Trafalgar Campus (Oakville)' },
	{ value: 'Sheridan College at CCTT - Fort Erie Campus', label: 'Sheridan College at CCTT - Fort Erie Campus' },
	{ value: 'Conestoga College, Waterloo', label: 'Conestoga College, Waterloo' },
	{ value: 'Conestoga College - Milton( Steeles Avenue East)', label: 'Conestoga College - Milton( Steeles Avenue East)' },
	{ value: 'Conestoga College - Doon Campus', label: 'Conestoga College - Doon Campus' },
	{ value: 'Conestoga College, Brantford', label: 'Conestoga College, Brantford' },
	{ value: 'Conestoga College, Guelph', label: 'Conestoga College, Guelph' },
	{ value: 'Conestoga College - Milton Parkhill Drive Campus', label: 'Conestoga College - Milton Parkhill Drive Campus' },
	{ value: 'Conestoga College - Kitchener (Downtown) Campus', label: 'Conestoga College - Kitchener (Downtown) Campus' },
	{ value: 'Conestoga College – Reuter Drive Campus', label: 'Conestoga College – Reuter Drive Campus' },
	{ value: 'Conestoga College, Cambridge', label: 'Conestoga College, Cambridge' },
	{ value: 'George Brown College, Casa Loma', label: 'George Brown College, Casa Loma' },
	{ value: 'George Brown College, St. James', label: 'George Brown College, St. James' },
	{ value: 'George Brown College, Waterfront', label: 'George Brown College, Waterfront' },
	{ value: 'Algoma University', label: 'Algoma University' },
	{ value: 'Southern Alberta Institute of Technology', label: 'Southern Alberta Institute of Technology' },
	{ value: 'Douglas College', label: 'Douglas College' },
	{ value: 'St. Thomas University', label: 'St. Thomas University' },
	{ value: 'Capilano University', label: 'Capilano University' },
	{ value: 'University Canada West', label: 'University Canada West' },
	{ value: 'Fanshawe College', label: 'Fanshawe College' },
	{ value: 'Fanshawe College, Toronto', label: 'Fanshawe College, Toronto' },
	{ value: 'Niagara College', label: 'Niagara College' },
	{ value: 'Niagara College, Toronto, Ontario', label: 'Niagara College, Toronto, Ontario' },
	{ value: 'Durham College', label: 'Durham College' },
	{ value: 'Georgian College', label: 'Georgian College' },
	{ value: 'Georgian College, Toronto, Ontario', label: 'Georgian College, Toronto, Ontario' },
	{ value: 'Yorkville University', label: 'Yorkville University' },
	{ value: 'Saskatchewan Polytechnic, Moose Jaw', label: 'Saskatchewan Polytechnic, Moose Jaw' },
	{ value: 'Saskatchewan Polytechnic, Prince Albert', label: 'Saskatchewan Polytechnic, Prince Albert' },
	{ value: 'Saskatchewan Polytechnic, Saskatoon', label: 'Saskatchewan Polytechnic, Saskatoon' },
	{ value: 'Saskatchewan Polytechnic, Regina', label: 'Saskatchewan Polytechnic, Regina' },
	{ value: 'Langara College', label: 'Langara College' },
	{ value: 'Vancouver Film School', label: 'Vancouver Film School' },
	{ value: 'Vancouver Community College', label: 'Vancouver Community College' },
	{ value: 'Bow Valley College', label: 'Bow Valley College' },
	{ value: 'Fleming College', label: 'Fleming College' },
	{ value: 'Fleming College, Toronto', label: 'Fleming College, Toronto' },
	{ value: 'Adler University', label: 'Adler University' },
	{ value: 'St. Lawrence College, Kingston', label: 'St. Lawrence College, Kingston' },
	{ value: 'St. Lawrence College, Brockville', label: 'St. Lawrence College, Brockville' },
	{ value: 'St. Lawrence College, Cornwall', label: 'St. Lawrence College, Cornwall' },
	{ value: 'Cambrian College', label: 'Cambrian College' },
	{ value: 'Cambrian College at Hanson', label: 'Cambrian College at Hanson' },
	{ value: 'Cambrian College at Hanson, Vancouver', label: 'Cambrian College at Hanson, Vancouver' },
	{ value: 'Selkirk College', label: 'Selkirk College' },
	{ value: 'Lambton College - Ottawa Campus', label: 'Lambton College - Ottawa Campus' },
	{ value: 'Lambton College, Sarnia', label: 'Lambton College, Sarnia' },
	{ value: 'Lambton College,Mississauga', label: 'Lambton College,Mississauga' },
	{ value: 'Lambton College, Toronto', label: 'Lambton College, Toronto' },
	{ value: 'College of New Caledonia, Prince George Campus', label: 'College of New Caledonia, Prince George Campus' },
	{ value: 'College of New Caledonia, Quesnel Campus', label: 'College of New Caledonia, Quesnel Campus' },
	{ value: 'North Island College', label: 'North Island College' },
	{ value: 'NorQuest College', label: 'NorQuest College' },
	{ value: 'OLDS College of Agriculture and Technology', label: 'OLDS College of Agriculture and Technology' },
	{ value: 'Justice Institute of British Columbia ( JIBC )', label: 'Justice Institute of British Columbia ( JIBC )' },
	{ value: 'Canadore College - College Drive North Bay Campus', label: 'Canadore College - College Drive North Bay Campus' },
	{ value: 'Canadore College - Commerce Court North Bay Campus', label: 'Canadore College - Commerce Court North Bay Campus' },
	{ value: 'Canadore College - Stanford Mississauga Campus', label: 'Canadore College - Stanford Mississauga Campus' },
	{ value: 'Canadore College - Stanford Scarborough Campus', label: 'Canadore College - Stanford Scarborough Campus' },
	{ value: 'Canadore College - Stanford Brampton Campus', label: 'Canadore College - Stanford Brampton Campus' },
	{ value: 'Confederation College - Thunder Bay Campus', label: 'Confederation College - Thunder Bay Campus' },
	{ value: 'Confederation College - Kenora Campus', label: 'Confederation College - Kenora Campus' },
	{ value: 'Confederation College - Fort Frances Campus', label: 'Confederation College - Fort Frances Campus' },
	{ value: 'Confederation College - Dryden Campus', label: 'Confederation College - Dryden Campus' },
	{ value: 'Confederation College - Greenstone Campus9', label: 'Confederation College - Greenstone Campus9' },
	{ value: 'Confederation College - Marathon Campus8', label: 'Confederation College - Marathon Campus8' },
	{ value: 'Confederation College - Sioux Lookout Campus', label: 'Confederation College - Sioux Lookout Campus' },
	{ value: 'King’s College (University of Western Ontario)', label: 'King’s College (University of Western Ontario)' },
	{ value: 'College of the Rockies', label: 'College of the Rockies' },
	{ value: 'Medicine Hat College', label: 'Medicine Hat College' },
	{ value: 'Northern Lights College', label: 'Northern Lights College' },
	{ value: 'Assiniboine Community College', label: 'Assiniboine Community College' },
	{ value: 'Lakeland College', label: 'Lakeland College' },
	{ value: 'Toronto School of Management (TSoM), Canada', label: 'Toronto School of Management (TSoM), Canada' },
	{ value: 'LaSalle College, Montreal', label: 'LaSalle College, Montreal' },
	{ value: 'Keyano College', label: 'Keyano College' },
	{ value: 'Manitoba Institute of Trades and Technology', label: 'Manitoba Institute of Trades and Technology' },
	{ value: 'Alexander College', label: 'Alexander College' },
	{ value: 'LaSalle College, Vancouver', label: 'LaSalle College, Vancouver' },
	{ value: 'Crandall University', label: 'Crandall University' },
	{ value: 'Crandall University - Sussex', label: 'Crandall University - Sussex' },
	{ value: 'Portage College at Campbell Campus, Edmonton', label: 'Portage College at Campbell Campus, Edmonton' },
	{ value: 'Coquitlam College', label: 'Coquitlam College' },
	{ value: 'Providence University College', label: 'Providence University College' },
	{ value: 'Northern College', label: 'Northern College' },
	{ value: 'Great Plains College', label: 'Great Plains College' },
	{ value: 'Great Plains College', label: 'Great Plains College' },
	{ value: 'Acsenda School of Management', label: 'Acsenda School of Management' },
	{ value: 'Le Cordon Bleu', label: 'Le Cordon Bleu' },
	{ value: 'Northern College at Pures-Toronto', label: 'Northern College at Pures-Toronto' },
	{ value: 'Toronto Film School', label: 'Toronto Film School' },
	{ value: 'Vancouver Premier College', label: 'Vancouver Premier College' },
	{ value: 'Suncrest College', label: 'Suncrest College' },
	{ value: 'Oxford International College, Halifax', label: 'Oxford International College, Halifax' },
	{ value: 'University of Niagara Falls, Canada', label: 'University of Niagara Falls, Canada' },
	{ value: 'North West College - Saskatchewan', label: 'North West College - Saskatchewan' },
	{ value: 'International Business University (IBU)', label: 'International Business University (IBU)' }
];

export default CanadaCourses;