import React from 'react';
import { Box } from '@chakra-ui/react';

export const Card = ({ children }) => {
	return (
		<Box
			maxW="md"
			p={5}
			boxShadow="md"
			borderWidth="1px"
			borderRadius="lg"
			bg="white"
		>
			{children}
		</Box>
	);
};

export default Card;
