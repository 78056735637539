const AustralianIdealCourses = [
	{ value: 'Diploma of Interpreting (LOTE-English)', label: 'Diploma of Interpreting (LOTE-English)' },
	{ value: 'Hospitality Management - Advanced Diploma', label: 'Hospitality Management - Advanced Diploma' },
	{ value: 'Travel and Tourism Management Diploma', label: 'Travel and Tourism Management Diploma' },
	{ value: 'General English - Advanced', label: 'General English - Advanced' },
	{ value: 'Management (Learning) GD', label: 'Management (Learning) GD' },
	{ value: 'English for Academic Purposes - EAP 1', label: 'English for Academic Purposes - EAP 1' },
	{ value: 'English for Academic Purposes - EAP 2', label: 'English for Academic Purposes - EAP 2' },
	{ value: 'Accounting - Diploma', label: 'Accounting - Diploma' },
	{ value: 'Travel and Tourism Management - Advanced Diploma', label: 'Travel and Tourism Management - Advanced Diploma' },
	{ value: 'Project Management Practice - Certificate IV', label: 'Project Management Practice - Certificate IV' },
	{ value: 'General English - Upper-Intermediate', label: 'General English - Upper-Intermediate' },
	{ value: 'General English - Elementary', label: 'General English - Elementary' },
	{ value: 'Hospitality Management - Advanced Diploma', label: 'Hospitality Management - Advanced Diploma' },
	{ value: 'Business (Administration) - Certificate III', label: 'Business (Administration) - Certificate III' },
	{ value: 'Accounts Administration - Certificate III', label: 'Accounts Administration - Certificate III' },
	{ value: 'Diploma of Marketing and Communication', label: 'Diploma of Marketing and Communication' },
	{ value: 'Advanced Diploma of Marketing and Communication', label: 'Advanced Diploma of Marketing and Communication' },
	{ value: 'Leadership and Management - Advanced Diploma (52 Weeks)', label: 'Leadership and Management - Advanced Diploma (52 Weeks)' },
	{ value: 'General English - Intermediate', label: 'General English - Intermediate' },
	{ value: 'Project Management Practice - Certificate IV', label: 'Project Management Practice - Certificate IV' },
	{ value: 'Diploma of Marketing and Communication', label: 'Diploma of Marketing and Communication' },
	{ value: 'English for Academic Purposes - EAP 2', label: 'English for Academic Purposes - EAP 2' },
	{ value: 'Hospitality Management - Diploma', label: 'Hospitality Management - Diploma' },
	{ value: 'Accounting - Advanced Diploma', label: 'Accounting - Advanced Diploma' },
	{ value: 'Leadership and Management - Advanced Diploma (78 Weeks)', label: 'Leadership and Management - Advanced Diploma (78 Weeks)' },
	{ value: 'Accounting - Diploma', label: 'Accounting - Diploma' },
	{ value: 'Travel and Tourism Management - Advanced Diploma', label: 'Travel and Tourism Management - Advanced Diploma' },
	{ value: 'Business (Administration) - Certificate IV', label: 'Business (Administration) - Certificate IV' },
	{ value: 'Project Management Practice - Certificate IV', label: 'Project Management Practice - Certificate IV' },
	{ value: 'General English - Beginner', label: 'General English - Beginner' },
	{ value: 'Advanced Diploma of Translating', label: 'Advanced Diploma of Translating' },
	{ value: 'Diploma of Interpreting (LOTE-English)', label: 'Diploma of Interpreting (LOTE-English)' },
	{ value: 'Accounts Administration - Certificate III', label: 'Accounts Administration - Certificate III' },
	{ value: 'Accounting and Bookkeeping - Certificate IV', label: 'Accounting and Bookkeeping - Certificate IV' },
	{ value: 'Travel and Tourism Management - Advanced Diploma', label: 'Travel and Tourism Management - Advanced Diploma' },
	{ value: 'General English - Advanced', label: 'General English - Advanced' },
	{ value: 'Travel and Tourism Management Diploma', label: 'Travel and Tourism Management Diploma' },
	{ value: 'Advanced Diploma of Marketing and Communication', label: 'Advanced Diploma of Marketing and Communication' },
	{ value: 'Advanced Diploma of Marketing and Communication', label: 'Advanced Diploma of Marketing and Communication' },
	{ value: 'Diploma of Marketing and Communication', label: 'Diploma of Marketing and Communication' },
	{ value: 'General English - Beginner', label: 'General English - Beginner' },
	{ value: 'Hospitality Management - Diploma', label: 'Hospitality Management - Diploma' },
	{ value: 'Hospitality Management - Advanced Diploma', label: 'Hospitality Management - Advanced Diploma' },
	{ value: 'Travel and Tourism Management Diploma', label: 'Travel and Tourism Management Diploma' },
	{ value: 'Business (Administration) - Certificate III', label: 'Business (Administration) - Certificate III' },
	{ value: 'Project Management - Diploma', label: 'Project Management - Diploma' },
	{ value: 'Graduate Diploma of Management (Learning)', label: 'Graduate Diploma of Management (Learning)' },
	{ value: 'Accounts Administration - Certificate III', label: 'Accounts Administration - Certificate III' },
	{ value: 'Accounting and Bookkeeping - Certificate IV', label: 'Accounting and Bookkeeping - Certificate IV' },
	{ value: 'Leadership and Management - Diploma', label: 'Leadership and Management - Diploma' },
	{ value: 'General English - Elementary', label: 'General English - Elementary' },
	{ value: 'Leadership and Management - Diploma', label: 'Leadership and Management - Diploma' },
	{ value: 'Advanced Diploma of Translating', label: 'Advanced Diploma of Translating' },
	{ value: 'Business (Administration) - Certificate IV', label: 'Business (Administration) - Certificate IV' },
	{ value: 'Leadership and Management - Advanced Diploma (52 Weeks)', label: 'Leadership and Management - Advanced Diploma (52 Weeks)' },
	{ value: 'Project Management - Diploma', label: 'Project Management - Diploma' },
	{ value: 'General English - Beginner', label: 'General English - Beginner' },
	{ value: 'General English - Pre-intermediate', label: 'General English - Pre-intermediate' },
	{ value: 'Hospitality Management - Diploma', label: 'Hospitality Management - Diploma' },
	{ value: 'General English - Advanced', label: 'General English - Advanced' },
	{ value: 'English for Academic Purposes - EAP 2', label: 'English for Academic Purposes - EAP 2' },
	{ value: 'General English - Intermediate', label: 'General English - Intermediate' },
	{ value: 'Leadership and Management - Advanced Diploma (52 Weeks)', label: 'Leadership and Management - Advanced Diploma (52 Weeks)' },
	{ value: 'General English - Upper-Intermediate', label: 'General English - Upper-Intermediate' },
	{ value: 'Leadership and Management - Advanced Diploma (78 Weeks)', label: 'Leadership and Management - Advanced Diploma (78 Weeks)' },
	{ value: 'Business (Administration) - Certificate IV', label: 'Business (Administration) - Certificate IV' },
	{ value: 'Accounting - Advanced Diploma', label: 'Accounting - Advanced Diploma' },
	{ value: 'Advanced Diploma of Translating', label: 'Advanced Diploma of Translating' },
	{ value: 'Accounting - Advanced Diploma', label: 'Accounting - Advanced Diploma' },
	{ value: 'Project Management - Diploma', label: 'Project Management - Diploma' },
	{ value: 'Diploma of Interpreting (LOTE-English)', label: 'Diploma of Interpreting (LOTE-English)' },
	{ value: 'General English - Pre-intermediate', label: 'General English - Pre-intermediate' },
	{ value: 'Leadership and Management - Advanced Diploma (78 Weeks)', label: 'Leadership and Management - Advanced Diploma (78 Weeks)' },
	{ value: 'Accounting and Bookkeeping - Certificate IV', label: 'Accounting and Bookkeeping - Certificate IV' },
	{ value: 'Graduate Diploma of Management (Learning)', label: 'Graduate Diploma of Management (Learning)' },
	{ value: 'Business (Administration) - Certificate III', label: 'Business (Administration) - Certificate III' },
	{ value: 'General English - Elementary', label: 'General English - Elementary' },
	{ value: 'Leadership and Management - Diploma', label: 'Leadership and Management - Diploma' }
];

export default AustralianIdealCourses;