const AustraliaCourses = [
	{ value: 'University of New South Wales', label: 'University of New South Wales' },
	{ value: 'UQ College', label: 'UQ College' },
	{ value: 'The University of Queensland', label: 'The University of Queensland' },
	{ value: 'Monash University', label: 'Monash University' },
	{ value: 'University of Adelaide College', label: 'University of Adelaide College' },
	{ value: 'The University of Adelaide', label: 'The University of Adelaide' },
	{ value: 'Griffith College', label: 'Griffith College' },
	{ value: 'UNSW College', label: 'UNSW College' },
	{ value: 'University of Western Australia', label: 'University of Western Australia' },
	{ value: 'La Trobe College', label: 'La Trobe College' },
	{ value: 'University Of Technology Sydney', label: 'University Of Technology Sydney' },
	{ value: 'Deakin University', label: 'Deakin University' },
	{ value: 'Curtin University', label: 'Curtin University' },
	{ value: 'Macquarie University', label: 'Macquarie University' },
	{ value: 'Queensland University of Technology', label: 'Queensland University of Technology' },
	{ value: 'RMIT University', label: 'RMIT University' },
	{ value: 'Griffith University', label: 'Griffith University' },
	{ value: 'University of Canberra College', label: 'University of Canberra College' },
	{ value: 'University of Wollongong', label: 'University of Wollongong' },
	{ value: 'Russo Business School', label: 'Russo Business School' },
	{ value: 'University of Newcastle', label: 'University of Newcastle' },
	{ value: 'La Trobe University, Melbourne', label: 'La Trobe University, Melbourne' },
	{ value: 'La Trobe University, Sydney', label: 'La Trobe University, Sydney' },
	{ value: 'University of Tasmania', label: 'University of Tasmania' },
	{ value: 'UP Education- University of Tasmania', label: 'UP Education- University of Tasmania' },
	{ value: 'University of South Australia', label: 'University of South Australia' },
	{ value: 'Western Sydney University, Paramatta', label: 'Western Sydney University, Paramatta' },
	{ value: 'Western Sydney University, Sydney City', label: 'Western Sydney University, Sydney City' },
	{ value: 'Flinders University', label: 'Flinders University' },
	{ value: 'James Cook University, Townsville', label: 'James Cook University, Townsville' },
	{ value: 'James Cook University, Brisbane', label: 'James Cook University, Brisbane' },
	{ value: 'James Cook University, Cairns', label: 'James Cook University, Cairns' },
	{ value: 'Charles Sturt University Sydney', label: 'Charles Sturt University Sydney' },
	{ value: 'Western Sydney University Melbourne- ATMC Education Group', label: 'Western Sydney University Melbourne- ATMC Education Group' },
	{ value: 'Amber Aviation Academy', label: 'Amber Aviation Academy' },
	{ value: 'CQUniversity, Sydney', label: 'CQUniversity, Sydney' },
	{ value: 'CQUniversity, Melbourne', label: 'CQUniversity, Melbourne' },
	{ value: 'CQUniversity, Brisbane', label: 'CQUniversity, Brisbane' },
	{ value: 'CQUniversity, Perth', label: 'CQUniversity, Perth' },
	{ value: 'CQUniversity, Rockhampton', label: 'CQUniversity, Rockhampton' },
	{ value: 'UP Education- Swinburne University of Technology', label: 'UP Education- Swinburne University of Technology' },
	{ value: 'Charles Darwin University', label: 'Charles Darwin University' },
	{ value: 'Swinburne University of Technology', label: 'Swinburne University of Technology' },
	{ value: 'Le Cordon Bleu, Sydney5', label: 'Le Cordon Bleu, Sydney5' },
	{ value: 'Edith Cowan College', label: 'Edith Cowan College' },
	{ value: 'Tafe Queensland', label: 'Tafe Queensland' },
	{ value: 'Engineering Institute of Technology', label: 'Engineering Institute of Technology' },
	{ value: 'William Angliss Institute', label: 'William Angliss Institute' },
	{ value: 'Australian College of Applied Professions', label: 'Australian College of Applied Professions' },
	{ value: 'International College of Management Sydney', label: 'International College of Management Sydney' },
	{ value: 'Kaplan Business School', label: 'Kaplan Business School' },
	{ value: 'UTS College', label: 'UTS College' },
	{ value: 'Deakin College', label: 'Deakin College' },
	{ value: 'Excelsia College', label: 'Excelsia College' },
	{ value: 'South Australia Institute of Business & Technology8', label: 'South Australia Institute of Business & Technology8' },
	{ value: 'Curtin College', label: 'Curtin College' },
	{ value: 'International College of Hotel Management', label: 'International College of Hotel Management' },
	{ value: 'Sydney Institute of Business & Technology.', label: 'Sydney Institute of Business & Technology.' },
	{ value: 'Academy of Interactive Technology (Sydney/Melbourne)', label: 'Academy of Interactive Technology (Sydney/Melbourne)' },
	{ value: 'Kent Institute', label: 'Kent Institute' },
	{ value: 'Eynesbury College', label: 'Eynesbury College' },
	{ value: 'University of Newcastle College of International Education', label: 'University of Newcastle College of International Education' },
	{ value: 'Le Cordon Bleu, Adelaide', label: 'Le Cordon Bleu, Adelaide' },
	{ value: 'Monash College', label: 'Monash College' },
	{ value: 'Le Cordon Bleu, Melbourne', label: 'Le Cordon Bleu, Melbourne' },
	{ value: 'Le Cordon Bleu, Perth', label: 'Le Cordon Bleu, Perth' },
	{ value: 'Analytics Institute of Australia', label: 'Analytics Institute of Australia' },
	{ value: 'UWA College in Partnership with INTO Perth', label: 'UWA College in Partnership with INTO Perth' }
];

export default AustraliaCourses;