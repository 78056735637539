const MaynoothCourses = [
	{ label: "B.ED- Bachelor of Education Degree Primary Teaching (GAELTACHT)", value: "B.ED- Bachelor of Education Degree Primary Teaching (GAELTACHT)" },
	{ label: "BA Accounting & Finance", value: "BA Accounting & Finance" },
	{ label: "BA Finance", value: "BA Finance" },
	{ label: "BA Media Studies", value: "BA Media Studies" },
	{ label: "BA Psychology", value: "BA Psychology" },
	{ label: "Bachelor Of Arts", value: "Bachelor Of Arts" },
	{ label: "BE Electronic Engineering", value: "BE Electronic Engineering" },
	{ label: "Bachelor of Music", value: "Bachelor of Music" },
	{ label: "Bachelor of Science", value: "Bachelor of Science" },
	{ label: "Bachelor of Social Science", value: "Bachelor of Social Science" },
	{ label: "BBS Business and Accounting", value: "BBS Business and Accounting" },
	{ label: "BBS Business and Management", value: "BBS Business and Management" },
	{ label: "BBS Entrepreneurship", value: "BBS Entrepreneurship" },
	{ label: "BBS International Business", value: "BBS International Business" },
	{ label: "BBS Marketing (MKT)", value: "BBS Marketing (MKT)" },
	{ label: "BCL (Law And Accounting) (LWA)", value: "BCL (Law And Accounting) (LWA)" },
	{ label: "BCL Law and Arts (LWD)", value: "BCL Law and Arts (LWD)" },
	{ label: "BCL (Law And Business) (LWB)", value: "BCL (Law And Business) (LWB)" },
	{ label: "BCL (Law And Criminology) (LWC)", value: "BCL (Law And Criminology) (LWC)" },
	{ label: "BSc Biological And Biomedical Sciences", value: "BSc Biological And Biomedical Sciences" },
	{ label: "BSc Biotechnology", value: "BSc Biotechnology" },
	{ label: "BSc Computational Thinking", value: "BSc Computational Thinking" },
	{ label: "BSc Computer Science and Software Engineering (Arts) (CSA)", value: "BSc Computer Science and Software Engineering (Arts) (CSA)" },
	{ label: "BSc Mathematics with Education (MED)", value: "BSc Mathematics with Education (MED)" },
	{ label: "BSc Multimedia, Mobile & Web Development (Arts) (MWA)", value: "BSc Multimedia, Mobile & Web Development (Arts) (MWA)" },
	{ label: "BSc Pharmaceutical And Biomedical Chemistry", value: "BSc Pharmaceutical And Biomedical Chemistry" },
	{ label: "BSc Physics with Astrophysics", value: "BSc Physics with Astrophysics" },
	{ label: "BSc Product Design (Marketing & Innovation)", value: "BSc Product Design (Marketing & Innovation)" },
	{ label: "BSc Psychology", value: "BSc Psychology" },
	{ label: "BSC Robotics and Intelligent Devices", value: "BSC Robotics and Intelligent Devices" },
	{ label: "BSc Science (with Education) (SED)", value: "BSc Science (with Education) (SED)" },
	{ label: "BSc Theoretical Physics & Mathematics", value: "BSc Theoretical Physics & Mathematics" },
	{ label: "LLB Bachelor of Law", value: "LLB Bachelor of Law" },
	{ label: "BSc Computer Sci & Software Engineering (CSS)", value: "BSc Computer Sci & Software Engineering (CSS)" },
	{ label: "BSc Multimedia, Mobile & Web Development (MWS)", value: "BSc Multimedia, Mobile & Web Development (MWS)" },
	{ label: "BSc Data Science", value: "BSc Data Science" },
	{ label: "BSc Biological And Geographical Sciences", value: "BSc Biological And Geographical Sciences" },
	{ label: "BSc Quantitative Finance", value: "BSc Quantitative Finance" },
	{ label: "BA Early Childhood Teaching & Learning", value: "BA Early Childhood Teaching & Learning" },
	{ label: "B.ED Bachelor of Education-Primary Teaching", value: "B.ED Bachelor of Education-Primary Teaching" },
	{ label: "Bachelor of Social Science (Community and Youth Work)", value: "Bachelor of Social Science (Community and Youth Work)" },
	{ label: "BBS Business Management And Global Cultures", value: "BBS Business Management And Global Cultures" },
	{ label: "BBS International Business And Global Cultures", value: "BBS International Business And Global Cultures" },
	{ label: "BBS Marketing And Global Cultures", value: "BBS Marketing And Global Cultures" },
	{ label: "BSC - Mathematics and Computer Science (with Education)", value: "BSC - Mathematics and Computer Science (with Education)" },
	{ label: "BSc Economics", value: "BSc Economics" },
	{ label: "MSc Digital Marketing", value: "MSc Digital Marketing" },
	{ label: "MA European History", value: "MA European History" },
	{ label: "MSc Business", value: "MSc Business" },
	{ label: "MSOCSC Social Work", value: "MSOCSC Social Work" },
	{ label: "MSOCSC Social Science (Rights and social Policy)", value: "MSOCSC Social Science (Rights and social Policy)" },
	{ label: "MA English: Literatures of Engagement", value: "MA English: Literatures of Engagement" },
	{ label: "MA Applied Linguistics & Intercultural Studies", value: "MA Applied Linguistics & Intercultural Studies" },
	{ label: "MSc Finance and Regulation", value: "MSc Finance and Regulation" },
	{ label: "MSc International Business", value: "MSc International Business" },
	{ label: "MSc Strategic Marketing", value: "MSc Strategic Marketing" },
	{ label: "MA Geography : Spatial Justice", value: "MA Geography : Spatial Justice" },
	{ label: "MA International Peacebuilding,Security and Development Practice", value: "MA International Peacebuilding,Security and Development Practice" },
	{ label: "MSc Sound & Music Computing", value: "MSc Sound & Music Computing" },
	{ label: "MSc Psychology", value: "MSc Psychology" },
	{ label: "MSc Mathematical Science", value: "MSc Mathematical Science" },
	{ label: "MSc Business Analytics", value: "MSc Business Analytics" },
	{ label: "MSc Strategy & Innovation", value: "MSc Strategy & Innovation" },
	{ label: "Msc Mathematics", value: "Msc Mathematics" },
	{ label: "MSc IT-Enabled Innovation", value: "MSc IT-Enabled Innovation" },
	{ label: "MSc in Climate Change", value: "MSc in Climate Change" },
	{ label: "MSc Immunology & Global Health", value: "MSc Immunology & Global Health" },
	{ label: "MSc Geographical Information Systems & Remote Sensing", value: "MSc Geographical Information Systems & Remote Sensing" },
	{ label: "MSc Data Science and Analytics", value: "MSc Data Science and Analytics" },
	{ label: "MSc Computer Science (applied)", value: "MSc Computer Science (applied)" },
	{ label: "MSc Business Management", value: "MSc Business Management" },
	{ label: "MA Sociology Societies In Transition", value: "MA Sociology Societies In Transition" },
	{ label: "MA Creative Music Technologies", value: "MA Creative Music Technologies" },
	{ label: "MA Music Composition", value: "MA Music Composition" },
	{ label: "MA Musicology", value: "MA Musicology" },
	{ label: "MA in Philosophy", value: "MA in Philosophy" },
	{ label: "MA Critical And Creative Media", value: "MA Critical And Creative Media" },
	{ label: "MA Performance & Musicology", value: "MA Performance & Musicology" },
	{ label: "MA Comparative Criminology and Criminal Justice", value: "MA Comparative Criminology and Criminal Justice" },
	{ label: "MSOCSC Community & Youthwork", value: "MSOCSC Community & Youthwork" },
	{ label: "MA Accounting", value: "MA Accounting" },
	{ label: "LLM ( International Justice )", value: "LLM ( International Justice )" },
	{ label: "LLM International Business Law", value: "LLM International Business Law" },
	{ label: "LLM Global Legal Studies", value: "LLM Global Legal Studies" },
	{ label: "MSc Computer Science in Software Engineering", value: "MSc Computer Science in Software Engineering" },
	{ label: "MSc Design Innovation", value: "MSc Design Innovation" },
	{ label: "MSc Economics", value: "MSc Economics" },
	{ label: "MSc Finance", value: "MSc Finance" },
	{ label: "M.Ed. In Adult and Community Education", value: "M.Ed. In Adult and Community Education" },
	{ label: "MA Anthropology", value: "MA Anthropology" },
	{ label: "MA Anthropology (Anthropology And Development)", value: "MA Anthropology (Anthropology And Development)" },
	{ label: "MA Anthropology (Linguistic Anthropology)", value: "MA Anthropology (Linguistic Anthropology)" },
	{ label: "MA Cultural Differences & Transnational Processes(Creole)", value: "MA Cultural Differences & Transnational Processes(Creole)" },
	{ label: "Master of Education", value: "Master of Education" },
	{ label: "Professional Master of Education (Secondary)", value: "Professional Master of Education (Secondary)" },
	{ label: "MA Geography", value: "MA Geography" },
	{ label: "MA International Development", value: "MA International Development" },
	{ label: "Higher Diploma in Mathematics", value: "Higher Diploma in Mathematics" }
];

export default MaynoothCourses;