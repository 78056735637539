const WhitecliffeCourses = [
	{ value: "Certificate in Arts and Design", label: "Certificate in Arts and Design" },
	{ value: "Certificate in Digital Media and Design", label: "Certificate in Digital Media and Design" },
	{ value: "Certificate in Apparel and Fashion Technology", label: "Certificate in Apparel and Fashion Technology" },
	{ value: "Certificate in Apparel and Fashion Technology - Patternmaking", label: "Certificate in Apparel and Fashion Technology - Patternmaking" },
	{ value: "Certificate in IT Essentials", label: "Certificate in IT Essentials" },
	{ value: "Diploma in Web Development and Design", label: "Diploma in Web Development and Design" },
	{ value: "Certificate in Apparel and Fashion Technology", label: "Certificate in Apparel and Fashion Technology" },
	{ value: "Certificate in Apparel and Fashion Technology - Patternmaking", label: "Certificate in Apparel and Fashion Technology - Patternmaking" },
	{ value: "Diploma in Apparel and Fashion Technology", label: "Diploma in Apparel and Fashion Technology" },
	{ value: "Certificate in Information Technology", label: "Certificate in Information Technology" },
	{ value: "Diploma in IT in Technical Support", label: "Diploma in IT in Technical Support" },
	{ value: "Diploma in Web Development and Design", label: "Diploma in Web Development and Design" },
	{ value: "Diploma in Networking", label: "Diploma in Networking" },
	{ value: "Diploma in Software Development", label: "Diploma in Software Development" },
	{ value: "Bachelor of Applied Information Technology", label: "Bachelor of Applied Information Technology" },
	{ value: "Postgraduate Diploma in Information Technology", label: "Postgraduate Diploma in Information Technology" },
	{ value: "Master of Information Technology", label: "Master of Information Technology" },
	{ value: "Certificate in Arts and Design", label: "Certificate in Arts and Design" },
	{ value: "Bachelor of Fine Arts in Fine Arts", label: "Bachelor of Fine Arts in Fine Arts" },
	{ value: "Bachelor of Fine Arts in Photo Media", label: "Bachelor of Fine Arts in Photo Media" },
	{ value: "Bachelor of Fine Arts (Honours)", label: "Bachelor of Fine Arts (Honours)" },
	{ value: "Master of Fine Arts", label: "Master of Fine Arts" },
	{ value: "Certificate in Digital Media and Design", label: "Certificate in Digital Media and Design" },
	{ value: "Bachelor of Fine Arts in Graphic Design", label: "Bachelor of Fine Arts in Graphic Design" },
	{ value: "Bachelor of Design and Digital Media", label: "Bachelor of Design and Digital Media" },
	{ value: "Master of Creative Enterprise and Innovation (MCEI)", label: "Master of Creative Enterprise and Innovation (MCEI)" },
	{ value: "Certificate in Apparel and Fashion Technology", label: "Certificate in Apparel and Fashion Technology" },
	{ value: "Certificate in Apparel and Fashion Technology - Patternmaking", label: "Certificate in Apparel and Fashion Technology - Patternmaking" },
	{ value: "Diploma in Apparel and Fashion Technology", label: "Diploma in Apparel and Fashion Technology" },
	{ value: "Bachelor of Fine Arts in Fashion and Sustainability", label: "Bachelor of Fine Arts in Fashion and Sustainability" },
	{ value: "Bachelor of Sustainable Fashion Design", label: "Bachelor of Sustainable Fashion Design" },
	{ value: "Certificate in Jewellery", label: "Certificate in Jewellery" },
	{ value: "Bachelor of Jewellery Design and Technology", label: "Bachelor of Jewellery Design and Technology" },
	{ value: "Certificate in Information Technology", label: "Certificate in Information Technology" },
	{ value: "Diploma in IT in Technical Support", label: "Diploma in IT in Technical Support" },
	{ value: "Diploma in Web Development and Design", label: "Diploma in Web Development and Design" },
	{ value: "Diploma in Networking", label: "Diploma in Networking" },
	{ value: "Diploma in Software Development", label: "Diploma in Software Development" },
	{ value: "Bachelor of Applied Information Technology", label: "Bachelor of Applied Information Technology" },
	{ value: "Postgraduate Diploma in Information Technology", label: "Postgraduate Diploma in Information Technology" },
	{ value: "Master of Information Technology", label: "Master of Information Technology" },
	{ value: "Postgraduate Diploma in Creative Arts Therapy", label: "Postgraduate Diploma in Creative Arts Therapy" },
	{ value: "Certificate in IT Essentials", label: "Certificate in IT Essentials" },
	{ value: "Certificate in Information Technology", label: "Certificate in Information Technology" },
	{ value: "Diploma in IT in Technical Support", label: "Diploma in IT in Technical Support" },
	{ value: "Diploma in Web Development and Design", label: "Diploma in Web Development and Design" },
	{ value: "Diploma in Networking", label: "Diploma in Networking" },
	{ value: "Diploma in Software Development", label: "Diploma in Software Development" },
	{ value: "Bachelor of Applied Information Technology", label: "Bachelor of Applied Information Technology" },
	{ value: "Postgraduate Diploma in Information Technology", label: "Postgraduate Diploma in Information Technology" },
	{ value: "Master of Information Technology", label: "Master of Information Technology" }
];

export default WhitecliffeCourses;