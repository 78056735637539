const RMITCourses = [
	{ value: "Bachelor of Applied Science (Project Management) (Honours)", label: "Bachelor of Applied Science (Project Management) (Honours)" },
	{ value: "Bachelor of Engineering (Biomedical Engineering) (Honours)", label: "Bachelor of Engineering (Biomedical Engineering) (Honours)" },
	{ value: "Associate Degree in Screen and Media Production", label: "Associate Degree in Screen and Media Production" },
	{ value: "Bachelor of Engineering (Sustainable Systems Engineering) (Honours)/Bachelor of Business (Management)", label: "Bachelor of Engineering (Sustainable Systems Engineering) (Honours)/Bachelor of Business (Management)" },
	{ value: "Diploma of Graphic Design", label: "Diploma of Graphic Design" },
	{ value: "Bachelor of Communication (Advertising)", label: "Bachelor of Communication (Advertising)" },
	{ value: "Bachelor of Engineering (Computer and Network Engineering) (Honours)/Bachelor of Business (Mgt)", label: "Bachelor of Engineering (Computer and Network Engineering) (Honours)/Bachelor of Business (Mgt)" },
	{ value: "Bachelor of Engineering (Adv Manufacturing & Mechatronics)(Hons)/Bachelor of Business(Intern Bus)", label: "Bachelor of Engineering (Adv Manufacturing & Mechatronics)(Hons)/Bachelor of Business(Intern Bus)" },
	{ value: "Associate Degree in Information Technology", label: "Associate Degree in Information Technology" },
	{ value: "Bachelor of Environment and Society", label: "Bachelor of Environment and Society" },
	{ value: "Bachelor of Applied Science (Aviation)/Bachelor of Business (Management)", label: "Bachelor of Applied Science (Aviation)/Bachelor of Business (Management)" },
	{ value: "Bachelor of Pharmaceutical Sciences", label: "Bachelor of Pharmaceutical Sciences" },
	{ value: "Bachelor of Business (Innovation and Enterprise)", label: "Bachelor of Business (Innovation and Enterprise)" },
	{ value: "Bachelor of Business (Information Systems)", label: "Bachelor of Business (Information Systems)" },
	{ value: "Bachelor of Software Engineering", label: "Bachelor of Software Engineering" },
	{ value: "Bachelor of Arts (Music Industry)", label: "Bachelor of Arts (Music Industry)" },
	{ value: "Bachelor of Applied Science (Aviation)", label: "Bachelor of Applied Science (Aviation)" },
	{ value: "Bachelor of Urban and Regional Planning (Honours)", label: "Bachelor of Urban and Regional Planning (Honours)" },
	{ value: "Bachelor of Industrial Design (Honours)", label: "Bachelor of Industrial Design (Honours)" },
	{ value: "Bachelor of Engineering (Computer and Network Engineering) (Honours)/Bachelor of Computer Science", label: "Bachelor of Engineering (Computer and Network Engineering) (Honours)/Bachelor of Computer Science" },
	{ value: "Advanced Diploma of Engineering Technology (Electrical)", label: "Advanced Diploma of Engineering Technology (Electrical)" },
	{ value: "Bachelor of Psychology", label: "Bachelor of Psychology" },
	{ value: "Master of Engineering (International Automotive Engineering)", label: "Master of Engineering (International Automotive Engineering)" },
	{ value: "Master of Advertising", label: "Master of Advertising" },
	{ value: "Bachelor of Engineering (Civil and Infrastructure) (Honours)", label: "Bachelor of Engineering (Civil and Infrastructure) (Honours)" },
	{ value: "Associate Degree in Fashion and Textile Merchandising", label: "Associate Degree in Fashion and Textile Merchandising" },
	{ value: "Master of Engineering (Robotics and Mechatronics Engineering)", label: "Master of Engineering (Robotics and Mechatronics Engineering)" },
	{ value: "Master of Human Resource Management", label: "Master of Human Resource Management" },
	{ value: "Master of Writing and Publishing", label: "Master of Writing and Publishing" },
	{ value: "Bachelor of Architectural Design", label: "Bachelor of Architectural Design" },
	{ value: "Bachelor of Applied Science (Surveying) (Honours)", label: "Bachelor of Applied Science (Surveying) (Honours)" },
	{ value: "Bachelor of Arts (Creative Writing)", label: "Bachelor of Arts (Creative Writing)" },
	{ value: "Bachelor of Engineering (Automotive Engineering)(Honours)/Bachelor of Industrial Design (Honours)", label: "Bachelor of Engineering (Automotive Engineering)(Honours)/Bachelor of Industrial Design (Honours)" },
	{ value: "Bachelor of Engineering (Automotive Engineering) (Honours)", label: "Bachelor of Engineering (Automotive Engineering) (Honours)" },
	{ value: "Bachelor of Arts (Fine Art)", label: "Bachelor of Arts (Fine Art)" },
	{ value: "Bachelor of Applied Science (Construction Management) (Honours)", label: "Bachelor of Applied Science (Construction Management) (Honours)" },
	{ value: "Bachelor of Applied Science (Psychology)", label: "Bachelor of Applied Science (Psychology)" },
	{ value: "Master of Landscape Architecture", label: "Master of Landscape Architecture" },
	{ value: "Bachelor of Science (Biotechnology)/Bachelor of Biomedical Science", label: "Bachelor of Science (Biotechnology)/Bachelor of Biomedical Science" },
	{ value: "Bachelor of Communication (Public Relations)", label: "Bachelor of Communication (Public Relations)" },
	{ value: "Bachelor of Engineering (Electrical Engineering) (Honours)", label: "Bachelor of Engineering (Electrical Engineering) (Honours)" },
	{ value: "Bachelor of Science (Biotechnology)", label: "Bachelor of Science (Biotechnology)" },
	{ value: "Bachelor of Engineering (Electronic and Computer Systems Engineering) (Honours)", label: "Bachelor of Engineering (Electronic and Computer Systems Engineering) (Honours)" },
	{ value: "Master of Marketing", label: "Master of Marketing" },
	{ value: "Bachelor of Environmental Science/Bachelor of Engineering (Environmental Engineering) (Honours)", label: "Bachelor of Environmental Science/Bachelor of Engineering (Environmental Engineering) (Honours)" },
	{ value: "Diploma of Business", label: "Diploma of Business" },
	{ value: "Master of Project Management", label: "Master of Project Management" },
	{ value: "Master of Property", label: "Master of Property" },
	{ value: "Bachelor of Engineering (Chemical Engineering) (Honours)/Bachelor of Pharmaceutical Sciences", label: "Bachelor of Engineering (Chemical Engineering) (Honours)/Bachelor of Pharmaceutical Sciences" },
	{ value: "Bachelor of Business (Financial Planning)", label: "Bachelor of Business (Financial Planning)" },
	{ value: "Master of Arts (Arts in Public Space)", label: "Master of Arts (Arts in Public Space)" },
	{ value: "Master of Interior Design", label: "Master of Interior Design" },
	{ value: "Master of Artificial Intelligence", label: "Master of Artificial Intelligence" },
	{ value: "Bachelor of Business (Business and Technology)", label: "Bachelor of Business (Business and Technology)" },
	{ value: "Master of Engineering (Telecommunication and Network Engineering)", label: "Master of Engineering (Telecommunication and Network Engineering)" },
	{ value: "Master of Laboratory Medicine", label: "Master of Laboratory Medicine" },
	{ value: "Advanced Diploma of Engineering (Mechanical)", label: "Advanced Diploma of Engineering (Mechanical)" },
	{ value: "Bachelor of Engineering (Electrical Engineering) (Honours) / Bachelor of Business (Management)", label: "Bachelor of Engineering (Electrical Engineering) (Honours) / Bachelor of Business (Management)" },
	{ value: "Associate Degree in Aviation (Professional Pilots)", label: "Associate Degree in Aviation (Professional Pilots)" },
	{ value: "Bachelor of Fashion (Enterprise)", label: "Bachelor of Fashion (Enterprise)" },
	{ value: "Bachelor of Engineering (Mechanical Engineering) (Honours)", label: "Bachelor of Engineering (Mechanical Engineering) (Honours)" },
	{ value: "Master of Engineering (Manufacturing)", label: "Master of Engineering (Manufacturing)" },
	{ value: "Bachelor of Environmental Science", label: "Bachelor of Environmental Science" },
	{ value: "Master of Justice and Criminology", label: "Master of Justice and Criminology" },
	{ value: "Master of Professional Accounting", label: "Master of Professional Accounting" },
	{ value: "Master of Photography", label: "Master of Photography" },
	{ value: "Master of Biotechnology", label: "Master of Biotechnology" },
	{ value: "Master of Architecture", label: "Master of Architecture" },
	{ value: "Bachelor of Science (Food Technology and Nutrition)", label: "Bachelor of Science (Food Technology and Nutrition)" },
	{ value: "Bachelor of Landscape Architectural Design", label: "Bachelor of Landscape Architectural Design" },
	{ value: "Bachelor of Business (Human Resource Management)", label: "Bachelor of Business (Human Resource Management)" },
	{ value: "Bachelor of Design (Digital Media)", label: "Bachelor of Design (Digital Media)" },
	{ value: "Bachelor of Engineering (Chemical Engineering) (Honours)/Bachelor of Business (Management)", label: "Bachelor of Engineering (Chemical Engineering) (Honours)/Bachelor of Business (Management)" },
	{ value: "Associate Degree in Fashion Design and Technology", label: "Associate Degree in Fashion Design and Technology" },
	{ value: "Bachelor of Fashion (Design)", label: "Bachelor of Fashion (Design)" },
	{ value: "Master of Food Science and Technology", label: "Master of Food Science and Technology" },
	{ value: "Master of Animation, Games and Interactivity", label: "Master of Animation, Games and Interactivity" },
	{ value: "Bachelor of Engineering (Computer and Network Engineering) (Honours)", label: "Bachelor of Engineering (Computer and Network Engineering) (Honours)" },
	{ value: "Bachelor of Business (Management and Change)", label: "Bachelor of Business (Management and Change)" },
	{ value: "Master of Engineering (Sustainable Energy)", label: "Master of Engineering (Sustainable Energy)" },
	{ value: "Bachelor of Accounting", label: "Bachelor of Accounting" },
	{ value: "Master of Engineering (Mechanical Engineering)", label: "Master of Engineering (Mechanical Engineering)" },
	{ value: "Master of Commerce", label: "Master of Commerce" },
	{ value: "Master of Translating and Interpreting", label: "Master of Translating and Interpreting" },
	{ value: "Bachelor of Design (Animation and Interactive Media)", label: "Bachelor of Design (Animation and Interactive Media)" },
	{ value: "Master of Arts (Arts Management)", label: "Master of Arts (Arts Management)" },
	{ value: "Master of Engineering (Electronic Engineering)", label: "Master of Engineering (Electronic Engineering)" },
	{ value: "Bachelor of Engineering (Automotive Engineering) (Honours)/Bachelor of Industrial Design (Honours)", label: "Bachelor of Engineering (Automotive Engineering) (Honours)/Bachelor of Industrial Design (Honours)" },
	{ value: "Bachelor of Engineering (Adv Manufacturing and Mechatronics) (Honours)", label: "Bachelor of Engineering (Adv Manufacturing and Mechatronics) (Honours)" },
	{ value: "Bachelor of Business – Finance major", label: "Bachelor of Business – Finance major" },
	{ value: "Bachelor of Business (Global Business)", label: "Bachelor of Business (Global Business)" },
	{ value: "Master of Business Administration", label: "Master of Business Administration" },
	{ value: "Master of Fine Art", label: "Master of Fine Art" },
	{ value: "Master of Public Policy", label: "Master of Public Policy" },
	{ value: "Master of Supply Chain and Logistics Management", label: "Master of Supply Chain and Logistics Management" },
	{ value: "Master of Analytics", label: "Master of Analytics" },
	{ value: "Master of Data Science", label: "Master of Data Science" },
	{ value: "Bachelor of Communication (Media)", label: "Bachelor of Communication (Media)" },
	{ value: "Bachelor of Criminology and Psychology", label: "Bachelor of Criminology and Psychology" },
	{ value: "Bachelor of Engineering (Environmental Engineering) (Honours)", label: "Bachelor of Engineering (Environmental Engineering) (Honours)" },
	{ value: "Advanced Diploma of Electronics and Communication Engineering", label: "Advanced Diploma of Electronics and Communication Engineering" },
	{ value: "Bachelor of Business (Management)", label: "Bachelor of Business (Management)" },
	{ value: "Bachelor of Business (Business Information Systems)", label: "Bachelor of Business (Business Information Systems)" },
	{ value: "Bachelor of Interior Design (Honours)", label: "Bachelor of Interior Design (Honours)" },
	{ value: "Master of Science (Laboratory and Clinical Science)", label: "Master of Science (Laboratory and Clinical Science)" },
	{ value: "Bachelor of Engineering (Aerospace Engineering) (Honours)/Bachelor of Business", label: "Bachelor of Engineering (Aerospace Engineering) (Honours)/Bachelor of Business" },
	{ value: "Master of Business Information Technology", label: "Master of Business Information Technology" },
	{ value: "Bachelor of Applied Science(Surveying) (Honours)", label: "Bachelor of Applied Science(Surveying) (Honours)" },
	{ value: "Diploma of Information Technology", label: "Diploma of Information Technology" },
	{ value: "Master of Design Innovation and Technology", label: "Master of Design Innovation and Technology" },
	{ value: "Master of Media", label: "Master of Media" },
	{ value: "Master of Cyber Security", label: "Master of Cyber Security" },
	{ value: "Bachelor of Engineering (Electronic and Computer Systems Engineering)(Honours)/Bachelor of Business", label: "Bachelor of Engineering (Electronic and Computer Systems Engineering)(Honours)/Bachelor of Business" },
	{ value: "Bachelor of Business (Entrepreneurship)", label: "Bachelor of Business (Entrepreneurship)" },
	{ value: "Bachelor of Legal and Dispute Studies", label: "Bachelor of Legal and Dispute Studies" },
	{ value: "Advanced Diploma of Engineering Technology (Civil Engineering Design)", label: "Advanced Diploma of Engineering Technology (Civil Engineering Design)" },
	{ value: "Bachelor of Information Technology", label: "Bachelor of Information Technology" },
	{ value: "Bachelor of Engineering (Civil and Infrastructure) (Honours)/Bachelor of Business", label: "Bachelor of Engineering (Civil and Infrastructure) (Honours)/Bachelor of Business" },
	{ value: "Master of Communication", label: "Master of Communication" },
	{ value: "Bachelor of Science", label: "Bachelor of Science" },
	{ value: "Master of Engineering (Management)", label: "Master of Engineering (Management)" },
	{ value: "Bachelor of Social Work (Honours)", label: "Bachelor of Social Work (Honours)" },
	{ value: "Bachelor of Engineering (Chemical Engineering) (Honours)/Bachelor of Science (Biotechnology)", label: "Bachelor of Engineering (Chemical Engineering) (Honours)/Bachelor of Science (Biotechnology)" },
	{ value: "Bachelor of Design (Games)", label: "Bachelor of Design (Games)" },
	{ value: "Bachelor of Engineering (Sustainable Systems Engineering) (Honours)", label: "Bachelor of Engineering (Sustainable Systems Engineering) (Honours)" },
	{ value: "Associate Degree in Engineering Technology", label: "Associate Degree in Engineering Technology" },
	{ value: "Bachelor of Business (Financial Planning)/ Bachelor of Business (Accountancy)", label: "Bachelor of Business (Financial Planning)/ Bachelor of Business (Accountancy)" },
	{ value: "Bachelor of Biomedical Science", label: "Bachelor of Biomedical Science" },
	{ value: "Bachelor of Engineering (Mechanical Engineering) (Honours)/Bachelor of Business", label: "Bachelor of Engineering (Mechanical Engineering) (Honours)/Bachelor of Business" },
	{ value: "Bachelor of Engineering (Aerospace Engineering) (Honours)", label: "Bachelor of Engineering (Aerospace Engineering) (Honours)" },
	{ value: "Foundation Studies", label: "Foundation Studies" },
	{ value: "Bachelor of Arts (Photography)", label: "Bachelor of Arts (Photography)" },
	{ value: "Bachelor of Engineering(Chemical Engineering)(Honours)/Bachelor of Pharmaceutical Sciences", label: "Bachelor of Engineering(Chemical Engineering)(Honours)/Bachelor of Pharmaceutical Sciences" },
	{ value: "Bachelor of Fashion and Textiles (Sustainable Innovation)", label: "Bachelor of Fashion and Textiles (Sustainable Innovation)" },
	{ value: "Master of Energy Efficient and Sustainable Building", label: "Master of Energy Efficient and Sustainable Building" },
	{ value: "Master of Engineering (Electrical and Electronic Engineering)", label: "Master of Engineering (Electrical and Electronic Engineering)" },
	{ value: "Master of Communication Design", label: "Master of Communication Design" },
	{ value: "Master of Urban Planning and Environment", label: "Master of Urban Planning and Environment" },
	{ value: "Bachelor of Computer Science", label: "Bachelor of Computer Science" },
	{ value: "Bachelor of Business (Blockchain Enabled Business)", label: "Bachelor of Business (Blockchain Enabled Business)" },
	{ value: "Bachelor of Youth Work and Youth Studies", label: "Bachelor of Youth Work and Youth Studies" },
	{ value: "Master of Engineering (Civil Engineering)", label: "Master of Engineering (Civil Engineering)" },
	{ value: "Master of International Business", label: "Master of International Business" },
	{ value: "Master of Fashion (Entrepreneurship)", label: "Master of Fashion (Entrepreneurship)" },
	{ value: "Bachelor of Business (Logistics and Supply Chain Management)", label: "Bachelor of Business (Logistics and Supply Chain Management)" },
	{ value: "English for Academic Purposes", label: "English for Academic Purposes" },
	{ value: "Associate Degree in Health Sciences", label: "Associate Degree in Health Sciences" },
	{ value: "Advanced Diploma of Computer Systems Engineering", label: "Advanced Diploma of Computer Systems Engineering" },
	{ value: "Bachelor of Nursing", label: "Bachelor of Nursing" },
	{ value: "Master of Science (Aviation)", label: "Master of Science (Aviation)" },
	{ value: "Master of Finance", label: "Master of Finance" },
	{ value: "Bachelor of Food Technology and Nutrition", label: "Bachelor of Food Technology and Nutrition" },
	{ value: "Diploma of Nursing", label: "Diploma of Nursing" },
	{ value: "Master of Engineering (Environmental Engineering)", label: "Master of Engineering (Environmental Engineering)" },
	{ value: "Master of Engineering (Electrical Engineering)", label: "Master of Engineering (Electrical Engineering)" },
	{ value: "Master of Engineering (Aerospace)", label: "Master of Engineering (Aerospace)" },
	{ value: "Master of Statistics and Operations Research", label: "Master of Statistics and Operations Research" },
	{ value: "Bachelor of Communication (Journalism)", label: "Bachelor of Communication (Journalism)" },
	{ value: "Bachelor of Social Science (Psychology)", label: "Bachelor of Social Science (Psychology)" },
	{ value: "Bachelor of Criminal Justice", label: "Bachelor of Criminal Justice" },
	{ value: "Bachelor of Engineering (Sustainable Systems Eng)(Honours)/Bachelor of Industrial Design (Honours)", label: "Bachelor of Engineering (Sustainable Systems Eng)(Honours)/Bachelor of Industrial Design (Honours)" },
	{ value: "Diploma of Building and Construction (Building)", label: "Diploma of Building and Construction (Building)" },
	{ value: "Master of Geospatial Science", label: "Master of Geospatial Science" },
	{ value: "Master of Information Technology", label: "Master of Information Technology" },
	{ value: "Bachelor of Business – Economics major", label: "Bachelor of Business – Economics major" },
	{ value: "Bachelor of Engineering (Chemical Engineering) (Honours) / Bachelor of Pharmaceutical Sciences", label: "Bachelor of Engineering (Chemical Engineering) (Honours) / Bachelor of Pharmaceutical Sciences" },
	{ value: "Bachelor of Applied Science (Property and Valuation) (Honours)", label: "Bachelor of Applied Science (Property and Valuation) (Honours)" },
	{ value: "Bachelor of Design (Communication Design)", label: "Bachelor of Design (Communication Design)" },
	{ value: "Bachelor of Communication (Professional Communication)", label: "Bachelor of Communication (Professional Communication)" },
	{ value: "Bachelor of Business (Marketing)", label: "Bachelor of Business (Marketing)" },
	{ value: "Bachelor of Engineering (Chemical Engineering) (Honours)", label: "Bachelor of Engineering (Chemical Engineering) (Honours)" }
];

export default RMITCourses;