const TrinityDublinCourses = [
	{ label: "BA Management science and information systems studies (MSISS)", value: "BA Management science and information systems studies (MSISS)" },
	{ label: "BSc Engineering with Management", value: "BSc Engineering with Management" },
	{ label: "B.A.I. Engineering", value: "B.A.I. Engineering" },
	{ label: "BSc Midwifery", value: "BSc Midwifery" },
	{ label: "BSc Occupational Therapy", value: "BSc Occupational Therapy" },
	{ label: "BSc Pharmacy", value: "BSc Pharmacy" },
	{ label: "BSc Physiotherapy", value: "BSc Physiotherapy" },
	{ label: "BA Ancient and medieval history and culture", value: "BA Ancient and medieval history and culture" },
	{ label: "BA Philosophy, political science, economics and sociology", value: "BA Philosophy, political science, economics and sociology" },
	{ label: "BA Music", value: "BA Music" },
	{ label: "LLB. Law", value: "LLB. Law" },
	{ label: "BBS Global Business (Bachelor in Business Studies)", value: "BBS Global Business (Bachelor in Business Studies)" },
	{ label: "B.A. Geography and Geoscience", value: "B.A. Geography and Geoscience" },
	{ label: "B.A. Mathematics", value: "B.A. Mathematics" },
	{ label: "BSc. Human Health and Disease", value: "BSc. Human Health and Disease" },
	{ label: "BSc. Radiation Therapy", value: "BSc. Radiation Therapy" },
	{ label: "BA Drama and theatre studies", value: "BA Drama and theatre studies" },
	{ label: "BA History", value: "BA History" },
	{ label: "B. Mus. Ed. Music education", value: "B. Mus. Ed. Music education" },
	{ label: "BA Philosophy", value: "BA Philosophy" },
	{ label: "BA Business, Economics and Social Studies (B.E.S.S.)", value: "BA Business, Economics and Social Studies (B.E.S.S.)" },
	{ label: "Business Studies and French", value: "Business Studies and French" },
	{ label: "Business Studies and German", value: "Business Studies and German" },
	{ label: "Business Studies and Russian", value: "Business Studies and Russian" },
	{ label: "Business Studies and Polish", value: "Business Studies and Polish" },
	{ label: "Business Studies and Spanish", value: "Business Studies and Spanish" },
	{ label: "BA Computer science Linguistics and language", value: "BA Computer science Linguistics and language" },
	{ label: "BA Classics, Ancient History and Archaeology", value: "BA Classics, Ancient History and Archaeology" },
	{ label: "BA Ancient History and Archaeology(Joint Honours)", value: "BA Ancient History and Archaeology(Joint Honours)" },
	{ label: "BA Classical Civilisation(Joint Honours)", value: "BA Classical Civilisation(Joint Honours)" },
	{ label: "BA Classical Languages(Joint Honours)", value: "BA Classical Languages(Joint Honours)" },
	{ label: "BSc Clinical Speech and Language Studies", value: "BSc Clinical Speech and Language Studies" },
	{ label: "Bachelor in Deaf Studies", value: "Bachelor in Deaf Studies" },
	{ label: "BA Drama Studies (JH)", value: "BA Drama Studies (JH)" },
	{ label: "BA English Studies", value: "BA English Studies" },
	{ label: "English literature (Joint Honors)", value: "English literature (Joint Honors)" },
	{ label: "BA European Studies", value: "BA European Studies" },
	{ label: "BA Film", value: "BA Film" },
	{ label: "BA Film Studies (Joint Honours)", value: "BA Film Studies (Joint Honours)" },
	{ label: "B.A. Geography (Joint Honours)", value: "B.A. Geography (Joint Honours)" },
	{ label: "BA History (Joint Honours)", value: "BA History (Joint Honours)" },
	{ label: "BA History of art and architecture (Joint Honours)", value: "BA History of art and architecture (Joint Honours)" },
	{ label: "BA History of art and architecture", value: "BA History of art and architecture" },
	{ label: "BA Mathematics (Joint Honours)", value: "BA Mathematics (Joint Honours)" },
	{ label: "BA Music (Joint Honours)", value: "BA Music (Joint Honours)" },
	{ label: "BA Philosophy (Joint Honours)", value: "BA Philosophy (Joint Honours)" },
	{ label: "BA Psychology", value: "BA Psychology" },
	{ label: "BA Social policy(Joint Honours)", value: "BA Social policy(Joint Honours)" },
	{ label: "BA Sociology (Joint Honours)", value: "BA Sociology (Joint Honours)" },
	{ label: "BA Biochemistry: Biological and Biomedical Sciences", value: "BA Biochemistry: Biological and Biomedical Sciences" },
	{ label: "BA Botany: Biological and Biomedical Sciences", value: "BA Botany: Biological and Biomedical Sciences" },
	{ label: "BA Environmental Sciences: Biological and Biomedical Sciences", value: "BA Environmental Sciences: Biological and Biomedical Sciences" },
	{ label: "BA Genetics: Biological and Biomedical Sciences", value: "BA Genetics: Biological and Biomedical Sciences" },
	{ label: "BA Immunology: Biological and Biomedical Sciences", value: "BA Immunology: Biological and Biomedical Sciences" },
	{ label: "BA Microbiology: Biological and Biomedical Sciences", value: "BA Microbiology: Biological and Biomedical Sciences" },
	{ label: "BA Molecular Medicine: Biological and Biomedical Sciences", value: "BA Molecular Medicine: Biological and Biomedical Sciences" },
	{ label: "BA Neuroscience: Biological and Biomedical Sciences", value: "BA Neuroscience: Biological and Biomedical Sciences" },
	{ label: "BA Physiology: Biological and Biomedical Sciences", value: "BA Physiology: Biological and Biomedical Sciences" },
	{ label: "BA Zoology: Biological and Biomedical Sciences", value: "BA Zoology: Biological and Biomedical Sciences" },
	{ label: "BA Chemistry: Chemical Sciences", value: "BA Chemistry: Chemical Sciences" },
	{ label: "BA Nanoscience: Chemical Sciences", value: "BA Nanoscience: Chemical Sciences" },
	{ label: "BA Chemistry With Biosciences: Chemical Sciences", value: "BA Chemistry With Biosciences: Chemical Sciences" },
	{ label: "BA Medicinal Chemistry: Chemical Sciences", value: "BA Medicinal Chemistry: Chemical Sciences" },
	{ label: "BA Physics: Physical Sciences", value: "BA Physics: Physical Sciences" },
	{ label: "BA Physics and Astrophysics: Physical Sciences", value: "BA Physics and Astrophysics: Physical Sciences" },
	{ label: "BA Physical Sciences", value: "BA Physical Sciences" },
	{ label: "BA Nanoscience: Physical Sciences", value: "BA Nanoscience: Physical Sciences" },
	{ label: "BA Theoretical physics", value: "BA Theoretical physics" },
	{ label: "BA Biological and Biomedical Sciences", value: "BA Biological and Biomedical Sciences" },
	{ label: "Biomedical engineering", value: "Biomedical engineering" },
	{ label: "BA Chemical Sciences", value: "BA Chemical Sciences" },
	{ label: "BA Chemistry with molecular modelling: Chemical Sciences", value: "BA Chemistry with molecular modelling: Chemical Sciences" },
	{ label: "Civil, structural, and environmental engineering", value: "Civil, structural, and environmental engineering" },
	{ label: "Computer engineering", value: "Computer engineering" },
	{ label: "BA Computer Science", value: "BA Computer Science" },
	{ label: "Computer Science (Joint Honours)", value: "Computer Science (Joint Honours)" },
	{ label: "BA Economics (Joint Honours)", value: "BA Economics (Joint Honours)" },
	{ label: "Electronic and Computer Engineering (joint programme)", value: "Electronic and Computer Engineering (joint programme)" },
	{ label: "Electronic engineering", value: "Electronic engineering" },
	{ label: "Environmental Science and Engineering", value: "Environmental Science and Engineering" },
	{ label: "Human Genetics: Biological and Biomedical Sciences", value: "Human Genetics: Biological and Biomedical Sciences" },
	{ label: "LLB Law and French", value: "LLB Law and French" },
	{ label: "LLB Law and German", value: "LLB Law and German" },
	{ label: "Mechanical and manufacturing engineering", value: "Mechanical and manufacturing engineering" },
	{ label: "BA Political Science (Joint Honours)", value: "BA Political Science (Joint Honours)" },
	{ label: "B.S.S. Social Studies", value: "B.S.S. Social Studies" },
	{ label: "B.Dent.Sc. Dental Science", value: "B.Dent.Sc. Dental Science" },
	{ label: "M.B. Medicine", value: "M.B. Medicine" },
	{ label: "B.A. Science", value: "B.A. Science" },
	{ label: "M.Sc. Applied Clinical Neuropsychology", value: "M.Sc. Applied Clinical Neuropsychology" },
	{ label: "Applied Intercultural Communications (M.Phil)", value: "Applied Intercultural Communications (M.Phil)" },
	{ label: "M.Phil. in Applied Linguistics", value: "M.Phil. in Applied Linguistics" },
	{ label: "M.Phil. in Comparative Literature", value: "M.Phil. in Comparative Literature" },
	{ label: "M.Phil. in Conflict Resolution and Reconciliation", value: "M.Phil. in Conflict Resolution and Reconciliation" },
	{ label: "Contextual Theologies and Interfaith Relations (M.Phil)", value: "Contextual Theologies and Interfaith Relations (M.Phil)" },
	{ label: "MSc Global Mental Health", value: "MSc Global Mental Health" },
	{ label: "History of Art and Architecture (M.Phil. / P.Grad.Dip.)", value: "History of Art and Architecture (M.Phil. / P.Grad.Dip.)" },
	{ label: "Healthcare Innovation (P.Grad.Dip)", value: "Healthcare Innovation (P.Grad.Dip)" },
	{ label: "Linguistics (M.Phil. / P.Grad.Dip.)", value: "Linguistics (M.Phil. / P.Grad.Dip.)" },
	{ label: "Mental Health (M.Sc. / P.Grad.Dip.)", value: "Mental Health (M.Sc. / P.Grad.Dip.)" },
	{ label: "Professional Masters Education (P.M.E.)", value: "Professional Masters Education (P.M.E.)" },
	{ label: "Quantum Science and Technology (M.Sc. / P.Grad.Dip)", value: "Quantum Science and Technology (M.Sc. / P.Grad.Dip)" },
	{ label: "Zero-Carbon Technology (M.Sc.)", value: "Zero-Carbon Technology (M.Sc.)" },
	{ label: "Modern and Contemporary Literary Studies (M.Phil. / P.Grad.Dip.)", value: "Modern and Contemporary Literary Studies (M.Phil. / P.Grad.Dip.)" },
	{ label: "Nursing (M.Sc.)", value: "Nursing (M.Sc.)" },
	{ label: "Statistics and Sustainability (M.Sc.)", value: "Statistics and Sustainability (M.Sc.)" },
	{ label: "MSc Health Policy and Management", value: "MSc Health Policy and Management" },
	{ label: "MSc. Ageing Health & Wellbeing in Intellectual Disability", value: "MSc. Ageing Health & Wellbeing in Intellectual Disability" },
	{ label: "Classics (M.Phil. / P.Grad.Dip.)", value: "Classics (M.Phil. / P.Grad.Dip.)" },
	{ label: "MSc.Clinical Speech and Language Studies", value: "MSc.Clinical Speech and Language Studies" },
	{ label: "M.Sc. Community Health", value: "M.Sc. Community Health" },
	{ label: "M.Sc Dementia", value: "M.Sc Dementia" },
	{ label: "English Language Teaching (M.Phil. / P.Grad.Dip.)", value: "English Language Teaching (M.Phil. / P.Grad.Dip.)" },
	{ label: "Environmental History (M.Phil. / P.Grad.Dip.)", value: "Environmental History (M.Phil. / P.Grad.Dip.)" },
	{ label: "Msc in Healthcare Infection", value: "Msc in Healthcare Infection" },
	{ label: "LL.M. Intellectual Property and Information Technology Law", value: "LL.M. Intellectual Property and Information Technology Law" },
	{ label: "LL.M. International and Comparative Law", value: "LL.M. International and Comparative Law" },
	{ label: "LL.M. International and European Business Law", value: "LL.M. International and European Business Law" },
	{ label: "Medieval Studies (M.Phil. / P.Grad.Dip.)", value: "Medieval Studies (M.Phil. / P.Grad.Dip.)" },
	{ label: "M.Sc. Midwifery Practice and Leadership", value: "M.Sc. Midwifery Practice and Leadership" },
	{ label: "M.Phil. Psychoanalytic Studies", value: "M.Phil. Psychoanalytic Studies" },
	{ label: "M.F.A. Stage Design", value: "M.F.A. Stage Design" },
	{ label: "M.F.A. Playwriting", value: "M.F.A. Playwriting" },
	{ label: "M.Phil. Theatre and Performance", value: "M.Phil. Theatre and Performance" },
	{ label: "M.F.A. Theatre Directing", value: "M.F.A. Theatre Directing" },
	{ label: "Postgraduate Diploma in Accounting", value: "Postgraduate Diploma in Accounting" },
	{ label: "MSc in Diagnostic Radiography", value: "MSc in Diagnostic Radiography" },
	{ label: "MSc in Entrepreneurship of Smart Medicine", value: "MSc in Entrepreneurship of Smart Medicine" },
	{ label: "MSc Smart and Sustainable Cities", value: "MSc Smart and Sustainable Cities" },
	{ label: "MSc Economics - International Development", value: "MSc Economics - International Development" },
	{ label: "Masters in Speech and Language Processing (M.Phil.)", value: "Masters in Speech and Language Processing (M.Phil.)" },
	{ label: "MSc Applied Social Research", value: "MSc Applied Social Research" },
	{ label: "Engineering (Environmental) (MSc/P.Grad.Dip)", value: "Engineering (Environmental) (MSc/P.Grad.Dip)" },
	{ label: "Engineering (Structural and Geotechnical) (MSc/P.Grad.Dip)", value: "Engineering (Structural and Geotechnical) (MSc/P.Grad.Dip)" },
	{ label: "Engineering (Transportation) (MSc/P.Grad.Dip)", value: "Engineering (Transportation) (MSc/P.Grad.Dip)" },
	{ label: "Engineering (Sustainable Energy) (MSc/P.Grad.Dip)", value: "Engineering (Sustainable Energy) (MSc/P.Grad.Dip)" },
	{ label: "M.Phil. in Digital Humanities and Culture", value: "M.Phil. in Digital Humanities and Culture" },
	{ label: "MSc in Business Analytics", value: "MSc in Business Analytics" },
	{ label: "Psychology (H.Dip.) - Psychology Conversion Course", value: "Psychology (H.Dip.) - Psychology Conversion Course" },
	{ label: "Immunotherapeutics (M.Sc./ P.Grad.Dip)", value: "Immunotherapeutics (M.Sc./ P.Grad.Dip)" },
	{ label: "Biomedical Engineering (M.Sc. / P.Grad.Dip.)", value: "Biomedical Engineering (M.Sc. / P.Grad.Dip.)" },
	{ label: "M.Sc. Law & Finance", value: "M.Sc. Law & Finance" },
	{ label: "M.Sc. in Quantum Fields, Strings and Gravity (Theoretical Physics)", value: "M.Sc. in Quantum Fields, Strings and Gravity (Theoretical Physics)" },
	{ label: "MSc Genomic Medicine", value: "MSc Genomic Medicine" },
	{ label: "MSc Cancer Survivorship", value: "MSc Cancer Survivorship" },
	{ label: "M.Phil. in Film Studies - Theory History Practice", value: "M.Phil. in Film Studies - Theory History Practice" },
	{ label: "M.Phil. in Film Studies - Screenwriting", value: "M.Phil. in Film Studies - Screenwriting" },
	{ label: "MSc Addiction Recovery", value: "MSc Addiction Recovery" },
	{ label: "MSc in International Politics", value: "MSc in International Politics" },
	{ label: "MPhil in Race, Ethnicity, Conflict", value: "MPhil in Race, Ethnicity, Conflict" },
	{ label: "Laws (LL.M)", value: "Laws (LL.M)" },
	{ label: "MSc Digital Marketing Strategy", value: "MSc Digital Marketing Strategy" },
	{ label: "MSc Entrepreneurship and Innovation", value: "MSc Entrepreneurship and Innovation" },
	{ label: "MSc Finance", value: "MSc Finance" },
	{ label: "MSc Financial Risk Management", value: "MSc Financial Risk Management" },
	{ label: "MSc Human Resource Management", value: "MSc Human Resource Management" },
	{ label: "MSc International Management", value: "MSc International Management" },
	{ label: "MSc Management", value: "MSc Management" },
	{ label: "MSc Marketing", value: "MSc Marketing" },
	{ label: "MBA", value: "MBA" },
	{ label: "MSc Applied Behaviour Analysis", value: "MSc Applied Behaviour Analysis" },
	{ label: "MSc Applied Psychology", value: "MSc Applied Psychology" },
	{ label: "MSc Immunology", value: "MSc Immunology" },
	{ label: "MSc Computer Science - Augmented and Virtual Reality", value: "MSc Computer Science - Augmented and Virtual Reality" },
	{ label: "MSc Computer Science - Data Science", value: "MSc Computer Science - Data Science" },
	{ label: "MSc Computer Science - Future Networked Systems", value: "MSc Computer Science - Future Networked Systems" },
	{ label: "MSc Computer Science - Intelligent Systems", value: "MSc Computer Science - Intelligent Systems" },
	{ label: "MSc Energy Science", value: "MSc Energy Science" },
	{ label: "MSc High Performance Computing", value: "MSc High Performance Computing" },
	{ label: "MSc Biodiversity and Conservation", value: "MSc Biodiversity and Conservation" },
	{ label: "MSc Development Practice", value: "MSc Development Practice" },
	{ label: "MSc Environmental Sciences", value: "MSc Environmental Sciences" },
	{ label: "MSc Global Health", value: "MSc Global Health" },
	{ label: "MSc Sports and Exercise Medicine", value: "MSc Sports and Exercise Medicine" },
	{ label: "MSc Neuroscience", value: "MSc Neuroscience" },
	{ label: "MSc Economics", value: "MSc Economics" },
	{ label: "M.Ed. Master in Education (M.Ed.)", value: "M.Ed. Master in Education (M.Ed.)" },
	{ label: "Childrens Literature (M.Phil. / P.Grad.Dip.)", value: "Childrens Literature (M.Phil. / P.Grad.Dip.)" },
	{ label: "Creative Writing (M.Phil.)", value: "Creative Writing (M.Phil.)" },
	{ label: "Early Modern History (M.Phil. / P.Grad.Dip.)", value: "Early Modern History (M.Phil. / P.Grad.Dip.)" },
	{ label: "Gender and Women's Studies (M.Phil. / P.Grad.Dip.)", value: "Gender and Women's Studies (M.Phil. / P.Grad.Dip.)" },
	{ label: "International History (M.Phil. / P.Grad.Dip.)", value: "International History (M.Phil. / P.Grad.Dip.)" },
	{ label: "Public History and Cultural Heritage (M.Phil. / P.Grad.Dip.)", value: "Public History and Cultural Heritage (M.Phil. / P.Grad.Dip.)" },
	{ label: "Master in Philosophy", value: "Master in Philosophy" },
	{ label: "Social Work (M.S.W. / P.Grad.Dip.)", value: "Social Work (M.S.W. / P.Grad.Dip.)" },
	{ label: "Electronic Information Engineering (M.Sc. / P.Grad.Dip.)", value: "Electronic Information Engineering (M.Sc. / P.Grad.Dip.)" },
	{ label: "Mechanical Engineering (M.Sc.)", value: "Mechanical Engineering (M.Sc.)" },
	{ label: "Music and Media Technologies (M.Phil.)", value: "Music and Media Technologies (M.Phil.)" },
	{ label: "Translational Oncology (M.Sc.)", value: "Translational Oncology (M.Sc.)" },
	{ label: "Pharmaceutical Sciences (M.Sc. / P.Grad. Dip.)", value: "Pharmaceutical Sciences (M.Sc. / P.Grad. Dip.)" },
	{ label: "MSc in Operations and Supply Chain Management", value: "MSc in Operations and Supply Chain Management" },
	{ label: "MSc Molecular Medicine", value: "MSc Molecular Medicine" },
	{ label: "MSc Interactive Digital Media", value: "MSc Interactive Digital Media" }
];

export default TrinityDublinCourses;