const UKCourses = [
	{ value: '(KAPLAN) University of Nottingham International College', label: '(KAPLAN) University of Nottingham International College' },
	{ value: '(STUDY GROUP) The University of Sheffield International College', label: '(STUDY GROUP) The University of Sheffield International College' },
	{ value: 'University of Leeds', label: 'University of Leeds' },
	{ value: '(INTO) Newcastle University', label: '(INTO) Newcastle University' },
	{ value: '(KAPLAN) University of Glasgow International College', label: '(KAPLAN) University of Glasgow International College' },
	{ value: 'University of Nottingham', label: 'University of Nottingham' },
	{ value: '(KAPLAN) Queen Mary University of London', label: '(KAPLAN) Queen Mary University of London' },
	{ value: 'University of Bristol', label: 'University of Bristol' },
	{ value: 'The University of Sheffield', label: 'The University of Sheffield' },
	{ value: 'University of Glasgow', label: 'University of Glasgow' },
	{ value: 'Newcastle University', label: 'Newcastle University' },
	{ value: '(KAPLAN) University of York', label: '(KAPLAN) University of York' },
	{ value: '(KAPLAN) University of Liverpool International College', label: '(KAPLAN) University of Liverpool International College' },
	{ value: 'University of Exeter', label: 'University of Exeter' },
	{ value: 'Queen Mary University of London', label: 'Queen Mary University of London' },
	{ value: '(STUDY GROUP) Cardiff University International Study Centre', label: '(STUDY GROUP) Cardiff University International Study Centre' },
	{ value: '(ONCAMPUS) Queen Mary University of London', label: '(ONCAMPUS) Queen Mary University of London' },
	{ value: 'University of Liverpool', label: 'University of Liverpool' },
	{ value: '(STUDY GROUP) University of Sussex, Falmer', label: '(STUDY GROUP) University of Sussex, Falmer' },
	{ value: '(NAVITAS) University of Leicester Global Study Centre', label: '(NAVITAS) University of Leicester Global Study Centre' },
	{ value: 'University of York', label: 'University of York' },
	{ value: '(INTO) Lancaster University', label: '(INTO) Lancaster University' },
	{ value: '(STUDY GROUP) University of Surrey International Study Centre', label: '(STUDY GROUP) University of Surrey International Study Centre' },
	{ value: '(INTO) University of East of Anglia', label: '(INTO) University of East of Anglia' },
	{ value: '(INTO) Queens University Belfast', label: '(INTO) Queens University Belfast' },
	{ value: '(STUDY GROUP) University of Aberdeen International Study Centre', label: '(STUDY GROUP) University of Aberdeen International Study Centre' },
	{ value: '(NAVITAS) University Academy ', label: '(NAVITAS) University Academy ' },
	{ value: '(STUDY GROUP) Durham University, Durham', label: '(STUDY GROUP) Durham University, Durham' },
	{ value: 'University of Surrey', label: 'University of Surrey' },
	{ value: 'University of Leicester', label: 'University of Leicester' },
	{ value: '(ONCAMPUS) University of Reading', label: '(ONCAMPUS) University of Reading' },
	{ value: 'Lancaster University', label: 'Lancaster University' },
	{ value: '(INTO) Manchester', label: '(INTO) Manchester' },
	{ value: 'University of Sussex', label: 'University of Sussex' },
	{ value: 'Queens University Belfast', label: 'Queens University Belfast' },
	{ value: 'University of Bath', label: 'University of Bath' },
	{ value: '(ONCAMPUS) University of Southampton', label: '(ONCAMPUS) University of Southampton' },
	{ value: 'University of East Anglia', label: 'University of East Anglia' },
	{ value: 'University of Southampton (Winchester)', label: 'University of Southampton (Winchester)' },
	{ value: '(OXFORD INTERNATIONAL EDUCATION GROUP) International College Dundee', label: '(OXFORD INTERNATIONAL EDUCATION GROUP) International College Dundee' },
	{ value: 'University of Aberdeen', label: 'University of Aberdeen' },
	{ value: 'Durham University', label: 'Durham University' },
	{ value: 'Loughborough University, London', label: 'Loughborough University, London' },
	{ value: 'Loughborough University', label: 'Loughborough University' },
	{ value: 'University of Strathclyde', label: 'University of Strathclyde' },
	{ value: '(ONCAMPUS) Loughborough University', label: '(ONCAMPUS) Loughborough University' },
	{ value: '(STUDY GROUP) University of Strathclyde International Study Centre', label: '(STUDY GROUP) University of Strathclyde International Study Centre' },
	{ value: '(NAVITAS) London Brunel International College', label: '(NAVITAS) London Brunel International College' },
	{ value: '(KAPLAN) University of Bristol', label: '(KAPLAN) University of Bristol' },
	{ value: '(OXFORD INTERNATIONAL EDUCATION GROUP) University of Kent International College', label: '(OXFORD INTERNATIONAL EDUCATION GROUP) University of Kent International College' },
	{ value: '(KAPLAN) University of Essex International College', label: '(KAPLAN) University of Essex International College' },
	{ value: 'University of Dundee', label: 'University of Dundee' },
	{ value: '(INTO) City University of London', label: '(INTO) City University of London' },
	{ value: 'Swansea University', label: 'Swansea University' },
	{ value: 'Brunel University London', label: 'Brunel University London' },
	{ value: '(NAVITAS) University of Plymouth International College', label: '(NAVITAS) University of Plymouth International College' },
	{ value: 'University of Essex', label: 'University of Essex' },
	{ value: 'Northumbria University, London', label: 'Northumbria University, London' },
	{ value: '(NAVITAS) International College of Portsmouth', label: '(NAVITAS) International College of Portsmouth' },
	{ value: 'University of Birmingham', label: 'University of Birmingham' },
	{ value: '(KAPLAN) University of Birmingham', label: '(KAPLAN) University of Birmingham' },
	{ value: 'Heriot Watt University', label: 'Heriot Watt University' },
	{ value: '(INTO) University of Exeter', label: '(INTO) University of Exeter' },
	{ value: 'Northumbria University, Newcastle', label: 'Northumbria University, Newcastle' },
	{ value: 'University of Portsmouth', label: 'University of Portsmouth' },
	{ value: 'University of Portsmouth, London', label: 'University of Portsmouth, London' },
	{ value: '(NAVITAS) The College, Swansea University', label: '(NAVITAS) The College, Swansea University' },
	{ value: 'University of Plymouth', label: 'University of Plymouth' },
	{ value: '(INTO) University of Stirling', label: '(INTO) University of Stirling' },
	{ value: 'Cranfield University', label: 'Cranfield University' },
	{ value: '(ONCAMPUS) Aston University', label: '(ONCAMPUS) Aston University' },
	{ value: 'Manchester Metropolitan University', label: 'Manchester Metropolitan University' },
	{ value: '(QAHE) Northumbria University', label: '(QAHE) Northumbria University' },
	{ value: '(ONCAMPUS) University of Hull', label: '(ONCAMPUS) University of Hull' },
	{ value: '(KAPLAN) UWE Bristol', label: '(KAPLAN) UWE Bristol' },
	{ value: 'University of Stirling', label: 'University of Stirling' },
	{ value: 'Ulster University, Birmingham Campus', label: 'Ulster University, Birmingham Campus' },
	{ value: 'Nottingham Trent University', label: 'Nottingham Trent University' },
	{ value: '(OXFORD INTERNATIONAL EDUCATION GROUP) Bangor University International College', label: '(OXFORD INTERNATIONAL EDUCATION GROUP) Bangor University International College' },
	{ value: '(ONCAMPUS) Birkbeck, University of London', label: '(ONCAMPUS) Birkbeck, University of London' },
	{ value: '(KAPLAN) Nottingham Trent International College', label: '(KAPLAN) Nottingham Trent International College' },
	{ value: 'Aston University', label: 'Aston University' },
	{ value: 'Coventry University, Coventry', label: 'Coventry University, Coventry' },
	{ value: 'CU (Coventry)', label: 'CU (Coventry)' },
	{ value: 'CU (London)', label: 'CU (London)' },
	{ value: 'CU (Scarborough)', label: 'CU (Scarborough)' },
	{ value: 'Coventry University, London', label: 'Coventry University, London' },
	{ value: '(STUDY GROUP) Liverpool John Moores University, Liverpool', label: '(STUDY GROUP) Liverpool John Moores University, Liverpool' },
	{ value: '(KAPLAN) University of Brighton', label: '(KAPLAN) University of Brighton' },
	{ value: 'University of the West of England, Bristol', label: 'University of the West of England, Bristol' },
	{ value: '(NAVITAS) Hertfordshire International College', label: '(NAVITAS) Hertfordshire International College' },
	{ value: 'Sheffield Hallam University', label: 'Sheffield Hallam University' },
	{ value: 'Bangor University', label: 'Bangor University' },
	{ value: 'Keele University', label: 'Keele University' },
	{ value: '(NAVITAS) Keele University International College', label: '(NAVITAS) Keele University International College' },
	{ value: '(OXFORD INTERNATIONAL EDUCATION GROUP) De Montfort University International College', label: '(OXFORD INTERNATIONAL EDUCATION GROUP) De Montfort University International College' },
	{ value: '(KAPLAN) Bournemouth University', label: '(KAPLAN) Bournemouth University' },
	{ value: 'University of Brighton', label: 'University of Brighton' },
	{ value: 'De Montfort University', label: 'De Montfort University' },
	{ value: 'Oxford Brookes University', label: 'Oxford Brookes University' },
	{ value: 'University of Hertfordshire', label: 'University of Hertfordshire' },
	{ value: 'The University of Huddersfield', label: 'The University of Huddersfield' },
	{ value: '(ONCAMPUS) Goldsmiths University of London', label: '(ONCAMPUS) Goldsmiths University of London' },
	{ value: 'University of Lincoln', label: 'University of Lincoln' },
	{ value: 'University of Salford', label: 'University of Salford' },
	{ value: '(STUDY GROUP) University of Huddersfield', label: '(STUDY GROUP) University of Huddersfield' },
	{ value: '(ONCAMPUS) University of Central Lancashire', label: '(ONCAMPUS) University of Central Lancashire' },
	{ value: 'Aberystwyth University', label: 'Aberystwyth University' },
	{ value: 'Middlesex University', label: 'Middlesex University' },
	{ value: '(OXFORD INTERNATIONAL EDUCATION GROUP) University of Greenwich International College', label: '(OXFORD INTERNATIONAL EDUCATION GROUP) University of Greenwich International College' },
	{ value: 'University of Greenwich', label: 'University of Greenwich' },
	{ value: 'University of Central Lancashire', label: 'University of Central Lancashire' },
	{ value: '(STUDY GROUP) University of Kingston International Study Centre', label: '(STUDY GROUP) University of Kingston International Study Centre' },
	{ value: 'Edinburgh Napier University', label: 'Edinburgh Napier University' },
	{ value: '(OXFORD INTERNATIONAL EDUCATION GROUP) Edinburgh Napier University International College', label: '(OXFORD INTERNATIONAL EDUCATION GROUP) Edinburgh Napier University International College' },
	{ value: 'Goldsmiths, University of London', label: 'Goldsmiths, University of London' },
	{ value: '(OXFORD INTERNATIONAL EDUCATION GROUP) University of Bradford International College', label: '(OXFORD INTERNATIONAL EDUCATION GROUP) University of Bradford International College' },
	{ value: 'St Georges, University of London', label: 'St Georges, University of London' },
	{ value: 'University of Bradford', label: 'University of Bradford' },
	{ value: 'Kingston University', label: 'Kingston University' },
	{ value: 'University of Wolverhampton', label: 'University of Wolverhampton' },
	{ value: 'Anglia Ruskin University (Chelmsford)', label: 'Anglia Ruskin University (Chelmsford)' },
	{ value: 'Anglia Ruskin University (Cambridge)', label: 'Anglia Ruskin University (Cambridge)' },
	{ value: 'Anglia Ruskin University, London', label: 'Anglia Ruskin University, London' },
	{ value: 'Anglia Ruskin University, Peterborough', label: 'Anglia Ruskin University, Peterborough' },
	{ value: 'Leeds Beckett University', label: 'Leeds Beckett University' },
	{ value: '(STUDY GROUP) Royal Holloway, University of London International Study Centre', label: '(STUDY GROUP) Royal Holloway, University of London International Study Centre' },
	{ value: 'London South Bank University', label: 'London South Bank University' },
	{ value: '(INTO) Manchester Metropolitan University', label: '(INTO) Manchester Metropolitan University' },
	{ value: '(ONCAMPUS) Royal Veterinary College, University of London', label: '(ONCAMPUS) Royal Veterinary College, University of London' },
	{ value: '(ONCAMPUS) London South Bank University', label: '(ONCAMPUS) London South Bank University' },
	{ value: 'Birmingham City University', label: 'Birmingham City University' },
	{ value: 'University of the West of Scotland', label: 'University of the West of Scotland' },
	{ value: 'Royal Holloway University of London', label: 'Royal Holloway University of London' },
	{ value: '(NAVITAS) Birmingham City University- International College', label: '(NAVITAS) Birmingham City University- International College' },
	{ value: '(STUDY GROUP) Teesside University International Study Centre', label: '(STUDY GROUP) Teesside University International Study Centre' },
	{ value: 'Teesside University (London)', label: 'Teesside University (London)' },
	{ value: 'Teesside University', label: 'Teesside University' },
	{ value: 'University of East London', label: 'University of East London' },
	{ value: '(NAVITAS) Robert Gordon University', label: '(NAVITAS) Robert Gordon University' },
	{ value: '(NAVITAS) Anglia Ruskin University College', label: '(NAVITAS) Anglia Ruskin University College' },
	{ value: '(QAHE) University of South Wales', label: '(QAHE) University of South Wales' },
	{ value: 'Robert Gordon University', label: 'Robert Gordon University' },
	{ value: 'University of South Wales', label: 'University of South Wales' },
	{ value: 'Staffordshire University', label: 'Staffordshire University' },
	{ value: 'University of Bedfordshire', label: 'University of Bedfordshire' },
	{ value: 'University of Chester', label: 'University of Chester' },
	{ value: 'University of Sunderland, Sunderland', label: 'University of Sunderland, Sunderland' },
	{ value: '(ONCAMPUS) University of Sunderland', label: '(ONCAMPUS) University of Sunderland' },
	{ value: 'University of Sunderland, London', label: 'University of Sunderland, London' },
	{ value: 'Canterbury Christ Church University', label: 'Canterbury Christ Church University' },
	{ value: '(NAVITAS) University of Northampton International College', label: '(NAVITAS) University of Northampton International College' },
	{ value: 'Cardiff Metropolitan University', label: 'Cardiff Metropolitan University' },
	{ value: '(STUDY GROUP) Leeds Beckett University, Leeds', label: '(STUDY GROUP) Leeds Beckett University, Leeds' },
	{ value: 'University of Gloucestershire', label: 'University of Gloucestershire' },
	{ value: 'The University of Northampton', label: 'The University of Northampton' },
	{ value: 'University of West London', label: 'University of West London' },
	{ value: 'Liverpool Hope University', label: 'Liverpool Hope University' },
	{ value: 'Abertay University', label: 'Abertay University' },
	{ value: 'University of Roehampton', label: 'University of Roehampton' },
	{ value: 'York St John University (London)', label: 'York St John University (London)' },
	{ value: 'York St John University (York)', label: 'York St John University (York)' },
	{ value: 'University of Bolton', label: 'University of Bolton' },
	{ value: 'Solent University', label: 'Solent University' },
	{ value: '(QAHE) Solent University', label: '(QAHE) Solent University' },
	{ value: 'The University of Buckingham', label: 'The University of Buckingham' },
	{ value: 'University of Southampton', label: 'University of Southampton' },
	{ value: 'Leeds Trinity University', label: 'Leeds Trinity University' },
	{ value: 'Regents University London', label: 'Regents University London' },
	{ value: 'University for the Creative Arts', label: 'University for the Creative Arts' },
	{ value: 'Hult International Business School', label: 'Hult International Business School' },
	{ value: 'University College Birmingham', label: 'University College Birmingham' },
	{ value: 'Le - Cordon Bleu', label: 'Le - Cordon Bleu' },
	{ value: 'BPP University', label: 'BPP University' },
	{ value: 'Cambridge School of Visual & Performing Arts', label: 'Cambridge School of Visual & Performing Arts' },
	{ value: '(INTO) London World Education Centre', label: '(INTO) London World Education Centre' },
	{ value: 'Istituto Marangoni', label: 'Istituto Marangoni' },
	{ value: 'University of Law (Birmingham)', label: 'University of Law (Birmingham)' },
	{ value: 'University of Law (Chester)', label: 'University of Law (Chester)' },
	{ value: 'University of Law (Guildford)', label: 'University of Law (Guildford)' },
	{ value: 'University of Law (Liverpool)', label: 'University of Law (Liverpool)' },
	{ value: 'University of Law (Manchester)', label: 'University of Law (Manchester)' },
	{ value: 'University of Law (Norwich)', label: 'University of Law (Norwich)' },
	{ value: 'University of Law (Online)', label: 'University of Law (Online)' },
	{ value: 'University of Law (Sheffield)', label: 'University of Law (Sheffield)' },
	{ value: 'University of Law (Bristol)', label: 'University of Law (Bristol)' },
	{ value: 'University of Law (Exeter)', label: 'University of Law (Exeter)' },
	{ value: 'University of Law (Leeds)', label: 'University of Law (Leeds)' },
	{ value: 'University of Law (London)', label: 'University of Law (London)' },
	{ value: 'University of Law (Newcastle)', label: 'University of Law (Newcastle)' },
	{ value: 'University of Law (Nottingham)', label: 'University of Law (Nottingham)' },
	{ value: 'University of Law (Reading)', label: 'University of Law (Reading)' },
	{ value: 'University of Law (Southampton)', label: 'University of Law (Southampton)' },
	{ value: 'Glion Institute of Higher Education', label: 'Glion Institute of Higher Education' },
	{ value: '(KAPLAN) International College London', label: '(KAPLAN) International College London' },
	{ value: 'Ravensbourne University London', label: 'Ravensbourne University London' },
	{ value: 'Norwich University of the Arts', label: 'Norwich University of the Arts' },
	{ value: 'Bishop Grosseteste University', label: 'Bishop Grosseteste University' },
	{ value: 'Bloomsbury Institute London', label: 'Bloomsbury Institute London' },
	{ value: 'Ulster University, London Campus', label: 'Ulster University, London Campus' },
	{ value: 'The Engineering and Design Institute London', label: 'The Engineering and Design Institute London' }
];

export default UKCourses;