import React, { useState, useEffect, useRef } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col, Spinner } from 'react-bootstrap';
import axios from 'axios';
import DocumentUpload from '../Forms/DocumentUpload';
import Main from '../global/wrapper/Main';
// import colors from '../global/colors';
import { getUserDetailsFromToken } from '../utils/getUserDetailsFromToken';
import CardFull from '../global/holder/CardFull';
import {
	Box,
	Heading,
	Text,
	Flex,
	Spacer,
	Badge,
	Grid,
	GridItem,
	useColorModeValue as mode,
	Tooltip,
	Wrap,
	WrapItem,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import CommentForm from '../Forms/CommentForm';
import CommentsList from '../displays/CommentsList';
import { useNavigation } from '../utils/NavigationContext';
import { useToast } from '@chakra-ui/react';

const EnquiryDetails = () => {
	const [copySuccess, setCopySuccess] = useState(''); // State to manage copy status
	const [commentsPerPage] = useState(10);
	const [studentData, setStudentData] = useState(null);
	const navigate = useNavigate();
	const [comment, setComment] = useState('');
	const [attachments, setAttachments] = useState([]);
	const [comments, setComments] = useState([]);

	/* Document Files */
	const [resumeFile, setResumeFile] = useState(null);
	const [xMarkSheetFile, setXMarkSheetFile] = useState(null);
	const [twelfthMarkSheetFile, setTwelfthMarkSheetFile] = useState(null);
	const [diplomaMarkSheet, setDiplomaMarkSheet] = useState(null);
	const [ugMarkSheet, setUgMarkSheet] = useState(null);
	const [pgMarkSheet, setPgMarkSheet] = useState(null);
	const [diplomaAwardCertificate, setDiplomaAwardCertificate] = useState(null);
	const [pgDegreeCertificate, setPgDegreeCertificate] = useState(null);
	const [transferCertificate, setTransferCertificate] = useState(null);
	const [pteIeltsCertificate, setGreGmatCertificate] = useState(null);
	const [employerReferenceLetter, setEmployerReferenceLetter] = useState(null);
	const [courseApplicationForm, setCourseApplicationForm] = useState(null);
	const [passportSizePhoto, setPassportSizePhoto] = useState(null);
	const [policeClearanceCertificate, setPoliceClearanceCertificate] = useState(null);
	const [feesReceipt, setFeesReceipt] = useState(null);

	const [uploadedFiles, setUploadedFiles] = useState({});
	const [attachmentIds, setAttachmentIds] = useState({});
	const [uploadedFileName, setUploadedFileName] = useState({});

	const [filePaths, setFilePaths] = useState({});
	const [visibility, setVisibility] = useState('Public');

	const { fromPage } = useNavigation();

	const [editingComment, setEditingComment] = useState({ id: null, text: "" });

	const [isOpen, setIsOpen] = useState(false);
	const onClose = () => setIsOpen(false);
	const cancelRef = useRef();
	const toast = useToast();

	const [searchParams] = useSearchParams();
	const currentPageFromURL = parseInt(searchParams.get('detailPage') || '1', 10);

	const userDetails = getUserDetailsFromToken();
	const isAdmin = userDetails?.privilege === 'Admin';
	const userId = userDetails ? userDetails.userId : null;
	const { id } = useParams(); // Assuming `id` is the parameter used for student details

	useEffect(() => {
		console.log('Current ID:', id);
		if (!id) {
			console.log('Redirecting to /enquiries');
			navigate('/enquiries');
		}
	}, [id, navigate]);

	useEffect(() => {
		// Fetch initial data for student and set states
		const fetchData = async () => {
			try {
				const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/enquiries/count/${id}`);
				setStudentData(response.data);

				// Fetch attachments
				const attachmentsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/enquiry-attachments/${id}`);
				const attachments = attachmentsResponse.data;

				// Update states for each attachment
				attachments.forEach(attachment => {
					setUploadedFileName(prev => ({ ...prev, [attachment.documentType]: attachment.documentName }));
					setFilePaths(prev => ({ ...prev, [attachment.documentType]: attachment.filePath }));
					setUploadedFiles(prev => ({ ...prev, [attachment.documentType]: true }));
				});

			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, [id, uploadedFileName]);

	useEffect(() => {
		if (studentData && studentData.studentID) {
			axios.get(`${process.env.REACT_APP_API_URL}/api/enquiry-attachments/${studentData.studentID}`)
				.then(response => {
					const attachments = response.data;
					const filesStatus = {};
					const ids = {};
					const paths = {};

					attachments.forEach(attachment => {
						const documentType = attachment.documentType;
						filesStatus[documentType] = true;
						ids[documentType] = attachment._id;
						paths[documentType] = attachment.filePath;
						// Add 'Name' to the key for uploaded file names
						filesStatus[documentType + 'Name'] = attachment.documentName;
						// Set uploaded file name for the specific document type
						setUploadedFileName(prevState => ({
							...prevState,
							[documentType]: attachment.documentName,
						}));
					});

					setUploadedFiles(filesStatus);
					setAttachmentIds(ids);
					setFilePaths(paths);
				})
				.catch(error => {
					console.error('Error fetching attachments:', error);
				});
		}
	}, [studentData]);

	useEffect(() => {
		if (studentData && studentData.studentID) {
			axios.get(`${process.env.REACT_APP_API_URL}/api/comments/student/${studentData.studentID}`)
				.then(response => {
					const sortedComments = response.data.sort((a, b) => new Date(b.datePosted) - new Date(a.datePosted));
					setComments(sortedComments);
				})
				.catch(error => {
					console.error('Error fetching comments:', error);
				});
		}
	}, [studentData, comment, attachments]);

	const copyToClipboard = () => {
		const url = `${process.env.REACT_APP_API_URL}/enquiry-details/${studentData.studentID}`;
		navigator.clipboard.writeText(url).then(() => {
			setCopySuccess('Copied!'); // Update state to show copied message
			setTimeout(() => setCopySuccess(''), 2000); // Reset message after 2 seconds
		}, () => {
			setCopySuccess('Failed to copy'); // In case of failure
		});
	};

	const formatDate = (dateString) => {
		const date = new Date(dateString);
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();

		const formattedDay = day < 10 ? `0${day}` : day;
		const formattedMonth = month < 10 ? `0${month}` : month;

		return `${formattedDay}/${formattedMonth}/${year}`;
	};

	const capitalizeFirstLetter = (string) => {
		if (typeof string !== 'string' || string.length === 0) return '';
		return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
	};

	const formatCommentDate = (dateString) => {
		if (!dateString || new Date(dateString).toString() === 'Invalid Date') {
			// Log for debugging
			console.error("Invalid or undefined date string:", dateString);
			return "Date not available";
		}
		const options = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
		return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
	};

	const fileInputRef = React.useRef();

	const isSubmitEnabled = comment.trim() !== '' || attachments.length > 0;

	const handleSubmitComment = async (event) => {
		event.preventDefault();
		const formData = new FormData();
		formData.append('comment', comment);
		formData.append('userID', userId);
		formData.append('studentID', id);
		formData.append('visibility', visibility);
		attachments.forEach((file) => formData.append('attachments', file));

		console.log('Submitting comment:', { comment, userId, id });
		console.log('Attachments:', attachments);


		try {
			const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/comments`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
			// Assuming response.data is the new comment object
			if (response.data) {
				setComments([response.data, ...comments]);
			}

			// Clear the comment textarea and attachments
			setComment('');
			setAttachments([]);
			if (fileInputRef.current) {
				fileInputRef.current.value = ""; // Reset file input field
			}
		} catch (error) {
			console.error('Error submitting comment:', error);
		}
	};

	const handleEditComment = (comment) => {
		console.log("Editing comment with ID:", comment._id); // Debug log
		setEditingComment({ id: comment._id, text: comment.text }); // Use _id
	};

	const deleteComment = async (commentId, attachments) => {
		try {
			const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/comments/delete/${commentId}`);
			if (response.data) {
				// Remove the deleted comment from the state
				setComments(comments.filter(comment => comment._id !== commentId));

				// Delete associated attachments if available
				if (attachments && attachments.length > 0) {
					for (const attachment of attachments) {
						// Assuming you have a separate API route to delete attachments
						await axios.post(`${process.env.REACT_APP_API_URL}/api/attachments/delete/${attachment}`);
					}
				}
			}
		} catch (error) {
			console.error('Error deleting comment:', error);
		}
	};

	const cancelEdit = () => {
		setEditingComment({ id: null, text: "" });
	};

	const submitEditedComment = async () => {
		if (!editingComment.id || !editingComment.text.trim()) {
			return;
		}

		try {
			const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/comments/edit/${editingComment.id}`, {
				newText: editingComment.text
			});

			if (response.data) {
				setComments(comments.map(comment =>
					comment._id === editingComment.id ? { ...comment, text: editingComment.text, edited: true } : comment // Use _id
				));
				cancelEdit();
			}
		} catch (error) {
			console.error('Error editing comment:', error);
		}
	};

	const deleteEnquiry = async () => {
		if (!studentData || !studentData.studentID) {
			toast({
				title: 'Error',
				description: 'Student ID is missing.',
				status: 'error',
				duration: 5000,
				isClosable: true,
			});
			return;
		}

		fetch(`${process.env.REACT_APP_API_URL}/api/enquiries/${studentData.studentID}`, {
			method: 'DELETE',
		})
			.then(response => {
				if (response.ok) {
					toast({
						title: 'Enquiry Deleted',
						description: 'The enquiry has been successfully deleted.',
						status: 'success',
						duration: 5000,
						isClosable: true,
					});
					navigate('/enquiries');
				} else {
					throw new Error('Failed to delete enquiry.');
				}
			})
			.catch(error => {
				console.error('Error:', error);
				toast({
					title: 'Error',
					description: 'Error deleting enquiry.',
					status: 'error',
					duration: 5000,
					isClosable: true,
				});
			})
			.finally(() => {
				onClose(); // Ensure the dialog is closed after the operation
			});
	};



	if (!studentData) {
		return (
			<div className="text-center" style={{ paddingTop: "100px", paddingBottom: "100px" }}>
				<Spinner size="md" />
			</div>
		);
	}

	const isProfileComplete = studentData.profileStatus === "complete";
	// const progressVariant = isProfileComplete ? "success" : "info";
	// const progressLabel = isProfileComplete ? "Profile Complete" : "Profile Incomplete";
	// const progressNow = isProfileComplete ? 100 : 50;

	const handlePageChange = (newPageNumber) => {
		// Get the current value of the 'backPage' parameter
		const backPage = searchParams.get('backPage');

		// Construct the new URL path
		const newPath = `/enquiry-details/${id}`;

		// Create a new URLSearchParams object for updating the URL
		const newSearchParams = new URLSearchParams();

		// If 'backPage' parameter exists, add it to the new search params
		if (backPage) {
			newSearchParams.set('backPage', backPage);
		}

		// Use 'navigate' to update the URL without the 'detailPage' parameter
		navigate({
			pathname: newPath,
			search: newSearchParams.toString()
		});
	};

	const indexOfLastComment = currentPageFromURL * commentsPerPage;
	const indexOfFirstComment = indexOfLastComment - commentsPerPage;
	const currentComments = comments.slice(indexOfFirstComment, indexOfLastComment);
	const totalPages = Math.ceil(comments.length / commentsPerPage);

	const renderPageNumbers = () => {
		const pageNumbers = [];
		for (let i = 1; i <= totalPages; i++) {
			pageNumbers.push(i);
		}

		return (
			<nav>
				<ul className="pagination">
					{pageNumbers.map(number => (
						<li key={number} className="page-item">
							<button
								onClick={() => handlePageChange(number)}
								className="page-link"
								style={{ border: 'none', background: 'none', color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
							>
								{number}
							</button>
						</li>
					))}
				</ul>
			</nav>
		);
	};

	// Helper function to determine if the file is an image
	const isImage = (fileName) => {
		return /\.(jpg|jpeg|png|gif)$/i.test(fileName);
	};

	const uploadDocument = async (event, documentType, file) => {
		event.preventDefault();
		if (!file) return;

		if (!id || typeof id !== 'string' || id.length !== 8) {
			console.error('Invalid Student ID');
			return;
		}

		const formData = new FormData();
		formData.append('file', file);
		formData.append('documentType', documentType);
		formData.append('studentID', id);

		const attachmentId = attachmentIds[documentType]; // Assuming attachmentIds is in scope

		try {
			let response;
			if (uploadedFiles[documentType]) {
				// Replace existing file
				formData.append('attachmentId', attachmentId);
				response = await axios.post(`${process.env.REACT_APP_API_URL}/api/enquiry-attachments/replace`, formData, {
					headers: { 'Content-Type': 'multipart/form-data' },
				});
			} else {
				// Upload new file
				response = await axios.post(`${process.env.REACT_APP_API_URL}/api/enquiry-attachments/upload`, formData, {
					headers: { 'Content-Type': 'multipart/form-data' },
				});
			}

			if (response.data) {
				// Assuming you get a filePath from the server's response
				const filePath = response.data.filePath;
				console.log(filePath);
				setFilePaths(prevState => ({ ...prevState, [documentType]: filePath }));

				setUploadedFiles(prevState => ({ ...prevState, [documentType]: true }));
				// Check if the server response contains the file name
				const fileName = response.data.fileName || (response.data.fileNames && response.data.fileNames[0]);
				setUploadedFileName(prevState => ({ ...prevState, [documentType]: fileName }));
				console.log('Updated uploadedFileName:', fileName);
			}
		} catch (error) {
			console.error(`Error uploading ${documentType}:`, error);
		}
	};

	const handleUploadAll = async (event) => {
		event.preventDefault();
		try {
			if (resumeFile) {
				await uploadDocument(event, 'resume', resumeFile);
				setResumeFile(null);
			}
			if (xMarkSheetFile) {
				await uploadDocument(event, 'xMarkSheet', xMarkSheetFile);
				setXMarkSheetFile(null
				);
			}
			if (twelfthMarkSheetFile) {
				await uploadDocument(event, 'twelfthMarkSheet', twelfthMarkSheetFile);
				setTwelfthMarkSheetFile(null);
			}
			if (diplomaMarkSheet) {
				await uploadDocument(event, 'diplomaMarkSheet', diplomaMarkSheet);
				setDiplomaMarkSheet(null);
			}
			if (ugMarkSheet) {
				await uploadDocument(event, 'ugMarkSheet', ugMarkSheet);
				setUgMarkSheet(null);
			}
			if (pgMarkSheet) {
				await uploadDocument(event, 'pgMarkSheet', pgMarkSheet);
				setPgMarkSheet(null);
			}
			if (diplomaAwardCertificate) {
				await uploadDocument(event, 'diplomaAwardCertificate', diplomaAwardCertificate);
				setDiplomaAwardCertificate(null);
			}
			if (pgDegreeCertificate) {
				await uploadDocument(event, 'pgDegreeCertificate', pgDegreeCertificate);
				setPgDegreeCertificate(null);
			}
			if (transferCertificate) {
				await uploadDocument(event, 'transferCertificate', transferCertificate);
				setTransferCertificate(null);
			}
			if (pteIeltsCertificate) {
				await uploadDocument(event, 'pteIeltsCertificate', pteIeltsCertificate);
				setGreGmatCertificate(null);
			}
			if (employerReferenceLetter) {
				await uploadDocument(event, 'employerReferenceLetter', employerReferenceLetter);
				setEmployerReferenceLetter(null);
			}
			if (courseApplicationForm) {
				await uploadDocument(event, 'courseApplicationForm', courseApplicationForm);
				setCourseApplicationForm(null);
			}
			if (passportSizePhoto) {
				await uploadDocument(event, 'passportSizePhoto', passportSizePhoto);
				setPassportSizePhoto(null);
			}
			if (policeClearanceCertificate) {
				await uploadDocument(event, 'policeClearanceCertificate', policeClearanceCertificate);
				setPoliceClearanceCertificate(null);
			}
			if (feesReceipt) {
				await uploadDocument(event, 'feesReceipt', feesReceipt);
				setFeesReceipt(null);
			}
		} catch (error) {
			console.error('Error uploading documents:', error);
		}
	};

	const handleRemoveFile = async (documentType, attachmentId) => {
		if (attachmentId) {
			try {
				await axios.delete(`${process.env.REACT_APP_API_URL}/api/enquiry-attachments/${attachmentId}`);
				console.log(`${documentType} file removed successfully`);
				setUploadedFiles(prevState => ({ ...prevState, [documentType]: false }));

				// Update the `attachmentIds` state to remove the entry for this document type
				setAttachmentIds(prevState => {
					const newState = { ...prevState };
					delete newState[documentType];
					return newState;
				});

				// Reset the specific file input state based on the `documentType`
				// resetFileInputState(documentType);
			} catch (error) {
				console.error(`Error removing ${documentType} file:`, error);
				return; // Exit the function if there's an error
			}
		}

		// Continue with further steps...
	};

	const goBack = () => {
		const backPage = searchParams.get('backPage') || '1';
		const targetPage = fromPage || 'enquiries';
		navigate(`/${targetPage}?page=${backPage}`);
	};

	return (
		<Main>
			<Container maxW="100%" m={5}>
				<Box>
					<AlertDialog
						isOpen={isOpen}
						leastDestructiveRef={cancelRef}
						onClose={onClose}
					>
						<AlertDialogOverlay>
							<AlertDialogContent>
								<AlertDialogHeader fontSize="lg" fontWeight="bold">
									Delete Enquiry
								</AlertDialogHeader>
								<AlertDialogBody>
									Are you sure? You can't undo this action afterwards.
								</AlertDialogBody>
								<AlertDialogFooter>
									<Button ref={cancelRef} onClick={onClose} style={{ marginRight: '10px' }}>
										Cancel
									</Button>
									<Button className="btn btn-danger" onClick={deleteEnquiry} ml={3}>
										Delete
									</Button>
								</AlertDialogFooter>
							</AlertDialogContent>
						</AlertDialogOverlay>
					</AlertDialog>
					<Flex align="center" justify="space-between" width="100%">
						<Button
							variant="primary"
							onClick={goBack}
							rounded="none"
							lefticon={<ArrowBackIcon />}
						>
							Go Back
						</Button>
						<Button className="btn btn-danger" style={{ marginLeft: 'auto' }} onClick={() => setIsOpen(true)}>
							Delete Enquiry
						</Button>
					</Flex>
				</Box>
				<CardFull>
					<Box className="student-welcome__head">
						<Heading size="xl" textAlign="center" fontWeight="bold" mt={4} style={{ textAlign: 'left' }}>
							<Text as="span" fontSize="3xl" color="blue.500">
								Welcome to the application of{' '}
							</Text>
							<Text as="span" fontSize="3xl" color="green.500">
								{`${studentData.givenName ? capitalizeFirstLetter(studentData.givenName) : 'N/A'} ${studentData.familyName ? capitalizeFirstLetter(studentData.familyName) : 'N/A'}`}
							</Text>
							<Badge ml={2} colorScheme="yellow" fontSize="md">
								{!isProfileComplete ? 'Active' : 'Inactive'}
							</Badge>
						</Heading>
						<Spacer p={4}></Spacer>
					</Box>
					<Grid templateColumns={{ base: '1fr', md: 'repeat(4, 1fr)' }} gap={4}>
						<GridItem colSpan={1}>
							<Box className="student-welcome__head-title">
								<Text fontWeight="bold">Name</Text>
								<Box className="namefld">
									<Text className="camelCasing">
										{`${studentData.givenName ? capitalizeFirstLetter(studentData.givenName) : 'N/A'} ${studentData.familyName ? capitalizeFirstLetter(studentData.familyName) : 'N/A'}`}
									</Text>
								</Box>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="student-welcome__head-title">
								<Text fontWeight="bold">Email</Text>
								<Box className="namefld">
									<Text className="emailSmall" id="dispEmail">
										{studentData.contactEmail ? studentData.contactEmail : 'N/A'}
									</Text>
								</Box>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="student-welcome__head-title">
								<Text fontWeight="bold">Phone</Text>
								<Box className="namefld">
									<Text className="StudentmobileNumber" id="dispMob">
										+91-{studentData.mobileNumber ? studentData.mobileNumber : 'N/A'}
									</Text>
								</Box>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="cust-colLink student-welcome__head-title">
								<Text fontWeight="bold">Student Url</Text>
								<Box className="student-welcome__head-title__link" maxWidth="250px">
									<Tooltip label={`${process.env.REACT_APP_API_URL}/enquiry-details/${studentData.studentID}`} placement="top">
										<Text className="student-welcome__head-title__link-trimmed" id="dispMob" isTruncated>
											{`${process.env.PUBLIC_URL}/enquiry-details/${studentData.studentID}`}
										</Text>
									</Tooltip>
									<Flex mt={2} alignItems="center">
										<Button colorScheme="blue" onClick={copyToClipboard}>Copy Link</Button>
										{copySuccess && <Text ml={2} mb={0} pl={2} pr={2} bg="gray.200">{copySuccess}</Text>}
									</Flex>
								</Box>
							</Box>
						</GridItem>
					</Grid>
				</CardFull>

				<CardFull>
					<Heading size="lg" mt={6} fontWeight="bold" color="blue.500">
						Personal Details
						<Spacer p={4}></Spacer>
					</Heading>
					<Grid templateColumns={{ base: '1fr', md: 'repeat(4, 1fr)' }} gap={4}>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Student ID:</Text>
								<Text>{studentData.studentID}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Father or Spouse Name:</Text>
								<Text>{studentData.fatherOrSpouseName ? capitalizeFirstLetter(studentData.fatherOrSpouseName) : ''}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Gender:</Text>
								<Text>{studentData.gender ? capitalizeFirstLetter(studentData.gender) : ''}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Marital Status:</Text>
								<Text>{studentData.maritalStatus ? capitalizeFirstLetter(studentData.maritalStatus) : ''}</Text>
							</Box>
						</GridItem>
					</Grid>
					<Grid templateColumns={{ base: '1fr', md: 'repeat(4, 1fr)' }} gap={4}>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">DOB:</Text>
								<Text>{studentData.dob ? formatDate(studentData.dob) : ''}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Phone Residence:</Text>
								<Text>{studentData.phoneResidence}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Phone Work:</Text>
								<Text>{studentData.phoneWork}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Emergency Number:</Text>
								<Text>{studentData.emergencyNumber}</Text>
							</Box>
						</GridItem>
					</Grid>
					<Grid templateColumns={{ base: '1fr', md: 'repeat(4, 1fr)' }} gap={4}>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Postal Address:</Text>
								<Text>{studentData.postalAddress}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">PinCode:</Text>
								<Text>{studentData.pinCode}</Text>
							</Box>
						</GridItem>
					</Grid>
				</CardFull>

				<CardFull>
					<Heading size="lg" mt={6} fontWeight="bold" color="blue.500">
						Passport Details
						<Spacer p={4}></Spacer>
					</Heading>
					<Grid templateColumns={{ base: '1fr', md: 'repeat(4, 1fr)' }} gap={4}>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Passport Number:</Text>
								<Text>{studentData.passportNumber}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Issued Date:</Text>
								<Text>{studentData.issuedDate ? formatDate(studentData.issuedDate) : ''}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Expiry Date:</Text>
								<Text>{studentData.expiryDate ? formatDate(studentData.expiryDate) : ''}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Place of Issue:</Text>
								<Text textTransform="capitalize">{studentData.placeOfIssue}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Passport Nationality:</Text>
								<Text textTransform="capitalize">{studentData.passportNationality}</Text>
							</Box>
						</GridItem>
					</Grid>
				</CardFull>

				<CardFull>
					<Heading size="lg" mt={6} fontWeight="bold" color="blue.500">
						Educational Details
						<Spacer p={4}></Spacer>
					</Heading>
					<Heading size="sm" mt={6} fontWeight="bold" color="blue.500">
						Secondary School Leaving Certificate
					</Heading>
					<Grid templateColumns={{ base: '1fr', md: 'repeat(4, 1fr)' }} gap={4}>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">SSLC:</Text>
								<Text textTransform="capitalize">{studentData.sslc ? studentData.sslc : 'N/A'}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Year of Passing:</Text>
								<Text>{studentData.sslcYearOfPassing ? studentData.sslcYearOfPassing : 'N/A'}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Marks (or) Percentage:</Text>
								<Text>{studentData.sslcMarksPercentage ? studentData.sslcMarksPercentage : 'N/A'}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">BackLog:</Text>
								<Text>{studentData.sslcBacklog ? studentData.sslcBacklog : 'N/A'}</Text>
							</Box>
						</GridItem>
					</Grid>

					<Heading size="sm" mt={6} fontWeight="bold" color="blue.500">
						Higher Secondary Certificate
					</Heading>
					<Grid templateColumns={{ base: '1fr', md: 'repeat(4, 1fr)' }} gap={4}>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">HSC:</Text>
								<Text>{studentData.hsc ? studentData.hsc : 'N/A'}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Year of Passing:</Text>
								<Text>{studentData.hscYearOfPassing ? studentData.hscYearOfPassing : 'N/A'}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Marks (or) Percentage:</Text>
								<Text>{studentData.hscMarksPercentage ? studentData.hscMarksPercentage : 'N/A'}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">BackLog:</Text>
								<Text>{studentData.hscBacklog ? studentData.hscBacklog : 'N/A'}</Text>
							</Box>
						</GridItem>
					</Grid>

					<Heading size="sm" mt={6} fontWeight="bold" color="blue.500">
						Diploma
					</Heading>
					<Grid templateColumns={{ base: '1fr', md: 'repeat(4, 1fr)' }} gap={4}>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Stream:</Text>
								<Text>{studentData.diplomaStream ? studentData.diplomaStream : 'N/A'}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Diploma:</Text>
								<Text>{studentData.diploma ? studentData.diploma : 'N/A'}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Year of Passing:</Text>
								<Text>{studentData.diplomaYearOfPassing ? studentData.diplomaYearOfPassing : 'N/A'}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Marks (or) Percentage:</Text>
								<Text>{studentData.diplomaMarksPercentage ? studentData.diplomaMarksPercentage : 'N/A'}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Back log:</Text>
								<Text>{studentData.diplomaBacklog ? studentData.diplomaBacklog : 'N/A'}</Text>
							</Box>
						</GridItem>
					</Grid>

					<Heading size="sm" mt={6} fontWeight="bold" color="blue.500">
						Under Graduate
					</Heading>
					<Grid templateColumns={{ base: '1fr', md: 'repeat(4, 1fr)' }} gap={4}>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Stream:</Text>
								<Text>{studentData.ugStream ? studentData.ugStream : 'N/A'}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Under Graduate:</Text>
								<Text>{studentData.ug ? studentData.ug : 'N/A'}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Year of Passing:</Text>
								<Text>{studentData.ugYearOfPassing ? studentData.ugYearOfPassing : 'N/A'}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Marks (or) Percentage:</Text>
								<Text>{studentData.ugMarksPercentage ? studentData.ugMarksPercentage : 'N/A'}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Back log:</Text>
								<Text>{studentData.ugBacklog ? studentData.ugBacklog : 'N/A'}</Text>
							</Box>
						</GridItem>
					</Grid>


					<Heading size="sm" mt={6} fontWeight="bold" color="blue.500">
						Post Graduate
					</Heading>
					<Grid templateColumns={{ base: '1fr', md: 'repeat(4, 1fr)' }} gap={4}>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Stream:</Text>
								<Text>{studentData.pgStream ? studentData.pgStream : 'N/A'}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Post Graduate:</Text>
								<Text>{studentData.pg ? studentData.pg : 'N/A'}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Year of Passing:</Text>
								<Text>{studentData.pgYearOfPassing ? studentData.pgYearOfPassing : 'N/A'}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Marks (or) Percentage:</Text>
								<Text>{studentData.pgMarksPercentage ? studentData.pgMarksPercentage : 'N/A'}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Back log:</Text>
								<Text>{studentData.pgBacklog ? studentData.pgBacklog : 'N/A'}</Text>
							</Box>
						</GridItem>
					</Grid>
				</CardFull>

				<CardFull>
					<Heading size="lg" mt={6} fontWeight="bold" color="blue.500">
						ENGLISH OR COMMON TEST
						<Spacer p={4}></Spacer>
					</Heading>
					<Grid templateColumns={{ base: '1fr', md: 'repeat(4, 1fr)' }} gap={4}>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">IELTS:</Text>
								<Text fontSize="larger">{studentData.ielts ? studentData.ielts : 'N/A'}</Text>
								<Flex gap={3}>
									{studentData.ieltsListening &&
										<Flex gap={1}>
											<Text fontWeight="bold">L: </Text> <Text>{studentData.ieltsListening}</Text>
										</Flex>
									}
									{studentData.ieltsReading &&
										<Flex gap={1}>
											<Text fontWeight="bold">R: </Text> <Text>{studentData.ieltsReading}</Text>
										</Flex>
									}
									{studentData.ieltsWriting &&
										<Flex gap={1}>
											<Text fontWeight="bold">W: </Text> <Text>{studentData.ieltsWriting}</Text>
										</Flex>
									}
									{studentData.ieltsSpeaking &&
										<Flex gap={1}>
											<Text fontWeight="bold">S: </Text> <Text>{studentData.ieltsSpeaking}</Text>
										</Flex>
									}
								</Flex>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">TOEFL:</Text>
								<Text fontSize="larger">{studentData.toefl ? studentData.toefl : 'N/A'}</Text>
								<Flex gap={3}>
									{studentData.toeflListening &&
										<Flex gap={1}>
											<Text fontWeight="bold">L: </Text> <Text>{studentData.toeflListening}</Text>
										</Flex>
									}
									{studentData.toeflReading &&
										<Flex gap={1}>
											<Text fontWeight="bold">R: </Text> <Text>{studentData.toeflReading}</Text>
										</Flex>
									}
									{studentData.toeflWriting &&
										<Flex gap={1}>
											<Text fontWeight="bold">W: </Text> <Text>{studentData.toeflWriting}</Text>
										</Flex>
									}
									{studentData.toeflSpeaking &&
										<Flex gap={1}>
											<Text fontWeight="bold">S: </Text> <Text>{studentData.toeflSpeaking}</Text>
										</Flex>
									}
								</Flex>
							</Box>

						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">PTE:</Text>
								<Text fontSize="larger">{studentData.pte ? studentData.pte : 'N/A'}</Text>
								<Flex gap={3}>
									{studentData.pteListening &&
										<Flex gap={1}>
											<Text fontWeight="bold">L: </Text> <Text>{studentData.pteListening}</Text>
										</Flex>
									}
									{studentData.pteReading &&
										<Flex gap={1}>
											<Text fontWeight="bold">R: </Text> <Text>{studentData.pteReading}</Text>
										</Flex>
									}
									{studentData.pteWriting &&
										<Flex gap={1}>
											<Text fontWeight="bold">W: </Text> <Text>{studentData.pteWriting}</Text>
										</Flex>
									}
									{studentData.pteSpeaking &&
										<Flex gap={1}>
											<Text fontWeight="bold">S: </Text> <Text>{studentData.pteSpeaking}</Text>
										</Flex>
									}
								</Flex>
							</Box>

						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">GMAT:</Text>
								<Text fontSize="larger">{studentData.gmat ? studentData.gmat : 'N/A'}</Text>
								<Flex gap={3}>
									{studentData.gmatQuantitative &&
										<Flex gap={1}>
											<Text fontWeight="bold">Q: </Text> <Text>{studentData.gmatQuantitative}</Text>
										</Flex>
									}
									{studentData.gmatVerbal &&
										<Flex gap={1}>
											<Text fontWeight="bold">V: </Text> <Text>{studentData.gmatVerbal}</Text>
										</Flex>
									}
									{studentData.gmatAnalyticalWriting &&
										<Flex gap={1}>
											<Text fontWeight="bold">AW: </Text> <Text>{studentData.gmatAnalyticalWriting}</Text>
										</Flex>
									}
									{studentData.gmatIntegratedReasoning &&
										<Flex gap={1}>
											<Text fontWeight="bold">IR: </Text> <Text>{studentData.gmatIntegratedReasoning}</Text>
										</Flex>
									}
								</Flex>
							</Box>

						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">GRE:</Text>
								<Text fontSize="larger">{studentData.gre ? studentData.gre : 'N/A'}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">SAT:</Text>
								<Text fontSize="larger">{studentData.sat ? studentData.sat : 'N/A'}</Text>
							</Box>
						</GridItem>
					</Grid>
				</CardFull>

				<CardFull>
					<Heading size="lg" mt={6} fontWeight="bold" color="blue.500">
						Study Preference
						<Spacer p={4}></Spacer>
					</Heading>
					<Grid templateColumns={{ base: '1fr', md: 'repeat(4, 1fr)' }} gap={4}>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Preferred Country:</Text>
								<Text textTransform="capitalize">{studentData.preferredCountry}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Plan to Start:</Text>
								<Text>
									{studentData.startMonth && `${studentData.startMonth}`}
									{studentData.startYear && ` / ${studentData.startYear}`}
								</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Enquiry Date:</Text>
								<Text>{studentData.enquiryDate ? formatDate(studentData.enquiryDate) : ''}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Follow up Date:</Text>
								<Text>{studentData.followUpDate ? formatDate(studentData.followUpDate) : ''}</Text>
							</Box>
						</GridItem>
					</Grid>
					<Grid templateColumns={{ base: '1fr', md: 'repeat(4, 1fr)' }} gap={4}>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Desired Course:</Text>
								<Text>{studentData.desiredCourse ? studentData.desiredCourse : ''}</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Preferred Universities:</Text>
								<Text textTransform="capitalize">
									<Wrap spacing={1}>
										{studentData.university.length > 0 ? (
											studentData.university.map((u, index) => (
												<WrapItem key={index}>
													<Box
														p={2} // Padding for spacing around text
														bg="blue.200" // Background color (you can change this to any Chakra UI color)
														borderRadius="md" // Rounded corners
													>
														<Text fontSize="smaller" mb={0}>{u.label}</Text>
													</Box>
												</WrapItem>
											))
										) : (
											// Render something else or nothing when there is no data
											null
										)}

									</Wrap>
								</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Education Interest:</Text>
								<Text>
									<Wrap spacing={1}>
										{studentData.educationInterest.length > 0 ? (
											studentData.educationInterest.map((u, index) => (
												<WrapItem key={index}>
													<Box
														p={2} // Padding for spacing around text
														bg="blue.200" // Background color (you can change this to any Chakra UI color)
														borderRadius="md" // Rounded corners
													>
														<Text fontSize="smaller" mb={0}>{u.label}</Text>
													</Box>
												</WrapItem>
											))
										) : (
											// Render something else or nothing when there is no data
											<Text fontSize="smaller" mb={0}>N/A</Text>
										)}

									</Wrap>
								</Text>
							</Box>
						</GridItem>
						<GridItem colSpan={1}>
							<Box className="details">
								<Text fontWeight="bold">Student Rating:</Text>
								<Text>{studentData.rateStudent ? studentData.rateStudent : ''}</Text>
							</Box>
						</GridItem>
					</Grid>
				</CardFull>

				{/* Document Upload */}
				<CardFull>
					<Heading size="lg" mt={6} fontWeight="bold" color="blue.500">
						Document Uploads
						<Spacer p={4}></Spacer>
					</Heading>
					<Row>
						<Col md={12}>
							<Form onSubmit={handleUploadAll}>
								<DocumentUpload
									label="Resume"
									onFileSelect={(e) => setResumeFile(e.target.files[0])}
									selectedFile={resumeFile}
									onRemove={() => handleRemoveFile('resume', attachmentIds['resume'])}
									isFileUploaded={uploadedFiles['resume']}
									studentID={studentData ? studentData.studentID : null}
									attachmentId={attachmentIds ? attachmentIds['resume'] : null}
									uploadedFileName={uploadedFileName['resume']} // Pass the uploadedFileName prop
									setUploadedFileName={setUploadedFileName} // Pass the setUploadedFileName function
									filePath={filePaths['resume']}
									setUploadedFiles={setUploadedFiles} // Add this prop
									documentType="resume"
									setFilePaths={setFilePaths}
								/>

								<DocumentUpload
									label="X Standard Mark Sheet"
									onFileSelect={(e) => setXMarkSheetFile(e.target.files[0])}
									onSubmit={() => uploadDocument('xMarkSheet', xMarkSheetFile)}
									selectedFile={xMarkSheetFile}
									onRemove={() => handleRemoveFile('xMarkSheet', attachmentIds['xMarkSheet'])}
									isFileUploaded={uploadedFiles['xMarkSheet']}
									studentID={studentData ? studentData.studentID : null}
									attachmentId={attachmentIds ? attachmentIds['xMarkSheet'] : null}
									uploadedFileName={uploadedFileName['xMarkSheet']}
									setUploadedFileName={setUploadedFileName}
									filePath={filePaths['xMarkSheet']}
									documentType="xMarkSheet"
									setFilePaths={setFilePaths}
								/>

								<DocumentUpload
									label="XII Standard Mark Sheet"
									onFileSelect={(e) => setTwelfthMarkSheetFile(e.target.files[0])}
									onSubmit={() => uploadDocument('twelfthMarkSheet', twelfthMarkSheetFile)}
									selectedFile={twelfthMarkSheetFile}
									onRemove={() => handleRemoveFile('twelfthMarkSheet', attachmentIds['twelfthMarkSheet'])}
									isFileUploaded={uploadedFiles['twelfthMarkSheet']}
									studentID={studentData ? studentData.studentID : null}
									attachmentId={attachmentIds ? attachmentIds['twelfthMarkSheet'] : null}
									uploadedFileName={uploadedFileName['twelfthMarkSheet']}
									setUploadedFileName={setUploadedFileName}
									filePath={filePaths['twelfthMarkSheet']}
									documentType="twelfthMarkSheet"
									setFilePaths={setFilePaths}
								/>

								<DocumentUpload
									label="Diploma Mark Sheet"
									onFileSelect={(e) => setDiplomaMarkSheet(e.target.files[0])}
									onSubmit={() => uploadDocument('diplomaMarkSheet', diplomaMarkSheet)}
									selectedFile={diplomaMarkSheet}
									onRemove={() => handleRemoveFile('diplomaMarkSheet', attachmentIds['diplomaMarkSheet'])}
									isFileUploaded={uploadedFiles['diplomaMarkSheet']}
									studentID={studentData ? studentData.studentID : null}
									attachmentId={attachmentIds ? attachmentIds['diplomaMarkSheet'] : null}
									uploadedFileName={uploadedFileName['diplomaMarkSheet']}
									setUploadedFileName={setUploadedFileName}
									filePath={filePaths['diplomaMarkSheet']}
									documentType="diplomaMarkSheet"
									setFilePaths={setFilePaths}
								/>

								<DocumentUpload
									label="UG Mark Sheet"
									onFileSelect={(e) => setUgMarkSheet(e.target.files[0])}
									onSubmit={() => uploadDocument('ugMarkSheet', ugMarkSheet)}
									selectedFile={ugMarkSheet}
									onRemove={() => handleRemoveFile('ugMarkSheet', attachmentIds['ugMarkSheet'])}
									isFileUploaded={uploadedFiles['ugMarkSheet']}
									studentID={studentData ? studentData.studentID : null}
									attachmentId={attachmentIds ? attachmentIds['ugMarkSheet'] : null}
									uploadedFileName={uploadedFileName['ugMarkSheet']}
									setUploadedFileName={setUploadedFileName}
									filePath={filePaths['ugMarkSheet']}
									documentType="ugMarkSheet"
									setFilePaths={setFilePaths}
								/>

								<DocumentUpload
									label="PG Mark Sheet"
									onFileSelect={(e) => setPgMarkSheet(e.target.files[0])}
									onSubmit={() => uploadDocument('pgMarkSheet', pgMarkSheet)}
									selectedFile={pgMarkSheet}
									onRemove={() => handleRemoveFile('pgMarkSheet', attachmentIds['pgMarkSheet'])}
									isFileUploaded={uploadedFiles['pgMarkSheet']}
									studentID={studentData ? studentData.studentID : null}
									attachmentId={attachmentIds ? attachmentIds['pgMarkSheet'] : null}
									uploadedFileName={uploadedFileName['pgMarkSheet']}
									setUploadedFileName={setUploadedFileName}
									filePath={filePaths['pgMarkSheet']}
									documentType="pgMarkSheet"
									setFilePaths={setFilePaths}
								/>

								<DocumentUpload
									label="Diploma Award Certificate"
									onFileSelect={(e) => setDiplomaAwardCertificate(e.target.files[0])}
									onSubmit={() => uploadDocument('diplomaAwardCertificate', diplomaAwardCertificate)}
									selectedFile={diplomaAwardCertificate}
									onRemove={() => handleRemoveFile('diplomaAwardCertificate', attachmentIds['diplomaAwardCertificate'])}
									isFileUploaded={uploadedFiles['diplomaAwardCertificate']}
									studentID={studentData ? studentData.studentID : null}
									attachmentId={attachmentIds ? attachmentIds['diplomaAwardCertificate'] : null}
									uploadedFileName={uploadedFileName['diplomaAwardCertificate']}
									setUploadedFileName={setUploadedFileName}
									filePath={filePaths['diplomaAwardCertificate']}
									documentType="diplomaAwardCertificate"
									setFilePaths={setFilePaths}
								/>

								<DocumentUpload
									label="PG Degree Certificate"
									onFileSelect={(e) => setPgDegreeCertificate(e.target.files[0])}
									onSubmit={() => uploadDocument('pgDegreeCertificate', pgDegreeCertificate)}
									selectedFile={pgDegreeCertificate}
									onRemove={() => handleRemoveFile('pgDegreeCertificate', attachmentIds['pgDegreeCertificate'])}
									isFileUploaded={uploadedFiles['pgDegreeCertificate']}
									studentID={studentData ? studentData.studentID : null}
									attachmentId={attachmentIds ? attachmentIds['pgDegreeCertificate'] : null}
									uploadedFileName={uploadedFileName['pgDegreeCertificate']}
									setUploadedFileName={setUploadedFileName}
									filePath={filePaths['pgDegreeCertificate']}
									documentType="pgDegreeCertificate"
									setFilePaths={setFilePaths}
								/>

								<DocumentUpload
									label="Transfer Certificate"
									onFileSelect={(e) => setTransferCertificate(e.target.files[0])}
									onSubmit={() => uploadDocument('transferCertificate', transferCertificate)}
									selectedFile={transferCertificate}
									onRemove={() => handleRemoveFile('transferCertificate', attachmentIds['transferCertificate'])}
									isFileUploaded={uploadedFiles['transferCertificate']}
									studentID={studentData ? studentData.studentID : null}
									attachmentId={attachmentIds ? attachmentIds['transferCertificate'] : null}
									uploadedFileName={uploadedFileName['transferCertificate']}
									setUploadedFileName={setUploadedFileName}
									filePath={filePaths['transferCertificate']}
									documentType="transferCertificate"
									setFilePaths={setFilePaths}
								/>

								<DocumentUpload
									label="PTE / IELTS Certificate"
									onFileSelect={(e) => setGreGmatCertificate(e.target.files[0])}
									onSubmit={() => uploadDocument('pteIeltsCertificate', pteIeltsCertificate)}
									selectedFile={pteIeltsCertificate}
									onRemove={() => handleRemoveFile('pteIeltsCertificate', attachmentIds['pteIeltsCertificate'])}
									isFileUploaded={uploadedFiles['pteIeltsCertificate']}
									studentID={studentData ? studentData.studentID : null}
									attachmentId={attachmentIds ? attachmentIds['pteIeltsCertificate'] : null}
									uploadedFileName={uploadedFileName['pteIeltsCertificate']}
									setUploadedFileName={setUploadedFileName}
									filePath={filePaths['pteIeltsCertificate']}
									documentType="pteIeltsCertificate"
									setFilePaths={setFilePaths}
								/>

								<DocumentUpload
									label="Letter of Reference - Employer"
									onFileSelect={(e) => setEmployerReferenceLetter(e.target.files[0])}
									onSubmit={() => uploadDocument('employerReferenceLetter', employerReferenceLetter)}
									selectedFile={employerReferenceLetter}
									onRemove={() => handleRemoveFile('employerReferenceLetter', attachmentIds['employerReferenceLetter'])}
									isFileUploaded={uploadedFiles['employerReferenceLetter']}
									studentID={studentData ? studentData.studentID : null}
									attachmentId={attachmentIds ? attachmentIds['employerReferenceLetter'] : null}
									uploadedFileName={uploadedFileName['employerReferenceLetter']}
									setUploadedFileName={setUploadedFileName}
									filePath={filePaths['employerReferenceLetter']}
									documentType="employerReferenceLetter"
									setFilePaths={setFilePaths}
								/>

								<DocumentUpload
									label="Course Application Form"
									onFileSelect={(e) => setCourseApplicationForm(e.target.files[0])}
									onSubmit={() => uploadDocument('courseApplicationForm', courseApplicationForm)}
									selectedFile={courseApplicationForm}
									onRemove={() => handleRemoveFile('courseApplicationForm', attachmentIds['courseApplicationForm'])}
									isFileUploaded={uploadedFiles['courseApplicationForm']}
									studentID={studentData ? studentData.studentID : null}
									attachmentId={attachmentIds ? attachmentIds['courseApplicationForm'] : null}
									uploadedFileName={uploadedFileName['courseApplicationForm']}
									setUploadedFileName={setUploadedFileName}
									filePath={filePaths['courseApplicationForm']}
									documentType="courseApplicationForm"
									setFilePaths={setFilePaths}
								/>

								<DocumentUpload
									label="Passport Size Photograph"
									onFileSelect={(e) => setPassportSizePhoto(e.target.files[0])}
									onSubmit={() => uploadDocument('passportSizePhoto', passportSizePhoto)}
									selectedFile={passportSizePhoto}
									onRemove={() => handleRemoveFile('passportSizePhoto', attachmentIds['passportSizePhoto'])}
									isFileUploaded={uploadedFiles['passportSizePhoto']}
									studentID={studentData ? studentData.studentID : null}
									attachmentId={attachmentIds ? attachmentIds['passportSizePhoto'] : null}
									uploadedFileName={uploadedFileName['passportSizePhoto']}
									setUploadedFileName={setUploadedFileName}
									filePath={filePaths['passportSizePhoto']}
									documentType="passportSizePhoto"
									setFilePaths={setFilePaths}
								/>

								<DocumentUpload
									label="Police Clearance Certificate"
									onFileSelect={(e) => setPoliceClearanceCertificate(e.target.files[0])}
									onSubmit={() => uploadDocument('policeClearanceCertificate', policeClearanceCertificate)}
									selectedFile={policeClearanceCertificate}
									onRemove={() => handleRemoveFile('policeClearanceCertificate', attachmentIds['policeClearanceCertificate'])}
									isFileUploaded={uploadedFiles['policeClearanceCertificate']}
									studentID={studentData ? studentData.studentID : null}
									attachmentId={attachmentIds ? attachmentIds['policeClearanceCertificate'] : null}
									uploadedFileName={uploadedFileName['policeClearanceCertificate']}
									setUploadedFileName={setUploadedFileName}
									filePath={filePaths['policeClearanceCertificate']}
									documentType="policeClearanceCertificate"
									setFilePaths={setFilePaths}
								/>

								<DocumentUpload
									label="Fees Receipt"
									onFileSelect={(e) => setFeesReceipt(e.target.files[0])}
									onSubmit={() => uploadDocument('feesReceipt', feesReceipt)}
									selectedFile={feesReceipt}
									onRemove={() => handleRemoveFile('feesReceipt', attachmentIds['feesReceipt'])}
									isFileUploaded={uploadedFiles['feesReceipt']}
									studentID={studentData ? studentData.studentID : null}
									attachmentId={attachmentIds ? attachmentIds['feesReceipt'] : null}
									uploadedFileName={uploadedFileName['feesReceipt']}
									setUploadedFileName={setUploadedFileName}
									filePath={filePaths['feesReceipt']}
									documentType="feesReceipt"
									setFilePaths={setFilePaths}
								/>


								<Button
									type="submit"
									disabled={
										!resumeFile && !xMarkSheetFile && !twelfthMarkSheetFile && !diplomaMarkSheet && !ugMarkSheet &&
										!pgMarkSheet && !diplomaAwardCertificate && !pgDegreeCertificate &&
										!transferCertificate && !pteIeltsCertificate && !employerReferenceLetter &&
										!courseApplicationForm && !passportSizePhoto && !policeClearanceCertificate &&
										!feesReceipt
									}
								>
									Upload All Documents
								</Button>

							</Form>
						</Col>
					</Row>
				</CardFull>

				<CardFull>
					<Heading size="lg" mt={6} fontWeight="bold" color="blue.500">
						Comments
						<Spacer p={4}></Spacer>
					</Heading>
					<Row>
						<Col md={10}>
							<CommentForm
								comment={comment}
								setComment={setComment}
								setAttachments={setAttachments}
								handleSubmitComment={handleSubmitComment}
								isSubmitEnabled={isSubmitEnabled}
								fileInputRef={fileInputRef}
								visibility={visibility}
								setVisibility={setVisibility}
							/>

							<CommentsList
								comments={currentComments}
								editingComment={editingComment}
								handleEditComment={handleEditComment}
								deleteComment={deleteComment}
								cancelEdit={cancelEdit}
								submitEditedComment={submitEditedComment}
								isImage={isImage}
								formatCommentDate={formatCommentDate}
								isAdmin={isAdmin}
								setEditingComment={setEditingComment}
								commentsPerPage={commentsPerPage}
								renderPageNumbers={renderPageNumbers}
								mode={mode}
							/>
						</Col>
					</Row>
				</CardFull>

			</Container>
		</Main >

	);
};

export default EnquiryDetails;