const EPITACourses = [
	{ value: "International Bachelor of Computer Science", label: "International Bachelor of Computer Science" },
	{ value: "MSc (AIS) Master of Science in Artificial Intelligence Systems", label: "MSc (AIS) Master of Science in Artificial Intelligence Systems" },
	{ value: "MSc (CS) Master of Science in Computer Science - Innovative Information Systems Management (i-ISM)", label: "MSc (CS) Master of Science in Computer Science - Innovative Information Systems Management (i-ISM)" },
	{ value: "MSc (CS) Master of Science in Computer Science - Computer Security", label: "MSc (CS) Master of Science in Computer Science - Computer Security" },
	{ value: "MSc (CS) Master of Science in Computer Science - Software Engineering (SE)", label: "MSc (CS) Master of Science in Computer Science - Software Engineering (SE)" },
	{ value: "MSc (CS) Master of Science in Computer Science - Data Science & Analytics (DSA)", label: "MSc (CS) Master of Science in Computer Science - Data Science & Analytics (DSA)" },
	{ value: "ME Master in Computer Engineering - Global IT Management", label: "ME Master in Computer Engineering - Global IT Management" },
	{ value: "ME Master in Computer Engineering - Multimedia & Information Technology", label: "ME Master in Computer Engineering - Multimedia & Information Technology" },
	{ value: "ME Master in Computer Engineering - Systems, Networks & Security", label: "ME Master in Computer Engineering - Systems, Networks & Security" },
	{ value: "MSc in Artificial Intelligence for Marketing Strategy", label: "MSc in Artificial Intelligence for Marketing Strategy" },
	{ value: "ME Master in Computer Engineering - Embedded & Realtime Systems", label: "ME Master in Computer Engineering - Embedded & Realtime Systems" },
	{ value: "ME Master in Computer Engineering - Telecommunications & Networks", label: "ME Master in Computer Engineering - Telecommunications & Networks" },
	{ value: "ME Master in Computer Engineering - Information System & Software Engineering", label: "ME Master in Computer Engineering - Information System & Software Engineering" },
	{ value: "ME Master in Computer Engineering - Data Science & Artificial Intelligence", label: "ME Master in Computer Engineering - Data Science & Artificial Intelligence" },
	{ value: "ME Master in Computer Engineering - Image Processing", label: "ME Master in Computer Engineering - Image Processing" }
];

export default EPITACourses;