import React, { useState, useEffect } from 'react';
import { Button, Table } from 'reactstrap';
import AccountLayout from '../layout/AccountLayout';
import {
	useToast, useDisclosure, AlertDialog,
	AlertDialogBody, AlertDialogFooter, AlertDialogHeader,
	AlertDialogContent, AlertDialogOverlay, Switch, Box, Input, useBreakpointValue
} from '@chakra-ui/react';

const UsersList = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const cancelRef = React.useRef();
	const [users, setUsers] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [recordsPerPage] = useState(20);
	const [userToDelete, setUserToDelete] = useState(null);
	const [searchTerm, setSearchTerm] = useState("");

	const toast = useToast();

	useEffect(() => {
		// Function to fetch users from the backend
		const fetchUsers = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users`);
				const data = await response.json();
				setUsers(data); // Set users in state, including their status
			} catch (error) {
				console.error('Error fetching users:', error);
				toast({
					title: 'Error',
					description: 'Unable to fetch user data.',
					status: 'error',
					duration: 5000,
					isClosable: true,
				});
			}
		};

		fetchUsers();
	}, [toast]);

	const toggleUserStatus = async (id, currentStatus) => {
		// Prepare the new status
		const newStatus = currentStatus === 'active' ? 'inactive' : 'active';

		// Optimistically update the UI
		const updatedUsers = users.map(user =>
			user._id === id ? { ...user, status: newStatus } : user
		);
		setUsers(updatedUsers);

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/${id}/toggle-status`, {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ status: newStatus }),
			});
			if (!response.ok) {
				throw new Error('Failed to toggle the user status.');
			}
			toast({
				title: 'Success',
				description: `User status updated to ${newStatus}.`,
				status: 'success',
				duration: 5000,
				isClosable: true,
			});
		} catch (error) {
			console.error('Error:', error);
			// Revert to original status in case of an error
			setUsers(users.map(user =>
				user._id === id ? { ...user, status: currentStatus } : user
			));
			toast({
				title: 'Error',
				description: 'There was an error updating the user status. The change has been reverted.',
				status: 'error',
				duration: 5000,
				isClosable: true,
			});
		}
	};

	const filteredUsers = searchTerm.length === 0 ? users : users.filter((user) =>
		user.userName.toLowerCase().includes(searchTerm.toLowerCase()) ||
		user.email.toLowerCase().includes(searchTerm.toLowerCase())
	);

	const lastIndexOfPage = currentPage * recordsPerPage;
	const firstIndexOfPage = lastIndexOfPage - recordsPerPage;
	const currentUsers = filteredUsers.slice(firstIndexOfPage, lastIndexOfPage);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);
	const pageNumbers = [];
	for (let i = 1; i <= Math.ceil(filteredUsers.length / recordsPerPage); i++) {
		pageNumbers.push(i);
	}


	const deleteUser = async () => {
		if (!userToDelete) return;

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/${userToDelete._id}`, {
				method: 'DELETE',
			});
			if (!response.ok) {
				throw new Error('Failed to delete the user.');
			}
			setUsers(users.filter(user => user._id !== userToDelete._id));
			toast({
				title: 'User deleted',
				description: `${userToDelete.userName} has been successfully deleted.`,
				status: 'success',
				duration: 5000,
				isClosable: true,
			});
		} catch (error) {
			console.error('Error:', error);
			toast({
				title: 'Error deleting user',
				description: 'There was an issue deleting the user.',
				status: 'error',
				duration: 5000,
				isClosable: true,
			});
		} finally {
			setUserToDelete(null); // Reset the userToDelete after operation
			onClose(); // Close the dialog
		}
	};

	const handleSearchTermChange = (e) => {
		setSearchTerm(e.target.value);
		setCurrentPage(1); // Reset current page to 1 when searching
	};

	const minWidth = useBreakpointValue({ base: '100%', md: '600px' });

	if (users.length === 0) {
		return <div>Loading users...</div>;
	}

	return (
		<>
			<AccountLayout>
				<Box style={{ minWidth: minWidth }}>
					<Input
						placeholder="Search users..."
						value={searchTerm}
						onChange={handleSearchTermChange}
						my={4}
					/>
				</Box>
				<Table className='table table-striped table-bordered'>
					<thead>
						<tr>
							<th>User Name</th>
							<th>Email</th>
							<th>Status</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{currentUsers.map((user) => (
							<tr key={user._id}>
								<td>{user.userName}</td>
								<td>{user.email}</td>
								<td>
									<Switch
										isChecked={user.status === 'active'}
										onChange={() => toggleUserStatus(user._id, user.status)}
										colorScheme="teal"
									/>
								</td>
								<td>
									<Button color="danger" onClick={() => { setUserToDelete(user); onOpen(); }}>
										Delete
									</Button>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
				{filteredUsers.length > recordsPerPage && (
					<nav>
						<ul className='pagination'>
							{pageNumbers.map(number => (
								<li key={number} className='page-item'>
									<button onClick={() => paginate(number)} className='page-link'>
										{number}
									</button>
								</li>
							))}
						</ul>
					</nav>
				)}
			</AccountLayout>
			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={() => {
					onClose();
					setUserToDelete(null); // Reset on close
				}}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Delete User
						</AlertDialogHeader>
						<AlertDialogBody>
							Are you sure you want to delete <strong>{userToDelete ? userToDelete.userName : ''}</strong>? You can't undo this action afterwards.
						</AlertDialogBody>
						<AlertDialogFooter gap="3">
							<Button ref={cancelRef} onClick={() => {
								onClose();
								setUserToDelete(null); // Reset on close
							}}>
								Cancel
							</Button>
							<Button color="danger" onClick={deleteUser}>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
};

export default UsersList;
