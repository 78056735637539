import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Box,
	VStack,
	FormControl,
	FormLabel,
	Input,
	Button,
	Alert,
	AlertIcon,
	AlertDescription,
	CloseButton,
	Flex,
	Link as ChakraLink,
	useToast,
	Center,
	Select
} from '@chakra-ui/react';

import { Card } from './global/holder/Card';

const AccountRegister = () => {
	const toast = useToast();
	const navigate = useNavigate();

	const [formData, setFormData] = useState({
		userName: '',
		email: '',
		password: '',
		confirmPassword: '',
		privilege: 'Editor', // Default privilege
		branchId: '',
	});

	const [branches, setBranches] = useState([]);
	const [passwordError, setPasswordError] = useState('');
	const [passwordValidateError, setPasswordValidateError] = useState('');
	const [passwordShown, setPasswordShown] = useState(false);
	const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

	useEffect(() => {
		const fetchBranches = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}/api/branches`);
				const data = await response.json();
				setBranches(data); // Set the list of branches in the state
			} catch (error) {
				console.error('Fetch branches error:', error);
				toast({
					title: 'Error fetching branches',
					description: 'Unable to load branch data.',
					status: 'error',
					duration: 9000,
					isClosable: true,
				});
			}
		};

		fetchBranches();
	}, [toast]);

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const togglePasswordVisibility = () => {
		setPasswordShown(!passwordShown);
	};

	const toggleConfirmPasswordVisibility = () => {
		setConfirmPasswordShown(!confirmPasswordShown);
	};

	const submitHandler = async (e) => {
		e.preventDefault();

		if (formData.password !== formData.confirmPassword) {
			setPasswordError('Passwords do not match');
			return;
		}
		setPasswordError('');

		// Password validation
		const isValidPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(formData.password);
		if (!isValidPassword && formData.password.length > 0) {
			setPasswordValidateError('Password must be at least 8 characters long and include a capital letter, number, and special character.');
			return;
		}
		setPasswordValidateError('');

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/register`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					userName: formData.userName,
					email: formData.email,
					password: formData.password,
					privilege: formData.privilege,
					branchId: formData.branchId
				}),
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
			toast({
				title: 'Account Created',
				description: 'The user account has been successfully registered.',
				status: 'success',
				duration: 5000,
				isClosable: true,
			});

			navigate('/login', { replace: true });
		} catch (error) {
			console.error('Error:', error);
			toast({
				title: 'Registration failed',
				description: 'Error registering user. Please try again.',
				status: 'error',
				duration: 5000,
				isClosable: true,
			});
		}
	};

	return (
		<Center h="100vh">
			<Card>
				<VStack spacing={4} align="stretch">
					<h1>Register a new user</h1>
					<Box as="form" onSubmit={submitHandler}>

						<FormControl id="branchId" isRequired>
							<FormLabel>Branch</FormLabel>
							<Select
								name="branchId"
								value={formData.branchId}
								onChange={handleChange}
							>
								<option value="">Select a branch</option>
								{branches.map((branch) => (
									<option key={branch.value} value={branch.value}>{branch.label}</option>
								))}
							</Select>
						</FormControl>

						{/* Username and Email Input Fields */}
						<FormControl id="userName" isRequired>
							<FormLabel>Username</FormLabel>
							<Input
								type="text"
								name="userName"
								value={formData.userName}
								onChange={handleChange}
							/>
						</FormControl>

						<FormControl id="email" isRequired>
							<FormLabel>Email</FormLabel>
							<Input
								type="email"
								name="email"
								value={formData.email}
								onChange={handleChange}
							/>
						</FormControl>

						{/* Password and Confirm Password Input Fields */}
						<FormControl id="password" isRequired>
							<FormLabel>Password</FormLabel>
							<Input
								type={passwordShown ? 'text' : 'password'}
								name="password"
								value={formData.password}
								onChange={handleChange}
							/>
							<Button onClick={togglePasswordVisibility} minW="80px">
								{passwordShown ? 'Hide' : 'Show'}
							</Button>
						</FormControl>

						<FormControl id="confirmPassword" isRequired>
							<FormLabel>Confirm Password</FormLabel>
							<Input
								type={confirmPasswordShown ? 'text' : 'password'}
								name="confirmPassword"
								value={formData.confirmPassword}
								onChange={handleChange}
							/>
							<Button onClick={toggleConfirmPasswordVisibility} minW="80px">
								{confirmPasswordShown ? 'Hide' : 'Show'}
							</Button>
						</FormControl>

						{/* Error Messages */}
						{passwordError && (
							<Alert status="error" mt={4}>
								<AlertIcon />
								<AlertDescription>{passwordError}</AlertDescription>
								<CloseButton
									position="absolute"
									right="8px"
									top="8px"
									onClick={() => setPasswordError('')}
								/>
							</Alert>
						)}

						{passwordValidateError && (
							<Alert status="error" mt={4}>
								<AlertIcon />
								<AlertDescription>{passwordValidateError}</AlertDescription>
								<CloseButton
									position="absolute"
									right="8px"
									top="8px"
									onClick={() => setPasswordValidateError('')}
								/>
							</Alert>
						)}

						{/* Submit Button */}
						<Button mt={4} colorScheme="blue" type="submit">
							Register
						</Button>

						{/* Already a user? Login Now */}
						<Flex mt={4} justifyContent="center">
							Already a user?
							<ChakraLink
								color="teal.500"
								ml={2}
								onClick={() => navigate('/login')}
							>
								Login Now
							</ChakraLink>
						</Flex>
					</Box>
				</VStack>
			</Card>
		</Center>
	);
};

export default AccountRegister;
