const TransportTelecommunicationCourses = [
	{ value: "Bachelors in Computer Sciences", label: "Bachelors in Computer Sciences" },
	{ value: "Bachelors Professional in Robotics", label: "Bachelors Professional in Robotics" },
	{ value: "Masters in Management of Information systems", label: "Masters in Management of Information systems" },
	{ value: "Masters in Computer Sciences-Software Engineering", label: "Masters in Computer Sciences-Software Engineering" },
	{ value: "Bachelors professional in Transport and Business Logistics", label: "Bachelors professional in Transport and Business Logistics" },
	{ value: "Bachelors in Aviation Engineering", label: "Bachelors in Aviation Engineering" },
	{ value: "Masters in Transport and Logistics", label: "Masters in Transport and Logistics" },
	{ value: "Masters in Business and Management", label: "Masters in Business and Management" },
	{ value: "Bachelors in Computer Sciences (UWE Bristol)", label: "Bachelors in Computer Sciences (UWE Bristol)" },
	{ value: "Bachelors in Business and Management", label: "Bachelors in Business and Management" },
	{ value: "Phd in Digital Economy and Business", label: "Phd in Digital Economy and Business" },
	{ value: "Masters in Computer Sciences (UWE Bristol)", label: "Masters in Computer Sciences (UWE Bristol)" },
	{ value: "Masters in Aviation Management (UWE Bristol)", label: "Masters in Aviation Management (UWE Bristol)" },
	{ value: "Bachelors in Electronics, Engineering", label: "Bachelors in Electronics, Engineering" },
	{ value: "Masters in Engineering Science in Electronics", label: "Masters in Engineering Science in Electronics" },
	{ value: "Phd in Telematics and Logistics", label: "Phd in Telematics and Logistics" }
];

export default TransportTelecommunicationCourses;
