const BoltonCourses = [
	{ value: "BA (Hons) Accountancy", label: "BA (Hons) Accountancy" },
	{ value: "BSc (Hons) Sport Rehabilitation", label: "BSc (Hons) Sport Rehabilitation" },
	{ value: "BSc (Hons) Health and Social Care Final Year (Top-up)", label: "BSc (Hons) Health and Social Care Final Year (Top-up)" },
	{ value: "BEng (Hons) Mechanical Engineering with foundation year", label: "BEng (Hons) Mechanical Engineering with foundation year" },
	{ value: "BDes (Hons) Special Make-up Effects for Film and TV with foundation year", label: "BDes (Hons) Special Make-up Effects for Film and TV with foundation year" },
	{ value: "BEng (Hons) Electrical and Electronics Engineering", label: "BEng (Hons) Electrical and Electronics Engineering" },
	{ value: "BSc (Hons) Health and Social Care", label: "BSc (Hons) Health and Social Care" },
	{ value: "BDes (Hons) Special Effects Modelmaking for Film and Television", label: "BDes (Hons) Special Effects Modelmaking for Film and Television" },
	{ value: "MSc Cloud and Network Security (May Start)", label: "MSc Cloud and Network Security (May Start)" },
	{ value: "BSc (Hons) Computing", label: "BSc (Hons) Computing" },
	{ value: "MSc Motorsport Engineering", label: "MSc Motorsport Engineering" },
	{ value: "BSc (Hons) Business Management (Top-up)", label: "BSc (Hons) Business Management (Top-up)" },
	{ value: "BA (Hons) Fashion (HE6 Top-up)", label: "BA (Hons) Fashion (HE6 Top-up)" },
	{ value: "MBA Business Administration (May Start)", label: "MBA Business Administration (May Start)" },
	{ value: "MSc Cloud and Network Security", label: "MSc Cloud and Network Security" },
	{ value: "BSc (Hons) Games Design with foundation year", label: "BSc (Hons) Games Design with foundation year" },
	{ value: "BA (Hons) Education and Learning", label: "BA (Hons) Education and Learning" },
	{ value: "BSc (Hons) Visual Effects and Animation", label: "BSc (Hons) Visual Effects and Animation" },
	{ value: "BEng (Hons) Software Engineering (HE6 Top-up)", label: "BEng (Hons) Software Engineering (HE6 Top-up)" },
	{ value: "MSc Entrepreneurship and Innovation (Extended)", label: "MSc Entrepreneurship and Innovation (Extended)" },
	{ value: "BSc (Hons) Motorsport Technology with foundation year", label: "BSc (Hons) Motorsport Technology with foundation year" },
	{ value: "BA (Hons) Fashion", label: "BA (Hons) Fashion" },
	{ value: "BA (Hons) Fine Art", label: "BA (Hons) Fine Art" },
	{ value: "BA (Hons) Social Work", label: "BA (Hons) Social Work" },
	{ value: "BEng (Hons) Software Engineering", label: "BEng (Hons) Software Engineering" },
	{ value: "BA (Hons) Community Development and Youth Studies (Top-up)", label: "BA (Hons) Community Development and Youth Studies (Top-up)" },
	{ value: "BA (Hons) Graphic Design with foundation year", label: "BA (Hons) Graphic Design with foundation year" },
	{ value: "BSc (Hons) Psychology and Cognitive Neuroscience", label: "BSc (Hons) Psychology and Cognitive Neuroscience" },
	{ value: "BA (Hons) Film and Media Production", label: "BA (Hons) Film and Media Production" },
	{ value: "MRes Environmental Management", label: "MRes Environmental Management" },
	{ value: "BA (Hons) Fine Art with foundation year", label: "BA (Hons) Fine Art with foundation year" },
	{ value: "BA (Hons) Games Art", label: "BA (Hons) Games Art" },
	{ value: "BA (Hons) Film and Media Production (Top-up)", label: "BA (Hons) Film and Media Production (Top-up)" },
	{ value: "MSc Biomedical Engineering", label: "MSc Biomedical Engineering" },
	{ value: "BEng (Hons) Electrical and Electronics Engineering with foundation year", label: "BEng (Hons) Electrical and Electronics Engineering with foundation year" },
	{ value: "BA (Hons) Digital Content Creation", label: "BA (Hons) Digital Content Creation" },
	{ value: "LLB (Hons) Law", label: "LLB (Hons) Law" },
	{ value: "BEng (Hons) Mechanical Engineering", label: "BEng (Hons) Mechanical Engineering" },
	{ value: "BA (Hons) Accountancy with foundation year", label: "BA (Hons) Accountancy with foundation year" },
	{ value: "BSc (Hons) Computing with foundation year", label: "BSc (Hons) Computing with foundation year" },
	{ value: "BSc (Hons) Sport Rehabilitation with foundation year", label: "BSc (Hons) Sport Rehabilitation with foundation year" },
	{ value: "BSc (Hons) Computing (Cyber Security)", label: "BSc (Hons) Computing (Cyber Security)" },
	{ value: "BDes (Hons) Special Effects for Film and Television", label: "BDes (Hons) Special Effects for Film and Television" },
	{ value: "MA Arts Management", label: "MA Arts Management" },
	{ value: "MSc Counselling and Positive Psychology", label: "MSc Counselling and Positive Psychology" },
	{ value: "MSc Systems Engineering (Mechanical) and Engineering Management", label: "MSc Systems Engineering (Mechanical) and Engineering Management" },
	{ value: "BSc (Hons) Criminological and Forensic Psychology with foundation year", label: "BSc (Hons) Criminological and Forensic Psychology with foundation year" },
	{ value: "BSc (Hons) Games Programming with foundation year", label: "BSc (Hons) Games Programming with foundation year" },
	{ value: "BA (Hons) Creative Writing", label: "BA (Hons) Creative Writing" },
	{ value: "BSc (Hons) Criminological and Forensic Psychology", label: "BSc (Hons) Criminological and Forensic Psychology" },
	{ value: "BSc (Hons) Digital Management and Marketing", label: "BSc (Hons) Digital Management and Marketing" },
	{ value: "BA (Hons) Fashion Photography", label: "BA (Hons) Fashion Photography" },
	{ value: "BSc (Hons) Psychology", label: "BSc (Hons) Psychology" },
	{ value: "MA Early Childhood Studies", label: "MA Early Childhood Studies" },
	{ value: "MRes Engineering Management", label: "MRes Engineering Management" },
	{ value: "BSc (Hons) Computing (Cyber Security) with foundation year", label: "BSc (Hons) Computing (Cyber Security) with foundation year" },
	{ value: "BA (Hons) Crime and Criminal Justice (HE6 Top-up)", label: "BA (Hons) Crime and Criminal Justice (HE6 Top-up)" },
	{ value: "MSc Civil Engineering", label: "MSc Civil Engineering" },
	{ value: "MA Inclusive Education", label: "MA Inclusive Education" },
	{ value: "BA (Hons) Textiles and Surface Design with foundation year", label: "BA (Hons) Textiles and Surface Design with foundation year" },
	{ value: "MSc Artificial Intelligence", label: "MSc Artificial Intelligence" },
	{ value: "BEng (Hons) Civil Engineering", label: "BEng (Hons) Civil Engineering" },
	{ value: "BDes (Hons) Special Make-up Effects for Film and TV", label: "BDes (Hons) Special Make-up Effects for Film and TV" },
	{ value: "MSc Applied Sport and Exercise Psychology", label: "MSc Applied Sport and Exercise Psychology" },
	{ value: "MA Global Educational Leadership", label: "MA Global Educational Leadership" },
	{ value: "BA (Hons) Early Years Childhood Studies", label: "BA (Hons) Early Years Childhood Studies" },
	{ value: "BSc (Hons) Computing (Data Analyst)", label: "BSc (Hons) Computing (Data Analyst)" },
	{ value: "BSc (Hons) Computer Networks and Security (HE6 Top-up)", label: "BSc (Hons) Computer Networks and Security (HE6 Top-up)" },
	{ value: "BSc (Hons) Mechatronics (Top-up)", label: "BSc (Hons) Mechatronics (Top-up)" },
	{ value: "MA Games Development", label: "MA Games Development" },
	{ value: "MSc Mechanical Engineering", label: "MSc Mechanical Engineering" },
	{ value: "BSc (Hons) Motorsport Technology", label: "BSc (Hons) Motorsport Technology" },
	{ value: "MSc Accountancy and Financial Management", label: "MSc Accountancy and Financial Management" },
	{ value: "MSc International Management (May Start)", label: "MSc International Management (May Start)" },
	{ value: "BSc (Hons) Health and Social Care with foundation year", label: "BSc (Hons) Health and Social Care with foundation year" },
	{ value: "LLB (Hons) Law with foundation year", label: "LLB (Hons) Law with foundation year" },
	{ value: "MSc Digital Dental Technology", label: "MSc Digital Dental Technology" },
	{ value: "MSc Human Society (with specialism)", label: "MSc Human Society (with specialism)" },
	{ value: "BSc (Hons) Games Programming", label: "BSc (Hons) Games Programming" },
	{ value: "MSc Electrical and Electronic Engineering", label: "MSc Electrical and Electronic Engineering" },
	{ value: "BA (Hons) Games Art with foundation year", label: "BA (Hons) Games Art with foundation year" },
	{ value: "BDes (Hons) Special Effects Modelmaking for Film and Television with foundation year", label: "BDes (Hons) Special Effects Modelmaking for Film and Television with foundation year" },
	{ value: "BA (Hons) Community Development and Youth Work (endorsed route)", label: "BA (Hons) Community Development and Youth Work (endorsed route)" },
	{ value: "BA (Hons) Animation and Illustration", label: "BA (Hons) Animation and Illustration" },
	{ value: "BSc (Hons) Business Management", label: "BSc (Hons) Business Management" },
	{ value: "BA (Hons) Photography", label: "BA (Hons) Photography" },
	{ value: "BA (Hons) Photojournalism and Documentary Photography", label: "BA (Hons) Photojournalism and Documentary Photography" },
	{ value: "BSc (Hons) Psychology, Psychotherapy and Counselling", label: "BSc (Hons) Psychology, Psychotherapy and Counselling" },
	{ value: "LLM International Business Law", label: "LLM International Business Law" },
	{ value: "MA Social Work", label: "MA Social Work" },
	{ value: "BEng (Hons) Biomedical Engineering with foundation year", label: "BEng (Hons) Biomedical Engineering with foundation year" },
	{ value: "BA (Hons) Photography with foundation year", label: "BA (Hons) Photography with foundation year" },
	{ value: "MSc Social Care, Health and Wellbeing", label: "MSc Social Care, Health and Wellbeing" },
	{ value: "BSc (Hons) Medical Biology with foundation year", label: "BSc (Hons) Medical Biology with foundation year" },
	{ value: "BSc (Hons) Psychology and Cognitive Neuroscience with foundation year", label: "BSc (Hons) Psychology and Cognitive Neuroscience with foundation year" },
	{ value: "BSc (Hons) Psychology, Psychotherapy and Counselling with foundation year", label: "BSc (Hons) Psychology, Psychotherapy and Counselling with foundation year" },
	{ value: "BSc (Hons) Computer Networks and Security", label: "BSc (Hons) Computer Networks and Security" },
	{ value: "MBA (Global Healthcare Management)", label: "MBA (Global Healthcare Management)" },
	{ value: "MSc International Management", label: "MSc International Management" },
	{ value: "MSc Strength and Conditioning", label: "MSc Strength and Conditioning" },
	{ value: "MSc Entrepreneurship and Innovation", label: "MSc Entrepreneurship and Innovation" },
	{ value: "MSc Systems Engineering (Mechatronics) and Engineering Management", label: "MSc Systems Engineering (Mechatronics) and Engineering Management" },
	{ value: "BSc (Hons) Business Management with foundation year", label: "BSc (Hons) Business Management with foundation year" },
	{ value: "BEng (Hons) Civil Engineering with foundation year", label: "BEng (Hons) Civil Engineering with foundation year" },
	{ value: "BSc (Hons) Dental Technology", label: "BSc (Hons) Dental Technology" },
	{ value: "BSc (Hons) Dental Technology (Top-up)", label: "BSc (Hons) Dental Technology (Top-up)" },
	{ value: "MA Visual Journalism and Storytelling", label: "MA Visual Journalism and Storytelling" },
	{ value: "BA (Hons) Textiles and Surface Design", label: "BA (Hons) Textiles and Surface Design" },
	{ value: "BEng (Hons) Biomedical Engineering", label: "BEng (Hons) Biomedical Engineering" },
	{ value: "BSc (Hons) Medical Biology", label: "BSc (Hons) Medical Biology" },
	{ value: "BA (Hons) Early Years Childhood Studies (Top-up)", label: "BA (Hons) Early Years Childhood Studies (Top-up)" },
	{ value: "BA (Hons) Animation and Illustration with foundation year", label: "BA (Hons) Animation and Illustration with foundation year" },
	{ value: "BA (Hons) Crime and Criminal Justice", label: "BA (Hons) Crime and Criminal Justice" },
	{ value: "BA (Hons) English", label: "BA (Hons) English" },
	{ value: "MA (Specialist Title) via Creative Practice", label: "MA (Specialist Title) via Creative Practice" },
	{ value: "MSc Electric Vehicle Technology (May Start)", label: "MSc Electric Vehicle Technology (May Start)" },
	{ value: "LLM Law", label: "LLM Law" },
	{ value: "BA (Hons) Fashion with foundation year", label: "BA (Hons) Fashion with foundation year" },
	{ value: "BDes (Hons) Special Effects for Film and Television with foundation year", label: "BDes (Hons) Special Effects for Film and Television with foundation year" },
	{ value: "MEd Professional Development in Education", label: "MEd Professional Development in Education" },
	{ value: "MA Educational Leadership", label: "MA Educational Leadership" },
	{ value: "MSc Systems Engineering (Electronic) and Engineering Management", label: "MSc Systems Engineering (Electronic) and Engineering Management" },
	{ value: "BEng (Hons) Automotive Performance Engineering (Motorsport) with foundation year", label: "BEng (Hons) Automotive Performance Engineering (Motorsport) with foundation year" },
	{ value: "BEng (Hons) Software Engineering With Foundation Year", label: "BEng (Hons) Software Engineering With Foundation Year" },
	{ value: "BEng (Hons) Automotive Performance Engineering (Motorsport)", label: "BEng (Hons) Automotive Performance Engineering (Motorsport)" },
	{ value: "MSc Social Neuroscience", label: "MSc Social Neuroscience" },
	{ value: "MSc Software Engineering", label: "MSc Software Engineering" },
	{ value: "BSc (Hons) Psychology with foundation year", label: "BSc (Hons) Psychology with foundation year" },
	{ value: "BA (Hons) Graphic Design", label: "BA (Hons) Graphic Design" },
	{ value: "BSc (Hons) Computing (Top-up)", label: "BSc (Hons) Computing (Top-up)" },
	{ value: "MSc Construction Project Management", label: "MSc Construction Project Management" },
	{ value: "MSc Electric Vehicle Technology", label: "MSc Electric Vehicle Technology" },
	{ value: "MSc Engineering Management", label: "MSc Engineering Management" },
	{ value: "MSc Psychology", label: "MSc Psychology" },
	{ value: "BA (Hons) Digital Content Creation with foundation year", label: "BA (Hons) Digital Content Creation with foundation year" },
	{ value: "BSc (Hons) Computing (Application Development)", label: "BSc (Hons) Computing (Application Development)" },
	{ value: "BA (Hons) English and Creative Writing", label: "BA (Hons) English and Creative Writing" },
	{ value: "BSc (Hons) Games Design", label: "BSc (Hons) Games Design" },
	{ value: "MA Applied and Community Arts (with Specialism)", label: "MA Applied and Community Arts (with Specialism)" },
	{ value: "MBA Business Administration", label: "MBA Business Administration" },
	{ value: "MSc Logistics and Supply Chain Management", label: "MSc Logistics and Supply Chain Management" },
	{ value: "MSc Psychology (Conversion)", label: "MSc Psychology (Conversion)" }
];

export default BoltonCourses;