const UniversityAucklandCourses = [
	{ value: "Master of Architecture (Professional) and Urban Planning (Professional)-Faculty of Creative Arts and Industries", label: "Master of Architecture (Professional) and Urban Planning (Professional)-Faculty of Creative Arts and Industries" },
	{ value: "Master of Urban Planning (Professional)-Faculty of Creative Arts and Industries", label: "Master of Urban Planning (Professional)-Faculty of Creative Arts and Industries" },
	{ value: "Master of Design-Faculty of Creative Arts and Industries (1 Year)", label: "Master of Design-Faculty of Creative Arts and Industries (1 Year)" },
	{ value: "Master of Design-Faculty of Creative Arts and Industries (1.5 Years)", label: "Master of Design-Faculty of Creative Arts and Industries (1.5 Years)" },
	{ value: "Master of Housing Studies-Faculty of Creative Arts and Industries (1 Year)", label: "Master of Housing Studies-Faculty of Creative Arts and Industries (1 Year)" },
	{ value: "Master of Architecture (Professional) and Heritage Conservation-Faculty of Creative Arts and Industries", label: "Master of Architecture (Professional) and Heritage Conservation-Faculty of Creative Arts and Industries" },
	{ value: "Master of Architecture (Professional) and Urban Design-Faculty of Creative Arts and Industries", label: "Master of Architecture (Professional) and Urban Design-Faculty of Creative Arts and Industries" },
	{ value: "Master of Urban Planning (Professional) and Urban Design-Faculty of Creative Arts and Industries", label: "Master of Urban Planning (Professional) and Urban Design-Faculty of Creative Arts and Industries" },
	{ value: "Master of Urban Planning (Professional) and Heritage Conservation-Faculty of Creative Arts and Industries", label: "Master of Urban Planning (Professional) and Heritage Conservation-Faculty of Creative Arts and Industries" },
	{ value: "Master of Architecture (Professional) and Housing Studies-Faculty of Creative Arts and Industries", label: "Master of Architecture (Professional) and Housing Studies-Faculty of Creative Arts and Industries" },
	{ value: "Master of Education Practice-Faculty of Education and Social Work", label: "Master of Education Practice-Faculty of Education and Social Work" },
	{ value: "Master of Counselling-Faculty of Education and Social Work", label: "Master of Counselling-Faculty of Education and Social Work" },
	{ value: "Master of Social Work (Professional)-Faculty of Education and Social Work", label: "Master of Social Work (Professional)-Faculty of Education and Social Work" },
	{ value: "Bachelor of Social Work-Faculty of Education and Social Work", label: "Bachelor of Social Work-Faculty of Education and Social Work" },
	{ value: "Bachelor of Social Work (Honours)-Faculty of Education and Social Work", label: "Bachelor of Social Work (Honours)-Faculty of Education and Social Work" },
	{ value: "Master of Science in Green Chemical Science-Faculty of Science (1 Year)", label: "Master of Science in Green Chemical Science-Faculty of Science (1 Year)" },
	{ value: "Master of Science in Green Chemical Science-Faculty of Science (2 Years)", label: "Master of Science in Green Chemical Science-Faculty of Science (2 Years)" },
	{ value: "Master of Science in Marine Science-Faculty of Science", label: "Master of Science in Marine Science-Faculty of Science" },
	{ value: "Master of Science in Mathematics-Faculty of Science", label: "Master of Science in Mathematics-Faculty of Science" },
	{ value: "Postgraduate Certificate in Business Analytics-Business School", label: "Postgraduate Certificate in Business Analytics-Business School" },
	{ value: "Master of Commerce in Economics-Business School", label: "Master of Commerce in Economics-Business School" },
	{ value: "Master of Science in Optometry-Faculty of Science (1 Year)", label: "Master of Science in Optometry-Faculty of Science (1 Year)" },
	{ value: "Master of Science in Optometry-Faculty of Science (2 Years)", label: "Master of Science in Optometry-Faculty of Science (2 Years)" },
	{ value: "Master of Engineering Geology-Faculty of Science (1 Year)", label: "Master of Engineering Geology-Faculty of Science (1 Year)" },
	{ value: "Master of Engineering Geology-Faculty of Science (1.5 Years)", label: "Master of Engineering Geology-Faculty of Science (1.5 Years)" },
	{ value: "Master of Information Technology-Faculty of Science (1 Year)", label: "Master of Information Technology-Faculty of Science (1 Year)" },
	{ value: "Master of Information Technology-Faculty of Science (2 Years)", label: "Master of Information Technology-Faculty of Science (2 Years)" },
	{ value: "Master of Science in Applied Mathematics-Faculty of Science (1 Year)", label: "Master of Science in Applied Mathematics-Faculty of Science (1 Year)" },
	{ value: "Master of Science in Applied Mathematics-Faculty of Science (2 Years)", label: "Master of Science in Applied Mathematics-Faculty of Science (2 Years)" },
	{ value: "Bachelor of Science in Psychology-Faculty of Science", label: "Bachelor of Science in Psychology-Faculty of Science" },
	{ value: "Bachelor of Science in Computer Science-Faculty of Science", label: "Bachelor of Science in Computer Science-Faculty of Science" },
	{ value: "Bachelor of Science in Environmental Science-Faculty of Science", label: "Bachelor of Science in Environmental Science-Faculty of Science" },
	{ value: "Master of Arts in Ancient History-Faculty of Arts (1 Year)", label: "Master of Arts in Ancient History-Faculty of Arts (1 Year)" },
	{ value: "Master of Arts in Ancient History-Faculty of Arts (2 Years)", label: "Master of Arts in Ancient History-Faculty of Arts (2 Years)" },
	{ value: "Bachelor of Commerce / Bachelor of Global Studies Conjoint", label: "Bachelor of Commerce / Bachelor of Global Studies Conjoint" },
	{ value: "Bachelor of Global Studies in Global Politics and Human Rights", label: "Bachelor of Global Studies in Global Politics and Human Rights" },
	{ value: "Bachelor of Global Studies in International Relations and Business", label: "Bachelor of Global Studies in International Relations and Business" },
	{ value: "Bachelor of Global Studies / Bachelor of Science Conjoint", label: "Bachelor of Global Studies / Bachelor of Science Conjoint" }
];

export default UniversityAucklandCourses;