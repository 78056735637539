const DeakinCourses = [
	{ value: "Master of Teaching (Secondary)", label: "Master of Teaching (Secondary)" },
	{ value: "Master of Commerce", label: "Master of Commerce" },
	{ value: "Master of Information Technology (Professional)", label: "Master of Information Technology (Professional)" },
	{ value: "Bachelor of Science/Master of Teaching (Secondary)", label: "Bachelor of Science/Master of Teaching (Secondary)" },
	{ value: "Bachelor of Arts (Honours)", label: "Bachelor of Arts (Honours)" },
	{ value: "Bachelor of Criminology / Bachelor of Cyber Security", label: "Bachelor of Criminology / Bachelor of Cyber Security" },
	{ value: "Bachelor of Design (Architecture) / Bachelor of Construction Management (Honours)", label: "Bachelor of Design (Architecture) / Bachelor of Construction Management (Honours)" },
	{ value: "Bachelor of Nursing / Bachelor of Midwifery", label: "Bachelor of Nursing / Bachelor of Midwifery" },
	{ value: "Bachelor of Science", label: "Bachelor of Science" },
	{ value: "Master of Information Technology Management", label: "Master of Information Technology Management" },
	{ value: "Bachelor of Arts / Bachelor of Science", label: "Bachelor of Arts / Bachelor of Science" },
	{ value: "Bachelor of Communication (Digital Media)", label: "Bachelor of Communication (Digital Media)" },
	{ value: "Bachelor of Nutrition Science/Bachelor of Commerce", label: "Bachelor of Nutrition Science/Bachelor of Commerce" },
	{ value: "Bachelor of Nutrition Science", label: "Bachelor of Nutrition Science" },
	{ value: "Doctor of Philosophy", label: "Doctor of Philosophy" },
	{ value: "Master of Applied Artificial Intelligence", label: "Master of Applied Artificial Intelligence" },
	{ value: "Master of International relation", label: "Master of International relation" },
	{ value: "Bachelor of Nutrition Science (Dietetics Pathways)", label: "Bachelor of Nutrition Science (Dietetics Pathways)" },
	{ value: "Master of Health Economics", label: "Master of Health Economics" },
	{ value: "Bachelor of Arts / Bachelor of Laws", label: "Bachelor of Arts / Bachelor of Laws" },
	{ value: "Bachelor of Education (Early Years)", label: "Bachelor of Education (Early Years)" },
	{ value: "Bachelor of Commerce/Bachelor of Science", label: "Bachelor of Commerce/Bachelor of Science" },
	{ value: "Bachelor of Science (Honours)", label: "Bachelor of Science (Honours)" },
	{ value: "Bachelor of Creative Arts (Dance)", label: "Bachelor of Creative Arts (Dance)" },
	{ value: "Master of Financial Planning", label: "Master of Financial Planning" },
	{ value: "Master of Health Promotion", label: "Master of Health Promotion" },
	{ value: "Master of Business Administration", label: "Master of Business Administration" },
	{ value: "Executive Master of Sport Business", label: "Executive Master of Sport Business" },
	{ value: "Master of Teaching (Primary and Secondary)", label: "Master of Teaching (Primary and Secondary)" },
	{ value: "Bachelor of Design (Architecture)/Bachelor of Construction Management (Honours)", label: "Bachelor of Design (Architecture)/Bachelor of Construction Management (Honours)" },
	{ value: "Master of Infrastructure Engineering and Management", label: "Master of Infrastructure Engineering and Management" },
	{ value: "Bachelor of Nursing (Clinical Leadership)", label: "Bachelor of Nursing (Clinical Leadership)" },
	{ value: "Master of Engineering (Professional)", label: "Master of Engineering (Professional)" },
	{ value: "Bachelor of Creative Arts (Drama)", label: "Bachelor of Creative Arts (Drama)" },
	{ value: "Bachelor of Environmental Science (Wildlife and Conservation Biology)", label: "Bachelor of Environmental Science (Wildlife and Conservation Biology)" },
	{ value: "Bachelor of Property and Real Estate", label: "Bachelor of Property and Real Estate" },
	{ value: "Master of Information Technology", label: "Master of Information Technology" },
	{ value: "Bachelor of Construction Management (Honours)", label: "Bachelor of Construction Management (Honours)" },
	{ value: "Bachelor of Health Sciences", label: "Bachelor of Health Sciences" },
	{ value: "Master of Arts (Writing and Literature)", label: "Master of Arts (Writing and Literature)" },
	{ value: "Bachelor of Exercise and Sport Science (Honours)", label: "Bachelor of Exercise and Sport Science (Honours)" },
	{ value: "Bachelor of Arts/ Master of International Business", label: "Bachelor of Arts/ Master of International Business" },
	{ value: "Bachelor of Creative Arts (Visual Arts)", label: "Bachelor of Creative Arts (Visual Arts)" },
	{ value: "Bachelor of Environmental Engineering (Honours)", label: "Bachelor of Environmental Engineering (Honours)" },
	{ value: "Bachelor of Public Health and Health Promotion / Bachelor of Commerce", label: "Bachelor of Public Health and Health Promotion / Bachelor of Commerce" },
	{ value: "Bachelor of Business/ Bachelor of Arts", label: "Bachelor of Business/ Bachelor of Arts" },
	{ value: "Bachelor of Environmental Science (Honours)", label: "Bachelor of Environmental Science (Honours)" },
	{ value: "Master of Nutrition and Population Health", label: "Master of Nutrition and Population Health" },
	{ value: "Bachelor of Psychological Science (Honours)", label: "Bachelor of Psychological Science (Honours)" },
	{ value: "Master of Construction Management (Professional)", label: "Master of Construction Management (Professional)" },
	{ value: "Bachelor of Nursing (Honours)", label: "Bachelor of Nursing (Honours)" },
	{ value: "Master of Teaching (Secondary)", label: "Master of Teaching (Secondary)" },
	{ value: "Master of Commerce", label: "Master of Commerce" },
	{ value: "Master of Information Technology (Professional)", label: "Master of Information Technology (Professional)" },
	{ value: "Bachelor of Science/Master of Teaching (Secondary)", label: "Bachelor of Science/Master of Teaching (Secondary)" },
	{ value: "Bachelor of Arts (Honours)", label: "Bachelor of Arts (Honours)" },
	{ value: "Bachelor of Criminology / Bachelor of Cyber Security", label: "Bachelor of Criminology / Bachelor of Cyber Security" },
	{ value: "Bachelor of Design (Architecture) / Bachelor of Construction Management (Honours)", label: "Bachelor of Design (Architecture) / Bachelor of Construction Management (Honours)" },
	{ value: "Bachelor of Nursing / Bachelor of Midwifery", label: "Bachelor of Nursing / Bachelor of Midwifery" },
	{ value: "Bachelor of Science", label: "Bachelor of Science" },
	{ value: "Master of Information Technology Management", label: "Master of Information Technology Management" },
	{ value: "Bachelor of Arts / Bachelor of Science", label: "Bachelor of Arts / Bachelor of Science" },
	{ value: "Bachelor of Communication (Digital Media)", label: "Bachelor of Communication (Digital Media)" },
	{ value: "Bachelor of Nutrition Science/Bachelor of Commerce", label: "Bachelor of Nutrition Science/Bachelor of Commerce" },
	{ value: "Bachelor of Nutrition Science", label: "Bachelor of Nutrition Science" },
	{ value: "Doctor of Philosophy", label: "Doctor of Philosophy" },
	{ value: "Master of Applied Artificial Intelligence", label: "Master of Applied Artificial Intelligence" },
	{ value: "Master of International relation", label: "Master of International relation" },
	{ value: "Bachelor of Nutrition Science (Dietetics Pathways)", label: "Bachelor of Nutrition Science (Dietetics Pathways)" },
	{ value: "Master of Health Economics", label: "Master of Health Economics" },
	{ value: "Bachelor of Arts / Bachelor of Laws", label: "Bachelor of Arts / Bachelor of Laws" },
	{ value: "Bachelor of Education (Early Years)", label: "Bachelor of Education (Early Years)" },
	{ value: "Bachelor of Commerce/Bachelor of Science", label: "Bachelor of Commerce/Bachelor of Science" },
	{ value: "Bachelor of Science (Honours)", label: "Bachelor of Science (Honours)" },
	{ value: "Bachelor of Creative Arts (Dance)", label: "Bachelor of Creative Arts (Dance)" },
	{ value: "Master of Financial Planning", label: "Master of Financial Planning" },
	{ value: "Master of Health Promotion", label: "Master of Health Promotion" },
	{ value: "Master of Business Administration", label: "Master of Business Administration" },
	{ value: "Executive Master of Sport Business", label: "Executive Master of Sport Business" },
	{ value: "Master of Teaching (Primary and Secondary)", label: "Master of Teaching (Primary and Secondary)" },
	{ value: "Bachelor of Design (Architecture)/Bachelor of Construction Management (Honours)", label: "Bachelor of Design (Architecture)/Bachelor of Construction Management (Honours)" },
	{ value: "Master of Infrastructure Engineering and Management", label: "Master of Infrastructure Engineering and Management" },
	{ value: "Bachelor of Nursing (Clinical Leadership)", label: "Bachelor of Nursing (Clinical Leadership)" },
	{ value: "Master of Engineering (Professional)", label: "Master of Engineering (Professional)" },
	{ value: "Bachelor of Creative Arts (Drama)", label: "Bachelor of Creative Arts (Drama)" },
	{ value: "Bachelor of Environmental Science (Wildlife and Conservation Biology)", label: "Bachelor of Environmental Science (Wildlife and Conservation Biology)" },
	{ value: "Bachelor of Property and Real Estate", label: "Bachelor of Property and Real Estate" },
	{ value: "Master of Information Technology", label: "Master of Information Technology" },
	{ value: "Bachelor of Construction Management (Honours)", label: "Bachelor of Construction Management (Honours)" },
	{ value: "Bachelor of Health Sciences", label: "Bachelor of Health Sciences" },
	{ value: "Master of Arts (Writing and Literature)", label: "Master of Arts (Writing and Literature)" },
	{ value: "Bachelor of Exercise and Sport Science (Honours)", label: "Bachelor of Exercise and Sport Science (Honours)" },
	{ value: "Bachelor of Arts/ Master of International Business", label: "Bachelor of Arts/ Master of International Business" },
	{ value: "Bachelor of Creative Arts (Visual Arts)", label: "Bachelor of Creative Arts (Visual Arts)" },
	{ value: "Bachelor of Environmental Engineering (Honours)", label: "Bachelor of Environmental Engineering (Honours)" },
	{ value: "Bachelor of Public Health and Health Promotion / Bachelor of Commerce", label: "Bachelor of Public Health and Health Promotion / Bachelor of Commerce" },
	{ value: "Bachelor of Business/ Bachelor of Arts", label: "Bachelor of Business/ Bachelor of Arts" },
	{ value: "Bachelor of Environmental Science (Honours)", label: "Bachelor of Environmental Science (Honours)" },
	{ value: "Master of Nutrition and Population Health", label: "Master of Nutrition and Population Health" },
	{ value: "Bachelor of Psychological Science (Honours)", label: "Bachelor of Psychological Science (Honours)" },
	{ value: "Master of Construction Management (Professional)", label: "Master of Construction Management (Professional)" },
	{ value: "Bachelor of Nursing (Honours)", label: "Bachelor of Nursing (Honours)" },
	{ value: "Bachelor of Zoology and Animal Science", label: "Bachelor of Zoology and Animal Science" },
	{ value: "Master of Film and Television", label: "Master of Film and Television" },
	{ value: "Master of Landscape Architecture", label: "Master of Landscape Architecture" },
	{ value: "Bachelor of Design (3D Animation)", label: "Bachelor of Design (3D Animation)" },
	{ value: "Bachelor of Exercise and Sport Science", label: "Bachelor of Exercise and Sport Science" },
	{ value: "Doctor of Medicine", label: "Doctor of Medicine" },
	{ value: "Bachelor of Human Resource Management (Psychology)", label: "Bachelor of Human Resource Management (Psychology)" },
	{ value: "Master of Education", label: "Master of Education" },
	{ value: "Bachelor of Business / Bachelor of Arts", label: "Bachelor of Business / Bachelor of Arts" },
	{ value: "Bachelor of Design (Visual Communication)", label: "Bachelor of Design (Visual Communication)" },
	{ value: "Bachelor of Mechatronics Engineering (Honours)", label: "Bachelor of Mechatronics Engineering (Honours)" },
	{ value: "Bachelor of Nursing / Bachelor of Psychological Science", label: "Bachelor of Nursing / Bachelor of Psychological Science" },
	{ value: "Bachelor of Property and Real Estate / Bachelor of Laws", label: "Bachelor of Property and Real Estate / Bachelor of Laws" },
	{ value: "Bachelor of Nursing/Bachelor of Psychological Science", label: "Bachelor of Nursing/Bachelor of Psychological Science" },
	{ value: "Master of Professional Accounting", label: "Master of Professional Accounting" },
	{ value: "Master of Business Analytics (Supply chain management)", label: "Master of Business Analytics (Supply chain management)" },
	{ value: "Master of Film & Television", label: "Master of Film & Television" },
	{ value: "Bachelor of Arts / Master of Teaching (Secondary)", label: "Bachelor of Arts / Master of Teaching (Secondary)" },
	{ value: "Bachelor of Biomedical Science", label: "Bachelor of Biomedical Science" },
	{ value: "Bachelor of Arts/Bachelor of Science", label: "Bachelor of Arts/Bachelor of Science" },
	{ value: "Bachelor of International Studies (Global Scholar)", label: "Bachelor of International Studies (Global Scholar)" },
	{ value: "Bachelor of Public Health and Health Promotion", label: "Bachelor of Public Health and Health Promotion" },
	{ value: "Master of Public Health", label: "Master of Public Health" },
	{ value: "Bachelor of Civil Engineering (Honours)", label: "Bachelor of Civil Engineering (Honours)" },
	{ value: "Bachelor of Forensic Science", label: "Bachelor of Forensic Science" },
	{ value: "Bachelor of Communication (Honours)", label: "Bachelor of Communication (Honours)" },
	{ value: "Bachelor of Zoology and Animal Science", label: "Bachelor of Zoology and Animal Science" },
	{ value: "Master of Film and Television", label: "Master of Film and Television" },
	{ value: "Master of Landscape Architecture", label: "Master of Landscape Architecture" },
	{ value: "Bachelor of Design (3D Animation)", label: "Bachelor of Design (3D Animation)" },
	{ value: "Bachelor of Exercise and Sport Science", label: "Bachelor of Exercise and Sport Science" },
	{ value: "Doctor of Medicine", label: "Doctor of Medicine" },
	{ value: "Bachelor of Human Resource Management (Psychology)", label: "Bachelor of Human Resource Management (Psychology)" },
	{ value: "Master of Education", label: "Master of Education" },
	{ value: "Bachelor of Business / Bachelor of Arts", label: "Bachelor of Business / Bachelor of Arts" },
	{ value: "Bachelor of Design (Visual Communication)", label: "Bachelor of Design (Visual Communication)" },
	{ value: "Bachelor of Mechatronics Engineering (Honours)", label: "Bachelor of Mechatronics Engineering (Honours)" },
	{ value: "Bachelor of Nursing / Bachelor of Psychological Science", label: "Bachelor of Nursing / Bachelor of Psychological Science" },
	{ value: "Bachelor of Property and Real Estate / Bachelor of Laws", label: "Bachelor of Property and Real Estate / Bachelor of Laws" },
	{ value: "Bachelor of Nursing/Bachelor of Psychological Science", label: "Bachelor of Nursing/Bachelor of Psychological Science" },
	{ value: "Master of Professional Accounting", label: "Master of Professional Accounting" },
	{ value: "Master of Business Analytics (Supply chain management)", label: "Master of Business Analytics (Supply chain management)" },
	{ value: "Master of Film & Television", label: "Master of Film & Television" },
	{ value: "Bachelor of Arts / Master of Teaching (Secondary)", label: "Bachelor of Arts / Master of Teaching (Secondary)" },
	{ value: "Bachelor of Biomedical Science", label: "Bachelor of Biomedical Science" },
	{ value: "Bachelor of Arts/Bachelor of Science", label: "Bachelor of Arts/Bachelor of Science" },
	{ value: "Bachelor of International Studies (Global Scholar)", label: "Bachelor of International Studies (Global Scholar)" },
	{ value: "Bachelor of Public Health and Health Promotion", label: "Bachelor of Public Health and Health Promotion" },
	{ value: "Master of Public Health", label: "Master of Public Health" },
	{ value: "Bachelor of Civil Engineering (Honours)", label: "Bachelor of Civil Engineering (Honours)" },
	{ value: "Bachelor of Forensic Science", label: "Bachelor of Forensic Science" },
	{ value: "Bachelor of Communication (Honours)", label: "Bachelor of Communication (Honours)" },
	{ value: "Bachelor of Commerce/Bachelor of Communication (Advertising)", label: "Bachelor of Commerce/Bachelor of Communication (Advertising)" },
	{ value: "Bachelor of Creative Arts (Honours)", label: "Bachelor of Creative Arts (Honours)" },
	{ value: "Master of Business Analytics", label: "Master of Business Analytics" },
	{ value: "Master of Communication", label: "Master of Communication" },
	{ value: "Master of Cultural Heritage", label: "Master of Cultural Heritage" },
	{ value: "Bachelor of Arts / Bachelor of Commerce", label: "Bachelor of Arts / Bachelor of Commerce" },
	{ value: "Master of Politics and Policy", label: "Master of Politics and Policy" },
	{ value: "Bachelor of Commerce/Bachelor of Communication (Public Relations)", label: "Bachelor of Commerce/Bachelor of Communication (Public Relations)" },
	{ value: "Master of Teaching (Primary and Early Childhood)", label: "Master of Teaching (Primary and Early Childhood)" },
	{ value: "Master of Information Systems", label: "Master of Information Systems" },
	{ value: "Bachelor of Commerce / Bachelor of Business Analytics", label: "Bachelor of Commerce / Bachelor of Business Analytics" },
	{ value: "Bachelor of Public Health and Health Promotion (Honours)", label: "Bachelor of Public Health and Health Promotion (Honours)" },
	{ value: "Master of Counselling", label: "Master of Counselling" },
	{ value: "Master of Clinical Exercise Physiology", label: "Master of Clinical Exercise Physiology" },
	{ value: "Bachelor of Commerce/Bachelor of Communication (Journalism)", label: "Bachelor of Commerce/Bachelor of Communication (Journalism)" },
	{ value: "Master of Business (Arts and Cultural Management)", label: "Master of Business (Arts and Cultural Management)" },
	{ value: "Master of Business Administration (Healthcare Management)", label: "Master of Business Administration (Healthcare Management)" },
	{ value: "Bachelor of Education (Primary)", label: "Bachelor of Education (Primary)" },
	{ value: "Bachelor of Criminology / Bachelor of Laws", label: "Bachelor of Criminology / Bachelor of Laws" },
	{ value: "Bachelor of Science/Bachelor of Laws", label: "Bachelor of Science/Bachelor of Laws" },
	{ value: "Bachelor of Health and Medical Science (Honours)", label: "Bachelor of Health and Medical Science (Honours)" },
	{ value: "Bachelor of Health Science", label: "Bachelor of Health Science" },
	{ value: "Master of Business Administration (International)", label: "Master of Business Administration (International)" },
	{ value: "Bachelor of Laws / Bachelor of International Studies", label: "Bachelor of Laws / Bachelor of International Studies" },
	{ value: "Bachelor of Occupational Therapy", label: "Bachelor of Occupational Therapy" },
	{ value: "Bachelor of Sport Development", label: "Bachelor of Sport Development" },
	{ value: "Master of Professional Accounting and Law", label: "Master of Professional Accounting and Law" },
	{ value: "Bachelor of Marine Science", label: "Bachelor of Marine Science" },
	{ value: "Master of Professional Accounting and Finance", label: "Master of Professional Accounting and Finance" },
	{ value: "Bachelor of Business (Sport Management)", label: "Bachelor of Business (Sport Management)" },
	{ value: "Bachelor of Commerce / Bachelor of Science", label: "Bachelor of Commerce / Bachelor of Science" },
	{ value: "Bachelor of Communication (Public Relations)", label: "Bachelor of Communication (Public Relations)" },
	{ value: "Bachelor of Health Sciences (Honours)", label: "Bachelor of Health Sciences (Honours)" },
	{ value: "Bachelor of Science (Plant Biology)", label: "Bachelor of Science (Plant Biology)" },
	{ value: "Bachelor of Exercise and Sport Science/Bachelor of Nutrition Science", label: "Bachelor of Exercise and Sport Science/Bachelor of Nutrition Science" },
	{ value: "Master of International Relations", label: "Master of International Relations" },
	{ value: "Bachelor of Forensic Science/Bachelor of Criminology", label: "Bachelor of Forensic Science/Bachelor of Criminology" },
	{ value: "Master of Infrastructure Engineering and Management (Professional)", label: "Master of Infrastructure Engineering and Management (Professional)" },
	{ value: "Master of Applied Artificial Intelligence (Professional)", label: "Master of Applied Artificial Intelligence (Professional)" },
	{ value: "Bachelor of Film, Television and Animation", label: "Bachelor of Film, Television and Animation" },
	{ value: "Bachelor of International Studies / Bachelor of Commerce", label: "Bachelor of International Studies / Bachelor of Commerce" },
	{ value: "Bachelor of Psychological Science", label: "Bachelor of Psychological Science" },
	{ value: "Bachelor of Arts - Advanced (Honours)", label: "Bachelor of Arts - Advanced (Honours)" },
	{ value: "Master of Architecture", label: "Master of Architecture" },
	{ value: "Bachelor of Science (Mathematical Modelling)", label: "Bachelor of Science (Mathematical Modelling)" },
	{ value: "Bachelor of Medical Imaging", label: "Bachelor of Medical Imaging" },
	{ value: "Master of Data Science (Professional)", label: "Master of Data Science (Professional)" },
	{ value: "Master of Humanitarian Services", label: "Master of Humanitarian Services" },
	{ value: "Bachelor of Public Health and Health Promotion/Bachelor of Commerce", label: "Bachelor of Public Health and Health Promotion/Bachelor of Commerce" },
	{ value: "Bachelor of Commerce / Bachelor of Arts", label: "Bachelor of Commerce / Bachelor of Arts" },
	{ value: "Master of Psychology (Clinical)", label: "Master of Psychology (Clinical)" },
	{ value: "Master of Psychology (Organisational)", label: "Master of Psychology (Organisational)" },
	{ value: "Master of Sustainability", label: "Master of Sustainability" },
	{ value: "Master of Cyber Security (Professional)", label: "Master of Cyber Security (Professional)" },
	{ value: "Master of Cultural Heritage and Museum Studies", label: "Master of Cultural Heritage and Museum Studies" },
	{ value: "Master of Creative Arts", label: "Master of Creative Arts" },
	{ value: "Master of Cyber Security", label: "Master of Cyber Security" },
	{ value: "Bachelor of Communication (Journalism)", label: "Bachelor of Communication (Journalism)" },
	{ value: "Bachelor of Mechanical Engineering (Honours)", label: "Bachelor of Mechanical Engineering (Honours)" },
	{ value: "Master of Human Nutrition", label: "Master of Human Nutrition" },
	{ value: "Master of Information Technology Management (Professional)", label: "Master of Information Technology Management (Professional)" },
	{ value: "Master of International relation (International)", label: "Master of International relation (International)" },
	{ value: "Master of Health and Human Services Management", label: "Master of Health and Human Services Management" },
	{ value: "Bachelor of Arts (Psychology)", label: "Bachelor of Arts (Psychology)" },
	{ value: "Bachelor of Property and Real Estate/Bachelor of Laws", label: "Bachelor of Property and Real Estate/Bachelor of Laws" },
	{ value: "Bachelor of Commerce / Bachelor of Laws", label: "Bachelor of Commerce / Bachelor of Laws" },
	{ value: "Bachelor of Nursing / Bachelor of Public Health and Health Promotion", label: "Bachelor of Nursing / Bachelor of Public Health and Health Promotion" },
	{ value: "Bachelor of Design (Architecture)", label: "Bachelor of Design (Architecture)" },
	{ value: "Bachelor of Arts/Master of Teaching (Secondary)", label: "Bachelor of Arts/Master of Teaching (Secondary)" },
	{ value: "Bachelor of Environmental Science (Marine Biology)", label: "Bachelor of Environmental Science (Marine Biology)" },
	{ value: "Master of Criminology", label: "Master of Criminology" },
	{ value: "Bachelor of Health Science and Medical Science (Honours)", label: "Bachelor of Health Science and Medical Science (Honours)" },
	{ value: "Bachelor of Exercise and Sport Science / Bachelor of Business (Sport Management)", label: "Bachelor of Exercise and Sport Science / Bachelor of Business (Sport Management)" },
	{ value: "Bachelor of Nutrition Science (Honours)", label: "Bachelor of Nutrition Science (Honours)" },
	{ value: "Bachelor of Film, Television and Animation (Honours)", label: "Bachelor of Film, Television and Animation (Honours)" },
	{ value: "Bachelor of Forensic Science / Bachelor of Criminology", label: "Bachelor of Forensic Science / Bachelor of Criminology" },
	{ value: "Bachelor of Science / Master of Teaching (Secondary)", label: "Bachelor of Science / Master of Teaching (Secondary)" },
	{ value: "Master of Data Science", label: "Master of Data Science" },
	{ value: "Bachelor of International Studies", label: "Bachelor of International Studies" },
	{ value: "Bachelor of Property and Real Estate / Bachelor of Commerce", label: "Bachelor of Property and Real Estate / Bachelor of Commerce" },
	{ value: "Master of Dietetics", label: "Master of Dietetics" },
	{ value: "Bachelor of Marketing (Psychology)", label: "Bachelor of Marketing (Psychology)" },
	{ value: "Master of Teaching (Early Childhood)", label: "Master of Teaching (Early Childhood)" },
	{ value: "Master of Leadership", label: "Master of Leadership" },
	{ value: "Bachelor of Forensic Science(Honours)", label: "Bachelor of Forensic Science(Honours)" },
	{ value: "Master of Energy System Management (Professional)", label: "Master of Energy System Management (Professional)" },
	{ value: "Bachelor of Creative Arts (Photography)", label: "Bachelor of Creative Arts (Photography)" },
	{ value: "Bachelor of Environmental Science (Environmental Management and Sustainability)", label: "Bachelor of Environmental Science (Environmental Management and Sustainability)" },
	{ value: "Bachelor of Science / Bachelor of Laws", label: "Bachelor of Science / Bachelor of Laws" },
	{ value: "Bachelor of Nursing", label: "Bachelor of Nursing" },
	{ value: "Bachelor of Design (Digital Technologies)", label: "Bachelor of Design (Digital Technologies)" },
	{ value: "Bachelor of Health and Physical Education", label: "Bachelor of Health and Physical Education" },
	{ value: "Bachelor of Software Engineering (Honours)", label: "Bachelor of Software Engineering (Honours)" },
	{ value: "Bachelor of Property and Real Estate/Bachelor of Commerce", label: "Bachelor of Property and Real Estate/Bachelor of Commerce" },
	{ value: "Bachelor of Social Work", label: "Bachelor of Social Work" },
	{ value: "Bachelor of Communication (Advertising)", label: "Bachelor of Communication (Advertising)" },
	{ value: "Master of Teaching (Primary)", label: "Master of Teaching (Primary)" },
	{ value: "Master of Teaching English to Speakers of Other Languages", label: "Master of Teaching English to Speakers of Other Languages" },
	{ value: "Master of Business (Sport Management)", label: "Master of Business (Sport Management)" },
	{ value: "Master of Engineering", label: "Master of Engineering" },
	{ value: "Bachelor of Communication (Digital Media)", label: "Bachelor of Communication (Digital Media)" },
	{ value: "Master of Energy System Management", label: "Master of Energy System Management" },
	{ value: "Bachelor of Criminology/ Bachelor of Psychology Science", label: "Bachelor of Criminology/ Bachelor of Psychology Science" },
	{ value: "Bachelor of Vision Science/Master of Optometry", label: "Bachelor of Vision Science/Master of Optometry" },
	{ value: "Bachelor of Exercise and Sport Science/Bachelor of Business (Sport Management)", label: "Bachelor of Exercise and Sport Science/Bachelor of Business (Sport Management)" },
];

export default DeakinCourses;
