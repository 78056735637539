const QueensUniversityCourses = [
	{ value: 'Bachelor of Arts ( BA ) - Art History', label: 'Bachelor of Arts ( BA ) - Art History' },
	{ value: 'Bachelor of Arts - Computing and the Creative Arts', label: 'Bachelor of Arts - Computing and the Creative Arts' },
	{ value: 'Bachelor of Arts - Drama', label: 'Bachelor of Arts - Drama' },
	{ value: 'Bachelor of Arts - Music', label: 'Bachelor of Arts - Music' },
	{ value: 'Bachelor of Arts (Honours )- Major in Economics', label: 'Bachelor of Arts (Honours )- Major in Economics' },
	{ value: 'Bachelor of Science - Astrophysics', label: 'Bachelor of Science - Astrophysics' },
	{ value: 'Bachelor of Science (Honours) - Environmental Science ( Major )', label: 'Bachelor of Science (Honours) - Environmental Science ( Major )' },
	{ value: 'Bachelor of Arts ( BA ) - Art History', label: 'Bachelor of Arts ( BA ) - Art History' },
	{ value: 'Bachelor of Arts - Computing and the Creative Arts', label: 'Bachelor of Arts - Computing and the Creative Arts' },
	{ value: 'Bachelor of Arts - Drama', label: 'Bachelor of Arts - Drama' },
	{ value: 'Bachelor of Arts - Music', label: 'Bachelor of Arts - Music' },
	{ value: 'Bachelor of Arts (Honours )- Major in Economics', label: 'Bachelor of Arts (Honours )- Major in Economics' },
	{ value: 'Bachelor of Science - Astrophysics', label: 'Bachelor of Science - Astrophysics' },
	{ value: 'Bachelor of Science (Honours) - Environmental Science ( Major )', label: 'Bachelor of Science (Honours) - Environmental Science ( Major )' },
	{ value: 'Bachelor of Arts ( BA ) - Art History', label: 'Bachelor of Arts ( BA ) - Art History' },
	{ value: 'Bachelor of Arts - Computing and the Creative Arts', label: 'Bachelor of Arts - Computing and the Creative Arts' },
	{ value: 'Bachelor of Arts - Drama', label: 'Bachelor of Arts - Drama' },
	{ value: 'Bachelor of Arts - Music', label: 'Bachelor of Arts - Music' },
	{ value: 'Bachelor of Arts (Honours )- Major in Economics', label: 'Bachelor of Arts (Honours )- Major in Economics' },
	{ value: 'Bachelor of Science - Astrophysics', label: 'Bachelor of Science - Astrophysics' },
	{ value: 'Bachelor of Science (Honours) - Environmental Science ( Major )', label: 'Bachelor of Science (Honours) - Environmental Science ( Major )' }
];

export default QueensUniversityCourses;
