import React, { useState, useRef } from 'react';
import { Form, FormGroup, Label, Input, Row, Col, FormFeedback, Container } from 'reactstrap';
import Main from '../global/wrapper/Main';
import Select from 'react-select';
import { useToast, Alert, AlertIcon, AlertDescription, CloseButton, } from '@chakra-ui/react';
import { countryOptions } from '../../data/countryOptions';
import CountryCourseData from '../../data/CountryCourseData';
import UniversityCourseData from '../../data/UniversityCourseData';
import { universityOptions } from '../../data/universityData';
import { educationInterestOptions } from '../../data/educationInterestOptions';

import { createNotification } from '../notifications/notification';
import colors from '../global/colors';

// const { addNotification } = useContext(NotificationContext);


const AddEnquiry = () => {
	const [showStudyPreference, setShowStudyPreference] = useState(true);
	const toast = useToast();

	const getCurrentDate = () => {
		const today = new Date();
		const year = today.getFullYear();
		const month = `${today.getMonth() + 1}`.padStart(2, '0'); // January is 0!
		const day = `${today.getDate()}`.padStart(2, '0');
		return `${year}-${month}-${day}`;
	};

	const [errorMessage, setErrorMessage] = useState('');
	const givenNameRef = useRef(null);
	const dobRef = useRef(null);
	const contactEmailRef = useRef(null);
	const mobileNumberRef = useRef(null);

	const hrStyle = {
		borderColor: colors.primary,
		opacity: 1
	};

	const h2Style = {
		backgroundColor: '#072f86',
		width: '100%',
		textAlign: 'left',
		color: 'white',
		padding: '5px',
		marginBottom: '10px',
		fontSize: '18px'
	};

	const rowStyle = {
		marginBottom: '15px'
	};

	const [selectedCountry, setSelectedCountry] = useState("");
	const [filteredUniversityOptions, setFilteredUniversityOptions] = useState([]);
	const [referral, setReferral] = useState("");
	const [desiredCourse, setDesiredCourse] = useState([]);
	const [desiredCourseOptions, setDesiredCourseOptions] = useState([]);
	const [branches, setBranches] = useState([]); // State to store branches
	const [selectedBranch, setSelectedBranch] = useState(null); // State to store selected branch
	const [counselors, setCounselors] = React.useState([]);
	const [selectedCounselor, setSelectedCounselor] = React.useState(null);


	// Fetch branches from the backend
	React.useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URL}/api/branches`)
			.then(response => response.json())
			.then(data => {
				setBranches(data);

				// Find and pre-select Haryana branch if it exists
				const haryanaBranch = data.find(branch => branch.label === "Haryana");
				if (haryanaBranch) {
					setSelectedBranch(haryanaBranch);
					setFormData(prevFormData => ({
						...prevFormData,
						branchId: haryanaBranch.value
					}));
				}
			})
			.catch(error => console.error('Error fetching branches:', error));
	}, []);

	React.useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URL}/api/counselors`)
			.then(response => response.json())
			.then(data => {
				// Assuming each counselor has a 'name' and an 'id' you want to map to a 'value' field for the Select component
				const counselorsFormatted = data.map(counselor => ({
					label: counselor.name, // Display name in the select
					value: counselor.id    // Unique identifier used as the value
				}));
				setCounselors(counselorsFormatted);

				// Find and pre-select a specific counselor if it exists, for example, "John Doe"
				const specificCounselor = counselorsFormatted.find(counselor => counselor.label === "John Doe");
				if (specificCounselor) {
					setSelectedCounselor(specificCounselor);
					// Update form data or handle the selection similarly to branches
					setFormData(prevFormData => ({
						...prevFormData,
						counselorId: specificCounselor.value
					}));
				}
			})
			.catch(error => console.error('Error fetching counselors:', error));
	}, []);


	const handleBranchChange = selectedOption => {
		setSelectedBranch(selectedOption);
		setFormData({ ...formData, branchId: selectedOption ? selectedOption.value : '' });
	};

	const handleCounselorChange = selectedOption => {
		setSelectedCounselor(selectedOption);
		// Assuming your backend expects counselorId and counselorName
		setFormData(prevFormData => ({
			...prevFormData,
			counselorId: selectedOption ? selectedOption.value : '',
			counselorName: selectedOption ? selectedOption.label : '', // Make sure this aligns with your backend model
		}));
	};


	const handleCountryChange = (selectedOption) => {
		setSelectedCountry(selectedOption); // Update the selected country state
		console.log("Selected Country:", selectedOption); // Log the selected country
		setFormData({ ...formData, preferredCountry: selectedOption ? selectedOption.value : '', university: [] });

		setDesiredCourse([]);

		const coursesForCountry = CountryCourseData[selectedOption.value] || [];
		setDesiredCourseOptions(coursesForCountry);
	};

	const handleReferralChange = (selectedOption) => {
		setReferral(selectedOption);
		setFormData({ ...formData, referral: selectedOption ? selectedOption.value : '' });
	};

	const referralOptions = [
		{ value: 'Google', label: 'Google' },
		{ value: 'Forum', label: 'Forum' },
		{ value: 'Website', label: 'Website' },
		{ value: 'News Paper', label: 'News Paper' },
		{ value: 'Education Expo', label: 'Education Expo' },
		{ value: 'X Student Referral', label: 'X Student Referral' },
		{ value: 'Search Engine', label: 'Search Engine' },
		{ value: 'Others', label: 'Others' }
	];

	const startMonths = Array.from({ length: 12 }, (v, k) => k + 1).map(month => ({
		value: month, label: new Date(0, month - 1).toLocaleString('default', { month: 'long' })
	}));
	const startYears = Array.from({ length: 31 }, (v, k) => new Date().getFullYear() + k).map(year => ({
		value: year, label: year
	}));
	const rateStudentOptions = [
		{ value: 'High', label: 'High' },
		{ value: 'Medium', label: 'Medium' },
		{ value: 'Low', label: 'Low' },
		// ... more options ...
	];
	const sourceOptions = [
		{ value: 'Direct', label: 'Direct' },
		{ value: 'Agent', label: 'Agent' },
		// ... more options ...
	];

	const initialFormData = {
		enquiryType: '',
		givenName: '',
		familyName: '',
		contactEmail: '',
		fatherOrSpouseName: '',
		gender: '',
		maritalStatus: '',
		dob: '',
		phoneResidence: '',
		phoneWork: '',
		mobileNumber: '',
		emergencyNumber: '',
		postalAddress: '',
		pinCode: '',
		passportNumber: '',
		issuedDate: '',
		expiryDate: '',
		placeOfIssue: '',
		passportNationality: '',
		preferredCountry: '',
		university: [],
		educationInterest: '',
		desiredCourses: '',
		startMonth: '',
		startYear: '',
		enquiryDate: getCurrentDate(),
		followUpDate: '',
		rateStudent: '',
		source: '',
		counselorName: '',
		referral: '',
		additionalNote: ''
	};

	const initialEducationalData = {
		sslc: '',
		sslcYearOfPassing: '',
		sslcMarksPercentage: '',
		sslcBacklog: '',
		hsc: '',
		hscYearOfPassing: '',
		hscMarksPercentage: '',
		hscBacklog: '',
		diplomaStream: '',
		diploma: '',
		diplomaYearOfPassing: '',
		diplomaMarksPercentage: '',
		diplomaBacklog: '',
		ugStream: '',
		ug: '',
		ugYearOfPassing: '',
		ugMarksPercentage: '',
		ugBacklog: '',
		pgStream: '',
		pg: '',
		pgYearOfPassing: '',
		pgMarksPercentage: '',
		pgBacklog: '',
		ielts: '',
		ieltsListening: '',
		ieltsReading: '',
		ieltsWriting: '',
		ieltsSpeaking: '',
		toefl: '',
		toeflListening: '',
		toeflReading: '',
		toeflWriting: '',
		toeflSpeaking: '',
		gre: '',
		sat: '',
		gmat: '',
		gmatQuantitative: '',
		gmatVerbal: '',
		gmatAnalyticalWriting: '',
		gmatIntegratedReasoning: '',
		pte: '',
		pteListening: '',
		pteReading: '',
		pteWriting: '',
		pteSpeaking: '',
		englishMark10: '',
		englishMark12: ''
	};

	const [formData, setFormData] = useState(initialFormData);
	const [educationalData, setEducationalData] = useState(initialEducationalData);


	React.useEffect(() => {
		if (selectedCountry) {
			const filteredUniversities = universityOptions.filter(uni => uni.country === selectedCountry.value);
			console.log("Filtered Universities:", filteredUniversities);
			setFilteredUniversityOptions(filteredUniversities);
		}
	}, [selectedCountry]);

	React.useEffect(() => {
		if (Array.isArray(formData.university)) {
			const selectedUniversitiesLabels = formData.university.map(u => u.label); // Ensure this matches the key structure in UniversityCourseData

			let coursesForSelectedUniversities = [];
			selectedUniversitiesLabels.forEach(universityLabel => {
				const coursesAtUniversity = UniversityCourseData[universityLabel] || [];
				coursesForSelectedUniversities.push(...coursesAtUniversity);
			});

			const uniqueCourses = [...new Set(coursesForSelectedUniversities)];
			setDesiredCourseOptions(uniqueCourses);
		}
	}, [formData.university]);


	console.log("formData", formData);

	const combinedFormData = { ...formData, ...educationalData };

	const [errors, setErrors] = useState({});

	const validate = () => {
		let tempErrors = {};
		let firstEmptyFieldRef = null;

		if (firstEmptyFieldRef && firstEmptyFieldRef.current) {
			firstEmptyFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
			firstEmptyFieldRef.current.focus();
		}

		if (!formData.givenName) {
			tempErrors.givenName = 'Given Name is required';
			firstEmptyFieldRef = givenNameRef;
		} else if (!formData.dob) {
			tempErrors.dob = 'Date of Birth is required';
			firstEmptyFieldRef = dobRef;
		} else if (!formData.contactEmail) {
			tempErrors.contactEmail = 'Email is required';
			firstEmptyFieldRef = contactEmailRef;
		} else if (!formData.mobileNumber) {
			tempErrors.mobileNumber = 'Mobile Number is required';
			firstEmptyFieldRef = mobileNumberRef;
		}

		setErrors(tempErrors);

		if (firstEmptyFieldRef) {
			firstEmptyFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
			firstEmptyFieldRef.current.focus();
			return false;
		}

		return Object.values(tempErrors).every(x => x === "");
	};

	const generateStudentID = () => {
		const randomNumber = Math.floor(10000 + Math.random() * 90000); // Generate a 5-digit random number
		return `GSC${randomNumber}`;
	};

	const changeHandler = (e) => {
		const { name, value } = e.target;
		if (name === 'enquiryType' && value === 'work') {
			setShowStudyPreference(false); // Hide the Study Preference section if enquiry type is 'work'
		} else {
			setShowStudyPreference(true); // Show the Study Preference section for other enquiry types
		}
		if (name in formData) {
			setFormData((prevData) => ({
				...prevData,
				[name]: value,
			}));
		} else {
			setEducationalData((prevData) => ({
				...prevData,
				[name]: value,
			}));
		}
		// Clear errors
		if (!!errors[name]) {
			setErrors((prevErrors) => ({
				...prevErrors,
				[name]: null,
			}));
		}
	};

	// In your React component where the form is
	const submitHandler = (e) => {
		e.preventDefault();
		if (!validate()) {
			// Validation failed, do not proceed
			return;
		}

		console.log('Form Data' + formData);

		const studentID = generateStudentID();
		const dataWithStudentIDAndBranch = { ...combinedFormData, studentID, branchId: formData.branchId, desiredCourses: desiredCourse };

		console.log("Form Data on Submission:", dataWithStudentIDAndBranch);

		fetch(`${process.env.REACT_APP_API_URL}/api/enquiries`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(dataWithStudentIDAndBranch),
		})
			.then(response => {
				if (!response.ok) {
					throw new Error(`HTTP error! status: ${response.status}`);
				}
				return response.json();
			})
			.then(data => {
				console.log(data);
				const newNotification = createNotification("User added successfully", "success");
				console.log(newNotification);
				toast({
					title: 'Success!',
					description: 'Enquiry submitted successfully.',
					status: 'success',
					duration: 5000,
					isClosable: true,
				});
				setFormData(prevState => ({ ...prevState, university: [], educationInterest: [] }));
				setEducationalData(initialEducationalData);
				setSelectedCountry("");
				setFilteredUniversityOptions([]);
				setReferral("");
				setDesiredCourse("");
				setSelectedBranch(null);
			})
			.catch(error => {
				console.error('Error:', error);
				setErrorMessage('Error submitting enquiry.');
			});
	};

	return (
		<Main>
			<Container>
				<h1 style={{ marginBottom: '20px' }} className='text-left'>Create a New Enquiry</h1>
				<Form onSubmit={submitHandler}>

					<Row form="true" style={rowStyle}>
						<Col md={6}>
							<FormGroup className="text-left">
								<Label for="referral">How did you hear about us?</Label>
								<Select
									options={referralOptions}
									value={referral}
									onChange={handleReferralChange}
									placeholder="Select your referral"
									className="basic-select"
									classNamePrefix="select"
									name="referral"
								/>
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup className="text-left">
								<Label for="branch">Branch</Label>
								<Select
									options={branches}
									value={selectedBranch}
									onChange={handleBranchChange}
									placeholder="Select Branch"
									className="basic-select"
									classNamePrefix="select"
									name="branch"
								/>
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup className="text-left">
								<Label for="enquiryType">Enquiry Type</Label>
								<Input
									type="select"
									name="enquiryType"
									id="enquiryType"
									value={formData.enquiryType}
									onChange={changeHandler}
								>
									<option value="">Select enquiry type</option>
									<option value="study">Study</option>
									<option value="work">Work</option>
								</Input>
							</FormGroup>
						</Col>
					</Row>

					<h2 style={h2Style}>ENQUIRY DETAILS</h2>
					<Row form="true" style={rowStyle}>
						{/* Enquiry Details */}
						<Col md={6}>
							<FormGroup className="text-left">
								<Label for="givenName">Given Name</Label>
								<div ref={givenNameRef}>
									<Input
										type="text"
										name="givenName"
										id="givenName"
										value={formData.givenName}
										onChange={changeHandler}
										invalid={errors.givenName ? true : false}
									/>
								</div>
								<FormFeedback>{errors.givenName}</FormFeedback>
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup className="text-left">
								<Label for="familyName">Family Name</Label>
								<Input
									type="text"
									name="familyName"
									id="familyName"
									value={formData.familyName}
									onChange={changeHandler}
								/>
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup className="text-left">
								<Label for="fatherOrSpouseName">Name of Father / Spouse</Label>
								<Input
									type="text"
									name="fatherOrSpouseName"
									id="fatherOrSpouseName"
									value={formData.fatherOrSpouseName}
									onChange={changeHandler}
								/>
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup className="text-left">
								<Label for="gender">Gender</Label>
								<Input
									type="select"
									name="gender"
									id="gender"
									value={formData.gender}
									onChange={changeHandler}
								>
									<option value="">Select Gender</option>
									<option value="male">Male</option>
									<option value="female">Female</option>
								</Input>
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup className="text-left">
								<Label for="maritalStatus">Marital Status</Label>
								<Input
									type="select"
									name="maritalStatus"
									id="maritalStatus"
									value={formData.maritalStatus}
									onChange={changeHandler}
								>
									<option value="">Select Marital Status</option>
									<option value="single">Single</option>
									<option value="married">Married</option>
								</Input>
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup className="text-left">
								<Label for="dob">Date of Birth</Label>
								<div ref={dobRef}>
									<Input
										type="date"
										name="dob"
										id="dob"
										value={formData.dob}
										onChange={changeHandler}
										invalid={errors.dob ? true : false}
									/>
								</div>
								<FormFeedback>{errors.dob}</FormFeedback>
							</FormGroup>
						</Col>
					</Row>

					<h2 style={h2Style}>CONTACT DETAILS</h2>
					<Row form="true" style={rowStyle}>
						{/* Contact Details */}
						<Col md={4}>
							<FormGroup className="text-left">
								<Label for="contactEmail">Email</Label>
								<div ref={contactEmailRef}>
									<Input
										type="email"
										name="contactEmail"
										id="contactEmail"
										value={formData.contactEmail}
										invalid={errors.contactEmail ? true : false}
										onChange={changeHandler}
									/>
								</div>
								<FormFeedback>{errors.contactEmail}</FormFeedback>
							</FormGroup>
						</Col>
						<Col md={4}>
							<FormGroup className="text-left">
								<Label for="Phone (Residence)">Phone (Residence)</Label>
								<Input
									type="text"
									name="phoneResidence"
									id="phoneResidence"
									maxLength={15}
									value={formData.phoneResidence}
									onChange={changeHandler}
									pattern="\d{1,12}"
								/>
							</FormGroup>
						</Col>
						<Col md={4}>
							<FormGroup className="text-left">
								<Label for="phoneWork">Phone (Work)</Label>
								<Input
									type="text"
									name="phoneWork"
									id="phoneWork"
									maxLength={15}
									value={formData.phoneWork}
									onChange={changeHandler}
									pattern="\d{1,12}"
								/>
							</FormGroup>
						</Col>
						<Col md={4}>
							<FormGroup className="text-left">
								<Label for="mobileNumber">Mobile Number</Label>
								<div ref={mobileNumberRef}>
									<Input
										type="text"
										name="mobileNumber"
										id="mobileNumber"
										maxLength={10}
										value={formData.mobileNumber}
										onChange={changeHandler}
										invalid={errors.mobileNumber ? true : false}
										pattern="\d{1,12}"
										placeholder="Enter 10-digit mobile number"
									/>
								</div>
								<FormFeedback>{errors.mobileNumber}</FormFeedback>
							</FormGroup>
						</Col>
						<Col md={4}>
							<FormGroup className="text-left">
								<Label for="emergencyNumber">Emergency Number</Label>
								<Input
									type="text"
									name="emergencyNumber"
									id="emergencyNumber"
									maxLength={15}
									value={formData.emergencyNumber}
									onChange={changeHandler}
									pattern="\d{1,12}"
								/>
							</FormGroup>
						</Col>
						<Col md={4}>
							<FormGroup className="text-left">
								<Label for="postalAddress">Postal Address</Label>
								<Input
									type="textarea"
									name="postalAddress"
									id="postalAddress"
									value={formData.postalAddress}
									onChange={changeHandler}
								/>
							</FormGroup>
						</Col>
						<Col md={4}>
							<FormGroup className="text-left">
								<Label for="pinCode">Pin Code</Label>
								<Input
									type="text"
									name="pinCode"
									id="pinCode"
									value={formData.pinCode}
									onChange={changeHandler}
									maxLength={6}
									pattern="\d{1,12}"
								/>
							</FormGroup>
						</Col>
					</Row>

					<h2 style={h2Style}>PASSPORT DETAILS</h2>
					<Row form="true" style={rowStyle}>
						{/* Passport Details */}
						<Col md={4}>
							<FormGroup className="text-left">
								<Label for="passportNumber">Passport Number</Label>
								<Input
									type="text"
									name="passportNumber"
									id="passportNumber"
									value={formData.passportNumber}
									onChange={changeHandler}
								/>
							</FormGroup>
						</Col>
						<Col md={4}>
							<FormGroup className="text-left">
								<Label for="issuedDate">Issued Date</Label>
								<Input
									type="date"
									name="issuedDate"
									id="issuedDate"
									value={formData.issuedDate}
									onChange={changeHandler}
								/>
							</FormGroup>
						</Col>
						<Col md={4}>
							<FormGroup className="text-left">
								<Label for="expiryDate">Expiry Date</Label>
								<Input
									type="date"
									name="expiryDate"
									id="expiryDate"
									value={formData.expiryDate}
									onChange={changeHandler}
								/>
							</FormGroup>
						</Col>
						<Col md={4}>
							<FormGroup className="text-left">
								<Label for="placeOfIssue">Place of Issue</Label>
								<Input
									type="text"
									name="placeOfIssue"
									id="placeOfIssue"
									value={formData.placeOfIssue}
									onChange={changeHandler}
								/>
							</FormGroup>
						</Col>
						<Col md={4}>
							<FormGroup className="text-left">
								<Label for="passportNationality">Passport Nationality</Label>
								<Input
									type="text"
									name="passportNationality"
									id="passportNationality"
									value={formData.passportNationality}
									onChange={changeHandler}
								/>
							</FormGroup>
						</Col>
					</Row>

					<h2 style={h2Style}>EDUCATIONAL DETAILS</h2>
					<Row form="true" style={rowStyle}>
						<Col md={6}>
							<h3 style={{ ...h2Style, backgroundColor: '#06A8D6', fontSize: '16px' }}>SECONDARY SCHOOL LEAVING CERTIFICATE</h3>
							<FormGroup className="text-left">
								{/* <Label for="sslc">SSLC <FontAwesomeIcon className='iconToolTip' icon={faInfoCircle} /></Label> */}
								<Input
									type="text"
									name="sslc"
									id="sslc"
									value={educationalData.sslc}
									onChange={changeHandler}
									placeholder='Ex: NLC Higher Secondary School'
								/>
							</FormGroup>
							<FormGroup className="text-left">
								<Label for="sslcYearOfPassing">Year of Passing</Label>
								<Input
									type="text"
									name="sslcYearOfPassing"
									id="sslcYearOfPassing"
									value={educationalData.sslcYearOfPassing}
									onChange={changeHandler}
									maxLength={4}
									pattern="\d{1,12}"
								/>
							</FormGroup>
							<FormGroup className="text-left">
								<Label for="sslcMarksPercentage">Marks (or) Percentage</Label>
								<Input
									type="text"
									name="sslcMarksPercentage"
									id="sslcMarksPercentage"
									value={educationalData.sslcMarksPercentage}
									onChange={changeHandler}
									maxLength={5}
									pattern="\d{1,2}(\.\d{1,2})?"
								/>
							</FormGroup>
							<FormGroup className="text-left">
								<Label for="sslcBacklog">BackLog</Label>
								<Input
									type="text"
									name="sslcBacklog"
									id="sslcBacklog"
									value={educationalData.sslcBacklog}
									onChange={changeHandler}
									maxLength={2}
									pattern="\d{1,12}"
								/>
							</FormGroup>
						</Col>
						<Col md={6}>
							<h3 style={{ ...h2Style, backgroundColor: '#06A8D6', fontSize: '16px' }}>HIGHER SECONDARY CERTIFICATE</h3>
							<FormGroup className="text-left">
								{/* <Label for="hsc">HSC</Label> */}
								<Input
									type="text"
									name="hsc"
									id="hsc"
									value={educationalData.hsc}
									onChange={changeHandler}
									placeholder='Ex: NLC Higher Secondary School'
								/>
							</FormGroup>
							<FormGroup className="text-left">
								<Label for="hscYearOfPassing">Year of Passing</Label>
								<Input
									type="text"
									name="hscYearOfPassing"
									id="hscYearOfPassing"
									value={educationalData.hscYearOfPassing}
									onChange={changeHandler}
									maxLength={4}
									pattern="\d{1,12}"
								/>
							</FormGroup>
							<FormGroup className="text-left">
								<Label for="hscMarksPercentage">Marks (or) Percentage</Label>
								<Input
									type="text"
									name="hscMarksPercentage"
									id="hscMarksPercentage"
									value={educationalData.hscMarksPercentage}
									onChange={changeHandler}
									maxLength={5}
									pattern="\d{1,2}(\.\d{1,2})?"
								/>
							</FormGroup>
							<FormGroup className="text-left">
								<Label for="hscBacklog">BackLog</Label>
								<Input
									type="text"
									name="hscBacklog"
									id="hscBacklog"
									value={educationalData.hscBacklog}
									onChange={changeHandler}
									maxLength={2}
									pattern="\d{1,12}"
								/>
							</FormGroup>
						</Col>

						<Col md={4}>
							<h3 style={{ ...h2Style, backgroundColor: '#06A8D6', fontSize: '16px' }}>DIPLOMA</h3>
							<FormGroup className="text-left">
								<Label for="diplomaStream">Stream</Label>
								<Input
									type="select"
									name="diplomaStream"
									id="diplomaStream"
									value={educationalData.diplomaStream}
									onChange={changeHandler}
								>
									<option value="">Select Stream</option>
									<option value="Commerce Stream">Commerce Stream</option>
									<option value="Arts Stream">Arts Stream</option>
									<option value="Science Stream">Science Stream</option>
									<option value="Engineering Stream">Engineering Stream</option>
								</Input>
							</FormGroup>
							<FormGroup className="text-left">
								<Label for="diploma">Diploma</Label>
								<Input type="text" placeholder="Ex: EEE, Mechanical, Computer Engineering" name="diploma" id="diploma" value={educationalData.diploma} onChange={changeHandler} />
							</FormGroup>
							<FormGroup className="text-left">
								<Label for="diplomaYearOfPassing">Year of Passing</Label>
								<Input type="text" name="diplomaYearOfPassing" id="diplomaYearOfPassing" value={educationalData.diplomaYearOfPassing} onChange={changeHandler} maxLength={4} pattern="\d{1,12}" />
							</FormGroup>
							<FormGroup className="text-left">
								<Label for="diplomaMarksPercentage">Marks (or) Percentage</Label>
								<Input type="text" name="diplomaMarksPercentage" id="diplomaMarksPercentage" value={educationalData.diplomaMarksPercentage} onChange={changeHandler} maxLength={5} pattern="\d{1,2}(\.\d{1,2})?" />
							</FormGroup>
							<FormGroup className="text-left">
								<Label for="diplomaBacklog">Back log</Label>
								<Input type="text" name="diplomaBacklog" id="diplomaBacklog" value={educationalData.diplomaBacklog} onChange={changeHandler} maxLength={2} pattern="\d{1,12}" />
							</FormGroup>
						</Col>

						{/* Column 2: Undergraduate Details */}
						<Col md={4}>
							<h3 style={{ ...h2Style, backgroundColor: '#06A8D6', fontSize: '16px' }}>UNDERGRADUATE</h3>
							<FormGroup className="text-left">
								<Label for="ugStream">Stream</Label>
								<Input
									type="select"
									name="ugStream"
									id="ugStream"
									value={educationalData.ugStream}
									onChange={changeHandler}
								>
									<option value="">Select Stream</option>
									<option value="Commerce Stream">Commerce Stream</option>
									<option value="Arts Stream">Arts Stream</option>
									<option value="Science Stream">Science Stream</option>
									<option value="Engineering Stream">Engineering Stream</option>
								</Input>
							</FormGroup>
							<FormGroup className="text-left">
								<Label for="ug">Under Graduate</Label>
								<Input type="text" placeholder="Ex: EEE, Mechanical, Computer Engineering" name="ug" id="ug" value={educationalData.ug} onChange={changeHandler} />
							</FormGroup>
							<FormGroup className="text-left">
								<Label for="ugYearOfPassing">Year of Passing</Label>
								<Input type="text" name="ugYearOfPassing" id="ugYearOfPassing" value={educationalData.ugYearOfPassing} onChange={changeHandler} maxLength={4} pattern="\d{1,12}" />
							</FormGroup>
							<FormGroup className="text-left">
								<Label for="ugMarksPercentage">Marks (or) Percentage</Label>
								<Input type="text" name="ugMarksPercentage" id="ugMarksPercentage" value={educationalData.ugMarksPercentage} onChange={changeHandler} maxLength={5} pattern="\d{1,2}(\.\d{1,2})?" />
							</FormGroup>
							<FormGroup className="text-left">
								<Label for="ugBacklog">Back log</Label>
								<Input type="text" name="ugBacklog" id="ugBacklog" value={educationalData.ugBacklog} onChange={changeHandler} maxLength={2} pattern="\d{1,12}" />
							</FormGroup>
						</Col>

						{/* Column 3: Postgraduate Details */}
						<Col md={4}>
							<h3 style={{ ...h2Style, backgroundColor: '#06A8D6', fontSize: '16px' }}>POST GRADUATE</h3>
							<FormGroup className="text-left">
								<Label for="pgStream">Stream</Label>
								<Input
									type="select"
									name="pgStream"
									id="pgStream"
									value={educationalData.pgStream}
									onChange={changeHandler}
								>
									<option value="">Select Stream</option>
									<option value="Commerce Stream">Commerce Stream</option>
									<option value="Arts Stream">Arts Stream</option>
									<option value="Science Stream">Science Stream</option>
									<option value="Engineering Stream">Engineering Stream</option>
								</Input>
							</FormGroup>
							<FormGroup className="text-left">
								<Label for="pg">Post Graduate</Label>
								<Input type="text" placeholder="Ex: EEE, Mechanical, Computer Engineering" name="pg" id="pg" value={educationalData.pg} onChange={changeHandler} />
							</FormGroup>
							<FormGroup className="text-left">
								<Label for="pgYearOfPassing">Year of Passing</Label>
								<Input type="text" name="pgYearOfPassing" id="pgYearOfPassing" value={educationalData.pgYearOfPassing} onChange={changeHandler} maxLength={4} pattern="\d{1,12}" />
							</FormGroup>
							<FormGroup className="text-left">
								<Label for="pgMarksPercentage">Marks (or) Percentage</Label>
								<Input type="text" name="pgMarksPercentage" id="pgMarksPercentage" value={educationalData.pgMarksPercentage} onChange={changeHandler} maxLength={5} pattern="\d{1,2}(\.\d{1,2})?" />
							</FormGroup>
							<FormGroup className="text-left">
								<Label for="pgBacklog">Back log</Label>
								<Input type="text" name="pgBacklog" id="pgBacklog" value={educationalData.pgBacklog} onChange={changeHandler} maxLength={2} />
							</FormGroup>
						</Col>
					</Row>

					<h2 style={h2Style}>ENGLISH OR COMMON TEST</h2>
					<Row form="true" style={rowStyle}>
						{/* Add input fields for English and Common Tests */}
						<Col md={6}>
							<FormGroup className="text-left">
								<Label for="ielts">IELTS</Label>
								<Input type="text" placeholder="Overall Score" name="ielts" id="ielts" value={educationalData.ielts} onChange={changeHandler} maxLength={12} pattern="\d{1,2}(\.\d{1,2})?" />
								<div className="form-sub-options">
									<FormGroup>
										<Label for="ieltsListening">Listening</Label>
										<Input type="text" name="ieltsListening" id="ieltsListening" value={educationalData.ieltsListening} onChange={changeHandler} />
									</FormGroup>
									<FormGroup>
										<Label for="ieltsReading">Reading</Label>
										<Input type="text" name="ieltsReading" id="ieltsReading" value={educationalData.ieltsReading} onChange={changeHandler} />
									</FormGroup>
									<FormGroup>
										<Label for="ieltsWriting">Writing</Label>
										<Input type="text" name="ieltsWriting" id="ieltsWriting" value={educationalData.ieltsWriting} onChange={changeHandler} />
									</FormGroup>
									<FormGroup>
										<Label for="ieltsSpeaking">Speaking</Label>
										<Input type="text" name="ieltsSpeaking" id="ieltsSpeaking" value={educationalData.ieltsSpeaking} onChange={changeHandler} />
									</FormGroup>
								</div>
							</FormGroup>
							<hr style={hrStyle} />
						</Col>
						<Col md={6}>
							<FormGroup className="text-left">
								<Label for="toefl">TOEFL</Label>
								<Input type="text" placeholder="Overall Score" name="toefl" id="toefl" value={educationalData.toefl} onChange={changeHandler} maxLength={12} pattern="\d{1,2}(\.\d{1,2})?" />
								<div className="form-sub-options">
									<FormGroup>
										<Label for="toeflListening">Listening</Label>
										<Input type="text" name="toeflListening" id="toeflListening" value={educationalData.toeflListening} onChange={changeHandler} />
									</FormGroup>
									<FormGroup>
										<Label for="toeflReading">Reading</Label>
										<Input type="text" name="toeflReading" id="toeflReading" value={educationalData.toeflReading} onChange={changeHandler} />
									</FormGroup>
									<FormGroup>
										<Label for="toeflWriting">Writing</Label>
										<Input type="text" name="toeflWriting" id="toeflWriting" value={educationalData.toeflWriting} onChange={changeHandler} />
									</FormGroup>
									<FormGroup>
										<Label for="toeflSpeaking">Speaking</Label>
										<Input type="text" name="toeflSpeaking" id="toeflSpeaking" value={educationalData.toeflSpeaking} onChange={changeHandler} />
									</FormGroup>
								</div>
							</FormGroup>
							<hr style={hrStyle} />
						</Col>
						<Col md={6}>
							<FormGroup className="text-left">
								<Label for="pte">PTE</Label>
								<Input type="text" placeholder="Overall Score" name="pte" id="pte" value={educationalData.pte} onChange={changeHandler} maxLength={12} pattern="\d{1,2}(\.\d{1,2})?" />
								<div className="form-sub-options">
									<FormGroup>
										<Label for="pteListening">Listening</Label>
										<Input type="text" name="pteListening" id="pteListening" value={educationalData.pteListening} onChange={changeHandler} />
									</FormGroup>
									<FormGroup>
										<Label for="pteReading">Reading</Label>
										<Input type="text" name="pteReading" id="pteReading" value={educationalData.pteReading} onChange={changeHandler} />
									</FormGroup>
									<FormGroup>
										<Label for="pteWriting">Writing</Label>
										<Input type="text" name="pteWriting" id="pteWriting" value={educationalData.pteWriting} onChange={changeHandler} />
									</FormGroup>
									<FormGroup>
										<Label for="pteSpeaking">Speaking</Label>
										<Input type="text" name="pteSpeaking" id="pteSpeaking" value={educationalData.pteSpeaking} onChange={changeHandler} />
									</FormGroup>
								</div>
							</FormGroup>
							<hr style={hrStyle} />
						</Col>
						<Col md={6}>
							<FormGroup className="text-left">
								<Label for="gmat">GMAT</Label>
								<Input type="text" placeholder="Overall Score" name="gmat" id="gmat" value={educationalData.gmat} onChange={changeHandler} maxLength={12} pattern="\d{1,2}(\.\d{1,2})?" />
								<div className="form-sub-options">
									<FormGroup>
										<Label for="gmatQuantitative">Quantitative</Label>
										<Input type="text" name="gmatQuantitative" id="gmatQuantitative" value={educationalData.gmatQuantitative} onChange={changeHandler} />
									</FormGroup>

									<FormGroup>
										<Label for="gmatVerbal">Verbal</Label>
										<Input type="text" name="gmatVerbal" id="gmatVerbal" value={educationalData.gmatVerbal} onChange={changeHandler} />
									</FormGroup>

									<FormGroup>
										<Label for="gmatAnalyticalWriting">Analytical Writing</Label>
										<Input type="text" name="gmatAnalyticalWriting" id="gmatAnalyticalWriting" value={educationalData.gmatAnalyticalWriting} onChange={changeHandler} />
									</FormGroup>

									<FormGroup>
										<Label for="gmatIntegratedReasoning">Integrated Reasoning</Label>
										<Input type="text" name="gmatIntegratedReasoning" id="gmatIntegratedReasoning" value={educationalData.gmatIntegratedReasoning} onChange={changeHandler} />
									</FormGroup>
								</div>
							</FormGroup>
							<hr style={hrStyle} />
						</Col>
						<Col md={6}>
							<FormGroup className="text-left">
								<Label for="gre">GRE</Label>
								<Input type="text" placeholder="Overall Score" name="gre" id="gre" value={educationalData.gre} onChange={changeHandler} maxLength={12} pattern="\d{1,2}(\.\d{1,2})?" />
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup className="text-left">
								<Label for="sat">SAT</Label>
								<Input type="text" placeholder="Overall Score" name="sat" id="sat" value={educationalData.sat} onChange={changeHandler} maxLength={12} pattern="\d{1,2}(\.\d{1,2})?" />
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup className="text-left">
								<Label for="englishMark10">10th English Mark</Label>
								<Input type="text" name="englishMark10" id="englishMark10" value={educationalData.englishMark10} onChange={changeHandler} maxLength={5} pattern="\d{1,2}(\.\d{1,2})?" />
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup className="text-left">
								<Label for="englishMark12">12th English Mark</Label>
								<Input type="text" name="englishMark12" id="englishMark12" value={educationalData.englishMark12} onChange={changeHandler} maxLength={5} pattern="\d{1,2}(\.\d{1,2})?" />
							</FormGroup>
						</Col>
					</Row>

					{showStudyPreference && (
						<div>
							<h2 style={h2Style}>STUDY PREFERENCE</h2>
							<Row form="true" style={rowStyle}>
								<Col md={6}>
									{/* Preferred Country Of Study Dropdown */}
									<FormGroup className="text-left">
										<Label for="countryOptions">Country</Label>
										<Select
											options={countryOptions}
											value={selectedCountry}
											onChange={handleCountryChange}
											placeholder="Select Country"
											className="basic-select"
											classNamePrefix="select"
											name="preferredCountry"
										/>
									</FormGroup>
								</Col>

								<Col md={6}>
									{/* Education Interest Dropdown */}
									<FormGroup className="text-left">
										<Label for="educationInterest">Education Interest</Label>
										<Select
											isMulti
											options={educationInterestOptions}
											value={formData.educationInterest}
											onChange={(selectedOptions) => setFormData({ ...formData, educationInterest: selectedOptions })}
											className="basic-multi-select"
											classNamePrefix="select"
										/>
									</FormGroup>
								</Col>

								<Col md={6}>
									{/* University Dropdown */}
									<FormGroup className="text-left">
										<Label for="universityOptions">University</Label>
										<Select
											isMulti
											options={filteredUniversityOptions}
											value={formData.university}
											onChange={(selectedOptions) => setFormData({ ...formData, university: selectedOptions })}
											placeholder="Select Universities"
											className="basic-multi-select"
											classNamePrefix="select"
										/>
									</FormGroup>
								</Col>


								<Col md={6}>
									{/* Desired Course Dropdown */}
									<FormGroup className="text-left">
										<Label for="desiredCourse">Desired Course</Label>
										<Select
											isMulti
											options={desiredCourseOptions}
											value={desiredCourse}
											onChange={(selectedOptions) => setDesiredCourse(selectedOptions)}
											placeholder="Select Desired Course"
											className="basic-multi-select"
											classNamePrefix="select"
											name="desiredCourse"
										/>

									</FormGroup>
								</Col>

								<Col md={6}>
									{/* Plan to Start - Month and Year Dropdown */}
									<h3 style={{ ...h2Style, backgroundColor: '#06A8D6', fontSize: '16px' }}>PLAN TO START</h3>
									<Row>
										<Col md={6}>
											<FormGroup className="text-left">
												<Label for="month">Month</Label>
												<Select
													options={startMonths}
													onChange={(selectedOption) => setFormData({ ...formData, startMonth: selectedOption.value })}
												/>
											</FormGroup>
										</Col>

										<Col md={6}>
											<FormGroup className="text-left">
												<Label for="year">Year</Label>
												<Select
													options={startYears}
													onChange={(selectedOption) => setFormData({ ...formData, startYear: selectedOption.value })}
												/>
											</FormGroup>
										</Col>
									</Row>
								</Col>

								<Col md={6}>
									{/* Enquiry Date - Datepicker */}
									<FormGroup className="text-left">
										<Label for="enquiryDate">Enquiry Date</Label>
										<Input
											type="date"
											name="enquiryDate"
											id="enquiryDate"
											value={formData.enquiryDate}
											onChange={(e) => setFormData({ ...formData, enquiryDate: e.target.value })}
											required="true"
										/>
									</FormGroup>
								</Col>

								<Col md={6}>
									{/* Follow up Date - Datepicker */}
									<FormGroup className="text-left">
										<Label for="followUpDate">Follow up Date</Label>
										<Input
											type="date"
											name="followUpDate"
											id="followUpDate"
											value={formData.followUpDate}
											onChange={(e) => setFormData({ ...formData, followUpDate: e.target.value })}
										/>
									</FormGroup>
								</Col>

								<Col md={6}>
									{/* Rate This Student */}
									<FormGroup className="text-left">
										<Label for="rateStudentOptions">Rate This Student</Label>
										<Select
											options={rateStudentOptions}
											onChange={(selectedOption) => setFormData({ ...formData, rateStudent: selectedOption.value })}
										/>
									</FormGroup>
								</Col>

								<Col md={6}>

									{/* Source */}
									<FormGroup className="text-left">
										<Label for="sourceOptions">Source</Label>
										<Select
											options={sourceOptions}
											onChange={(selectedOption) => setFormData({ ...formData, source: selectedOption.value })}
										/>
									</FormGroup>
								</Col>

								<Col md={6}>

									{/* Counselor Name */}
									<FormGroup className="text-left">
										<Label for="counselorNameOptions">Counselor Name</Label>
										<Select
											options={counselors}
											value={selectedCounselor}
											onChange={handleCounselorChange}
											placeholder="Select Counselor"
											className="basic-select"
											classNamePrefix="select"
											name="counselor"
										/>
									</FormGroup>


								</Col>
							</Row>
						</div>
					)}

					<Row form="true" style={rowStyle}>
						<Col md={12}>
							<FormGroup className="text-left">
								<Label for="additionalNote">Additional Note</Label>
								<textarea
									className="form-control"
									name="additionalNote"
									id="additionalNote"
									value={formData.additionalNote} // Link to formData
									onChange={(e) => setFormData({ ...formData, additionalNote: e.target.value })}
									rows="3"
								></textarea>
							</FormGroup>
						</Col>
					</Row>


					<Col md={12} className="d-flex align-items-center justify-content-center">
						<Row>


							{errorMessage && (
								<Alert status="error" mt={4}>
									<AlertIcon />
									<AlertDescription>{errorMessage}</AlertDescription>
									<CloseButton
										position="absolute"
										right="8px"
										top="8px"
										onClick={() => setErrorMessage('')}
									/>
								</Alert>
							)}
							<button type="submit" className="btn btn-primary btn-lg btn-block btn-square mt-20">Submit</button>
						</Row>
					</Col>

				</Form>
			</Container>
		</Main>
	);
};

export default AddEnquiry;