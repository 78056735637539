const LeCordonBlueNZCourses = [
	{ value: "New Zealand Certificate in French Cuisine, Level 2 (HV4483)", label: "New Zealand Certificate in French Cuisine, Level 2 (HV4483)" },
	{ value: "New Zealand Certificate in French Cuisine, Level 3 (HV4484)", label: "New Zealand Certificate in French Cuisine, Level 3 (HV4484)" },
	{ value: "New Zealand Certificate in French Cuisine, Level 4 (HV4485)", label: "New Zealand Certificate in French Cuisine, Level 4 (HV4485)" },
	{ value: "New Zealand Certificate in French Patisserie, Level 2 (HV4487)", label: "New Zealand Certificate in French Patisserie, Level 2 (HV4487)" },
	{ value: "New Zealand Certificate in French Patisserie, Level 3 (HV4488)", label: "New Zealand Certificate in French Patisserie, Level 3 (HV4488)" },
	{ value: "New Zealand Certificate in French Patisserie, Level 4 (HV4489)", label: "New Zealand Certificate in French Patisserie, Level 4 (HV4489)" },
	{ value: "Bachelor of Culinary Arts and Business (HV4582)", label: "Bachelor of Culinary Arts and Business (HV4582)" }
];

export default LeCordonBlueNZCourses;