const BedfordshireCourses = [
	{ value: "Sport Development and Management BA (Hons)", label: "Sport Development and Management BA (Hons)" },
	{ value: "BSc (Hons) Artificial Intelligence and Robotics", label: "BSc (Hons) Artificial Intelligence and Robotics" },
	{ value: "Journalism BA (Hons)", label: "Journalism BA (Hons)" },
	{ value: "Football Studies BA (Hons)", label: "Football Studies BA (Hons)" },
	{ value: "Mass Communications with Data Analytics MA", label: "Mass Communications with Data Analytics MA" },
	{ value: "MSc Applied computing and information technology (15 Months)", label: "MSc Applied computing and information technology (15 Months)" },
	{ value: "BSc (Hons) Aviation and Airport Management", label: "BSc (Hons) Aviation and Airport Management" },
	{ value: "BSc (Hons) Construction Management (Top up)", label: "BSc (Hons) Construction Management (Top up)" },
	{ value: "Education with Psychology BA (Hons)", label: "Education with Psychology BA (Hons)" },
	{ value: "Education Studies & English BA (Hons)", label: "Education Studies & English BA (Hons)" },
	{ value: "Business Studies (International) BSc (Hons)", label: "Business Studies (International) BSc (Hons)" },
	{ value: "MA Criminology", label: "MA Criminology" },
	{ value: "MSc International Human Resource Management", label: "MSc International Human Resource Management" },
	{ value: "MSc Dance Science", label: "MSc Dance Science" },
	{ value: "Pre-sessional English 12 weeks", label: "Pre-sessional English 12 weeks" },
	{ value: "Accounting BA (Hons)", label: "Accounting BA (Hons)" },
	{ value: "Accounting and Finance BSc (Hons)", label: "Accounting and Finance BSc (Hons)" },
	{ value: "Travel, Aviation and Tourism Management BSc (Hons)", label: "Travel, Aviation and Tourism Management BSc (Hons)" },
	{ value: "Paramedic Science BSc (Hons)", label: "Paramedic Science BSc (Hons)" },
	{ value: "BSc (Hons) Food and Nutrition Science", label: "BSc (Hons) Food and Nutrition Science" },
	{ value: "Illustration BA (Hons)", label: "Illustration BA (Hons)" },
	{ value: "Education (Social Justice) MA", label: "Education (Social Justice) MA" },
	{ value: "(LLM) International Oil and Gas Law", label: "(LLM) International Oil and Gas Law" },
	{ value: "BSc (Hons) Business Information Systems", label: "BSc (Hons) Business Information Systems" },
	{ value: "Pharmacology & Health Science BSc (Hons)", label: "Pharmacology & Health Science BSc (Hons)" },
	{ value: "Sustainable Construction FD", label: "Sustainable Construction FD" },
	{ value: "Sport Science and Coaching BSc (Hons)", label: "Sport Science and Coaching BSc (Hons)" },
	{ value: "Sport and Physical Education BA (Hons)", label: "Sport and Physical Education BA (Hons)" },
	{ value: "Education BA (Hons)", label: "Education BA (Hons)" },
	{ value: "Business Studies with Marketing BSc (Hons)", label: "Business Studies with Marketing BSc (Hons)" },
	{ value: "International Tourism with Hospitality Management BSc (Hons)", label: "International Tourism with Hospitality Management BSc (Hons)" },
	{ value: "Clinical Exercise Therapy BSc (Hons)", label: "Clinical Exercise Therapy BSc (Hons)" },
	{ value: "Art & Design with Data Analytics MA", label: "Art & Design with Data Analytics MA" },
	{ value: "MSc International Tourism Planning and Management", label: "MSc International Tourism Planning and Management" },
	{ value: "MSc Computer Security and Forensics", label: "MSc Computer Security and Forensics" },
	{ value: "Forensic Science & Criminology BSc (Hons)", label: "Forensic Science & Criminology BSc (Hons)" },
	{ value: "Football Coaching BA (Hons)", label: "Football Coaching BA (Hons)" },
	{ value: "Dance Performance & Choreography MA", label: "Dance Performance & Choreography MA" },
	{ value: "MSc Accounting and Business Finance with Data Analytics", label: "MSc Accounting and Business Finance with Data Analytics" },
	{ value: "Business Administration (Finance) MBA", label: "Business Administration (Finance) MBA" },
	{ value: "Pre-sessional English 4 weeks", label: "Pre-sessional English 4 weeks" },
	{ value: "Pre-sessional English 16 weeks", label: "Pre-sessional English 16 weeks" },
	{ value: "Accounting and Financial Services (Top up) BA (Hons)", label: "Accounting and Financial Services (Top up) BA (Hons)" },
	{ value: "Football Business BA (Hons)", label: "Football Business BA (Hons)" },
	{ value: "Sport and Physical Activity (Leadership and Management) MA", label: "Sport and Physical Activity (Leadership and Management) MA" },
	{ value: "Applied Linguistics (TESOL) MA", label: "Applied Linguistics (TESOL) MA" },
	{ value: "International Education (Teaching) MA", label: "International Education (Teaching) MA" },
	{ value: "MSc Automotive Engineering for Electric Vehicles", label: "MSc Automotive Engineering for Electric Vehicles" },
	{ value: "MSc Applied Computing and Information Technology with Project Management", label: "MSc Applied Computing and Information Technology with Project Management" },
	{ value: "Sport Science and Personal Training BSc (Hons)", label: "Sport Science and Personal Training BSc (Hons)" },
	{ value: "Business Studies (Project Management) BSc (Hons)", label: "Business Studies (Project Management) BSc (Hons)" },
	{ value: "International Tourism Management BSc (Hons)", label: "International Tourism Management BSc (Hons)" },
	{ value: "Screen Performance & Communication Techniques MA", label: "Screen Performance & Communication Techniques MA" },
	{ value: "MSc Microbiology in Public Health (15 month)", label: "MSc Microbiology in Public Health (15 month)" },
	{ value: "MBA (with Placement)", label: "MBA (with Placement)" },
	{ value: "Psychology, Counselling & Therapies BSc (Hons)", label: "Psychology, Counselling & Therapies BSc (Hons)" },
	{ value: "Criminology BSc (Hons)", label: "Criminology BSc (Hons)" },
	{ value: "Bioengineering MSc", label: "Bioengineering MSc" },
	{ value: "MSc Marketing", label: "MSc Marketing" },
	{ value: "MBA Business Administration with Data Analytics", label: "MBA Business Administration with Data Analytics" },
	{ value: "Business Administration (Human Resource Management) MBA", label: "Business Administration (Human Resource Management) MBA" },
	{ value: "Business Administration (Internship) MBA", label: "Business Administration (Internship) MBA" },
	{ value: "MSc Sustainable Management", label: "MSc Sustainable Management" },
	{ value: "International Business BSc (Hons)", label: "International Business BSc (Hons)" },
	{ value: "Aviation & Airport Management BSc (Hons)", label: "Aviation & Airport Management BSc (Hons)" },
	{ value: "BSc (hons) Marketing with International Tourism Management", label: "BSc (hons) Marketing with International Tourism Management" },
	{ value: "BSc (Hons) Pharmacology and Health Science", label: "BSc (Hons) Pharmacology and Health Science" },
	{ value: "MSc Project Management", label: "MSc Project Management" },
	{ value: "MSc Computer Science with Project Management", label: "MSc Computer Science with Project Management" },
	{ value: "MA Dance Performance and Choreography", label: "MA Dance Performance and Choreography" },
	{ value: "MSc Event Management", label: "MSc Event Management" },
	{ value: "MSc International Business with Law", label: "MSc International Business with Law" },
	{ value: "Agriculture FD of Science", label: "Agriculture FD of Science" },
	{ value: "BSc (Hons) Business Studies (General)", label: "BSc (Hons) Business Studies (General)" },
	{ value: "BSc (Hons) Pharmaceutical and Chemical Sciences", label: "BSc (Hons) Pharmaceutical and Chemical Sciences" },
	{ value: "Environmental Health Science BSc (Hons)", label: "Environmental Health Science BSc (Hons)" },
	{ value: "Human Bioscience with Enterprise BSc (Hons)", label: "Human Bioscience with Enterprise BSc (Hons)" },
	{ value: "MSc Food Security in Public Health", label: "MSc Food Security in Public Health" },
	{ value: "MA Art and design", label: "MA Art and design" },
	{ value: "Business Administration MBA", label: "Business Administration MBA" },
	{ value: "Business Administration (Hospital and Health Services Management) MBA", label: "Business Administration (Hospital and Health Services Management) MBA" },
	{ value: "Clinical Exercise Physiology", label: "Clinical Exercise Physiology" },
	{ value: "Sport Journalism BA (Hons)", label: "Sport Journalism BA (Hons)" },
	{ value: "Computer Networking BSc (Hons)", label: "Computer Networking BSc (Hons)" },
	{ value: "MA Education", label: "MA Education" },
	{ value: "MA Mass Communications", label: "MA Mass Communications" },
	{ value: "MSc International Finance and Banking", label: "MSc International Finance and Banking" },
	{ value: "MSc Health Psychology", label: "MSc Health Psychology" },
	{ value: "Sports Therapy and Rehabilitation BSc (Hons)", label: "Sports Therapy and Rehabilitation BSc (Hons)" },
	{ value: "Television Production MA", label: "Television Production MA" },
	{ value: "MA International Journalism with Data Analytics", label: "MA International Journalism with Data Analytics" },
	{ value: "MSc Project Management with Data Analytics", label: "MSc Project Management with Data Analytics" },
	{ value: "Creative Writing BA (Hons)", label: "Creative Writing BA (Hons)" },
	{ value: "MSc International Business with Data Analytics", label: "MSc International Business with Data Analytics" },
	{ value: "MSc Biotechnology", label: "MSc Biotechnology" },
	{ value: "MSc Environmental Management", label: "MSc Environmental Management" },
	{ value: "MSc Information Systems Management", label: "MSc Information Systems Management" },
	{ value: "Telecommunications and Network Engineering Bachelor of Engineering BEng (Hons)", label: "Telecommunications and Network Engineering Bachelor of Engineering BEng (Hons)" },
	{ value: "Media Communications BA (Hons)", label: "Media Communications BA (Hons)" },
	{ value: "Business Management with Law BSc (Hons)", label: "Business Management with Law BSc (Hons)" },
	{ value: "Business Studies with Finance BSc (Hons)", label: "Business Studies with Finance BSc (Hons)" },
	{ value: "Advertising & Marketing Communications BA (Hons)", label: "Advertising & Marketing Communications BA (Hons)" },
	{ value: "Fashion Design BA (Hons)", label: "Fashion Design BA (Hons)" },
	{ value: "Physical Activity, Nutrition & Behaviour Change MSc", label: "Physical Activity, Nutrition & Behaviour Change MSc" },
	{ value: "MSc Strength and Conditioning", label: "MSc Strength and Conditioning" },
	{ value: "MSc Nursing with Registration (Adult)", label: "MSc Nursing with Registration (Adult)" },
	{ value: "MA English Literature", label: "MA English Literature" },
	{ value: "Events Management BSc (Hons)", label: "Events Management BSc (Hons)" },
	{ value: "Marketing BSc (Hons)", label: "Marketing BSc (Hons)" },
	{ value: "MSc Computer Networking with Project Management", label: "MSc Computer Networking with Project Management" },
	{ value: "MSc Electronic Engineering with Project Management", label: "MSc Electronic Engineering with Project Management" },
	{ value: "MSc Biotechnology with Project Management", label: "MSc Biotechnology with Project Management" },
	{ value: "MSc Forensic Psychology", label: "MSc Forensic Psychology" },
	{ value: "MSc International Business", label: "MSc International Business" },
	{ value: "Law with Psychology LLB (Hons)", label: "Law with Psychology LLB (Hons)" },
	{ value: "Cybersecurity BSc (Hons)", label: "Cybersecurity BSc (Hons)" },
	{ value: "Graphic Design BA (Hons)", label: "Graphic Design BA (Hons)" },
	{ value: "Clinical Exercise Physiology MSc", label: "Clinical Exercise Physiology MSc" },
	{ value: "MSc International Business with Aviation Management", label: "MSc International Business with Aviation Management" },
	{ value: "MSc Cyber Security with Project Management", label: "MSc Cyber Security with Project Management" },
	{ value: "BEng (Hons) Mechanical Engineering", label: "BEng (Hons) Mechanical Engineering" },
	{ value: "Biomedical Science BSc (Hons)", label: "Biomedical Science BSc (Hons)" },
	{ value: "Creative Writing & Journalism BA (Hons)", label: "Creative Writing & Journalism BA (Hons)" },
	{ value: "Law LLB (Hons)", label: "Law LLB (Hons)" },
	{ value: "Law with Criminology LLB (Hons)", label: "Law with Criminology LLB (Hons)" },
	{ value: "Business Economics BSc (Hons)", label: "Business Economics BSc (Hons)" },
	{ value: "Social Sciences BA (Hons)", label: "Social Sciences BA (Hons)" },
	{ value: "International Journalism with Project Management MA", label: "International Journalism with Project Management MA" },
	{ value: "BSc (Hons) Computer Science", label: "BSc (Hons) Computer Science" },
	{ value: "Automotive Engineering BEng (Hons)", label: "Automotive Engineering BEng (Hons)" },
	{ value: "Business Administration (Digital Technology Management) MBA", label: "Business Administration (Digital Technology Management) MBA" },
	{ value: "MA International Journalism", label: "MA International Journalism" },
	{ value: "Early Years Education BA (Hons)", label: "Early Years Education BA (Hons)" },
	{ value: "Biochemistry BSc (Hons)", label: "Biochemistry BSc (Hons)" },
	{ value: "MSc Integrated Healthcare Practice and Strategic Leadership", label: "MSc Integrated Healthcare Practice and Strategic Leadership" },
	{ value: "MSc Electronic Engineering with placement", label: "MSc Electronic Engineering with placement" },
	{ value: "MSc Financial Risk Management", label: "MSc Financial Risk Management" },
	{ value: "BA (Hons) Health and Social Care", label: "BA (Hons) Health and Social Care" },
	{ value: "Information & Data Systems BSc (Hons)", label: "Information & Data Systems BSc (Hons)" },
	{ value: "Strength & Conditioning BSc (Hons)", label: "Strength & Conditioning BSc (Hons)" },
	{ value: "Art & Design BA (Hons)", label: "Art & Design BA (Hons)" },
	{ value: "MSc Biotechnology with Data Analytics", label: "MSc Biotechnology with Data Analytics" },
	{ value: "Business Administration (Logistics and Supply Chain Management) MBA", label: "Business Administration (Logistics and Supply Chain Management) MBA" },
	{ value: "Business Administration (Oil and Gas Management) MBA", label: "Business Administration (Oil and Gas Management) MBA" },
	{ value: "Pre-sessional English 2 weeks", label: "Pre-sessional English 2 weeks" },
	{ value: "Sport and Exercise Science BSc (Hons)", label: "Sport and Exercise Science BSc (Hons)" },
	{ value: "Social Work BSc (Hons)", label: "Social Work BSc (Hons)" },
	{ value: "MSc Pharmacology (15 month)", label: "MSc Pharmacology (15 month)" },
	{ value: "Pre-sessional English 24 weeks", label: "Pre-sessional English 24 weeks" },
	{ value: "MSc Applied psychology (Conversion)", label: "MSc Applied psychology (Conversion)" },
	{ value: "International Finance & Banking BSc (Hons)", label: "International Finance & Banking BSc (Hons)" },
	{ value: "Film & Television Production BA (Hons)", label: "Film & Television Production BA (Hons)" },
	{ value: "Performing Arts BA (Hons)", label: "Performing Arts BA (Hons)" },
	{ value: "Adult Nursing BSc (Hons)", label: "Adult Nursing BSc (Hons)" },
	{ value: "Mass Communications with Project Management MA", label: "Mass Communications with Project Management MA" },
	{ value: "Pharmacology with Data Analytics MSc", label: "Pharmacology with Data Analytics MSc" },
	{ value: "Animation BA (Hons)", label: "Animation BA (Hons)" },
	{ value: "Psychology BSc (Hons)", label: "Psychology BSc (Hons)" },
	{ value: "Human Resources Management with Law (Accredited) BSc (Hons)", label: "Human Resources Management with Law (Accredited) BSc (Hons)" },
	{ value: "Computer Games Development BSc (Hons)", label: "Computer Games Development BSc (Hons)" },
	{ value: "Computer Science BSc (Hons)", label: "Computer Science BSc (Hons)" },
	{ value: "Film Production BA (Hons)", label: "Film Production BA (Hons)" },
	{ value: "Media Performance for Film TV & Theatre BA (Hons)", label: "Media Performance for Film TV & Theatre BA (Hons)" },
	{ value: "Media Production BA (Hons)", label: "Media Production BA (Hons)" },
	{ value: "International Social Welfare and Social Development MA", label: "International Social Welfare and Social Development MA" },
	{ value: "MSc Mechanical Engineering", label: "MSc Mechanical Engineering" },
	{ value: "MSc Microbiology in Public Health", label: "MSc Microbiology in Public Health" },
	{ value: "Media, Marketing and Public Relations BA (Hons)", label: "Media, Marketing and Public Relations BA (Hons)" },
	{ value: "BA (Hons) Criminology and Sociology", label: "BA (Hons) Criminology and Sociology" },
	{ value: "Health, Nutrition & Exercise BSc (Hons)", label: "Health, Nutrition & Exercise BSc (Hons)" },
	{ value: "MSc International Relations Management", label: "MSc International Relations Management" },
	{ value: "MSc Public Health", label: "MSc Public Health" },
	{ value: "MSc Computer Networking", label: "MSc Computer Networking" },
	{ value: "(LLM) International Commercial and Dispute Resolution Law", label: "(LLM) International Commercial and Dispute Resolution Law" },
	{ value: "Advertising and Marketing Communications BSc (Hons)", label: "Advertising and Marketing Communications BSc (Hons)" },
	{ value: "Biological Science BSc (Hons)", label: "Biological Science BSc (Hons)" },
	{ value: "Television Production BA (Hons)", label: "Television Production BA (Hons)" },
	{ value: "English Literature BA (Hons)", label: "English Literature BA (Hons)" },
	{ value: "International Tourism with Events Management BSc (Hons)", label: "International Tourism with Events Management BSc (Hons)" },
	{ value: "Computer Animation & Visual Effects BSc (Hons)", label: "Computer Animation & Visual Effects BSc (Hons)" },
	{ value: "MSc Purchasing, Logistics and Supply Chain Management", label: "MSc Purchasing, Logistics and Supply Chain Management" },
	{ value: "BSc (Hons) Business Management", label: "BSc (Hons) Business Management" },
	{ value: "Dance and Professional Practice BA (Hons)", label: "Dance and Professional Practice BA (Hons)" },
	{ value: "Law with Financial Management (Hons)", label: "Law with Financial Management (Hons)" },
	{ value: "Economics & Finance BSc (Hons)", label: "Economics & Finance BSc (Hons)" },
	{ value: "Photography BA (Hons)", label: "Photography BA (Hons)" },
	{ value: "Purchasing Logistics and Supply Chain Management with Data Analytics", label: "Purchasing Logistics and Supply Chain Management with Data Analytics" },
	{ value: "MSc Pharmacology with Project Management", label: "MSc Pharmacology with Project Management" },
	{ value: "MSc Pharmacology", label: "MSc Pharmacology" },
	{ value: "Childhood and Youth: Applied Perspectives- MA", label: "Childhood and Youth: Applied Perspectives- MA" },
	{ value: "Sports Science (Sports Coaching) FD of Science", label: "Sports Science (Sports Coaching) FD of Science" },
	{ value: "Psychology and Criminal Behaviour BSc (Hons)", label: "Psychology and Criminal Behaviour BSc (Hons)" },
	{ value: "Marketing with Aviation & Airport Management BSc (Hons)", label: "Marketing with Aviation & Airport Management BSc (Hons)" },
	{ value: "Child & Youth Studies BA (Hons)", label: "Child & Youth Studies BA (Hons)" },
	{ value: "MSc Biomedical Engineering", label: "MSc Biomedical Engineering" },
	{ value: "Advertising and Branding Design BA (Hons)", label: "Advertising and Branding Design BA (Hons)" },
	{ value: "BA (Hons) Interior Design", label: "BA (Hons) Interior Design" },
	{ value: "BSc (Hons) Information Technology", label: "BSc (Hons) Information Technology" },
	{ value: "Interior Architecture BA (Hons)", label: "Interior Architecture BA (Hons)" },
	{ value: "MSc Accounting & Business Finance", label: "MSc Accounting & Business Finance" },
	{ value: "BA (Hons) Early Childhood Education", label: "BA (Hons) Early Childhood Education" },
	{ value: "Forensic Science BSc (Hons)", label: "Forensic Science BSc (Hons)" },
	{ value: "Software Engineering & Applications with Project Management MSc", label: "Software Engineering & Applications with Project Management MSc" },
	{ value: "MSc Digital Marketing", label: "MSc Digital Marketing" },
	{ value: "MSc International Business (with Placement)", label: "MSc International Business (with Placement)" },
	{ value: "MSc Management", label: "MSc Management" },
	{ value: "MSc Computer Science with placement", label: "MSc Computer Science with placement" },
	{ value: "Business Administration (Marketing) MBA", label: "Business Administration (Marketing) MBA" },
	{ value: "Product Design BSc (Hons)", label: "Product Design BSc (Hons)" },
	{ value: "BEng (Hons) Electronic Engineering", label: "BEng (Hons) Electronic Engineering" },
	{ value: "BSc (Hons) Software Engineering", label: "BSc (Hons) Software Engineering" },
	{ value: "Human Resource Management (Accredited) BSc (Hons)", label: "Human Resource Management (Accredited) BSc (Hons)" },
	{ value: "Acting BA (Hons)", label: "Acting BA (Hons)" },
	{ value: "Criminology & Sociology BSc (Hons)", label: "Criminology & Sociology BSc (Hons)" },
	{ value: "Cyber Security MSc", label: "Cyber Security MSc" },
	{ value: "LLM International Business Law", label: "LLM International Business Law" },
	{ value: "MSc Software Engineering and Applications", label: "MSc Software Engineering and Applications" },
	{ value: "MSc Electronic Engineering", label: "MSc Electronic Engineering" },
	{ value: "MSc Financial Economics", label: "MSc Financial Economics" },
	{ value: "MA Childhood and Youth: Applied Perspectives", label: "MA Childhood and Youth: Applied Perspectives" },
	{ value: "Pre-sessional English 8 weeks", label: "Pre-sessional English 8 weeks" },
	{ value: "MSc in Computer Science", label: "MSc in Computer Science" },
	{ value: "MA Creative Digital Film Production", label: "MA Creative Digital Film Production" }
];

export default BedfordshireCourses;