const CambrianCourses = [
	{ value: 'Graduate Certificate in Global Business Management (GBGC)', label: 'Graduate Certificate in Global Business Management (GBGC)' },
	{ value: 'Advanced Diploma in Computer Systems Technology', label: 'Advanced Diploma in Computer Systems Technology' },
	{ value: 'Graduate Certificate in Human Resources Management', label: 'Graduate Certificate in Human Resources Management' },
	{ value: 'Honours Bachelor of Business Administration - Accounting', label: 'Honours Bachelor of Business Administration - Accounting' },
	{ value: 'Graduate Certificate in Community and Health Services Navigation', label: 'Graduate Certificate in Community and Health Services Navigation' },
	{ value: 'Graduate Certificate in Project Management', label: 'Graduate Certificate in Project Management' },
	{ value: 'Advanced Diploma in Business Administration - Accounting', label: 'Advanced Diploma in Business Administration - Accounting' },
	{ value: 'Certificate in Personal Support Worker', label: 'Certificate in Personal Support Worker' },
	{ value: 'Diploma in Game Design', label: 'Diploma in Game Design' },
	{ value: 'Diploma in Fitness and Health Promotion', label: 'Diploma in Fitness and Health Promotion' },
	{ value: 'Certificate in Pre-Health Sciences (Pathway to Advanced Diplomas and Degrees)', label: 'Certificate in Pre-Health Sciences (Pathway to Advanced Diplomas and Degrees)' },
	{ value: 'Graduate Certificate in Cloud Computing', label: 'Graduate Certificate in Cloud Computing' },
	{ value: 'Diploma in Civil Engineering Technician', label: 'Diploma in Civil Engineering Technician' },
	{ value: 'Diploma in Motive Power Technician - Truck and Coach (Optional Co-op)', label: 'Diploma in Motive Power Technician - Truck and Coach (Optional Co-op)' },
	{ value: 'Honours Bachelor of Business Administration - General', label: 'Honours Bachelor of Business Administration - General' },
	{ value: 'Certificate in Arts and Design Fundamentals', label: 'Certificate in Arts and Design Fundamentals' },
	{ value: 'Advanced Diploma in Electromechanical Engineering Technology- Mechatronics', label: 'Advanced Diploma in Electromechanical Engineering Technology- Mechatronics' },
	{ value: 'Diploma in Heating, Ventilation & Air Conditioning Technician', label: 'Diploma in Heating, Ventilation & Air Conditioning Technician' },
	{ value: 'Advanced Diploma in Mining Engineering Technology', label: 'Advanced Diploma in Mining Engineering Technology' },
	{ value: 'Graduate Certificate in Environmental Monitoring & Impact Assessment', label: 'Graduate Certificate in Environmental Monitoring & Impact Assessment' },
	{ value: 'Diploma in Powerline Technician', label: 'Diploma in Powerline Technician' },
	{ value: 'Diploma in Heavy Equipment Technician', label: 'Diploma in Heavy Equipment Technician' },
	{ value: 'Graduate Certificate in Addictions and Mental Health', label: 'Graduate Certificate in Addictions and Mental Health' },
	{ value: 'Diploma in Media Communications', label: 'Diploma in Media Communications' },
	{ value: 'Honours Bachelor of Science in Nursing', label: 'Honours Bachelor of Science in Nursing' },
	{ value: 'Certificate in Carpentry Renovation Techniques', label: 'Certificate in Carpentry Renovation Techniques' },
	{ value: 'Graduate Certificate in Project Management (Hanson)', label: 'Graduate Certificate in Project Management (Hanson)' },
	{ value: 'Diploma in Social Service Worker', label: 'Diploma in Social Service Worker' },
	{ value: 'Diploma in Business - Finance', label: 'Diploma in Business - Finance' },
	{ value: 'Graduate Certificate in Cybersecurity', label: 'Graduate Certificate in Cybersecurity' },
	{ value: 'Diploma in Early Childhood Education', label: 'Diploma in Early Childhood Education' },
	{ value: 'Diploma in Workplace Safety and Prevention', label: 'Diploma in Workplace Safety and Prevention' },
	{ value: 'Advanced Diploma in Chemical Technology- Lab and Process Control', label: 'Advanced Diploma in Chemical Technology- Lab and Process Control' },
	{ value: 'Advanced Diploma in Dental Hygiene', label: 'Advanced Diploma in Dental Hygiene' },
	{ value: 'Advanced Diploma in Medical Laboratory Technology', label: 'Advanced Diploma in Medical Laboratory Technology' },
	{ value: 'Diploma in Mechanical Engineering Technician - Industrial Maintenance (IMTN)', label: 'Diploma in Mechanical Engineering Technician - Industrial Maintenance (IMTN)' },
	{ value: 'Graduate Certificate in Crime Analytics', label: 'Graduate Certificate in Crime Analytics' },
	{ value: 'Honours Bachelor of Business Administration - Business Analytics', label: 'Honours Bachelor of Business Administration - Business Analytics' },
	{ value: 'Graduate Certificate in Professional Accounting Practice', label: 'Graduate Certificate in Professional Accounting Practice' },
	{ value: 'Advanced Diploma in Massage Therapy', label: 'Advanced Diploma in Massage Therapy' },
	{ value: 'Diploma in Community and Justice Services', label: 'Diploma in Community and Justice Services' },
	{ value: 'Certificate in Business Fundamentals', label: 'Certificate in Business Fundamentals' },
	{ value: 'Advanced Diploma in Power Engineering Technology', label: 'Advanced Diploma in Power Engineering Technology' },
	{ value: 'Certificate in Corporate Safety and Security', label: 'Certificate in Corporate Safety and Security' },
	{ value: 'Certificate in Independent Music Production', label: 'Certificate in Independent Music Production' },
	{ value: 'Diploma in Occupational Therapist Assistant and Physiotherapist Assistant', label: 'Diploma in Occupational Therapist Assistant and Physiotherapist Assistant' },
	{ value: 'Advanced Diploma in Music - Performance', label: 'Advanced Diploma in Music - Performance' },
	{ value: 'Diploma in Mechanical Engineering Technician', label: 'Diploma in Mechanical Engineering Technician' },
	{ value: 'Diploma in Electrical Engineering Technician- Industrial', label: 'Diploma in Electrical Engineering Technician- Industrial' },
	{ value: 'Certificate in Dental Assisting (Levels I & II)', label: 'Certificate in Dental Assisting (Levels I & II)' },
	{ value: 'Diploma in Welding and Fabrication Technician', label: 'Diploma in Welding and Fabrication Technician' },
	{ value: 'Graduate Certificate in Supply Chain Management', label: 'Graduate Certificate in Supply Chain Management' },
	{ value: 'Advanced Diploma in Child and Youth Care', label: 'Advanced Diploma in Child and Youth Care' },
	{ value: 'Graduate Certificate in I.T. Business Analysis', label: 'Graduate Certificate in I.T. Business Analysis' },
	{ value: 'Diploma in Power Engineering Technician', label: 'Diploma in Power Engineering Technician' },
	{ value: 'Diploma in Practical Nursing', label: 'Diploma in Practical Nursing' },
	{ value: 'Graduate Certificate in Business Analytics', label: 'Graduate Certificate in Business Analytics' },
	{ value: 'Honours Bachelor of Business Administration - Data Analytics', label: 'Honours Bachelor of Business Administration - Data Analytics' },
	{ value: 'Advanced Diploma in Graphic Design', label: 'Advanced Diploma in Graphic Design' },
	{ value: 'Diploma in Business - Accounting', label: 'Diploma in Business - Accounting' },
	{ value: 'Advanced Diploma in Business Administration', label: 'Advanced Diploma in Business Administration' },
	{ value: 'Graduate Certificate in Mobile Application Development One Year Program', label: 'Graduate Certificate in Mobile Application Development One Year Program' },
	{ value: 'Graduate Certificate in Organization Management', label: 'Graduate Certificate in Organization Management' },
	{ value: 'Diploma in Social Service Worker (Indigenous Specialization)', label: 'Diploma in Social Service Worker (Indigenous Specialization)' },
	{ value: 'Diploma in Business', label: 'Diploma in Business' },
	{ value: 'Diploma in Chemical Engineering Technician', label: 'Diploma in Chemical Engineering Technician' },
	{ value: 'Diploma in Mechanical Engineering Technician - Industrial Maintenance', label: 'Diploma in Mechanical Engineering Technician - Industrial Maintenance' },
	{ value: 'Diploma in Hospitality – Hotel and Restaurant', label: 'Diploma in Hospitality – Hotel and Restaurant' },
	{ value: 'Graduate Certificate in Human Resources Management (Hanson)', label: 'Graduate Certificate in Human Resources Management (Hanson)' },
	{ value: 'Diploma in Developmental Services Worker', label: 'Diploma in Developmental Services Worker' },
	{ value: 'Diploma in Police Foundations', label: 'Diploma in Police Foundations' },
	{ value: 'Advanced Diploma in Animation', label: 'Advanced Diploma in Animation' },
	{ value: 'Diploma in Design and Visual Arts', label: 'Diploma in Design and Visual Arts' },
	{ value: 'Diploma in Motive Power Technician - Service and Management', label: 'Diploma in Motive Power Technician - Service and Management' },
	{ value: 'Advanced Diploma in Civil Engineering Technology', label: 'Advanced Diploma in Civil Engineering Technology' },
	{ value: 'Diploma in Mechanical Engineering Technology', label: 'Diploma in Mechanical Engineering Technology' },
	{ value: 'Diploma in Computer Systems Technician', label: 'Diploma in Computer Systems Technician' },
	{ value: 'Graduate Certificate in International Business Management', label: 'Graduate Certificate in International Business Management' },
	{ value: 'Diploma in Protection, Security and Investigation', label: 'Diploma in Protection, Security and Investigation' },
	{ value: 'Graduate Certificate in Advanced Care Paramedic', label: 'Graduate Certificate in Advanced Care Paramedic' },
	{ value: 'Diploma in Environmental Technician', label: 'Diploma in Environmental Technician' },
	{ value: 'Diploma in Electromechanical Engineering Technician - Mechatronics', label: 'Diploma in Electromechanical Engineering Technician - Mechatronics' },
	{ value: 'Diploma in Mining Engineering Technician', label: 'Diploma in Mining Engineering Technician' },
	{ value: 'Advanced Diploma in Electrical Engineering Technology- Industrial', label: 'Advanced Diploma in Electrical Engineering Technology- Industrial' },
	{ value: 'Graduate Certificate in Health Analytics', label: 'Graduate Certificate in Health Analytics' },
	{ value: 'Graduate Certificate in Public Relations', label: 'Graduate Certificate in Public Relations' },
	{ value: 'Certificate in Pre-Service Firefighter Education and Training', label: 'Certificate in Pre-Service Firefighter Education and Training' },
	{ value: 'Certificate in Pre-Trades/Technology', label: 'Certificate in Pre-Trades/Technology' },
	{ value: 'Honours Bachelor of Business Administration - Digital Marketing', label: 'Honours Bachelor of Business Administration - Digital Marketing' },
	{ value: 'Diploma in Computer Programming - Internet of Things', label: 'Diploma in Computer Programming - Internet of Things' },
];

export default CambrianCourses;
