import React from 'react';
import { Container, Flex } from "@chakra-ui/react";
import AccountSidebar from '../pages/Account/AccountSidebar';
import Main from '../global/wrapper/Main';

const AccountLayout = ({ children }) => {
	return (
		<Main>
			<Container maxW="90%">
				<Flex
					direction={['column', null, 'row']} // Stack vertically on mobile, then row layout for tablet and desktop
					gap="40px"
					wrap="wrap"
				>
					<div style={{
						width: ['100%', null, '20%'], // 100% width on mobile, 20% on tablet and desktop
						marginBottom: ['1rem', null, '0'] // Add bottom margin on mobile only
					}}>
						<AccountSidebar />
					</div>
					<div style={{
						minWidth: ["500px", null, "100%"],
						width: ['100%', null, '100%'] // 100% width on mobile, 78% on tablet and desktop
					}}>
						{children}
					</div>
				</Flex>
			</Container>
		</Main>
	);
}

export default AccountLayout;
