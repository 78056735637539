const UniversityGuelphCourses = [
	{ value: 'Bachelor of Applied Science in Applied Human Nutrition', label: 'Bachelor of Applied Science in Applied Human Nutrition' },
	{ value: 'Bachelor of Applied Science in Child Studies', label: 'Bachelor of Applied Science in Child Studies' },
	{ value: 'Bachelor of Applied Science in Family Studies and Human Development', label: 'Bachelor of Applied Science in Family Studies and Human Development' },
	{ value: 'Bachelor of Arts (General)', label: 'Bachelor of Arts (General)' },
	{ value: 'Bachelor of Arts in Anthropology', label: 'Bachelor of Arts in Anthropology' },
	{ value: 'Bachelor of Arts in Classical Studies', label: 'Bachelor of Arts in Classical Studies' },
	{ value: 'Bachelor of One Health', label: 'Bachelor of One Health' },
	{ value: 'Honours Bachelor of Applied Science in Psychology + Diploma in General Arts & Science', label: 'Honours Bachelor of Applied Science in Psychology + Diploma in General Arts & Science' },
	{ value: 'Honours Bachelor of Business Administration + Diploma in Business Administration', label: 'Honours Bachelor of Business Administration + Diploma in Business Administration' }
];

export default UniversityGuelphCourses;
