const BrunelUniversityCourses = [
	{ value: "BSc in Computer Science (Digital Media and Games)", label: "BSc in Computer Science (Digital Media and Games)" },
	{ value: "BA in Film Production and Theatre", label: "BA in Film Production and Theatre" },
	{ value: "BA in Music (4 years full-time with placement)", label: "BA in Music (4 years full-time with placement)" },
	{ value: "BSc in Visual Effects and Motion Graphics (4 years full-time with placement)", label: "BSc in Visual Effects and Motion Graphics (4 years full-time with placement)" },
	{ value: "BEng in Electronic and Electrical Engineering (Communication Systems) (4 years full-time with placement)", label: "BEng in Electronic and Electrical Engineering (Communication Systems) (4 years full-time with placement)" },
	{ value: "MSc Advanced Clinical Practice", label: "MSc Advanced Clinical Practice" },
	{ value: "LLM in International Financial Regulation and Corporate Law", label: "LLM in International Financial Regulation and Corporate Law" },
	{ value: "BEng in Aerospace Engineering (4 years full-time with placement)", label: "BEng in Aerospace Engineering (4 years full-time with placement)" },
	{ value: "BSc in Digital Design", label: "BSc in Digital Design" },
	{ value: "BSc in Economics and Management", label: "BSc in Economics and Management" },
	{ value: "BA in Film and Television Studies and English", label: "BA in Film and Television Studies and English" },
	{ value: "LLB in Law with Criminal Justice (4 years full-time with placement)", label: "LLB in Law with Criminal Justice (4 years full-time with placement)" },
	{ value: "BSc in Visual Effects and Motion Graphics", label: "BSc in Visual Effects and Motion Graphics" },
	{ value: "BA Industrial Design with Placement (4 years)", label: "BA Industrial Design with Placement (4 years)" },
	{ value: "BA Journalism (Communication)", label: "BA Journalism (Communication)" },
	{ value: "MSc Accounting and Business Intelligence (16 months with Placement)", label: "MSc Accounting and Business Intelligence (16 months with Placement)" },
	{ value: "LLM Intellectual Property Law", label: "LLM Intellectual Property Law" },
	{ value: "BSc in Business Computing (eBusiness) -4 years full-time with placement", label: "BSc in Business Computing (eBusiness) -4 years full-time with placement" },
	{ value: "BSc in Computer Science", label: "BSc in Computer Science" },
	{ value: "BA in Creative Writing (4 years full-time with placement)", label: "BA in Creative Writing (4 years full-time with placement)" },
	{ value: "BSc in Computer Science (Digital Media and Games)", label: "BSc in Computer Science (Digital Media and Games)" },
	{ value: "BA in Film Production and Theatre", label: "BA in Film Production and Theatre" },
	{ value: "BA in Music (4 years full-time with placement)", label: "BA in Music (4 years full-time with placement)" },
	{ value: "BSc in Visual Effects and Motion Graphics (4 years full-time with placement)", label: "BSc in Visual Effects and Motion Graphics (4 years full-time with placement)" },
	{ value: "BEng in Electronic and Electrical Engineering (Communication Systems) (4 years full-time with placement)", label: "BEng in Electronic and Electrical Engineering (Communication Systems) (4 years full-time with placement)" },
	{ value: "MSc Advanced Clinical Practice", label: "MSc Advanced Clinical Practice" },
	{ value: "LLM in International Financial Regulation and Corporate Law", label: "LLM in International Financial Regulation and Corporate Law" },
	{ value: "BEng in Aerospace Engineering (4 years full-time with placement)", label: "BEng in Aerospace Engineering (4 years full-time with placement)" },
	{ value: "BSc in Digital Design", label: "BSc in Digital Design" },
	{ value: "BSc in Economics and Management", label: "BSc in Economics and Management" },
	{ value: "BA in Film and Television Studies and English", label: "BA in Film and Television Studies and English" },
	{ value: "LLB in Law with Criminal Justice (4 years full-time with placement)", label: "LLB in Law with Criminal Justice (4 years full-time with placement)" },
	{ value: "BSc in Visual Effects and Motion Graphics", label: "BSc in Visual Effects and Motion Graphics" },
	{ value: "BA Industrial Design with Placement (4 years)", label: "BA Industrial Design with Placement (4 years)" },
	{ value: "BA Journalism (Communication)", label: "BA Journalism (Communication)" },
	{ value: "MSc Accounting and Business Intelligence (16 months with Placement)", label: "MSc Accounting and Business Intelligence (16 months with Placement)" },
	{ value: "LLM Intellectual Property Law", label: "LLM Intellectual Property Law" },
	{ value: "BSc in Business Computing (eBusiness) -4 years full-time with placement", label: "BSc in Business Computing (eBusiness) -4 years full-time with placement" },
	{ value: "BSc in Computer Science", label: "BSc in Computer Science" },
	{ value: "BA in Creative Writing (4 years full-time with placement)", label: "BA in Creative Writing (4 years full-time with placement)" },
	{ value: "MMath in Financial Mathematics (5 years full-time with placement)", label: "MMath in Financial Mathematics (5 years full-time with placement)" },
	{ value: "BASc in Global Challenges (Security)", label: "BASc in Global Challenges (Security)" },
	{ value: "BSc in Mathematics", label: "BSc in Mathematics" },
	{ value: "MMath in Mathematics (5 years full-time with placement)", label: "MMath in Mathematics (5 years full-time with placement)" },
	{ value: "MEng in Mechanical Engineering", label: "MEng in Mechanical Engineering" },
	{ value: "MSc in Corporate Brand Management (16 Month)", label: "MSc in Corporate Brand Management (16 Month)" },
	{ value: "MSc Structural Engineering", label: "MSc Structural Engineering" },
	{ value: "BSc in Business and Management (Brunel Business School) (4 years full-time with placement)", label: "BSc in Business and Management (Brunel Business School) (4 years full-time with placement)" },
	{ value: "BEng in Computer Systems Engineering", label: "BEng in Computer Systems Engineering" },
	{ value: "BSc in Economics and Business Finance", label: "BSc in Economics and Business Finance" },
	{ value: "MEng in Electronic and Electrical Engineering", label: "MEng in Electronic and Electrical Engineering" },
	{ value: "BA in Film and Television Studies and English (4 years full-time with placement)", label: "BA in Film and Television Studies and English (4 years full-time with placement)" },
	{ value: "BSc in Politics and Sociology (4 years full-time with placement)", label: "BSc in Politics and Sociology (4 years full-time with placement)" },
	{ value: "BA in Theatre and Creative Writing (4 years full-time with placement)", label: "BA in Theatre and Creative Writing (4 years full-time with placement)" },
	{ value: "BEng in Electronic and Electrical Engineering (Energy Systems)) (4 years full-time with placement)", label: "BEng in Electronic and Electrical Engineering (Energy Systems)) (4 years full-time with placement)" },
	{ value: "LLM Intellectual Property Law (16 Months with Placement)", label: "LLM Intellectual Property Law (16 Months with Placement)" },
	{ value: "MSc in Building Services Engineering", label: "MSc in Building Services Engineering" },
	{ value: "MA in Digital Games Theory and Design", label: "MA in Digital Games Theory and Design" },
	{ value: "MSc in Engineering Management", label: "MSc in Engineering Management" },
	{ value: "MSc in Finance and Accounting (2 years full-time with placement )", label: "MSc in Finance and Accounting (2 years full-time with placement )" },
	{ value: "MSc in Media and Communications", label: "MSc in Media and Communications" },
	{ value: "MSc in Psychological Sciences (Conversion)", label: "MSc in Psychological Sciences (Conversion)" },
	{ value: "BSc in Computer Science (Software Engineering)", label: "BSc in Computer Science (Software Engineering)" },
	{ value: "BSc in Financial Mathematics", label: "BSc in Financial Mathematics" },
	{ value: "MSc Water and Environmental Engineering", label: "MSc Water and Environmental Engineering" },
	{ value: "MBA Digital Innovation Management", label: "MBA Digital Innovation Management" },
	{ value: "MSc Digital Design (Immersive Mixed Reality)", label: "MSc Digital Design (Immersive Mixed Reality)" },
	{ value: "MSc Accounting and Business Intelligence", label: "MSc Accounting and Business Intelligence" },
	{ value: "MSc in Business Intelligence and Digital Marketing (Brunel Business School)", label: "MSc in Business Intelligence and Digital Marketing (Brunel Business School)" },
	{ value: "MSc in Finance and Investment", label: "MSc in Finance and Investment" },
	{ value: "PGCE in Primary Education (5-11 years) with recommendation for QTS", label: "PGCE in Primary Education (5-11 years) with recommendation for QTS" },
	{ value: "Presessional English Course (6 weeks)", label: "Presessional English Course (6 weeks)" },
	{ value: "Presessional English Course (4 weeks)", label: "Presessional English Course (4 weeks)" },
	{ value: "BSc in Biomedical Sciences (Biochemistry) (4 years full-time with placement)", label: "BSc in Biomedical Sciences (Biochemistry) (4 years full-time with placement)" },
	{ value: "BSc in Computer Science (Artificial Intelligence) (4 years full-time with placement)", label: "BSc in Computer Science (Artificial Intelligence) (4 years full-time with placement)" },
	{ value: "BSc in International Business (Brunel Business School) (4 year full-time with placement)", label: "BSc in International Business (Brunel Business School) (4 year full-time with placement)" },
	{ value: "BSc in Life Sciences", label: "BSc in Life Sciences" },
	{ value: "BSc in Sociology (Media)", label: "BSc in Sociology (Media)" },
	{ value: "Medicine MBBS", label: "Medicine MBBS" },
	{ value: "MSc Digital Design (3D Animation)", label: "MSc Digital Design (3D Animation)" },
	{ value: "MSc in Business Finance (16 months full-time with placement)", label: "MSc in Business Finance (16 months full-time with placement)" },
	{ value: "MSc in Digital Design and Branding", label: "MSc in Digital Design and Branding" },
	{ value: "Law LLB", label: "Law LLB" },
	{ value: "Mathematics and Computing with Integrated Foundation Year (5 years full-time with placement)", label: "Mathematics and Computing with Integrated Foundation Year (5 years full-time with placement)" },
	{ value: "BSc in Sport, Health and Exercise Sciences", label: "BSc in Sport, Health and Exercise Sciences" },
	{ value: "BEng in Electronic and Electrical Engineering(Artificial Intelligence)(4 years full-time with placement)", label: "BEng in Electronic and Electrical Engineering(Artificial Intelligence)(4 years full-time with placement)" },
	{ value: "MSc Management (2 years with placement)", label: "MSc Management (2 years with placement)" },
	{ value: "MSc Advanced Clinical Practice (Neurological Rehabilitation)", label: "MSc Advanced Clinical Practice (Neurological Rehabilitation)" },
	{ value: "MSc in Advanced Engineering Design", label: "MSc in Advanced Engineering Design" },
	{ value: "MSc in Cognitive and Clinical Neuroscience", label: "MSc in Cognitive and Clinical Neuroscience" },
	{ value: "MSc in Medical Anthropology", label: "MSc in Medical Anthropology" },
	{ value: "BEng in Electronic and Electrical Engineering", label: "BEng in Electronic and Electrical Engineering" },
	{ value: "MSci in Environmental Sciences", label: "MSci in Environmental Sciences" },
	{ value: "MSc in Anthropology of International Development and Humanitarian Assistance", label: "MSc in Anthropology of International Development and Humanitarian Assistance" },
	{ value: "MSc in Banking and Finance (2 years full-time with placement)", label: "MSc in Banking and Finance (2 years full-time with placement)" },
	{ value: "MSc in Disease Mechanisms and Therapeutics", label: "MSc in Disease Mechanisms and Therapeutics" },
	{ value: "MSc in Oil and Gas Engineering", label: "MSc in Oil and Gas Engineering" },
	{ value: "BA in Games Design and Creative Writing", label: "BA in Games Design and Creative Writing" },
	{ value: "BSc in International Politics (4 years full-time with placement)", label: "BSc in International Politics (4 years full-time with placement)" },
	{ value: "MSc in Human Resource Management (16 months with placement)", label: "MSc in Human Resource Management (16 months with placement)" },
	{ value: "MSc in Building Services Engineering with Sustainable Energy", label: "MSc in Building Services Engineering with Sustainable Energy" },
	{ value: "MSc in Finance and Accounting", label: "MSc in Finance and Accounting" },
	{ value: "MA in International Journalism", label: "MA in International Journalism" },
	{ value: "MRes in Social Anthropology", label: "MRes in Social Anthropology" },
	{ value: "MSc in Structural Integrity (Asset Reliability Management)", label: "MSc in Structural Integrity (Asset Reliability Management)" },
	{ value: "MEng in Automotive Engineering", label: "MEng in Automotive Engineering" },
	{ value: "BSc in Biomedical Sciences (Biochemistry)", label: "BSc in Biomedical Sciences (Biochemistry)" },
	{ value: "BSc in International Politics", label: "BSc in International Politics" },
	{ value: "BSc in Physiotherapy (full-time programme)", label: "BSc in Physiotherapy (full-time programme)" },
	{ value: "BSc in Psychology (Sport, Health and Exercise)", label: "BSc in Psychology (Sport, Health and Exercise)" },
	{ value: "BSc in Psychology (Sport, Health and Exercise) (4 years full-time with placement)", label: "BSc in Psychology (Sport, Health and Exercise) (4 years full-time with placement)" },
	{ value: "BSc in Psychology (4 years full-time with placement)", label: "BSc in Psychology (4 years full-time with placement)" },
	{ value: "BEng in Electronic and Electrical Engineering (Computer Systems)", label: "BEng in Electronic and Electrical Engineering (Computer Systems)" },
	{ value: "BA in Modern History", label: "BA in Modern History" },
	{ value: "BSc in Design", label: "BSc in Design" },
	{ value: "Master of Laws (LLM Law) with Placement", label: "Master of Laws (LLM Law) with Placement" },
	{ value: "MSc in Financial Mathematics", label: "MSc in Financial Mathematics" },
	{ value: "MA in Intelligence and Security Studies", label: "MA in Intelligence and Security Studies" },
	{ value: "MSc in Psychological and Psychiatric Anthropology", label: "MSc in Psychological and Psychiatric Anthropology" },
	{ value: "MSc in Sport, Health and Exercise Sciences", label: "MSc in Sport, Health and Exercise Sciences" },
	{ value: "MSc in Sustainability, Entrepreneurship and Design (21 months full-time with placement)", label: "MSc in Sustainability, Entrepreneurship and Design (21 months full-time with placement)" },
	{ value: "PGCE in Secondary Education (English) with Recommendation for QTS", label: "PGCE in Secondary Education (English) with Recommendation for QTS" },
	{ value: "BSc in Accountancy (4 years full-time with placement)", label: "BSc in Accountancy (4 years full-time with placement)" },
	{ value: "BSc in Anthropology and Sociology", label: "BSc in Anthropology and Sociology" },
	{ value: "MEng in Automotive Engineering (5 years full-time with placement)", label: "MEng in Automotive Engineering (5 years full-time with placement)" },
	{ value: "BSc in Computer Science (Network Computing)", label: "BSc in Computer Science (Network Computing)" },
	{ value: "BEng in Electronic and Electrical Engineering (4 years full-time with placement)", label: "BEng in Electronic and Electrical Engineering (4 years full-time with placement)" },
	{ value: "LLB in Law (Graduate Entry) (3 years full-time with placement)", label: "LLB in Law (Graduate Entry) (3 years full-time with placement)" },
	{ value: "BSc in Sport, Health and Exercise Sciences with Business Studies", label: "BSc in Sport, Health and Exercise Sciences with Business Studies" },
	{ value: "BA in Modern History (4 years full-time with placement)", label: "BA in Modern History (4 years full-time with placement)" },
	{ value: "MSc Electric Vehicle Systems", label: "MSc Electric Vehicle Systems" },
	{ value: "MSc Accounting and Business Intelligence with Placement", label: "MSc Accounting and Business Intelligence with Placement" },
	{ value: "MSc in Banking and Finance (16 months full-time with placement)", label: "MSc in Banking and Finance (16 months full-time with placement)" },
	{ value: "MSc in Finance and Investment (16 months)", label: "MSc in Finance and Investment (16 months)" },
	{ value: "MSc in Environmental Management", label: "MSc in Environmental Management" },
	{ value: "MSc Environmental Management (1.5 years full-time with Placement)", label: "MSc Environmental Management (1.5 years full-time with Placement)" },
	{ value: "MSc in Physiotherapy (Pre-Registration)", label: "MSc in Physiotherapy (Pre-Registration)" },
	{ value: "BEng in Chemical Engineering", label: "BEng in Chemical Engineering" },
	{ value: "BSc in Communication and Media Studies", label: "BSc in Communication and Media Studies" },
	{ value: "BSc in Economics and Business Finance (4 years full-time with placement)", label: "BSc in Economics and Business Finance (4 years full-time with placement)" },
	{ value: "BSc in Economics and Management (4 years full-time with placement)", label: "BSc in Economics and Management (4 years full-time with placement)" },
	{ value: "MEng in Electronic and Electrical Engineering (5 years full-time with placement)", label: "MEng in Electronic and Electrical Engineering (5 years full-time with placement)" },
	{ value: "BA in English (4 years full-time with placement)", label: "BA in English (4 years full-time with placement)" },
	{ value: "MSci in Environmental Sciences (4 years full-time with placement)", label: "MSci in Environmental Sciences (4 years full-time with placement)" },
	{ value: "BASc in Global Challenges (Global Innovation) (4 years full-time with placement)", label: "BASc in Global Challenges (Global Innovation) (4 years full-time with placement)" },
	{ value: "BSc in Environmental Sciences -4 years full-time with placement", label: "BSc in Environmental Sciences -4 years full-time with placement" },
	{ value: "BSc in Financial Mathematics (4 years full-time with placement)", label: "BSc in Financial Mathematics (4 years full-time with placement)" },
	{ value: "BSc in Mathematics (4 years full-time with placement)", label: "BSc in Mathematics (4 years full-time with placement)" },
	{ value: "BEng in Mechanical Engineering", label: "BEng in Mechanical Engineering" },
	{ value: "BA in Military and International History", label: "BA in Military and International History" },
	{ value: "MSc in Advanced Manufacturing Systems", label: "MSc in Advanced Manufacturing Systems" },
	{ value: "BSc in Communication and Media Studies (4 years full-time with placement)", label: "BSc in Communication and Media Studies (4 years full-time with placement)" },
	{ value: "Engineering with an Integrated Foundation Year", label: "Engineering with an Integrated Foundation Year" },
	{ value: "BSc in Finance and Accounting", label: "BSc in Finance and Accounting" },
	{ value: "BASc in Global Challenges (Security) (4 years full-time with placement)", label: "BASc in Global Challenges (Security) (4 years full-time with placement)" },
	{ value: "BASc in Global Challenges (Social Cohesion) (4 years full-time with placement)", label: "BASc in Global Challenges (Social Cohesion) (4 years full-time with placement)" },
	{ value: "Law LLB (4 years full-time with placement)", label: "Law LLB (4 years full-time with placement)" },
	{ value: "MEng Civil Engineering (Environmental Engineering)", label: "MEng Civil Engineering (Environmental Engineering)" },
	{ value: "MSc in Human Resource Management", label: "MSc in Human Resource Management" },
	{ value: "MSc in Marketing (Brunel Business School)", label: "MSc in Marketing (Brunel Business School)" },
	{ value: "LLB in Law (Graduate Entry)", label: "LLB in Law (Graduate Entry)" },
	{ value: "BSc in Mathematics with Computer Science", label: "BSc in Mathematics with Computer Science" },
	{ value: "BA in Theatre (4 years full-time with placement)", label: "BA in Theatre (4 years full-time with placement)" },
	{ value: "MDes in Design", label: "MDes in Design" },
	{ value: "MSc International Business with Placement (16 months)", label: "MSc International Business with Placement (16 months)" },
	{ value: "International Commercial Law LLM (24 months with Placement)", label: "International Commercial Law LLM (24 months with Placement)" },
	{ value: "MSc in Corporate Brand Management (2 years full-time with placement)", label: "MSc in Corporate Brand Management (2 years full-time with placement)" },
	{ value: "MSc in Global Supply Chain Management (Brunel Business School)", label: "MSc in Global Supply Chain Management (Brunel Business School)" },
	{ value: "Presessional English Course (10 weeks)", label: "Presessional English Course (10 weeks)" },
	{ value: "MEng in Chemical Engineering (5 years full-time with placement)", label: "MEng in Chemical Engineering (5 years full-time with placement)" },
	{ value: "BA in English", label: "BA in English" },
	{ value: "BEng in Electronic and Electrical Engineering (Artificial Intelligence)", label: "BEng in Electronic and Electrical Engineering (Artificial Intelligence)" },
	{ value: "BA Journalism (Politics)", label: "BA Journalism (Politics)" },
	{ value: "BSc Marketing Management with Placement", label: "BSc Marketing Management with Placement" },
	{ value: "MSc in Accounting and Business Management (16 months full-time with placement)", label: "MSc in Accounting and Business Management (16 months full-time with placement)" },
	{ value: "MSc Advanced Clinical Practice (Occupational Therapy)", label: "MSc Advanced Clinical Practice (Occupational Therapy)" },
	{ value: "MSc in Banking and Finance", label: "MSc in Banking and Finance" },
	{ value: "MA in Education", label: "MA in Education" },
	{ value: "MSc in Flood and Coastal Engineering", label: "MSc in Flood and Coastal Engineering" },
	{ value: "MA in Law, Policy and Practice", label: "MA in Law, Policy and Practice" },
	{ value: "MSc in Sustainable Electrical Power", label: "MSc in Sustainable Electrical Power" },
	{ value: "Presessional English Course (7 weeks)", label: "Presessional English Course (7 weeks)" },
	{ value: "BSc in Economics", label: "BSc in Economics" },
	{ value: "Engineering with an Integrated Foundation Year (5 years with Placement)", label: "Engineering with an Integrated Foundation Year (5 years with Placement)" },
	{ value: "BASc in Global Challenges (Planetary Health)", label: "BASc in Global Challenges (Planetary Health)" },
	{ value: "BSc in Politics and Sociology", label: "BSc in Politics and Sociology" },
	{ value: "BA in Theatre and English (4 years full-time with placement)", label: "BA in Theatre and English (4 years full-time with placement)" },
	{ value: "MSc Digital Design (Motion Graphics)", label: "MSc Digital Design (Motion Graphics)" },
	{ value: "PGCE in Secondary Education (Mathematics) with recommendation for QTS", label: "PGCE in Secondary Education (Mathematics) with recommendation for QTS" },
	{ value: "MSc in Sport and Exercise Psychology", label: "MSc in Sport and Exercise Psychology" },
	{ value: "PhD Computer Science", label: "PhD Computer Science" },
	{ value: "BSc in Business Computing (Social Media) (4 years full-time with placement)", label: "BSc in Business Computing (Social Media) (4 years full-time with placement)" },
	{ value: "BSc in Computer Science (Software Engineering) (4 years full-time with placement)", label: "BSc in Computer Science (Software Engineering) (4 years full-time with placement)" },
	{ value: "MEng in Aerospace Engineering (5 years full-time with placement)", label: "MEng in Aerospace Engineering (5 years full-time with placement)" },
	{ value: "BSc in Computer Science (Artificial Intelligence)", label: "BSc in Computer Science (Artificial Intelligence)" },
	{ value: "BSc in Environmental Sciences-3 years", label: "BSc in Environmental Sciences-3 years" },
	{ value: "BA in Film Production (4 years full-time with placement)", label: "BA in Film Production (4 years full-time with placement)" },
	{ value: "BSc in Biomedical Sciences (Immunology) (4 years full-time with placement)", label: "BSc in Biomedical Sciences (Immunology) (4 years full-time with placement)" },
	{ value: "BASc in Global Challenges (Global Innovation)", label: "BASc in Global Challenges (Global Innovation)" },
	{ value: "MMath in Mathematics", label: "MMath in Mathematics" },
	{ value: "BSc Marketing Management", label: "BSc Marketing Management" },
	{ value: "MSc in Human Resource Management (2 years with placement)", label: "MSc in Human Resource Management (2 years with placement)" },
	{ value: "MSc Artificial Intelligence Strategy", label: "MSc Artificial Intelligence Strategy" },
	{ value: "MSc Advanced Clinical Practice (Musculoskeletal Rehabilitation)", label: "MSc Advanced Clinical Practice (Musculoskeletal Rehabilitation)" },
	{ value: "MSc in Advanced Mechanical Engineering", label: "MSc in Advanced Mechanical Engineering" },
	{ value: "MA in Children, Youth and International Development", label: "MA in Children, Youth and International Development" },
	{ value: "MSc in Data Science and Analytics", label: "MSc in Data Science and Analytics" },
	{ value: "MSc in Statistics with Data Analytics", label: "MSc in Statistics with Data Analytics" },
	{ value: "MEng in Aerospace Engineering", label: "MEng in Aerospace Engineering" },
	{ value: "BSc in Anthropology (4 years full-time with placement)", label: "BSc in Anthropology (4 years full-time with placement)" },
	{ value: "BEng in Automotive Engineering (4 years full-time with placement)", label: "BEng in Automotive Engineering (4 years full-time with placement)" },
	{ value: "BSc in Biomedical Sciences (Genetics)", label: "BSc in Biomedical Sciences (Genetics)" },
	{ value: "BSc in Business Computing (Human-Computer Interaction)", label: "BSc in Business Computing (Human-Computer Interaction)" },
	{ value: "BSc in Economics (4 years full-time with placement)", label: "BSc in Economics (4 years full-time with placement)" },
	{ value: "BSc in Politics", label: "BSc in Politics" },
	{ value: "BSc in Sociology (4 years full-time with placement)", label: "BSc in Sociology (4 years full-time with placement)" },
	{ value: "BSc Sport, Health and Exercise Sciences (Physical Education, Coaching and Social Issues)", label: "BSc Sport, Health and Exercise Sciences (Physical Education, Coaching and Social Issues)" },
	{ value: "MSc in Accounting and Business Management (2 years full-time with placement)", label: "MSc in Accounting and Business Management (2 years full-time with placement)" },
	{ value: "MSc Civil Engineering", label: "MSc Civil Engineering" },
	{ value: "MSc Artificial Intelligence", label: "MSc Artificial Intelligence" },
	{ value: "MA in Law, Policy and Practice (24 months full-time)", label: "MA in Law, Policy and Practice (24 months full-time)" },
	{ value: "MSc in Anthropology of Childhood, Youth and Education", label: "MSc in Anthropology of Childhood, Youth and Education" },
	{ value: "MSc in Occupational Therapy (Pre-Registration)", label: "MSc in Occupational Therapy (Pre-Registration)" },
	{ value: "MSc in Integrated Product Design", label: "MSc in Integrated Product Design" },
	{ value: "MSc in Marketing (Brunel Business School)(2 years full-time with placement)", label: "MSc in Marketing (Brunel Business School)(2 years full-time with placement)" },
	{ value: "MSc in Physician Associate", label: "MSc in Physician Associate" },
	{ value: "MSc in Sustainability, Entrepreneurship and Design", label: "MSc in Sustainability, Entrepreneurship and Design" },
	{ value: "PhD Sociology and Communication", label: "PhD Sociology and Communication" },
	{ value: "BEng in Aerospace Engineering", label: "BEng in Aerospace Engineering" },
	{ value: "BSc in Banking and Finance (4 years full-time with placement)", label: "BSc in Banking and Finance (4 years full-time with placement)" },
	{ value: "BSc in Biomedical Sciences (Human Health)", label: "BSc in Biomedical Sciences (Human Health)" },
	{ value: "BSc in Business and Management (Brunel Business School)", label: "BSc in Business and Management (Brunel Business School)" },
	{ value: "Mathematics and Computing with Integrated Foundation Year", label: "Mathematics and Computing with Integrated Foundation Year" },
	{ value: "BSc in Psychology", label: "BSc in Psychology" },
	{ value: "BA in Theatre", label: "BA in Theatre" },
	{ value: "MSc Renewable Energy Engineering", label: "MSc Renewable Energy Engineering" },
	{ value: "MA in International Relations", label: "MA in International Relations" },
	{ value: "BEng in Automotive Engineering", label: "BEng in Automotive Engineering" },
	{ value: "BSc in Business Computing (4 years full-time with placement)", label: "BSc in Business Computing (4 years full-time with placement)" },
	{ value: "MEng in Civil Engineering (5 years full-time with placement)", label: "MEng in Civil Engineering (5 years full-time with placement)" },
	{ value: "Economics and Mathematics with an Integrated Foundation Year", label: "Economics and Mathematics with an Integrated Foundation Year" },
	{ value: "LLB in Law with International Arbitration and Commercial Law (4 years full-time with placement)", label: "LLB in Law with International Arbitration and Commercial Law (4 years full-time with placement)" },
	{ value: "BEng in Electronic and Electrical Engineering (Communication Systems)", label: "BEng in Electronic and Electrical Engineering (Communication Systems)" },
	{ value: "BEng in Electronic and Electrical Engineering (Computer Systems) (4 years full-time with placement)", label: "BEng in Electronic and Electrical Engineering (Computer Systems) (4 years full-time with placement)" },
	{ value: "BA Industrial Design", label: "BA Industrial Design" },
	{ value: "MSc Project and Infrastructure Management", label: "MSc Project and Infrastructure Management" },
	{ value: "MSc Management (16 months with placement)", label: "MSc Management (16 months with placement)" },
	{ value: "MBA Business Administration (Brunel Business School)", label: "MBA Business Administration (Brunel Business School)" },
	{ value: "BSc in Anthropology and Sociology (4 years full-time with placement)", label: "BSc in Anthropology and Sociology (4 years full-time with placement)" },
	{ value: "BSc in Business Computing (Human-Computer Interaction) (4 years full-time with placement)", label: "BSc in Business Computing (Human-Computer Interaction) (4 years full-time with placement)" },
	{ value: "BEng in Chemical Engineering (4 years full-time with placement)", label: "BEng in Chemical Engineering (4 years full-time with placement)" },
	{ value: "BSc in Digital Design (4 years full-time with placement)", label: "BSc in Digital Design (4 years full-time with placement)" },
	{ value: "BSc in Sport, Health and Exercise Sciences (4 years full-time with placement)", label: "BSc in Sport, Health and Exercise Sciences (4 years full-time with placement)" },
	{ value: "BSc Sport, Health and Exercise Sciences (Physical Education, Coaching and Social Issues) (4 years full-time with Placement)", label: "BSc Sport, Health and Exercise Sciences (Physical Education, Coaching and Social Issues) (4 years full-time with Placement)" },
	{ value: "MBA General Management", label: "MBA General Management" },
	{ value: "MSc Business Finance (2 years full-time with placement)", label: "MSc Business Finance (2 years full-time with placement)" },
	{ value: "BSc in Biomedical Sciences (Genetics) (4 years full-time with placement)", label: "BSc in Biomedical Sciences (Genetics) (4 years full-time with placement)" },
	{ value: "BSc in Economics and Accounting (4 years full-time)", label: "BSc in Economics and Accounting (4 years full-time)" },
	{ value: "BA in Film Production", label: "BA in Film Production" },
	{ value: "BA in Games Design", label: "BA in Games Design" },
	{ value: "BASc in Global Challenges (Planetary Health) (4 years full-time with placement)", label: "BASc in Global Challenges (Planetary Health) (4 years full-time with placement)" },
	{ value: "BSc in Politics and History", label: "BSc in Politics and History" },
	{ value: "BSc in Politics and History (4 years full-time with placement)", label: "BSc in Politics and History (4 years full-time with placement)" },
	{ value: "BSc in Sport, Health and Exercise Sciences with Business Studies (4 years full-time with placement)", label: "BSc in Sport, Health and Exercise Sciences with Business Studies (4 years full-time with placement)" },
	{ value: "PgDip and MSc Specialist Community Public Health Nursing", label: "PgDip and MSc Specialist Community Public Health Nursing" },
	{ value: "MSc Wireless and Computer Communication Networks", label: "MSc Wireless and Computer Communication Networks" },
	{ value: "MSc Artificial Intelligence Strategy with Placement", label: "MSc Artificial Intelligence Strategy with Placement" },
	{ value: "LLM in International Human Rights Law", label: "LLM in International Human Rights Law" },
	{ value: "BSc in Accountancy", label: "BSc in Accountancy" },
	{ value: "BSc in Economics and Accounting", label: "BSc in Economics and Accounting" },
	{ value: "BA in English with Creative Writing (4 years full-time with placement)", label: "BA in English with Creative Writing (4 years full-time with placement)" },
	{ value: "MSci in Environmental Sciences (5 years full-time with placement)", label: "MSci in Environmental Sciences (5 years full-time with placement)" },
	{ value: "BSc in Mathematics and Statistics with Management", label: "BSc in Mathematics and Statistics with Management" },
	{ value: "BA in Military and International History (4 years full-time with placement)", label: "BA in Military and International History (4 years full-time with placement)" },
	{ value: "Presessional English Course (23 weeks)", label: "Presessional English Course (23 weeks)" },
	{ value: "BSc in Biomedical Sciences", label: "BSc in Biomedical Sciences" },
	{ value: "BA in Creative Writing", label: "BA in Creative Writing" },
	{ value: "BSc in International Business (Brunel Business School)", label: "BSc in International Business (Brunel Business School)" },
	{ value: "BSc in Life Sciences (4 years full-time with placement)", label: "BSc in Life Sciences (4 years full-time with placement)" },
	{ value: "MEng in Mechanical Engineering (5 years full-time with placement)", label: "MEng in Mechanical Engineering (5 years full-time with placement)" },
	{ value: "BA in Theatre and English", label: "BA in Theatre and English" },
	{ value: "MSc Digital Design (3D Animation) (with Placement)", label: "MSc Digital Design (3D Animation) (with Placement)" },
	{ value: "MSc Artificial Intelligence Strategy (16 Month)", label: "MSc Artificial Intelligence Strategy (16 Month)" },
	{ value: "MSc in Digital Service Design", label: "MSc in Digital Service Design" },
	{ value: "PhD Manufacturing and Enterprise Engineering", label: "PhD Manufacturing and Enterprise Engineering" },
	{ value: "BSc in Banking and Finance", label: "BSc in Banking and Finance" },
	{ value: "BEng in Civil Engineering", label: "BEng in Civil Engineering" },
	{ value: "BSc in Computer Science (4 years full-time with placement)", label: "BSc in Computer Science (4 years full-time with placement)" },
	{ value: "Economics and Mathematics with an Integrated Foundation Year (5 years full-time with placement)", label: "Economics and Mathematics with an Integrated Foundation Year (5 years full-time with placement)" },
	{ value: "BA in Education (4 years full-time with placement)", label: "BA in Education (4 years full-time with placement)" },
	{ value: "MDes Industrial Design", label: "MDes Industrial Design" },
	{ value: "MA in English Literature", label: "MA in English Literature" },
	{ value: "MEng in Civil Engineering", label: "MEng in Civil Engineering" },
	{ value: "BSc in Computer Science (Digital Media and Games) (4 years full-time with placement)", label: "BSc in Computer Science (Digital Media and Games) (4 years full-time with placement)" },
	{ value: "BA in Film and Television Studies (4 years full-time with placement)", label: "BA in Film and Television Studies (4 years full-time with placement)" },
	{ value: "BA in Film Production and Theatre (4 years full-time with placement)", label: "BA in Film Production and Theatre (4 years full-time with placement)" },
	{ value: "LLB in Law with International Arbitration and Commercial Law", label: "LLB in Law with International Arbitration and Commercial Law" },
	{ value: "BA in Music", label: "BA in Music" },
	{ value: "BSc in Sociology (Media) (4 years full-time with placement)", label: "BSc in Sociology (Media) (4 years full-time with placement)" },
	{ value: "MSc Psychology, Culture and Evolution", label: "MSc Psychology, Culture and Evolution" },
	{ value: "Presessional English Course (16 weeks)", label: "Presessional English Course (16 weeks)" },
	{ value: "BSc in Biomedical Sciences (Immunology)", label: "BSc in Biomedical Sciences (Immunology)" },
	{ value: "BA in Education", label: "BA in Education" },
	{ value: "BA in English with Creative Writing", label: "BA in English with Creative Writing" },
	{ value: "BSc in Environmental Sciences-3 years full-time with placement", label: "BSc in Environmental Sciences-3 years full-time with placement" },
	{ value: "BSc in Occupational Therapy", label: "BSc in Occupational Therapy" },
	{ value: "BEng in Electronic and Electrical Engineering (Energy Systems)", label: "BEng in Electronic and Electrical Engineering (Energy Systems)" },
	{ value: "BEng Civil Engineering (Environmental Engineering)", label: "BEng Civil Engineering (Environmental Engineering)" },
	{ value: "MSc in Finance and Accounting (16 months)", label: "MSc in Finance and Accounting (16 months)" },
	{ value: "MSc Management", label: "MSc Management" },
	{ value: "MSc in Business Finance", label: "MSc in Business Finance" },
	{ value: "LLM in International Commercial Law", label: "LLM in International Commercial Law" },
	{ value: "PhD Cognitive and Clinical Neuroscience", label: "PhD Cognitive and Clinical Neuroscience" },
	{ value: "BSc in Business Computing (Social Media)", label: "BSc in Business Computing (Social Media)" },
	{ value: "BSc in Business Computing", label: "BSc in Business Computing" },
	{ value: "BEng in Civil Engineering (4 years full-time with placement)", label: "BEng in Civil Engineering (4 years full-time with placement)" },
	{ value: "BSc in Computer Science (Network Computing) (4 years full-time with placement)", label: "BSc in Computer Science (Network Computing) (4 years full-time with placement)" },
	{ value: "BA in Film and Television Studies", label: "BA in Film and Television Studies" },
	{ value: "MMath in Financial Mathematics", label: "MMath in Financial Mathematics" },
	{ value: "MSc Advanced Clinical Practice (Cardiovascular Health)", label: "MSc Advanced Clinical Practice (Cardiovascular Health)" },
	{ value: "MSc in Political Communication", label: "MSc in Political Communication" },
	{ value: "MA in International Relations (15 months full-time with placement)", label: "MA in International Relations (15 months full-time with placement)" },
	{ value: "BSc in Anthropology", label: "BSc in Anthropology" },
	{ value: "BSc in Biomedical Sciences (Human Health) -4 years full-time with placement", label: "BSc in Biomedical Sciences (Human Health) -4 years full-time with placement" },
	{ value: "BSc in Business Computing (eBusiness)", label: "BSc in Business Computing (eBusiness)" },
	{ value: "BEng in Computer Systems Engineering (4 years full-time with placement)", label: "BEng in Computer Systems Engineering (4 years full-time with placement)" },
	{ value: "BSc in Sociology", label: "BSc in Sociology" },
	{ value: "BA in Theatre and Creative Writing", label: "BA in Theatre and Creative Writing" },
	{ value: "MSc Advanced Clinical Practice (Pelvic Health)", label: "MSc Advanced Clinical Practice (Pelvic Health)" },
	{ value: "MSc in Digital Design and Branding (2 years full-time with placement)", label: "MSc in Digital Design and Branding (2 years full-time with placement)" },
	{ value: "MSc Digital Design (Immersive Mixed Reality) (with Placement)", label: "MSc Digital Design (Immersive Mixed Reality) (with Placement)" },
	{ value: "MA in Media and Public Relations", label: "MA in Media and Public Relations" },
	{ value: "MSc Social Work", label: "MSc Social Work" },
	{ value: "Presessional English Course (12 weeks)", label: "Presessional English Course (12 weeks)" },
	{ value: "BSc in Biomedical Sciences (4 years full-time with placement)", label: "BSc in Biomedical Sciences (4 years full-time with placement)" },
	{ value: "BSc in Finance and Accounting (4 years full-time with placement)", label: "BSc in Finance and Accounting (4 years full-time with placement)" },
	{ value: "BASc in Global Challenges (Social Cohesion)", label: "BASc in Global Challenges (Social Cohesion)" },
	{ value: "BEng in Mechanical Engineering (4 years full-time with placement)", label: "BEng in Mechanical Engineering (4 years full-time with placement)" },
	{ value: "BSc in Politics (4 years full-time with placement)", label: "BSc in Politics (4 years full-time with placement)" },
	{ value: "MDes Industrial Design with Placement (5 years)", label: "MDes Industrial Design with Placement (5 years)" },
	{ value: "MDes in Design (5 years full-time with placement)", label: "MDes in Design (5 years full-time with placement)" },
	{ value: "MSc International Business (Brunel Business School) with Placement", label: "MSc International Business (Brunel Business School) with Placement" },
	{ value: "MSc in Corporate Brand Management", label: "MSc in Corporate Brand Management" },
	{ value: "MSc in Finance and Investment (2 years full-time with placement)", label: "MSc in Finance and Investment (2 years full-time with placement)" },
	{ value: "MSc in Public Health and Health Promotion", label: "MSc in Public Health and Health Promotion" },
	{ value: "BSc in Mathematics and Statistics with Management (4 years full-time with placement)", label: "BSc in Mathematics and Statistics with Management (4 years full-time with placement)" },
	{ value: "BSc in Mathematics with Computer Science (4 years full-time with placement)", label: "BSc in Mathematics with Computer Science (4 years full-time with placement)" },
	{ value: "MEng Civil Engineering (Environmental Engineering) (5 years full-time with placement)", label: "MEng Civil Engineering (Environmental Engineering) (5 years full-time with placement)" },
	{ value: "MSc Digital Design (Motion Graphics) (with Placement)", label: "MSc Digital Design (Motion Graphics) (with Placement)" },
	{ value: "MSc in Advanced Electronic and Electrical Engineering", label: "MSc in Advanced Electronic and Electrical Engineering" },
	{ value: "MSc in Aerospace Engineering", label: "MSc in Aerospace Engineering" },
	{ value: "MEng in Chemical Engineering", label: "MEng in Chemical Engineering" },
	{ value: "LLB in Law with Criminal Justice", label: "LLB in Law with Criminal Justice" },
	{ value: "BSc in Design (4 years full-time with placement)", label: "BSc in Design (4 years full-time with placement)" },
	{ value: "MSc in Automotive and Motorsport Engineering", label: "MSc in Automotive and Motorsport Engineering" },
	{ value: "BEng Civil Engineering (Environmental Engineering) (4 years full-time with placement)", label: "BEng Civil Engineering (Environmental Engineering) (4 years full-time with placement)" },
	{ value: "MSc in Accounting and Business Management (Brunel Business School)", label: "MSc in Accounting and Business Management (Brunel Business School)" },
	{ value: "MA in Creative Writing", label: "MA in Creative Writing" },
	{ value: "MA in Design and Branding Strategy", label: "MA in Design and Branding Strategy" },
	{ value: "MA in Design Strategy and Innovation", label: "MA in Design Strategy and Innovation" },
	{ value: "MSc in International Business (Brunel Business School)", label: "MSc in International Business (Brunel Business School)" },
	{ value: "Master of Laws (LLM Law)", label: "Master of Laws (LLM Law)" }
];

export default BrunelUniversityCourses;