import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const RouteChecker = () => {
	const location = useLocation();

	useEffect(() => {
		const appEl = document.querySelector(".App");
		if (location.pathname === '/login' || location.pathname === '/registration' || location.pathname === '/request-password-reset') {
			appEl.classList.add('special-background');
			appEl.style.backgroundImage = `url(${process.env.PUBLIC_URL}/login_bg.jpg)`;
		} else {
			appEl.classList.remove('special-background');
			appEl.style.backgroundImage = '';
		}
	}, [location]);

	return null;
};

export default RouteChecker;
