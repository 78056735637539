const OtagoCourses = [
	{ value: "Master of Science Communication (MSciComm) - Division of Sciences", label: "Master of Science Communication (MSciComm) - Division of Sciences" },
	{ value: "Postgraduate Diploma in Science - Physiology - Division of Sciences", label: "Postgraduate Diploma in Science - Physiology - Division of Sciences" },
	{ value: "Postgraduate Diploma in Science - Chemistry - Division of Sciences", label: "Postgraduate Diploma in Science - Chemistry - Division of Sciences" },
	{ value: "Postgraduate Diploma in Science - Telecommunications - Division of Sciences", label: "Postgraduate Diploma in Science - Telecommunications - Division of Sciences" },
	{ value: "Postgraduate Diploma in Science - Statistics - Division of Sciences", label: "Postgraduate Diploma in Science - Statistics - Division of Sciences" },
	{ value: "Postgraduate Diploma in Applied Science (PGDipAppSc) - Software Engineering - Division of Sciences", label: "Postgraduate Diploma in Applied Science (PGDipAppSc) - Software Engineering - Division of Sciences" },
	{ value: "Postgraduate Diploma in Applied Science (PGDipAppSc) - Artificial Intelligence - Division of Sciences", label: "Postgraduate Diploma in Applied Science (PGDipAppSc) - Artificial Intelligence - Division of Sciences" },
	{ value: "Postgraduate Diploma in Wildlife Management (PGDipWLM) - Division of Sciences", label: "Postgraduate Diploma in Wildlife Management (PGDipWLM) - Division of Sciences" },
	{ value: "Postgraduate Diploma in Applied Statistics (PGDipApStat) - Division of Sciences", label: "Postgraduate Diploma in Applied Statistics (PGDipApStat) - Division of Sciences" },
	{ value: "Master of Science (MSc) - Immunology - Division of Sciences", label: "Master of Science (MSc) - Immunology - Division of Sciences" },
	{ value: "Master of Applied Science (MAppSc) - Telecommunications - Division of Sciences", label: "Master of Applied Science (MAppSc) - Telecommunications - Division of Sciences" },
	{ value: "Master of Arts (Coursework) (MA(Coursework)) - Philosophy, Politics and Economics - Division of Humanities", label: "Master of Arts (Coursework) (MA(Coursework)) - Philosophy, Politics and Economics - Division of Humanities" },
	{ value: "Master of Surveying (MSurv) - Division of Sciences", label: "Master of Surveying (MSurv) - Division of Sciences" },
	{ value: "Postgraduate Diploma in Applied Science (PGDipAppSc) - Science and Natural History Filmmaking - Division of Sciences", label: "Postgraduate Diploma in Applied Science (PGDipAppSc) - Science and Natural History Filmmaking - Division of Sciences" },
	{ value: "Postgraduate Diploma in Science - Mathematics - Division of Sciences", label: "Postgraduate Diploma in Science - Mathematics - Division of Sciences" },
	{ value: "Postgraduate Diploma in Science - Economics - Division of Sciences", label: "Postgraduate Diploma in Science - Economics - Division of Sciences" },
	{ value: "Postgraduate Diploma in Science - Physics - Division of Sciences", label: "Postgraduate Diploma in Science - Physics - Division of Sciences" },
	{ value: "Postgraduate Diploma in Science - Software Engineering - Division of Sciences", label: "Postgraduate Diploma in Science - Software Engineering - Division of Sciences" },
	{ value: "Postgraduate Diploma in Science - Sport, Exercise and Health - Division of Sciences", label: "Postgraduate Diploma in Science - Sport, Exercise and Health - Division of Sciences" },
	{ value: "Postgraduate Diploma in Science - Surveying - Division of Sciences", label: "Postgraduate Diploma in Science - Surveying - Division of Sciences" },
	{ value: "Master of Science (MSc) - Economics - Division of Sciences", label: "Master of Science (MSc) - Economics - Division of Sciences" },
	{ value: "Master of Science (MSc) - Neuroscience - Division of Sciences", label: "Master of Science (MSc) - Neuroscience - Division of Sciences" },
	{ value: "Postgraduate Diploma in Applied Science (PGDipAppSc) - Molecular Biotechnology - Division of Sciences", label: "Postgraduate Diploma in Applied Science (PGDipAppSc) - Molecular Biotechnology - Division of Sciences" },
	{ value: "Postgraduate Diploma in Applied Science (PGDipAppSc) - Plant Biotechnology - Division of Sciences", label: "Postgraduate Diploma in Applied Science (PGDipAppSc) - Plant Biotechnology - Division of Sciences" },
	{ value: "Postgraduate Diploma in Applied Science (PGDipAppSc) - Quantitative Genetics - Division of Sciences", label: "Postgraduate Diploma in Applied Science (PGDipAppSc) - Quantitative Genetics - Division of Sciences" },
	{ value: "Postgraduate Diploma in Science - Marine Science - Division of Sciences", label: "Postgraduate Diploma in Science - Marine Science - Division of Sciences" },
	{ value: "Postgraduate Diploma in Science - Psychology - Division of Sciences", label: "Postgraduate Diploma in Science - Psychology - Division of Sciences" },
	{ value: "Master of Teaching and Learning (MTchgLn) - Division of Humanities", label: "Master of Teaching and Learning (MTchgLn) - Division of Humanities" },
	{ value: "Bachelor of Science (BSc) - Pharmacology - Division of Sciences", label: "Bachelor of Science (BSc) - Pharmacology - Division of Sciences" },
	{ value: "Bachelor of Science (BSc) - Physics - Division of Sciences", label: "Bachelor of Science (BSc) - Physics - Division of Sciences" },
	{ value: "Bachelor of Biomedical Sciences (BBiomedSc) - Drugs and Human Health - Division of Health Sciences", label: "Bachelor of Biomedical Sciences (BBiomedSc) - Drugs and Human Health - Division of Health Sciences" },
	{ value: "Bachelor of Science (BSc) - Human Nutrition - Division of Health Sciences", label: "Bachelor of Science (BSc) - Human Nutrition - Division of Health Sciences" }
];

export default OtagoCourses;