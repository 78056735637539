const LakelandCourses = [
	{ value: 'Early Childhood Education Certificate (Can transition to the Diploma program upon completion)', label: 'Early Childhood Education Certificate (Can transition to the Diploma program upon completion)' },
	{ value: 'Esthetician Certificate', label: 'Esthetician Certificate' },
	{ value: 'General Agriculture Certificate', label: 'General Agriculture Certificate' },
	{ value: 'Agribusiness Diploma', label: 'Agribusiness Diploma' },
	{ value: 'Business Administration Diploma: Small Business & Entrepreneurship Major', label: 'Business Administration Diploma: Small Business & Entrepreneurship Major' },
	{ value: 'Business Administration Certficate: Administrative Professional', label: 'Business Administration Certficate: Administrative Professional' },
	{ value: 'Business Administration Diploma: Real Estate Appraisal & Assessment Major', label: 'Business Administration Diploma: Real Estate Appraisal & Assessment Major' },
	{ value: 'Crop Technology Diploma', label: 'Crop Technology Diploma' },
	{ value: 'Interior Design Technology Diploma', label: 'Interior Design Technology Diploma' },
	{ value: 'ANIMAL SCIENCE TECHNOLOGY (2 Yr. Diploma): Dairy Science Major', label: 'ANIMAL SCIENCE TECHNOLOGY (2 Yr. Diploma): Dairy Science Major' },
	{ value: 'Early childhood education Diploma', label: 'Early childhood education Diploma' },
	{ value: 'Environmental Sciences Diploma: Environmental Conservation & Reclamation Major', label: 'Environmental Sciences Diploma: Environmental Conservation & Reclamation Major' },
	{ value: 'Power Engineering Technician', label: 'Power Engineering Technician' },
	{ value: 'Bachelor of Agriculture Technology', label: 'Bachelor of Agriculture Technology' },
	{ value: 'Business Administration Diploma: Accounting Major', label: 'Business Administration Diploma: Accounting Major' },
	{ value: 'Agriculture Sustainability Diploma', label: 'Agriculture Sustainability Diploma' },
	{ value: 'Health Care Aide', label: 'Health Care Aide' },
	{ value: 'Business Administration Diploma: General Major', label: 'Business Administration Diploma: General Major' },
	{ value: 'ANIMAL SCIENCE TECHNOLOGY (2 Yr. Diploma): Livestock Science Major', label: 'ANIMAL SCIENCE TECHNOLOGY (2 Yr. Diploma): Livestock Science Major' },
	{ value: 'Clinical Esthetician', label: 'Clinical Esthetician' },
	{ value: 'ANIMAL SCIENCE TECHNOLOGY (2 Yr. Diploma): Beef Science Major', label: 'ANIMAL SCIENCE TECHNOLOGY (2 Yr. Diploma): Beef Science Major' },
	{ value: 'Accounting Technician', label: 'Accounting Technician' },
	{ value: 'University Transfer', label: 'University Transfer' },
	{ value: 'Environmental Sciences Diploma: General Environmental Sciences', label: 'Environmental Sciences Diploma: General Environmental Sciences' },
	{ value: 'Business Administration Diploma: Marketing Major', label: 'Business Administration Diploma: Marketing Major' },
	{ value: 'Child & Youth Care Counsellor Diploma', label: 'Child & Youth Care Counsellor Diploma' },
	{ value: 'Business Administration Certificate', label: 'Business Administration Certificate' },
	{ value: 'Environmental Sciences Diploma: Land Stewardship & Conservation Environmental Sciences Diploma: Renewable Resource Reclamation Major', label: 'Environmental Sciences Diploma: Land Stewardship & Conservation Environmental Sciences Diploma: Renewable Resource Reclamation Major' },
	{ value: 'Environmental Sciences Certificate (Can transition to the Diploma program upon completion)', label: 'Environmental Sciences Certificate (Can transition to the Diploma program upon completion)' },
	{ value: 'Environmental Sciences Diploma: Renewable Resource Reclamation Major', label: 'Environmental Sciences Diploma: Renewable Resource Reclamation Major' },
	{ value: 'Post-bachelor Commercial Agriculture Production Certificate', label: 'Post-bachelor Commercial Agriculture Production Certificate' },
	{ value: 'Community Support Practitioner Diploma', label: 'Community Support Practitioner Diploma' }
];


export default LakelandCourses;