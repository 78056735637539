const FlindersCourses = [
	{ value: "Bachelor of Science (Animal Behaviour)", label: "Bachelor of Science (Animal Behaviour)" },
	{ value: "Bachelor of General Science/Master of Teaching (Primary R-7)", label: "Bachelor of General Science/Master of Teaching (Primary R-7)" },
	{ value: "Bachelor of Applied Geographical Information Systems", label: "Bachelor of Applied Geographical Information Systems" },
	{ value: "Bachelor of Arts", label: "Bachelor of Arts" },
	{ value: "Bachelor of Arts - Enhanced Program for High Achievers", label: "Bachelor of Arts - Enhanced Program for High Achievers" },
	{ value: "Bachelor of Creative Arts (Screen)", label: "Bachelor of Creative Arts (Screen)" },
	{ value: "Bachelor of Clinical Sciences, Doctor of Medicine", label: "Bachelor of Clinical Sciences, Doctor of Medicine" },
	{ value: "Bachelor of Languages/Master of Teaching (Secondary)", label: "Bachelor of Languages/Master of Teaching (Secondary)" },
	{ value: "Bachelor of Education (Secondary) / Bachelor of Health Sciences", label: "Bachelor of Education (Secondary) / Bachelor of Health Sciences" },
	{ value: "Bachelor of Information Technology (Honours)", label: "Bachelor of Information Technology (Honours)" },
	{ value: "Master of Education(Gifted Education) - Basic Level Entry", label: "Master of Education(Gifted Education) - Basic Level Entry" },
	{ value: "Diploma in Health", label: "Diploma in Health" },
	{ value: "Bachelor of Archaeology", label: "Bachelor of Archaeology" },
	{ value: "Master of Engineering Science (Biomedical)", label: "Master of Engineering Science (Biomedical)" },
	{ value: "Master of Engineering (Mechanical)", label: "Master of Engineering (Mechanical)" },
	{ value: "Bachelor of Science (Honours) (Marine Biology)", label: "Bachelor of Science (Honours) (Marine Biology)" },
	{ value: "Bachelor of Science (Honours) (Animal Behaviour)", label: "Bachelor of Science (Honours) (Animal Behaviour)" },
	{ value: "Bachelor of Science (Honours) (Coasts and Oceans)", label: "Bachelor of Science (Honours) (Coasts and Oceans)" },
	{ value: "Master of Accounting", label: "Master of Accounting" },
	{ value: "Master of Business (Marketing)", label: "Master of Business (Marketing)" },
	{ value: "Master of Geospatial Information Science", label: "Master of Geospatial Information Science" },
	{ value: "Bachelor of Science (Animal Behaviour)", label: "Bachelor of Science (Animal Behaviour)" },
	{ value: "Bachelor of General Science/Master of Teaching (Primary R-7)", label: "Bachelor of General Science/Master of Teaching (Primary R-7)" },
	{ value: "Bachelor of Applied Geographical Information Systems", label: "Bachelor of Applied Geographical Information Systems" },
	{ value: "Bachelor of Arts", label: "Bachelor of Arts" },
	{ value: "Bachelor of Arts - Enhanced Program for High Achievers", label: "Bachelor of Arts - Enhanced Program for High Achievers" },
	{ value: "Bachelor of Creative Arts (Screen)", label: "Bachelor of Creative Arts (Screen)" },
	{ value: "Bachelor of Clinical Sciences, Doctor of Medicine", label: "Bachelor of Clinical Sciences, Doctor of Medicine" },
	{ value: "Bachelor of Languages/Master of Teaching (Secondary)", label: "Bachelor of Languages/Master of Teaching (Secondary)" },
	{ value: "Bachelor of Education (Secondary) / Bachelor of Health Sciences", label: "Bachelor of Education (Secondary) / Bachelor of Health Sciences" },
	{ value: "Bachelor of Information Technology (Honours)", label: "Bachelor of Information Technology (Honours)" },
	{ value: "Master of Education(Gifted Education) - Basic Level Entry", label: "Master of Education(Gifted Education) - Basic Level Entry" },
	{ value: "Diploma in Health", label: "Diploma in Health" },
	{ value: "Bachelor of Archaeology", label: "Bachelor of Archaeology" },
	{ value: "Master of Engineering Science (Biomedical)", label: "Master of Engineering Science (Biomedical)" },
	{ value: "Master of Engineering (Mechanical)", label: "Master of Engineering (Mechanical)" },
	{ value: "Bachelor of Science (Honours) (Marine Biology)", label: "Bachelor of Science (Honours) (Marine Biology)" },
	{ value: "Bachelor of Science (Honours) (Animal Behaviour)", label: "Bachelor of Science (Honours) (Animal Behaviour)" },
	{ value: "Bachelor of Science (Honours) (Coasts and Oceans)", label: "Bachelor of Science (Honours) (Coasts and Oceans)" },
	{ value: "Master of Accounting", label: "Master of Accounting" },
	{ value: "Master of Business (Marketing)", label: "Master of Business (Marketing)" },
	{ value: "Master of Geospatial Information Science", label: "Master of Geospatial Information Science" },
	{ value: "Master of Occupational Therapy", label: "Master of Occupational Therapy" },
	{ value: "Master of Engineering Science (Software)", label: "Master of Engineering Science (Software)" },
	{ value: "Bachelor of Creative Arts (Creative Writing)", label: "Bachelor of Creative Arts (Creative Writing)" },
	{ value: "Bachelor of Education (Primary R - 7), Bachelor of Arts", label: "Bachelor of Education (Primary R - 7), Bachelor of Arts" },
	{ value: "Bachelor of Engineering (Maritime) (Honours)", label: "Bachelor of Engineering (Maritime) (Honours)" },
	{ value: "Bachelor of Business (International Business)", label: "Bachelor of Business (International Business)" },
	{ value: "Master of Disability Practice and Leadership (Developmental Education)", label: "Master of Disability Practice and Leadership (Developmental Education)" },
	{ value: "Master of Engineering Science (Civil)", label: "Master of Engineering Science (Civil)" },
	{ value: "Bachelor of Finance", label: "Bachelor of Finance" },
	{ value: "Bachelor of Health Sciences (Psychology)", label: "Bachelor of Health Sciences (Psychology)" },
	{ value: "Bachelor of Accounting and Finance", label: "Bachelor of Accounting and Finance" },
	{ value: "Bachelor of Information Technology (Data Analytics)", label: "Bachelor of Information Technology (Data Analytics)" },
	{ value: "Bachelor of Arts and Science", label: "Bachelor of Arts and Science" },
	{ value: "Bachelor of Arts/Master of Teaching (Early Childhood)", label: "Bachelor of Arts/Master of Teaching (Early Childhood)" },
	{ value: "Bachelor of Education (Secondary) / Bachelor of Science", label: "Bachelor of Education (Secondary) / Bachelor of Science" },
	{ value: "Bachelor of Business (Sport Management)", label: "Bachelor of Business (Sport Management)" },
	{ value: "Bachelor of Creative Industries (Interactive Design)", label: "Bachelor of Creative Industries (Interactive Design)" },
	{ value: "Bachelor of Information Technology (Games Development)", label: "Bachelor of Information Technology (Games Development)" },
	{ value: "Master of Engineering Science (Mechanical)", label: "Master of Engineering Science (Mechanical)" },
	{ value: "Master of Clinical Exercise Physiology", label: "Master of Clinical Exercise Physiology" },
	{ value: "Master of Business", label: "Master of Business" },
	{ value: "Master of Audiology", label: "Master of Audiology" },
	{ value: "Bachelor of Laws and Legal Practice (Graduate entry)", label: "Bachelor of Laws and Legal Practice (Graduate entry)" },
	{ value: "Bachelor of Psychological Science", label: "Bachelor of Psychological Science" },
	{ value: "Bachelor of Science (Animal Behaviour)", label: "Bachelor of Science (Animal Behaviour)" },
	{ value: "Bachelor of General Science/Master of Teaching (Primary R-7)", label: "Bachelor of General Science/Master of Teaching (Primary R-7)" },
	{ value: "Bachelor of Applied Geographical Information Systems", label: "Bachelor of Applied Geographical Information Systems" },
	{ value: "Bachelor of Arts", label: "Bachelor of Arts" },
	{ value: "Bachelor of Arts - Enhanced Program for High Achievers", label: "Bachelor of Arts - Enhanced Program for High Achievers" },
	{ value: "Bachelor of Creative Arts (Screen)", label: "Bachelor of Creative Arts (Screen)" },
	{ value: "Bachelor of Clinical Sciences, Doctor of Medicine", label: "Bachelor of Clinical Sciences, Doctor of Medicine" },
	{ value: "Bachelor of Languages/Master of Teaching (Secondary)", label: "Bachelor of Languages/Master of Teaching (Secondary)" },
	{ value: "Bachelor of Education (Secondary) / Bachelor of Health Sciences", label: "Bachelor of Education (Secondary) / Bachelor of Health Sciences" },
	{ value: "Bachelor of Information Technology (Honours)", label: "Bachelor of Information Technology (Honours)" },
	{ value: "Master of Education(Gifted Education) - Basic Level Entry", label: "Master of Education(Gifted Education) - Basic Level Entry" },
	{ value: "Diploma in Health", label: "Diploma in Health" },
	{ value: "Bachelor of Archaeology", label: "Bachelor of Archaeology" },
	{ value: "Master of Engineering Science (Biomedical)", label: "Master of Engineering Science (Biomedical)" },
	{ value: "Master of Engineering (Mechanical)", label: "Master of Engineering (Mechanical)" },
	{ value: "Bachelor of Science (Honours) (Marine Biology)", label: "Bachelor of Science (Honours) (Marine Biology)" },
	{ value: "Bachelor of Science (Honours) (Animal Behaviour)", label: "Bachelor of Science (Honours) (Animal Behaviour)" },
	{ value: "Bachelor of Science (Honours) (Coasts and Oceans)", label: "Bachelor of Science (Honours) (Coasts and Oceans)" },
	{ value: "Master of Accounting", label: "Master of Accounting" },
	{ value: "Master of Business (Marketing)", label: "Master of Business (Marketing)" },
	{ value: "Master of Geospatial Information Science", label: "Master of Geospatial Information Science" },
	{ value: "Master of Occupational Therapy", label: "Master of Occupational Therapy" },
	{ value: "Master of Engineering Science (Software)", label: "Master of Engineering Science (Software)" },
	{ value: "Bachelor of Creative Arts (Creative Writing)", label: "Bachelor of Creative Arts (Creative Writing)" },
	{ value: "Bachelor of Education (Primary R - 7), Bachelor of Arts", label: "Bachelor of Education (Primary R - 7), Bachelor of Arts" },
	{ value: "Bachelor of Engineering (Maritime) (Honours)", label: "Bachelor of Engineering (Maritime) (Honours)" },
	{ value: "Bachelor of Business (International Business)", label: "Bachelor of Business (International Business)" },
	{ value: "Master of Disability Practice and Leadership (Developmental Education)", label: "Master of Disability Practice and Leadership (Developmental Education)" },
	{ value: "Master of Engineering Science (Civil)", label: "Master of Engineering Science (Civil)" },
	{ value: "Bachelor of Finance", label: "Bachelor of Finance" },
	{ value: "Bachelor of Health Sciences (Psychology)", label: "Bachelor of Health Sciences (Psychology)" },
	{ value: "Bachelor of Accounting and Finance", label: "Bachelor of Accounting and Finance" },
	{ value: "Bachelor of Information Technology (Data Analytics)", label: "Bachelor of Information Technology (Data Analytics)" },
	{ value: "Bachelor of Arts and Science", label: "Bachelor of Arts and Science" },
	{ value: "Bachelor of Arts/Master of Teaching (Early Childhood)", label: "Bachelor of Arts/Master of Teaching (Early Childhood)" },
	{ value: "Bachelor of Education (Secondary) / Bachelor of Science", label: "Bachelor of Education (Secondary) / Bachelor of Science" },
	{ value: "Bachelor of Business (Sport Management)", label: "Bachelor of Business (Sport Management)" },
	{ value: "Bachelor of Creative Industries (Interactive Design)", label: "Bachelor of Creative Industries (Interactive Design)" },
	{ value: "Bachelor of Information Technology (Games Development)", label: "Bachelor of Information Technology (Games Development)" },
	{ value: "Master of Engineering Science (Mechanical)", label: "Master of Engineering Science (Mechanical)" },
	{ value: "Master of Clinical Exercise Physiology", label: "Master of Clinical Exercise Physiology" },
	{ value: "Master of Business", label: "Master of Business" },
	{ value: "Master of Audiology", label: "Master of Audiology" },
	{ value: "Bachelor of Laws and Legal Practice (Graduate entry)", label: "Bachelor of Laws and Legal Practice (Graduate entry)" },
	{ value: "Bachelor of Psychological Science", label: "Bachelor of Psychological Science" },
	{ value: "Bachelor of Information Technology (Digital Media) (Honours)", label: "Bachelor of Information Technology (Digital Media) (Honours)" },
	{ value: "Bachelor of Science (Biochemistry and Molecular Biology)", label: "Bachelor of Science (Biochemistry and Molecular Biology)" },
	{ value: "Bachelor of Science (Environmental Geology)", label: "Bachelor of Science (Environmental Geology)" },
	{ value: "Bachelor of Banking and Finance", label: "Bachelor of Banking and Finance" },
	{ value: "Master of Clinical Education", label: "Master of Clinical Education" },
	{ value: "Master of Biotechnology Studies", label: "Master of Biotechnology Studies" },
	{ value: "Master of Business (International Business)", label: "Master of Business (International Business)" },
	{ value: "Master of Paramedic Science", label: "Master of Paramedic Science" },
	{ value: "Bachelor of Engineering (Electrical and Electronic) (Honours)", label: "Bachelor of Engineering (Electrical and Electronic) (Honours)" },
	{ value: "Bachelor of Engineering (Honours) - Flexible Entry", label: "Bachelor of Engineering (Honours) - Flexible Entry" },
	{ value: "Bachelor of Engineering (Mechanical) (Honours)/ Master of Engineering Management", label: "Bachelor of Engineering (Mechanical) (Honours)/ Master of Engineering Management" },
	{ value: "Master of Education (Early Childhood Studies) - Basic Entry Level", label: "Master of Education (Early Childhood Studies) - Basic Entry Level" },
	{ value: "Doctor of Medicine", label: "Doctor of Medicine" },
	{ value: "Master of Environmental Health", label: "Master of Environmental Health" },
	{ value: "Master of Theological Studies", label: "Master of Theological Studies" },
	{ value: "Master of Science (Mathematics)", label: "Master of Science (Mathematics)" },
	{ value: "Bachelor of Law and Society", label: "Bachelor of Law and Society" },
	{ value: "Bachelor of Engineering (Mechanical) (Honours) / Master of Engineering (Biomedical)", label: "Bachelor of Engineering (Mechanical) (Honours) / Master of Engineering (Biomedical)" },
	{ value: "Bachelor of Criminology (Honours)", label: "Bachelor of Criminology (Honours)" },
	{ value: "Bachelor of Science (Marine Biology)", label: "Bachelor of Science (Marine Biology)" },
	{ value: "Bachelor of Finance (Advanced Leadership)", label: "Bachelor of Finance (Advanced Leadership)" },
	{ value: "Bachelor of Science (Honours) (Marine Biology and Aquaculture)", label: "Bachelor of Science (Honours) (Marine Biology and Aquaculture)" },
	{ value: "Bachelor of Creative Industries (Digital Media)", label: "Bachelor of Creative Industries (Digital Media)" },
	{ value: "Bachelor of Business (Marketing)", label: "Bachelor of Business (Marketing)" },
	{ value: "Bachelor of Creative Industries (Theatre and Performance)", label: "Bachelor of Creative Industries (Theatre and Performance)" },
	{ value: "Bachelor of Science (Honours) (Enhanced Program for High Achievers)", label: "Bachelor of Science (Honours) (Enhanced Program for High Achievers)" },
	{ value: "Bachelor of Business", label: "Bachelor of Business" },
	{ value: "Bachelor of Engineering (Biomedical) (Honours) / Master of Engineering (Biomedical)", label: "Bachelor of Engineering (Biomedical) (Honours) / Master of Engineering (Biomedical)" },
	{ value: "Bachelor of Science (Hydrology)", label: "Bachelor of Science (Hydrology)" },
	{ value: "Master of Science (Chemistry)", label: "Master of Science (Chemistry)" },
	{ value: "Bachelor of Information Technology (Game Development) (Honours)", label: "Bachelor of Information Technology (Game Development) (Honours)" },
	{ value: "Master of Accounting and Marketing", label: "Master of Accounting and Marketing" },
	{ value: "Bachelor of Mathematical Sciences (Honours)", label: "Bachelor of Mathematical Sciences (Honours)" },
	{ value: "Bachelor of Engineering Science", label: "Bachelor of Engineering Science" },
	{ value: "Bachelor of Human Nutrition", label: "Bachelor of Human Nutrition" },
	{ value: "Bachelor of Information Technology (Digital Health Systems) (Honours)", label: "Bachelor of Information Technology (Digital Health Systems) (Honours)" },
	{ value: "Bachelor of Science (Geography)", label: "Bachelor of Science (Geography)" },
	{ value: "Bachelor of Science (Honours) (Hydrology)", label: "Bachelor of Science (Honours) (Hydrology)" },
	{ value: "Bachelor of Creative Arts (Visual Effects and Entertainment Design) (Honours)", label: "Bachelor of Creative Arts (Visual Effects and Entertainment Design) (Honours)" },
	{ value: "Bachelor of Behavioural Science (Psychology)", label: "Bachelor of Behavioural Science (Psychology)" },
	{ value: "Bachelor of Engineering (Electronics) (Honours)", label: "Bachelor of Engineering (Electronics) (Honours)" },
	{ value: "Master of Social Work (Graduate Entry)", label: "Master of Social Work (Graduate Entry)" },
	{ value: "Bachelor of Nutrition and Dietetics", label: "Bachelor of Nutrition and Dietetics" },
	{ value: "Bachelor of Engineering (Robotics) (Honours)", label: "Bachelor of Engineering (Robotics) (Honours)" },
	{ value: "Bachelor of Information Technology (Digital Media)", label: "Bachelor of Information Technology (Digital Media)" },
	{ value: "Bachelor of Education (Early Childhood)/Bachelor of Special Education", label: "Bachelor of Education (Early Childhood)/Bachelor of Special Education" },
	{ value: "Bachelor of Engineering (Civil) (Honours)/ Master of Engineering Management", label: "Bachelor of Engineering (Civil) (Honours)/ Master of Engineering Management" },
	{ value: "Bachelor of Computer Science (Artificial Intelligence) (Honours)", label: "Bachelor of Computer Science (Artificial Intelligence) (Honours)" },
	{ value: "Master of Engineering Science (Civil)", label: "Master of Engineering Science (Civil)" },
	{ value: "Master of Business Administration Industry Focused", label: "Master of Business Administration Industry Focused" },
	{ value: "Master of Business Administration (Business Analytics)", label: "Master of Business Administration (Business Analytics)" },
	{ value: "Master of Virtual Production", label: "Master of Virtual Production" },
	{ value: "Bachelor of Laws and Legal Practice (Honours)", label: "Bachelor of Laws and Legal Practice (Honours)" },
	{ value: "Bachelor of Education (Secondary) / Bachelor of Arts", label: "Bachelor of Education (Secondary) / Bachelor of Arts" },
	{ value: "Bachelor of Engineering (Electrical) (Honours)", label: "Bachelor of Engineering (Electrical) (Honours)" },
	{ value: "Bachelor of Health Sciences", label: "Bachelor of Health Sciences" },
	{ value: "Bachelor of Science (Chemical Sciences)", label: "Bachelor of Science (Chemical Sciences)" },
	{ value: "Bachelor of Science (Forensic and Analytical Science)", label: "Bachelor of Science (Forensic and Analytical Science)" },
	{ value: "Bachelor of Science (Honours) (Molecular Bioscience)", label: "Bachelor of Science (Honours) (Molecular Bioscience)" },
	{ value: "Bachelor of Creative Industries (Festivals and Arts Production)", label: "Bachelor of Creative Industries (Festivals and Arts Production)" },
	{ value: "Bachelor of Creative Industries (Writing and Publishing)", label: "Bachelor of Creative Industries (Writing and Publishing)" },
	{ value: "Bachelor of Engineering (Environmental) (Honours)", label: "Bachelor of Engineering (Environmental) (Honours)" },
	{ value: "Bachelor of Information Technology (Game Development)", label: "Bachelor of Information Technology (Game Development)" },
	{ value: "Master of Geospatial Information Systems", label: "Master of Geospatial Information Systems" },
	{ value: "Master of Clinical Education", label: "Master of Clinical Education" },
	{ value: "Master of Engineering (Materials)", label: "Master of Engineering (Materials)" },
	{ value: "Bachelor of Science (Honours) (Chemical Sciences)", label: "Bachelor of Science (Honours) (Chemical Sciences)" },
	{ value: "Bachelor of Education (Primary R-7)/Bachelor of Special Education", label: "Bachelor of Education (Primary R-7)/Bachelor of Special Education" },
	{ value: "Bachelor of Health Sciences / Master of Physiotherapy", label: "Bachelor of Health Sciences / Master of Physiotherapy" },
	{ value: "Bachelor of Education (Secondary Health and Physical Education)", label: "Bachelor of Education (Secondary Health and Physical Education)" },
	{ value: "Bachelor of Science (Statistics)", label: "Bachelor of Science (Statistics)" },
	{ value: "Master of Forensic Science (Biology)", label: "Master of Forensic Science (Biology)" },
	{ value: "Master of Media and Communication", label: "Master of Media and Communication" },
	{ value: "Master of Physiotherapy", label: "Master of Physiotherapy" },
	{ value: "Master of Public Administration (Policy)", label: "Master of Public Administration (Policy)" },
	{ value: "Bachelor of Science (Cognitive Science)", label: "Bachelor of Science (Cognitive Science)" },
	{ value: "Bachelor of Computer Science (Artificial Intelligence)", label: "Bachelor of Computer Science (Artificial Intelligence)" },
	{ value: "Master in Business Administration (Social Impact)", label: "Master in Business Administration (Social Impact)" },
	{ value: "Master of Clinical Rehabilitation", label: "Master of Clinical Rehabilitation" },
	{ value: "Master of Forensic Science", label: "Master of Forensic Science" },
	{ value: "Master of Information Technology (Network and Cybersecurity Systems)", label: "Master of Information Technology (Network and Cybersecurity Systems)" },
	{ value: "Master of Education - Basic Entry Level", label: "Master of Education - Basic Entry Level" },
	{ value: "Master of Science (Groundwater Hydrology)", label: "Master of Science (Groundwater Hydrology)" },
	{ value: "Bachelor of Science/Master of Teaching (Secondary)", label: "Bachelor of Science/Master of Teaching (Secondary)" },
	{ value: "Master of Forensic Science (Forensic and Analytical Chemistry)", label: "Master of Forensic Science (Forensic and Analytical Chemistry)" },
	{ value: "Master of Accounting and Finance", label: "Master of Accounting and Finance" },
	{ value: "Master of Arts (Women's and Gender Studies)", label: "Master of Arts (Women's and Gender Studies)" },
	{ value: "Master of Counselling (Behavioural Health)", label: "Master of Counselling (Behavioural Health)" },
	{ value: "Bachelor of Midwifery (Pre-Registration)", label: "Bachelor of Midwifery (Pre-Registration)" },
	{ value: "Bachelor of Paramedic Science", label: "Bachelor of Paramedic Science" },
	{ value: "Bachelor of Science (Nanotechnology)", label: "Bachelor of Science (Nanotechnology)" },
	{ value: "Bachelor of Creative Arts (Costume Design) (VET pathway)", label: "Bachelor of Creative Arts (Costume Design) (VET pathway)" },
	{ value: "Bachelor of Engineering (Environmental) (Honours), Master of Engineering (Civil)", label: "Bachelor of Engineering (Environmental) (Honours), Master of Engineering (Civil)" },
	{ value: "Master of Environmental Management and Sustainability", label: "Master of Environmental Management and Sustainability" },
	{ value: "Master of Teaching (Early Childhood)", label: "Master of Teaching (Early Childhood)" },
	{ value: "Master of Screen and Media Production (Basic level entry)", label: "Master of Screen and Media Production (Basic level entry)" },
	{ value: "Bachelor of Design and Technology Innovation", label: "Bachelor of Design and Technology Innovation" },
	{ value: "Bachelor of Psychology (Honours)", label: "Bachelor of Psychology (Honours)" },
	{ value: "Bachelor of Information Technology (Simulation and Serious Games) (Honours)", label: "Bachelor of Information Technology (Simulation and Serious Games) (Honours)" },
	{ value: "Bachelor of Science (Biotechnology)", label: "Bachelor of Science (Biotechnology)" },
	{ value: "Bachelor of Science (Animal Biology)", label: "Bachelor of Science (Animal Biology)" },
	{ value: "Bachelor of Laws and Legal Practice (Graduate entry) (Honours)", label: "Bachelor of Laws and Legal Practice (Graduate entry) (Honours)" },
	{ value: "Bachelor of Education (Secondary) / Bachelor of Special Education", label: "Bachelor of Education (Secondary) / Bachelor of Special Education" },
	{ value: "Bachelor of Science (Coasts and Oceans)", label: "Bachelor of Science (Coasts and Oceans)" },
	{ value: "Bachelor of Information Technology (Business and Information Systems)", label: "Bachelor of Information Technology (Business and Information Systems)" },
	{ value: "Master of Professional Psychology", label: "Master of Professional Psychology" },
	{ value: "Bachelor of Information Technology (Network and Cybersecurity Systems)", label: "Bachelor of Information Technology (Network and Cybersecurity Systems)" },
	{ value: "Bachelor of Laws and Legal Practice", label: "Bachelor of Laws and Legal Practice" },
	{ value: "Bachelor of Media Arts", label: "Bachelor of Media Arts" },
	{ value: "Bachelor of Engineering (Robotics) (Honours) / Master of Engineering (Electronics)", label: "Bachelor of Engineering (Robotics) (Honours) / Master of Engineering (Electronics)" },
	{ value: "Bachelor of Science (Life Sciences)", label: "Bachelor of Science (Life Sciences)" },
	{ value: "Bachelor of Education (Primary R-7) / Bachelor of General Science", label: "Bachelor of Education (Primary R-7) / Bachelor of General Science" },
	{ value: "Master of Engineering Science (Electrical and Electronic)", label: "Master of Engineering Science (Electrical and Electronic)" },
	{ value: "Master of Nursing (Primary Health Care)", label: "Master of Nursing (Primary Health Care)" },
	{ value: "Master of Engineering (Electrical and Electronic)", label: "Master of Engineering (Electrical and Electronic)" },
	{ value: "Master of Psychology (Clinical)", label: "Master of Psychology (Clinical)" },
	{ value: "Master of Education (Languages Education) - Basic Entry Level", label: "Master of Education (Languages Education) - Basic Entry Level" },
	{ value: "Master of Engineering (Electronics)", label: "Master of Engineering (Electronics)" },
	{ value: "Master of Public Administration (Management)", label: "Master of Public Administration (Management)" },
	{ value: "Bachelor of Education (Secondary)", label: "Bachelor of Education (Secondary)" },
	{ value: "Bachelor of Inclusive and Specialised Education (Primary)", label: "Bachelor of Inclusive and Specialised Education (Primary)" },
	{ value: "Bachelor of International Relations and Political Science", label: "Bachelor of International Relations and Political Science" },
	{ value: "Bachelor of Sport, Health and Physical Activity (Honours)", label: "Bachelor of Sport, Health and Physical Activity (Honours)" },
	{ value: "Master of Engineering Science (Materials)", label: "Master of Engineering Science (Materials)" },
	{ value: "Master of Computer Science (Artificial Intelligence)", label: "Master of Computer Science (Artificial Intelligence)" },
	{ value: "Master of Disability Practice and Leadership", label: "Master of Disability Practice and Leadership" },
	{ value: "Master of Engineering (Civil)", label: "Master of Engineering (Civil)" },
	{ value: "Master of Business Administration, Master of Health Administration", label: "Master of Business Administration, Master of Health Administration" },
	{ value: "Bachelor of Creative Arts (Drama)", label: "Bachelor of Creative Arts (Drama)" },
	{ value: "Bachelor of Science (Chemistry)", label: "Bachelor of Science (Chemistry)" },
	{ value: "Bachelor of Geospatial Information Systems", label: "Bachelor of Geospatial Information Systems" },
	{ value: "Bachelor of Languages", label: "Bachelor of Languages" },
	{ value: "Bachelor of Sport, Health and Physical Activity", label: "Bachelor of Sport, Health and Physical Activity" },
	{ value: "Bachelor of Science (Honours) (Biotechnology)", label: "Bachelor of Science (Honours) (Biotechnology)" },
	{ value: "Bachelor of Criminology", label: "Bachelor of Criminology" },
	{ value: "Bachelor of Finance and Business Economics", label: "Bachelor of Finance and Business Economics" },
	{ value: "Bachelor of Education (Primary)", label: "Bachelor of Education (Primary)" },
	{ value: "Bachelor of Engineering (Honours) - General Entry", label: "Bachelor of Engineering (Honours) - General Entry" },
	{ value: "Bachelor of Science (Computer Science)", label: "Bachelor of Science (Computer Science)" },
	{ value: "Bachelor of Creative Arts (Enterprise)", label: "Bachelor of Creative Arts (Enterprise)" },
	{ value: "Bachelor of Science (Environmental Science)", label: "Bachelor of Science (Environmental Science)" },
	{ value: "Bachelor of Business Economics", label: "Bachelor of Business Economics" },
	{ value: "Bachelor of Science (Molecular Bioscience)", label: "Bachelor of Science (Molecular Bioscience)" },
	{ value: "Bachelor of Mathematical Sciences", label: "Bachelor of Mathematical Sciences" },
	{ value: "Bachelor of Information Technology (Digital Forensics)", label: "Bachelor of Information Technology (Digital Forensics)" },
	{ value: "Bachelor of Science (Plant Biology)", label: "Bachelor of Science (Plant Biology)" },
	{ value: "Master of Screen and Media Production (Advanced level entry)", label: "Master of Screen and Media Production (Advanced level entry)" },
	{ value: "Master of Computer Science", label: "Master of Computer Science" },
	{ value: "Master of Business (Human Resource Management)", label: "Master of Business (Human Resource Management)" },
	{ value: "Master of Engineering Science (Electrical and Electronic)", label: "Master of Engineering Science (Electrical and Electronic)" },
	{ value: "Master of Engineering Science (Materials)", label: "Master of Engineering Science (Materials)" },
	{ value: "Master of Archaeology and Heritage Management (Basic level entry)", label: "Master of Archaeology and Heritage Management (Basic level entry)" },
	{ value: "Bachelor of Engineering (Civil) (Honours)", label: "Bachelor of Engineering (Civil) (Honours)" },
	{ value: "Master of Engineering Science (Software)", label: "Master of Engineering Science (Software)" },
	{ value: "Masters in Environmental Management and Sustainability", label: "Masters in Environmental Management and Sustainability" },
	{ value: "Master of Engineering (Biomedical)", label: "Master of Engineering (Biomedical)" },
	{ value: "Bachelor of Business (Human Resource Management)", label: "Bachelor of Business (Human Resource Management)" },
	{ value: "Bachelor of Arts (Professional Futures)", label: "Bachelor of Arts (Professional Futures)" },
	{ value: "Bachelor of Banking and Finance (Advanced Leadership)", label: "Bachelor of Banking and Finance (Advanced Leadership)" },
	{ value: "Master of Public Policy and Management", label: "Master of Public Policy and Management" },
	{ value: "Bachelor of Medical Science", label: "Bachelor of Medical Science" },
	{ value: "Bachelor of Education (Secondary) / Bachelor of Languages", label: "Bachelor of Education (Secondary) / Bachelor of Languages" },
	{ value: "Bachelor of Social Work", label: "Bachelor of Social Work" },
	{ value: "Bachelor of Science (Energy and Advanced Materials)", label: "Bachelor of Science (Energy and Advanced Materials)" },
	{ value: "Bachelor of Science (Honours) (Energy and Advanced Sciences)", label: "Bachelor of Science (Honours) (Energy and Advanced Sciences)" },
	{ value: "Bachelor of Science (Honours) (Biodiversity and Conservation)", label: "Bachelor of Science (Honours) (Biodiversity and Conservation)" },
	{ value: "Bachelor of Creative Arts (Honours)", label: "Bachelor of Creative Arts (Honours)" },
	{ value: "Bachelor of Information Technology (Digital Forensics) (Honours)", label: "Bachelor of Information Technology (Digital Forensics) (Honours)" },
	{ value: "Bachelor of Science (Mathematics)", label: "Bachelor of Science (Mathematics)" },
	{ value: "Master of Business Administration (Marketing)", label: "Master of Business Administration (Marketing)" },
	{ value: "Master of Science (Aquaculture)", label: "Master of Science (Aquaculture)" },
	{ value: "Master of Engineering Science (Biomedical)", label: "Master of Engineering Science (Biomedical)" },
	{ value: "Bachelor of Exercise Science", label: "Bachelor of Exercise Science" },
	{ value: "Bachelor of Information Technology", label: "Bachelor of Information Technology" },
	{ value: "Bachelor of Tourism and Events", label: "Bachelor of Tourism and Events" },
	{ value: "Bachelor of Finance and Business Economics (Advanced Leadership)", label: "Bachelor of Finance and Business Economics (Advanced Leadership)" },
	{ value: "Bachelor of Education (Honours)", label: "Bachelor of Education (Honours)" },
	{ value: "Bachelor of Science (Plant Science)", label: "Bachelor of Science (Plant Science)" },
	{ value: "Bachelor of Science (Palaeontology)", label: "Bachelor of Science (Palaeontology)" },
	{ value: "Bachelor of Arts/Master of Teaching (Primary R-7)", label: "Bachelor of Arts/Master of Teaching (Primary R-7)" },
	{ value: "Bachelor of Engineering (Electrical and Electronic) (Honours), Master of Engineering (Mechanical)", label: "Bachelor of Engineering (Electrical and Electronic) (Honours), Master of Engineering (Mechanical)" },
	{ value: "Bachelor of Science (Environmental Hydrology and Water Resources)", label: "Bachelor of Science (Environmental Hydrology and Water Resources)" },
	{ value: "Bachelor of Health Sciences / Master of Occupational Therapy", label: "Bachelor of Health Sciences / Master of Occupational Therapy" },
	{ value: "Bachelor of Accounting and Finance (Advanced Leadership)", label: "Bachelor of Accounting and Finance (Advanced Leadership)" },
	{ value: "Bachelor of Information Technology (Machine Learning)", label: "Bachelor of Information Technology (Machine Learning)" },
	{ value: "Master of Engineering Management", label: "Master of Engineering Management" },
	{ value: "Bachelor of Computer Science (Honours)", label: "Bachelor of Computer Science (Honours)" },
	{ value: "Bachelor of Information Technology (Network and Cybersecurity Systems) (Honours)", label: "Bachelor of Information Technology (Network and Cybersecurity Systems) (Honours)" },
	{ value: "Bachelor of Disability and Developmental Education", label: "Bachelor of Disability and Developmental Education" },
	{ value: "Bachelor of Education (Early Childhood)/Bachelor of Arts", label: "Bachelor of Education (Early Childhood)/Bachelor of Arts" },
	{ value: "Bachelor of Engineering (Biomedical) (Honours)", label: "Bachelor of Engineering (Biomedical) (Honours)" },
	{ value: "Bachelor of Speech Pathology", label: "Bachelor of Speech Pathology" },
	{ value: "Bachelor of Science (Marine Biology and Aquaculture)", label: "Bachelor of Science (Marine Biology and Aquaculture)" },
	{ value: "Bachelor of Science (Honours) (Physics)", label: "Bachelor of Science (Honours) (Physics)" },
	{ value: "Bachelor of Science", label: "Bachelor of Science" },
	{ value: "Bachelor of Engineering (Software) (Honours)", label: "Bachelor of Engineering (Software) (Honours)" },
	{ value: "Bachelor of Business Economics (Advanced Leadership)", label: "Bachelor of Business Economics (Advanced Leadership)" },
	{ value: "Bachelor of Media And Communication", label: "Bachelor of Media And Communication" },
	{ value: "Bachelor of Laws", label: "Bachelor of Laws" },
	{ value: "Bachelor of Engineering (Computer and Network Systems) (Honours)", label: "Bachelor of Engineering (Computer and Network Systems) (Honours)" },
	{ value: "Bachelor of Science (Honours) (Chemical Sciences) / Master of Engineering (Materials)", label: "Bachelor of Science (Honours) (Chemical Sciences) / Master of Engineering (Materials)" },
	{ value: "Bachelor of Engineering (Mechanical) (Honours)", label: "Bachelor of Engineering (Mechanical) (Honours)" },
	{ value: "Bachelor of Science (Honours) (Forensic and Analytical Science)", label: "Bachelor of Science (Honours) (Forensic and Analytical Science)" },
	{ value: "Bachelor of Business (Innovation and Enterprise)", label: "Bachelor of Business (Innovation and Enterprise)" },
	{ value: "Master of Arts (International Relations)", label: "Master of Arts (International Relations)" },
	{ value: "Master of Business Administration Future Business", label: "Master of Business Administration Future Business" },
	{ value: "Master of Science and Engineering (Biomedical)", label: "Master of Science and Engineering (Biomedical)" },
	{ value: "Bachelor of Arts/Master of Teaching (Secondary)", label: "Bachelor of Arts/Master of Teaching (Secondary)" },
	{ value: "Bachelor of Education (Primary R-7)/Bachelor of General Science", label: "Bachelor of Education (Primary R-7)/Bachelor of General Science" },
	{ value: "Master of Speech Pathology", label: "Master of Speech Pathology" },
	{ value: "Master of Teaching (Special Education) (Primary R-7 stream)", label: "Master of Teaching (Special Education) (Primary R-7 stream)" },
	{ value: "Master of Science (Computer Science)", label: "Master of Science (Computer Science)" },
	{ value: "Bachelor of Computer Science (Hons)/Master of Science (Mathematics)", label: "Bachelor of Computer Science (Hons)/Master of Science (Mathematics)" },
	{ value: "Bachelor of Creative Industries (Film and Television)", label: "Bachelor of Creative Industries (Film and Television)" },
	{ value: "Bachelor of Science (Honours) (Energy and Advanced Materials)", label: "Bachelor of Science (Honours) (Energy and Advanced Materials)" },
	{ value: "Bachelor of Engineering Technology (Advanced Manufacturing and Digital Design)", label: "Bachelor of Engineering Technology (Advanced Manufacturing and Digital Design)" },
	{ value: "Master of Business Administration (Human Resource Management)", label: "Master of Business Administration (Human Resource Management)" },
	{ value: "Master of Business Administration (Finance)", label: "Master of Business Administration (Finance)" },
	{ value: "Master of Cognitive Behaviour Therapy", label: "Master of Cognitive Behaviour Therapy" },
	{ value: "Master of Teaching (Primary R - 7)", label: "Master of Teaching (Primary R - 7)" },
	{ value: "Master of Teaching (Secondary)", label: "Master of Teaching (Secondary)" },
	{ value: "Master of Information Technology", label: "Master of Information Technology" },
	{ value: "Bachelor of Early Childhood Education (Birth - 8)", label: "Bachelor of Early Childhood Education (Birth - 8)" },
	{ value: "Bachelor of Science (Physics)", label: "Bachelor of Science (Physics)" },
	{ value: "Master of Nursing (Acute Care)", label: "Master of Nursing (Acute Care)" },
	{ value: "Master of Business Administration (International Business)", label: "Master of Business Administration (International Business)" },
	{ value: "Bachelor of Engineering (Electrical and Electronic) (Honours)/Master of Engineering Management", label: "Bachelor of Engineering (Electrical and Electronic) (Honours)/Master of Engineering Management" },
	{ value: "Bachelor of Accounting", label: "Bachelor of Accounting" },
	{ value: "Bachelor of Accounting (Advanced Leadership)", label: "Bachelor of Accounting (Advanced Leadership)" },
	{ value: "Master of Business Administration", label: "Master of Business Administration" },
	{ value: "Master of Business (Finance)", label: "Master of Business (Finance)" },
	{ value: "Master of Nanotechnology", label: "Master of Nanotechnology" },
	{ value: "Master of Public Administration", label: "Master of Public Administration" },
	{ value: "Bachelor of Commerce", label: "Bachelor of Commerce" },
	{ value: "Bachelor of Computer Science", label: "Bachelor of Computer Science" },
	{ value: "Bachelor of Science (Honours) (Geography)", label: "Bachelor of Science (Honours) (Geography)" },
	{ value: "Master of Education(Cognitive Psychology and Educational Practice) - Basic Level Entry", label: "Master of Education(Cognitive Psychology and Educational Practice) - Basic Level Entry" },
	{ value: "Master of International Development", label: "Master of International Development" },
	{ value: "Master of Science (Physics)", label: "Master of Science (Physics)" },
	{ value: "Master of Science (Water Resources Management)", label: "Master of Science (Water Resources Management)" },
	{ value: "Master of Environmental Management", label: "Master of Environmental Management" },
	{ value: "Bachelor of Science (Biodiversity and Conservation)", label: "Bachelor of Science (Biodiversity and Conservation)" },
	{ value: "Bachelor of Science (Honours) (Nanotechnology)", label: "Bachelor of Science (Honours) (Nanotechnology)" },
	{ value: "Bachelor of Business (Supply Chain Management)", label: "Bachelor of Business (Supply Chain Management)" },
	{ value: "Bachelor of Nursing (Pre-Registration)", label: "Bachelor of Nursing (Pre-Registration)" },
	{ value: "Bachelor of Creative Arts (Visual Effects and Entertainment Design )", label: "Bachelor of Creative Arts (Visual Effects and Entertainment Design )" },
	{ value: "Bachelor of Science (Ecology and Evolutionary Biology)", label: "Bachelor of Science (Ecology and Evolutionary Biology)" },
	{ value: "Master of Education(Leadership and Management) - Basic Level Entry", label: "Master of Education(Leadership and Management) - Basic Level Entry" },
	{ value: "Master of Nutrition and Dietetics", label: "Master of Nutrition and Dietetics" },
	{ value: "Master of Education(Special Education) - Basic Entry Level", label: "Master of Education(Special Education) - Basic Entry Level" },
	{ value: "Master of Maritime Archaeology", label: "Master of Maritime Archaeology" },
	{ value: "Master of Engineering Science (Mechanical)", label: "Master of Engineering Science (Mechanical)" },
	{ value: "Master of Teaching (TESOL)", label: "Master of Teaching (TESOL)" },
	{ value: "Master of Palliative Care", label: "Master of Palliative Care" },
	{ value: "Master of Business Administration (Healthcare Management)", label: "Master of Business Administration (Healthcare Management)" },
	{ value: "Master of Public Health", label: "Master of Public Health" },
	{ value: "Bachelor of Science (Aquatic Biology)", label: "Bachelor of Science (Aquatic Biology)" },
	{ value: "Bachelor of Science (Microbiology)", label: "Bachelor of Science (Microbiology)" },
	{ value: "Master of Data Science", label: "Master of Data Science" },
	{ value: "Master of International Relations", label: "Master of International Relations" },
	{ value: "Bachelor of Information Technology (Simulation and Serious Games)", label: "Bachelor of Information Technology (Simulation and Serious Games)" },
	{ value: "Bachelor of Science (Honours) (Environmental Science)", label: "Bachelor of Science (Honours) (Environmental Science)" },
	{ value: "Bachelor of Business (Management)", label: "Bachelor of Business (Management)" },
	{ value: "Bachelor of Business (Advanced Leadership)", label: "Bachelor of Business (Advanced Leadership)" },
	{ value: "Bachelor of Inclusive and Specialised Education (Early Childhood)", label: "Bachelor of Inclusive and Specialised Education (Early Childhood)" },
	{ value: "Bachelor of Science (Environmental Management)", label: "Bachelor of Science (Environmental Management)" },
	{ value: "Bachelor of Science (Ocean and Climate Sciences)", label: "Bachelor of Science (Ocean and Climate Sciences)" },
	{ value: "Bachelor of Science (Honours) (Palaeontology)", label: "Bachelor of Science (Honours) (Palaeontology)" }
];

export default FlindersCourses;
