import { jwtDecode } from "jwt-decode";

export function getUserDetailsFromToken() {
	const token = localStorage.getItem('token');
	if (token) {
		try {
			const decoded = jwtDecode(token);
			return {
				userId: decoded.userId,
				userName: decoded.userName,
				privilege: decoded.privilege,
				branchId: decoded.branchId,
				branchName: decoded.branchName
			};
		} catch (error) {
			console.error("Error decoding token: ", error);
			return null;
		}
	}
	return null;
}
