import React from 'react';
import { Routes, Route } from 'react-router-dom';
import CreateUser from '../../Forms/CreateUser';
import { Card } from '../../global/holder/Card';
import PasswordResetRequest from '../../passwords/PasswordResetRequest';
import { getUserDetailsFromToken } from '../../utils/getUserDetailsFromToken';
import AccountLayout from '../../layout/AccountLayout';
import { useBreakpointValue } from '@chakra-ui/react';

const AccountContent = () => {
	const userDetails = getUserDetailsFromToken();
	const { userName } = userDetails || {};
	const minWidth = useBreakpointValue({ base: '100%', md: '400px' });
	return (
		<Card className="minHeight200">
			<div style={{ minWidth: minWidth }}>
				<h1>Welcome, <span style={{ color: '#6e6e6e' }}>{userName || 'User'}</span></h1>
				<p><a href="/logout">Logout</a></p>

				<div className='mt-5'>
					<p>If you have any suggestions for improvement, please reach out to the developer.</p>
				</div>
			</div>
		</Card>
	)
}

const Account = () => {
	return (
		<Routes>
			{/* Define Route for each NavLink */}
			<Route path="/" element={
				<AccountLayout>
					<AccountContent />
				</AccountLayout>
			} />
			<Route path="/account/create-user" element={
				<AccountLayout>
					<CreateUser />
				</AccountLayout>
			} />
			<Route path="/account/change-password" element={
				<AccountLayout>
					<PasswordResetRequest />
				</AccountLayout>
			} />
		</Routes>
	);
}

export default Account;
