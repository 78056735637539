const SRHBerlinCourses = [
	{ label: "BA in International Business Administration (International Experience Track) - Berlin Campus", value: "BA in International Business Administration (International Experience Track) - Berlin Campus" },
	{ label: "BA in International Business Administration - Digital Marketing (International Experience Track) - Berlin Campus", value: "BA in International Business Administration - Digital Marketing (International Experience Track) - Berlin Campus" },
	{ label: "BA in Creative Industries Management (Berlin Campus)", value: "BA in Creative Industries Management (Berlin Campus)" },
	{ label: "BA in Illustration- (Berlin Campus)", value: "BA in Illustration- (Berlin Campus)" },
	{ label: "BA in Photography (Berlin Campus)", value: "BA in Photography (Berlin Campus)" },
	{ label: "BA in Global Hospitality Management (Dresden Campus)", value: "BA in Global Hospitality Management (Dresden Campus)" },
	{ label: "BA in Advertising & Brand Design - (Berlin Campus)", value: "BA in Advertising & Brand Design - (Berlin Campus)" },
	{ label: "BA in Audio Design (Berlin Campus)", value: "BA in Audio Design (Berlin Campus)" },
	{ label: "BA in Music Production (Berlin Campus)", value: "BA in Music Production (Berlin Campus)" },
	{ label: "Bachelor of Music(B.Mus) in Popular Music (Berlin Campus)", value: "Bachelor of Music(B.Mus) in Popular Music (Berlin Campus)" },
	{ label: "BA in International Business Administration (Fast Track)  - Berlin Campus", value: "BA in International Business Administration (Fast Track)  - Berlin Campus" },
	{ label: "BA in International Business Administration - Digital Marketing (Fast Track)- Berlin Campus", value: "BA in International Business Administration - Digital Marketing (Fast Track)- Berlin Campus" },
	{ label: "BSc in Computer Science (Fast Track)- Berlin Campus", value: "BSc in Computer Science (Fast Track)- Berlin Campus" },
	{ label: "BA in  Film & Motion Design (Berlin Campus)", value: "BA in  Film & Motion Design (Berlin Campus)" },
	{ label: "Bachelor of Science in Web Development (Berlin Campus)", value: "Bachelor of Science in Web Development (Berlin Campus)" },
	{ label: "BA in International Business Administration- Healthcare Management (Berlin Campus)", value: "BA in International Business Administration- Healthcare Management (Berlin Campus)" },
	{ label: "BA in User Experience Design and Content Creation (Berlin Campus)", value: "BA in User Experience Design and Content Creation (Berlin Campus)" },
	{ label: "BSc in Computer Science- Health Informatics - (Berlin Campus)", value: "BSc in Computer Science- Health Informatics - (Berlin Campus)" },
	{ label: "BA in International Business Administration - Business Psychology (International Experience Track) - Berlin Campus", value: "BA in International Business Administration - Business Psychology (International Experience Track) - Berlin Campus" },
	{ label: "BA in International Business Administration - Business Psychology (Fast Track) - Berlin Campus", value: "BA in International Business Administration - Business Psychology (Fast Track) - Berlin Campus" },
	{ label: "B.Eng in Applied Mechatronic Systems (Berlin Campus)", value: "B.Eng in Applied Mechatronic Systems (Berlin Campus)" },
	{ label: "BSc in Computer Science (International Experience Track) - Berlin Campus", value: "BSc in Computer Science (International Experience Track) - Berlin Campus" },
	{ label: "MBA in International Human Resource Management (Berlin Campus)", value: "MBA in International Human Resource Management (Berlin Campus)" },
	{ label: "M.Sc.Digital Transformation Management", value: "M.Sc.Digital Transformation Management" },
	{ label: "MEng Sustainable Battery Production Engineering  (Dual)  (Berlin Campus)", value: "MEng Sustainable Battery Production Engineering  (Dual)  (Berlin Campus)" },
	{ label: "MA in International Business and Leadership (Hamburg Campus) - 2 Year", value: "MA in International Business and Leadership (Hamburg Campus) - 2 Year" },
	{ label: "M.A International Management in  Creative Leadership (Berlin Campus)", value: "M.A International Management in  Creative Leadership (Berlin Campus)" },
	{ label: "(EMBA) Executive MBA General Management", value: "(EMBA) Executive MBA General Management" },
	{ label: "MA in International Business and Leadership- (Dresden Campus) -1 Year", value: "MA in International Business and Leadership- (Dresden Campus) -1 Year" },
	{ label: "MA in International Business and Leadership- (Dresden Campus) - 2 Years", value: "MA in International Business and Leadership- (Dresden Campus) - 2 Years" },
	{ label: "MA in International Business and Leadership-Trade Management (Hamburg Campus)", value: "MA in International Business and Leadership-Trade Management (Hamburg Campus)" },
	{ label: "M.Sc. In Computer Science- Focus on Big Data and Artificial Intelligence (Fast track)- Berlin Campus", value: "M.Sc. In Computer Science- Focus on Big Data and Artificial Intelligence (Fast track)- Berlin Campus" },
	{ label: "M.Sc. In Computer Science-Cyber Security(Fast Track) - Berlin Campus", value: "M.Sc. In Computer Science-Cyber Security(Fast Track) - Berlin Campus" },
	{ label: "MA in International Business - Sustainability Management (Hamburg Campus) - 1 Year", value: "MA in International Business - Sustainability Management (Hamburg Campus) - 1 Year" },
	{ label: "MA in International Business - Sustainability Management (Hamburg Campus) - 2 Years", value: "MA in International Business - Sustainability Management (Hamburg Campus) - 2 Years" },
	{ label: "Master in Hospitality Management and Leadership (Dresden Campus)- 1 Year", value: "Master in Hospitality Management and Leadership (Dresden Campus)- 1 Year" },
	{ label: "MA in International Business -Healthcare Management  (Dresden Campus) - 1 Year", value: "MA in International Business -Healthcare Management  (Dresden Campus) - 1 Year" },
	{ label: "MA in International Business - Healthcare Management (Dresden Campus) - 2 Years", value: "MA in International Business - Healthcare Management (Dresden Campus) - 2 Years" },
	{ label: "MSc in Supply Chain Management ( Hamburg Campus )", value: "MSc in Supply Chain Management ( Hamburg Campus )" },
	{ label: "Master of Arts in Strategic Design (Berlin Campus)", value: "Master of Arts in Strategic Design (Berlin Campus)" },
	{ label: "MA in Social Design & Sustainable Innovation (Berlin Campus)", value: "MA in Social Design & Sustainable Innovation (Berlin Campus)" },
	{ label: "MA in Film, Television and Digital Narratives (Berlin Campus)", value: "MA in Film, Television and Digital Narratives (Berlin Campus)" },
	{ label: "MA in Photography (Berlin Campus)", value: "MA in Photography (Berlin Campus)" },
	{ label: "M.Sc. In Computer Science- Big Data and Artificial Intelligence- (Berlin Campus)", value: "M.Sc. In Computer Science- Big Data and Artificial Intelligence- (Berlin Campus)" },
	{ label: "M.A in Entrepreneurship (Berlin Campus)", value: "M.A in Entrepreneurship (Berlin Campus)" },
	{ label: "M.A International Management (Berlin Campus)", value: "M.A International Management (Berlin Campus)" },
	{ label: "MA in Hospitality Management and Leadership (Dresden Campus) - 2 Years", value: "MA in Hospitality Management and Leadership (Dresden Campus) - 2 Years" },
	{ label: "MA in International Business and Leadership (Hamburg Campus) - 1 Year", value: "MA in International Business and Leadership (Hamburg Campus) - 1 Year" },
	{ label: "MBA General Management (Berlin Campus)", value: "MBA General Management (Berlin Campus)" },
	{ label: "MBA- Healthcare Management  (Berlin Campus)", value: "MBA- Healthcare Management  (Berlin Campus)" },
	{ label: "MEng & Sustainable Technology Management - Mobility & Automotive Industry (Berlin Campus)", value: "MEng & Sustainable Technology Management - Mobility & Automotive Industry (Berlin Campus)" },
	{ label: "M.Sc. In Computer Science-Cyber Security (Berlin Campus)", value: "M.Sc. In Computer Science-Cyber Security (Berlin Campus)" },
	{ label: "MSc in Supply Chain Management- (Berlin Campus)", value: "MSc in Supply Chain Management- (Berlin Campus)" },
	{ label: "M.Eng. Engineering in International Business - Renewable Energy, Water and Waste management (Berlin Campus)", value: "M.Eng. Engineering in International Business - Renewable Energy, Water and Waste management (Berlin Campus)" },
	{ label: "Master of Engineering  & Sustainable Technology Management (M.Eng.) -Industry 4.0: Automation, Robotics & 3D Manufacturing (Berlin Campus)", value: "Master of Engineering  & Sustainable Technology Management (M.Eng.) -Industry 4.0: Automation, Robotics & 3D Manufacturing (Berlin Campus)" },
	{ label: "Master of Engineering &  Sustainable Technology Management (M.Eng.) -Smart Building Technologies- (Berlin Campus)", value: "Master of Engineering &  Sustainable Technology Management (M.Eng.) -Smart Building Technologies- (Berlin Campus)" },
	{ label: "One Year Foundation Program (SRH International)", value: "One Year Foundation Program (SRH International)" }
];

export default SRHBerlinCourses;