const LeCordonBleuFranceCourses = [
	{ value: "Certification in Basic Cuisine (Standard)", label: "Certification in Basic Cuisine (Standard)" },
	{ value: "Certification in Basic Cuisine (Intensive)", label: "Certification in Basic Cuisine (Intensive)" },
	{ value: "Certification in Intermediate Cuisine (Standard)", label: "Certification in Intermediate Cuisine (Standard)" },
	{ value: "Certification in Intermediate Cuisine (Intensive)", label: "Certification in Intermediate Cuisine (Intensive)" },
	{ value: "Certification in Superior Cuisine (Standard)", label: "Certification in Superior Cuisine (Standard)" },
	{ value: "Certification in Basic Patisserie (Standard)", label: "Certification in Basic Patisserie (Standard)" },
	{ value: "Certification in Basic Patisserie (Intensive)", label: "Certification in Basic Patisserie (Intensive)" },
	{ value: "Certification in Intermediate Patisserie (Standard)", label: "Certification in Intermediate Patisserie (Standard)" },
	{ value: "Certification in Intermediate Patisserie (Intensive)", label: "Certification in Intermediate Patisserie (Intensive)" },
	{ value: "Certification in Superior Patisserie (Standard)", label: "Certification in Superior Patisserie (Standard)" },
	{ value: "Diploma in Cuisine (Standard)", label: "Diploma in Cuisine (Standard)" },
	{ value: "Diploma in Cuisine (Intensive)", label: "Diploma in Cuisine (Intensive)" },
	{ value: "Diploma in Patisserie (Standard)", label: "Diploma in Patisserie (Standard)" },
	{ value: "Diploma in Patisserie (Intensive)", label: "Diploma in Patisserie (Intensive)" },
	{ value: "Grand Diploma (Standard)", label: "Grand Diploma (Standard)" },
	{ value: "Wine and Management Programme (Standard)", label: "Wine and Management Programme (Standard)" },
	{ value: "Boulangerie Diploma (Standard)", label: "Boulangerie Diploma (Standard)" }
];

export default LeCordonBleuFranceCourses;