import React, { useState, useEffect } from 'react';
import { Button, Table, Form, FormGroup, Label, Input } from 'reactstrap';
import AccountLayout from '../layout/AccountLayout';
import {
	useToast, useDisclosure, AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	useBreakpointValue,
	Box
} from '@chakra-ui/react';

const CounselorsList = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const cancelRef = React.useRef();
	const [counselors, setCounselors] = useState([]);
	const [editingId, setEditingId] = useState(null); // Use null for initial state
	const [newCounselorName, setNewCounselorName] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [recordsPerPage] = useState(20);
	const [deleteCounselorId, setDeleteCounselorId] = useState(null);
	const [searchTerm, setSearchTerm] = useState('');

	const toast = useToast();

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URL}/api/counselors`)
			.then(res => res.json())
			.then(data => {
				const counselorsWithId = data.map(counselor => ({
					...counselor,
					id: counselor._id // Adjust according to the actual key in your data
				}));
				setCounselors(counselorsWithId);
			})
			.catch(error => console.error('Error fetching counselors:', error));
	}, []);

	console.log(counselors.map(counselor => counselor.id)); // Check for uniqueness

	const filteredCounselors = searchTerm.length === 0
		? counselors
		: counselors.filter(counselor =>
			counselor.name.toLowerCase().includes(searchTerm.toLowerCase())
		);

	const currentCounselors = filteredCounselors.slice(
		(currentPage - 1) * recordsPerPage,
		currentPage * recordsPerPage
	);

	const paginate = pageNumber => setCurrentPage(pageNumber);
	const pageNumbers = [];
	for (let i = 1; i <= Math.ceil(filteredCounselors.length / recordsPerPage); i++) {
		pageNumbers.push(i);
	}

	const deleteCounselor = async (id) => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/counselors/${id}`, {
				method: 'DELETE',
			});
			if (!response.ok) {
				throw new Error('Failed to delete the counselor.');
			}
			setCounselors(counselors.filter(counselor => counselor.id !== id));
			toast({
				title: 'Counselor deleted',
				description: 'The counselor has been successfully deleted.',
				status: 'success',
				duration: 5000,
				isClosable: true,
			});
		} catch (error) {
			console.error('Error:', error);
			toast({
				title: 'Error',
				description: 'There was an error deleting the counselor.',
				status: 'error',
				duration: 5000,
				isClosable: true,
			});
		}
	};

	const editCounselor = async (id) => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/counselors/${id}`, {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ name: newCounselorName }),
			});
			if (!response.ok) {
				throw new Error('Failed to update the counselor.');
			}
			setCounselors(counselors.map(counselor =>
				counselor.id === id ? { ...counselor, name: newCounselorName } : counselor
			));
			setEditingId('');
			setNewCounselorName('');
			toast({
				title: 'Counselor updated',
				description: 'The counselor has been successfully updated.',
				status: 'success',
				duration: 5000,
				isClosable: true,
			});
		} catch (error) {
			console.error('Error:', error);
			toast({
				title: 'Error',
				description: 'There was an error updating the counselor.',
				status: 'error',
				duration: 5000,
				isClosable: true,
			});
		}
	};

	const handleEditChange = (e) => {
		console.log("Editing:", editingId);
		setNewCounselorName(e.target.value);
	};

	const handleEditSubmit = (e, id) => {
		e.preventDefault();
		console.log("Submitting for ID:", id);
		editCounselor(id);
	};

	const handleSearchChange = (e) => setSearchTerm(e.target.value);

	const minWidth = useBreakpointValue({ base: '100%', md: '500px' });

	if (counselors.length === 0) {
		return <div>Loading counselors...</div>;
	}

	return (
		<>
			<AccountLayout>
				<Box my="4">
					<Input
						placeholder="Search counselors..."
						value={searchTerm}
						onChange={handleSearchChange}
					/>
				</Box>
				<Table className='table table-striped table-bordered' style={{ minWidth: minWidth }}>
					<thead>
						<tr>
							<th>Counselor Name</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{currentCounselors.map((counselor) => (
							<tr key={counselor.id}>
								{editingId === counselor.id ? (
									<td>
										<Form inline onSubmit={(e) => handleEditSubmit(e, counselor.id)}>
											<FormGroup>
												<Label hidden>Edit Counselor Name</Label>
												<Input
													type="text"
													value={newCounselorName}
													onChange={handleEditChange}
													required
												/>
											</FormGroup>
											<div style={{ display: 'flex', gap: '10px' }}>
												<Button type="submit" color="primary">Save</Button>
												<Button onClick={() => setEditingId(null)} color="secondary">Cancel</Button>
											</div>
										</Form>
									</td>
								) : (
									<td>{counselor.name}</td>
								)}
								<td style={{ display: 'flex', gap: '10px' }}>
									<Button onClick={() => {
										console.log("Setting editingId to:", counselor.id);
										setEditingId(counselor.id);
										setNewCounselorName(counselor.name);
									}} color="info">Edit</Button>
									<Button onClick={() => { setDeleteCounselorId(counselor.id); onOpen(); }} color="danger">Delete</Button>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
				{counselors.length > recordsPerPage && (
					<nav>
						<ul className='pagination'>
							{pageNumbers.map(number => (
								<li key={number} className='page-item'>
									<button onClick={() => paginate(number)} className='page-link'>
										{number}
									</button>
								</li>
							))}
						</ul>
					</nav>
				)}
			</AccountLayout>
			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Delete Counselor
						</AlertDialogHeader>

						<AlertDialogBody>
							Are you sure? You can't undo this action afterwards.
						</AlertDialogBody>

						<AlertDialogFooter style={{ display: 'flex', gap: '10px' }}>
							<Button ref={cancelRef} onClick={onClose}>
								Cancel
							</Button>
							<Button color="danger" onClick={() => {
								deleteCounselor(deleteCounselorId);
								onClose();
							}} ml={3}>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
};

export default CounselorsList;
