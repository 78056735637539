const ReginaCourses = [
	{ value: 'Bachelor of Science (Honours) in Chemistry', label: 'Bachelor of Science (Honours) in Chemistry' },
	{ value: 'Bachelor of Applied Science in Environmental Systems Engineering', label: 'Bachelor of Applied Science in Environmental Systems Engineering' },
	{ value: 'Diploma in Health Studies', label: 'Diploma in Health Studies' },
	{ value: 'Diploma in Health Studies - Kinesiology', label: 'Diploma in Health Studies - Kinesiology' },
	{ value: 'Master of Adult Education and Human Resource Development', label: 'Master of Adult Education and Human Resource Development' },
	{ value: 'MSc Computer Science - Data Science', label: 'MSc Computer Science - Data Science' },
	{ value: 'Diploma in Creative Technologies', label: 'Diploma in Creative Technologies' },
	{ value: 'Advanced Certificate in Public Relations and Communications Management', label: 'Advanced Certificate in Public Relations and Communications Management' },
	{ value: 'Bachelor of Applied Science in Electronic Systems Engineering', label: 'Bachelor of Applied Science in Electronic Systems Engineering' },
	{ value: 'Bachelor of Engineering - Software Systems', label: 'Bachelor of Engineering - Software Systems' },
	{ value: 'Bachelor of Arts Honours in Geography and Environmental Studies', label: 'Bachelor of Arts Honours in Geography and Environmental Studies' },
	{ value: 'Bachelor of Arts Honours in Indigenous Studies', label: 'Bachelor of Arts Honours in Indigenous Studies' },
	{ value: 'Bachelor of Arts Honours in Political Science', label: 'Bachelor of Arts Honours in Political Science' },
	{ value: 'Bachelor of Arts Honours in Psychology', label: 'Bachelor of Arts Honours in Psychology' },
	{ value: 'Bachelor of Arts Honours in Religious Studies', label: 'Bachelor of Arts Honours in Religious Studies' },
	{ value: 'Bachelor of Arts Honours in Women\'s and Gender Studies', label: 'Bachelor of Arts Honours in Women\'s and Gender Studies' }
];

export default ReginaCourses;
