const IUIntCourses = [
	{ label: "Bachelor Business Administration", value: "Bachelor Business Administration" },
	{ label: "Bachelor International Management - 180 ECTS", value: "Bachelor International Management - 180 ECTS" },
	{ label: "ENTREPRENEURSHIP", value: "ENTREPRENEURSHIP" },
	{ label: "BUSINESS & IT", value: "BUSINESS & IT" },
	{ label: "BSc Computer Science - 180 ECTS", value: "BSc Computer Science - 180 ECTS" },
	{ label: "BSc Data Science - 180 ECTS", value: "BSc Data Science - 180 ECTS" },
	{ label: "CYBER SECURITY -Undergraduate", value: "CYBER SECURITY -Undergraduate" },
	{ label: "Bachelor Industrial Engineering & Management - 180 ECTS", value: "Bachelor Industrial Engineering & Management - 180 ECTS" },
	{ label: "B.Eng. Robotics - 180 ECTS", value: "B.Eng. Robotics - 180 ECTS" },
	{ label: "AVIATION MANAGEMENT", value: "AVIATION MANAGEMENT" },
	{ label: "HOSPITALITY MANAGEMENT", value: "HOSPITALITY MANAGEMENT" },
	{ label: "Master International Management - 120 ECTS", value: "Master International Management - 120 ECTS" },
	{ label: "Master in Management - Leadership - 60 ECTS", value: "Master in Management - Leadership - 60 ECTS" },
	{ label: "MANAGEMENT: LEADERSHIP MANAGEMENT", value: "MANAGEMENT: LEADERSHIP MANAGEMENT" },
	{ label: "INTERNATIONAL MANAGEMENT (MIM)", value: "INTERNATIONAL MANAGEMENT (MIM)" },
	{ label: "CYBER SECURITY -Master's", value: "CYBER SECURITY -Master's" },
	{ label: "ARTIFICIAL INTELLIGENCE", value: "ARTIFICIAL INTELLIGENCE" },
	{ label: "DATA SCIENCE -Master's", value: "DATA SCIENCE -Master's" },
	{ label: "Master Computer Science - 120 ECTS", value: "Master Computer Science - 120 ECTS" },
	{ label: "MBA in IT Management - 90 ECTS", value: "MBA in IT Management - 90 ECTS" },
	{ label: "Master in Management - Big Data Management - 60 ECTS", value: "Master in Management - Big Data Management - 60 ECTS" },
	{ label: "COMPUTER SCIENCE IN CYBER SECURITY", value: "COMPUTER SCIENCE IN CYBER SECURITY" },
	{ label: "Master Engineering Management - 60 ECTS", value: "Master Engineering Management - 60 ECTS" },
	{ label: "MBA in Engineering Management - 90 ECTS", value: "MBA in Engineering Management - 90 ECTS" },
	{ label: "INTERNATIONAL HOSPITALITY MANAGEMENT", value: "INTERNATIONAL HOSPITALITY MANAGEMENT" },
	{ label: "INTERNATIONAL AVIATION MANAGEMENT", value: "INTERNATIONAL AVIATION MANAGEMENT" },
	{ label: "MARKETING MANAGEMENT", value: "MARKETING MANAGEMENT" },
	{ label: "MBA in International Marketing - 90 ECTS", value: "MBA in International Marketing - 90 ECTS" },
	{ label: "Master in Management - International Marketing - 60 ECTS", value: "Master in Management - International Marketing - 60 ECTS" },
	{ label: "Master of Business Administration MBA (1 Year Option) - 60 ECTS", value: "Master of Business Administration MBA (1 Year Option) - 60 ECTS" },
	{ label: "Master in Management - Finance and Accounting - 60 ECTS", value: "Master in Management - Finance and Accounting - 60 ECTS" },
	{ label: "ONE-YEAR MBA", value: "ONE-YEAR MBA" },
	{ label: "MASTER OF BUSINESS ADMINISTRATION", value: "MASTER OF BUSINESS ADMINISTRATION" },
	{ label: "MBA in Big Data Management - 90 ECTS", value: "MBA in Big Data Management - 90 ECTS" },
	{ label: "IT MANAGEMENT", value: "IT MANAGEMENT" },
	{ label: "BSc Cyber Security", value: "BSc Cyber Security" },
	{ label: "B.A. Digital Business", value: "B.A. Digital Business" },
	{ label: "B.A. Entrepreneurship", value: "B.A. Entrepreneurship" },
	{ label: "M.Sc. Data Science", value: "M.Sc. Data Science" },
	{ label: "M.Sc. Artificial Intelligence - 120 ECTS", value: "M.Sc. Artificial Intelligence - 120 ECTS" },
	{ label: "M.Sc. Data Science - 120 ECTS", value: "M.Sc. Data Science - 120 ECTS" },
	{ label: "M.Sc. Cyber Security - 120 ECTS", value: "M.Sc. Cyber Security - 120 ECTS" },
	{ label: "Master in Management - Big Data Management - 60 ECTS", value: "Master in Management - Big Data Management - 60 ECTS" },
	{ label: "Master in Management - Leadership - 60 ECTS - 60 ECTS", value: "Master in Management - Leadership - 60 ECTS - 60 ECTS" },
	{ label: "Master Computer Science - 120 ECTS", value: "Master Computer Science - 120 ECTS" },
	{ label: "Master International Management - 120 ECTS", value: "Master International Management - 120 ECTS" },
	{ label: "Master of Business Administration MBA", value: "Master of Business Administration MBA" },
	{ label: "MBA in Big Data Management - 90 ECTS", value: "MBA in Big Data Management - 90 ECTS" },
	{ label: "MBA in Engineering Management - 90 ECTS", value: "MBA in Engineering Management - 90 ECTS" },
	{ label: "MBA in Finance & Accounting - 90 ECTS", value: "MBA in Finance & Accounting - 90 ECTS" },
	{ label: "MBA in IT Management - 90 ECTS - 90 ECTS", value: "MBA in IT Management - 90 ECTS - 90 ECTS" },
	{ label: "MBA in International Marketing - 90 ECTS", value: "MBA in International Marketing - 90 ECTS" },
	{ label: "Master Engineering Management - 60 ECTS", value: "Master Engineering Management - 60 ECTS" },
	{ label: "Master of Business Administration MBA (1 Year Option) - 60 ECTS", value: "Master of Business Administration MBA (1 Year Option) - 60 ECTS" },
	{ label: "MAM Big Data Management", value: "MAM Big Data Management" },
	{ label: "Master in Management - International Marketing - 60 ECTS", value: "Master in Management - International Marketing - 60 ECTS" },
	{ label: "Bachelor International Management - 180 ECTS", value: "Bachelor International Management - 180 ECTS" },
	{ label: "Bachelor Business Administration - 180 ECTS", value: "Bachelor Business Administration - 180 ECTS" },
	{ label: "BSc Computer Science - 180 ECTS", value: "BSc Computer Science - 180 ECTS" },
	{ label: "Bachelor Industrial Engineering & Management - 180 ECTS", value: "Bachelor Industrial Engineering & Management - 180 ECTS" },
	{ label: "Pathway (Pre-Course)", value: "Pathway (Pre-Course)" },
	{ label: "BSc Artificial Intelligence - 180 ECTS", value: "BSc Artificial Intelligence - 180 ECTS" },
	{ label: "BSc Business & IT - 180 ECTS", value: "BSc Business & IT - 180 ECTS" },
	{ label: "BSc Cyber Security - 180 ECTS", value: "BSc Cyber Security - 180 ECTS" },
	{ label: "BSc Software Development - 180 ECTS", value: "BSc Software Development - 180 ECTS" },
	{ label: "Bachelor in Hospitality Management - 180 ECTS", value: "Bachelor in Hospitality Management - 180 ECTS" },
	{ label: "Master in Management - Engineering Management - 60 ECTS", value: "Master in Management - Engineering Management - 60 ECTS" },
	{ label: "Master International Management - 60 ECTS", value: "Master International Management - 60 ECTS" },
	{ label: "MBA in Artificial Intelligence - 90 ECTS", value: "MBA in Artificial Intelligence - 90 ECTS" },
	{ label: "MBA in E-Sports Management - 90 ECTS", value: "MBA in E-Sports Management - 90 ECTS" },
	{ label: "MBA in Finance & Accounting - 90 ECTS", value: "MBA in Finance & Accounting - 90 ECTS" },
	{ label: "MBA in Healthcare Management - 90 ECTS", value: "MBA in Healthcare Management - 90 ECTS" },
	{ label: "MBA in Human Resource Management - 90 ECTS", value: "MBA in Human Resource Management - 90 ECTS" },
	{ label: "MBA in Innovation & Entrepreneurship - 90 ECTS", value: "MBA in Innovation & Entrepreneurship - 90 ECTS" },
	{ label: "MBA in Supply Chain Management - 90 ECTS", value: "MBA in Supply Chain Management - 90 ECTS" },
	{ label: "Pathway (one year Prep-course)", value: "Pathway (one year Prep-course)" },
	{ label: "BSc Artificial Intelligence - 180 ECTS", value: "BSc Artificial Intelligence - 180 ECTS" },
	{ label: "BSc Business & IT - 180 ECTS", value: "BSc Business & IT - 180 ECTS" },
	{ label: "BSc Cyber Security - 180 ECTS", value: "BSc Cyber Security - 180 ECTS" },
	{ label: "BSc Software Development - 180 ECTS", value: "BSc Software Development - 180 ECTS" },
	{ label: "Bachelor in Aviation Management - 180 ECTS", value: "Bachelor in Aviation Management - 180 ECTS" },
	{ label: "Bachelor in Hospitality Management - 180 ECTS", value: "Bachelor in Hospitality Management - 180 ECTS" },
	{ label: "M.Sc. Business Intelligence - 120 ECTS", value: "M.Sc. Business Intelligence - 120 ECTS" },
	{ label: "M.Sc. Data Management - 120 ECTS", value: "M.Sc. Data Management - 120 ECTS" },
	{ label: "Master in Management - Engineering Management - 60 ECTS", value: "Master in Management - Engineering Management - 60 ECTS" },
	{ label: "Master International Management - 60 ECTS", value: "Master International Management - 60 ECTS" },
	{ label: "MBA in Artificial Intelligence - 90 ECTS", value: "MBA in Artificial Intelligence - 90 ECTS" },
	{ label: "MBA in E-Sports Management - 90 ECTS", value: "MBA in E-Sports Management - 90 ECTS" },
	{ label: "MBA in Healthcare Management - 90 ECTS", value: "MBA in Healthcare Management - 90 ECTS" },
	{ label: "MBA in Human Resource Management - 90 ECTS", value: "MBA in Human Resource Management - 90 ECTS" },
	{ label: "MBA in Innovation & Entrepreneurship - 90 ECTS", value: "MBA in Innovation & Entrepreneurship - 90 ECTS" },
	{ label: "MBA in Supply Chain Management - 90 ECTS", value: "MBA in Supply Chain Management - 90 ECTS" },
	{ label: "Master of Business Administration - 90 ECTS", value: "Master of Business Administration - 90 ECTS" },
	{ label: "B.Eng. in Industrial Engineering and Management", value: "B.Eng. in Industrial Engineering and Management" },
	{ label: "B.Eng. in Robotics", value: "B.Eng. in Robotics" },
	{ label: "B.Sc in Software Development", value: "B.Sc in Software Development" },
	{ label: "B.Sc in Applied Artificial Intelligence", value: "B.Sc in Applied Artificial Intelligence" },
	{ label: "B.Sc in Computer Science", value: "B.Sc in Computer Science" },
	{ label: "B.Sc in Cyber Security", value: "B.Sc in Cyber Security" },
	{ label: "B.Sc in Data Science", value: "B.Sc in Data Science" },
	{ label: "B.Sc in Business and IT", value: "B.Sc in Business and IT" },
	{ label: "B.A in Hospitality Management", value: "B.A in Hospitality Management" },
	{ label: "B.A in Management", value: "B.A in Management" },
	{ label: "B.A in International Management", value: "B.A in International Management" },
	{ label: "B.A in Entrepreneurship", value: "B.A in Entrepreneurship" },
	{ label: "B.A in Digital Business", value: "B.A in Digital Business" },
	{ label: "B.A in Business Administration", value: "B.A in Business Administration" },
	{ label: "B.A in International Healthcare Management", value: "B.A in International Healthcare Management" },
	{ label: "B.Sc in Applied Psychology", value: "B.Sc in Applied Psychology" },
	{ label: "B.Sc in Industrial and Organizational Psychology", value: "B.Sc in Industrial and Organizational Psychology" },
	{ label: "M.A in Information Technology Management", value: "M.A in Information Technology Management" },
	{ label: "M.Sc in Cyber Security Management", value: "M.Sc in Cyber Security Management" },
	{ label: "M.Sc in Cyber Security", value: "M.Sc in Cyber Security" },
	{ label: "M.Sc in Data Management", value: "M.Sc in Data Management" },
	{ label: "M.Sc in Data Science", value: "M.Sc in Data Science" },
	{ label: "M.Sc in Computer Science", value: "M.Sc in Computer Science" },
	{ label: "M.Eng in Engineering Management", value: "M.Eng in Engineering Management" },
	{ label: "M.Sc in Artificial Intelligence", value: "M.Sc in Artificial Intelligence" },
	{ label: "M.Sc in Finance, Accounting and Taxation", value: "M.Sc in Finance, Accounting and Taxation" },
	{ label: "M.Sc in Business Intelligence", value: "M.Sc in Business Intelligence" },
	{ label: "M.A in Project Management", value: "M.A in Project Management" },
	{ label: "M.A in Human Resource Management", value: "M.A in Human Resource Management" },
	{ label: "M.A in Digital Innovation & Intrapreneurship", value: "M.A in Digital Innovation & Intrapreneurship" },
	{ label: "M.A in International Management", value: "M.A in International Management" },
	{ label: "M.A in Management", value: "M.A in Management" },
	{ label: "M.A in Innovation & Entrepreneurship", value: "M.A in Innovation & Entrepreneurship" },
	{ label: "M.A in Digital Marketing", value: "M.A in Digital Marketing" },
	{ label: "M.A in Marketing Management", value: "M.A in Marketing Management" },
	{ label: "M.A in International Healthcare Management", value: "M.A in International Healthcare Management" },
	{ label: "Master of Business Administration (MBA)", value: "Master of Business Administration (MBA)" },
	{ label: "One-year MBA", value: "One-year MBA" },
	{ label: "MBA in Innovation & Entrepreneurship", value: "MBA in Innovation & Entrepreneurship" },
	{ label: "MBA in Human Resource Management", value: "MBA in Human Resource Management" },
	{ label: "MBA in Human Healthcare Management", value: "MBA in Human Healthcare Management" },
	{ label: "MBA in E-Sports Management", value: "MBA in E-Sports Management" },
	{ label: "MBA in Engineering Management", value: "MBA in Engineering Management" },
	{ label: "MBA in Big Data Management", value: "MBA in Big Data Management" },
	{ label: "MBA in Finance & Accounting", value: "MBA in Finance & Accounting" },
	{ label: "MBA in International Marketing", value: "MBA in International Marketing" },
	{ label: "MBA in IT Management", value: "MBA in IT Management" },
	{ label: "MBA in Artificial Intelligence", value: "MBA in Artificial Intelligence" },
	{ label: "MBA in Supply Chain Management", value: "MBA in Supply Chain Management" }
];

export default IUIntCourses;