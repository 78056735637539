import React, { createContext, useState } from 'react';

export const NotificationContext = createContext({
	notifications: [], // Default value
	// other default values and methods if any
});

export const NotificationProvider = ({ children }) => {
	const [notifications, setNotifications] = useState([]);

	const addNotification = (notification) => {
		setNotifications([...notifications, notification]);
	};

	const removeNotification = (notification) => {
		setNotifications(notifications.filter(n => n !== notification));
	};

	return (
		<NotificationContext.Provider value={{ notifications, addNotification, removeNotification }}>
			{children}
		</NotificationContext.Provider>
	);
};
