// ProtectedRoutes.js

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../utils/AuthContext';
import Dashboard from '../pages/Dashboard';
import AddEnquiry from "../Forms/AddEnquiry";
import Enquiries from "../pages/Enquiries";
import EnquiryDetails from "../pages/EnquiryDetails";
import Account from '../pages/Account/Account';
import NotFound from '../layout/NotFound';
import CreateUser from '../Forms/CreateUser';
import PasswordResetRequest from '../passwords/PasswordResetRequest';
import { getUserDetailsFromToken } from '../utils/getUserDetailsFromToken';
import CreateBranch from '../Forms/CreateBranch';
import BranchesList from '../displays/BranchesList';
import CreateCounselor from '../Forms/CreateCounselor';
import CounselorsList from '../displays/CounselorsList';
import UsersList from '../displays/UsersList';
import SearchEnquiries from '../pages/SearchEnquiries';

const ProtectedRoutes = () => {
	const { isLoggedIn } = useAuth();
	const userDetails = getUserDetailsFromToken();
	const isAdmin = userDetails?.privilege === 'Admin';

	if (!isLoggedIn) {
		return <Navigate to="/login" />;
	}

	return (
		<Routes>
			<Route path="/dashboard" element={<Dashboard />} />
			<Route path="/add-enquiry" element={<AddEnquiry />} />
			<Route path="/enquiries" element={<Enquiries />} />
			<Route path="/enquiry-details/:id" element={<EnquiryDetails />} />
			<Route path="/enquiry-details/" element={<Enquiries />} />
			<Route path="/account" element={<Account />} />
			{isAdmin && <Route path="/account/create-user" element={<CreateUser />} />}
			{isAdmin && <Route path="/account/create-branch" element={<CreateBranch />} />}
			{isAdmin && <Route path="/account/manage-branches" element={<BranchesList />} />}
			{isAdmin && <Route path="/account/create-counselor" element={<CreateCounselor />} />}
			{isAdmin && <Route path="/account/manage-counselors" element={<CounselorsList />} />}
			{isAdmin && <Route path="/account/manage-users" element={<UsersList />} />}
			{isAdmin && <Route path="/search" element={<SearchEnquiries />} />}
			<Route path="/account/change-password" element={<PasswordResetRequest />} />
			<Route path="*" element={<NotFound />} />
		</Routes>
	);
};

export default ProtectedRoutes;
