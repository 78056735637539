const JamesCookCourses = [
	{ value: "Bachelor of Engineering (Honours), Bachelor of Science", label: "Bachelor of Engineering (Honours), Bachelor of Science" },
	{ value: "Bachelor of Laws (Graduate Entry)", label: "Bachelor of Laws (Graduate Entry)" },
	{ value: "Bachelor of Medicine, Bachelor of Surgery", label: "Bachelor of Medicine, Bachelor of Surgery" },
	{ value: "Master of Engineering (Professional)", label: "Master of Engineering (Professional)" },
	{ value: "Master of International Tourism and Hospitality Management", label: "Master of International Tourism and Hospitality Management" },
	{ value: "Bachelor of Engineering (Honours) Electronic Systems and Internet of Things Engineering", label: "Bachelor of Engineering (Honours) Electronic Systems and Internet of Things Engineering" },
	{ value: "Master of Governance and Leadership", label: "Master of Governance and Leadership" },
	{ value: "Bachelor of Biomedical Sciences", label: "Bachelor of Biomedical Sciences" },
	{ value: "Master of Public Health and Tropical Medicine", label: "Master of Public Health and Tropical Medicine" },
	{ value: "Bachelor of Information Technology", label: "Bachelor of Information Technology" },
	{ value: "Master of Professional Accounting", label: "Master of Professional Accounting" },
	{ value: "Bachelor of Arts", label: "Bachelor of Arts" },
	{ value: "Bachelor of Tourism, Hospitality & Events", label: "Bachelor of Tourism, Hospitality & Events" },
	{ value: "Bachelor of Science - Bachelor of Laws", label: "Bachelor of Science - Bachelor of Laws" },
	{ value: "Bachelor of Dental Surgery", label: "Bachelor of Dental Surgery" },
	{ value: "Master of Philosophy (Society and Culture)", label: "Master of Philosophy (Society and Culture)" },
	{ value: "Master of Social Work (Professional Qualifying)", label: "Master of Social Work (Professional Qualifying)" },
	{ value: "Bachelor of Engineering (Honours) Electrical and Electronic Engineering", label: "Bachelor of Engineering (Honours) Electrical and Electronic Engineering" },
	{ value: "Master of Philosophy (Natural and Physical Sciences)", label: "Master of Philosophy (Natural and Physical Sciences)" },
	{ value: "Master of Planning and Urban Design", label: "Master of Planning and Urban Design" },
	{ value: "Master of Global Development", label: "Master of Global Development" },
	{ value: "Bachelor of Arts / Bachelor of Science", label: "Bachelor of Arts / Bachelor of Science" },
	{ value: "Bachelor of Information Technology", label: "Bachelor of Information Technology" },
	{ value: "Master of Philosophy (Indigenous)", label: "Master of Philosophy (Indigenous)" },
	{ value: "Master of Public Health-Master of Business Administration", label: "Master of Public Health-Master of Business Administration" },
	{ value: "Master of International Hospitality & Tourism Management", label: "Master of International Hospitality & Tourism Management" },
	{ value: "Bachelor of Engineering", label: "Bachelor of Engineering" },
	{ value: "Bachelor of Education ( Primary )", label: "Bachelor of Education ( Primary )" },
	{ value: "Master of Marine Biology", label: "Master of Marine Biology" },
	{ value: "Bachelor of Engineering (Honours), Bachelor of Science", label: "Bachelor of Engineering (Honours), Bachelor of Science" },
	{ value: "Bachelor of Laws (Graduate Entry)", label: "Bachelor of Laws (Graduate Entry)" },
	{ value: "Bachelor of Medicine, Bachelor of Surgery", label: "Bachelor of Medicine, Bachelor of Surgery" },
	{ value: "Master of Engineering (Professional)", label: "Master of Engineering (Professional)" },
	{ value: "Master of International Tourism and Hospitality Management", label: "Master of International Tourism and Hospitality Management" },
	{ value: "Bachelor of Engineering (Honours) Electronic Systems and Internet of Things Engineering", label: "Bachelor of Engineering (Honours) Electronic Systems and Internet of Things Engineering" },
	{ value: "Master of Governance and Leadership", label: "Master of Governance and Leadership" },
	{ value: "Bachelor of Biomedical Sciences", label: "Bachelor of Biomedical Sciences" },
	{ value: "Master of Public Health and Tropical Medicine", label: "Master of Public Health and Tropical Medicine" },
	{ value: "Bachelor of Information Technology", label: "Bachelor of Information Technology" },
	{ value: "Master of Professional Accounting", label: "Master of Professional Accounting" },
	{ value: "Bachelor of Arts", label: "Bachelor of Arts" },
	{ value: "Bachelor of Tourism, Hospitality & Events", label: "Bachelor of Tourism, Hospitality & Events" },
	{ value: "Bachelor of Science - Bachelor of Laws", label: "Bachelor of Science - Bachelor of Laws" },
	{ value: "Bachelor of Dental Surgery", label: "Bachelor of Dental Surgery" },
	{ value: "Master of Philosophy (Society and Culture)", label: "Master of Philosophy (Society and Culture)" },
	{ value: "Master of Social Work (Professional Qualifying)", label: "Master of Social Work (Professional Qualifying)" },
	{ value: "Bachelor of Engineering (Honours) Electrical and Electronic Engineering", label: "Bachelor of Engineering (Honours) Electrical and Electronic Engineering" },
	{ value: "Master of Philosophy (Natural and Physical Sciences)", label: "Master of Philosophy (Natural and Physical Sciences)" },
	{ value: "Master of Planning and Urban Design", label: "Master of Planning and Urban Design" },
	{ value: "Master of Global Development", label: "Master of Global Development" },
	{ value: "Bachelor of Arts / Bachelor of Science", label: "Bachelor of Arts / Bachelor of Science" },
	{ value: "Bachelor of Information Technology", label: "Bachelor of Information Technology" },
	{ value: "Master of Philosophy (Indigenous)", label: "Master of Philosophy (Indigenous)" },
	{ value: "Master of Public Health-Master of Business Administration", label: "Master of Public Health-Master of Business Administration" },
	{ value: "Master of International Hospitality & Tourism Management", label: "Master of International Hospitality & Tourism Management" },
	{ value: "Bachelor of Engineering", label: "Bachelor of Engineering" },
	{ value: "Bachelor of Education ( Primary )", label: "Bachelor of Education ( Primary )" },
	{ value: "Master of Marine Biology", label: "Master of Marine Biology" },
	{ value: "Master of Business Administration-Master of Conflict Management and Resolution", label: "Master of Business Administration-Master of Conflict Management and Resolution" },
	{ value: "Master of Science (Professional)", label: "Master of Science (Professional)" },
	{ value: "Bachelor of Science", label: "Bachelor of Science" },
	{ value: "Bachelor of Arts / Bachelor of Business", label: "Bachelor of Arts / Bachelor of Business" },
	{ value: "Bachelor of Sport and Exercise Science", label: "Bachelor of Sport and Exercise Science" },
	{ value: "Bachelor of Veterinary Science", label: "Bachelor of Veterinary Science" },
	{ value: "Master of Business Administration", label: "Master of Business Administration" },
	{ value: "Bachelor of Planning", label: "Bachelor of Planning" },
	{ value: "Master of International Hospitality & Tourism Management – Master of Business Administration", label: "Master of International Hospitality & Tourism Management – Master of Business Administration" },
	{ value: "Master of Data Science (Professional)", label: "Master of Data Science (Professional)" },
	{ value: "Master of Education – Master of Business Administration", label: "Master of Education – Master of Business Administration" },
	{ value: "Bachelor of Geology", label: "Bachelor of Geology" },
	{ value: "Master of Tropical Veterinary Science", label: "Master of Tropical Veterinary Science" },
	{ value: "Master of Information Technology (Business Informatics)", label: "Master of Information Technology (Business Informatics)" },
	{ value: "Master of Information Technology (Computing & Networking)", label: "Master of Information Technology (Computing & Networking)" },
	{ value: "Bachelor of Engineering (Honours), Bachelor of Information Technology", label: "Bachelor of Engineering (Honours), Bachelor of Information Technology" },
	{ value: "Bachelor of Occupational Therapy (Honours)", label: "Bachelor of Occupational Therapy (Honours)" },
	{ value: "Bachelor of Commerce", label: "Bachelor of Commerce" },
	{ value: "Master of Information Technology", label: "Master of Information Technology" },
	{ value: "Master of Professional Accounting", label: "Master of Professional Accounting" },
	{ value: "Master of Professional Accounting-Master of Business Administration", label: "Master of Professional Accounting-Master of Business Administration" },
	{ value: "Bachelor of Business (Accounting)", label: "Bachelor of Business (Accounting)" },
	{ value: "Master of Medical Science", label: "Master of Medical Science" },
	{ value: "Master of Teaching and Learning (Secondary)", label: "Master of Teaching and Learning (Secondary)" },
	{ value: "Master of Philosophy (Education)", label: "Master of Philosophy (Education)" },
	{ value: "Master of Conflict Management and Resolution", label: "Master of Conflict Management and Resolution" },
	{ value: "Master of Rehabilitation", label: "Master of Rehabilitation" },
	{ value: "Master of Engineering Management", label: "Master of Engineering Management" },
	{ value: "Bachelor of Engineering (Honours), Bachelor of Science", label: "Bachelor of Engineering (Honours), Bachelor of Science" },
	{ value: "Bachelor of Laws (Graduate Entry)", label: "Bachelor of Laws (Graduate Entry)" },
	{ value: "Bachelor of Medicine, Bachelor of Surgery", label: "Bachelor of Medicine, Bachelor of Surgery" },
	{ value: "Master of Engineering (Professional)", label: "Master of Engineering (Professional)" },
	{ value: "Master of International Tourism and Hospitality Management", label: "Master of International Tourism and Hospitality Management" },
	{ value: "Bachelor of Engineering (Honours) Electronic Systems and Internet of Things Engineering", label: "Bachelor of Engineering (Honours) Electronic Systems and Internet of Things Engineering" },
	{ value: "Master of Governance and Leadership", label: "Master of Governance and Leadership" },
	{ value: "Bachelor of Biomedical Sciences", label: "Bachelor of Biomedical Sciences" },
	{ value: "Master of Public Health and Tropical Medicine", label: "Master of Public Health and Tropical Medicine" },
	{ value: "Bachelor of Information Technology", label: "Bachelor of Information Technology" },
	{ value: "Master of Professional Accounting", label: "Master of Professional Accounting" },
	{ value: "Bachelor of Arts", label: "Bachelor of Arts" },
	{ value: "Bachelor of Tourism, Hospitality & Events", label: "Bachelor of Tourism, Hospitality & Events" },
	{ value: "Bachelor of Science - Bachelor of Laws", label: "Bachelor of Science - Bachelor of Laws" },
	{ value: "Bachelor of Dental Surgery", label: "Bachelor of Dental Surgery" },
	{ value: "Master of Philosophy (Society and Culture)", label: "Master of Philosophy (Society and Culture)" },
	{ value: "Master of Social Work (Professional Qualifying)", label: "Master of Social Work (Professional Qualifying)" },
	{ value: "Bachelor of Engineering (Honours) Electrical and Electronic Engineering", label: "Bachelor of Engineering (Honours) Electrical and Electronic Engineering" },
	{ value: "Master of Philosophy (Natural and Physical Sciences)", label: "Master of Philosophy (Natural and Physical Sciences)" },
	{ value: "Master of Planning and Urban Design", label: "Master of Planning and Urban Design" },
	{ value: "Master of Global Development", label: "Master of Global Development" },
	{ value: "Bachelor of Arts / Bachelor of Science", label: "Bachelor of Arts / Bachelor of Science" },
	{ value: "Bachelor of Information Technology", label: "Bachelor of Information Technology" },
	{ value: "Master of Philosophy (Indigenous)", label: "Master of Philosophy (Indigenous)" },
	{ value: "Master of Public Health-Master of Business Administration", label: "Master of Public Health-Master of Business Administration" },
	{ value: "Master of International Hospitality & Tourism Management", label: "Master of International Hospitality & Tourism Management" },
	{ value: "Bachelor of Engineering", label: "Bachelor of Engineering" },
	{ value: "Bachelor of Education ( Primary )", label: "Bachelor of Education ( Primary )" },
	{ value: "Master of Marine Biology", label: "Master of Marine Biology" },
	{ value: "Master of Business Administration-Master of Conflict Management and Resolution", label: "Master of Business Administration-Master of Conflict Management and Resolution" },
	{ value: "Master of Science (Professional)", label: "Master of Science (Professional)" },
	{ value: "Bachelor of Science", label: "Bachelor of Science" },
	{ value: "Bachelor of Arts / Bachelor of Business", label: "Bachelor of Arts / Bachelor of Business" },
	{ value: "Bachelor of Sport and Exercise Science", label: "Bachelor of Sport and Exercise Science" },
	{ value: "Bachelor of Veterinary Science", label: "Bachelor of Veterinary Science" },
	{ value: "Master of Business Administration", label: "Master of Business Administration" },
	{ value: "Bachelor of Planning", label: "Bachelor of Planning" },
	{ value: "Master of International Hospitality & Tourism Management – Master of Business Administration", label: "Master of International Hospitality & Tourism Management – Master of Business Administration" },
	{ value: "Master of Data Science (Professional)", label: "Master of Data Science (Professional)" },
	{ value: "Master of Education – Master of Business Administration", label: "Master of Education – Master of Business Administration" },
	{ value: "Bachelor of Geology", label: "Bachelor of Geology" },
	{ value: "Master of Tropical Veterinary Science", label: "Master of Tropical Veterinary Science" },
	{ value: "Master of Information Technology (Business Informatics)", label: "Master of Information Technology (Business Informatics)" },
	{ value: "Master of Information Technology (Computing & Networking)", label: "Master of Information Technology (Computing & Networking)" },
	{ value: "Bachelor of Engineering (Honours), Bachelor of Information Technology", label: "Bachelor of Engineering (Honours), Bachelor of Information Technology" },
	{ value: "Bachelor of Occupational Therapy (Honours)", label: "Bachelor of Occupational Therapy (Honours)" },
	{ value: "Bachelor of Commerce", label: "Bachelor of Commerce" },
	{ value: "Master of Information Technology", label: "Master of Information Technology" },
	{ value: "Master of Professional Accounting", label: "Master of Professional Accounting" },
	{ value: "Master of Professional Accounting-Master of Business Administration", label: "Master of Professional Accounting-Master of Business Administration" },
	{ value: "Bachelor of Business (Accounting)", label: "Bachelor of Business (Accounting)" },
	{ value: "Master of Medical Science", label: "Master of Medical Science" },
	{ value: "Master of Teaching and Learning (Secondary)", label: "Master of Teaching and Learning (Secondary)" },
	{ value: "Master of Philosophy (Education)", label: "Master of Philosophy (Education)" },
	{ value: "Master of Conflict Management and Resolution", label: "Master of Conflict Management and Resolution" },
	{ value: "Master of Rehabilitation", label: "Master of Rehabilitation" },
	{ value: "Master of Engineering Management", label: "Master of Engineering Management" },
	{ value: "Master of Professional Accounting – Master of Business Administration", label: "Master of Professional Accounting – Master of Business Administration" },
	{ value: "Master of Business Administration", label: "Master of Business Administration" },
	{ value: "Master of Animal Sciences", label: "Master of Animal Sciences" },
	{ value: "Master of Science", label: "Master of Science" },
	{ value: "Master of Information Technology - Master of Business Administration", label: "Master of Information Technology - Master of Business Administration" },
	{ value: "Postgraduate Qualifying Program (Business)", label: "Postgraduate Qualifying Program (Business)" },
	{ value: "Master of Philosophy (Agriculture, Environmental and Related Studies)", label: "Master of Philosophy (Agriculture, Environmental and Related Studies)" },
	{ value: "Master of Philosophy (Engineering and Related Technologies)", label: "Master of Philosophy (Engineering and Related Technologies)" },
	{ value: "Master of Teaching and Learning (Primary)", label: "Master of Teaching and Learning (Primary)" },
	{ value: "Bachelor of Business (Management)", label: "Bachelor of Business (Management)" },
	{ value: "Master of Public Health", label: "Master of Public Health" },
	{ value: "Bachelor of Advanced Science", label: "Bachelor of Advanced Science" },
	{ value: "Bachelor of Engineering (Honours) Chemical Engineering", label: "Bachelor of Engineering (Honours) Chemical Engineering" },
	{ value: "Master of Philosophy (Health)", label: "Master of Philosophy (Health)" },
	{ value: "Bachelor of Commerce (Accounting)", label: "Bachelor of Commerce (Accounting)" },
	{ value: "Master of Data Science", label: "Master of Data Science" },
	{ value: "Bachelor of Education (Early Childhood Education)", label: "Bachelor of Education (Early Childhood Education)" },
	{ value: "Bachelor of Medical Laboratory Science(Honours)", label: "Bachelor of Medical Laboratory Science(Honours)" },
	{ value: "Master of International Tourism and Hospitality Management - Master of Business Administration", label: "Master of International Tourism and Hospitality Management - Master of Business Administration" },
	{ value: "Bachelor of Arts / Bachelor of Laws", label: "Bachelor of Arts / Bachelor of Laws" },
	{ value: "Bachelor of Business and Environmental Science", label: "Bachelor of Business and Environmental Science" },
	{ value: "Master of Philosophy (Information Technology)", label: "Master of Philosophy (Information Technology)" },
	{ value: "Bachelor of Business", label: "Bachelor of Business" },
	{ value: "Bachelor of Business-Bachelor of Laws", label: "Bachelor of Business-Bachelor of Laws" },
	{ value: "Bachelor of Business-Bachelor of Psychological Science", label: "Bachelor of Business-Bachelor of Psychological Science" },
	{ value: "Bachelor of Education (Secondary)", label: "Bachelor of Education (Secondary)" },
	{ value: "Bachelor of Physiotherapy", label: "Bachelor of Physiotherapy" },
	{ value: "Bachelor of Social Work", label: "Bachelor of Social Work" },
	{ value: "Bachelor of Clinical Sciences (Honours)", label: "Bachelor of Clinical Sciences (Honours)" },
	{ value: "Bachelor of Psychological Science", label: "Bachelor of Psychological Science" },
	{ value: "Bachelor of Nursing Science", label: "Bachelor of Nursing Science" },
	{ value: "Master of Philosophy (Management and Commerce)", label: "Master of Philosophy (Management and Commerce)" },
	{ value: "Bachelor of Business (Hospitality & Tourism Management)", label: "Bachelor of Business (Hospitality & Tourism Management)" },
	{ value: "Bachelor of Pharmacy", label: "Bachelor of Pharmacy" },
	{ value: "Master of Philosophy (Medical and Molecular Sciences)", label: "Master of Philosophy (Medical and Molecular Sciences)" },
	{ value: "Bachelor of Tourism, Hospitality and Events", label: "Bachelor of Tourism, Hospitality and Events" },
	{ value: "Bachelor of Engineering (Honours) Civil Engineering", label: "Bachelor of Engineering (Honours) Civil Engineering" },
	{ value: "Bachelor of Environmental Practice", label: "Bachelor of Environmental Practice" },
	{ value: "Bachelor of Veterinary Science (Honours)", label: "Bachelor of Veterinary Science (Honours)" },
	{ value: "Bachelor of Sport and Exercise Science - Bachelor of Psychological Science", label: "Bachelor of Sport and Exercise Science - Bachelor of Psychological Science" },
	{ value: "Master of Social Science", label: "Master of Social Science" },
	{ value: "Master of Philosophy (Creative Arts)", label: "Master of Philosophy (Creative Arts)" },
	{ value: "Bachelor of Business (Business Intelligence and Information Systems)", label: "Bachelor of Business (Business Intelligence and Information Systems)" },
	{ value: "Master of Business Administration (Leadership)", label: "Master of Business Administration (Leadership)" },
	{ value: "Bachelor of Engineering (Honours) Mechanical Engineering", label: "Bachelor of Engineering (Honours) Mechanical Engineering" },
	{ value: "Bachelor of Laws", label: "Bachelor of Laws" },
	{ value: "Bachelor of Marine Science", label: "Bachelor of Marine Science" },
	{ value: "Bachelor of Speech Pathology", label: "Bachelor of Speech Pathology" },
	{ value: "Bachelor of Technology and Innovation", label: "Bachelor of Technology and Innovation" },
	{ value: "Bachelor of Business (International Business)", label: "Bachelor of Business (International Business)" }
];

export default JamesCookCourses;