const GeorgeBrownCourses = [
	{ value: 'Advanced Diploma in Architectural Technology', label: 'Advanced Diploma in Architectural Technology' },
	{ value: 'Advanced Diploma in Business Administration (with work experience)', label: 'Advanced Diploma in Business Administration (with work experience)' },
	{ value: 'Diploma in Event Planning', label: 'Diploma in Event Planning' },
	{ value: 'Diploma in Business', label: 'Diploma in Business' },
	{ value: 'Diploma in Carpentry and Renovation Technician', label: 'Diploma in Carpentry and Renovation Technician' },
	{ value: 'Certificate in Electrical Techniques', label: 'Certificate in Electrical Techniques' },
	{ value: 'Honours Bachelor of Business Administration (Business Analytics)', label: 'Honours Bachelor of Business Administration (Business Analytics)' },
	{ value: 'Diploma in Culinary Management ( Integrated Learning )', label: 'Diploma in Culinary Management ( Integrated Learning )' },
	{ value: 'Honours Bachelor of Behaviour Analysis', label: 'Honours Bachelor of Behaviour Analysis' },
	{ value: 'Advanced Diploma in Business Administration – Accounting (with work experience)', label: 'Advanced Diploma in Business Administration – Accounting (with work experience)' },
	{ value: 'Diploma in Computer Systems Technician', label: 'Diploma in Computer Systems Technician' },
	{ value: 'Diploma in Jewellery Methods', label: 'Diploma in Jewellery Methods' },
	{ value: 'Diploma in Dance Performance', label: 'Diploma in Dance Performance' },
	{ value: 'Graduate Certificate in Blockchain Development', label: 'Graduate Certificate in Blockchain Development' },
	{ value: 'Pre-Health Sciences Pathway to Certificates and Diplomas', label: 'Pre-Health Sciences Pathway to Certificates and Diplomas' },
	{ value: 'Graduate certificate in Culinary Arts – Italian', label: 'Graduate certificate in Culinary Arts – Italian' },
	{ value: 'Advanced Diploma in Business Administration – Accounting', label: 'Advanced Diploma in Business Administration – Accounting' },
	{ value: 'Advanced Diploma in  Interior  Design Technology', label: 'Advanced Diploma in  Interior  Design Technology' },
	{ value: 'Advanced Diploma in Graphic Design', label: 'Advanced Diploma in Graphic Design' },
	{ value: 'Diploma in Business – Finance', label: 'Diploma in Business – Finance' },
	{ value: 'Graduate certificate in Visual Effects', label: 'Graduate certificate in Visual Effects' },
	{ value: 'Diploma in Architectural Technician', label: 'Diploma in Architectural Technician' },
	{ value: 'Graduate certificate in Network and System Security Analysis', label: 'Graduate certificate in Network and System Security Analysis' },
	{ value: 'Honours Bachelor of Business Administration (Hospitality)', label: 'Honours Bachelor of Business Administration (Hospitality)' },
	{ value: 'Advanced Diploma in  Business Administration – Human Resources', label: 'Advanced Diploma in  Business Administration – Human Resources' },
	{ value: 'Diploma in Electromechanical  Engineering Technician', label: 'Diploma in Electromechanical  Engineering Technician' },
	{ value: 'Graduate certificate in Wireless Networking', label: 'Graduate certificate in Wireless Networking' },
	{ value: 'Diploma in Behavioural Science Technician', label: 'Diploma in Behavioural Science Technician' },
	{ value: 'Graduate certificate in International Business Management', label: 'Graduate certificate in International Business Management' },
	{ value: 'Graduate certificate in Project Management', label: 'Graduate certificate in Project Management' },
	{ value: 'Graduate certificate in Advanced Wine and Beverage Business Management (Postgraduate)', label: 'Graduate certificate in Advanced Wine and Beverage Business Management (Postgraduate)' },
	{ value: "Diploma in Assaulted Women's and Children's Counsellor/Advocate", label: "Diploma in Assaulted Women's and Children's Counsellor/Advocate" },
	{ value: 'Diploma in Business – Human Resources', label: 'Diploma in Business – Human Resources' },
	{ value: 'Certificate in General Arts and Science – Introduction to Performing Arts Careers', label: 'Certificate in General Arts and Science – Introduction to Performing Arts Careers' },
	{ value: 'Diploma in Early Childhood Education', label: 'Diploma in Early Childhood Education' },
	{ value: 'Advanced Diploma in Business Administration – Human Resources (with work experience)', label: 'Advanced Diploma in Business Administration – Human Resources (with work experience)' },
	{ value: 'Diploma in General Arts and Science', label: 'Diploma in General Arts and Science' },
	{ value: 'Graduate certificate in Digital Design – Game Design', label: 'Graduate certificate in Digital Design – Game Design' },
	{ value: 'Graduate certificate in Interactive Media Management', label: 'Graduate certificate in Interactive Media Management' },
	{ value: 'Graduate certificate in Autism and Behavioural Science', label: 'Graduate certificate in Autism and Behavioural Science' },
	{ value: 'Diploma in Social Service Worker', label: 'Diploma in Social Service Worker' },
	{ value: 'Certificate in Plumbing Techniques', label: 'Certificate in Plumbing Techniques' },
	{ value: 'Certificate in Construction Techniques', label: 'Certificate in Construction Techniques' },
	{ value: 'Graduate certificate in Construction Management ( For Internationally Educated Professionals )', label: 'Graduate certificate in Construction Management ( For Internationally Educated Professionals )' },
	{ value: 'Advanced Diploma in Business Administration – International Business (with work experience)', label: 'Advanced Diploma in Business Administration – International Business (with work experience)' },
	{ value: 'Advanced Diploma in Computer Programming and Analysis', label: 'Advanced Diploma in Computer Programming and Analysis' },
	{ value: 'Graduate certificate in Information Systems Business Analysis', label: 'Graduate certificate in Information Systems Business Analysis' },
	{ value: 'Advanced Diploma in Game – Programming', label: 'Advanced Diploma in Game – Programming' },
	{ value: 'Advanced Diploma in Dental Hygiene', label: 'Advanced Diploma in Dental Hygiene' },
	{ value: 'Graduate Certificate in Marketing Management – Digital Media', label: 'Graduate Certificate in Marketing Management – Digital Media' },
	{ value: 'Diploma in Video Design & Production', label: 'Diploma in Video Design & Production' },
	{ value: 'Diploma in Construction Engineering Technician', label: 'Diploma in Construction Engineering Technician' },
	{ value: 'Diploma in Mechanical Technician – CNC and Precision Machining', label: 'Diploma in Mechanical Technician – CNC and Precision Machining' },
	{ value: 'Certificate in Construction Trades Techniques', label: 'Certificate in Construction Trades Techniques' },
	{ value: 'Certificate in Dental Assisting (Levels I and II)', label: 'Certificate in Dental Assisting (Levels I and II)' },
	{ value: 'Honours Bachelor of Commerce (Culinary Management)', label: 'Honours Bachelor of Commerce (Culinary Management)' },
	{ value: 'Graduate certificate in Human Resources Management', label: 'Graduate certificate in Human Resources Management' },
	{ value: 'Certificate in Jewellery Essentials', label: 'Certificate in Jewellery Essentials' },
	{ value: 'Diploma in Fashion Techniques and Design', label: 'Diploma in Fashion Techniques and Design' },
	{ value: 'Diploma in Recreation Management in Gerontology', label: 'Diploma in Recreation Management in Gerontology' },
	{ value: 'Advanced Diploma in Hearing Instrument Specialist', label: 'Advanced Diploma in Hearing Instrument Specialist' },
	{ value: 'Advanced Diploma in Dental Technology', label: 'Advanced Diploma in Dental Technology' },
	{ value: 'Graduate certificate in Web Development - Front End Design', label: 'Graduate certificate in Web Development - Front End Design' },
	{ value: 'Graduate certificate in Marketing Management – Financial Services', label: 'Graduate certificate in Marketing Management – Financial Services' },
	{ value: 'Advanced Diploma in Computer Programmer Analyst', label: 'Advanced Diploma in Computer Programmer Analyst' },
	{ value: 'Diploma in Activation Co-ordinator/Gerontology', label: 'Diploma in Activation Co-ordinator/Gerontology' },
	{ value: 'Honours Bachelor of Business Administration (Hospitality) (Fast-Track)', label: 'Honours Bachelor of Business Administration (Hospitality) (Fast-Track)' },
	{ value: 'Graduate certificate in Financial Planning', label: 'Graduate certificate in Financial Planning' },
	{ value: 'Diploma in Acting for Media', label: 'Diploma in Acting for Media' },
	{ value: 'Certificate in Hospitality Services', label: 'Certificate in Hospitality Services' },
	{ value: 'Certificate in Gemmology', label: 'Certificate in Gemmology' },
	{ value: 'Advanced Diploma in Civil Engineering Technology', label: 'Advanced Diploma in Civil Engineering Technology' },
	{ value: 'Graduate Certificate in Food Tourism Entrepreneurship', label: 'Graduate Certificate in Food Tourism Entrepreneurship' },
	{ value: 'Diploma in Social Service Worker (fast-track)', label: 'Diploma in Social Service Worker (fast-track)' },
	{ value: 'Advanced Diploma in Business Administration – Project Management', label: 'Advanced Diploma in Business Administration – Project Management' },
	{ value: 'Advanced Diploma in Child and Youth Care', label: 'Advanced Diploma in Child and Youth Care' },
	{ value: 'Graduate Certificate in Analytics for Business Decision Making', label: 'Graduate Certificate in Analytics for Business Decision Making' },
	{ value: 'Graduate Certificate in Health Informatics', label: 'Graduate Certificate in Health Informatics' },
	{ value: 'Graduate Certificate in Advanced Manufacturing', label: 'Graduate Certificate in Advanced Manufacturing' },
	{ value: 'Graduate Certificate in Supply Chain Management', label: 'Graduate Certificate in Supply Chain Management' },
	{ value: 'Honours Bachelor of Commerce (Financial Services)', label: 'Honours Bachelor of Commerce (Financial Services)' },
	{ value: 'Diploma in Business – Accounting', label: 'Diploma in Business – Accounting' },
	{ value: 'Advanced Diploma in Business Administration – Finance', label: 'Advanced Diploma in Business Administration – Finance' },
	{ value: 'Advanced Diploma in Heating, Refrigeration, and Air Conditioning Technology', label: 'Advanced Diploma in Heating, Refrigeration, and Air Conditioning Technology' },
	{ value: 'Advanced Diploma in Construction Engineering Technology', label: 'Advanced Diploma in Construction Engineering Technology' },
	{ value: 'Graduate Certificate in Mobile Application Development and Strategy', label: 'Graduate Certificate in Mobile Application Development and Strategy' },
	{ value: 'Advanced Diploma in Business Administration – Supply Chain and Operations Management (with work experience)', label: 'Advanced Diploma in Business Administration – Supply Chain and Operations Management (with work experience)' },
	{ value: 'Certificate in Media Foundation', label: 'Certificate in Media Foundation' },
	{ value: 'Graduate Certificate in Cyber Security', label: 'Graduate Certificate in Cyber Security' },
	{ value: 'Graduate certificate in Sound Design & Production', label: 'Graduate certificate in Sound Design & Production' },
	{ value: 'Diploma in Baking and Pastry Arts Management', label: 'Diploma in Baking and Pastry Arts Management' },
	{ value: 'Diploma in Food and Beverage Management – Restaurant Management', label: 'Diploma in Food and Beverage Management – Restaurant Management' },
	{ value: 'Advanced Diploma in Business Administration – Supply Chain and Operations Management', label: 'Advanced Diploma in Business Administration – Supply Chain and Operations Management' },
	{ value: 'Diploma in Fashion Management', label: 'Diploma in Fashion Management' },
	{ value: 'Advanced Diploma in Business Administration – Finance (with work experience)', label: 'Advanced Diploma in Business Administration – Finance (with work experience)' },
	{ value: 'Diploma in Community Worker', label: 'Diploma in Community Worker' },
	{ value: 'Graduate certificate in Cloud Computing Technologies (Postgraduate)', label: 'Graduate certificate in Cloud Computing Technologies (Postgraduate)' },
	{ value: 'Graduate certificate in Advanced French Patisserie (Postgraduate)', label: 'Graduate certificate in Advanced French Patisserie (Postgraduate)' },
	{ value: 'Certificate in Culinary Skills', label: 'Certificate in Culinary Skills' },
	{ value: 'Advanced Diploma in Electromechanical Engineering Technology – Building Automation', label: 'Advanced Diploma in Electromechanical Engineering Technology – Building Automation' },
	{ value: 'Graduate Certificate in Interdisciplinary Design Strategy', label: 'Graduate Certificate in Interdisciplinary Design Strategy' },
	{ value: 'Diploma in Business – Marketing', label: 'Diploma in Business – Marketing' },
	{ value: 'Diploma in Culinary Management – Nutrition', label: 'Diploma in Culinary Management – Nutrition' },
	{ value: 'Advanced Diploma in Business Administration', label: 'Advanced Diploma in Business Administration' },
	{ value: 'Advanced Diploma in Business Administration – International Business', label: 'Advanced Diploma in Business Administration – International Business' },
	{ value: 'Advanced Diploma in Mechanical Engineering Technology – Design', label: 'Advanced Diploma in Mechanical Engineering Technology – Design' },
	{ value: 'Honours Bachelor of Digital Experience Design Program ( Co-op )', label: 'Honours Bachelor of Digital Experience Design Program ( Co-op )' },
	{ value: 'Advanced Diploma in Interaction Design', label: 'Advanced Diploma in Interaction Design' },
	{ value: 'Diploma in Health Information Management', label: 'Diploma in Health Information Management' },
	{ value: 'Certificate in Welding Techniques', label: 'Certificate in Welding Techniques' },
	{ value: 'Certificate in Dental Office Administration', label: 'Certificate in Dental Office Administration' },
	{ value: 'Graduate certificate in Career Development Practitioner', label: 'Graduate certificate in Career Development Practitioner' },
	{ value: 'Advanced Diploma in Business Administration – Project Management (with work experience)', label: 'Advanced Diploma in Business Administration – Project Management (with work experience)' },
	{ value: 'Advanced Diploma in Business Administration – Marketing', label: 'Advanced Diploma in Business Administration – Marketing' },
	{ value: 'Diploma in Fashion Business Industry', label: 'Diploma in Fashion Business Industry' },
	{ value: 'Advanced Diploma in Computer Systems Technology', label: 'Advanced Diploma in Computer Systems Technology' },
	{ value: 'Graduate certificate in Screenwriting & Narrative Design', label: 'Graduate certificate in Screenwriting & Narrative Design' },
	{ value: 'Graduate certificate in Construction Management', label: 'Graduate certificate in Construction Management' },
	{ value: 'Honours Bachelor of Technology (Construction Management)', label: 'Honours Bachelor of Technology (Construction Management)' },
	{ value: 'Certificate in Art and Design Foundation', label: 'Certificate in Art and Design Foundation' },
	{ value: 'Diploma in Culinary Management', label: 'Diploma in Culinary Management' },
	{ value: 'Advanced Diploma in Jewellery Arts', label: 'Advanced Diploma in Jewellery Arts' },
	{ value: 'Advanced Diploma in Building Renovation Technology', label: 'Advanced Diploma in Building Renovation Technology' },
	{ value: 'Pre-Health Sciences Pathway to Advanced Diplomas and Degrees', label: 'Pre-Health Sciences Pathway to Advanced Diplomas and Degrees' },
	{ value: 'Graduate certificate in Food and Nutrition Management', label: 'Graduate certificate in Food and Nutrition Management' },
	{ value: 'Certificate in Dance Performance Preparation', label: 'Certificate in Dance Performance Preparation' },
	{ value: 'Graduate certificate in International Fashion Management', label: 'Graduate certificate in International Fashion Management' },
	{ value: 'Graduate certificate in Building Information Modeling Management', label: 'Graduate certificate in Building Information Modeling Management' },
	{ value: 'Diploma in Office Administration – Health Services', label: 'Diploma in Office Administration – Health Services' },
	{ value: 'Graduate certificate in Sport and Event Marketing', label: 'Graduate certificate in Sport and Event Marketing' },
	{ value: 'Diploma in Community Worker (fast-track)', label: 'Diploma in Community Worker (fast-track)' },
	{ value: 'Diploma in Heating, Refrigeration, and Air Conditioning Technician', label: 'Diploma in Heating, Refrigeration, and Air Conditioning Technician' },
	{ value: 'Graduate Certificate in Applied A.I. Solutions Development', label: 'Graduate Certificate in Applied A.I. Solutions Development' },
	{ value: 'Advanced Diploma in Game – Art', label: 'Advanced Diploma in Game – Art' },
	{ value: 'Graduate certificate in Entrepreneurship Management', label: 'Graduate certificate in Entrepreneurship Management' },
	{ value: 'Diploma in Hospitality – Hotel Operations Management', label: 'Diploma in Hospitality – Hotel Operations Management' },
	{ value: 'Diploma in Tourism and Hospitality Management', label: 'Diploma in Tourism and Hospitality Management' },
	{ value: 'Diploma in Commercial Dance', label: 'Diploma in Commercial Dance' },
	{ value: 'Graduate Certificate in Concept Art for Entertainment', label: 'Graduate Certificate in Concept Art for Entertainment' },
	{ value: 'Graduate certificate in Design Management', label: 'Graduate certificate in Design Management' },
	{ value: 'Advanced Diploma in Child and Youth Care ( Accelerated )', label: 'Advanced Diploma in Child and Youth Care ( Accelerated )' },
	{ value: 'Advanced Diploma in Business Administration – Marketing (with work experience)', label: 'Advanced Diploma in Business Administration – Marketing (with work experience)' },
	{ value: 'Graduate certificate in Strategic Relationship Marketing', label: 'Graduate certificate in Strategic Relationship Marketing' }
];


export default GeorgeBrownCourses;