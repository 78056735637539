const QueenslandUniversityCourses = [
	{ value: "Master of Education (STEM in Education)", label: "Master of Education (STEM in Education)" },
	{ value: "Master of Engineering Management and Master of Engineering", label: "Master of Engineering Management and Master of Engineering" },
	{ value: "Bachelor of Mathematics (Applied and Computational Mathematics)", label: "Bachelor of Mathematics (Applied and Computational Mathematics)" },
	{ value: "Master of Teaching (Secondary)", label: "Master of Teaching (Secondary)" },
	{ value: "Bachelor of Fine Arts (Dance)", label: "Bachelor of Fine Arts (Dance)" },
	{ value: "Bachelor of Education (Primary)", label: "Bachelor of Education (Primary)" },
	{ value: "Bachelor of Business/ Bachelor of Fine Arts (Animations)", label: "Bachelor of Business/ Bachelor of Fine Arts (Animations)" },
	{ value: "Bachelor of Property Economics/ Bachelor of Law (Honours)", label: "Bachelor of Property Economics/ Bachelor of Law (Honours)" },
	{ value: "Bachelor of Business/Bachelor of Information Technology", label: "Bachelor of Business/Bachelor of Information Technology" },
	{ value: "Bachelor of Engineering (Honours) / Bachelor of Information Technology", label: "Bachelor of Engineering (Honours) / Bachelor of Information Technology" },
	{ value: "Bachelor of Information Technology/ Bachelor of Laws (Honours)", label: "Bachelor of Information Technology/ Bachelor of Laws (Honours)" },
	{ value: "Bachelor of Communication (Professional Communication)/ Bachelor of Nutrition Science", label: "Bachelor of Communication (Professional Communication)/ Bachelor of Nutrition Science" },
	{ value: "Bachelor of Design (Industrial Design)/Bachelor of Engineering (Honours)", label: "Bachelor of Design (Industrial Design)/Bachelor of Engineering (Honours)" },
	{ value: "Bachelor of Vision Science", label: "Bachelor of Vision Science" },
	{ value: "Master of Education", label: "Master of Education" },
	{ value: "Bachelor of Mathematics (Statistical Science)", label: "Bachelor of Mathematics (Statistical Science)" },
	{ value: "Master of Professional Engineering (Mechanical)", label: "Master of Professional Engineering (Mechanical)" },
	{ value: "Bachelor of Laws (Honours)", label: "Bachelor of Laws (Honours)" },
	{ value: "Diploma in Information Technology", label: "Diploma in Information Technology" },
	{ value: "Bachelor of Education (Secondary)/ Bachelor of Business", label: "Bachelor of Education (Secondary)/ Bachelor of Business" },
	{ value: "Bachelor of Business/Bachelor of Design (Interior Architecture)", label: "Bachelor of Business/Bachelor of Design (Interior Architecture)" },
	{ value: "Bachelor of Engineering (Honours) (Computer and Software Systems)", label: "Bachelor of Engineering (Honours) (Computer and Software Systems)" },
	{ value: "Bachelor of Business (Accountancy)", label: "Bachelor of Business (Accountancy)" },
	{ value: "Master of Education (Teaching English to Speakers of Other Languages - TESOL)", label: "Master of Education (Teaching English to Speakers of Other Languages - TESOL)" },
	{ value: "Master of Business Administration (MBA)", label: "Master of Business Administration (MBA)" },
	{ value: "Bachelor of Medical Laboratory Science", label: "Bachelor of Medical Laboratory Science" },
	{ value: "Bachelor of Communication (Professional Communication)", label: "Bachelor of Communication (Professional Communication)" },
	{ value: "Master of Professional Engineering (Electrical)", label: "Master of Professional Engineering (Electrical)" },
	{ value: "Bachelor of Business/Bachelor of Fine Arts (Visual Arts)", label: "Bachelor of Business/Bachelor of Fine Arts (Visual Arts)" },
	{ value: "Bachelor of Communication (Journalism)/ Bachelor of Justice", label: "Bachelor of Communication (Journalism)/ Bachelor of Justice" },
	{ value: "Bachelor of Communication (Professional Communication)/ Bachelor of Justice", label: "Bachelor of Communication (Professional Communication)/ Bachelor of Justice" },
	{ value: "Bachelor of Information Technology/ Bachelor of Creative Industries", label: "Bachelor of Information Technology/ Bachelor of Creative Industries" },
	{ value: "Bachelor of Pharmacy (Honours)", label: "Bachelor of Pharmacy (Honours)" },
	{ value: "Bachelor of Human Services/Bachelor of Business", label: "Bachelor of Human Services/Bachelor of Business" },
	{ value: "Bachelor of Business (Financial Planning) Honours", label: "Bachelor of Business (Financial Planning) Honours" },
	{ value: "Bachelor of Justice/ Bachelor of Laws (Honours)", label: "Bachelor of Justice/ Bachelor of Laws (Honours)" },
	{ value: "Bachelor of Design (Interior Architecture)/Bachelor of Property Economics", label: "Bachelor of Design (Interior Architecture)/Bachelor of Property Economics" },
	{ value: "Diploma in Health Science (Health Studies)", label: "Diploma in Health Science (Health Studies)" },
	{ value: "Bachelor of Business - International", label: "Bachelor of Business - International" },
	{ value: "Bachelor of Business/Bachelor of Communication (Entertainment Industries)", label: "Bachelor of Business/Bachelor of Communication (Entertainment Industries)" },
	{ value: "Bachelor of Information Technology (Information Systems)", label: "Bachelor of Information Technology (Information Systems)" },
	{ value: "Bachelor of Design - International (Interior Architecture)", label: "Bachelor of Design - International (Interior Architecture)" },
	{ value: "Bachelor of Engineering (Honours) (Electrical and Aerospace)", label: "Bachelor of Engineering (Honours) (Electrical and Aerospace)" },
	{ value: "Master of Health, Safety and Environment", label: "Master of Health, Safety and Environment" },
	{ value: "Master of Professional Engineering (Electrical and Management)", label: "Master of Professional Engineering (Electrical and Management)" },
	{ value: "Bachelor of Design (Landscape Architecture)", label: "Bachelor of Design (Landscape Architecture)" },
	{ value: "Bachelor of Fine Arts (Creative Writing)/ Bachelor of Laws (Honours)", label: "Bachelor of Fine Arts (Creative Writing)/ Bachelor of Laws (Honours)" },
	{ value: "Standard Foundation Program", label: "Standard Foundation Program" },
	{ value: "Master of Nursing - Entry to Practice", label: "Master of Nursing - Entry to Practice" },
	{ value: "Bachelor of Design(Interaction Design)/ Bachelor of Engineering (Honours)", label: "Bachelor of Design(Interaction Design)/ Bachelor of Engineering (Honours)" },
	{ value: "Bachelor of Engineering (Honours) (Mechanical)", label: "Bachelor of Engineering (Honours) (Mechanical)" },
	{ value: "Bachelor of Fine Arts (Animation)", label: "Bachelor of Fine Arts (Animation)" },
	{ value: "Bachelor of Fine Arts (Creative Writing)", label: "Bachelor of Fine Arts (Creative Writing)" },
	{ value: "Bachelor of Communication (Digital Media)/ Bachelor of Law (Honours)", label: "Bachelor of Communication (Digital Media)/ Bachelor of Law (Honours)" },
	{ value: "Bachelor of Communication (Digital Media)/ Bachelor of Information Technology", label: "Bachelor of Communication (Digital Media)/ Bachelor of Information Technology" },
	{ value: "Bachelor of Business/Bachelor of Design (Architecture)", label: "Bachelor of Business/Bachelor of Design (Architecture)" },
	{ value: "Bachelor of Engineering (Honours) (Computer and Software Systems)", label: "Bachelor of Engineering (Honours) (Computer and Software Systems)" },
	{ value: "Master of Education", label: "Master of Education" },
	{ value: "Master of Business (Professional Accounting)", label: "Master of Business (Professional Accounting)" },
	{ value: "Bachelor of Urban Development (Honours) (Urban and Regional Planning)", label: "Bachelor of Urban Development (Honours) (Urban and Regional Planning)" },
	{ value: "Bachelor of Business/Bachelor of Design (Industrial Design)", label: "Bachelor of Business/Bachelor of Design (Industrial Design)" },
	{ value: "Bachelor of Design (Interior Architecture)/Bachelor of Urban Development (Honours) (Construction Management)", label: "Bachelor of Design (Interior Architecture)/Bachelor of Urban Development (Honours) (Construction Management)" },
	{ value: "Bachelor of Engineering (Honours)/Master of Robotics and Artificial Intelligence", label: "Bachelor of Engineering (Honours)/Master of Robotics and Artificial Intelligence" },
	{ value: "Bachelor of Podiatry", label: "Bachelor of Podiatry" },
	{ value: "Bachelor of Human Services/ Bachelor of Public Health", label: "Bachelor of Human Services/ Bachelor of Public Health" },
	{ value: "Bachelor of Biomedical Science/ Bachelor of Mathematics", label: "Bachelor of Biomedical Science/ Bachelor of Mathematics" },
	{ value: "Bachelor of Human Services/ Bachelor of Justice", label: "Bachelor of Human Services/ Bachelor of Justice" },
	{ value: "Bachelor of Engineering (Honours) (Mechatronics)", label: "Bachelor of Engineering (Honours) (Mechatronics)" },
	{ value: "Master of Project Management", label: "Master of Project Management" },
	{ value: "Master of Advance Practice Nursing", label: "Master of Advance Practice Nursing" },
	{ value: "Bachelor of Business / Bachelor of Communication (Digital Media)", label: "Bachelor of Business / Bachelor of Communication (Digital Media)" },
	{ value: "Bachelor of Design (Landscape Architecture)/Bachelor of Engineering (Honours)", label: "Bachelor of Design (Landscape Architecture)/Bachelor of Engineering (Honours)" },
	{ value: "Bachelor of Communication (Journalism)/ Bachelor of Law (Honours)", label: "Bachelor of Communication (Journalism)/ Bachelor of Law (Honours)" },
	{ value: "Bachelor of Business/Bachelor of Creative Industries", label: "Bachelor of Business/Bachelor of Creative Industries" },
	{ value: "Bachelor of Medical Imaging (Honours)", label: "Bachelor of Medical Imaging (Honours)" },
	{ value: "Bachelor of Communication (Professional Communication)/ Bachelor of Public Health", label: "Bachelor of Communication (Professional Communication)/ Bachelor of Public Health" },
	{ value: "Bachelor of Communication (Professional Communication)/ Bachelor of Science", label: "Bachelor of Communication (Professional Communication)/ Bachelor of Science" },
	{ value: "Master of Education (Inclusive Education)", label: "Master of Education (Inclusive Education)" },
	{ value: "Bachelor of Fine Arts (Drama)", label: "Bachelor of Fine Arts (Drama)" },
	{ value: "Bachelor of Communication (Entertainment Industries)", label: "Bachelor of Communication (Entertainment Industries)" },
	{ value: "English for Academic Purposes 2 Standard", label: "English for Academic Purposes 2 Standard" },
	{ value: "Bachelor of Business (Public Relations)", label: "Bachelor of Business (Public Relations)" },
	{ value: "Master of Project Management", label: "Master of Project Management" },
	{ value: "Bachelor of Fine Arts (Dance Performance)", label: "Bachelor of Fine Arts (Dance Performance)" },
	{ value: "Bachelor of Sport and Exercise Science", label: "Bachelor of Sport and Exercise Science" },
	{ value: "Diploma in Health Science (Nursing)", label: "Diploma in Health Science (Nursing)" },
	{ value: "Bachelor of Games And Interactive Environments/ Bachelor of Mathematics", label: "Bachelor of Games And Interactive Environments/ Bachelor of Mathematics" },
	{ value: "Bachelor of InformationTechnology/ Bachelor of Mathematics", label: "Bachelor of InformationTechnology/ Bachelor of Mathematics" },
	{ value: "Bachelor of Business (Honours) (Philanthropy and Nonprofit Studies)", label: "Bachelor of Business (Honours) (Philanthropy and Nonprofit Studies)" },
	{ value: "Bachelor of Business (Economics)", label: "Bachelor of Business (Economics)" },
	{ value: "Bachelor of Business (Human Resource Management)", label: "Bachelor of Business (Human Resource Management)" },
	{ value: "Bachelor of Business (Management)", label: "Bachelor of Business (Management)" },
	{ value: "Bachelor of Education (Early Childhood)", label: "Bachelor of Education (Early Childhood)" },
	{ value: "Bachelor of Creative Industries/Bachelor of Laws (Honours)", label: "Bachelor of Creative Industries/Bachelor of Laws (Honours)" },
	{ value: "Bachelor of Data Science", label: "Bachelor of Data Science" },
	{ value: "Bachelor of Communication (Journalism)/ Bachelor of Science", label: "Bachelor of Communication (Journalism)/ Bachelor of Science" },
	{ value: "Bachelor of Nutrition & Dietetics (Honours)", label: "Bachelor of Nutrition & Dietetics (Honours)" },
	{ value: "Bachelor of Communication (Journalism)", label: "Bachelor of Communication (Journalism)" },
	{ value: "Bachelor of Behavioural Science (Psychology)/Bachelor of Justice", label: "Bachelor of Behavioural Science (Psychology)/Bachelor of Justice" },
	{ value: "Bachelor of Urban Development (Honours) (Quantity Surveying and Cost Engineering)", label: "Bachelor of Urban Development (Honours) (Quantity Surveying and Cost Engineering)" },
	{ value: "Bachelor of Design - International (Architecture)", label: "Bachelor of Design - International (Architecture)" },
	{ value: "Bachelor of Education (Secondary)", label: "Bachelor of Education (Secondary)" },
	{ value: "Master of Public Health", label: "Master of Public Health" },
	{ value: "Bachelor of Laws (Honours) - Graduate Entry", label: "Bachelor of Laws (Honours) - Graduate Entry" },
	{ value: "Diploma in Creative Industries", label: "Diploma in Creative Industries" },
	{ value: "Bachelor of Design (Architecture)/ Bachelor of Engineering (Honours)", label: "Bachelor of Design (Architecture)/ Bachelor of Engineering (Honours)" },
	{ value: "Bachelor of Design (Architecture)/Bachelor of Urban Development (Honours) (Construction Management)", label: "Bachelor of Design (Architecture)/Bachelor of Urban Development (Honours) (Construction Management)" },
	{ value: "Bachelor of Business/Bachelor of Laws (Honours)", label: "Bachelor of Business/Bachelor of Laws (Honours)" },
	{ value: "Bachelor of Education (Secondary)/Bachelor of Fine Arts (Drama)", label: "Bachelor of Education (Secondary)/Bachelor of Fine Arts (Drama)" },
	{ value: "Master of Information Technology", label: "Master of Information Technology" },
	{ value: "Bachelor of Engineering (Honours) (Civil)", label: "Bachelor of Engineering (Honours) (Civil)" },
	{ value: "Bachelor of Science (Environmental Science)", label: "Bachelor of Science (Environmental Science)" },
	{ value: "Bachelor of Biomedical Science", label: "Bachelor of Biomedical Science" },
	{ value: "Bachelor of Business/Bachelor of Design (Visual Communication)", label: "Bachelor of Business/Bachelor of Design (Visual Communication)" },
	{ value: "Bachelor of Business/ Bachelor of Engineering (Honours)", label: "Bachelor of Business/ Bachelor of Engineering (Honours)" },
	{ value: "Bachelor of Property Economics/Bachelor of Business", label: "Bachelor of Property Economics/Bachelor of Business" },
	{ value: "Diploma in Engineering", label: "Diploma in Engineering" },
	{ value: "Bachelor of Business/Bachelor of Fine Arts (Drama)", label: "Bachelor of Business/Bachelor of Fine Arts (Drama)" },
	{ value: "Bachelor of Education (Secondary)/Bachelor of Fine Arts (Dance)", label: "Bachelor of Education (Secondary)/Bachelor of Fine Arts (Dance)" },
	{ value: "Bachelor of Design - International (Landscape Architecture)", label: "Bachelor of Design - International (Landscape Architecture)" },
	{ value: "Bachelor of Behavioural Science (Psychology)/Bachelor of Social Work", label: "Bachelor of Behavioural Science (Psychology)/Bachelor of Social Work" },
	{ value: "Bachelor of Fine Arts (Film, Screen and New Media)", label: "Bachelor of Fine Arts (Film, Screen and New Media)" },
	{ value: "Bachelor of Behavioural Science (Psychology)/Bachelor of Business", label: "Bachelor of Behavioural Science (Psychology)/Bachelor of Business" },
	{ value: "Master of Business (Applied Finance)", label: "Master of Business (Applied Finance)" },
	{ value: "Bachelor of Business/Bachelor of Communication (Journalism)", label: "Bachelor of Business/Bachelor of Communication (Journalism)" },
	{ value: "Bachelor of Business/Bachelor of Justice", label: "Bachelor of Business/Bachelor of Justice" },
	{ value: "Bachelor of Science Advanced (Honours)", label: "Bachelor of Science Advanced (Honours)" },
	{ value: "Bachelor of Engineering (Honours) (Electrical)", label: "Bachelor of Engineering (Honours) (Electrical)" },
	{ value: "Bachelor of Business (Finance)", label: "Bachelor of Business (Finance)" },
	{ value: "Bachelor of Business (International Business)", label: "Bachelor of Business (International Business)" },
	{ value: "Master of Architecture", label: "Master of Architecture" },
	{ value: "Bachelor of Human Services", label: "Bachelor of Human Services" },
	{ value: "Bachelor of Behavioural Science (Psychology)", label: "Bachelor of Behavioural Science (Psychology)" },
	{ value: "Bachelor of Social Work", label: "Bachelor of Social Work" },
	{ value: "Bachelor of Behavioural Science (Psychology)/Bachelor of Justice", label: "Bachelor of Behavioural Science (Psychology)/Bachelor of Justice" },
	{ value: "Bachelor of Business (Finance) Honours", label: "Bachelor of Business (Finance) Honours" },
	{ value: "Bachelor of Business (Dean's Honours)", label: "Bachelor of Business (Dean's Honours)" },
	{ value: "Bachelor of Communication (Advertising and Public Relations)", label: "Bachelor of Communication (Advertising and Public Relations)" },
	{ value: "Bachelor of Biomedical Science/ Bachelor of Business", label: "Bachelor of Biomedical Science/ Bachelor of Business" },
	{ value: "Bachelor of Behavioural Science (Psychology)/Bachelor of Law (Honours)", label: "Bachelor of Behavioural Science (Psychology)/Bachelor of Law (Honours)" },
	{ value: "Bachelor of Science (Honours)", label: "Bachelor of Science (Honours)" },
	{ value: "Bachelor of Design (Industrial Design)/ Bachelor of Laws (Honours)", label: "Bachelor of Design (Industrial Design)/ Bachelor of Laws (Honours)" },
	{ value: "Bachelor of Design - International (Industrial Design)", label: "Bachelor of Design - International (Industrial Design)" },
	{ value: "Bachelor of Behavioural Science (Psychology)", label: "Bachelor of Behavioural Science (Psychology)" },
	{ value: "Bachelor of Business/Bachelor of Games And Interactive Environments", label: "Bachelor of Business/Bachelor of Games And Interactive Environments" },
	{ value: "Bachelor of Fine Arts (Technical Production)", label: "Bachelor of Fine Arts (Technical Production)" },
	{ value: "Bachelor of Nursing", label: "Bachelor of Nursing" },
	{ value: "Master of Psychology (Educational and Developmental)", label: "Master of Psychology (Educational and Developmental)" },
	{ value: "Master of Teaching (Primary)", label: "Master of Teaching (Primary)" },
	{ value: "Master of Business (Integrated Marketing Communication)", label: "Master of Business (Integrated Marketing Communication)" },
	{ value: "Bachelor of Engineering (Honours)/ Bachelor of Mathematics", label: "Bachelor of Engineering (Honours)/ Bachelor of Mathematics" },
	{ value: "Bachelor of Biomedical Science/ Bachelor of Law (Honour)", label: "Bachelor of Biomedical Science/ Bachelor of Law (Honour)" },
	{ value: "Bachelor of Education (Secondary)/Bachelor of Fine Arts (Creative Writing)", label: "Bachelor of Education (Secondary)/Bachelor of Fine Arts (Creative Writing)" },
	{ value: "Bachelor of Fine Arts (Music)", label: "Bachelor of Fine Arts (Music)" },
	{ value: "Bachelor of Science (Earth Science)", label: "Bachelor of Science (Earth Science)" },
	{ value: "Bachelor of Science (Physics)", label: "Bachelor of Science (Physics)" },
	{ value: "Master of Psychology(Educational and Developmental)", label: "Master of Psychology(Educational and Developmental)" },
	{ value: "Bachelor of Podiatry (Graduate Entry)", label: "Bachelor of Podiatry (Graduate Entry)" },
	{ value: "Bachelor of Games and Interactive Environments (Software Technologies)", label: "Bachelor of Games and Interactive Environments (Software Technologies)" },
	{ value: "Master of Robotics and Artificial Intelligence", label: "Master of Robotics and Artificial Intelligence" },
	{ value: "Master of Engineering Management", label: "Master of Engineering Management" },
	{ value: "Diploma in Business", label: "Diploma in Business" },
	{ value: "Bachelor of Business/Bachelor of Design (Fashion)", label: "Bachelor of Business/Bachelor of Design (Fashion)" },
	{ value: "Bachelor of Design (Interaction Design)/ Bachelor of Information Technology", label: "Bachelor of Design (Interaction Design)/ Bachelor of Information Technology" },
	{ value: "Master of Business (Philanthrophy and Non Profit Studies)", label: "Master of Business (Philanthrophy and Non Profit Studies)" },
	{ value: "Master of Business (Human Resource Management)", label: "Master of Business (Human Resource Management)" },
	{ value: "Bachelor of Science/ Bachelor of Games And Interactive Environments", label: "Bachelor of Science/ Bachelor of Games And Interactive Environments" },
	{ value: "Bachelor of Mathematics (Honours)", label: "Bachelor of Mathematics (Honours)" },
	{ value: "Bachelor of Business (Advertising)", label: "Bachelor of Business (Advertising)" },
	{ value: "Bachelor of Design - International (Visual Communication)", label: "Bachelor of Design - International (Visual Communication)" },
	{ value: "Bachelor of Engineering (Honours) (Chemical Process)", label: "Bachelor of Engineering (Honours) (Chemical Process)" },
	{ value: "Bachelor of Business/Bachelor of Mathematics", label: "Bachelor of Business/Bachelor of Mathematics" },
	{ value: "Master of Business (International Business)", label: "Master of Business (International Business)" },
	{ value: "Bachelor of Business/Bachelor of Design (Interaction Design)", label: "Bachelor of Business/Bachelor of Design (Interaction Design)" },
	{ value: "Bachelor of Education (Secondary)/Bachelor of Fine Arts (Visual Arts)", label: "Bachelor of Education (Secondary)/Bachelor of Fine Arts (Visual Arts)" },
	{ value: "Bachelor of Science/Bachelor of Laws (Honours)", label: "Bachelor of Science/Bachelor of Laws (Honours)" },
	{ value: "Bachelor of Creative Industries/ Bachelor of Human Services", label: "Bachelor of Creative Industries/ Bachelor of Human Services" },
	{ value: "Bachelor of Nursing/Bachelor of Behavioural Science (Psychology)", label: "Bachelor of Nursing/Bachelor of Behavioural Science (Psychology)" },
	{ value: "Bachelor of Communication", label: "Bachelor of Communication" },
	{ value: "Bachelor of Science/ Bachelor of Information Technology", label: "Bachelor of Science/ Bachelor of Information Technology" },
	{ value: "Bachelor of Mathematics (Optional Research)", label: "Bachelor of Mathematics (Optional Research)" },
	{ value: "Bachelor of Education (Secondary)/Bachelor of Information Technology", label: "Bachelor of Education (Secondary)/Bachelor of Information Technology" },
	{ value: "Bachelor of Design (Architecture)", label: "Bachelor of Design (Architecture)" },
	{ value: "Bachelor of Fine Arts (Film, Screen and New Media)/ Bachelor of Laws (Honours)", label: "Bachelor of Fine Arts (Film, Screen and New Media)/ Bachelor of Laws (Honours)" },
	{ value: "Master of Education (General Studies)", label: "Master of Education (General Studies)" },
	{ value: "Master of Digital Communication", label: "Master of Digital Communication" },
	{ value: "Master of Business (Public Relations)", label: "Master of Business (Public Relations)" },
	{ value: "Bachelor of Business/Bachelor of Fine Arts (Creative Writing)", label: "Bachelor of Business/Bachelor of Fine Arts (Creative Writing)" },
	{ value: "Bachelor of Design (Landscape Architecture)/Bachelor of Urban Development (Honours) (Urban and Regional Planning)", label: "Bachelor of Design (Landscape Architecture)/Bachelor of Urban Development (Honours) (Urban and Regional Planning)" },
	{ value: "Bachelor of Science/Bachelor of Business", label: "Bachelor of Science/Bachelor of Business" },
	{ value: "Bachelor of Design - International (Fashion)", label: "Bachelor of Design - International (Fashion)" },
	{ value: "Bachelor of Design(Interaction Design)/ Bachelor of Information Technology", label: "Bachelor of Design(Interaction Design)/ Bachelor of Information Technology" },
	{ value: "Bachelor of Design (Interior Architecture)", label: "Bachelor of Design (Interior Architecture)" },
	{ value: "Master of Data Analytics", label: "Master of Data Analytics" },
	{ value: "Bachelor of Business (Economics) Honours", label: "Bachelor of Business (Economics) Honours" },
	{ value: "Bachelor of Communication (Digital Media)/ Bachelor of Justice", label: "Bachelor of Communication (Digital Media)/ Bachelor of Justice" },
	{ value: "Master of Education (Leadership and Management)", label: "Master of Education (Leadership and Management)" },
	{ value: "Bachelor of Fine Arts (Visual Arts)", label: "Bachelor of Fine Arts (Visual Arts)" },
	{ value: "Bachelor of Games and Interactive Environments (Animation)", label: "Bachelor of Games and Interactive Environments (Animation)" },
	{ value: "Bachelor of Biomedical Science", label: "Bachelor of Biomedical Science" },
	{ value: "Master of Clinical Psychology", label: "Master of Clinical Psychology" },
	{ value: "Bachelor of Business/Bachelor of Fine Arts (Film, Screen and New Media)", label: "Bachelor of Business/Bachelor of Fine Arts (Film, Screen and New Media)" },
	{ value: "Bachelor of Nursing/Bachelor of Public Health", label: "Bachelor of Nursing/Bachelor of Public Health" },
	{ value: "Bachelor of Design (Architecture)/Bachelor of Property Economics", label: "Bachelor of Design (Architecture)/Bachelor of Property Economics" },
	{ value: "Bachelor of Games and Interactive Environments (Game Design)", label: "Bachelor of Games and Interactive Environments (Game Design)" },
	{ value: "Bachelor of Property Economics", label: "Bachelor of Property Economics" },
	{ value: "Bachelor of Urban Development (Honours) (Construction Management)", label: "Bachelor of Urban Development (Honours) (Construction Management)" },
	{ value: "Bachelor of Design (Honours) (Architectural Studies)", label: "Bachelor of Design (Honours) (Architectural Studies)" },
	{ value: "Bachelor of Fine Arts (Acting)", label: "Bachelor of Fine Arts (Acting)" },
	{ value: "Bachelor of Health Information Management", label: "Bachelor of Health Information Management" },
	{ value: "Master of Teaching (Early Childhood)", label: "Master of Teaching (Early Childhood)" },
	{ value: "Bachelor of Engineering (Honours)/Bachelor of Science", label: "Bachelor of Engineering (Honours)/Bachelor of Science" },
	{ value: "Bachelor of Communication (Entertainment Industries)/ Bachelor of Law (Honours)", label: "Bachelor of Communication (Entertainment Industries)/ Bachelor of Law (Honours)" },
	{ value: "Bachelor of Public Health", label: "Bachelor of Public Health" },
	{ value: "Master of Optometry", label: "Master of Optometry" },
	{ value: "Bachelor of Business/Bachelor of Communication (Professional Communication)", label: "Bachelor of Business/Bachelor of Communication (Professional Communication)" },
	{ value: "Bachelor of Design (Landscape Architecture)/Bachelor of Science", label: "Bachelor of Design (Landscape Architecture)/Bachelor of Science" },
	{ value: "Bachelor of Business (Accountancy) Honours", label: "Bachelor of Business (Accountancy) Honours" },
	{ value: "Bachelor of Education (Secondary)/Bachelor of Fine Arts (Music)", label: "Bachelor of Education (Secondary)/Bachelor of Fine Arts (Music)" },
	{ value: "Bachelor of Design - International (Interaction Design)", label: "Bachelor of Design - International (Interaction Design)" },
	{ value: "Master of Business Process Management", label: "Master of Business Process Management" },
	{ value: "Bachelor of Clinical Exercise Physiology", label: "Bachelor of Clinical Exercise Physiology" },
	{ value: "Bachelor of Information Technology (Computer Science)", label: "Bachelor of Information Technology (Computer Science)" },
	{ value: "Bachelor of Justice", label: "Bachelor of Justice" },
	{ value: "Bachelor of Business (Marketing)", label: "Bachelor of Business (Marketing)" },
	{ value: "Master of Engineering Management and Master of Project Management", label: "Master of Engineering Management and Master of Project Management" },
	{ value: "Bachelor of Business (Financial Planning)", label: "Bachelor of Business (Financial Planning)" },
	{ value: "Bachelor of Nutrition Science", label: "Bachelor of Nutrition Science" },
	{ value: "Bachelor of Communication (Digital Media)", label: "Bachelor of Communication (Digital Media)" },
	{ value: "Master of Social Work - Qualifying", label: "Master of Social Work - Qualifying" },
	{ value: "Master of Professional Engineering (Mechanical and Management)", label: "Master of Professional Engineering (Mechanical and Management)" },
	{ value: "Bachelor of Business/Bachelor of Communication (Creative Industries)", label: "Bachelor of Business/Bachelor of Communication (Creative Industries)" },
	{ value: "Bachelor of Education (Secondary)/Bachelor of Fine Arts (Film, Screen and New Media)", label: "Bachelor of Education (Secondary)/Bachelor of Fine Arts (Film, Screen and New Media)" },
	{ value: "Bachelor of Creative Industries", label: "Bachelor of Creative Industries" },
	{ value: "Bachelor of Design (Industrial Design)/Bachelor of Law (Honours)", label: "Bachelor of Design (Industrial Design)/Bachelor of Law (Honours)" },
	{ value: "Bachelor of Engineering (Honours) (Medical)", label: "Bachelor of Engineering (Honours) (Medical)" },
	{ value: "Bachelor of Science (Biological Sciences)", label: "Bachelor of Science (Biological Sciences)" },
	{ value: "Bachelor of Science (Chemistry)", label: "Bachelor of Science (Chemistry)" },
	{ value: "Master of Philosophy", label: "Master of Philosophy" },
	{ value: "Master of Engineering and Master of Project Management", label: "Master of Engineering and Master of Project Management" },
	{ value: "Master of Professional Engineering (Civil)", label: "Master of Professional Engineering (Civil)" },
	{ value: "Master of Counselling", label: "Master of Counselling" },
	{ value: "Master of Business (Marketing)", label: "Master of Business (Marketing)" },
	{ value: "Master of Business (Strategic Advertising)", label: "Master of Business (Strategic Advertising)" },
	{ value: "Bachelor of Business/Bachelor of Design (Landscape Architecture)", label: "Bachelor of Business/Bachelor of Design (Landscape Architecture)" }
];

export default QueenslandUniversityCourses;
