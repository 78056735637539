const AcadiaCourses = [
	{ value: 'Bachelor of Arts - French', label: 'Bachelor of Arts - French' },
	{ value: 'Bachelor of Computer Science', label: 'Bachelor of Computer Science' },
	{ value: 'Bachelor of Arts - Psychology', label: 'Bachelor of Arts - Psychology' },
	{ value: 'Bachelor of Arts - Economics', label: 'Bachelor of Arts - Economics' },
	{ value: 'Bachelor of Kinesiology', label: 'Bachelor of Kinesiology' },
	{ value: 'Bachelor of Arts - Environmental and Sustainability Studies', label: 'Bachelor of Arts - Environmental and Sustainability Studies' },
	{ value: 'Bachelor of Science - Nutrition', label: 'Bachelor of Science - Nutrition' },
	{ value: 'Bachelor of Science - Biology', label: 'Bachelor of Science - Biology' },
	{ value: 'Bachelor of Community Development', label: 'Bachelor of Community Development' },
	{ value: 'Bachelor of Arts - German', label: 'Bachelor of Arts - German' },
	{ value: 'Bachelor of Science - Geology', label: 'Bachelor of Science - Geology' },
	{ value: 'Bachelor of Arts - Sociology', label: 'Bachelor of Arts - Sociology' },
	{ value: 'Bachelor of Arts - Canadian Studies', label: 'Bachelor of Arts - Canadian Studies' },
	{ value: 'Bachelor of Science - Mathematics and Statistics', label: 'Bachelor of Science - Mathematics and Statistics' },
	{ value: 'Bachelor of Science - Environmental Science', label: 'Bachelor of Science - Environmental Science' },
	{ value: 'Bachelor of Science - Environmental Geoscience', label: 'Bachelor of Science - Environmental Geoscience' },
	{ value: 'Bachelor of Arts - Philosophy', label: 'Bachelor of Arts - Philosophy' },
	{ value: 'Bachelor of Arts - Theatre', label: 'Bachelor of Arts - Theatre' },
	{ value: 'Certificate in Applied Science (Engineering 2yrs)', label: 'Certificate in Applied Science (Engineering 2yrs)' },
	{ value: 'Bachelor of Science - Chemistry', label: 'Bachelor of Science - Chemistry' },
	{ value: 'Bachelor of Music', label: 'Bachelor of Music' },
	{ value: 'Bachelor of Applied Science (Engineering 3 yrs)', label: 'Bachelor of Applied Science (Engineering 3 yrs)' },
	{ value: 'Bachelor of Applied Computer Science', label: 'Bachelor of Applied Computer Science' },
	{ value: 'Bachelor of Arts - History', label: 'Bachelor of Arts - History' },
	{ value: 'Bachelor of Arts - Classic', label: 'Bachelor of Arts - Classic' },
	{ value: 'Bachelor of Business Administration', label: 'Bachelor of Business Administration' },
	{ value: 'Bachelor of Arts - Law and Society', label: 'Bachelor of Arts - Law and Society' },
	{ value: 'Bachelor of Arts - English', label: 'Bachelor of Arts - English' },
	{ value: 'Bachelor of Community Development in Environmental and Sustainability', label: 'Bachelor of Community Development in Environmental and Sustainability' },
	{ value: 'Bachelor of Arts - Mathematics and Statistics', label: 'Bachelor of Arts - Mathematics and Statistics' },
	{ value: 'Bachelor of Science - Physics', label: 'Bachelor of Science - Physics' },
	{ value: 'Bachelor of Arts - Women\'s and Gender Studies', label: 'Bachelor of Arts - Women\'s and Gender Studies' },
	{ value: 'Bachelor of Science - Economics', label: 'Bachelor of Science - Economics' },
	{ value: 'Bachelor of Arts - Politics', label: 'Bachelor of Arts - Politics' },
	{ value: 'Bachelor of Science - Psychology', label: 'Bachelor of Science - Psychology' },
	{ value: 'Bachelor of Music Therapy', label: 'Bachelor of Music Therapy' }
];

export default AcadiaCourses;
