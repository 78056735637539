// Australia
import AlanaKayeCourses from './courses/institutes/Australia/AlanaKayeCourses';
import AlphacrucisCourses from './courses/institutes/Australia/AlphacrucisCourses';
import GriffithUniversityCourses from './courses/institutes/Australia/GriffithUniversityCourses';
import GriffithCollegeCourses from './courses/institutes/Australia/GriffithCollegeCourses';
import AustralianIdealCourses from './courses/institutes/Australia/AustralianIdealCourses';
import CharlesDarwinCourses from './courses/institutes/Australia/CharlesDarwinCourses';
import DeakinCourses from './courses/institutes/Australia/DeakinCourses';
import FlindersCourses from './courses/institutes/Australia/FlindersCourses';
import JamesCookCourses from './courses/institutes/Australia/JamesCookCourses';
import LaTrobeCourses from './courses/institutes/Australia/LaTrobeCourses';
import LeCordonBleuCourses from './courses/institutes/Australia/LeCordonBleuCourses';
import LeadCollegeCourses from './courses/institutes/Australia/LeadCollegeCourses';
import QueenslandUniversityCourses from './courses/institutes/Australia/QueenslandUniversityCourses';
import RMITCourses from './courses/institutes/Australia/RMITCourses';

// France
import BurgundySchoolBusinessCourses from './courses/institutes/France/BurgundySchoolBusinessCourses';
import CollegeDeParisCourses from './courses/institutes/France/CollegeDeParisCourses';
import EcoleConteCourses from './courses/institutes/France/EcoleConteCourses';
import ECEMABusinessCourses from './courses/institutes/France/ECEMABusinessCourses';
import CYTechGrandeCourses from './courses/institutes/France/CYTechGrandeCourses';
import EMACourses from './courses/institutes/France/EMACourses';
import EPITACourses from './courses/institutes/France/EPITACourses';
import ESSCACourses from './courses/institutes/France/ESSCACourses';
import IDRACCourses from './courses/institutes/France/IDRACCourses';
import LeCordonBleuFranceCourses from './courses/institutes/France/LeCordonBleuFranceCourses';
import SkemaBusinessCourses from './courses/institutes/France/SkemaBusinessCourses';

// Canada
import UniverisityNiagaraFallsCourses from './courses/institutes/Canada/UniverisityNiagaraFallsCourses';
import UniCanadaWestCourses from './courses/institutes/Canada/UniCanadaWestCourses';
import QueensUniversityCourses from './courses/institutes/Canada/QueensUniversityCourses';
import UniversityVictoriaCourses from './courses/institutes/Canada/UniversityVictoriaCourses';
import UniversityGuelphCourses from './courses/institutes/Canada/UniversityGuelphCourses';
import BrunswickCourses from './courses/institutes/Canada/BrunswickCourses';
import ReginaCourses from './courses/institutes/Canada/ReginaCourses';
import LakeheadCourses from './courses/institutes/Canada/LakeheadCourses';
import AcadiaCourses from './courses/institutes/Canada/AcadiaCourses';
import BowValleyCourses from './courses/institutes/Canada/BowValleyCourses';
import CambrianCourses from './courses/institutes/Canada/CambrianCourses';
import CapeBretonCourses from './courses/institutes/Canada/CapeBretonCourses';
import CapilanoCourses from './courses/institutes/Canada/CapilanoCourses';
import ConestogaCourses from './courses/institutes/Canada/ConestogaCourses';
import DurhamCourses from './courses/institutes/Canada/DurhamCourses';
import FanshaweCourses from './courses/institutes/Canada/FanshaweCourses';
import GeorgeBrownCourses from './courses/institutes/Canada/GeorgeBrownCourses';
import LakelandCourses from './courses/institutes/Canada/LakelandCourses';
import WinnipegCourses from './courses/institutes/Canada/WinnipegCourses';
import SheridanCourses from './courses/institutes/Canada/SheridanCourses';

// Germany
import AbertayGermanyCourses from './courses/institutes/Germany/AbertayGermanyCourses';
import EBSGerCourses from './courses/institutes/Germany/EBSGerCourses';
import EUBusinessCourses from './courses/institutes/Germany/EUBusinessCourses';
import GISMABusinessCourses from './courses/institutes/Germany/GISMABusinessCourses';
import ISMGerCourses from './courses/institutes/Germany/ISMGerCourses';
import IUIntCourses from './courses/institutes/Germany/IUIntCourses';
import UniEuropeAppliedSciencesCourses from './courses/institutes/Germany/UniEuropeAppliedSciencesCourses';
import SRHBerlinCourses from './courses/institutes/Germany/SRHBerlinCourses';

// Ireland
import AmericanCollegeDublinCourses from './courses/institutes/Ireland/AmericanCollegeDublinCourses';
import AtlanticTechCourses from './courses/institutes/Ireland/AtlanticTechCourses';
import DublinBusinessSchoolCourses from './courses/institutes/Ireland/DublinBusinessSchoolCourses';
import DundalkCourses from './courses/institutes/Ireland/DundalkCourses';
import MaynoothCourses from './courses/institutes/Ireland/MaynoothCourses';
import ShannonCourses from './courses/institutes/Ireland/ShannonCourses';
import TrinityDublinCourses from './courses/institutes/Ireland/TrinityDublinCourses';
import UniCollegeCorkCourses from './courses/institutes/Ireland/UniCollegeCorkCourses';

// Latvia
import TransportTelecommunicationCourses from './courses/institutes/Latvia/TransportTelecommunicationCourses';

// Slovania
import SwarogCourses from './courses/institutes/Slovenia/SvarogCourses';
import IBSCourses from './courses/institutes/Slovenia/IBSCourses';
import MariborCourses from './courses/institutes/Slovenia/MariborCourses';

// New zealand
import CrimsonGlobalCourses from './courses/institutes/Newzealand/CrimsonGlobalCourses';
import AGIEducationCourses from './courses/institutes/Newzealand/AGIEducationCourses';
import WhitecliffeCourses from './courses/institutes/Newzealand/WhitecliffeCourses';
import LeCordonBlueNZCourses from './courses/institutes/Newzealand/LeCordonBlueNZCourses';
import EliteSchoolBeautySpaCourses from './courses/institutes/Newzealand/EliteSchoolBeautySpaCourses';
import CutAboveAcademyCourses from './courses/institutes/Newzealand/CutAboveAcademyCourses';
import UniversityCanterburyCourses from './courses/institutes/Newzealand/UniversityCanterburyCourses';
import AucklandUniversityTechCourses from './courses/institutes/Newzealand/AucklandUniversityTechCourses';
import OtagoCourses from './courses/institutes/Newzealand/OtagoCourses';
import UniversityAucklandCourses from './courses/institutes/Newzealand/UniversityAucklandCourses';
import NzManagementAcadCourses from './courses/institutes/Newzealand/NzManagementAcadCourses';

// UK
import AbertayUniversityCourses from './courses/institutes/UK/AbertayUniversityCourses';
import AngliaRuskinCourses from './courses/institutes/UK/AngliaRuskinCourses';
import BangorUniversityCourses from './courses/institutes/UK/BangorUniversityCourses';
import BrunelUniversityCourses from './courses/institutes/UK/BrunelUniversityCourses';
import CoventryUniversityCourses from './courses/institutes/UK/CoventryUniversityCourses';
import QueenBelfastCourses from './courses/institutes/UK/QueenBelfastCourses';
import BedfordshireCourses from './courses/institutes/UK/BedfordshireCourses';
import BoltonCourses from './courses/institutes/UK/BoltonCourses';

const coursesByInstitute = {
	// Australia
	"Alana Kaye College Darwin": AlanaKayeCourses,
	"Alphacrucis University College Sydney": AlphacrucisCourses,
	"Griffith University": GriffithUniversityCourses,
	"Griffith College": GriffithCollegeCourses,
	"Australian Ideal College": AustralianIdealCourses,
	"Charles Darwin International Pathway College": CharlesDarwinCourses,
	"Deakin University": DeakinCourses,
	"Flinders University": FlindersCourses,
	"James Cook University": JamesCookCourses,
	"La Trobe University": LaTrobeCourses,
	"Le Cordon Bleu Australia": LeCordonBleuCourses,
	"Lead College Pty Ltd": LeadCollegeCourses,
	"Queensland University of Technology": QueenslandUniversityCourses,
	"RMIT University, Melbourne": RMITCourses,
	// Canada
	"University of Niagara Falls, Canada": UniverisityNiagaraFallsCourses,
	"University Canada West": UniCanadaWestCourses,
	"Queens University, Canada": QueensUniversityCourses,
	"University of Victoria": UniversityVictoriaCourses,
	"University of Guelph": UniversityGuelphCourses,
	"University of New Brunswick": BrunswickCourses,
	"University of Regina": ReginaCourses,
	"Lakehead University, Thunder Bay": LakeheadCourses,
	"Acadia University": AcadiaCourses,
	"Bow Valley College, Calgary, Alberta": BowValleyCourses,
	"Cambrian College": CambrianCourses,
	"Cape Breton University": CapeBretonCourses,
	"Capilano University": CapilanoCourses,
	"Conestoga College - Kitchener (Downtown) Campus": ConestogaCourses,
	"Durham College": DurhamCourses,
	"Fanshawe College": FanshaweCourses,
	"George Brown College": GeorgeBrownCourses,
	"Lakeland College": LakelandCourses,
	"University of Winnipeg": WinnipegCourses,
	"Sheridan College": SheridanCourses,
	// France
	"Burgundy School of Business": BurgundySchoolBusinessCourses,
	"College de paris (Paris)": CollegeDeParisCourses,
	"College de Paris Ecole Conte (Paris)": EcoleConteCourses,
	"College De Paris ECEMA Business School (Lyon)": ECEMABusinessCourses,
	"CY Tech Grande Ecole of Sciences, Engineering, Economics and Management": CYTechGrandeCourses,
	"Ecole de Management Applique (EMA)": EMACourses,
	"EPITA- Graduate School of Computer Science": EPITACourses,
	"ESSCA School of Management": ESSCACourses,
	"IDRAC Business School": IDRACCourses,
	"Le Cordon Bleu Paris": LeCordonBleuFranceCourses,
	"Skema Business School": SkemaBusinessCourses,
	// Germany
	"Abertay University": AbertayGermanyCourses,
	"EBS University": EBSGerCourses,
	"EU Business School Munich": EUBusinessCourses,
	"GISMA Business School": GISMABusinessCourses,
	"International School of Management (ISM)": ISMGerCourses,
	"IU International University of Applied Sciences": IUIntCourses,
	"University of Europe for Applied Sciences": UniEuropeAppliedSciencesCourses,
	"SRH Berlin University of Applied Sciences": SRHBerlinCourses,
	// Ireland
	"American College Dublin, Dublin": AmericanCollegeDublinCourses,
	"Atlantic Technological University": AtlanticTechCourses,
	"Dublin Business School": DublinBusinessSchoolCourses,
	"Dundalk Institute of Technology, Dundalk": DundalkCourses,
	"Maynooth University": MaynoothCourses,
	"Shannon College of Hotel Management": ShannonCourses,
	"Trinity College Dublin": TrinityDublinCourses,
	"University College Cork": UniCollegeCorkCourses,
	// Latvia
	"Transport and Telecommunication Institute": TransportTelecommunicationCourses,
	// Newzealand
	"Crimson Global Academy": CrimsonGlobalCourses,
	"AGI Education Auckland City": AGIEducationCourses,
	"Whitecliffe Manukau": WhitecliffeCourses,
	"Le Cordon Blue New Zealand": LeCordonBlueNZCourses,
	"Elite School of Beauty and Spa": EliteSchoolBeautySpaCourses,
	"Cut Above Academy": CutAboveAcademyCourses,
	"University of Canterbury": UniversityCanterburyCourses,
	"Auckland University of Technology": AucklandUniversityTechCourses,
	"The University of Otago": OtagoCourses,
	"The University of Auckland": UniversityAucklandCourses,
	"New Zealand Management Academies": NzManagementAcadCourses,
	// Slovania
	"Svarog educational Institute": SwarogCourses,
	"IBS International Business School Ljubljana": IBSCourses,
	"University of Maribor": MariborCourses,
	// UK
	"Abertay University UK": AbertayUniversityCourses,
	"Anglia Ruskin University": AngliaRuskinCourses,
	"Bangor University": BangorUniversityCourses,
	"Brunel University London": BrunelUniversityCourses,
	"Coventry University": CoventryUniversityCourses,
	"Queens University Belfast": QueenBelfastCourses,
	"University of Bedfordshire": BedfordshireCourses,
	"University of Bolton": BoltonCourses,
};

export default coursesByInstitute;
