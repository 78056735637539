import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useAuth } from '../../utils/AuthContext';
import { Flex, Link as ChakraLink, Divider, useBreakpointValue } from '@chakra-ui/react';
import { getUserDetailsFromToken } from '../../utils/getUserDetailsFromToken';

function Navigation() {
	const { isLoggedIn } = useAuth();
	const userDetails = getUserDetailsFromToken();
	const isAdmin = userDetails?.privilege === 'Admin';
	const location = useLocation();

	// Determine the link color based on screen size
	const linkColor = useBreakpointValue({ base: 'blue.700', md: 'white' });
	const hoverColor = useBreakpointValue({ base: 'blue.500', md: 'blue.200' });
	const activeColor = useBreakpointValue({ base: 'blue.500', md: 'blue.200' });

	// Styling for links
	const linkStyle = {
		fontSize: 'lg',
		fontWeight: 'semibold',
		color: linkColor,
		px: [2, 3], // Horizontal padding, more on larger screens
		py: [1, 2], // Vertical padding
		display: 'block',
		_hover: {
			color: hoverColor, // Change the color on hover
		},
		_active: {
			color: activeColor, // Change the color when the link is active
		},
	};

	const NavLinkWrapper = ({ to, children }) => (
		<ChakraLink
			as={NavLink}
			to={to}
			sx={location.pathname === to ? { ...linkStyle, color: activeColor } : linkStyle}
		>
			{children}
		</ChakraLink>
	);

	const dividerOrientation = useBreakpointValue({ base: 'horizontal', md: 'vertical' });

	return (
		<Flex
			as="nav"
			className="header-inner__menu-links"
			align="center"
			wrap="wrap"
			direction={{ base: 'column', md: 'row' }}
			gap={[1, 1]}
		>
			{isLoggedIn && (
				<>
					<NavLinkWrapper to="/dashboard">Dashboard</NavLinkWrapper>
					{dividerOrientation === 'vertical' ? (
						<Divider orientation="vertical" borderColor="gray.400" height="12px" mx={2} />
					) : (
						<Divider orientation="horizontal" borderColor="gray.400" margin="0" height="1px" mx={2} />
					)}
					<NavLinkWrapper to="/add-enquiry">Add Enquiry</NavLinkWrapper>
					{dividerOrientation === 'vertical' ? (
						<Divider orientation="vertical" borderColor="gray.400" height="12px" mx={2} />
					) : (
						<Divider orientation="horizontal" borderColor="gray.400" margin="0" height="1px" mx={2} />
					)}
					<NavLinkWrapper to="/enquiries">Enquiry / Enrollment</NavLinkWrapper>
					{dividerOrientation === 'vertical' ? (
						<Divider orientation="vertical" borderColor="gray.400" height="12px" mx={2} />
					) : (
						<Divider orientation="horizontal" borderColor="gray.400" margin="0" height="1px" mx={2} />
					)}
					{isAdmin &&
						<>
							<NavLinkWrapper to="/search">Search</NavLinkWrapper>
							{dividerOrientation === 'vertical' ? (
								<Divider orientation="vertical" borderColor="gray.400" height="12px" mx={2} />
							) : (
								<Divider orientation="horizontal" borderColor="gray.400" margin="0" height="1px" mx={2} />
							)}
						</>
					}
					<NavLinkWrapper to="/account">Account</NavLinkWrapper>
					{dividerOrientation === 'vertical' ? (
						<Divider orientation="vertical" borderColor="gray.400" height="12px" mx={2} />
					) : (
						<Divider orientation="horizontal" borderColor="gray.400" margin="0" height="1px" mx={2} />
					)}
					<NavLinkWrapper to="/logout">Logout</NavLinkWrapper>
				</>
			)}
			{!isLoggedIn && (
				<>
					<NavLinkWrapper to="/registration">Register</NavLinkWrapper>
					{dividerOrientation === 'vertical' ? (
						<Divider orientation="vertical" borderColor="gray.400" height="12px" mx={2} />
					) : (
						<Divider orientation="horizontal" borderColor="gray.400" margin="0" height="1px" mx={2} />
					)}
					<NavLinkWrapper to="/login">Login</NavLinkWrapper>
				</>
			)}
		</Flex>
	);
}

export default Navigation;
