const SheridanCourses = [
	{ value: 'Graduate Certificate in Game Level Design', label: 'Graduate Certificate in Game Level Design' },
	{ value: 'Diploma in Social Service Worker – Gerontology', label: 'Diploma in Social Service Worker – Gerontology' },
	{ value: 'Advanced Diploma in Internet Communications Technology', label: 'Advanced Diploma in Internet Communications Technology' },
	{ value: 'Diploma in Makeup for Media and Creative Arts', label: 'Diploma in Makeup for Media and Creative Arts' },
	{ value: 'Honours Bachelor of Computer Science(Specialty in Data Analytics)', label: 'Honours Bachelor of Computer Science(Specialty in Data Analytics)' },
	{ value: 'Honours Bachelor of Computer Science(Specialty in Game Engineering)', label: 'Honours Bachelor of Computer Science(Specialty in Game Engineering)' },
	{ value: 'Advanced Diploma in Computer Systems Technology – Information Systems Engineering', label: 'Advanced Diploma in Computer Systems Technology – Information Systems Engineering' },
	{ value: 'Honours Bachelor of Computer Science(Specialty in Network Engineering)', label: 'Honours Bachelor of Computer Science(Specialty in Network Engineering)' },
	{ value: 'Graduate Certificate in Interactive Media Management', label: 'Graduate Certificate in Interactive Media Management' },
	{ value: 'Graduate Certificate in Public Relations – Corporate Communications', label: 'Graduate Certificate in Public Relations – Corporate Communications' },
	{ value: 'Diploma in Educational Support', label: 'Diploma in Educational Support' },
	{ value: 'Certificate in Media Fundamentals', label: 'Certificate in Media Fundamentals' },
	{ value: 'Graduate Certificate in Visual Effects', label: 'Graduate Certificate in Visual Effects' },
	{ value: 'Advanced Diploma in Visual and Creative Arts', label: 'Advanced Diploma in Visual and Creative Arts' },
	{ value: 'Certificate in Performing Arts – Preparation', label: 'Certificate in Performing Arts – Preparation' },
	{ value: 'Honours Bachelor of Interaction Design', label: 'Honours Bachelor of Interaction Design' },
	{ value: 'Honours Bachelor of Experiential Design', label: 'Honours Bachelor of Experiential Design' },
	{ value: 'Honours Bachelor of Craft and Design (Glass)', label: 'Honours Bachelor of Craft and Design (Glass)' },
	{ value: 'Honours Bachelor of Craft and Design (Ceramics)', label: 'Honours Bachelor of Craft and Design (Ceramics)' },
	{ value: 'Diploma in Early Childhood Education – Intensive', label: 'Diploma in Early Childhood Education – Intensive' },
	{ value: 'Honours Bachelor of Photography', label: 'Honours Bachelor of Photography' },
	{ value: 'Honours Bachelor of Computer Science (Mobile Computing)', label: 'Honours Bachelor of Computer Science (Mobile Computing)' },
	{ value: 'Certificate in Arts Fundamentals', label: 'Certificate in Arts Fundamentals' },
	{ value: 'Honours Bachelor of Information Sciences (Cyber Security)', label: 'Honours Bachelor of Information Sciences (Cyber Security)' },
	{ value: 'Advanced Diploma in Child and Youth Care', label: 'Advanced Diploma in Child and Youth Care' },
	{ value: 'Diploma in Educational Support – Fast Track Intensive', label: 'Diploma in Educational Support – Fast Track Intensive' },
	{ value: 'Advanced Diploma in Technical Production for the Performing Arts Industry', label: 'Advanced Diploma in Technical Production for the Performing Arts Industry' },
	{ value: 'Graduate Certificate in Game Development – Advanced Programming', label: 'Graduate Certificate in Game Development – Advanced Programming' },
	{ value: 'Honours Bachelor of Animation', label: 'Honours Bachelor of Animation' },
	{ value: 'Diploma in General Arts & Science', label: 'Diploma in General Arts & Science' },
	{ value: 'Graduate Certificate in Music Scoring for Screen and Stage', label: 'Graduate Certificate in Music Scoring for Screen and Stage' },
	{ value: 'Honours Bachelor of Game Design', label: 'Honours Bachelor of Game Design' },
	{ value: 'Graduate Certificate in Computer Animation', label: 'Graduate Certificate in Computer Animation' },
	{ value: 'Honours Bachelor of Music Theatre Performance', label: 'Honours Bachelor of Music Theatre Performance' },
	{ value: 'Certificate in General Arts & Science', label: 'Certificate in General Arts & Science' },
	{ value: 'Diploma in Early Childhood Education', label: 'Diploma in Early Childhood Education' },
	{ value: 'Graduate Certificate in Digital Creature Animation – Technical Direction', label: 'Graduate Certificate in Digital Creature Animation – Technical Direction' },
	{ value: 'Graduate Certificate in Digital Product Design', label: 'Graduate Certificate in Digital Product Design' },
	{ value: 'Honours Bachelor of Computer Science(Specialty in Cloud Computing)', label: 'Honours Bachelor of Computer Science(Specialty in Cloud Computing)' },
	{ value: 'Diploma in Computer Programming', label: 'Diploma in Computer Programming' },
	{ value: 'Diploma in Visual and Creative Arts', label: 'Diploma in Visual and Creative Arts' },
	{ value: 'Honours Bachelor of Craft and Design (Industrial Design)', label: 'Honours Bachelor of Craft and Design (Industrial Design)' },
	{ value: 'Honours Bachelor of Film and Television', label: 'Honours Bachelor of Film and Television' },
	{ value: 'Advanced Diploma in Computer Systems Technology – Software Development and Network Engineering', label: 'Advanced Diploma in Computer Systems Technology – Software Development and Network Engineering' },
	{ value: 'Honours Bachelor of Craft and Design (Furniture)', label: 'Honours Bachelor of Craft and Design (Furniture)' },
	{ value: 'Honours Bachelor of Illustration', label: 'Honours Bachelor of Illustration' },
	{ value: 'Honours Bachelor of Craft and Design (Textiles)', label: 'Honours Bachelor of Craft and Design (Textiles)' },
	{ value: 'Diploma in Journalism', label: 'Diploma in Journalism' }
];

export default SheridanCourses;