const IDRACCourses = [
	{ value: "Bachelor in Marketing and Business development ( Year 3 Entry )", label: "Bachelor in Marketing and Business development ( Year 3 Entry )" },
	{ value: "Grande Ecole Program - Bachelor in Marketing and Business ( Year 3 Entry )", label: "Grande Ecole Program - Bachelor in Marketing and Business ( Year 3 Entry )" },
	{ value: "Bachelor in Marketing and Business ( Direct Program )", label: "Bachelor in Marketing and Business ( Direct Program )" },
	{ value: "MBA in Computer Science and Information System ( Direct Program )", label: "MBA in Computer Science and Information System ( Direct Program )" },
	{ value: "MBA in Strategy & business innovation major International Business Development ( Direct Program )", label: "MBA in Strategy & business innovation major International Business Development ( Direct Program )" },
	{ value: "MBA in Strategy & business innovation major Wine Business Management ( Direct Program )", label: "MBA in Strategy & business innovation major Wine Business Management ( Direct Program )" },
	{ value: "MBA in International Business Development ( Direct Program )", label: "MBA in International Business Development ( Direct Program )" },
	{ value: "Master Grande Ecole Program", label: "Master Grande Ecole Program" }
];

export default IDRACCourses;