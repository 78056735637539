const FanshaweCourses = [
	{ value: 'Advanced Diploma in Cyber Security', label: 'Advanced Diploma in Cyber Security' },
	{ value: 'Advanced Diploma in Computer Programming and Analysis', label: 'Advanced Diploma in Computer Programming and Analysis' },
	{ value: 'Graduate Certificate in Autism and Behavioural Science', label: 'Graduate Certificate in Autism and Behavioural Science' },
	{ value: 'Graduate Certificate in Information Security Management', label: 'Graduate Certificate in Information Security Management' },
	{ value: 'Diploma in Animation', label: 'Diploma in Animation' },
	{ value: 'Certificate in Culinary Skills', label: 'Certificate in Culinary Skills' },
	{ value: 'Certificate in Customer Service Fundamentals - Insurance', label: 'Certificate in Customer Service Fundamentals - Insurance' },
	{ value: 'Certificate in Electrical Techniques', label: 'Certificate in Electrical Techniques' },
	{ value: 'Certificate in Fire Safety Systems', label: 'Certificate in Fire Safety Systems' },
	{ value: 'Diploma in Heating, Refrigeration and Air Conditioning Technician (Co-op)', label: 'Diploma in Heating, Refrigeration and Air Conditioning Technician (Co-op)' },
	{ value: 'Diploma in Developmental Services Worker (Accelerated)', label: 'Diploma in Developmental Services Worker (Accelerated)' },
	{ value: 'Graduate Certificate in Customer Relationship Marketing and Sales Management', label: 'Graduate Certificate in Customer Relationship Marketing and Sales Management' },
	{ value: 'Diploma in Occupational Therapist Assistant and Physiotherapist Assistant', label: 'Diploma in Occupational Therapist Assistant and Physiotherapist Assistant' },
	{ value: 'Certificate in Professional Butchery Techniques', label: 'Certificate in Professional Butchery Techniques' },
	{ value: 'Diploma in GIS and Urban Planning', label: 'Diploma in GIS and Urban Planning' },
	{ value: 'Diploma in Police Foundations', label: 'Diploma in Police Foundations' },
	{ value: 'Graduate Certificate in Information Technology Infrastructure', label: 'Graduate Certificate in Information Technology Infrastructure' },
	{ value: 'Graduate Certificate in Construction Project Management', label: 'Graduate Certificate in Construction Project Management' },
	{ value: 'Graduate Certificate in Regulatory Affairs and Quality Operations (Co-Op)', label: 'Graduate Certificate in Regulatory Affairs and Quality Operations (Co-Op)' },
	{ value: 'Advanced Diploma in Respiratory Therapy', label: 'Advanced Diploma in Respiratory Therapy' },
	{ value: 'Diploma in Business - Accounting (Co-op)', label: 'Diploma in Business - Accounting (Co-op)' },
	{ value: 'Diploma in Developmental Services Worker', label: 'Diploma in Developmental Services Worker' },
	{ value: 'Diploma in Nutrition and Food Service Management', label: 'Diploma in Nutrition and Food Service Management' },
	{ value: 'Graduate Certificate in Advanced Filmmaking', label: 'Graduate Certificate in Advanced Filmmaking' },
	{ value: 'Graduate Certificate in Marketing Management', label: 'Graduate Certificate in Marketing Management' },
	{ value: 'Graduate Certificate in Public Relations - Corporate Communications', label: 'Graduate Certificate in Public Relations - Corporate Communications' },
	{ value: 'Advanced Diploma in Construction Engineering Technology - Management', label: 'Advanced Diploma in Construction Engineering Technology - Management' },
	{ value: 'Diploma in Business (Co-op)', label: 'Diploma in Business (Co-op)' },
	{ value: 'Diploma in Interior Decorating', label: 'Diploma in Interior Decorating' },
	{ value: 'Diploma in Office Administration - Health Services', label: 'Diploma in Office Administration - Health Services' },
	{ value: 'Graduate Certificate in Applied Aerospace Manufacturing', label: 'Graduate Certificate in Applied Aerospace Manufacturing' },
	{ value: 'Graduate Certificate in Gerontology - Interprofessional Practice', label: 'Graduate Certificate in Gerontology - Interprofessional Practice' },
	{ value: 'Graduate Certificate in Health Systems Management', label: 'Graduate Certificate in Health Systems Management' },
	{ value: 'Graduate Certificate in Insurance and Risk Management', label: 'Graduate Certificate in Insurance and Risk Management' },
	{ value: 'Certificate in Aircraft Structural Repair Technician', label: 'Certificate in Aircraft Structural Repair Technician' },
	{ value: 'Advanced Diploma in Child and Youth Care', label: 'Advanced Diploma in Child and Youth Care' },
	{ value: 'Advanced Diploma in Interactive Media Development - 3D Visualization', label: 'Advanced Diploma in Interactive Media Development - 3D Visualization' },
	{ value: 'Advanced Diploma in Medical Radiation Technology', label: 'Advanced Diploma in Medical Radiation Technology' },
	{ value: 'Diploma in Early Childhood Education', label: 'Diploma in Early Childhood Education' },
	{ value: 'Graduate Certificate in Mobile Application Development', label: 'Graduate Certificate in Mobile Application Development' },
	{ value: 'Graduate Certificate in Food Processing - Operational Leadership', label: 'Graduate Certificate in Food Processing - Operational Leadership' },
	{ value: 'Graduate Certificate in Business Management - Entrepreneurship', label: 'Graduate Certificate in Business Management - Entrepreneurship' },
	{ value: 'Graduate Certificate in Addictions and Mental Health (Toronto)', label: 'Graduate Certificate in Addictions and Mental Health (Toronto)' },
	{ value: 'Certificate in Carpentry and Renovation Technician', label: 'Certificate in Carpentry and Renovation Technician' },
	{ value: 'Diploma in Social Service Worker', label: 'Diploma in Social Service Worker' },
	{ value: 'Diploma in Tourism - Travel', label: 'Diploma in Tourism - Travel' },
	{ value: 'Graduate Certificate in Applied Mechanical Design', label: 'Graduate Certificate in Applied Mechanical Design' },
	{ value: 'Graduate Certificate in Practical Elements of Mechanical Engineering', label: 'Graduate Certificate in Practical Elements of Mechanical Engineering' },
	{ value: 'Certificate in Design Foundations', label: 'Certificate in Design Foundations' },
	{ value: 'Diploma in Electrical Engineering Technician', label: 'Diploma in Electrical Engineering Technician' },
	{ value: 'Diploma in Golf and Club Management (Co-op)', label: 'Diploma in Golf and Club Management (Co-op)' },
	{ value: 'Diploma in Horticulture Technician', label: 'Diploma in Horticulture Technician' },
	{ value: 'Diploma in Renewable Energies Technician (Co-op)', label: 'Diploma in Renewable Energies Technician (Co-op)' },
	{ value: 'Graduate Certificate in User Experience Design', label: 'Graduate Certificate in User Experience Design' },
	{ value: 'Certificate in Fine Art Foundation', label: 'Certificate in Fine Art Foundation' },
	{ value: 'Advanced Diploma in Business Administration - Marketing', label: 'Advanced Diploma in Business Administration - Marketing' },
	{ value: 'Advanced Diploma in Graphic Design', label: 'Advanced Diploma in Graphic Design' },
	{ value: 'Advanced Diploma in Massage Therapy (Accelerated)', label: 'Advanced Diploma in Massage Therapy (Accelerated)' },
	{ value: 'Diploma in Adventure Expeditions and Interpretive Leadership', label: 'Diploma in Adventure Expeditions and Interpretive Leadership' },
	{ value: 'Diploma in Business - Finance', label: 'Diploma in Business - Finance' },
	{ value: 'Diploma in Computer Systems Technician', label: 'Diploma in Computer Systems Technician' },
	{ value: 'Diploma in Educational Support', label: 'Diploma in Educational Support' },
	{ value: 'Advanced Diploma in Computer Systems Technology', label: 'Advanced Diploma in Computer Systems Technology' },
	{ value: 'Diploma in Mechanical Engineering Technician-Industrial Maintenance', label: 'Diploma in Mechanical Engineering Technician-Industrial Maintenance' },
	{ value: 'Graduate Certificate in Digital Communication Management', label: 'Graduate Certificate in Digital Communication Management' },
	{ value: 'Graduate Certificate in BIM and Integrated Practice', label: 'Graduate Certificate in BIM and Integrated Practice' },
	{ value: 'Graduate Certificate in Food Processing - Product Development', label: 'Graduate Certificate in Food Processing - Product Development' },
	{ value: 'Advanced Diploma in Civil Engineering Technology', label: 'Advanced Diploma in Civil Engineering Technology' },
	{ value: 'Diploma in Heating, Refrigeration and Air Conditioning Technician', label: 'Diploma in Heating, Refrigeration and Air Conditioning Technician' },
	{ value: 'Diploma in Recreation and Leisure Services', label: 'Diploma in Recreation and Leisure Services' },
	{ value: 'Graduate Certificate in Geographic Information Systems (GIS)', label: 'Graduate Certificate in Geographic Information Systems (GIS)' },
	{ value: 'Certificate in Auto Body Repair Techniques', label: 'Certificate in Auto Body Repair Techniques' },
	{ value: 'Certificate in Business Fundamentals - Entrepreneurship', label: 'Certificate in Business Fundamentals - Entrepreneurship' },
	{ value: 'Advanced Diploma in Business Administration - Leadership and Management', label: 'Advanced Diploma in Business Administration - Leadership and Management' },
	{ value: 'Diploma in Business - Human Resources', label: 'Diploma in Business - Human Resources' },
	{ value: 'Graduate Certificate in Hospitality and Tourism Operations Management', label: 'Graduate Certificate in Hospitality and Tourism Operations Management' },
	{ value: 'Graduate Certificate in Operations Management', label: 'Graduate Certificate in Operations Management' },
	{ value: 'Graduate Certificate in Software and Information Systems Testing (Co-Op)', label: 'Graduate Certificate in Software and Information Systems Testing (Co-Op)' },
	{ value: 'Graduate Certificate in Gerontology - Interprofessional Practice (Toronto)', label: 'Graduate Certificate in Gerontology - Interprofessional Practice (Toronto)' },
	{ value: 'Honours Bachelor of Commerce (Accounting)', label: 'Honours Bachelor of Commerce (Accounting)' },
	{ value: 'Graduate Certificate in Advanced Ergonomic Studies', label: 'Graduate Certificate in Advanced Ergonomic Studies' },
	{ value: 'Graduate Certificate in Retirement Residence Management', label: 'Graduate Certificate in Retirement Residence Management' },
	{ value: 'Graduate Certificate in Business Analysis (Co-op)', label: 'Graduate Certificate in Business Analysis (Co-op)' },
	{ value: 'Graduate Certificate in Photography - Advanced', label: 'Graduate Certificate in Photography - Advanced' },
	{ value: 'Diploma in Acting for Screen and Stage', label: 'Diploma in Acting for Screen and Stage' },
	{ value: 'Diploma in Social Service Worker (Fast Track)', label: 'Diploma in Social Service Worker (Fast Track)' },
	{ value: 'Certificate in Plumbing Techniques', label: 'Certificate in Plumbing Techniques' },
	{ value: 'Advanced Diploma in Fire Inspection and Fire Safety Education', label: 'Advanced Diploma in Fire Inspection and Fire Safety Education' },
	{ value: 'Diploma in Food and Beverage Management (Co-op)', label: 'Diploma in Food and Beverage Management (Co-op)' },
	{ value: 'Diploma in Manufacturing Engineering Technician (Co-op)', label: 'Diploma in Manufacturing Engineering Technician (Co-op)' },
	{ value: 'Diploma in Mechanical Engineering Technician - Tool and Die', label: 'Diploma in Mechanical Engineering Technician - Tool and Die' },
	{ value: 'Graduate Certificate in Sport and Event Marketing', label: 'Graduate Certificate in Sport and Event Marketing' },
	{ value: 'Graduate Certificate in Agri-Business Management', label: 'Graduate Certificate in Agri-Business Management' },
	{ value: 'Graduate Certificate in Hospitality and Tourism Operations Management (Toronto)', label: 'Graduate Certificate in Hospitality and Tourism Operations Management (Toronto)' },
	{ value: 'Advanced Diploma in Building Renovation Technology', label: 'Advanced Diploma in Building Renovation Technology' },
	{ value: 'Diploma in Law Clerk (Co-op)', label: 'Diploma in Law Clerk (Co-op)' },
	{ value: 'Diploma in Paramedic', label: 'Diploma in Paramedic' },
	{ value: 'Certificate in Flight Services', label: 'Certificate in Flight Services' },
	{ value: 'Diploma in Business - Insurance', label: 'Diploma in Business - Insurance' },
	{ value: 'Graduate Certificate in Addictions and Mental Health', label: 'Graduate Certificate in Addictions and Mental Health' },
	{ value: 'Diploma in Office Administration - Executive (Toronto)', label: 'Diploma in Office Administration - Executive (Toronto)' },
	{ value: 'Advanced Diploma in Chemical Laboratory Technology - Science Laboratory', label: 'Advanced Diploma in Chemical Laboratory Technology - Science Laboratory' },
	{ value: 'Advanced Diploma in Commercial Flight and Aviation Leadership', label: 'Advanced Diploma in Commercial Flight and Aviation Leadership' },
	{ value: 'Graduate Certificate in Technical Systems Analysis (Co-op)', label: 'Graduate Certificate in Technical Systems Analysis (Co-op)' },
	{ value: 'Advanced Diploma in Fine Art', label: 'Advanced Diploma in Fine Art' },
	{ value: 'Diploma in Fashion Marketing and Management', label: 'Diploma in Fashion Marketing and Management' },
	{ value: 'Diploma in Internet Applications and Web Development', label: 'Diploma in Internet Applications and Web Development' },
	{ value: 'Diploma in Journalism - Broadcast', label: 'Diploma in Journalism - Broadcast' },
	{ value: 'Diploma in Welding and Fabrication Technician (Co-op)', label: 'Diploma in Welding and Fabrication Technician (Co-op)' },
	{ value: 'Graduate Certificate in Business Management', label: 'Graduate Certificate in Business Management' },
	{ value: 'Graduate Certificate in Broadcast Journalism - Television News', label: 'Graduate Certificate in Broadcast Journalism - Television News' },
	{ value: 'Diploma in Construction Engineering Technician', label: 'Diploma in Construction Engineering Technician' },
	{ value: 'Graduate Certificate in Regulatory Affairs and Quality Operations (Co-Op) (Toronto)', label: 'Graduate Certificate in Regulatory Affairs and Quality Operations (Co-Op) (Toronto)' },
	{ value: 'Honours Bachelor of Interior Design', label: 'Honours Bachelor of Interior Design' },
	{ value: 'Diploma in Business - Supply Chain and Operations (Co-op)', label: 'Diploma in Business - Supply Chain and Operations (Co-op)' },
	{ value: 'Certificate in Business Fundamentals', label: 'Certificate in Business Fundamentals' },
	{ value: 'Diploma in Business - Marketing', label: 'Diploma in Business - Marketing' },
	{ value: 'Certificate in Community Pharmacy Assistant', label: 'Certificate in Community Pharmacy Assistant' },
	{ value: 'Graduate Certificate in Supply Chain Management - Logistics', label: 'Graduate Certificate in Supply Chain Management - Logistics' },
	{ value: 'Graduate Certificate in Automotive Service Management', label: 'Graduate Certificate in Automotive Service Management' },
	{ value: 'Graduate Certificate in Aerospace Operations Management', label: 'Graduate Certificate in Aerospace Operations Management' },
	{ value: 'Graduate Certificate in Palliative Care', label: 'Graduate Certificate in Palliative Care' },
	{ value: 'Diploma in Hospitality - Hotel and Resort Services Management Co-op', label: 'Diploma in Hospitality - Hotel and Resort Services Management Co-op' },
	{ value: 'Honours Bachelor of Environmental Design and Planning', label: 'Honours Bachelor of Environmental Design and Planning' },
	{ value: 'Diploma in Events Planning', label: 'Diploma in Events Planning' },
	{ value: 'Diploma in Business', label: 'Diploma in Business' },
	{ value: 'Graduate Certificate in Financial Technology & Innovation ( Co-Op )', label: 'Graduate Certificate in Financial Technology & Innovation ( Co-Op )' },
	{ value: 'Graduate Certificate in Paralegal', label: 'Graduate Certificate in Paralegal' },
	{ value: 'Graduate Certificate in Artificial Intelligence and Machine Learning', label: 'Graduate Certificate in Artificial Intelligence and Machine Learning' },
	{ value: 'Diploma in Developmental Services Worker (Toronto)', label: 'Diploma in Developmental Services Worker (Toronto)' },
	{ value: 'Advanced Diploma in Dental Hygiene', label: 'Advanced Diploma in Dental Hygiene' },
	{ value: 'Honours Bachelor of Commerce (Digital Marketing)', label: 'Honours Bachelor of Commerce (Digital Marketing)' },
	{ value: 'Certificate in Office Administration - General', label: 'Certificate in Office Administration - General' },
	{ value: 'Diploma in Business - Accounting', label: 'Diploma in Business - Accounting' },
	{ value: 'Diploma in Motive Power Technician (Automotive)', label: 'Diploma in Motive Power Technician (Automotive)' },
	{ value: 'Diploma in Protection, Security and Investigation', label: 'Diploma in Protection, Security and Investigation' },
	{ value: 'Graduate Certificate in Advanced Live Digital Media Production', label: 'Graduate Certificate in Advanced Live Digital Media Production' },
	{ value: 'Graduate Certificate in Remotely Piloted Aerial Systems Commercial Operations', label: 'Graduate Certificate in Remotely Piloted Aerial Systems Commercial Operations' },
	{ value: 'Graduate Certificate in Business Analysis', label: 'Graduate Certificate in Business Analysis' },
	{ value: 'Graduate Certificate in Human Resources Management', label: 'Graduate Certificate in Human Resources Management' },
	{ value: 'Certificate in General Arts and Science', label: 'Certificate in General Arts and Science' },
	{ value: 'Advanced Diploma in Electrical Engineering Technology (Co-op)', label: 'Advanced Diploma in Electrical Engineering Technology (Co-op)' },
	{ value: 'Diploma in Aviation Technician - Avionics Maintenance (Co-op)', label: 'Diploma in Aviation Technician - Avionics Maintenance (Co-op)' },
	{ value: 'Diploma in Carpentry and Renovation Technician', label: 'Diploma in Carpentry and Renovation Technician' },
	{ value: 'Graduate Certificate in Teaching English to Speakers of Other Languages and Intercultural Competence', label: 'Graduate Certificate in Teaching English to Speakers of Other Languages and Intercultural Competence' },
	{ value: 'Graduate Certificate in 3D Animation and Character Design', label: 'Graduate Certificate in 3D Animation and Character Design' },
	{ value: 'Graduate Certificate in Costume Production', label: 'Graduate Certificate in Costume Production' },
	{ value: 'Certificate in Human Services Foundation', label: 'Certificate in Human Services Foundation' },
	{ value: 'Advanced Diploma in Fashion Design', label: 'Advanced Diploma in Fashion Design' },
	{ value: 'Diploma in Aviation Technician - Aircraft Maintenance (Co-op)', label: 'Diploma in Aviation Technician - Aircraft Maintenance (Co-op)' },
	{ value: 'Graduate Certificate in Composites and Advanced Materials Aerospace Manufacturing', label: 'Graduate Certificate in Composites and Advanced Materials Aerospace Manufacturing' },
	{ value: 'Graduate Certificate in Research and Evaluation (Co-op)', label: 'Graduate Certificate in Research and Evaluation (Co-op)' },
	{ value: 'Graduate Certificate in Health Care Administration Management', label: 'Graduate Certificate in Health Care Administration Management' },
	{ value: 'Graduate Certificate in Network and Security Architecture', label: 'Graduate Certificate in Network and Security Architecture' },
	{ value: 'Diploma in Early Childhood Education (Toronto)', label: 'Diploma in Early Childhood Education (Toronto)' },
	{ value: 'Certificate in Personal Support Worker', label: 'Certificate in Personal Support Worker' },
	{ value: 'Advanced Diploma in Environmental Technology', label: 'Advanced Diploma in Environmental Technology' },
	{ value: 'Diploma in Fitness and Health Promotion', label: 'Diploma in Fitness and Health Promotion' },
	{ value: 'Diploma in Hospitality - Hotel & Resort Services Management (Co-op)', label: 'Diploma in Hospitality - Hotel & Resort Services Management (Co-op)' },
	{ value: 'Certificate in Welding Techniques', label: 'Certificate in Welding Techniques' },
	{ value: 'Honours Bachelor of Applied Technology - Biotechnology', label: 'Honours Bachelor of Applied Technology - Biotechnology' },
	{ value: 'Honours Bachelor of Commerce (Human Resources Management)', label: 'Honours Bachelor of Commerce (Human Resources Management)' },
	{ value: 'Diploma in Broadcasting - Radio and Media Production', label: 'Diploma in Broadcasting - Radio and Media Production' },
	{ value: 'Diploma in Culinary Management', label: 'Diploma in Culinary Management' },
	{ value: 'Graduate Certificate in Professional Accounting', label: 'Graduate Certificate in Professional Accounting' },
	{ value: 'Graduate Certificate in International Business Management', label: 'Graduate Certificate in International Business Management' },
	{ value: 'Graduate Certificate in Project Management', label: 'Graduate Certificate in Project Management' },
	{ value: 'Honours Bachelor of Commerce (Management)', label: 'Honours Bachelor of Commerce (Management)' },
	{ value: 'Honours Bachelor of Early Childhood Leadership', label: 'Honours Bachelor of Early Childhood Leadership' },
	{ value: 'Diploma in Baking and Pastry Arts Management', label: 'Diploma in Baking and Pastry Arts Management' },
	{ value: 'Diploma in Business - Finance (Co-op)', label: 'Diploma in Business - Finance (Co-op)' },
	{ value: 'Graduate Certificate in Business and Information Systems Architecture', label: 'Graduate Certificate in Business and Information Systems Architecture' },
	{ value: 'Advanced Diploma in Manufacturing Engineering Technology (Co-op)', label: 'Advanced Diploma in Manufacturing Engineering Technology (Co-op)' },
	{ value: 'Diploma in Electromechanical Engineering Technician (Coop)', label: 'Diploma in Electromechanical Engineering Technician (Coop)' },
	{ value: 'Graduate Certificate in Professional Financial Services', label: 'Graduate Certificate in Professional Financial Services' },
	{ value: 'Graduate Certificate in Visual Effects and Editing for Contemporary Media', label: 'Graduate Certificate in Visual Effects and Editing for Contemporary Media' },
	{ value: 'Graduate Certificate in Game Development - Advanced Programming', label: 'Graduate Certificate in Game Development - Advanced Programming' },
	{ value: 'Graduate Certificate in Automotive Sales', label: 'Graduate Certificate in Automotive Sales' },
	{ value: 'Graduate Certificate in Retirement Residence Management (Toronto)', label: 'Graduate Certificate in Retirement Residence Management (Toronto)' },
	{ value: 'Post-Graduate Certificate in Construction Project Management (Co-Op)', label: 'Post-Graduate Certificate in Construction Project Management (Co-Op)' },
	{ value: 'Honours Bachelor Degree in Collaborative Nursing', label: 'Honours Bachelor Degree in Collaborative Nursing' },
	{ value: 'Diploma in Interactive Media Design', label: 'Diploma in Interactive Media Design' },
	{ value: 'Graduate Certificate in Advanced Communication for Professionals', label: 'Graduate Certificate in Advanced Communication for Professionals' },
	{ value: 'Diploma in Business - Insurance (Co-Op)', label: 'Diploma in Business - Insurance (Co-Op)' },
	{ value: 'Graduate Certificate in Occupational Health and Safety Management (Co-op)', label: 'Graduate Certificate in Occupational Health and Safety Management (Co-op)' },
	{ value: 'Post-Graduate Certificate in Chronic Disease Management', label: 'Post-Graduate Certificate in Chronic Disease Management' },
	{ value: 'Certificate in Dental Assisting (Levels I and II)', label: 'Certificate in Dental Assisting (Levels I and II)' },
	{ value: 'Certificate in Public Safety Fundamentals', label: 'Certificate in Public Safety Fundamentals' },
	{ value: 'Diploma in Broadcasting - Television and Film Production', label: 'Diploma in Broadcasting - Television and Film Production' },
	{ value: 'Diploma in Business - Marketing (Co-op)', label: 'Diploma in Business - Marketing (Co-op)' },
	{ value: 'Diploma in Office Administration - Executive', label: 'Diploma in Office Administration - Executive' },
	{ value: 'Diploma in Pharmacy Technician', label: 'Diploma in Pharmacy Technician' },
	{ value: 'Diploma in Photography', label: 'Diploma in Photography' },
	{ value: 'Graduate Certificate in Audio Post-Production', label: 'Graduate Certificate in Audio Post-Production' },
	{ value: 'Advanced Diploma in Architectural Technology', label: 'Advanced Diploma in Architectural Technology' },
	{ value: 'Diploma in Early Childhood Education (Accelerated)', label: 'Diploma in Early Childhood Education (Accelerated)' },
	{ value: 'Advanced Diploma in Landscape Design', label: 'Advanced Diploma in Landscape Design' },
	{ value: 'Diploma in Business - Entrepreneurship and Management', label: 'Diploma in Business - Entrepreneurship and Management' },
	{ value: 'Diploma in Motive Power Technician (Diesel)', label: 'Diploma in Motive Power Technician (Diesel)' },
	{ value: 'Diploma in Music Industry Arts', label: 'Diploma in Music Industry Arts' },
	{ value: 'Graduate Certificate in Cannabis Applied Science', label: 'Graduate Certificate in Cannabis Applied Science' },
	{ value: 'Advanced Diploma in Business Administration - Accounting', label: 'Advanced Diploma in Business Administration - Accounting' }
];

export default FanshaweCourses;