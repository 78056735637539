const CapeBretonCourses = [
	{ value: 'Bachelor of Arts', label: 'Bachelor of Arts' },
	{ value: 'Bachelor of Business Administration', label: 'Bachelor of Business Administration' },
	{ value: 'Bachelor of Engineering Technology (Post - Degree)', label: 'Bachelor of Engineering Technology (Post - Degree)' },
	{ value: 'Bachelor of Health Sciences (Public Health)', label: 'Bachelor of Health Sciences (Public Health)' },
	{ value: 'Master of Business Administration in Community Economic Development', label: 'Master of Business Administration in Community Economic Development' },
	{ value: 'Bachelor of Science', label: 'Bachelor of Science' },
	{ value: 'Bachelor of Engineering (Transfer Diploma 2+2 Yrs)', label: 'Bachelor of Engineering (Transfer Diploma 2+2 Yrs)' },
	{ value: 'Bachelor of Hospitality and Tourism Management', label: 'Bachelor of Hospitality and Tourism Management' },
	{ value: 'Bachelor of Engineering Technology', label: 'Bachelor of Engineering Technology' },
	{ value: 'Diploma in Government Management', label: 'Diploma in Government Management' },
	{ value: 'Bachelor of Arts and Community Studies', label: 'Bachelor of Arts and Community Studies' },
	{ value: 'Bachelor of Science, Nutrition (Transfer)', label: 'Bachelor of Science, Nutrition (Transfer)' },
	{ value: 'Bachelor of Arts and Science in Environment', label: 'Bachelor of Arts and Science in Environment' },
	{ value: 'Bachelor of Emergency Management', label: 'Bachelor of Emergency Management' },
	{ value: 'Bachelor of Science, Nursing', label: 'Bachelor of Science, Nursing' }
];

export default CapeBretonCourses;
