const GISMABusinessCourses = [
	{ value: "BSc in Data Science, AI, and Digital Business", label: "BSc in Data Science, AI, and Digital Business" },
	{ value: "BSc in International Business Management", label: "BSc in International Business Management" },
	{ value: "BSc in Computer Science", label: "BSc in Computer Science" },
	{ value: "BEng in Software Engineering", label: "BEng in Software Engineering" },
	{ value: "BSc in Business Management", label: "BSc in Business Management" },
	{ value: "Global Master of Business Administration", label: "Global Master of Business Administration" },
	{ value: "Masters of Science (MSc) Data Science, AI, and Digital Business", label: "Masters of Science (MSc) Data Science, AI, and Digital Business" },
	{ value: "Masters of Science Data Science, AI, and Digital Business", label: "Masters of Science Data Science, AI, and Digital Business" },
	{ value: "Masters of Science (MSc) in International Business Management", label: "Masters of Science (MSc) in International Business Management" },
	{ value: "Masters of Science in International Business Management", label: "Masters of Science in International Business Management" },
	{ value: "Masters of Science (MSc) in Leadership for Digital Transformation", label: "Masters of Science (MSc) in Leadership for Digital Transformation" },
	{ value: "Masters of Science in Leadership for Digital Transformation", label: "Masters of Science in Leadership for Digital Transformation" },
	{ value: "Master of Engineering (MEng) Computer Science", label: "Master of Engineering (MEng) Computer Science" },
	{ value: "Master of Engineering Computer Science", label: "Master of Engineering Computer Science" },
	{ value: "Masters of Science (MSc) in Business Management", label: "Masters of Science (MSc) in Business Management" },
	{ value: "Masters of Science in Business Management", label: "Masters of Science in Business Management" },
	{ value: "Master of Science (MSc) in International Business Management", label: "Master of Science (MSc) in International Business Management" }
];

export default GISMABusinessCourses;