import { BrowserRouter as Router } from 'react-router-dom';
import Page from './components/layout/Page';
import { AuthProvider } from './components/utils/AuthContext';
import { NavigationProvider } from './components/utils/NavigationContext';
import { NotificationProvider } from './components/notifications/NotificationContext';
import RouterChecker from './components/utils/RouterChecker';

function App() {
	return (
		<AuthProvider>
			<NavigationProvider>
				<NotificationProvider>
					<div className="App">
						<Router>
							<RouterChecker />
							<Page />
						</Router>
					</div>
				</NotificationProvider>
			</NavigationProvider>
		</AuthProvider>
	);
}

export default App;
