// NotificationComponent.js

import React, { useContext } from 'react';
import { NotificationContext } from './NotificationContext';

const NotificationComponent = () => {
	const { notifications } = useContext(NotificationContext);

	return (
		<div>
			{notifications.map((notification, index) => (
				<div key={index} className={`notification ${notification.type}`}>
					{notification.message}
				</div>
			))}
		</div>
	);
};

export default NotificationComponent;
