const MariborCourses = [
	{ value: 'AGRICULTURAL ECONOMICS', label: 'AGRICULTURAL ECONOMICS' },
	{ value: 'AGRICULTURE', label: 'AGRICULTURE' },
	{ value: 'AGRONOMY - ORNAMENTALS, VEGETABLES AND FIELD CROPS', label: 'AGRONOMY - ORNAMENTALS, VEGETABLES AND FIELD CROPS' },
	{ value: 'FOOD SAFETY IN THE AGRI-FOOD CHAIN', label: 'FOOD SAFETY IN THE AGRI-FOOD CHAIN' },
	{ value: 'ART HISTORY', label: 'ART HISTORY' },
	{ value: 'ENGLISH LANGUAGE AND LITERATURE', label: 'ENGLISH LANGUAGE AND LITERATURE' },
	{ value: 'ENGLISH STUDIES', label: 'ENGLISH STUDIES' },
	{ value: 'GEOGRAPHY', label: 'GEOGRAPHY' },
	{ value: 'GERMAN AS A FOREIGN LANGUAGE', label: 'GERMAN AS A FOREIGN LANGUAGE' },
	{ value: 'HISTORY', label: 'HISTORY' },
	{ value: 'INTERCULTURAL GERMAN STUDIES', label: 'INTERCULTURAL GERMAN STUDIES' },
	{ value: 'PEDAGOGY', label: 'PEDAGOGY' },
	{ value: 'PHILOSOPHY', label: 'PHILOSOPHY' },
	{ value: 'PSYCHOLOGY', label: 'PSYCHOLOGY' },
	{ value: 'SLOVENE LANGUAGE AND LITERATURE', label: 'SLOVENE LANGUAGE AND LITERATURE' },
	{ value: 'SOCIOLOGY', label: 'SOCIOLOGY' },
	{ value: 'CHEMICAL ENGINEERING', label: 'CHEMICAL ENGINEERING' },
	{ value: 'CHEMICAL TECHNOLOGY', label: 'CHEMICAL TECHNOLOGY' },
	{ value: 'CHEMISTRY', label: 'CHEMISTRY' },
	{ value: 'ARCHITECTURE', label: 'ARCHITECTURE' },
	{ value: 'CIVIL ENGINEERING', label: 'CIVIL ENGINEERING' },
	{ value: 'INDUSTRIAL ENGINEERING', label: 'INDUSTRIAL ENGINEERING' },
	{ value: 'TRAFFIC AND TRANSPORTATION ENGINEERING', label: 'TRAFFIC AND TRANSPORTATION ENGINEERING' },
	{ value: 'CRIMINAL JUSTICE AND SECURITY', label: 'CRIMINAL JUSTICE AND SECURITY' },
	{ value: 'INFORMATION SECURITY', label: 'INFORMATION SECURITY' },
	{ value: 'SECURITY AND POLICING', label: 'SECURITY AND POLICING' },
	{ value: 'BUSINESS ADMINISTRATION', label: 'BUSINESS ADMINISTRATION' },
	{ value: 'ECONOMIC AND BUSINESS SCIENCES', label: 'ECONOMIC AND BUSINESS SCIENCES' },
	{ value: 'ELEMENTARY EDUCATION', label: 'ELEMENTARY EDUCATION' },
	{ value: 'FINE ART EDUCATION', label: 'FINE ART EDUCATION' },
	{ value: 'SCIENCE OF EDUCATION', label: 'SCIENCE OF EDUCATION' },
	{ value: 'COMPUTER SCIENCE AND INFORMATION TECHNOLOGIES', label: 'COMPUTER SCIENCE AND INFORMATION TECHNOLOGIES' },
	{ value: 'ELECTRICAL ENGINEERING', label: 'ELECTRICAL ENGINEERING' },
	{ value: 'MEDIA COMMUNICATIONS', label: 'MEDIA COMMUNICATIONS' },
	{ value: 'NURSING CARE', label: 'NURSING CARE' },
	{ value: 'TOURISM: COOPERATION AND DEVELOPMENT', label: 'TOURISM: COOPERATION AND DEVELOPMENT' },
];

export default MariborCourses;
