const GriffithUniversityCourses = [
	{ value: 'DIRECT ENTRY PROGRAM Griffith English Language Institute', label: 'DIRECT ENTRY PROGRAM Griffith English Language Institute' },
	{ value: 'Bachelor of Construction Management (Honours)', label: 'Bachelor of Construction Management (Honours)' },
	{ value: 'Bachelor of Exercise Science / Bachelor of Psychological Science', label: 'Bachelor of Exercise Science / Bachelor of Psychological Science' },
	{ value: 'Master of Business (Economics)', label: 'Master of Business (Economics)' },
	{ value: 'Bachelor of Communication and Journalism / Bachelor of Government and International Relations', label: 'Bachelor of Communication and Journalism / Bachelor of Government and International Relations' },
	{ value: 'Master of Marketing/Master of International Business', label: 'Master of Marketing/Master of International Business' },
	{ value: 'Master of Civil Engineering', label: 'Master of Civil Engineering' },
	{ value: 'Master of Environmental Engineering', label: 'Master of Environmental Engineering' },
	{ value: 'Bachelor of Engineering (Honours) (Electronic and UAV Engineering)', label: 'Bachelor of Engineering (Honours) (Electronic and UAV Engineering)' },
	{ value: 'Master of Information Systems and Information Technology Management', label: 'Master of Information Systems and Information Technology Management' },
	{ value: 'Master of Professional Accounting', label: 'Master of Professional Accounting' },
	{ value: 'Bachelor of Engineering (Honours) / Bachelor of Environmental Science', label: 'Bachelor of Engineering (Honours) / Bachelor of Environmental Science' },
	{ value: 'Bachelor of Counselling', label: 'Bachelor of Counselling' },
	{ value: 'Bachelor of Laws (Honours) / Bachelor of Business', label: 'Bachelor of Laws (Honours) / Bachelor of Business' },
	{ value: 'Bachelor of Engineering (Honours) / Bachelor of Industrial Design', label: 'Bachelor of Engineering (Honours) / Bachelor of Industrial Design' },
	{ value: 'Bachelor of Film and Screen Media Production', label: 'Bachelor of Film and Screen Media Production' },
	{ value: 'Master of Pharmacy', label: 'Master of Pharmacy' },
	{ value: 'Bachelor of Engineering (Honours) / Bachelor of information Technology', label: 'Bachelor of Engineering (Honours) / Bachelor of information Technology' },
	{ value: 'Master of Civil Engineering Advanced', label: 'Master of Civil Engineering Advanced' },
	{ value: 'Bachelor of Forensic Science', label: 'Bachelor of Forensic Science' },
	{ value: 'Master of Business (Sport Management)', label: 'Master of Business (Sport Management)' },
	{ value: 'Master of Finance', label: 'Master of Finance' },
	{ value: 'Bachelor of Laws (Honours) / Bachelor of Government and International Relations', label: 'Bachelor of Laws (Honours) / Bachelor of Government and International Relations' },
	{ value: 'Master of Science', label: 'Master of Science' },
	{ value: 'DIRECT ENTRY PROGRAM Griffith English Language Institute', label: 'DIRECT ENTRY PROGRAM Griffith English Language Institute' },
	{ value: 'Bachelor of Construction Management (Honours)', label: 'Bachelor of Construction Management (Honours)' },
	{ value: 'Bachelor of Exercise Science / Bachelor of Psychological Science', label: 'Bachelor of Exercise Science / Bachelor of Psychological Science' },
	{ value: 'Master of Business (Economics)', label: 'Master of Business (Economics)' },
	{ value: 'Bachelor of Communication and Journalism / Bachelor of Government and International Relations', label: 'Bachelor of Communication and Journalism / Bachelor of Government and International Relations' },
	{ value: 'Master of Marketing/Master of International Business', label: 'Master of Marketing/Master of International Business' },
	{ value: 'Master of Civil Engineering', label: 'Master of Civil Engineering' },
	{ value: 'Master of Environmental Engineering', label: 'Master of Environmental Engineering' },
	{ value: 'Bachelor of Engineering (Honours) (Electronic and UAV Engineering)', label: 'Bachelor of Engineering (Honours) (Electronic and UAV Engineering)' },
	{ value: 'Master of Information Systems and Information Technology Management', label: 'Master of Information Systems and Information Technology Management' },
	{ value: 'Master of Professional Accounting', label: 'Master of Professional Accounting' },
	{ value: 'Bachelor of Engineering (Honours) / Bachelor of Environmental Science', label: 'Bachelor of Engineering (Honours) / Bachelor of Environmental Science' },
	{ value: 'Bachelor of Counselling', label: 'Bachelor of Counselling' },
	{ value: 'Bachelor of Laws (Honours) / Bachelor of Business', label: 'Bachelor of Laws (Honours) / Bachelor of Business' },
	{ value: 'Bachelor of Engineering (Honours) / Bachelor of Industrial Design', label: 'Bachelor of Engineering (Honours) / Bachelor of Industrial Design' },
	{ value: 'Bachelor of Film and Screen Media Production', label: 'Bachelor of Film and Screen Media Production' },
	{ value: 'Master of Pharmacy', label: 'Master of Pharmacy' },
	{ value: 'Bachelor of Engineering (Honours) / Bachelor of information Technology', label: 'Bachelor of Engineering (Honours) / Bachelor of information Technology' },
	{ value: 'Master of Civil Engineering Advanced', label: 'Master of Civil Engineering Advanced' },
	{ value: 'Bachelor of Forensic Science', label: 'Bachelor of Forensic Science' },
	{ value: 'Master of Business (Sport Management)', label: 'Master of Business (Sport Management)' },
	{ value: 'Master of Finance', label: 'Master of Finance' },
	{ value: 'Bachelor of Laws (Honours) / Bachelor of Government and International Relations', label: 'Bachelor of Laws (Honours) / Bachelor of Government and International Relations' },
	{ value: 'Master of Science', label: 'Master of Science' },
	{ value: 'Bachelor of Human Service / Bachelor of Criminology and Criminal Justice', label: 'Bachelor of Human Service / Bachelor of Criminology and Criminal Justice' },
	{ value: 'Bachelor of Engineering (Honours) / Bachelor of Business (Environmental Engineering)', label: 'Bachelor of Engineering (Honours) / Bachelor of Business (Environmental Engineering)' },
	{ value: 'Bachelor of Design (Graphic and Communication Design)', label: 'Bachelor of Design (Graphic and Communication Design)' },
	{ value: 'Master of Business (Accounting)', label: 'Master of Business (Accounting)' },
	{ value: 'Master of Business (Innovation and Entrepreneurship)', label: 'Master of Business (Innovation and Entrepreneurship)' },
	{ value: 'Master of Dentistry', label: 'Master of Dentistry' },
	{ value: 'Bachelor of Criminology and Criminal Justice', label: 'Bachelor of Criminology and Criminal Justice' },
	{ value: 'Master of Business (Business Law)', label: 'Master of Business (Business Law)' },
	{ value: 'Bachelor of Engineering (Honours) (Mechatronic Engineering)', label: 'Bachelor of Engineering (Honours) (Mechatronic Engineering)' },
	{ value: 'Bachelor of Sport Development / Bachelor of Business', label: 'Bachelor of Sport Development / Bachelor of Business' },
	{ value: 'Bachelor of Pharmacy', label: 'Bachelor of Pharmacy' },
	{ value: 'Bachelor of Engineering (Honours) / Bachelor of Business (Electronic Engineering)', label: 'Bachelor of Engineering (Honours) / Bachelor of Business (Electronic Engineering)' },
	{ value: 'Master of Engineering Project Management Advanced', label: 'Master of Engineering Project Management Advanced' },
	{ value: 'Direct Entry Program', label: 'Direct Entry Program' },
	{ value: 'Master of Electronic and Computer Engineering / Master of Electronic and Sport Engineering', label: 'Master of Electronic and Computer Engineering / Master of Electronic and Sport Engineering' },
	{ value: 'Bachelor of Dental Health Science', label: 'Bachelor of Dental Health Science' },
	{ value: 'Master of Clinical Psychology', label: 'Master of Clinical Psychology' },
	{ value: 'Master of Business Administration', label: 'Master of Business Administration' },
	{ value: 'Master of Environmental Engineering and Pollution Control', label: 'Master of Environmental Engineering and Pollution Control' },
	{ value: 'Bachelor of Arts (Screen Studies)', label: 'Bachelor of Arts (Screen Studies)' },
	{ value: 'Master of Urban and Environmental Planning', label: 'Master of Urban and Environmental Planning' },
	{ value: 'Bachelor of Environmental Science / Bachelor of Data Science', label: 'Bachelor of Environmental Science / Bachelor of Data Science' },
	{ value: 'Bachelor of Science', label: 'Bachelor of Science' },
	{ value: 'Bachelor of Marine Science', label: 'Bachelor of Marine Science' },
	{ value: 'Bachelor of Engineering (Honours) (Environmental Engineering)', label: 'Bachelor of Engineering (Honours) (Environmental Engineering)' },
	{ value: 'Bachelor of Engineering (Honours) (Mechatronics)', label: 'Bachelor of Engineering (Honours) (Mechatronics)' },
	{ value: 'Master of Catchment Science', label: 'Master of Catchment Science' },
	{ value: 'Master of Education', label: 'Master of Education' },
	{ value: 'Bachelor of Acting', label: 'Bachelor of Acting' },
	{ value: 'Bachelor of Aviation Management', label: 'Bachelor of Aviation Management' },
	{ value: 'Bachelor of Psychological Science / Bachelor of Criminology and Criminal Justice', label: 'Bachelor of Psychological Science / Bachelor of Criminology and Criminal Justice' },
	{ value: 'Master of Professional Engineering (Mechanical Engineering)', label: 'Master of Professional Engineering (Mechanical Engineering)' },
	{ value: 'Bachelor of Psychological Science / Bachelor of Business', label: 'Bachelor of Psychological Science / Bachelor of Business' },
	{ value: 'Bachelor of Criminology and Criminal Justice / Bachelor of Information Technology', label: 'Bachelor of Criminology and Criminal Justice / Bachelor of Information Technology' },
	{ value: 'Master of Information Technology', label: 'Master of Information Technology' },
	{ value: 'Bachelor of Engineering (Honours) (Electrical and Electronic Engineering)', label: 'Bachelor of Engineering (Honours) (Electrical and Electronic Engineering)' },
	{ value: 'Master of Cyber Security', label: 'Master of Cyber Security' },
	{ value: 'Bachelor of Engineering (Honours) / Bachelor of Business (Employment Relations)', label: 'Bachelor of Engineering (Honours) / Bachelor of Business (Employment Relations)' },
	{ value: 'Bachelor of Engineering (Honours) / Bachelor of Business (Management)', label: 'Bachelor of Engineering (Honours) / Bachelor of Business (Management)' },
	{ value: 'Bachelor of Design / Bachelor of Business', label: 'Bachelor of Design / Bachelor of Business' },
	{ value: 'Master of Screen Production', label: 'Master of Screen Production' },
	{ value: 'Bachelor of Engineering (Honours) / Bachelor of Business (Event Management)', label: 'Bachelor of Engineering (Honours) / Bachelor of Business (Event Management)' },
	{ value: 'Master of Electronic and Computer Engineering/Master of Electronic and Energy Engineering', label: 'Master of Electronic and Computer Engineering/Master of Electronic and Energy Engineering' },
	{ value: 'Master of Advanced Health Services Management', label: 'Master of Advanced Health Services Management' },
	{ value: 'Master of Business (Management)', label: 'Master of Business (Management)' },
	{ value: 'Bachelor of Science / Bachelor of Arts', label: 'Bachelor of Science / Bachelor of Arts' },
	{ value: 'Bachelor of Social Science', label: 'Bachelor of Social Science' },
	{ value: 'Bachelor of Engineering (Honours) / Bachelor of Business (Civil Engineering)', label: 'Bachelor of Engineering (Honours) / Bachelor of Business (Civil Engineering)' },
	{ value: 'Master of Arts in Visual Arts', label: 'Master of Arts in Visual Arts' },
	{ value: 'Bachelor of Intelligent Digital Technologies', label: 'Bachelor of Intelligent Digital Technologies' },
	{ value: 'Bachelor of Business / Bachelor of Data Science', label: 'Bachelor of Business / Bachelor of Data Science' },
	{ value: 'Bachelor of Government and International Relations', label: 'Bachelor of Government and International Relations' },
	{ value: 'Bachelor of Engineering (Honours) / Bachelor of Business (Sport Management)', label: 'Bachelor of Engineering (Honours) / Bachelor of Business (Sport Management)' },
	{ value: 'Bachelor of Aviation', label: 'Bachelor of Aviation' },
	{ value: 'Master of Global Development', label: 'Master of Global Development' },
	{ value: 'Bachelor of Engineering (Honours) (Software Engineering)', label: 'Bachelor of Engineering (Honours) (Software Engineering)' },
	{ value: 'Bachelor of Biomedical Science', label: 'Bachelor of Biomedical Science' },
	{ value: 'Bachelor of Health Science', label: 'Bachelor of Health Science' },
	{ value: 'Master of Global Public Health', label: 'Master of Global Public Health' },
	{ value: 'Bachelor of Arts / Bachelor of Business', label: 'Bachelor of Arts / Bachelor of Business' },
	{ value: 'Master of Electronic and Sports Engineering', label: 'Master of Electronic and Sports Engineering' },
	{ value: 'Bachelor of Information Technology', label: 'Bachelor of Information Technology' },
	{ value: 'Bachelor of Laws (Honours) / Bachelor of Arts', label: 'Bachelor of Laws (Honours) / Bachelor of Arts' },
	{ value: 'Bachelor of Laws (Honours) / Bachelor of Environmental Science', label: 'Bachelor of Laws (Honours) / Bachelor of Environmental Science' },
	{ value: 'Bachelor of Human Service', label: 'Bachelor of Human Service' },
	{ value: 'Bachelor of Pharmacology and Toxicology', label: 'Bachelor of Pharmacology and Toxicology' },
	{ value: 'Bachelor of Public Health', label: 'Bachelor of Public Health' },
	{ value: 'Bachelor of Sport Development', label: 'Bachelor of Sport Development' },
	{ value: 'Master of Design', label: 'Master of Design' },
	{ value: 'Master of Information Technology (Enterprise Architecture)', label: 'Master of Information Technology (Enterprise Architecture)' },
	{ value: 'Bachelor of Clinical Exercise Physiology', label: 'Bachelor of Clinical Exercise Physiology' },
	{ value: 'Bachelor of Government and International Relations / Bachelor of Criminology and Criminal Justice', label: 'Bachelor of Government and International Relations / Bachelor of Criminology and Criminal Justice' },
	{ value: 'Bachelor of Dental Hygiene', label: 'Bachelor of Dental Hygiene' },
	{ value: 'Bachelor of Engineering (Honours) / Bachelor of Data Science', label: 'Bachelor of Engineering (Honours) / Bachelor of Data Science' },
	{ value: 'Bachelor of Nursing', label: 'Bachelor of Nursing' },
	{ value: 'Bachelor of Nutrition and Dietetics', label: 'Bachelor of Nutrition and Dietetics' },
	{ value: 'Master of Primary Teaching', label: 'Master of Primary Teaching' },
	{ value: 'Master of Professional Engineering (Civil Engineering)', label: 'Master of Professional Engineering (Civil Engineering)' },
	{ value: 'Bachelor of Forensic Science / Bachelor of Criminology and Criminal Justice', label: 'Bachelor of Forensic Science / Bachelor of Criminology and Criminal Justice' },
	{ value: 'Master of Marketing/Master of International Tourism and Hospitality Management', label: 'Master of Marketing/Master of International Tourism and Hospitality Management' },
	{ value: 'Bachelor of Engineering (Honours) / Bachelor of Business (Sustainable Enterprise)', label: 'Bachelor of Engineering (Honours) / Bachelor of Business (Sustainable Enterprise)' },
	{ value: 'Bachelor of Engineering (Honours) / Bachelor of Business (Tourism Management)', label: 'Bachelor of Engineering (Honours) / Bachelor of Business (Tourism Management)' },
	{ value: 'Bachelor of Musical Theatre', label: 'Bachelor of Musical Theatre' },
	{ value: 'Master of Engineering Project Management', label: 'Master of Engineering Project Management' },
	{ value: 'Master of International Law Studies', label: 'Master of International Law Studies' },
	{ value: 'Master of Architecture', label: 'Master of Architecture' },
	{ value: 'Bachelor of Engineering (Honours) / Bachelor of Business (Logistics and Supply Chain Management)', label: 'Bachelor of Engineering (Honours) / Bachelor of Business (Logistics and Supply Chain Management)' },
	{ value: 'Bachelor of Science / Bachelor of Data Science', label: 'Bachelor of Science / Bachelor of Data Science' },
	{ value: 'Master of Business (Business Analytics)', label: 'Master of Business (Business Analytics)' },
	{ value: 'Master of Electronic and Computer Engineering', label: 'Master of Electronic and Computer Engineering' },
	{ value: 'Bachelor of Dental Technology', label: 'Bachelor of Dental Technology' },
	{ value: 'Bachelor of Aviation / Bachelor of Information Technology', label: 'Bachelor of Aviation / Bachelor of Information Technology' },
	{ value: 'Master of Biotechnology', label: 'Master of Biotechnology' },
	{ value: 'Master of Business (Digital Business)', label: 'Master of Business (Digital Business)' },
	{ value: 'Bachelor of International Tourism and Hotel Management / Bachelor of Business', label: 'Bachelor of International Tourism and Hotel Management / Bachelor of Business' },
	{ value: 'Bachelor of Engineering (Honours) / Bachelor of Business (Asian Business)', label: 'Bachelor of Engineering (Honours) / Bachelor of Business (Asian Business)' },
	{ value: 'Bachelor of Engineering (Honours) / Bachelor of Computer Science', label: 'Bachelor of Engineering (Honours) / Bachelor of Computer Science' },
	{ value: 'Master of Business (International Tourism and Hospitality Management)', label: 'Master of Business (International Tourism and Hospitality Management)' },
	{ value: 'Bachelor of Arts (Creative Writing)', label: 'Bachelor of Arts (Creative Writing)' },
	{ value: 'Master of Design Research', label: 'Master of Design Research' },
	{ value: 'Bachelor of Architectural Design', label: 'Bachelor of Architectural Design' },
	{ value: 'Bachelor of Communication and Journalism / Bachelor of Business', label: 'Bachelor of Communication and Journalism / Bachelor of Business' },
	{ value: 'Bachelor of Laws (Honours)', label: 'Bachelor of Laws (Honours)' },
	{ value: 'Master of Environment', label: 'Master of Environment' },
	{ value: 'Master of Visual Arts', label: 'Master of Visual Arts' },
	{ value: 'Bachelor of Animation', label: 'Bachelor of Animation' },
	{ value: 'Master of Business (Information Systems)', label: 'Master of Business (Information Systems)' },
	{ value: 'Bachelor of Engineering (Honours) / Bachelor of Business (Electrical Engineering)', label: 'Bachelor of Engineering (Honours) / Bachelor of Business (Electrical Engineering)' },
	{ value: 'Master of International Relations', label: 'Master of International Relations' },
	{ value: 'Bachelor of Exercise Science / Bachelor of Business', label: 'Bachelor of Exercise Science / Bachelor of Business' },
	{ value: 'Bachelor of Industrial Design', label: 'Bachelor of Industrial Design' },
	{ value: 'Master of Business (Supply Chain Management)', label: 'Master of Business (Supply Chain Management)' },
	{ value: 'Bachelor of Science / Bachelor of Information Technology', label: 'Bachelor of Science / Bachelor of Information Technology' },
	{ value: 'Bachelor of Engineering (Honours) / Bachelor of Business (Entrepreneurship and Self-Employment)', label: 'Bachelor of Engineering (Honours) / Bachelor of Business (Entrepreneurship and Self-Employment)' },
	{ value: 'Bachelor of Engineering (Honours) / Bachelor of Business (Marketing)', label: 'Bachelor of Engineering (Honours) / Bachelor of Business (Marketing)' },
	{ value: 'Bachelor of Dental Technology / Bachelor of Dental Prosthetics', label: 'Bachelor of Dental Technology / Bachelor of Dental Prosthetics' },
	{ value: 'Master of Professional Engineering (Electronic Engineering)', label: 'Master of Professional Engineering (Electronic Engineering)' },
	{ value: 'Master of Professional Engineering (Environmental Engineering)', label: 'Master of Professional Engineering (Environmental Engineering)' },
	{ value: 'Master of Social Work', label: 'Master of Social Work' },
	{ value: 'Bachelor of Psychological Science', label: 'Bachelor of Psychological Science' },
	{ value: 'Master of International Tourism and Hospitality Management', label: 'Master of International Tourism and Hospitality Management' },
	{ value: 'Master of International Business/Master of International Relations', label: 'Master of International Business/Master of International Relations' },
	{ value: 'Master of Business (Marketing)', label: 'Master of Business (Marketing)' },
	{ value: 'Bachelor of Laws (Honours) / Bachelor of Criminology and Criminal Justice', label: 'Bachelor of Laws (Honours) / Bachelor of Criminology and Criminal Justice' },
	{ value: 'Bachelor of Paramedicine', label: 'Bachelor of Paramedicine' },
	{ value: 'Bachelor of Education', label: 'Bachelor of Education' },
	{ value: 'Master of Business (Human Resource Management)', label: 'Master of Business (Human Resource Management)' },
	{ value: 'Master of Business (International Business)', label: 'Master of Business (International Business)' },
	{ value: 'Bachelor of Environmental Science', label: 'Bachelor of Environmental Science' },
	{ value: 'Bachelor of Social Work', label: 'Bachelor of Social Work' },
	{ value: 'Griffith English Language Institute - Direct Entry Program', label: 'Griffith English Language Institute - Direct Entry Program' },
	{ value: 'Bachelor of Engineering (Honours) / Bachelor of Business (Human Resource Management)', label: 'Bachelor of Engineering (Honours) / Bachelor of Business (Human Resource Management)' },
	{ value: 'Master of Electronic and Computer Engineering/Master of Electronic and Sport Engineering', label: 'Master of Electronic and Computer Engineering/Master of Electronic and Sport Engineering' },
	{ value: 'Master of Secondary Teaching', label: 'Master of Secondary Teaching' },
	{ value: 'Bachelor of Laws (Honours) / Bachelor of Psychological Science', label: 'Bachelor of Laws (Honours) / Bachelor of Psychological Science' },
	{ value: 'Master of Electronic and Computer Engineering / Master of Electronic and Energy Engineering', label: 'Master of Electronic and Computer Engineering / Master of Electronic and Energy Engineering' },
	{ value: 'JURIS DOCTOR', label: 'JURIS DOCTOR' },
	{ value: 'Bachelor of Medical Laboratory Science', label: 'Bachelor of Medical Laboratory Science' },
	{ value: 'Bachelor of Engineering (Honours) (Electronic Engineering)', label: 'Bachelor of Engineering (Honours) (Electronic Engineering)' },
	{ value: 'Bachelor of Engineering (Honours) / Bachelor of Business (Mechanical Engineering)', label: 'Bachelor of Engineering (Honours) / Bachelor of Business (Mechanical Engineering)' },
	{ value: 'Bachelor of Engineering (Honours) / Bachelor of Business (Real Estate and Property Development)', label: 'Bachelor of Engineering (Honours) / Bachelor of Business (Real Estate and Property Development)' },
	{ value: 'Bachelor of Engineering Honours (Mechanical Engineering)', label: 'Bachelor of Engineering Honours (Mechanical Engineering)' },
	{ value: 'Bachelor of Engineering (Honours) (Civil Engineering)', label: 'Bachelor of Engineering (Honours) (Civil Engineering)' },
	{ value: 'Bachelor of Engineering (Honours) (Electronic and Energy Engineering)', label: 'Bachelor of Engineering (Honours) (Electronic and Energy Engineering)' },
	{ value: 'Bachelor of Computer Science', label: 'Bachelor of Computer Science' },
	{ value: 'Bachelor of Engineering (Honours) / Bachelor of Business (International Business)', label: 'Bachelor of Engineering (Honours) / Bachelor of Business (International Business)' },
	{ value: 'Bachelor of Music', label: 'Bachelor of Music' },
	{ value: 'Bachelor of Games Design', label: 'Bachelor of Games Design' },
	{ value: 'Bachelor of Engineering (Honours) / Bachelor of Aviation', label: 'Bachelor of Engineering (Honours) / Bachelor of Aviation' },
	{ value: 'Master of Electronic and Energy Engineering', label: 'Master of Electronic and Energy Engineering' },
	{ value: 'Master of International Business', label: 'Master of International Business' },
	{ value: 'Masters Qualifying Program', label: 'Masters Qualifying Program' },
	{ value: 'Master of Aviation Management', label: 'Master of Aviation Management' },
	{ value: 'Master of Information Technology (Data Analytics)', label: 'Master of Information Technology (Data Analytics)' },
	{ value: 'Bachelor of Communication and Journalism', label: 'Bachelor of Communication and Journalism' },
	{ value: 'Bachelor of Exercise Science', label: 'Bachelor of Exercise Science' },
	{ value: 'Bachelor of Engineering (Honours) (Mechanical Engineering)', label: 'Bachelor of Engineering (Honours) (Mechanical Engineering)' },
	{ value: 'Bachelor of Science Advanced (Honours)', label: 'Bachelor of Science Advanced (Honours)' }
];

export default GriffithUniversityCourses;