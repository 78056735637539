const ConestogaCourses = [
	{ value: 'Diploma in Journalism', label: 'Diploma in Journalism' },
	{ value: 'Graduate Certificate in Big Data Solutions Architecture (Optional Co-op)', label: 'Graduate Certificate in Big Data Solutions Architecture (Optional Co-op)' },
	{ value: 'Graduate Certificate in Global Business Management', label: 'Graduate Certificate in Global Business Management' },
	{ value: 'Graduate Certificate in Information Technology Business Analysis', label: 'Graduate Certificate in Information Technology Business Analysis' },
	{ value: 'Graduate Certificate in Project Management', label: 'Graduate Certificate in Project Management' },
	{ value: 'Graduate Certificate in Financial Technology', label: 'Graduate Certificate in Financial Technology' },
	{ value: 'Graduate Certificate in Digital Solutions Management (Optional Co-op)', label: 'Graduate Certificate in Digital Solutions Management (Optional Co-op)' },
	{ value: 'Graduate Certificate in Strategic Global Business Management', label: 'Graduate Certificate in Strategic Global Business Management' },
	{ value: 'Diploma in Business - International Business', label: 'Diploma in Business - International Business' },
	{ value: 'Graduate Certificate in Process Quality Engineering', label: 'Graduate Certificate in Process Quality Engineering' },
	{ value: 'Graduate Certificate in Interactive Media Management - Interaction Design', label: 'Graduate Certificate in Interactive Media Management - Interaction Design' },
	{ value: 'Graduate Certificate in Business Management - Entrepreneurship and Innovation', label: 'Graduate Certificate in Business Management - Entrepreneurship and Innovation' },
	{ value: 'Graduate Certificate in Health Care Administration and Service Management', label: 'Graduate Certificate in Health Care Administration and Service Management' },
	{ value: 'Certificate in Data Processing and Reporting', label: 'Certificate in Data Processing and Reporting' },
	{ value: 'Graduate Certificate in Professional Accounting Practice', label: 'Graduate Certificate in Professional Accounting Practice' },
	{ value: 'Graduate Certificate in Administrative Business Management', label: 'Graduate Certificate in Administrative Business Management' },
	{ value: 'Graduate Certificate in Quality Assurance - Manufacturing and Management', label: 'Graduate Certificate in Quality Assurance - Manufacturing and Management' },
	{ value: 'Graduate Certificate in Information Technology Project Management', label: 'Graduate Certificate in Information Technology Project Management' },
	{ value: 'Graduate Certificate in Reporting Systems and Database Development', label: 'Graduate Certificate in Reporting Systems and Database Development' },
	{ value: 'Graduate Certificate in Strategic Marketing Communications', label: 'Graduate Certificate in Strategic Marketing Communications' },
	{ value: 'Graduate Certificate in Management and Leadership Development', label: 'Graduate Certificate in Management and Leadership Development' }
];

export default ConestogaCourses;
