const AucklandUniversityTechCourses = [
	{ value: "Postgraduate Certificate in Professional Accounting-Business School", label: "Postgraduate Certificate in Professional Accounting-Business School" },
	{ value: "Bachelor of Arts (Chinese Studies) and Bachelor of Business (Management and Leadership) Conjoint Programmes- Business School", label: "Bachelor of Arts (Chinese Studies) and Bachelor of Business (Management and Leadership) Conjoint Programmes- Business School" },
	{ value: "Bachelor of Arts (Chinese Studies) and Bachelor of Business (Human Resource Management and Employment Relations) Conjoint Programmes-Business School", label: "Bachelor of Arts (Chinese Studies) and Bachelor of Business (Human Resource Management and Employment Relations) Conjoint Programmes-Business School" },
	{ value: "Bachelor of Arts (Economics) and Bachelor of Business (Human Resource Management and Employment Relations) Conjoint Programmes-Business School", label: "Bachelor of Arts (Economics) and Bachelor of Business (Human Resource Management and Employment Relations) Conjoint Programmes-Business School" },
	{ value: "Bachelor of Arts (Economics) and Bachelor of Business (Management and Leadership) Conjoint Programmes-Business School", label: "Bachelor of Arts (Economics) and Bachelor of Business (Management and Leadership) Conjoint Programmes-Business School" },
	{ value: "Doctor of Philosophy (PhD)", label: "Doctor of Philosophy (PhD)" },
	{ value: "Master of Philosophy", label: "Master of Philosophy" }
];

export default AucklandUniversityTechCourses;