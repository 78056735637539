import React from 'react';
import { Box, VStack, Link as ChakraLink } from '@chakra-ui/react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { getUserDetailsFromToken } from '../../utils/getUserDetailsFromToken';

const AccountSidebar = () => {
	const userDetails = getUserDetailsFromToken();
	const { privilege } = userDetails || {};

	const location = useLocation();
	const isActive = (path) => location.pathname === path;

	// Define active link styles
	const activeLinkStyle = {
		fontWeight: "bold",
		color: "blue.500", // Example active color
		textDecoration: "underline",
	};

	return (
		<Box p={5} shadow="md" borderWidth="1px">
			<VStack align="stretch" spacing={4}>
				<ChakraLink as={RouterLink} to="/account" fontWeight="semibold" sx={isActive("/account") ? activeLinkStyle : undefined}>
					My Account
				</ChakraLink>
				{privilege === 'Admin' && (
					<>
						<ChakraLink as={RouterLink} to="/account/create-user" fontWeight="semibold" sx={isActive("/account/create-user") ? activeLinkStyle : undefined}>
							Create User
						</ChakraLink>
						<ChakraLink as={RouterLink} to="/account/manage-users" fontWeight="semibold" sx={isActive("/account/manage-users") ? activeLinkStyle : undefined}>
							Manage Users
						</ChakraLink>
						<ChakraLink as={RouterLink} to="/account/create-branch" fontWeight="semibold" sx={isActive("/account/create-branch") ? activeLinkStyle : undefined}>
							Create Branch
						</ChakraLink>
						<ChakraLink as={RouterLink} to="/account/manage-branches" fontWeight="semibold" sx={isActive("/account/manage-branches") ? activeLinkStyle : undefined}>
							Manage Branches
						</ChakraLink>
						<ChakraLink as={RouterLink} to="/account/create-counselor" fontWeight="semibold" sx={isActive("/account/create-counselor") ? activeLinkStyle : undefined}>
							Create Counselor
						</ChakraLink>
						<ChakraLink as={RouterLink} to="/account/manage-counselors" fontWeight="semibold" sx={isActive("/account/manage-counselors") ? activeLinkStyle : undefined}>
							Manage Counselors
						</ChakraLink>
					</>
				)}

				<ChakraLink as={RouterLink} to="/account/change-password" fontWeight="semibold" sx={isActive("/account/change-password") ? activeLinkStyle : undefined}>
					Change Password
				</ChakraLink>
			</VStack>
		</Box>
	);
};

export default AccountSidebar;
