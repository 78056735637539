const ESSCACourses = [
	{ value: "Bachelor in International Management", label: "Bachelor in International Management" },
	{ value: "Msc in Global Business Management", label: "Msc in Global Business Management" },
	{ value: "Msc in International Luxury Marketing", label: "Msc in International Luxury Marketing" },
	{ value: "MSc in International and Sustainable Management", label: "MSc in International and Sustainable Management" },
	{ value: "MSc in Digital and Big Data for value", label: "MSc in Digital and Big Data for value" },
	{ value: "MIM- Organisation, Management and Human Resource", label: "MIM- Organisation, Management and Human Resource" },
	{ value: "MIM- Finance, Accounting and Management control", label: "MIM- Finance, Accounting and Management control" },
	{ value: "MIM- Marketing and Retailing", label: "MIM- Marketing and Retailing" },
	{ value: "MIM-Strategy , Entrepreneurship and International Business", label: "MIM-Strategy , Entrepreneurship and International Business" },
	{ value: "Grande Ecole Programme (UG+PG)", label: "Grande Ecole Programme (UG+PG)" },
	{ value: "MSc in Management of Artificial Intelligence", label: "MSc in Management of Artificial Intelligence" }
];

export default ESSCACourses;