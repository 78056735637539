const USACourses = [
	{ value: '(Shorelight) Johns Hopkins University', label: '(Shorelight) Johns Hopkins University' },
	{ value: 'University of WisconsinMadison Division of Continuing Studies', label: 'University of WisconsinMadison Division of Continuing Studies' },
	{ value: 'University of California, Irvine Division of Continuing Education', label: 'University of California, Irvine Division of Continuing Education' },
	{ value: '(INTO) University of Arizona', label: '(INTO) University of Arizona' },
	{ value: '(Kaplan) Arizona State University', label: '(Kaplan) Arizona State University' },
	{ value: '(Shorelight) University of Utah', label: '(Shorelight) University of Utah' },
	{ value: 'Virginia Tech Language and Culture Institute', label: 'Virginia Tech Language and Culture Institute' },
	{ value: '(INTO) UMass Amherst', label: '(INTO) UMass Amherst' },
	{ value: 'Colorado State University', label: 'Colorado State University' },
	{ value: '(INTO) Oregon State University', label: '(INTO) Oregon State University' },
	{ value: 'University of South Florida', label: 'University of South Florida' },
	{ value: '(Kaplan) University of Connecticut', label: '(Kaplan) University of Connecticut' },
	{ value: 'University of California Riverside - College of Engineering', label: 'University of California Riverside - College of Engineering' },
	{ value: 'University of California Riverside Extension', label: 'University of California Riverside Extension' },
	{ value: 'University of California Riverside', label: 'University of California Riverside' },
	{ value: 'University of Delaware', label: 'University of Delaware' },
	{ value: 'Washington State University - Vancouver (USA) Campus', label: 'Washington State University - Vancouver (USA) Campus' },
	{ value: 'Washington State University - Tri-Cities Campus', label: 'Washington State University - Tri-Cities Campus' },
	{ value: 'Washington State University', label: 'Washington State University' },
	{ value: '(INTO) University of Alabama at Birmingham', label: '(INTO) University of Alabama at Birmingham' },
	{ value: 'University of Kansas', label: 'University of Kansas' },
	{ value: 'University of Cincinnati', label: 'University of Cincinnati' },
	{ value: 'University of New Mexico', label: 'University of New Mexico' },
	{ value: 'Virginia Commonwealth University', label: 'Virginia Commonwealth University' },
	{ value: '(Shorelight) University of South Carolina', label: '(Shorelight) University of South Carolina' },
	{ value: '(INTO) George Mason University', label: '(INTO) George Mason University' },
	{ value: 'Worcester Polytechnic Institute', label: 'Worcester Polytechnic Institute' },
	{ value: 'Drexel University', label: 'Drexel University' },
	{ value: '(Shorelight) Louisiana State University', label: '(Shorelight) Louisiana State University' },
	{ value: 'Georgia State University', label: 'Georgia State University' },
	{ value: '(Shorelight) Florida International University, Florida', label: '(Shorelight) Florida International University, Florida' },
	{ value: 'Florida International University, Florida', label: 'Florida International University, Florida' },
	{ value: 'The University of Alabama, Tuscaloosa', label: 'The University of Alabama, Tuscaloosa' },
	{ value: '(Shorelight) Auburn University, Alabama', label: '(Shorelight) Auburn University, Alabama' },
	{ value: 'University of North Texas - Frisco', label: 'University of North Texas - Frisco' },
	{ value: 'Oklahoma State University - Stillwater', label: 'Oklahoma State University - Stillwater' },
	{ value: 'University of North Texas', label: 'University of North Texas' },
	{ value: 'University of Albany, The State University of New York, Albany (SUNY Albany)', label: 'University of Albany, The State University of New York, Albany (SUNY Albany)' },
	{ value: 'University of Louisville', label: 'University of Louisville' },
	{ value: 'University of New Hampshire', label: 'University of New Hampshire' },
	{ value: 'University of Nevada', label: 'University of Nevada' },
	{ value: 'Rochester Institute of Technology', label: 'Rochester Institute of Technology' },
	{ value: 'Mississippi State University', label: 'Mississippi State University' },
	{ value: 'University of Wisconsin Milwaukee, Wisconsin', label: 'University of Wisconsin Milwaukee, Wisconsin' },
	{ value: '(INTO) Jefferson University, St, Philadelphia, Pennsylvania', label: '(INTO) Jefferson University, St, Philadelphia, Pennsylvania' },
	{ value: '(Shorelight) University of Illinois Chicago', label: '(Shorelight) University of Illinois Chicago' },
	{ value: '(INTO) Saint Louis University', label: '(INTO) Saint Louis University' },
	{ value: 'Northern Arizona University', label: 'Northern Arizona University' },
	{ value: 'Kent State University', label: 'Kent State University' },
	{ value: 'Portland State University', label: 'Portland State University' },
	{ value: '(Study Group) Florida Atlantic University', label: '(Study Group) Florida Atlantic University' },
	{ value: 'University of Maryland Baltimore County', label: 'University of Maryland Baltimore County' },
	{ value: 'Montana State University', label: 'Montana State University' },
	{ value: '(Shorelight) University of Massachusetts Boston (UMass Boston)', label: '(Shorelight) University of Massachusetts Boston (UMass Boston)' },
	{ value: 'University of Idaho', label: 'University of Idaho' },
	{ value: 'Ohio University', label: 'Ohio University' },
	{ value: 'New Jersey Institute of Technology', label: 'New Jersey Institute of Technology' },
	{ value: '(Shorelight) American University Washington D.C.', label: '(Shorelight) American University Washington D.C.' },
	{ value: 'University of Toledo', label: 'University of Toledo' },
	{ value: 'Missouri University of Science and Technology', label: 'Missouri University of Science and Technology' },
	{ value: 'Miami University', label: 'Miami University' },
	{ value: 'East Carolina University', label: 'East Carolina University' },
	{ value: 'University of Massachusetts Lowell (UMass Lowell)', label: 'University of Massachusetts Lowell (UMass Lowell)' },
	{ value: 'Marquette University', label: 'Marquette University' },
	{ value: 'The University of Memphis', label: 'The University of Memphis' },
	{ value: 'San Jose State University', label: 'San Jose State University' },
	{ value: 'Texas State University', label: 'Texas State University' },
	{ value: 'San Francisco State University', label: 'San Francisco State University' },
	{ value: 'California State University, Northridge', label: 'California State University, Northridge' },
	{ value: 'Florida Institute Of Technology', label: 'Florida Institute Of Technology' },
	{ value: 'Nova Southeastern University', label: 'Nova Southeastern University' },
	{ value: '(Study Group) DePaul University', label: '(Study Group) DePaul University' },
	{ value: 'Wright State University', label: 'Wright State University' },
	{ value: 'Rowan University', label: 'Rowan University' },
	{ value: 'University of Colorado Colorado Springs', label: 'University of Colorado Colorado Springs' },
	{ value: '(INTO) Hofstra University', label: '(INTO) Hofstra University' },
	{ value: '(Shorelight) University of Dayton', label: '(Shorelight) University of Dayton' },
	{ value: 'Central Michigan University', label: 'Central Michigan University' },
	{ value: 'California State University, Sacramento', label: 'California State University, Sacramento' },
	{ value: 'Kennesaw State University', label: 'Kennesaw State University' },
	{ value: 'University of Nebraska at Omaha', label: 'University of Nebraska at Omaha' },
	{ value: '(Study Group) Western Washington University', label: '(Study Group) Western Washington University' },
	{ value: 'Ball State University', label: 'Ball State University' },
	{ value: 'University of Massachusetts Dartmouth (UMass Dartmouth)', label: 'University of Massachusetts Dartmouth (UMass Dartmouth)' },
	{ value: 'Embry Riddle Aeronautical University', label: 'Embry Riddle Aeronautical University' },
	{ value: '(Shorelight) Cleveland State University', label: '(Shorelight) Cleveland State University' },
	{ value: '(Study Group) James Madison University', label: '(Study Group) James Madison University' },
	{ value: 'California State University, Los Angeles', label: 'California State University, Los Angeles' },
	{ value: 'Summer Discovery', label: 'Summer Discovery' },
	{ value: 'Texas A & M University - Corpus Christi', label: 'Texas A & M University - Corpus Christi' },
	{ value: 'Yeshiva University', label: 'Yeshiva University' },
	{ value: 'University of South Alabama', label: 'University of South Alabama' },
	{ value: 'Duquesne University', label: 'Duquesne University' },
	{ value: 'Middle Tennessee State University', label: 'Middle Tennessee State University' },
	{ value: '(Navitas) Queens College', label: '(Navitas) Queens College' },
	{ value: 'California State University, Fresno', label: 'California State University, Fresno' },
	{ value: 'Wichita State University', label: 'Wichita State University' },
	{ value: 'Grand Valley State University, Michigan', label: 'Grand Valley State University, Michigan' },
	{ value: 'Western Kentucky University', label: 'Western Kentucky University' },
	{ value: '(Kaplan) Pace University', label: '(Kaplan) Pace University' },
	{ value: 'Marshall University', label: 'Marshall University' },
	{ value: 'California State Polytechnic University, Pomona (College of the Extended University)', label: 'California State Polytechnic University, Pomona (College of the Extended University)' },
	{ value: 'Southern Illinois University Edwardsville', label: 'Southern Illinois University Edwardsville' },
	{ value: '(Shorelight) University of the Pacific', label: '(Shorelight) University of the Pacific' },
	{ value: 'Missouri State University', label: 'Missouri State University' },
	{ value: 'Eastern Michigan University', label: 'Eastern Michigan University' },
	{ value: 'Sonoma State University', label: 'Sonoma State University' },
	{ value: 'University of Wisconsin-Eau Claire', label: 'University of Wisconsin-Eau Claire' },
	{ value: 'California State University, East Bay', label: 'California State University, East Bay' },
	{ value: 'Tennessee Tech University', label: 'Tennessee Tech University' },
	{ value: 'California State University, San Bernardino', label: 'California State University, San Bernardino' },
	{ value: 'University of St. Thomas', label: 'University of St. Thomas' },
	{ value: 'The State University of New York at New Paltz (SUNY New Paltz)', label: 'The State University of New York at New Paltz (SUNY New Paltz)' },
	{ value: 'New York Institute of Technology', label: 'New York Institute of Technology' },
	{ value: '(Shorelight) Adelphi University', label: '(Shorelight) Adelphi University' },
	{ value: '(INTO) Suffolk University', label: '(INTO) Suffolk University' },
	{ value: 'Seattle University', label: 'Seattle University' },
	{ value: '(INTO) Illinois State University', label: '(INTO) Illinois State University' },
	{ value: 'Claremont Graduate University', label: 'Claremont Graduate University' },
	{ value: '(Study Group) Long Island University Post (LIU Post)', label: '(Study Group) Long Island University Post (LIU Post)' },
	{ value: '(Study Group) Long Island University Brooklyn (LIU Brooklyn)', label: '(Study Group) Long Island University Brooklyn (LIU Brooklyn)' },
	{ value: 'California State University, Dominguez Hills', label: 'California State University, Dominguez Hills' },
	{ value: 'Purdue University - Northwest', label: 'Purdue University - Northwest' },
	{ value: 'Indiana University of Pennsylvania', label: 'Indiana University of Pennsylvania' },
	{ value: 'The Catholic University of America', label: 'The Catholic University of America' },
	{ value: 'Texas A & M University - Kingsville', label: 'Texas A & M University - Kingsville' },
	{ value: 'State University of New York Oswego (SUNY Oswego)', label: 'State University of New York Oswego (SUNY Oswego)' },
	{ value: 'California State University, Bakersfield', label: 'California State University, Bakersfield' },
	{ value: 'Arkansas State University', label: 'Arkansas State University' },
	{ value: 'Weber State University', label: 'Weber State University' },
	{ value: 'University of WisconsinLa Crosse', label: 'University of WisconsinLa Crosse' },
	{ value: 'Marist College', label: 'Marist College' },
	{ value: 'Fairleigh Dickinson University (USA)', label: 'Fairleigh Dickinson University (USA)' },
	{ value: 'University of New Haven', label: 'University of New Haven' },
	{ value: 'Valparaiso University', label: 'Valparaiso University' },
	{ value: 'Youngstown State University', label: 'Youngstown State University' },
	{ value: '(Kaplan) Simmons University', label: '(Kaplan) Simmons University' },
	{ value: 'Western Illinois University - Quad Cities', label: 'Western Illinois University - Quad Cities' },
	{ value: 'University of Tampa', label: 'University of Tampa' },
	{ value: 'Murray State University', label: 'Murray State University' },
	{ value: 'California State University, Stanislaus', label: 'California State University, Stanislaus' },
	{ value: '(Study Group) University of Hartford', label: '(Study Group) University of Hartford' },
	{ value: 'Western New England University', label: 'Western New England University' },
	{ value: 'Sacred Heart University', label: 'Sacred Heart University' },
	{ value: 'University of Bridgeport', label: 'University of Bridgeport' },
	{ value: 'Rider University', label: 'Rider University' },
	{ value: 'The University of Scranton', label: 'The University of Scranton' },
	{ value: 'Western Illinois University - Macomb', label: 'Western Illinois University - Macomb' },
	{ value: 'Webster University - (St. Louis, Missouri)', label: 'Webster University - (St. Louis, Missouri)' },
	{ value: 'Webster University - (San Antonio, Texas', label: 'Webster University - (San Antonio, Texas' },
	{ value: 'Troy University', label: 'Troy University' },
	{ value: 'University of Michigan-Flint', label: 'University of Michigan-Flint' },
	{ value: 'California State University, Channel Islands', label: 'California State University, Channel Islands' },
	{ value: 'University Of Central Oklahoma', label: 'University Of Central Oklahoma' },
	{ value: 'Pittsburg State University', label: 'Pittsburg State University' },
	{ value: 'Massachusetts College of Pharmacy and Health Sciences (MCPHS University)', label: 'Massachusetts College of Pharmacy and Health Sciences (MCPHS University)' },
	{ value: 'University of Wisconsin-Stout', label: 'University of Wisconsin-Stout' },
	{ value: 'Southeast Missouri State University', label: 'Southeast Missouri State University' },
	{ value: 'Lawrence Technological University', label: 'Lawrence Technological University' },
	{ value: 'Auburn University at Montgomery', label: 'Auburn University at Montgomery' },
	{ value: 'Truman State University', label: 'Truman State University' },
	{ value: '(Shorelight) University of Illinois Springfield', label: '(Shorelight) University of Illinois Springfield' },
	{ value: '(INTO) Drew University', label: '(INTO) Drew University' },
	{ value: 'California Lutheran University', label: 'California Lutheran University' },
	{ value: 'Governors State University', label: 'Governors State University' },
	{ value: 'Marymount University', label: 'Marymount University' },
	{ value: 'California Baptist University', label: 'California Baptist University' },
	{ value: 'Saginaw Valley State University', label: 'Saginaw Valley State University' },
	{ value: 'Slippery Rock University', label: 'Slippery Rock University' },
	{ value: 'Dakota State University', label: 'Dakota State University' },
	{ value: 'Milwaukee School of Engineering', label: 'Milwaukee School of Engineering' },
	{ value: 'University of Colorado Denver', label: 'University of Colorado Denver' },
	{ value: 'Southern New Hampshire University', label: 'Southern New Hampshire University' },
	{ value: 'State University of New York, Fredonia', label: 'State University of New York, Fredonia' },
	{ value: 'Midwestern State University', label: 'Midwestern State University' },
	{ value: 'Johnson & Wales University', label: 'Johnson & Wales University' },
	{ value: 'SUNY Polytechnic Institute', label: 'SUNY Polytechnic Institute' },
	{ value: 'Park University', label: 'Park University' },
	{ value: 'Southwest Minnesota State University', label: 'Southwest Minnesota State University' },
	{ value: 'State University of New York, Geneseo (SUNY Geneseo)', label: 'State University of New York, Geneseo (SUNY Geneseo)' },
	{ value: 'Saint Marys College of California', label: 'Saint Marys College of California' },
	{ value: 'Augustana University', label: 'Augustana University' }
];

export default USACourses;