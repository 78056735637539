const LakeheadCourses = [
	{ value: 'Bachelor of Science - Natural Science', label: 'Bachelor of Science - Natural Science' },
	{ value: 'Honours Bachelor of Outdoor Recreation/Bachelor of Science - Natural Science', label: 'Honours Bachelor of Outdoor Recreation/Bachelor of Science - Natural Science' },
	{ value: 'Honours Bachelor of Arts - Philosophy', label: 'Honours Bachelor of Arts - Philosophy' },
	{ value: 'Honours Bachelor of Arts - Philosophy and Political Science', label: 'Honours Bachelor of Arts - Philosophy and Political Science' },
	{ value: 'Honours Bachelor of Arts - Political Science and Women\'s Studies', label: 'Honours Bachelor of Arts - Political Science and Women\'s Studies' },
	{ value: 'Honours Bachelor of Arts-Sociology and Women\'s Studies', label: 'Honours Bachelor of Arts-Sociology and Women\'s Studies' },
	{ value: 'Bachelor of Education - Sociology', label: 'Bachelor of Education - Sociology' },
	{ value: 'Master of Science in Archaeological Science', label: 'Master of Science in Archaeological Science' },
	{ value: 'Master of Business Administration with Advanced Studies in Management', label: 'Master of Business Administration with Advanced Studies in Management' },
	{ value: 'Master of Science in Computer Science - Co-operative Option (Project based)', label: 'Master of Science in Computer Science - Co-operative Option (Project based)' },
	{ value: 'Master of Science in Engineering in Civil Engineering (MSc Eng)', label: 'Master of Science in Engineering in Civil Engineering (MSc Eng)' },
	{ value: 'Master of Science in Engineering in Mechanical Engineering (MSc Eng)', label: 'Master of Science in Engineering in Mechanical Engineering (MSc Eng)' },
	{ value: 'Master of Science in Engineering in Chemical Engineering (MSc Eng)', label: 'Master of Science in Engineering in Chemical Engineering (MSc Eng)' },
	{ value: 'Master of Arts in Sociology (MA) with Specialization in Social Justice Studies', label: 'Master of Arts in Sociology (MA) with Specialization in Social Justice Studies' },
	{ value: 'Bachelor of Arts-Psychology', label: 'Bachelor of Arts-Psychology' },
	{ value: 'Bachelor of Arts - Women\'s Studies', label: 'Bachelor of Arts - Women\'s Studies' },
	{ value: 'Bachelor of Arts-Political Science & Pre-Law', label: 'Bachelor of Arts-Political Science & Pre-Law' },
	{ value: 'Honours Bachelor of Arts - Political Science & Pre - Law', label: 'Honours Bachelor of Arts - Political Science & Pre - Law' },
	{ value: 'Graduate Diploma in Professional Kinesiology', label: 'Graduate Diploma in Professional Kinesiology' },
	{ value: 'Bachelor of Business Administration', label: 'Bachelor of Business Administration' },
	{ value: 'Master of Science in Computer Science (Course based)', label: 'Master of Science in Computer Science (Course based)' },
	{ value: 'Honours Bachelor of Commerce -Major in International Business', label: 'Honours Bachelor of Commerce -Major in International Business' },
	{ value: 'Bachelor of Arts - Anthropology', label: 'Bachelor of Arts - Anthropology' }
];

export default LakeheadCourses;
