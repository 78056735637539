import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
	const navigate = useNavigate();

	useEffect(() => {
		// Perform logout actions here
		// For example, remove the token from local storage
		localStorage.removeItem('token');

		// Redirect the user to the homepage or login page after logout
		navigate('/login', { replace: true });
		window.location.reload();

		// Since this component is just for handling logout, no UI is rendered
	}, [navigate]);

	return null; // No UI is returned, as this component is purely for functionality
};

export default Logout;
