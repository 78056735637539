import { Container, Row } from 'reactstrap';
import colors from '../global/colors';
import { useNavigate } from 'react-router-dom';
import {
	Link as ChakraLink,
} from '@chakra-ui/react';
import Main from "../global/wrapper/Main";
import { Accordion } from 'react-bootstrap';
import { getUserDetailsFromToken } from "../utils/getUserDetailsFromToken";

const logoUrl = "/logo.png";

function HomePage() {
	const navigate = useNavigate();
	const userDetails = getUserDetailsFromToken();
	const isAdmin = userDetails?.privilege === 'Admin';
	const isEditor = userDetails?.privilege === 'Editor';
	const isViewer = userDetails?.privilege === 'Viewer';

	const buttonStyle = {
		display: 'flex',
		justifyContent: 'center',
		gridGap: '20px'
	};
	const h2Style = {
		fontSize: '52px'
	};
	const pStyle = {
		fontSize: '20px'
	};

	return (
		<Main>
			<Container>
				{isAdmin &&
					<>You have admin privilege</>
				}
				{isEditor &&
					<>You have editor privilege</>
				}
				{isViewer &&
					<>You have viewer privilege</>
				}
			</Container>
			{!isAdmin && !isEditor && !isViewer &&
				<>
					<section className='introduction text-center' style={{ marginBottom: '6rem' }}>
						<Container>
							<p><a href="/"><img style={{ margin: "0 auto" }} src={logoUrl} width="100" alt="Logo" className="App-logo" /></a></p>
							<h2 style={{ ...h2Style, color: colors.primary }}>End to End Study Abroad Recruitment Platform</h2>
							<h3 style={{ marginBottom: '20px' }}>GSC is an innovative student-recruitment platform that helps access to tailored solutions for seamless operations.</h3>
							<div style={buttonStyle}>
								<ChakraLink
									bg="darkblue"
									color="white"
									p={2}
									ml={2}
									onClick={() => navigate('/registration')}
								>
									Register Now
								</ChakraLink>
								<ChakraLink
									color="teal.500"
									bg="lightblue"
									p={2}
									ml={2}
									onClick={() => navigate('/login')}
								>
									Login Now
								</ChakraLink>
							</div>
						</Container>
					</section>
					<section className='uiInterface'>
						<Container>
							<div style={{ maxWidth: '56rem', margin: '0 auto' }}>
								<h2 style={h2Style}>Features for partners</h2>
								<div>
									<p style={pStyle}>At KC Overseas, we take great pride in empowering our valued partners with our advanced coursefinder.ai platform. With a user-friendly interface and an extensive database of courses, we are committed to ensuring a seamless navigation experience and effortless discovery of the perfect educational opportunities for your students.</p>
								</div>
							</div>
						</Container>
					</section>
					<section className='faq-section'>
						<Container className='p-5'>
							<Row>
								<h2 className='text-center' style={{ ...h2Style, marginBottom: '2rem', color: colors.primary }}>Frequently Asked Questions</h2>
								<Accordion defaultActiveKey="0">
									<Accordion.Item eventKey="0">
										<Accordion.Header>What is GSC?</Accordion.Header>
										<Accordion.Body>
											GSC is an innovative student-recruitment platform that offers tailored solutions for seamless operations in the study abroad recruitment process.
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="1">
										<Accordion.Header>How do I register with GSC?</Accordion.Header>
										<Accordion.Body>
											You can register with GSC by clicking the "Register with us" button on our homepage and following the registration process.
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="2">
										<Accordion.Header>What features does GSC offer for partners?</Accordion.Header>
										<Accordion.Body>
											GSC offers a range of features for partners, including a user-friendly interface, an extensive database of courses, and advanced tools for seamless student recruitment.
										</Accordion.Body>
									</Accordion.Item>
									{/* Add more Accordion.Items as needed */}
								</Accordion>
							</Row>
						</Container>
					</section>
				</>
			}
		</Main>
	)
}

export default HomePage;