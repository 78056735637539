export const educationInterestOptions = [
	{ value: 'Nursing', label: 'Nursing' },
	{ value: 'Economics', label: 'Economics' },
	{ value: 'Electronics, Telecommunications and', label: 'Electronics, Telecommunications and' },
	{ value: 'Electronics, Telecommunications and Informatics', label: 'Electronics, Telecommunications and Informatics' },
	{ value: 'Power and Aeronautical Engineering', label: 'Power and Aeronautical Engineering' },
	{ value: 'Aeronautical Engineering', label: 'Aeronautical Engineering' },
	{ value: 'Mechanical Engineering', label: 'Mechanical Engineering' },
	{ value: 'Arts', label: 'Arts' },
	{ value: 'Economics and Management', label: 'Economics and Management' },
	{ value: 'Humanities', label: 'Humanities' },
	{ value: 'Informatics', label: 'Informatics' },
	{ value: 'Natural Sciences', label: 'Natural Sciences' },
	{ value: 'Law', label: 'Law' },
	{ value: 'Music Academy', label: 'Music Academy' },
	{ value: 'Political Science and Diplomacy', label: 'Political Science and Diplomacy' },
	{ value: 'Social Sciences', label: 'Social Sciences' },
	{ value: 'All Faculties', label: 'All Faculties' },
	{ value: 'Pharmacy', label: 'Pharmacy' },
	{ value: 'Medicine', label: 'Medicine' },
	{ value: 'Odontology', label: 'Odontology' },
	{ value: 'Public Health', label: 'Public Health' },
	{ value: 'Veterinary Medicine', label: 'Veterinary Medicine' },
	{ value: 'Animal Husbandry Technology', label: 'Animal Husbandry Technology' },
	{ value: 'Social Science', label: 'Social Science' },
	{ value: 'Architecture and Urban Planning (FAUP)', label: 'Architecture and Urban Planning (FAUP)' },
	{ value: 'Civil Engineering', label: 'Civil Engineering' },
	{ value: 'Management', label: 'Management' },
	{ value: 'Computer Science and Information Technology', label: 'Computer Science and Information Technology' },
	{ value: 'Economics and Business Administration', label: 'Economics and Business Administration' },
	{ value: 'Civil and Environmental Engineering', label: 'Civil and Environmental Engineering' },
	{ value: 'Architecture', label: 'Architecture' },
	{ value: 'Physical Sciences', label: 'Physical Sciences' },
	{ value: 'Information Technology', label: 'Information Technology' },
	{ value: 'Statistics', label: 'Statistics' },
	{ value: 'Institute of Computer Science', label: 'Institute of Computer Science' },
	{ value: 'Computer Science', label: 'Computer Science' },
	{ value: 'Sports', label: 'Sports' },
	{ value: 'Electronics', label: 'Electronics' },
	{ value: 'Biomedical Sciences', label: 'Biomedical Sciences' },
	{ value: 'Mechanical Engineering', label: 'Mechanical Engineering' },
	{ value: 'Business Management', label: 'Business Management' },
	{ value: 'Chemical and Materials Technology', label: 'Chemical and Materials Technology' },
	{ value: 'Transport Engineering', label: 'Transport Engineering' },
	{ value: 'Fundamental Sciences', label: 'Fundamental Sciences' },
	{ value: 'Science', label: 'Science' },
	{ value: 'Physiotherapy', label: 'Physiotherapy' },
	{ value: 'Environmental Engineering', label: 'Environmental Engineering' },
	{ value: 'Mechanics', label: 'Mechanics' },
	{ value: 'Communication', label: 'Communication' },
	{ value: 'Electrical Engineering', label: 'Electrical Engineering' },
	{ value: 'Port and Shipping', label: 'Port and Shipping' },
	{ value: 'Mathematics and Computer Science', label: 'Mathematics and Computer Science' },
	{ value: 'Philosophy', label: 'Philosophy' },
	{ value: 'Animation', label: 'Animation' },
	{ value: 'Medical Science', label: 'Medical Science' },
	{ value: 'Aviation Management', label: 'Aviation Management' },
	{ value: 'Accounting', label: 'Accounting' },
	{ value: 'Engineering and Applied Science', label: 'Engineering and Applied Science' },
	{ value: 'ELECTRICAL AND CONTROL ENGINEERING', label: 'ELECTRICAL AND CONTROL ENGINEERING' },
	{ value: 'Management and Enterprise', label: 'Management and Enterprise' },
	{ value: 'Agriculture', label: 'Agriculture' },
	{ value: 'Chemistry', label: 'Chemistry' },
	{ value: 'Biological Sciences', label: 'Biological Sciences' },
	{ value: 'Applied physics and Instrumentation', label: 'Applied physics and Instrumentation' },
	{ value: 'Architecture', label: 'Architecture' },
	{ value: 'Tourism and Management', label: 'Tourism and Management' },
	{ value: 'Civil Engineering', label: 'Civil Engineering' },
	{ value: 'Electrical Engineering', label: 'Electrical Engineering' },
	{ value: 'Electronics and Information Technology', label: 'Electronics and Information Technology' },
	{ value: 'Mathematics and Information Science', label: 'Mathematics and Information Science' },
	{ value: 'Mechatronics', label: 'Mechatronics' },
	{ value: 'Production Engineering', label: 'Production Engineering' },
	{ value: 'Transport and Mechanical Engineering', label: 'Transport and Mechanical Engineering' },
	{ value: 'Materials Science and Applied Chemistry', label: 'Materials Science and Applied Chemistry' },
	{ value: 'Power and Electrical Engineering', label: 'Power and Electrical Engineering' },
	{ value: 'Engineering Economics and Management', label: 'Engineering Economics and Management' },
	{ value: 'Finance', label: 'Finance' },
	{ value: 'Electronics and Telecommunications', label: 'Electronics and Telecommunications' },
	{ value: 'Chemical Engineering', label: 'Chemical Engineering' },
	{ value: 'Culinary', label: 'Culinary' },
	{ value: 'Civil and Environmental Sciences', label: 'Civil and Environmental Sciences' },
	{ value: 'Forestry', label: 'Forestry' },
	{ value: 'Horticulture', label: 'Horticulture' },
	{ value: 'Human nutrition and Consumer Sciences', label: 'Human nutrition and Consumer Sciences' },
	{ value: 'Economic Sciences', label: 'Economic Sciences' },
	{ value: 'Applied Informatics and Mathematics', label: 'Applied Informatics and Mathematics' },
	{ value: 'Veterinary Medicine', label: 'Veterinary Medicine' },
	{ value: 'Aeronautical Engineering', label: 'Aeronautical Engineering' },
	{ value: 'Education and Psychology', label: 'Education and Psychology' },
	{ value: 'Biomedical Engineering', label: 'Biomedical Engineering' },
	{ value: 'Energy Systems', label: 'Energy Systems' },
	{ value: 'Fisheries', label: 'Fisheries' },
	{ value: 'Commerce', label: 'Commerce' },
	{ value: 'Engineering Business Management', label: 'Engineering Business Management' },
	{ value: 'Communication and Media', label: 'Communication and Media' },
	{ value: 'Marine Engineering', label: 'Marine Engineering' },
	{ value: 'Music', label: 'Music' },
	{ value: 'Petroleum engineering', label: 'Petroleum engineering' },
	{ value: 'Optometry', label: 'Optometry' },
	{ value: 'Hotel Management', label: 'Hotel Management' },
	{ value: 'Education', label: 'Education' },
	{ value: 'Academy of Music', label: 'Academy of Music' },
	{ value: 'Civil Engineering', label: 'Civil Engineering' },
	{ value: 'Electrical engineering and Communication', label: 'Electrical engineering and Communication' },
	{ value: 'Agronomy', label: 'Agronomy' },
	{ value: 'Fine arts', label: 'Fine arts' },
	{ value: 'Computing', label: 'Computing' },
	{ value: 'Business and Economics', label: 'Business and Economics' },
	{ value: 'Engineering', label: 'Engineering' },
	{ value: 'Business', label: 'Business' },
	{ value: 'Environmental Sciences', label: 'Environmental Sciences' },
	{ value: 'Bio-Medicine', label: 'Bio-Medicine' },
	{ value: 'Culture and Society', label: 'Culture and Society' },
	{ value: 'Health and Society', label: 'Health and Society' },
	{ value: 'Education and Society', label: 'Education and Society' },
	{ value: 'Tech and Society', label: 'Tech and Society' },
	{ value: 'Health Sciences', label: 'Health Sciences' },
	{ value: 'Behavioural Science', label: 'Behavioural Science' },
	{ value: 'Mechanical Engineering and Mechatronics', label: 'Mechanical Engineering and Mechatronics' },
	{ value: 'Archaeology', label: 'Archaeology' },
	{ value: 'Telecommunications and Electronics', label: 'Telecommunications and Electronics' },
	{ value: 'Marketing', label: 'Marketing' },
	{ value: 'Chemistry', label: 'Chemistry' },
	{ value: 'Physics', label: 'Physics' },
	{ value: 'Food and Beverages', label: 'Food and Beverages' },
	{ value: 'Aviation', label: 'Aviation' },
	{ value: 'Multimedia', label: 'Multimedia' },
	{ value: 'Public Administration', label: 'Public Administration' },
	{ value: 'Business and Social Science', label: 'Business and Social Science' },
	{ value: 'Hospitality Management', label: 'Hospitality Management' },
	{ value: 'Marine Environment', label: 'Marine Environment' },
	{ value: 'Innovation Management', label: 'Innovation Management' },
	{ value: 'Human Resource Management', label: 'Human Resource Management' },
	{ value: 'Health Care', label: 'Health Care' },
	{ value: 'Entrepreneurship & Innovation', label: 'Entrepreneurship & Innovation' },
	{ value: 'Supply Chain Management & Purchasing', label: 'Supply Chain Management & Purchasing' },
	{ value: 'Global Luxury Management', label: 'Global Luxury Management' },
	{ value: 'Biomedical Sciences', label: 'Biomedical Sciences' },
	{ value: 'Adventure', label: 'Adventure' },
	{ value: 'Nano Technology', label: 'Nano Technology' },
	{ value: 'Banking & Insurance', label: 'Banking & Insurance' },
	{ value: 'Hospitality', label: 'Hospitality' },
	{ value: 'Transport & Logistics', label: 'Transport & Logistics' },
	{ value: 'Urban & Real Estate Management', label: 'Urban & Real Estate Management' },
	{ value: 'PR & Event Management', label: 'PR & Event Management' },
	{ value: 'Faculty of Accounting', label: 'Faculty of Accounting' },
	{ value: 'Anthropology', label: 'Anthropology' },
	{ value: 'Astrophysics', label: 'Astrophysics' },
	{ value: 'Computing and Information Systems', label: 'Computing and Information Systems' },
	{ value: 'Medicine and Surgery', label: 'Medicine and Surgery' },
	{ value: 'Computing Science and Business Administration', label: 'Computing Science and Business Administration' },
	{ value: 'Criminology', label: 'Criminology' },
	{ value: 'English', label: 'English' },
	{ value: 'Psychology', label: 'Psychology' },
	{ value: 'Technology', label: 'Technology' },
	{ value: 'Education', label: 'Education' },
	{ value: 'Textiles', label: 'Textiles' },
	{ value: 'Graphic Communication Engineering', label: 'Graphic Communication Engineering' },
	{ value: 'Industrial Engineering', label: 'Industrial Engineering' },
	{ value: 'Dentistry', label: 'Dentistry' },
	{ value: 'Production', label: 'Production' },
	{ value: 'Electronic and Computer Engineering', label: 'Electronic and Computer Engineering' },
	{ value: 'Computer Science & Engineering / Information Technology', label: 'Computer Science & Engineering / Information Technology' },
	{ value: 'Electronics & Communication Engineering', label: 'Electronics & Communication Engineering' },
	{ value: 'Electrical & Electronics Engineering', label: 'Electrical & Electronics Engineering' },
	{ value: 'Instrumentation & Control / Electronics & Instrumentation Engineering', label: 'Instrumentation & Control / Electronics & Instrumentation Engineering' },
	{ value: 'Mechanical Engineering / Production Engineering / Industrial Engineering', label: 'Mechanical Engineering / Production Engineering / Industrial Engineering' },
	{ value: 'Chemical Engineering', label: 'Chemical Engineering' },
	{ value: 'Biotechnology- Engineering', label: 'Biotechnology- Engineering' },
	{ value: 'Civil Engineering', label: 'Civil Engineering' },
	{ value: 'Architecture', label: 'Architecture' },
	{ value: 'Pharmacy', label: 'Pharmacy' },
	{ value: 'Bio medical Engineering', label: 'Bio medical Engineering' },
	{ value: 'Food Technology / Dairy Technology', label: 'Food Technology / Dairy Technology' },
	{ value: 'Agriculture', label: 'Agriculture' },
	{ value: 'Veterinary Medicine', label: 'Veterinary Medicine' },
	{ value: 'Chemistry', label: 'Chemistry' },
	{ value: 'Geology', label: 'Geology' },
	{ value: 'B.Sc (Computer Science / Information Science / Electronics)', label: 'B.Sc (Computer Science / Information Science / Electronics)' },
	{ value: 'BCA', label: 'BCA' },
	{ value: 'Accounting and Finance', label: 'Accounting and Finance' },
	{ value: 'Business & Management', label: 'Business & Management' },
	{ value: 'Marketing Management', label: 'Marketing Management' },
	{ value: 'Digital Media', label: 'Digital Media' },
	{ value: 'Legal Studies', label: 'Legal Studies' },
	{ value: 'Business Studies', label: 'Business Studies' },
	{ value: 'Science & Technology', label: 'Science & Technology' },
	{ value: 'Lord Ashcroft International Business School', label: 'Lord Ashcroft International Business School' },
	{ value: 'Sports', label: 'Sports' },
	{ value: 'Architecture and Built Environment', label: 'Architecture and Built Environment' },
	{ value: 'Arts, Law & Social Sciences', label: 'Arts, Law & Social Sciences' },
	{ value: 'Health, Social Care & Education', label: 'Health, Social Care & Education' },
	{ value: 'BA Hospitality and Tourism Management', label: 'BA Hospitality and Tourism Management' },
	{ value: 'Alcohol and Drug Studies', label: 'Alcohol and Drug Studies' },
	{ value: 'Human Resource', label: 'Human Resource' },
	{ value: 'Maths', label: 'Maths' },
	{ value: 'Science in Ocean Engineering', label: 'Science in Ocean Engineering' },
	{ value: 'Business Administration', label: 'Business Administration' },
	{ value: 'Business and Professional Programs', label: 'Business and Professional Programs' },
	{ value: 'Language Arts Division', label: 'Language Arts Division' },
	{ value: 'Kinesiology/Athletics/Dance Division', label: 'Kinesiology/Athletics/Dance Division' },
	{ value: 'Science/Mathematics/Technology Division', label: 'Science/Mathematics/Technology Division' },
	{ value: 'Social Science/Creative Arts Division', label: 'Social Science/Creative Arts Division' },
	{ value: 'Administration of Justice', label: 'Administration of Justice' },
	{ value: 'Addiction Studies', label: 'Addiction Studies' },
	{ value: 'Art', label: 'Art' },
	{ value: 'Astronomy', label: 'Astronomy' },
	{ value: 'Arts and Humanities', label: 'Arts and Humanities' },
	{ value: 'Business and Public Administration', label: 'Business and Public Administration' },
	{ value: 'Natural sciences, Mathematics and Engineering', label: 'Natural sciences, Mathematics and Engineering' },
	{ value: 'Journalism', label: 'Journalism' },
	{ value: 'Global Business & Entrepreneurship', label: 'Global Business & Entrepreneurship' },
	{ value: 'Paralegal', label: 'Paralegal' },
	{ value: 'Business Application & Technology', label: 'Business Application & Technology' },
	{ value: 'Design & Social Inquiry', label: 'Design & Social Inquiry' },
	{ value: 'Bachelor of Arts', label: 'Bachelor of Arts' },
	{ value: 'Liberal Arts', label: 'Liberal Arts' },
	{ value: 'International Economics and Business', label: 'International Economics and Business' },
	{ value: 'Computing Science', label: 'Computing Science' },
	{ value: 'Fashion Portfolio', label: 'Fashion Portfolio' },
	{ value: 'Visual Communication', label: 'Visual Communication' },
	{ value: 'Curatorial Practice', label: 'Curatorial Practice' },
	{ value: 'Teaching English to Speakers of Other Languages', label: 'Teaching English to Speakers of Other Languages' },
	{ value: 'Creative Writing', label: 'Creative Writing' },
	{ value: 'Advertising Practice', label: 'Advertising Practice' },
	{ value: 'Arts Management', label: 'Arts Management' },
	{ value: 'Heritage Management', label: 'Heritage Management' },
	{ value: 'Creative Technologies and Enterprise', label: 'Creative Technologies and Enterprise' },
	{ value: 'Art & Design', label: 'Art & Design' },
	{ value: 'Marine Technologies and Natural Sciences, Klaipeda', label: 'Marine Technologies and Natural Sciences, Klaipeda' },
	{ value: 'Textile Engineering', label: 'Textile Engineering' },
	{ value: 'UPT - Masters', label: 'UPT - Masters' },
	{ value: 'Theology', label: 'Theology' },
	{ value: 'Molecular life cycle', label: 'Molecular life cycle' },
	{ value: 'Language', label: 'Language' },
	{ value: 'International relations and Political Science', label: 'International relations and Political Science' },
	{ value: 'Mining', label: 'Mining' },
	{ value: 'International Affairs', label: 'International Affairs' },
	{ value: 'Innovation', label: 'Innovation' },
	{ value: 'Entrepreneurhip', label: 'Entrepreneurhip' },
	{ value: 'Digital Media', label: 'Digital Media' },
	{ value: 'Information System', label: 'Information System' },
	{ value: 'International Purchase', label: 'International Purchase' },
	{ value: 'Fashion and Luxury Retail Management', label: 'Fashion and Luxury Retail Management' },
	{ value: 'Grand Ecole Master', label: 'Grand Ecole Master' },
	{ value: 'International Business', label: 'International Business' },
	{ value: 'Biomass & Waste for Energy & Materials', label: 'Biomass & Waste for Energy & Materials' },
	{ value: 'Pharmaceutical Engineering', label: 'Pharmaceutical Engineering' },
	{ value: 'BBA', label: 'BBA' },
	{ value: 'MBA', label: 'MBA' },
	{ value: 'Mathematics and Natural Sciences', label: 'Mathematics and Natural Sciences' },
	{ value: 'International Management', label: 'International Management' },
	{ value: 'Faculty in Finance', label: 'Faculty in Finance' },
	{ value: 'Diplomacy', label: 'Diplomacy' },
	{ value: 'Health Sciences', label: 'Health Sciences' },
	{ value: 'Social Science', label: 'Social Science' },
	{ value: 'Humanities and Educational Sciences', label: 'Humanities and Educational Sciences' },
	{ value: 'Transport means: Railway vehicles', label: 'Transport means: Railway vehicles' },
	{ value: 'Regional Development and Governance', label: 'Regional Development and Governance' },
	{ value: 'Tourism', label: 'Tourism' },
	{ value: 'Informatics and Technologies of Communication', label: 'Informatics and Technologies of Communication' },
	{ value: 'Food Sciences', label: 'Food Sciences' },
	{ value: 'European Language and Culture', label: 'European Language and Culture' },
	{ value: 'International Relations', label: 'International Relations' },
	{ value: 'Marketing', label: 'Marketing' },
	{ value: 'Biology', label: 'Biology' },
	{ value: 'Chemical Technology', label: 'Chemical Technology' },
	{ value: 'Mechanical Engineering and Design', label: 'Mechanical Engineering and Design' },
	{ value: 'Banking, Finance and Insurance Sciences - Economics', label: 'Banking, Finance and Insurance Sciences - Economics' },
	{ value: 'Medicine and Surgery', label: 'Medicine and Surgery' },
	{ value: 'Economics and Law', label: 'Economics and Law' },
	{ value: 'Engineering and Information Technology', label: 'Engineering and Information Technology' },
	{ value: 'Sciences', label: 'Sciences' },
	{ value: 'Medical School', label: 'Medical School' },
	{ value: 'Management Studies', label: 'Management Studies' },
	{ value: 'Communication', label: 'Communication' },
	{ value: 'Business Economics and Management', label: 'Business Economics and Management' },
	{ value: 'Computers', label: 'Computers' },
	{ value: 'Econometrics', label: 'Econometrics' },
	{ value: 'Language and communication', label: 'Language and communication' },
	{ value: 'Science Behavioural and Social Sciences', label: 'Science Behavioural and Social Sciences' },
	{ value: 'System Dynamics', label: 'System Dynamics' },
	{ value: 'Geography Planing and Environment', label: 'Geography Planing and Environment' },
	{ value: 'Medical Science', label: 'Medical Science' },
	{ value: 'Public Administration and Political Science', label: 'Public Administration and Political Science' },
	{ value: 'Philosophy, Theology and Religious', label: 'Philosophy, Theology and Religious' },
	{ value: 'Logistics', label: 'Logistics' },
	{ value: 'Life Sciences', label: 'Life Sciences' },
	{ value: 'Biotechnology', label: 'Biotechnology' },
	{ value: 'Design', label: 'Design' },
	{ value: 'Electrical Engineering and Informatics', label: 'Electrical Engineering and Informatics' },
	{ value: 'Manufacturing Technologies', label: 'Manufacturing Technologies' },
	{ value: 'Aeronautics', label: 'Aeronautics' },
	{ value: 'Media Science', label: 'Media Science' },
	{ value: 'Sociology', label: 'Sociology' },
	{ value: 'Veterinary Sciences', label: 'Veterinary Sciences' },
	{ value: 'Social Work', label: 'Social Work' },
	{ value: 'Development', label: 'Development' },
	{ value: 'Signal Processing', label: 'Signal Processing' },
	{ value: 'Special Education', label: 'Special Education' },
	{ value: 'Entrepreneurship', label: 'Entrepreneurship' },
	{ value: 'Operational and Economics of transport and communication', label: 'Operational and Economics of transport and communication' },
	{ value: 'Digital Marketing', label: 'Digital Marketing' },
	{ value: 'Sport Management', label: 'Sport Management' },
	{ value: 'International Finance', label: 'International Finance' },
	{ value: 'Oil and Gas Management', label: 'Oil and Gas Management' },
	{ value: 'Hospitality Management', label: 'Hospitality Management' },
	{ value: 'Philology', label: 'Philology' },
	{ value: 'COMPUTER SCIENCE AND ENGINEERING', label: 'COMPUTER SCIENCE AND ENGINEERING' },
	{ value: 'ELECTRICAL ENGINEERING AND INFORMATION TECHNOLOGIES', label: 'ELECTRICAL ENGINEERING AND INFORMATION TECHNOLOGIES' },
	{ value: 'INSTITUTE OF EARTHQUAKE ENGINEERING AND ENGINEERING SEISMOLOGY', label: 'INSTITUTE OF EARTHQUAKE ENGINEERING AND ENGINEERING SEISMOLOGY' },
	{ value: 'ICT', label: 'ICT' },
	{ value: 'Public Administration and Political Science ', label: 'Public Administration and Political Science ' },
	{ value: 'Computer Science', label: 'Computer Science' },
	{ value: 'Dental Surgery', label: 'Dental Surgery' },
	{ value: 'Law', label: 'Law' },
	{ value: 'Medicine & Surgery', label: 'Medicine & Surgery' },
	{ value: 'Social Wellbeing', label: 'Social Wellbeing' },
	{ value: 'Institute of Earth Systems', label: 'Institute of Earth Systems' },
	{ value: 'Visual Representation', label: 'Visual Representation' },
	{ value: 'Graphic Design', label: 'Graphic Design' },
	{ value: 'Media and Design', label: 'Media and Design' },
	{ value: 'Photography', label: 'Photography' },
	{ value: 'Environmental Design', label: 'Environmental Design' },
	{ value: 'Food Science', label: 'Food Science' },
	{ value: 'Horticulture', label: 'Horticulture' },
	{ value: 'Management and Technology', label: 'Management and Technology' },
	{ value: 'Health Sciences', label: 'Health Sciences' },
	{ value: 'Business Economics', label: 'Business Economics' },
	{ value: 'Shipping', label: 'Shipping' },
	{ value: 'Human Science', label: 'Human Science' },
	{ value: 'CYBER SECURITY', label: 'CYBER SECURITY' },
	{ value: 'Culinary Arts', label: 'Culinary Arts' },
	{ value: 'History', label: 'History' },
	{ value: 'Psychology', label: 'Psychology' },
	{ value: 'Data Science', label: 'Data Science' },
	{ value: 'Loss and Healing', label: 'Loss and Healing' },
	{ value: 'Engineering with FIU', label: 'Engineering with FIU' },
	{ value: 'Forensics', label: 'Forensics' },
	{ value: 'Human Resource', label: 'Human Resource' },
	{ value: 'JD/MS', label: 'JD/MS' },
	{ value: 'Divinity', label: 'Divinity' },
	{ value: 'Science in Autism', label: 'Science in Autism' },
	{ value: 'Big Data', label: 'Big Data' },
	{ value: 'Bioethics', label: 'Bioethics' },
	{ value: 'Science in Guidance and Counseling', label: 'Science in Guidance and Counseling' },
	{ value: 'Instructional Design and Technology', label: 'Instructional Design and Technology' },
	{ value: 'Homeland Security', label: 'Homeland Security' },
	{ value: 'School of Management', label: 'School of Management' },
	{ value: 'Material Science and Engineering', label: 'Material Science and Engineering' },
	{ value: 'Mechanical Engineering and Informatics', label: 'Mechanical Engineering and Informatics' },
	{ value: 'Earth Science and Engineering', label: 'Earth Science and Engineering' },
	{ value: 'Finance', label: 'Finance' },
	{ value: 'Analytics', label: 'Analytics' },
	{ value: 'Masters in Middle Eastern Studies', label: 'Masters in Middle Eastern Studies' },
	{ value: 'English and American Studies', label: 'English and American Studies' },
	{ value: 'American Studies', label: 'American Studies' },
	{ value: 'Telecommunications', label: 'Telecommunications' },
	{ value: 'Nanotechnology', label: 'Nanotechnology' },
	{ value: 'Ocean technology', label: 'Ocean technology' },
	{ value: 'International Trade', label: 'International Trade' },
	{ value: 'Web Development', label: 'Web Development' },
	{ value: 'Industrial Systems Analytics', label: 'Industrial Systems Analytics' },
	{ value: 'Hospitality and Tourism Management', label: 'Hospitality and Tourism Management' },
	{ value: 'Information Technology', label: 'Information Technology' },
	{ value: 'Graphic & Communication', label: 'Graphic & Communication' },
	{ value: 'Innovation, Strategy, and Entrepreneurship', label: 'Innovation, Strategy, and Entrepreneurship' },
	{ value: 'Project Management', label: 'Project Management' },
	{ value: 'Green technologies and Monitoring', label: 'Green technologies and Monitoring' },
	{ value: 'BSC in Adaptronics', label: 'BSC in Adaptronics' },
	{ value: 'Chemical Technologies', label: 'Chemical Technologies' },
	{ value: 'Bsc in Data Engineering', label: 'Bsc in Data Engineering' },
	{ value: 'Electronics and mobile communication', label: 'Electronics and mobile communication' },
	{ value: 'Medical Engineering', label: 'Medical Engineering' },
	{ value: 'Control Engineering and Robotics', label: 'Control Engineering and Robotics' },
	{ value: 'Technical Translation', label: 'Technical Translation' },
	{ value: 'Electronics and Telecommunications', label: 'Electronics and Telecommunications' },
	{ value: 'Bachelor of Science civil engineering', label: 'Bachelor of Science civil engineering' },
	{ value: 'B.sc Electric and Hybrid Vehicles Engineering', label: 'B.sc Electric and Hybrid Vehicles Engineering' },
	{ value: 'Informatics', label: 'Informatics' },
	{ value: 'Industrial Engineering', label: 'Industrial Engineering' },
	{ value: 'Bachelor of Science Aerospace Engineering', label: 'Bachelor of Science Aerospace Engineering' },
	{ value: 'Supply Chain Management', label: 'Supply Chain Management' },
	{ value: 'Masters of Science Photonics', label: 'Masters of Science Photonics' },
	{ value: 'Masters in Science Aero space engineering', label: 'Masters in Science Aero space engineering' },
	{ value: 'Bsc in Industrial Design and Multimedia', label: 'Bsc in Industrial Design and Multimedia' },
	{ value: 'BSc in Journalism and social networks', label: 'BSc in Journalism and social networks' },
	{ value: 'Bsc in Advertising, PR and marketing', label: 'Bsc in Advertising, PR and marketing' },
	{ value: 'Masters in Science Transport', label: 'Masters in Science Transport' },
	{ value: 'Communication and Digital Marketing', label: 'Communication and Digital Marketing' },
	{ value: 'Sports Management and Golf', label: 'Sports Management and Golf' },
	{ value: 'Physiotherapy', label: 'Physiotherapy' },
	{ value: 'English Philology', label: 'English Philology' },
	{ value: 'Translation Studies', label: 'Translation Studies' },
	{ value: 'Business and Export management', label: 'Business and Export management' },
	{ value: 'Beauty Science', label: 'Beauty Science' },
	{ value: 'International Tourism', label: 'International Tourism' },
	{ value: 'Sport Science', label: 'Sport Science' },
	{ value: 'International Business Management', label: 'International Business Management' },
	{ value: 'Multimedia Design and Communication', label: 'Multimedia Design and Communication' },
	{ value: 'Logistics Management', label: 'Logistics Management' },
	{ value: 'Commerce Management', label: 'Commerce Management' },
	{ value: 'Digital Concept Development', label: 'Digital Concept Development' },
	{ value: 'International Development Studies', label: 'International Development Studies' },
	{ value: 'System Engineering', label: 'System Engineering' },
	{ value: 'European Forestry', label: 'European Forestry' },
	{ value: 'Automotive Engineering', label: 'Automotive Engineering' },
	{ value: 'Horticultural Engineering', label: 'Horticultural Engineering' },
	{ value: 'Nuclear Power Engineering', label: 'Nuclear Power Engineering' },
	{ value: 'MSC Aerospace Engineering', label: 'MSC Aerospace Engineering' },
	{ value: 'Cybernetics and Robotics', label: 'Cybernetics and Robotics' },
	{ value: 'Nuclear Physics', label: 'Nuclear Physics' },
	{ value: 'Biomedical and Clinical Technology', label: 'Biomedical and Clinical Technology' },
	{ value: 'BSc INFOMATICS', label: 'BSc INFOMATICS' },
	{ value: 'Geography', label: 'Geography' },
	{ value: 'Informatics & Management', label: 'Informatics & Management' },
	{ value: 'Systems', label: 'Systems' },
	{ value: 'Engineering', label: 'Engineering' },
	{ value: 'Computer and Networks Systems', label: 'Computer and Networks Systems' },
	{ value: 'Legal Practice', label: 'Legal Practice' },
	{ value: 'Wood Technology', label: 'Wood Technology' },
	{ value: 'Audiology', label: 'Audiology' },
	{ value: 'Pharmacy', label: 'Pharmacy' },
	{ value: 'Physiotherapy', label: 'Physiotherapy' },
	{ value: 'Physiology', label: 'Physiology' },
	{ value: 'Medical Imaging', label: 'Medical Imaging' },
	{ value: 'Radiotherapy', label: 'Radiotherapy' },
	{ value: 'Orthoptics', label: 'Orthoptics' },
	{ value: 'Osteopathy', label: 'Osteopathy' },
	{ value: 'Occupational Therapy', label: 'Occupational Therapy' },
	{ value: 'Organizational Management', label: 'Organizational Management' },
	{ value: 'Business Studies', label: 'Business Studies' },
	{ value: 'Applied mathematics', label: 'Applied mathematics' },
	{ value: 'Business Communication', label: 'Business Communication' },
	{ value: 'Business Innovation', label: 'Business Innovation' },
	{ value: 'Computer and Automation', label: 'Computer and Automation' },
	{ value: 'Engineering Management', label: 'Engineering Management' },
	{ value: 'Information Sciences', label: 'Information Sciences' },
	{ value: 'Human Resources', label: 'Human Resources' },
	{ value: 'Management Assistance', label: 'Management Assistance' },
	{ value: 'Business Information', label: 'Business Information' },
	{ value: 'Intercultural Studies for Business', label: 'Intercultural Studies for Business' },
	{ value: 'Social Work and Social Rehabilitation', label: 'Social Work and Social Rehabilitation' },
	{ value: 'Career Education', label: 'Career Education' },
	{ value: 'Special Education', label: 'Special Education' },
	{ value: 'Pedagogy of Music', label: 'Pedagogy of Music' },
	{ value: 'Physical Education', label: 'Physical Education' },
	{ value: 'Mathematics and Science', label: 'Mathematics and Science' },
	{ value: 'Humanities', label: 'Humanities' },
	{ value: 'Protestant Theology', label: 'Protestant Theology' },
	{ value: 'Mathematics', label: 'Mathematics' },
	{ value: 'Network Technologies', label: 'Network Technologies' },
	{ value: 'Electric Power Systems', label: 'Electric Power Systems' },
	{ value: 'B.E in Automatics and Robotics', label: 'B.E in Automatics and Robotics' },
	{ value: 'Industrial Design', label: 'Industrial Design' },
	{ value: 'Automation', label: 'Automation' },
	{ value: 'Lifestyle Medicine', label: 'Lifestyle Medicine' },
	{ value: 'Opthalmology', label: 'Opthalmology' },
	{ value: 'Internal Security', label: 'Internal Security' },
	{ value: 'Philology', label: 'Philology' },
	{ value: 'Gastroenterology', label: 'Gastroenterology' },
	{ value: 'Cultural industries', label: 'Cultural industries' },
	{ value: 'Journalism', label: 'Journalism' },
	{ value: 'Banking and Finance', label: 'Banking and Finance' },
	{ value: 'Agricultural Biotechnology', label: 'Agricultural Biotechnology' },
	{ value: 'Rural Development', label: 'Rural Development' },
	{ value: 'Foundation Program', label: 'Foundation Program' },
	{ value: 'Media', label: 'Media' },
	{ value: 'Analytical chemistry', label: 'Analytical chemistry' },
	{ value: 'Applied physics', label: 'Applied physics' },
	{ value: 'Primary Education', label: 'Primary Education' },
	{ value: 'Cultural Heritage', label: 'Cultural Heritage' },
	{ value: 'Animal Behaviour', label: 'Animal Behaviour' },
	{ value: 'Architectural Technology', label: 'Architectural Technology' },
	{ value: 'Music Technology', label: 'Music Technology' },
	{ value: 'Bioscience', label: 'Bioscience' },
	{ value: 'Building Surveying', label: 'Building Surveying' },
	{ value: 'Digital Economy', label: 'Digital Economy' },
	{ value: 'International Business Law', label: 'International Business Law' },
	{ value: 'International Law', label: 'International Law' },
	{ value: 'Photography', label: 'Photography' },
	{ value: 'Building Constructions', label: 'Building Constructions' },
	{ value: 'Geotechnics', label: 'Geotechnics' },
	{ value: 'Public Administration', label: 'Public Administration' },
	{ value: 'Rehabilation', label: 'Rehabilation' },
	{ value: 'Physical Activities', label: 'Physical Activities' },
	{ value: 'Governance', label: 'Governance' },
	{ value: 'Game and Leisure', label: 'Game and Leisure' },
	{ value: 'Economic Relations', label: 'Economic Relations' },
	{ value: 'Information Security in Telecommunication', label: 'Information Security in Telecommunication' },
	{ value: 'Psychological Maintenance', label: 'Psychological Maintenance' },
	{ value: 'Information Security', label: 'Information Security' },
	{ value: 'Design of Electronic', label: 'Design of Electronic' },
	{ value: 'Machine and Data Processing', label: 'Machine and Data Processing' },
	{ value: 'Electrochemical', label: 'Electrochemical' },
	{ value: 'Quality Control', label: 'Quality Control' },
	{ value: 'Medicinal Drugs', label: 'Medicinal Drugs' },
	{ value: 'Bioecology', label: 'Bioecology' },
	{ value: 'Vocational Training', label: 'Vocational Training' },
	{ value: 'Energy Management', label: 'Energy Management' },
	{ value: 'Woodworking Technologies', label: 'Woodworking Technologies' },
	{ value: 'Forestry Engineering', label: 'Forestry Engineering' },
	{ value: 'Landscape Architecture', label: 'Landscape Architecture' },
	{ value: 'Machinery Manufacturing', label: 'Machinery Manufacturing' },
	{ value: 'Power Engineering', label: 'Power Engineering' },
	{ value: 'Merchandising and Goods Expertise', label: 'Merchandising and Goods Expertise' },
	{ value: 'Professional Studies', label: 'Professional Studies' },
	{ value: 'Political and Juridical Sciences', label: 'Political and Juridical Sciences' },
	{ value: 'Bachelor in Art and Design', label: 'Bachelor in Art and Design' },
	{ value: 'Environmental Studies', label: 'Environmental Studies' },
	{ value: 'Urban Design', label: 'Urban Design' },
	{ value: 'Urban Social Development and Human Resources', label: 'Urban Social Development and Human Resources' },
	{ value: 'Immovable Property', label: 'Immovable Property' },
	{ value: 'Computer Mechatronics', label: 'Computer Mechatronics' },
	{ value: 'Technology and Equipment', label: 'Technology and Equipment' },
	{ value: 'Machine Building', label: 'Machine Building' },
	{ value: '3D technology', label: '3D technology' },
	{ value: 'Machine and Apparatus of Light', label: 'Machine and Apparatus of Light' },
	{ value: 'Information System', label: 'Information System' },
	{ value: 'Technical Maintenance', label: 'Technical Maintenance' },
	{ value: 'Textile Material Production', label: 'Textile Material Production' },
	{ value: 'Production of clothes', label: 'Production of clothes' },
	{ value: 'Metrology', label: 'Metrology' },
	{ value: 'Technology and Processing', label: 'Technology and Processing' },
	{ value: 'Technology of Footware', label: 'Technology of Footware' },
	{ value: 'Tourism Management', label: 'Tourism Management' },
	{ value: 'Hungarian', label: 'Hungarian' },
	{ value: 'International Relations', label: 'International Relations' },
	{ value: 'Kindergarten Education', label: 'Kindergarten Education' },
	{ value: 'Sociology', label: 'Sociology' },
	{ value: 'Assyriology', label: 'Assyriology' },
	{ value: 'Cartography', label: 'Cartography' },
	{ value: 'Cultural Anthropology', label: 'Cultural Anthropology' },
	{ value: 'Ethnic', label: 'Ethnic' },
	{ value: 'Film Studies', label: 'Film Studies' },
	{ value: 'Indology', label: 'Indology' },
	{ value: 'Meteorology', label: 'Meteorology' },
	{ value: 'Semiotics', label: 'Semiotics' },
	{ value: 'Games', label: 'Games' },
	{ value: 'Built Environment', label: 'Built Environment' },
	{ value: 'Leisure & Event Environment', label: 'Leisure & Event Environment' },
	{ value: 'Leisure Studies', label: 'Leisure Studies' },
	{ value: 'Imagineering', label: 'Imagineering' },
	{ value: 'Automotive Technology', label: 'Automotive Technology' },
	{ value: 'Electrical Engineering', label: 'Electrical Engineering' },
	{ value: 'Medical Science', label: 'Medical Science' },
	{ value: 'SUSTAINABLE INNOVATION', label: 'SUSTAINABLE INNOVATION' },
	{ value: 'CONSTRUCTION MANAGEMENT', label: 'CONSTRUCTION MANAGEMENT' },
	{ value: 'Innovation Management', label: 'Innovation Management' },
	{ value: 'Marine Technology', label: 'Marine Technology' },
	{ value: 'Fashion', label: 'Fashion' },
	{ value: 'Bussiness', label: 'Bussiness' },
	{ value: 'ACCOUNTANCY', label: 'ACCOUNTANCY' },
	{ value: 'Philosophy', label: 'Philosophy' },
	{ value: 'CULTURE', label: 'CULTURE' },
	{ value: 'Development', label: 'Development' },
	{ value: 'Classics and European Identity', label: 'Classics and European Identity' },
	{ value: 'Criminal Justice', label: 'Criminal Justice' },
	{ value: 'European Cultures', label: 'European Cultures' },
	{ value: 'Political Science', label: 'Political Science' },
	{ value: 'Administration in International Organizations', label: 'Administration in International Organizations' },
	{ value: 'Applied Geoscience', label: 'Applied Geoscience' },
	{ value: 'Empirical and Theoretical Linguistics', label: 'Empirical and Theoretical Linguistics' },
	{ value: 'European Studies', label: 'European Studies' },
	{ value: 'LLM International & European Law', label: 'LLM International & European Law' },
	{ value: 'Theoretical Physics', label: 'Theoretical Physics' },
	{ value: 'National Security', label: 'National Security' },
	{ value: 'Anthropology', label: 'Anthropology' },
	{ value: 'Geophysics', label: 'Geophysics' },
	{ value: 'Computer Aided Engineering', label: 'Computer Aided Engineering' },
	{ value: 'English Studies', label: 'English Studies' },
	{ value: 'Environmental Protection', label: 'Environmental Protection' },
	{ value: 'Electrical Engineering and Computer Science', label: 'Electrical Engineering and Computer Science' },
	{ value: 'Civil Engineering & Architecture', label: 'Civil Engineering & Architecture' },
	{ value: 'Technological Science', label: 'Technological Science' },
	{ value: 'Linguistics', label: 'Linguistics' },
	{ value: 'Dentist', label: 'Dentist' },
	{ value: 'Mechanical Engineering and Applied Computer Science', label: 'Mechanical Engineering and Applied Computer Science' },
	{ value: 'Science and Technology', label: 'Science and Technology' },
	{ value: 'Telecommunications and Computer Science', label: 'Telecommunications and Computer Science' },
	{ value: 'Advanced Biobased and Bioinspired Materials', label: 'Advanced Biobased and Bioinspired Materials' },
	{ value: 'Advanced Mechanical Engineering', label: 'Advanced Mechanical Engineering' },
	{ value: 'Advanced Technologies', label: 'Advanced Technologies' },
	{ value: 'Business and Technology', label: 'Business and Technology' },
	{ value: 'Information Management', label: 'Information Management' },
	{ value: 'Food Technology and Human Nutrition', label: 'Food Technology and Human Nutrition' },
	{ value: 'Applied Sciences', label: 'Applied Sciences' },
	{ value: 'Veterinary Medicine', label: 'Veterinary Medicine' },
	{ value: 'Cloud Computing', label: 'Cloud Computing' },
	{ value: 'BSc Gestion et Technologie', label: 'BSc Gestion et Technologie' },
	{ value: 'BSc in Computer Science', label: 'BSc in Computer Science' },
	{ value: 'BSc in Science and Technology', label: 'BSc in Science and Technology' },
	{ value: 'Computer Science', label: 'Computer Science' },
	{ value: 'Economics of Globalisation and European Integration', label: 'Economics of Globalisation and European Integration' },
	{ value: 'Information Systems Management', label: 'Information Systems Management' },
	{ value: 'Quantitative Economics Analysis', label: 'Quantitative Economics Analysis' },
	{ value: 'Prehistoric Archaeology', label: 'Prehistoric Archaeology' },
	{ value: 'Languages and Literature', label: 'Languages and Literature' },
	{ value: 'Dentistry', label: 'Dentistry' },
	{ value: 'International Management', label: 'International Management' },
	{ value: 'International and Diplomatics studies', label: 'International and Diplomatics studies' },
	{ value: 'Environmental Technology', label: 'Environmental Technology' },
	{ value: 'Drug Synthesis and Production', label: 'Drug Synthesis and Production' },
	{ value: 'Chemistry and Chemical Technologies', label: 'Chemistry and Chemical Technologies' },
	{ value: 'Conservation and Restoration of Cultural Heritage Objects: Works of Arts and Crafts', label: 'Conservation and Restoration of Cultural Heritage Objects: Works of Arts and Crafts' },
	{ value: 'Life Science', label: 'Life Science' },
	{ value: 'Agricultural Sciences and Veterinary Medicine', label: 'Agricultural Sciences and Veterinary Medicine' },
	{ value: 'Marine Sciences', label: 'Marine Sciences' },
	{ value: 'Physics and Astronomy', label: 'Physics and Astronomy' },
	{ value: 'Economics and Management', label: 'Economics and Management' },
	{ value: 'Food and Drug', label: 'Food and Drug' },
	{ value: 'Management and Economics', label: 'Management and Economics' },
	{ value: 'APPLIED INFORMATICS', label: 'APPLIED INFORMATICS' },
	{ value: 'Multimedia', label: 'Multimedia' },
	{ value: 'Physics', label: 'Physics' },
	{ value: 'Automotic electronic engineering', label: 'Automotic electronic engineering' },
	{ value: 'Electronic Engineering', label: 'Electronic Engineering' },
	{ value: 'Legal and Economic', label: 'Legal and Economic' },
	{ value: 'Industrial and Information Engineering', label: 'Industrial and Information Engineering' },
	{ value: 'Computer Science and Mathematics', label: 'Computer Science and Mathematics' },
	{ value: 'LIFE, HEALTH AND ENVIRONMENTAL SCIENCES', label: 'LIFE, HEALTH AND ENVIRONMENTAL SCIENCES' },
	{ value: 'Digital Science', label: 'Digital Science' },
	{ value: 'Tourism', label: 'Tourism' },
	{ value: 'Gastronomy: Creativity, Ecology and Education', label: 'Gastronomy: Creativity, Ecology and Education' },
	{ value: 'Experience', label: 'Experience' },
	{ value: 'Fashion Design', label: 'Fashion Design' },
	{ value: 'Fashion and Bussiness', label: 'Fashion and Bussiness' },
	{ value: 'Art Direction', label: 'Art Direction' },
	{ value: 'Human Science', label: 'Human Science' },
	{ value: 'Hospitality', label: 'Hospitality' },
	{ value: 'Skills and Trades Training', label: 'Skills and Trades Training' },
	{ value: 'Alaska Highway Consortium on Teacher Education (AHCOTE)', label: 'Alaska Highway Consortium on Teacher Education (AHCOTE)' },
	{ value: 'Archaeology Diploma', label: 'Archaeology Diploma' },
	{ value: 'Associate of Arts Degree', label: 'Associate of Arts Degree' },
	{ value: 'Associate of Science Degree', label: 'Associate of Science Degree' },
	{ value: 'Business Management Advanced Certificate in Management', label: 'Business Management Advanced Certificate in Management' },
	{ value: 'Business Management Certificate', label: 'Business Management Certificate' },
	{ value: 'Business Management Diploma', label: 'Business Management Diploma' },
	{ value: 'Business Management Executive Assistant Diploma', label: 'Business Management Executive Assistant Diploma' },
	{ value: 'Business', label: 'Business' },
	{ value: 'Office Administration', label: 'Office Administration' },
	{ value: 'Global Business Management', label: 'Global Business Management' },
	{ value: 'Continuing Care Assistant', label: 'Continuing Care Assistant' },
	{ value: 'Agricultural Equipment Technician', label: 'Agricultural Equipment Technician' },
	{ value: 'Heavy Equipment Truck & Transport Technician program', label: 'Heavy Equipment Truck & Transport Technician program' },
	{ value: 'Power Engineering Technician', label: 'Power Engineering Technician' },
	{ value: 'Arts & Science', label: 'Arts & Science' },
	{ value: 'Business Management Interactive Technologies and Game Design', label: 'Business Management Interactive Technologies and Game Design' },
	{ value: 'Social Work', label: 'Social Work' },
	{ value: 'Nursing', label: 'Nursing' },
	{ value: 'Sociology', label: 'Sociology' },
	{ value: 'Business Management Post Degree Diploma', label: 'Business Management Post Degree Diploma' },
	{ value: 'Diploma in Agribusiness', label: 'Diploma in Agribusiness' },
	{ value: 'Architectural Technology', label: 'Architectural Technology' },
	{ value: 'Aviation Technician', label: 'Aviation Technician' },
	{ value: 'Aviation Technician – Aircraft Maintenance', label: 'Aviation Technician – Aircraft Maintenance' },
	{ value: 'Baking and Pastry Arts Management', label: 'Baking and Pastry Arts Management' },
	{ value: 'Criminology', label: 'Criminology' },
	{ value: 'BIM and Integrated Practice', label: 'BIM and Integrated Practice' },
	{ value: 'Broadcasting – Radio', label: 'Broadcasting – Radio' },
	{ value: 'Broadcasting - Television and Film Production', label: 'Broadcasting - Television and Film Production' },
	{ value: 'Early Childhood Education and Care', label: 'Early Childhood Education and Care' },
	{ value: 'Business (co-op)', label: 'Business (co-op)' },
	{ value: 'Business Accounting', label: 'Business Accounting' },
	{ value: 'Business – Entrepreneurship and Management', label: 'Business – Entrepreneurship and Management' },
	{ value: 'Education Assistant – Certificate', label: 'Education Assistant – Certificate' },
	{ value: 'Business and Finance', label: 'Business and Finance' },
	{ value: 'Business and Insurance', label: 'Business and Insurance' },
	{ value: 'Business – Logistics and Supply Chain Management', label: 'Business – Logistics and Supply Chain Management' },
	{ value: 'Business and Marketing', label: 'Business and Marketing' },
	{ value: 'Business – Payroll and Bookkeeping', label: 'Business – Payroll and Bookkeeping' },
	{ value: 'Business and Accounting', label: 'Business and Accounting' },
	{ value: 'Business Administration - Human Resources', label: 'Business Administration - Human Resources' },
	{ value: 'Business Administration - Leadership  and Management', label: 'Business Administration - Leadership  and Management' },
	{ value: 'Engineering - Certificate', label: 'Engineering - Certificate' },
	{ value: 'Business Administration - Marketing', label: 'Business Administration - Marketing' },
	{ value: 'The Carpentry and Renovation Technician', label: 'The Carpentry and Renovation Technician' },
	{ value: 'Chemical Laboratory Technology - Science Laboratory', label: 'Chemical Laboratory Technology - Science Laboratory' },
	{ value: 'Child and Youth Care', label: 'Child and Youth Care' },
	{ value: 'Civil Engineering Technology', label: 'Civil Engineering Technology' },
	{ value: 'Collaborate Nursing', label: 'Collaborate Nursing' },
	{ value: 'Computer Programmer Analyst', label: 'Computer Programmer Analyst' },
	{ value: 'Computer Systems Technician', label: 'Computer Systems Technician' },
	{ value: 'Cyber Security', label: 'Cyber Security' },
	{ value: 'Dental Hygiene', label: 'Dental Hygiene' },
	{ value: 'Developmental Services Worker', label: 'Developmental Services Worker' },
	{ value: 'Electrical Engineering Technician', label: 'Electrical Engineering Technician' },
	{ value: 'Electrical Engineering Technology', label: 'Electrical Engineering Technology' },
	{ value: 'General Arts and Sciences', label: 'General Arts and Sciences' },
	{ value: 'Electromechanical Engineering Technician', label: 'Electromechanical Engineering Technician' },
	{ value: 'Humanities', label: 'Humanities' },
	{ value: 'Fashion Design', label: 'Fashion Design' },
	{ value: 'Fashion Marketing and Management', label: 'Fashion Marketing and Management' },
	{ value: 'Fine Art', label: 'Fine Art' },
	{ value: 'Fire Inspection and Fire Safety', label: 'Fire Inspection and Fire Safety' },
	{ value: 'Fitness and Health Promotion', label: 'Fitness and Health Promotion' },
	{ value: 'Land and Water Resources Diploma', label: 'Land and Water Resources Diploma' },
	{ value: 'GIS and Urban Planning', label: 'GIS and Urban Planning' },
	{ value: 'Heating, Refrigeration, and Air Conditioning Technician', label: 'Heating, Refrigeration, and Air Conditioning Technician' },
	{ value: 'Pre-Education - Certificate', label: 'Pre-Education - Certificate' },
	{ value: 'Leadership', label: 'Leadership' },
	{ value: 'Medicine', label: 'Medicine' },
	{ value: 'Design and Planning', label: 'Design and Planning' },
	{ value: 'Interior Design', label: 'Interior Design' },
	{ value: 'Social Sciences - Certificate', label: 'Social Sciences - Certificate' },
	{ value: 'Horticulture Technician', label: 'Horticulture Technician' },
	{ value: 'Hospitality and Tourism Operations Management', label: 'Hospitality and Tourism Operations Management' },
	{ value: 'Interactive Media Design', label: 'Interactive Media Design' },
	{ value: 'Social Services Worker Diploma', label: 'Social Services Worker Diploma' },
	{ value: 'Arts', label: 'Arts' },
	{ value: 'Communication Arts', label: 'Communication Arts' },
	{ value: 'HEALTH', label: 'HEALTH' },
	{ value: 'Skill Trades', label: 'Skill Trades' },
	{ value: 'Community Service', label: 'Community Service' },
	{ value: 'CONSTRUCTION & ENGINEERING TECHNOLOGIES', label: 'CONSTRUCTION & ENGINEERING TECHNOLOGIES' },
	{ value: 'Interior Decorating', label: 'Interior Decorating' },
	{ value: 'Journalism – Broadcast', label: 'Journalism – Broadcast' },
	{ value: 'Landscape Design', label: 'Landscape Design' },
	{ value: 'Law', label: 'Law' },
	{ value: 'Manufacturing Engineering Technician', label: 'Manufacturing Engineering Technician' },
	{ value: 'Massage Therapy', label: 'Massage Therapy' },
	{ value: 'Medical Radiation Technology', label: 'Medical Radiation Technology' },
	{ value: 'Motive Power Technician', label: 'Motive Power Technician' },
	{ value: 'Music Industry Arts', label: 'Music Industry Arts' },
	{ value: 'Office Administration – Executive program', label: 'Office Administration – Executive program' },
	{ value: 'Paramedic', label: 'Paramedic' },
	{ value: 'Plumbing Techniques', label: 'Plumbing Techniques' },
	{ value: 'Police Foundations', label: 'Police Foundations' },
	{ value: 'Practical Nursing', label: 'Practical Nursing' },
	{ value: 'Recreation and Leisure Services', label: 'Recreation and Leisure Services' },
	{ value: 'Respiratory Therapy', label: 'Respiratory Therapy' },
	{ value: 'Social Service Worker', label: 'Social Service Worker' },
	{ value: 'Special Events Planning', label: 'Special Events Planning' },
	{ value: 'Theatre Arts - Performance', label: 'Theatre Arts - Performance' },
	{ value: 'Tourism and Travel', label: 'Tourism and Travel' },
	{ value: 'Business Adminstration', label: 'Business Adminstration' },
	{ value: 'Logistics and Supply Chain Management', label: 'Logistics and Supply Chain Management' },
	{ value: 'Advertising and Marketing Communications', label: 'Advertising and Marketing Communications' },
	{ value: 'Interactive Marketing Communications', label: 'Interactive Marketing Communications' },
	{ value: 'Bs Phychology+ MD Course', label: 'Bs Phychology+ MD Course' },
	{ value: 'MD course', label: 'MD course' },
	{ value: 'AB Phychology + MD course', label: 'AB Phychology + MD course' },
	{ value: 'Ecology, environmental protection and conservation of nature (BA)', label: 'Ecology, environmental protection and conservation of nature (BA)' },
	{ value: 'Chemical Engineering', label: 'Chemical Engineering' },
	{ value: 'Leadership & Management', label: 'Leadership & Management' },
	{ value: 'Construction Engineering', label: 'Construction Engineering' },
	{ value: 'Health and Social Care', label: 'Health and Social Care' },
	{ value: 'Financial Services', label: 'Financial Services' },
	{ value: 'Children’s Care, Learning and Development', label: 'Children’s Care, Learning and Development' },
	{ value: 'Electrical Systems', label: 'Electrical Systems' },
	{ value: 'Multimedia Software Development', label: 'Multimedia Software Development' },
	{ value: 'Software Development', label: 'Software Development' },
	{ value: 'Computer Systems and Networks', label: 'Computer Systems and Networks' },
	{ value: 'Engineering and Transport – Building & Construction Engineering', label: 'Engineering and Transport – Building & Construction Engineering' },
	{ value: 'Digital Entrepreneurship', label: 'Digital Entrepreneurship' },
	{ value: 'Tourism and Hospitality Management', label: 'Tourism and Hospitality Management' },
	{ value: 'Marketing and Communication', label: 'Marketing and Communication' },
	{ value: 'Bachelors in English Philology', label: 'Bachelors in English Philology' },
	{ value: 'Mathematics and Accounting', label: 'Mathematics and Accounting' },
	{ value: 'Foundation in Science', label: 'Foundation in Science' },
	{ value: 'Archaeology', label: 'Archaeology' },
	{ value: 'Theater Production', label: 'Theater Production' },
	{ value: 'Business Finance', label: 'Business Finance' },
	{ value: 'Business Informatics', label: 'Business Informatics' },
	{ value: 'Electrical and Electronics', label: 'Electrical and Electronics' },
	{ value: 'Actuarial Science', label: 'Actuarial Science' },
	{ value: 'Environment and Development', label: 'Environment and Development' },
	{ value: 'Geography with Economics', label: 'Geography with Economics' },
	{ value: 'Language, Culture and Society', label: 'Language, Culture and Society' },
	{ value: 'Banking, Regulation and Financial Stability', label: 'Banking, Regulation and Financial Stability' },
	{ value: 'Biomedical Engineering', label: 'Biomedical Engineering' },
	{ value: 'Climate Change Science and Policy', label: 'Climate Change Science and Policy' },
	{ value: 'Clinical Neuropsychology', label: 'Clinical Neuropsychology' },
	{ value: 'Comparative Literatures and Cultures', label: 'Comparative Literatures and Cultures' },
	{ value: 'Development and Security', label: 'Development and Security' },
	{ value: 'Engineering Technology', label: 'Engineering Technology' },
	{ value: 'Civil Construction', label: 'Civil Construction' },
	{ value: 'Computerised Control of Electrical Technologies', label: 'Computerised Control of Electrical Technologies' },
	{ value: 'Organization and Management of International Economic Relations', label: 'Organization and Management of International Economic Relations' },
	{ value: 'Electronic Systems', label: 'Electronic Systems' },
	{ value: 'Advanced Automotive Electronic Engineering', label: 'Advanced Automotive Electronic Engineering' },
	{ value: 'Food Safety and Food Risk', label: 'Food Safety and Food Risk' },
	{ value: 'Innovation Design', label: 'Innovation Design' },
	{ value: 'Languages for Communication in International Enterprises and Organizations', label: 'Languages for Communication in International Enterprises and Organizations' },
	{ value: 'International Marketing Management', label: 'International Marketing Management' },
	{ value: 'International Event Management', label: 'International Event Management' },
	{ value: 'Aviation Management', label: 'Aviation Management' },
	{ value: 'Transport and Logistics Management', label: 'Transport and Logistics Management' },
	{ value: 'Health Care Management', label: 'Health Care Management' },
	{ value: 'International Finance and Accounting', label: 'International Finance and Accounting' },
	{ value: 'IT Management', label: 'IT Management' },
	{ value: 'International Human Resource Management', label: 'International Human Resource Management' },
	{ value: 'Business Information Systems', label: 'Business Information Systems' },
	{ value: 'International Business Administration', label: 'International Business Administration' },
	{ value: 'Focus on Renewable Energy', label: 'Focus on Renewable Energy' },
	{ value: 'General Management', label: 'General Management' },
	{ value: 'Focus on Creative Management', label: 'Focus on Creative Management' },
	{ value: 'Healthcare Management', label: 'Healthcare Management' },
	{ value: 'Focus on Cyber Security', label: 'Focus on Cyber Security' },
	{ value: 'IT Security Management', label: 'IT Security Management' },
	{ value: 'Business Intelligence and Analytics', label: 'Business Intelligence and Analytics' },
	{ value: 'Strategic Business Management', label: 'Strategic Business Management' },
	{ value: 'Data Analytics and Marketing', label: 'Data Analytics and Marketing' },
	{ value: 'Data Analytics and Information Systems Management', label: 'Data Analytics and Information Systems Management' },
	{ value: 'Financial Management', label: 'Financial Management' },
	{ value: 'Management with Marketing', label: 'Management with Marketing' },
	{ value: 'Management with Arts', label: 'Management with Arts' },
	{ value: 'Management with Tourism', label: 'Management with Tourism' },
	{ value: 'Marketing Management', label: 'Marketing Management' },
	{ value: 'Enterprise Development and Entrepreneurship', label: 'Enterprise Development and Entrepreneurship' },
	{ value: 'Applied Management', label: 'Applied Management' },
	{ value: 'Public Health', label: 'Public Health' },
	{ value: 'Corporate Management', label: 'Corporate Management' },
	{ value: 'Finance and Management', label: 'Finance and Management' },
	{ value: 'Digital Business and Data Science', label: 'Digital Business and Data Science' },
	{ value: 'Game Design', label: 'Game Design' },
	{ value: 'Communication Design', label: 'Communication Design' },
	{ value: 'Finance and Accounting', label: 'Finance and Accounting' },
	{ value: 'Finance and Accounting with ISEG Lisbon', label: 'Finance and Accounting with ISEG Lisbon' },
	{ value: 'Management with ESCP Europe', label: 'Management with ESCP Europe' },
	{ value: 'Management with KEDGE Business School', label: 'Management with KEDGE Business School' },
	{ value: 'Big Data Analysis', label: 'Big Data Analysis' },
	{ value: 'Health Economics and Big Data Analytics', label: 'Health Economics and Big Data Analytics' },
	{ value: 'Management with Catolica-Lisbon', label: 'Management with Catolica-Lisbon' },
	{ value: 'Management with Hull University Business School', label: 'Management with Hull University Business School' },
	{ value: 'Management with Lancaster University Management School', label: 'Management with Lancaster University Management School' },
	{ value: 'Finance and Accounting with SKEMA Business School', label: 'Finance and Accounting with SKEMA Business School' },
	{ value: 'Finance and Accounting with University of Porto', label: 'Finance and Accounting with University of Porto' },
	{ value: 'Cognitive Neuroscience', label: 'Cognitive Neuroscience' },
	{ value: 'Business Law', label: 'Business Law' },
	{ value: 'International and European Law Advanced', label: 'International and European Law Advanced' },
	{ value: 'Linguistics', label: 'Linguistics' },
	{ value: 'Philosophy and Science', label: 'Philosophy and Science' },
	{ value: 'Perception, Action and Control', label: 'Perception, Action and Control' },
	{ value: 'Creative Industries', label: 'Creative Industries' },
	{ value: 'General Linguistics', label: 'General Linguistics' },
	{ value: 'Language and Communication', label: 'Language and Communication' },
	{ value: 'International and European Law', label: 'International and European Law' },
	{ value: 'Systematic Theology', label: 'Systematic Theology' },
	{ value: 'Practical Theology', label: 'Practical Theology' },
	{ value: 'Plasticity and Memory', label: 'Plasticity and Memory' },
	{ value: 'Diversities in Youth Care', label: 'Diversities in Youth Care' },
	{ value: 'Biblical Exegesis', label: 'Biblical Exegesis' },
	{ value: 'Brain Networks and Neuronal Communication', label: 'Brain Networks and Neuronal Communication' },
	{ value: 'Pedagogical Sciences', label: 'Pedagogical Sciences' },
	{ value: 'European Law and Global Affairs', label: 'European Law and Global Affairs' },
	{ value: 'Gifted Education', label: 'Gifted Education' },
	{ value: 'Regional and Environmental Economics', label: 'Regional and Environmental Economics' },
	{ value: 'Technical Management', label: 'Technical Management' },
	{ value: 'Mechatronics Engineering', label: 'Mechatronics Engineering' },
	{ value: 'Industrial Design Engineering', label: 'Industrial Design Engineering' },
	{ value: 'Electrical Engineering', label: 'Electrical Engineering' },
	{ value: 'Business Administration and Management', label: 'Business Administration and Management' },
	{ value: 'Business Development', label: 'Business Development' },
	{ value: 'Computer Science Engineering', label: 'Computer Science Engineering' },
	{ value: 'Economist in Tourism and Management', label: 'Economist in Tourism and Management' },
	{ value: 'Business and Cultural Diplomacy', label: 'Business and Cultural Diplomacy' },
	{ value: 'International Relations and Cultural Diplomacy', label: 'International Relations and Cultural Diplomacy' },
	{ value: 'Network Design and Computer Management', label: 'Network Design and Computer Management' },
	{ value: 'Digital Service Innovation', label: 'Digital Service Innovation' },
	{ value: 'Mechanical Engineering', label: 'Mechanical Engineering' },
	{ value: 'Electronics Design', label: 'Electronics Design' },
	{ value: 'Network Forensics', label: 'Network Forensics' },
	{ value: 'Embedded and Intelligent Systems', label: 'Embedded and Intelligent Systems' },
	{ value: 'Renewable Energy Systems', label: 'Renewable Energy Systems' },
	{ value: 'Industrial Management and Innovation', label: 'Industrial Management and Innovation' },
	{ value: 'Strategic Entrepreneurship for International Growth with Specialization in International Marketing', label: 'Strategic Entrepreneurship for International Growth with Specialization in International Marketing' },
	{ value: 'Applied Environmental Science', label: 'Applied Environmental Science' },
	{ value: 'Bioinformatics', label: 'Bioinformatics' },
	{ value: 'Vehicle Engineering', label: 'Vehicle Engineering' },
	{ value: 'Industrial and Environmental Biotechnology', label: 'Industrial and Environmental Biotechnology' },
	{ value: 'Media Management', label: 'Media Management' },
	{ value: 'Nuclear Energy (EMINE)', label: 'Nuclear Energy (EMINE)' },
	{ value: 'Sustainable Urban Planning and Design', label: 'Sustainable Urban Planning and Design' },
	{ value: 'Polymer Technology', label: 'Polymer Technology' },
	{ value: 'Nanotechnology', label: 'Nanotechnology' },
	{ value: 'ICT Innovation', label: 'ICT Innovation' },
	{ value: 'Architectural Lighting Design', label: 'Architectural Lighting Design' },
	{ value: 'Aerospace Engineering', label: 'Aerospace Engineering' },
	{ value: 'Embedded Systems', label: 'Embedded Systems' },
	{ value: 'Entrepreneurship and Innovation Management', label: 'Entrepreneurship and Innovation Management' },
	{ value: 'Medical Biotechnology', label: 'Medical Biotechnology' },
	{ value: 'Electric Power Engineering', label: 'Electric Power Engineering' },
	{ value: 'Molecular Science and Engineering', label: 'Molecular Science and Engineering' },
	{ value: 'Industrial Management', label: 'Industrial Management' },
	{ value: 'Renewable Energy (RENE)', label: 'Renewable Energy (RENE)' },
	{ value: 'Management and Engineering of Environment and Energy', label: 'Management and Engineering of Environment and Energy' },
	{ value: 'Environmental Engineering and Sustainable Infrastructure', label: 'Environmental Engineering and Sustainable Infrastructure' },
	{ value: 'Production Engineering and Management', label: 'Production Engineering and Management' },
	{ value: 'Engineering Materials Science', label: 'Engineering Materials Science' },
	{ value: 'Sustainable Energy Engineering', label: 'Sustainable Energy Engineering' },
	{ value: 'Electromagnetics, Fusion and Space Engineering', label: 'Electromagnetics, Fusion and Space Engineering' },
	{ value: 'Information and Network Engineering', label: 'Information and Network Engineering' },
	{ value: 'Smart Electrical Networks and Systems (SENSE)', label: 'Smart Electrical Networks and Systems (SENSE)' },
	{ value: 'Sustainable Technology', label: 'Sustainable Technology' },
	{ value: 'Communication Systems', label: 'Communication Systems' },
	{ value: 'Maritime Engineering', label: 'Maritime Engineering' },
	{ value: 'Macromolecular Materials', label: 'Macromolecular Materials' },
	{ value: 'Real Estate and Construction Management', label: 'Real Estate and Construction Management' },
	{ value: 'Civil and Architectural Engineering', label: 'Civil and Architectural Engineering' },
	{ value: 'Comparative, European and International Legal Studies', label: 'Comparative, European and International Legal Studies' },
	{ value: 'International Security Studies', label: 'International Security Studies' },
	{ value: 'Human Computer Interaction', label: 'Human Computer Interaction' },
	{ value: 'Energy Engineering', label: 'Energy Engineering' },
	{ value: 'European Master in Business Studies', label: 'European Master in Business Studies' },
	{ value: 'European and International Studies', label: 'European and International Studies' },
	{ value: 'Sociology and Social Research', label: 'Sociology and Social Research' },
	{ value: 'Materials and Production Engineering', label: 'Materials and Production Engineering' },
	{ value: 'Cognitive Science', label: 'Cognitive Science' },
	{ value: 'Quantitative and Computational Biology', label: 'Quantitative and Computational Biology' },
	{ value: 'Cellular and Molecular Biotechnology', label: 'Cellular and Molecular Biotechnology' },
	{ value: 'Information and Communication Engineering', label: 'Information and Communication Engineering' },
	{ value: 'Management of International Communication', label: 'Management of International Communication' },
	{ value: 'Business Logistics Management', label: 'Business Logistics Management' },
	{ value: 'International Finance Management', label: 'International Finance Management' },
	{ value: 'Tourism Strategic Management', label: 'Tourism Strategic Management' },
	{ value: 'Business Administration - Tourism Strategic Management', label: 'Business Administration - Tourism Strategic Management' },
	{ value: 'Maxillofacial Surgery', label: 'Maxillofacial Surgery' },

	{ value: 'Opthalmology', label: 'Opthalmology' },
	{ value: 'Oral Surgery', label: 'Oral Surgery' },
	{ value: 'Orthodontics', label: 'Orthodontics' },
	{ value: 'Orthopedic Odontology', label: 'Orthopedic Odontology' },
	{ value: 'Paediatric Dentistry', label: 'Paediatric Dentistry' },
	{ value: 'Allergology and Clinical Immunology', label: 'Allergology and Clinical Immunology' },
	{ value: 'Cardiac Anaesthesiology and Intensive Care', label: 'Cardiac Anaesthesiology and Intensive Care' },
	{ value: 'Cardiac,Thoracic and Vascular Surgery', label: 'Cardiac,Thoracic and Vascular Surgery' },
	{ value: 'Fellowship in Cardiology', label: 'Fellowship in Cardiology' },
	{ value: 'Veterinary', label: 'Veterinary' },
	{ value: 'Physiotherapy', label: 'Physiotherapy' },
	{ value: 'Nursing', label: 'Nursing' },
	{ value: 'Mechanical Engineer', label: 'Mechanical Engineer' },
	{ value: 'Electrical Engineer', label: 'Electrical Engineer' },
	{ value: 'Computer & Informatics', label: 'Computer & Informatics' },
	{ value: 'Chemical Engineer', label: 'Chemical Engineer' },
	{ value: 'Civil Engineer', label: 'Civil Engineer' },
	{ value: 'Transportation & Vehicle Engineering', label: 'Transportation & Vehicle Engineering' },
	{ value: 'Business', label: 'Business' },
	{ value: 'Management', label: 'Management' },
	{ value: 'Tourism', label: 'Tourism' },
	{ value: 'Economics and Banking', label: 'Economics and Banking' },
	{ value: 'Economics and Business', label: 'Economics and Business' },
	{ value: 'Electronics and Communications Engineering', label: 'Electronics and Communications Engineering' },
	{ value: 'International Accounting and Management', label: 'International Accounting and Management' },
	{ value: 'Public and Cultural Diplomacy', label: 'Public and Cultural Diplomacy' },
	{ value: 'Medical Biotechnologies', label: 'Medical Biotechnologies' },
	{ value: 'International Studies', label: 'International Studies' },
	{ value: 'Genetic Counsellors', label: 'Genetic Counsellors' },
	{ value: 'Biodiversity and Environmental Health', label: 'Biodiversity and Environmental Health' },
	{ value: 'Language and Mind - Linguistics and Cognitive Studies', label: 'Language and Mind - Linguistics and Cognitive Studies' },
	{ value: 'Computer and Automation Engineering', label: 'Computer and Automation Engineering' },
	{ value: 'Animal Husbandry Engineering', label: 'Animal Husbandry Engineering' },
	{ value: 'Agricultural Engineering', label: 'Agricultural Engineering' },
	{ value: 'Agricultural Environmental Management Engineering', label: 'Agricultural Environmental Management Engineering' },
	{ value: 'Agricultural Water Management Engineering', label: 'Agricultural Water Management Engineering' },
	{ value: 'Classical Musical Instrumental Performance', label: 'Classical Musical Instrumental Performance' },
	{ value: 'Complex Rehabilitation', label: 'Complex Rehabilitation' },
	{ value: 'European and International Business Law', label: 'European and International Business Law' },
	{ value: 'Rural Development Engineering', label: 'Rural Development Engineering' },
	{ value: 'New Media Arts', label: 'New Media Arts' },
	{ value: 'International Economics and Commercial Diplomacy', label: 'International Economics and Commercial Diplomacy' },
	{ value: 'Export Management', label: 'Export Management' },
	{ value: 'MEDICAL DOCTOR', label: 'MEDICAL DOCTOR' },
	{ value: 'Fire and rescue', label: 'Fire and rescue' },
	{ value: 'Animal Science', label: 'Animal Science' },
	{ value: 'Architectural Environment Engineering', label: 'Architectural Environment Engineering' },
	{ value: 'Biblical Studies', label: 'Biblical Studies' },
	{ value: 'Biochemistry', label: 'Biochemistry' },
	{ value: 'Cancer Sciences', label: 'Cancer Sciences' },
	{ value: 'Classical Civilisation', label: 'Classical Civilisation' },
	{ value: 'Classics', label: 'Classics' },
	{ value: 'Food and Nutrition', label: 'Food and Nutrition' },
	{ value: 'Education BA Hons', label: 'Education BA Hons' },
	{ value: 'Environmental Biology', label: 'Environmental Biology' },
	{ value: 'Environmental Engineering', label: 'Environmental Engineering' },
	{ value: 'French studies', label: 'French studies' },
	{ value: 'Genetics', label: 'Genetics' },
	{ value: 'German BA Hons', label: 'German BA Hons' },
	{ value: 'Hispanic Studies', label: 'Hispanic Studies' },
	{ value: 'Media and Communication', label: 'Media and Communication' },
	{ value: 'Liberal Arts', label: 'Liberal Arts' },
	{ value: 'Biological Chemistry', label: 'Biological Chemistry' },
	{ value: 'Microbiology', label: 'Microbiology' },
	{ value: 'Midwifery', label: 'Midwifery' },
	{ value: 'Language Studies', label: 'Language Studies' },
	{ value: 'Neuroscience', label: 'Neuroscience' },
	{ value: 'Nutrition', label: 'Nutrition' },
	{ value: 'Pharmaceutical Sciences', label: 'Pharmaceutical Sciences' },
	{ value: 'Plant Science', label: 'Plant Science' },
	{ value: 'Politics', label: 'Politics' },
	{ value: 'Product Design', label: 'Product Design' },
	{ value: 'Religion', label: 'Religion' },
	{ value: 'Chinese Studies', label: 'Chinese Studies' },
	{ value: 'Russian Studies', label: 'Russian Studies' },
	{ value: 'Social Work', label: 'Social Work' },
	{ value: 'Sport and Exercise', label: 'Sport and Exercise' },
	{ value: 'Zoology', label: 'Zoology' },
	{ value: 'International business and Management', label: 'International business and Management' },
	{ value: 'Petroleum Geoengineering', label: 'Petroleum Geoengineering' },
	{ value: 'Petroleum Engineering', label: 'Petroleum Engineering' },
	{ value: 'Materials Engineering', label: 'Materials Engineering' },
	{ value: 'Business Administration', label: 'Business Administration' },
	{ value: 'Ocean Engineering', label: 'Ocean Engineering' },
	{ value: 'International Luxury Management', label: 'International Luxury Management' },
	{ value: 'International Marketing', label: 'International Marketing' },
	{ value: 'Luxury', label: 'Luxury' },
	{ value: 'Luxury Hospitality & Event Management', label: 'Luxury Hospitality & Event Management' },
	{ value: 'Digital Marketing & Brand Management', label: 'Digital Marketing & Brand Management' },
	{ value: 'Luxury Brand Management, Food & wine', label: 'Luxury Brand Management, Food & wine' },
	{ value: 'Modern Languages', label: 'Modern Languages' },
	{ value: 'German Nursing Adaptation program', label: 'German Nursing Adaptation program' },
	{ value: 'Business Management', label: 'Business Management' },
	{ value: 'Energy and Sustainable Management', label: 'Energy and Sustainable Management' },
	{ value: 'Luxury Management', label: 'Luxury Management' },
	{ value: 'Arts & Cultural Management', label: 'Arts & Cultural Management' },
	{ value: 'Accounting and Finance', label: 'Accounting and Finance' },
	{ value: 'Accounting and Finance with Foundation Year', label: 'Accounting and Finance with Foundation Year' },
	{ value: 'Acting', label: 'Acting' },
	{ value: 'Airline and Airport Management', label: 'Airline and Airport Management' },
	{ value: 'Artificial Intelligence', label: 'Artificial Intelligence' },
	{ value: 'International Business', label: 'International Business' },
	{ value: 'Banking and Finance', label: 'Banking and Finance' },
	{ value: 'Digital Transformation', label: 'Digital Transformation' },
	{ value: 'Sports Science', label: 'Sports Science' },
	{ value: 'Logistics and Transport Management', label: 'Logistics and Transport Management' },
	{ value: 'Adult Nursing', label: 'Adult Nursing' },
	{ value: 'LLB', label: 'LLB' },
	{ value: 'LLM', label: 'LLM' },
	{ value: 'International Hotel Management', label: 'International Hotel Management' },
	{ value: 'Strategic Management in Logistics', label: 'Strategic Management in Logistics' },
	{ value: 'International Technology Transfer Management', label: 'International Technology Transfer Management' },
	{ value: 'Foundation Certificate', label: 'Foundation Certificate' },
	{ value: 'Software Engineering', label: 'Software Engineering' },
	{ value: 'Celtic Civilisation', label: 'Celtic Civilisation' },
	{ value: 'Chemical Physics', label: 'Chemical Physics' },
	{ value: 'Immunology', label: 'Immunology' },
	{ value: 'Anatomy', label: 'Anatomy' },
	{ value: 'Italian', label: 'Italian' },
	{ value: 'Museum Studies', label: 'Museum Studies' },
	{ value: 'Ancient Cultures', label: 'Ancient Cultures' },
	{ value: 'Sensor and Imaging Systems', label: 'Sensor and Imaging Systems' },
	{ value: 'Theatre & Performance', label: 'Theatre & Performance' },
	{ value: 'Academic Practice', label: 'Academic Practice' },
	{ value: 'Ancestral Studies', label: 'Ancestral Studies' },
	{ value: 'Neuropsychology', label: 'Neuropsychology' },
	{ value: 'Oral Sciences', label: 'Oral Sciences' },
	{ value: 'Musicology', label: 'Musicology' },
	{ value: 'Sound Design', label: 'Sound Design' },
	{ value: 'Cardiovascular Sciences', label: 'Cardiovascular Sciences' },
	{ value: 'Sociolinguistics', label: 'Sociolinguistics' },
	{ value: 'War Studies', label: 'War Studies' },
	{ value: 'Education Policies', label: 'Education Policies' },
	{ value: 'Global Development', label: 'Global Development' },
	{ value: 'Composition and Creative Practice', label: 'Composition and Creative Practice' },
	{ value: 'Endodontics', label: 'Endodontics' },
	{ value: 'Educational Studies', label: 'Educational Studies' },
	{ value: 'Playwriting and Dramaturgy', label: 'Playwriting and Dramaturgy' },
	{ value: 'African Ecosystems', label: 'African Ecosystems' },
	{ value: 'Epidemiology', label: 'Epidemiology' },
	{ value: 'Postgraduate Certificate', label: 'Postgraduate Certificate' },
	{ value: 'Wildlife', label: 'Wildlife' },
	{ value: 'Teaching', label: 'Teaching' },
	{ value: 'Land and Hydrographic Surveying', label: 'Land and Hydrographic Surveying' },
	{ value: 'Structural Engineering', label: 'Structural Engineering' },
	{ value: 'Biostatistics', label: 'Biostatistics' },
	{ value: 'Geomatics', label: 'Geomatics' },
	{ value: 'Critical Care', label: 'Critical Care' },
	{ value: 'Quantum Technology', label: 'Quantum Technology' },
	{ value: 'Oral and Maxillofacial Surgery', label: 'Oral and Maxillofacial Surgery' },
	{ value: 'Social Justice', label: 'Social Justice' },
	{ value: 'Film Production', label: 'Film Production' },
	{ value: 'Graphic design', label: 'Graphic design' },
	{ value: 'General Engineering', label: 'General Engineering' },
	{ value: 'BDS Dentistry', label: 'BDS Dentistry' },
	{ value: 'Robotics', label: 'Robotics' },
	{ value: 'Engineeing science', label: 'Engineeing science' },
	{ value: 'Mechanical Engineering and Sustainable Technology', label: 'Mechanical Engineering and Sustainable Technology' },
	{ value: 'Information Technology', label: 'Information Technology' },
	{ value: 'Hospitality Management', label: 'Hospitality Management' },
	{ value: 'Hotel and Hospitality', label: 'Hotel and Hospitality' },
	{ value: 'Nursing Studies', label: 'Nursing Studies' },
	{ value: 'Psychology', label: 'Psychology' },
	{ value: 'Game Development', label: 'Game Development' },
	{ value: 'Lean Enterprise', label: 'Lean Enterprise' },
	{ value: 'Diploma in Accounting and Finance', label: 'Diploma in Accounting and Finance' },
	{ value: 'Diploma in Business Administration', label: 'Diploma in Business Administration' },
	{ value: 'Aircraft Structures', label: 'Aircraft Structures' },
	{ value: 'Preparatory Access', label: 'Preparatory Access' },
	{ value: 'Performance Building', label: 'Performance Building' },
	{ value: 'Water Resource Management', label: 'Water Resource Management' },
	{ value: 'Exercise and Sports Science', label: 'Exercise and Sports Science' },
	{ value: 'Radiography', label: 'Radiography' },
	{ value: 'Dietetics', label: 'Dietetics' },
	{ value: 'Illustration', label: 'Illustration' },
	{ value: 'Oncology', label: 'Oncology' },
	{ value: 'Inclusion', label: 'Inclusion' },
	{ value: 'Science', label: 'Science' },
	{ value: 'Administration', label: 'Administration' },
	{ value: 'QUALITY MANAGEMENT', label: 'QUALITY MANAGEMENT' },
	{ value: 'Teacher Education', label: 'Teacher Education' },
	{ value: 'Future Studies', label: 'Future Studies' },
	{ value: 'Health and Technology', label: 'Health and Technology' },
	{ value: 'Information and Communication Technology', label: 'Information and Communication Technology' },
	{ value: 'Food Development', label: 'Food Development' },
	{ value: 'Physical and Chemical Sciences', label: 'Physical and Chemical Sciences' },
	{ value: 'East Asian Studies', label: 'East Asian Studies' },
	{ value: 'Inequalities, Interventions and New welfare state', label: 'Inequalities, Interventions and New welfare state' },
	{ value: 'Business and Economics', label: 'Business and Economics' },
	{ value: 'Technology and Engineering', label: 'Technology and Engineering' },
	{ value: 'Arts and Design', label: 'Arts and Design' },
	{ value: 'Biomedical Imaging', label: 'Biomedical Imaging' },
	{ value: 'Governance of Digitization', label: 'Governance of Digitization' },
	{ value: 'International Law and Human Rights', label: 'International Law and Human Rights' },
	{ value: 'Peace, Mediation and Conflict Research', label: 'Peace, Mediation and Conflict Research' },
	{ value: 'Social Exclusion', label: 'Social Exclusion' },
	{ value: 'Sustainable Chemical and Process Engineering', label: 'Sustainable Chemical and Process Engineering' },
	{ value: 'Teaching and Learning', label: 'Teaching and Learning' },
	{ value: 'Mining and Geology', label: 'Mining and Geology' },
	{ value: 'Safety Engineering', label: 'Safety Engineering' },
	{ value: 'Textile Engineering', label: 'Textile Engineering' },
	{ value: 'Integrated Engineering', label: 'Integrated Engineering' },
	{ value: 'Design and Technology Futures', label: 'Design and Technology Futures' },
	{ value: 'e-Governance Technologies and Services', label: 'e-Governance Technologies and Services' },
	{ value: 'Technology Governance and Digital Transformation', label: 'Technology Governance and Digital Transformation' },
	{ value: 'Technology of Wood, Plastics and Textiles', label: 'Technology of Wood, Plastics and Textiles' },
	{ value: 'Applied Measurement Science', label: 'Applied Measurement Science' },
	{ value: 'Bioengineering', label: 'Bioengineering' },
	{ value: 'Materials Science', label: 'Materials Science' },
	{ value: 'Life Sciences', label: 'Life Sciences' },
	{ value: 'Business Management', label: 'Business Management' },
	{ value: 'Control Engineering', label: 'Control Engineering' },
	{ value: 'SPORTS MANAGEMENT', label: 'SPORTS MANAGEMENT' },
	{ value: 'Mechanical Technology', label: 'Mechanical Technology' },
	{ value: 'Sustainable Production in Mechanical Engineering', label: 'Sustainable Production in Mechanical Engineering' },
	{ value: 'Energy Conversion', label: 'Energy Conversion' },
	{ value: 'Mechatronic System Design', label: 'Mechatronic System Design' },
	{ value: 'Advanced Structural Design and Robotised Welding', label: 'Advanced Structural Design and Robotised Welding' },
	{ value: 'Bioenergy Systems', label: 'Bioenergy Systems' },
	{ value: 'Business Administration in Artificial Intelligence', label: 'Business Administration in Artificial Intelligence' },
	{ value: 'Engineering in Robotics', label: 'Engineering in Robotics' },
	{ value: 'Business Administration in International Business', label: 'Business Administration in International Business' },
	{ value: 'Marine Technology in Maritime Management/Sea Captain', label: 'Marine Technology in Maritime Management/Sea Captain' },
	{ value: 'Engineering in Industrial Management (blended learning)', label: 'Engineering in Industrial Management (blended learning)' },
	{ value: 'Engineering in Logistics', label: 'Engineering in Logistics' },
	{ value: 'Cellular and Molecular Biology', label: 'Cellular and Molecular Biology' },
	{ value: 'Behavioural and Applied Economic', label: 'Behavioural and Applied Economic' },
];