import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Box,
	VStack,
	FormControl,
	FormLabel,
	Input,
	Button,
	Alert,
	AlertIcon,
	AlertDescription,
	CloseButton,
	Flex,
	Link as ChakraLink,
	useToast,
	Center,
} from '@chakra-ui/react';
import { Card } from './global/holder/Card';
import { useAuth } from './utils/AuthContext';

const Login = () => {
	const { isLoggedIn } = useAuth();
	const navigate = useNavigate();
	const toast = useToast();

	const [loginData, setLoginData] = useState({
		usernameOrEmail: '',
		password: '',
	});

	const [passwordShown, setPasswordShown] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const { setIsLoggedIn } = useAuth();

	useEffect(() => {
		if (successMessage) {
			const timer = setTimeout(() => {
				navigate('/dashboard', { replace: true });
				window.location.reload();
			}, 2000);

			return () => clearTimeout(timer);
		}
	}, [successMessage, navigate]);

	useEffect(() => {
		if (isLoggedIn) {
			navigate('/dashboard', { replace: true });
		}
	}, [navigate, isLoggedIn]);

	const togglePasswordVisibility = () => {
		setPasswordShown(passwordShown => !passwordShown);
	};

	const loginHandler = async (e) => {
		e.preventDefault();

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					usernameOrEmail: loginData.usernameOrEmail,
					password: loginData.password
				}),
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const contentType = response.headers.get("content-type");
			if (contentType && contentType.includes("application/json")) {
				const data = await response.json();
				console.log(data);
				if (data.token && data.sText) {
					localStorage.setItem('token', data.token);

					setIsLoggedIn(true);
					toast({
						title: data.sText,
						description: 'You are now viewing your Dashboard',
						status: 'success',
						duration: 5000,
						isClosable: true,
					});

					setLoginData({
						usernameOrEmail: '',
						password: ''
					});
				}
				// Reset fields

			}

			setErrorMessage('');
		} catch (error) {
			console.error('Error:', error);
			toast({
				title: 'Login failed',
				description: 'Please check your credentials.',
				status: 'error',
				duration: 5000,
				isClosable: true,
			});
		}
	};

	const handleChange = (e) => {
		setLoginData({ ...loginData, [e.target.name]: e.target.value });
	};

	return (
		<Center h="100vh">
			<Card>
				<VStack spacing={4} align="stretch">
					<h1>Login</h1>
					<Box as="form" onSubmit={loginHandler}>
						<FormControl id="usernameOrEmail" isRequired>
							<FormLabel>Username or Email</FormLabel>
							<Input
								type="text"
								name="usernameOrEmail"
								value={loginData.usernameOrEmail}
								onChange={handleChange}
								required
							/>
						</FormControl>

						<FormControl id="password" isRequired>
							<FormLabel>Password</FormLabel>
							<Input
								type={passwordShown ? 'text' : 'password'}
								name="password"
								value={loginData.password}
								onChange={handleChange}
								required
							/>
							<Button onClick={togglePasswordVisibility} mt="2" minW="80px">
								{passwordShown ? 'Hide' : 'Show'}
							</Button>
						</FormControl>

						<Button mt={4} colorScheme="blue" type="submit">
							Login
						</Button>

						<Flex mt={4} justifyContent="center">
							Not a user?
							<ChakraLink
								color="teal.500"
								ml={2}
								onClick={() => navigate('/registration')}
							>
								Register Now
							</ChakraLink>
						</Flex>

						<Flex mt={4} justifyContent="center">
							Forgot password?
							<ChakraLink
								color="teal.500"
								ml={2}
								onClick={() => navigate('/request-password-reset')}
							>
								Reset Now
							</ChakraLink>
						</Flex>

						{successMessage && (
							<Alert status="success" mt={4}>
								<AlertIcon />
								<AlertDescription>{successMessage}</AlertDescription>
								<CloseButton
									position="absolute"
									right="8px"
									top="8px"
									onClick={() => setSuccessMessage('')}
								/>
							</Alert>
						)}

						{errorMessage && (
							<Alert status="error" mt={4}>
								<AlertIcon />
								<AlertDescription>{errorMessage}</AlertDescription>
								<CloseButton
									position="absolute"
									right="8px"
									top="8px"
									onClick={() => setErrorMessage('')}
								/>
							</Alert>
						)}
					</Box>
				</VStack>
			</Card>
		</Center>
	);
};

export default Login;
