import Header from "../global/Header/Header";
import Footer from "../global/Footer/Footer";
import HomePage from '../pages/HomePage';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoutes from "./ProtectedRoutes";
import AccountRegister from "../AccountRegister";
import Login from "../Login";
import Logout from "../Logout";
import NotFound from "./NotFound";
import PasswordResetRequest from "../passwords/PasswordResetRequest";
import PasswordReset from '../passwords/PasswordReset';

function Page() {
	return (
		<>
			<Header />
			<Routes>
				<Route path="/" element={<HomePage />} />
				<Route path="/registration" element={<AccountRegister />} />
				<Route path="/login" element={<Login />} />
				<Route path="/logout" element={<Logout />} />
				<Route path="/request-password-reset" element={<PasswordResetRequest />} />
				<Route path="/reset-password/:token" element={<PasswordReset />} />
				<Route path="/*" element={<ProtectedRoutes />} />
				<Route path="*" element={<NotFound />} />
			</Routes>
			<Footer />
		</>
	)
}

export default Page;
