const NewzealandCourses = [
	{ value: 'University of Canterbury', label: 'University of Canterbury' },
	{ value: 'Massey University', label: 'Massey University' },
	{ value: 'University of Waikato', label: 'University of Waikato' },
	{ value: 'Auckland University of Technology', label: 'Auckland University of Technology' },
	{ value: 'Victoria University of Wellington', label: 'Victoria University of Wellington' },
	{ value: 'Lincoln University', label: 'Lincoln University' },
	{ value: 'Unitec Institute of Technology', label: 'Unitec Institute of Technology' },
	{ value: 'Eastern Institute of Technology', label: 'Eastern Institute of Technology' },
	{ value: 'Otago Polytechnic', label: 'Otago Polytechnic' },
	{ value: 'Wintec (Waikato Institute of Technology)', label: 'Wintec (Waikato Institute of Technology)' },
	{ value: 'Manukau Institute of Technology', label: 'Manukau Institute of Technology' },
	{ value: 'Nelson and Marlborough Institute of Technology', label: 'Nelson and Marlborough Institute of Technology' },
	{ value: 'Ara Institute of Canterbury', label: 'Ara Institute of Canterbury' },
	{ value: 'Southern Institute of Technology', label: 'Southern Institute of Technology' },
	{ value: 'Whitireia New Zealand', label: 'Whitireia New Zealand' },
	{ value: 'Wellington Institute of Technology (WelTec)', label: 'Wellington Institute of Technology (WelTec)' },
	{ value: 'Toi Ohomai Institute of Technology', label: 'Toi Ohomai Institute of Technology' },
	{ value: 'North Tec', label: 'North Tec' },
	{ value: 'Western Institute of Technology at Taranaki (WITT)', label: 'Western Institute of Technology at Taranaki (WITT)' },
	{ value: 'Whitecliffe College', label: 'Whitecliffe College' },
	{ value: 'Aspire2International Group', label: 'Aspire2International Group' },
	{ value: 'Pacific International Hotel Management School', label: 'Pacific International Hotel Management School' },
	{ value: 'ATMC New Zealand', label: 'ATMC New Zealand' },
	{ value: 'New Zealand Skills & Education', label: 'New Zealand Skills & Education' },
	{ value: 'Future Skills Academy', label: 'Future Skills Academy' },
	{ value: 'University of waikato College', label: 'University of waikato College' },
	{ value: 'Universal College of Learning', label: 'Universal College of Learning' },
	{ value: 'Le Cordon Bleu', label: 'Le Cordon Bleu' },
	{ value: 'New Zealand Airline Academy', label: 'New Zealand Airline Academy' },
	{ value: 'UC International College, Christchurch', label: 'UC International College, Christchurch' }
];

export default NewzealandCourses;